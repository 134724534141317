import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const DotsIconSvgStyles = styled.div`
  height: 100%;
  display: flex;
  align-items: center;

  svg {
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    fill: ${({ fill }) => fill};
    transform: ${({transform}) => transform};
    transform-origin: ${({transformOrigin}) => transformOrigin} ;
  }
`

const DotsIconSvg = ({ width = '17px', height = '19px', fill = '#8f8f8f', transform, transformOrigin }) => (
  <DotsIconSvgStyles width={width} height={height} fill={fill} transform={transform} transformOrigin={transformOrigin}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 14.9 3.2"
      version="1.1"
      viewBox="0 0 14.9 3.2"
      xmlSpace="preserve"
    >
      <path
        d="M14.9 1.6c0-.9-.7-1.6-1.6-1.6-.9 0-1.6.7-1.6 1.6 0 .9.7 1.6 1.6 1.6.9 0 1.6-.7 1.6-1.6M9.1 1.6C9.1.7 8.3 0 7.4 0c-.8 0-1.6.7-1.6 1.6 0 .9.7 1.6 1.6 1.6.9 0 1.7-.7 1.7-1.6M3.3 1.6C3.3.7 2.5 0 1.6 0 .7 0 0 .7 0 1.6c0 .9.7 1.6 1.6 1.6.9 0 1.7-.7 1.7-1.6"
        className="st0"
        transform="translate(-1814 -260) translate(1814 260)"
      />
    </svg>
  </DotsIconSvgStyles>
)

DotsIconSvg.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  transform: PropTypes.string,
  transformOrigin: PropTypes.string,
}

export default DotsIconSvg
