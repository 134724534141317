import React from "react";
import RSFlag from "../Icons/rs.png";
import GBFlag from "../Icons/gb.png";

export const CountryFlags = locales => {
  const flagObjects = {
    rs: <img src={RSFlag} />,
    en: <img src={GBFlag} />
  };
  return flagObjects[locales] || <>No Flag</>;
};
