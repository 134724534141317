import React from 'react'
import LoginStyles from './LoginStyles'
import Form from '../../components/forms/forgotPassword/ForgotPassowrdForm'


const ForgotPassword = () => (
	<LoginStyles>
		<Form />
	</LoginStyles>
)


export default ForgotPassword
