import styled from 'styled-components'

const BasicInfoSectionStyles = styled.div`
	.basicInfoContainer {
		display: flex;
		align-items: center;
		margin: 60px 0;

		.labelAndValidation {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.inputsContainer {
			margin-left: 30px;

			.topInputs {
				display: flex;
				margin-bottom: 10px;
			}

			.middleInputs {
				display: flex;
				margin-bottom: 10px;
			}

			.bottomInputs {
				display: flex;
				margin-bottom: 10px;
			}

			.infoText {
				font-size: 16px;
				min-height: 29px;
				font-weight: 500;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: normal;
				color: #4b4b4b;
				background: #f7f7f7;
				padding: 5px 5px;
				overflow: hidden;
				border-radius: 4px;
			}

			.checkboxContainer {
				display: flex;
				gap: 10px;
				align-items: center;
			}
		}
	}
`

export default BasicInfoSectionStyles
