import React from "react";
import { closeModalAnim } from "../../LocalBenefits/shared";
import { useDispatch } from "react-redux";
import { setRefund } from "../../../../actions/hr/refunds.actions";
import { RefundModal } from "./RefundModal";
import { Modal } from "../../../../components";

export const ShowRefundModal = ({ handleStatus, singleRefund }) => {
  const dispatch = useDispatch();

  const closeModal = () => {
    closeModalAnim();

    setTimeout(() => {
      dispatch(setRefund({}));
    }, 350);
  };
  return (
    <>
      {singleRefund?.id && (
        <Modal closeModal={closeModal}>
          <RefundModal handleStatus={handleStatus} onClose={closeModal} />
        </Modal>
      )}
    </>
  );
};
