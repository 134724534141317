import styled from 'styled-components'

const CompanyTagsSectionStyles = styled.div`
	.companyTagsContainer {
		margin: 60px 0;

		.addTagContainer {
			margin: 0 0 30px 0;
			height: 47px;
			display: flex;
			align-items: flex-end;

			.addTagInput {
				display: flex;
				align-items: flex-end;
				transform: translateX(100px);
				opacity: 0;
				transition: all 0.4s ease-in-out;

				.tagNameInputContainer {
					margin: 0 30px 0 0;
					width: 150px;
				}
			}
		}

		.tagsContainer {
			max-width: 746px;
			border: solid 1px #979797;
			border-radius: 4px;
			display: flex;
			flex-wrap: wrap;
		}
		.tagButton {
			display: flex;
			align-items: center;
			padding: 5px;
			background: #1568bf;
			margin: 5px;
			border-radius: 4px;

			.tagName {
				color: #fff;
				font-size: 14px;
				font-weight: 600;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: normal;
				margin-right: 10px;
			}

			.removeTagButton {
				width: 18px;
				height: 20px;
				font-size: 12px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: normal;
				background: #fff;
				color: black;
				border-radius: 4px;
				border: 1px solid #fff;
				outline: none;
				cursor: pointer;
			}
		}
	}

	.showAddTagInput {
		transform: translateX(0) !important;
		opacity: 1 !important;
	}

	.companyDetailsTagsSection {
		.companyDetailsTagsContainer{
			max-width: 833px;
			border: solid 1px #979797;
			border-radius: 4px;
			display: flex;
			flex-wrap: wrap;
			margin: 30px 0;
		}

		.tagButton {
			display: flex;
			align-items: center;
			padding: 5px;
			background: #1568bf;
			margin: 5px;
			border-radius: 4px;

			.tagName {
				color: #fff;
				font-size: 14px;
				font-weight: 600;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: normal;
			}
		}

	}
	
	
`

export default CompanyTagsSectionStyles
