export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SET_USER = "SET_USER";
export const SET_USER_ONBOARDING_DONE = "SET_USER_ONBOARDING_DONE";
export const FINISH_USER_ONBOARDING = "SET_USER_ONBOARDING_TO_TRUE";
export const FINISH_EMPLOYEE_ONBOARDING = "FINISH_EMPLOYEE_ONBOARDING";
export const SET_USER_TOKENS = "SET_USER_TOKENS";
export const SET_USER_NOTIFICATIONS = "SET_USER_NOTIFICATIONS";
export const POP_SYSTEM_NOTIFICATIONS = "POP_SYSTEM_NOTIFICATIONS";
export const USER_ACCEPT_PRIVACY_POLICY = "USER_ACCEPT_PRIVACY_POLICY";
export const SYNC_USER = "SYNC_USER";
export const SWITCH_USER_ROLE = "SWITCH_USER_ROLES";
export const SET_USER_THEMES = "SET_USER_THEMES";
export const SET_GLOBAL_NOTIFICATION = "SET_GLOBAL_NOTIFICATION";
export const IS_REFUND_DOWNLOADING = "IS_REFUND_DOWNLOADING";
export const SET_DOWNLOAD_PERCENTAGE = "SET_DOWNLOAD_PERCENTAGE";
export const SET_CITIES = "SET_CITIES";
