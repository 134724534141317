const getInitals = (name, isFirstLetter) => {
  if (name && name !== "" && isFirstLetter) {
    return name.charAt(0);
  }

  if (name && name !== undefined && !isFirstLetter) {
    let rgx = new RegExp(/(\p{L}{1})(\p{L}{1})+/, "gu");
    let initials = [...name.matchAll(rgx)];

    const splitName = name.split(" ");

    if (splitName[0].length === 1 && splitName[1].length === 1) {
      return `${splitName[0]}${splitName[1]}`;
    }

    if (splitName[0].length === 1 && splitName[1].length > 1) {
      return (initials = (
        splitName[0] + (initials.pop()?.[1] || "")
      ).toUpperCase());
    }

    if (splitName[0].length > 1 && splitName[1].length === 1) {
      return (initials = (
        (initials.shift()?.[1] || "") + splitName[1]
      ).toUpperCase());
    }

    return (initials = (
      (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
    ).toUpperCase());
  }

  return "";
};

export default getInitals;
