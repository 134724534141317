import styled from 'styled-components'


const EditEmployeeStyles = styled.section`
	.editEmployeeContainer {
		width: calc(100% - 210px);
		margin-left: 210px;
		background: whitesmoke;
		min-height: 100vh;
	}

	.editEmployeeContent {
		padding: 0 30px 30px 30px;
		display: flex;
	}

	#editEmployeeContentCard {
		opacity: 0;
		transition: all 0.4s ease-in-out;
	}

	.buttonsContainer {
		display: flex;
		margin-bottom: 30px;
	}

	@media screen and (max-width: 767px) {
		.editEmployeeContainer {
			width: 100%;
			margin-left: 0;
		}    

		.editEmployeeContent {
		   padding: 0 0 30px 0;
		}
	}
	
`

export default EditEmployeeStyles
