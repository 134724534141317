import moment from "moment";

const adminDashboardStatisticAlgorithm = data => {
  if (data.lenght === 0) return null;

  const result = {};

  data.forEach(uniqueItem => {
    const date = moment(uniqueItem.createdAt).format("YYYY.MM.DD");
    if (!result[date]) {
      result[date] = [];
    }
    result[date].push(uniqueItem.userId);
  });

  const sortedDates = Object.keys(result).sort((a, b) =>
    moment(a, "YYYY.MM.DD").diff(moment(b, "YYYY.MM.DD"))
  );

  return { statistic: result, dates: sortedDates };
};

export default adminDashboardStatisticAlgorithm;
