import styled from 'styled-components'

const InitialsCircleStyles = styled.div`
	min-width: ${({ size }) => `${size}px`};
	min-height: ${({ size }) => `${size}px`};
	background: ${({ background }) => background};
	color: ${({ color }) => color};
	border-radius: ${({ size }) => `${size / 2}px`};
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: ${({ fontSize }) => `${fontSize}px`};
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-transform: uppercase;
`

export default InitialsCircleStyles
