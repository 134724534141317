import React from 'react'
import PropTypes from 'prop-types'

import AttachedFileWrapper from './AttachmentStyles'

import { AttachmentIcon, TrashIcon } from '../../assets/Icons'

const AttachedFile = ({ name, size, removeFile }) => (
	<AttachedFileWrapper>
		<div className="iconContentContainer">
			<AttachmentIcon stroke="#1568bf" />
			<div className="content">
				<h5 className="name">{name}</h5>
				<p className="size">{size} KB</p>
			</div>
		</div>
		<div onClick={() => removeFile()}>
			<TrashIcon />
		</div>
	</AttachedFileWrapper>
)

AttachedFile.propTypes = {
	removeFile: PropTypes.func,
	name: PropTypes.string,
	size: PropTypes.number
}

export default AttachedFile
