import React from "react";
import { useTranslation } from "react-i18next";

export const RefundStatusFilter = ({
  handleOnClickStatus,
  handleStatus,
  style,
  classNAme
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={`refundFilter ${classNAme || ""}`}
      role="menu"
      style={style}
    >
      <div className="arrow"></div>
      <div className="refundFilterItem" role="menuitem">
        <div
          className="refundFilterItemFlexCenter"
          onClick={e => {
            e.stopPropagation();
            handleOnClickStatus("all");
          }}
        >
          <span>{t("Show All")}</span>
        </div>
      </div>
      <div className="refundFilterItem" role="menuitem">
        <div
          className="refundFilterItemFlex"
          onClick={e => {
            e.stopPropagation();
            handleOnClickStatus("pending");
          }}
        >
          {handleStatus(null)}
          <span>{t("Pending")}</span>
        </div>
      </div>
      <div className="refundFilterItem" role="menuitem">
        <div
          className="refundFilterItemFlex"
          onClick={e => {
            e.stopPropagation();
            handleOnClickStatus("accepted");
          }}
        >
          {handleStatus(true)}
          <span>{t("Accepted")}</span>
        </div>
      </div>
      <div className="refundFilterItem" role="menuitem">
        <div
          className="refundFilterItemFlex"
          onClick={e => {
            e.stopPropagation();
            handleOnClickStatus("rejected");
          }}
        >
          {handleStatus(false)}
          <span>{t("Rejected")}</span>
        </div>
      </div>
    </div>
  );
};
