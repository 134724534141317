import { findLocales } from "../../../utils/helpers/findLocales";

export const validateCompanyThemeForm = ({ photo }) => {
  const isInLanguage = findLocales("rs");

  const errors = {};

  if (!photo) {
    errors.photo = isInLanguage ? "*Obavezno polje" : "* Required";
  }

  return errors;
};
