import React from 'react'

const ImagePlaceholer = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="44"
		height="33"
		viewBox="0 0 44 33"
	>
		<g
			fill="none"
			fillRule="evenodd"
			stroke="#AFAFAF"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="1.8"
			transform="translate(1 1)"
		>
			<rect width="41.4" height="30.6" rx="2.7" />
			<circle cx="13.5" cy="9.9" r="4.5" />
			<path d="M.108 28.674L9.9 18.9l6.3 6.3m-1.8-1.8l11.7-11.7L41.4 27" />
		</g>
	</svg>
)

export default ImagePlaceholer
