import styled from "styled-components";

const ListOfRefundsMobileStyles = styled.div`
  height: 100%;

  .refundsListItem {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 30px;
    height: 100px;
    gap: 8px;
  }

  .refundsListItemTop {
    display: flex;
    justify-content: space-between;

    .refundsListItemTopName {
      font-size: 16px;
      font-weight: 500;
      color: #5d5d5d;
    }
  }

  .refundsListItemBottomCenter {
    text-align: center;
  }

  .refundsListItemBottom {
    display: flex;
    justify-content: space-between;
    color: black;
    align-items: center;

    p {
      font-size: 12px;
      font-weight: 500;
    }
    span {
      font-size: 8px;
    }

    border-bottom: 1px solid #5d5d5d;
  }

  .refundsListItemTopButton {
    width: 20px;
    height: 20px;
    color: #1568bf;
  }

  svg {
    width: 16px;
    height: 16px;
  }

  svg.pending {
    color: gray;
  }
  svg.accepted {
    color: green;
  }
  svg.rejected {
    color: red;
  }
`;

export default ListOfRefundsMobileStyles;
