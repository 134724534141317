import React from "react";
import { CheckboxInput } from "../../../../../components";
import moment from "moment";

import { transformTokensToRsd } from "../../../../../utils/transformTokensToRsd";

export const ListOfRefundsDesktopBody = props => {
  const {
    listOfRefunds,
    handleRefundOpen,
    handleCheckedOnChange,
    handleStatus,
    checkedPendingItems
  } = props;

  return (
    <div className="refundsListBody">
      {listOfRefunds.map(refundItem => {
        return (
          <div
            tabIndex={0}
            className="refundsListItem"
            role="row"
            key={refundItem.id}
            onClick={() => handleRefundOpen(refundItem)}
          >
            <div className="refundsListItemCheckbox" role="cell">
              <div className="checkboxCol">
                <CheckboxInput
                  onChange={() => handleCheckedOnChange(refundItem.id)}
                  isChecked={checkedPendingItems.includes(refundItem.id)}
                  name={String(refundItem.id)}
                  width="18px"
                  height="18px"
                />
              </div>
            </div>
            <div className="refundsListItemDate" role="cell">
              {refundItem.UserBenefit.User.UserEmployee?.CompanyBranch?.code ||
                "/"}
            </div>
            <div className="refundsListItemName" role="cell">
              {refundItem.UserBenefit.User.firstName}{" "}
              {refundItem.UserBenefit.User.lastName}
            </div>
            <div className="refundsListItemBenefit" role="cell">
              {refundItem.UserBenefit.BenefitGroup.name}
            </div>
            <div className="refundsListItemPrice" role="cell">
              {transformTokensToRsd(refundItem.UserBenefit.benefitPrice)}
            </div>
            <div className="refundsListItemStatus" role="cell">
              {handleStatus(`${refundItem.status}`)}
            </div>
            <div className="refundsListItemDate" role="cell">
              {moment(refundItem.createdAt).format("DD.MM.YYYY.")}
            </div>
          </div>
        );
      })}
    </div>
  );
};
