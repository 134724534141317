import styled from 'styled-components'

const InputFileButtonWrapper = styled.div`
  .fileUpload {
		width: ${({ width }) => width};
		height: 40px;
		background: #1568bf;
		border: 1px solid #1568bf;
		border-radius: 4px;
		display: flex;
		align-items: center;
		padding: 0 10px;
		cursor: pointer;
		position: relative;

		.text {
			width: 100%;
			text-align: center;
			font-size: 14px;
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #fff;
			margin-left: 10px;
		}
  }
`

export default InputFileButtonWrapper
