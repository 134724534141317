class Company {
	constructor(companyData = {}) {
		this.id = companyData.id || null
		this.name = companyData.name || ''
	}
}

class Notification {
	constructor(notificationData = {}) {
		this.subject = notificationData.subejct || ''
		this.message = notificationData.message || ''
		this.companies = notificationData.companies ?
			notificationData.companies.map(company => new Company(company))
			:
			[]
	}
}


export { Notification, Company }
