import styled from 'styled-components'

const CompaniesListItemExpandedStyles = styled.div`
  max-height: 800px;

  @keyframes expandListItem {
    from {
      transform: translateY(120px);
      height: 120px;
      opacity: 0;
    }
    to {
      transform: translateY(0);
      max-height: 800px;
      opacity: 1;
    }
  }

  .listItemExpanded {
    display: flex;
    width: 100%;
    background: rgb(255, 255, 255);
    height: 0;
    z-index: 2;
    max-height: 0;
    transition: max-height 0.15s ease-out;
    overflow: hidden;
  }


  .listItemExpanded.open {
    display: flex;
    width: 100%;
    background: rgb(255, 255, 255);
    z-index: 2;      
    animation: expandListItem 0.4s ease-in;
    height: 180px;
    max-height: 2000px;
    transition: max-height 0.15s ease-out;  
    transition-delay: 1s;
    transform-origin: center;
    padding: 12px 20px;
  }

  .idNumber {
    display: flex;
    align-items: flex-start;
    width: 25%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #5F5F5F;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 30px;
  }

  .expandedListItemLabel {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #1D1D1D;
  }

  .expandedListItemDataContainer {
    display: flex;
    flex-direction: column;
    gap: 7px;
    margin-bottom: 10px;
  }

  .genderValue:first-letter {
    text-transform: uppercase;
  }

  .addressWrapper {
    width: 24%;
    display: flex;
    align-items: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #5F5F5F;
    flex-direction: column;
    justify-content: flex-start;
  }

  .contactWrapper {
    width: 11%;
    display: flex;
    align-items: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #5F5F5F;
    flex-direction: column;
    justify-content: flex-start;
  }

  .birthDayAndEnrolldateWrapper {
    width: 25%;
    display: flex;
    align-items: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #5F5F5F;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 3%;
  }
`

export default CompaniesListItemExpandedStyles;
