// Constants
import { BenefitExpirationTypeEnum } from "./benefit.constants";
import LOCALES from "./locales.constants";

export const FILTERS_TIMER_IN_MILLISECONDS = 600;

export const PRICE_STEP = 50;
export const PRICE_MIN = 0;
export const PRICE_MAX = 1000;

export const BENEFIT_EXPIRATION_TYPES = [
  {
    name: LOCALES[localStorage.getItem("locales") || "rs"]["one_time"],
    key: BenefitExpirationTypeEnum.ONE_TIME.split(" ").join("_")
  },
  {
    name: LOCALES[localStorage.getItem("locales") || "rs"]["monthly"],
    key: BenefitExpirationTypeEnum.MONTH
  },
  {
    name: LOCALES[localStorage.getItem("locales") || "rs"]["voucher"],
    key: BenefitExpirationTypeEnum.VOUCHER
  }
];

export const SORT_OPTIONS = [
  {
    id: 1,
    name: LOCALES[localStorage.getItem("locales") || "rs"]["newest"],
    key: "created"
  },
  {
    id: 2,
    name:
      LOCALES[localStorage.getItem("locales") || "rs"]["benefit_group_name"],
    key: "name"
  },
  {
    id: 3,
    name: LOCALES[localStorage.getItem("locales") || "rs"]["best_rated"],
    key: "rating"
  }
];
