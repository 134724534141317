import styled from "styled-components";

const TableRowStyles = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 0.5px solid #b2b2b2;
  cursor: ${({ findItem }) => (findItem ? "pointer" : "")};

  &:last-child {
    border-bottom: none;
  }

  .linkStyles {
    text-decoration: none;
    width: ${({ tableStylings }) => tableStylings.firstColWidth};
  }

  .nameCol {
    display: flex;
    align-items: center;
    width: ${({ tableStylings }) => tableStylings.firstColWidth};
    justify-content: ${({ tableStylings }) =>
    tableStylings.firstColJustifyContent};
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #5d5d5d;
    margin: ${({ tableStylings }) => tableStylings.firstColMargin};
    padding: ${({ tableStylings }) => tableStylings.firstColPadding};
    text-transform: capitalize;
    position: relative;

    .employeeAvatar {
      min-width: 24px;
      min-height: 24px;
      border-radius: 12px;
      background: #1568bf;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      text-transform: uppercase;
    }
  }

  .tierCol {
    width: ${({ tableStylings }) => tableStylings.secondColWidth};
    display: flex;
    align-items: center;
    justify-content: ${({ tableStylings }) =>
    tableStylings.secondColJustifyContent};
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8f8f8f;
    margin: ${({ tableStylings }) => tableStylings.secondColMargin};
    padding: ${({ tableStylings }) => tableStylings.secondColPadding};
  }

  .tokensCol {
    width: ${({ tableStylings }) => tableStylings.thirdColWidth};
    display: flex;
    align-items: center;
    justify-content: ${({ tableStylings }) =>
    tableStylings.thirdColJustifyContent};
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8f8f8f;
    margin: ${({ tableStylings }) => tableStylings.thirdColMargin};
    padding: ${({ tableStylings }) => tableStylings.thirdColPadding};
  }

  .emailCol {
    width: ${({ tableStylings }) => tableStylings.forthColWidth};
    flex: 1;
    min-width: 0;
    display: flex;
    align-items: center;
    justify-content: ${({ tableStylings }) =>
    tableStylings.forthColJustifyContent};
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8f8f8f;
    margin: ${({ tableStylings }) => tableStylings.forthColMargin};
    padding: ${({ tableStylings }) => tableStylings.forthColPadding};

    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .fifthCol {
    width: ${({ tableStylings }) => tableStylings.fifthColWidth};
    display: flex;
    align-items: center;
    justify-content: ${({ tableStylings }) =>
    tableStylings.fifthColJustifyContent};
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8f8f8f;
    margin: ${({ tableStylings }) => tableStylings.fifthColMargin};
    padding: ${({ tableStylings }) => tableStylings.fifthColPadding};
    
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 5px;
     }    
  }

  .sixthCol {
    width: ${({ tableStylings }) => tableStylings.sixthColWidth};
    display: flex;
    align-items: center;
    justify-content: ${({ tableStylings }) =>
    tableStylings.sixthColJustifyContent};
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8f8f8f;
    margin: ${({ tableStylings }) => tableStylings.sixthColMargin};
    padding: ${({ tableStylings }) => tableStylings.sixthColPadding};
  }

  .actionsCol {
    width: ${({ tableStylings }) => tableStylings.actionsColWidth};
    display: flex;
    align-items: center;
    justify-content: ${({ tableStylings }) =>
    tableStylings.actionsColJustifyContent};
    background: none;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8f8f8f;
    cursor: pointer;
    position: relative;
    margin: ${({ tableStylings }) => tableStylings.actionsColMargin};
    padding: ${({ tableStylings }) => tableStylings.actionsColPadding};
  }

  .tooltip {
    width: 180px;
    /* height: 107px; */
    background: #f7f7f7;
    border: 1px solid #1568bf !important;
    padding: 0;
    border-radius: 4px;
  }

  .email-tooltip {
    /* height: 107px; */
    background: #282a3b !important;
    border: 1px solid #282a3b !important;
    padding: 0;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.85;
    letter-spacing: -0.22px;
    color: #ffffff;
    text-align: center;
    padding: 0 8px;
  }

  .email-tooltip:after {
    border-top: 8px solid #282a3b !important;
    margin-left: -3px !important;
  }
`;

export default TableRowStyles;
