import { isEmpty } from "../../services/general.utils";
import { JpgPhoto } from "../../services/global.entities";
import Category from "./Category.entity";
import LocalBenefit from "./LocalBenefit.entity";

class LocalBenefitGroup {
  constructor(benefitGroupData = {}) {
    this.id = benefitGroupData.id || null;
    this.name = benefitGroupData.name || "";
    this.description = benefitGroupData.description || "";
    this.summary = benefitGroupData.summary || "";
    this.categories = !isEmpty(benefitGroupData.categories)
      ? benefitGroupData.categories.map(category => new Category(category))
      : null;
    this.photo = benefitGroupData.Photo
      ? new JpgPhoto(benefitGroupData.Photo)
      : new JpgPhoto();
    this.benefits = benefitGroupData.Benefit
      ? benefitGroupData.Benefit.map(benefit => new LocalBenefit(benefit))
      : [];
  }
}

export default LocalBenefitGroup;
