import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { css } from "@emotion/core";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
// custom components and animations
import {
  Header,
  Card,
  Modal,
  openModalAnim,
  closeModalAnim
} from "../../../components";
import PrivacyPolicy from "../../../components/privacyPolicyModal";
import ButtonsContainer from "./buttonsContainer/ButtonsContainer";
import BenefitGroupsFilter from "./benefitGroupsFilters/BenefitGroupsFilters";
import BrowseBenefitGroups from "./BrowseBenefitGroups/BrowseBenefitGroups";
import SubscribedBenefitGroups from "./SubscribedBenefitGroups/SubscribedBenefitGroups";
import BenefitSubscribe from "./benefitSubscribe";
import VoucherReimbursement from "./voucherReimbursement";
import Onboarding from "./employeeOnboarding";
import Notification from "./employeeNotifications";
import SyncModal from "./SyncModal";
// style component
import EmployeeBenefitsStyles from "./EmployeeBenefitsStyles";
// services
import * as api from "../../../services/api/employee/employeeBenefits.services";
import * as userApiService from "../../../services/api/auth.service";
import { uniqueArrayOfObjects } from "../../../services/general.utils";
// actions
import * as actionCreators from "../../../actions/employee/employeeBenefits.actions";
import * as appActionCreators from "../../../actions/app.actions";
// hooks
import { useDisableBodyScroll } from "../../../hooks/useDisableBodyScroll";
import { FavoriteBenefits } from "./FavoriteBenefits/FavoriteBenefits";
import { apiService } from "../../../services/api/api.service";
import { SocialProofNotifications } from "./SocialProofNotifications/SocialProofNotifications";
import { monthsInSerbian } from "../../../constants/monthsInSerbian.constants";
import moment from "moment";
import { useTranslation } from "react-i18next";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: #1568bf;
`;

const EmployeeBenefits = ({
  user,
  employeeOnboarding,
  notifications,
  isLoading,
  employeeBenefitsSetInitialState,
  isSubscribedBenefitGroupsVisible,
  benefitGroupType,
  isModalOpen,
  appliedChosenCategoryIds,
  appliedCityIds,
  appliedRemoteFilter,
  appliedChosenPriceRange,
  chosenBenefitGroup,
  closeBenefitGroupModal,
  setSystemNotifications,
  setIsInitialStateSet,
  isFavoriteTab
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { currentlyPopularBenefit } = useSelector(
    state => state.employeeBenefitsPage.socialProof
  );

  const fetchMostPopularBenefitGroup = async () => {
    try {
      const durationOfCurrentBenefitGroupSubscriptions = 3;
      const benefitGroupId = undefined;

      const response = await apiService.get(
        `/benefit-group/social-proof/notification?duration=${durationOfCurrentBenefitGroupSubscriptions}${
          benefitGroupId ? `&benefitGroupId=${benefitGroupId}` : ""
        }`
      );

      if (response.data.items.length > 0) {
        dispatch(
          actionCreators.setCurrentlyPopularBenefit(response.data.items[0])
        );
      }
    } catch (error) {
      toast.error(t("failed_to_get_popular_benefit"));
    }
  };

  const initialVoucherReimbursementData = {
    user_id: user.id,
    email: user.email,
    name: `${user.firstName} ${user.lastName}`,
    benefit_name: "",
    benefit_price: 0,
    created_at: new Date(),
    voucher_id: null,
    benefit_group_id: null
  };
  const showPrivacyPolicyModal = useRef(false);
  const isMounted = useRef(false);
  const [isSyncOpen, setIsSyncOpen] = useState(false);

  const [showPDFBill, setShowPDFBill] = useState(false);
  const [voucherReimbursementData, setVoucherReimbursementData] = useState(
    initialVoucherReimbursementData
  );

  const { usedBenefits } = useSelector(state => state.employeeBenefitsPage);
  const { firstName, vocativeName } = useSelector(state => state.app.user);

  const setUsedBenefits = payload => {
    dispatch(actionCreators.setUsedBenefitsRedux(payload));
  };

  const fetchBenefits = async () => {
    const response = await apiService.get("/favorites");
    const listOfFavorite =
      response.data?.items.length > 0
        ? response.data.items.map(item => item.group)
        : [];

    dispatch(actionCreators.setListOfFavorites(listOfFavorite));
  };
  useEffect(() => {
    fetchBenefits();
    fetchMostPopularBenefitGroup();
  }, []);

  useDisableBodyScroll(isModalOpen);
  useDisableBodyScroll(isSyncOpen);

  const closeModal = () => {
    closeModalAnim();
    // delays change of state until animation is done
    setTimeout(() => {
      closeBenefitGroupModal();
    }, 350);

    const metadata = {
      user_id: user.id,
      email: user.email,
      name: `${user.firstName} ${user.lastName}`,
      benefit_group_name: chosenBenefitGroup.name,
      created_at: new Date()
    };
    window.Intercom("trackEvent", "subscribe-modal-closed", metadata);
  };

  const fetchNotifications = async () => {
    const response = await userApiService.getUnreadNotifications();
    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : t("failed_to_get_notifications")
      );
    }

    setSystemNotifications(response.notifications);
  };

  const fetchInitialData = async type => {
    const [benefitGroupsResponse, categoriesResponse] = await Promise.all([
      api.getBenefitGroups({
        chosenCategories: appliedChosenCategoryIds,
        page: 1,
        priceRange: appliedChosenPriceRange,
        chosenCities: appliedCityIds,
        remoteFilter: appliedRemoteFilter,
        type: type
      }),
      api.getCategoriesForEmployeePlatform()
    ]);

    if (benefitGroupsResponse.hasError) {
      return;
    }

    if (categoriesResponse.hasError) {
      return toast.error(
        categoriesResponse.errorMessage
          ? categoriesResponse.errorMessage
          : t("failed_to_get_categories")
      );
    }

    if (isMounted.current) {
      employeeBenefitsSetInitialState(
        benefitGroupsResponse.groups,
        benefitGroupsResponse.count,
        benefitGroupsResponse.pages,
        1,
        categoriesResponse.categories
      );
      setIsInitialStateSet(true);
    }

    // displays content after all state is set
    setTimeout(() => {
      const editEmployeeContentCard = document.getElementById(
        "benefitGroupList"
      );
      if (isMounted.current && editEmployeeContentCard) {
        editEmployeeContentCard.style.opacity = 1;
      }
    }, 100);
  };

  const fetchAlreadyUsedBenefits = async id => {
    const response = await api.getAlreadyUsedBenefits(id);

    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : t("failed_to_get_used_benefits")
      );
    }

    setUsedBenefits(uniqueArrayOfObjects(response.benefits));
  };

  useEffect(() => {
    isMounted.current = true;
    fetchInitialData(benefitGroupType);

    fetchNotifications();
    return () => {
      fetchInitialData(benefitGroupType);
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current && !employeeOnboarding) {
      // opens onboarding modal after 500 ms
      setTimeout(() => {
        openModalAnim();
      }, 500);
    }

    showPrivacyPolicyModal.current = true;
    if (
      showPrivacyPolicyModal.current &&
      user.authenticatedRole &&
      !user.hasAcceptedPrivacyPolicy
    ) {
      setTimeout(() => {
        openModalAnim();
      }, 500);
    }
  }, []);

  return (
    <EmployeeBenefitsStyles>
      <Header
        isTokenVisable
        openSyncModal={() => {
          setIsSyncOpen(true);
          setTimeout(() => {
            openModalAnim();
          }, 100);
        }}
      />
      <p className="smallScreenNotification">
        {t("choose_benefits_message", {
          name: vocativeName !== "" ? vocativeName : firstName
        })}
        <b>
          {
            monthsInSerbian[
              moment()
                .add(1, "month")
                .format("MMMM")
            ]
          }
        </b>
      </p>
      <div className="container">
        {isLoading ? (
          <div
            style={{
              height: "calc(100vh - 180px)",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <BeatLoader
              css={override}
              size={25}
              color="#123abc"
              loading={isLoading}
            />
          </div>
        ) : (
          <>
            <div className="benefitsAndFilterContainer">
              <Card padding="30px 30px 0 30px" id="benefitGroupList">
                <div style={{ minHeight: "calc(100vh - 180px)" }}>
                  <ButtonsContainer />
                  {isSubscribedBenefitGroupsVisible && !isFavoriteTab && (
                    <SubscribedBenefitGroups
                      fetchAlreadyUsedBenefits={fetchAlreadyUsedBenefits}
                      fetchData={fetchInitialData}
                    />
                  )}

                  {!isSubscribedBenefitGroupsVisible && (
                    <BrowseBenefitGroups
                      fetchAlreadyUsedBenefits={fetchAlreadyUsedBenefits}
                      fetchData={fetchInitialData}
                    />
                  )}
                  {isFavoriteTab && <FavoriteBenefits />}
                </div>
              </Card>
              {!isSubscribedBenefitGroupsVisible && <BenefitGroupsFilter />}
            </div>
            {isModalOpen && chosenBenefitGroup && (
              <Modal closeModal={closeModal}>
                <BenefitSubscribe
                  usedBenefits={usedBenefits}
                  setUsedBenefits={setUsedBenefits}
                  setShowPDFBill={setShowPDFBill}
                  setVoucherReimbursementData={setVoucherReimbursementData}
                />
              </Modal>
            )}
            {showPDFBill && (
              <Modal
                closeModal={() => setShowPDFBill(false)}
                disableClose={true}
                isCloseVisable={false}
              >
                <VoucherReimbursement
                  setShowPDFBill={setShowPDFBill}
                  voucherReimbursementData={voucherReimbursementData}
                  benefitGroup={chosenBenefitGroup}
                />
              </Modal>
            )}
            {!employeeOnboarding && (
              <Modal isCloseVisable={false}>
                <Onboarding />
              </Modal>
            )}
            {notifications?.length > 0 && (
              <Modal isCloseVisable={false}>
                <Notification notification={notifications[0]} />
              </Modal>
            )}
            {isSyncOpen && (
              <Modal
                closeModal={() => {
                  closeModalAnim();
                  setTimeout(() => {
                    setIsSyncOpen(false);
                  }, 350);
                }}
              >
                <SyncModal />
              </Modal>
            )}
            {showPrivacyPolicyModal.current &&
              user.authenticatedRole &&
              !user.hasAcceptedPrivacyPolicy && (
                <Modal isCloseVisable={false}>
                  <PrivacyPolicy />
                </Modal>
              )}
          </>
        )}
      </div>
      {currentlyPopularBenefit?.benefit_group_id && (
        <SocialProofNotifications />
      )}
    </EmployeeBenefitsStyles>
  );
};

const mapStateToProps = state => {
  return {
    user: state.app.user,
    employeeOnboarding: state.app.user.employeeOnboarding,
    notifications: state.app.notifications,
    benefitGroupsCount:
      state.employeeBenefitsPage.benefitsBrowse.benefitGroupsCount,
    benefitGroupsPage:
      state.employeeBenefitsPage.benefitsBrowse.benefitGroupsPage,
    appliedChosenCategoryIds:
      state.employeeBenefitsPage.filters.appliedChosenCategoryIds,
    appliedCityIds: state.employeeBenefitsPage.filters.cityIds,
    appliedRemoteFilter: state.employeeBenefitsPage.filters.isRemote,
    appliedChosenPriceRange:
      state.employeeBenefitsPage.filters.appliedChosenPriceRange,
    isLoading: state.employeeBenefitsPage.pageFrontEndStates.isLoading,
    isSubscribedBenefitGroupsVisible:
      state.employeeBenefitsPage.pageFrontEndStates
        .isSubscribedBenefitGroupsVisible,
    isFavoriteTab: state.employeeBenefitsPage.pageFrontEndStates.isFavoriteTab,
    benefitGroupType:
      state.employeeBenefitsPage.pageFrontEndStates.benefitGroupType,
    isModalOpen: state.employeeBenefitsPage.pageFrontEndStates.isModalOpen,
    chosenBenefitGroup:
      state.employeeBenefitsPage.pageFrontEndStates.chosenBenefitGroup
  };
};

const mapDispatchToProps = dispatch => {
  return {
    employeeBenefitsSetInitialState: (
      benefitGroups,
      benefitGroupsCount,
      benefitGroupsTotalPages,
      benefitGroupsPage,
      categories,
      subscribedBenefitGroups
    ) =>
      dispatch(
        actionCreators.employeeBenefitsSetInitialState(
          benefitGroups,
          benefitGroupsCount,
          benefitGroupsTotalPages,
          benefitGroupsPage,
          categories,
          subscribedBenefitGroups
        )
      ),
    closeBenefitGroupModal: () =>
      dispatch(actionCreators.closeBenefitGroupModal()),
    setSystemNotifications: notifications =>
      dispatch(appActionCreators.setSystemNotifications(notifications)),
    setIsInitialStateSet: flag =>
      dispatch(actionCreators.setIsInitialStateSet(flag))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeBenefits);
