import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ToggleButtonStyles from "./ToggleButtonStyles";
import { useTranslation } from "react-i18next";

const ToggleButton = ({ tiers, isActive, onToggle }) => {
  const { t } = useTranslation();

  return (
    <ToggleButtonStyles tiers={tiers} isActive={isActive}>
      <div className="activationCheckBox">
        {isActive && <p className="activityOnText">{t("on")}</p>}
        <div className="dot" onClick={tiers > 0 && onToggle} />
        {!isActive && <p className="activationOffText">{t("off")}</p>}
      </div>
      {isActive ? (
        <p className="benefitActivityStatusText">{t("bg_enabled")}</p>
      ) : (
        <p className="benefitActivityStatusText">{t("bg_disabled")}</p>
      )}
    </ToggleButtonStyles>
  );
};

ToggleButton.propTypes = {
  isActive: PropTypes.bool,
  onToggle: PropTypes.func,
  tiers: PropTypes.number
};

const mapStateToProps = state => {
  return {
    isActive: state.benefits.editPageState.isBenefitGroupActive,
    tiers: state.benefits.editPageState.tiers.length
  };
};

export default connect(mapStateToProps, null)(ToggleButton);
