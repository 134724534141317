import { companyTagsSectionActionsTypes } from './companyTagsSectionReducer'

export const useCompanyTagInternActions = (dispatch) => {
	const openAddTagInput = () => {
		dispatch({ type: companyTagsSectionActionsTypes.openAddTagInput })
		setTimeout(() => {
			const addCompanyTagInput = document.getElementById('addTagInput')
			addCompanyTagInput.classList.add('showAddTagInput')
		}, 100)
	}

	const addTag = (setFieldValue, values, tag) => {
		const addCompanyTagInput = document.getElementById('addTagInput')
		addCompanyTagInput.classList.remove('showAddTagInput')
		setTimeout(() => {
			dispatch({ type: companyTagsSectionActionsTypes.closeAddTagInput })
			setFieldValue('tags', [...values.tags, tag])
			dispatch({ type: companyTagsSectionActionsTypes.handleTagChange, tag: undefined })
		}, 400)
	}


	const handleTagChange = (allTags, id) => {
		const chosenTag = allTags.find(tag => tag.id === id);
		dispatch({ type: companyTagsSectionActionsTypes.handleTagChange, tag: chosenTag })
	}

	const filteredTags = (tags, allTags) => {
		const chosenIds = []
		tags.forEach(tag => {
			chosenIds.push(tag.id)
		})
		return allTags.filter(t => !chosenIds.includes(t.id))
	}

	return {
		filteredTags,
		openAddTagInput,
		addTag,
		handleTagChange
	}
}
