import * as actionTypes from "../../constants/actionTypes/admin/categories.constants";

export const setCategories = categories => {
  return { type: actionTypes.SET_CATEGORIES, categories };
};

export const addCategory = category => {
  return { type: actionTypes.ADD_CATEGORY, category };
}

export const editCategory = category => {
  return { type: actionTypes.EDIT_CATEGORY, category };
}

export const deleteCategory = id => {
  return { type: actionTypes.DELETE_CATEGORY, id };
}