import { BenefitGroup, Provider, Benefit } from "../../models/domain";
import * as actionTypes from "../../constants/actionTypes/admin/providers.constants";
import * as modelMapperService from "../../models/modelMapper.service";

export const setInitProviders = (providers, count) => {
  providers = modelMapperService.mapManyToDomain(providers, Provider);
  return { type: actionTypes.SET_INITAL_PROVIDERS, providers, count };
};

export const setProvidersPage = page => {
  return { type: actionTypes.SET_PROVIDERS_PAGE, page };
};

export const setProvidersSearch = search => {
  return { type: actionTypes.SET_PROVIDERS_SEARCH, search };
};

export const editProvider = provider => {
  provider = modelMapperService.mapOneToDomain(provider, Provider);
  return { type: actionTypes.EDIT_PROVIDER, provider };
};

export const removeProvider = id => {
  return { type: actionTypes.REMOVE_PROVIDER, id };
};

export const addBenefitGroup = (providerId, benefitGroup) => {
  benefitGroup = modelMapperService.mapOneToDomain(benefitGroup, BenefitGroup);
  return { type: actionTypes.ADD_BENEFIT_GROUP, providerId, benefitGroup };
};

export const editBenefitGroup = (providerId, benefitGroup) => {
  benefitGroup = modelMapperService.mapOneToDomain(benefitGroup, BenefitGroup);
  return { type: actionTypes.EDIT_BENEFIT_GROUP, providerId, benefitGroup };
};

export const deleteBenefitGroup = (providerId, benefitGroupId) => {
  return { type: actionTypes.REMOVE_BENEFIT_GROUP, providerId, benefitGroupId };
};

export const addBenefit = (providerId, benefitGroupId, benefit) => {
  benefit = modelMapperService.mapOneToDomain(benefit, Benefit);
  return { type: actionTypes.ADD_BENEFIT, providerId, benefitGroupId, benefit };
};

export const editBenefit = (providerId, benefitGroupId, benefit) => {
  benefit = modelMapperService.mapOneToDomain(benefit, Benefit);
  return {
    type: actionTypes.EDIT_BENEFIT,
    providerId,
    benefitGroupId,
    benefit
  };
};

export const deleteBenefit = (providerId, benefitGroupId, benefitId) => {
  return {
    type: actionTypes.REMOVE_BENEFIT,
    providerId,
    benefitGroupId,
    benefitId
  };
};
