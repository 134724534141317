import * as actionTypes from "../../constants/actionTypes/admin/users.constants";

export const setUsers = payload => {
  return { type: actionTypes.SET_USERS, payload };
};

export const setPage = payload => {
  return { type: actionTypes.SET_PAGE, payload };
};

export const onSearchChange = payload => {
  return { type: actionTypes.SEARCH_CHANGE, payload };
};
