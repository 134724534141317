import React, { useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
// styles
import { PrivacyPolicyStyles } from "./privacyPolicy.styles";
// assets
import { BenefitLogoIcon } from "../../assets/Icons";
// components
import { Button, CheckboxInput, InputLabel } from "../";
// services
import * as api from "../../services/api/auth.service";
// actions
import * as actionCreators from "../../actions/app.actions";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = ({ acceptPrivacyPolicy, logout }) => {
  const { t } = useTranslation();
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const handleAcceptPrivacyPolicy = async () => {
    const response = await api.acceptPrivacyPolicy();
    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : t("failed_to_accept_policy")
      );
    }
    acceptPrivacyPolicy();
  };

  return (
    <PrivacyPolicyStyles>
      <div className="circleLogoContainer">
        <BenefitLogoIcon />
      </div>
      <div className="privacy-policy-content">
        <h2>OBAVEŠTENJE O PRIVATNOSTI</h2>

        <h3>1. Datum izdavanja Obaveštenja o privatnosti</h3>
        <p>
          <span>Ovo Obaveštenje o privatnosti (</span>
          <b>Obaveštenje</b>
          <span>) je izdato 25.02.2021. godine.</span>
        </p>
        <h3>2. Svrha obaveštavanja</h3>
        <p>
          <span>
            Ovim Obaveštenjem želimo da Vam na lako razumljiv i transparentan
            način pružimo informacije o tome na koji način SBT obrađuje podatke
            o ličnosti lica na koja se podaci odnose, koje kategorije podataka o
            ličnosti obrađuje SBT i koja prava imaju lica na koja se podaci
            odnose. Obrađujemo Vaše podatke o ličnosti u ime kompanija sa kojima
            sarađujemo (
          </span>
          <b>Klijenti</b>
          <span>) kako bismo:</span>
        </p>
        <ol>
          <li aria-level="1">
            <span>
              1. mogli da svojim Klijentima omogućimo sve prednosti korišćenja
              naše softverske platforme Benefiti.rs (
            </span>
            <b>Platforma</b>
            <span>
              ), preko koje oni u svojstvu poslodavca i rukovaoca u obradi
              podataka o ličnosti svojim zaposlenima (
            </span>
            <b>Krajnji korisnici</b>
            <span>
              ) obezbeđuju personalizovane, fleksibilne i merljive benefite i
              podsticaje u okviru svog predefinisanog budžeta (
            </span>
            <b>Benefiti</b>
            <span>);</span>
          </li>
          <li aria-level="1">
            <span>
              2. omogućili Klijentima isporučivanje odabranih Benefita Krajnjim
              korisnicima. 
            </span>
          </li>
        </ol>
        <p>
          <span>
            Sve podatke o ličnosti navedene u ovom Obaveštenju obrađujemo i
            štitimo u potpunosti u skladu sa srpskim Zakonom o zaštiti podataka
            o ličnosti (ZZPL) i Opštom uredom o zaštiti podataka o ličnosti
            (GDPR), a isključivo na osnovu pismenih uputstava Klijenta kao
            rukovaoca.
          </span>
        </p>
        <ul>
          <li aria-level="1">
            <span>
              Podatak o ličnosti predstavlja svaki podatak koji se odnosi na
              fizičko lice čiji je identitet određen ili odrediv, kao što su
              lično ime, godište, fotografija ili lokacija. Podaci koji su
              anonimizovani se ne smatraju ličnim podacima.
            </span>
          </li>
        </ul>
        <ul>
          <li aria-level="1">
            <span>
              Obrada predstavlja svaku radnju ili skup radnji koje se vrše sa
              podacima o ličnosti ili njihovim skupovima (npr. prikupljanje,
              skladištenje, upotreba, umnožavanje, objavljivanje, brisanje ili
              uništavanje).
            </span>
          </li>
        </ul>
        <h3>3. Izmene i dopune</h3>
        <p>
          <span>
            Ovo je poslednja verzija Obaveštenja (25. februar 2021. godine).
          </span>
        </p>
        <p>
          <span>
            SBT ima puno pravo da u bilo kom momentu izmeni ovo Obaveštenje. U
            slučaju da se Obaveštenje menja ili dopunjuje, unećemo datum
            poslednje verzije dokumenta. O svakoj promeni Obaveštenja ćemo Vas
            blagovremeno obavestiti. Molimo Vas da imate na umu činjenicu da
            izmene i dopune ovog Obaveštenja ne utiču ni na koji način na
            Obaveštenje o privatnosti koje je sačinio Klijent ili bilo koja
            treća strana povodom Platforme, odnosno Benefita.
          </span>
        </p>
        <h3>4. O nama</h3>
        <p>
          <span>
            U ovom Obaveštenju, ,,SBT’’, ,,mi’’ i ,,naši’’ upućuje na Smart
            Benefit Technologies d.o.o. Beograd, sa sedištem u ul. Takovska br.
            12, Beograd-Palilula, Republika Srbija.
          </span>
        </p>
        <p>
          <b>
            <i>SBT postupa isključivo u svojstu obrađivača</i>
          </b>
          <span> u obradi Vaših podataka o ličnosti:</span>
        </p>
        <ul>
          <li aria-level="1">
            <span>
              Kada u ime Klijenata kao rukovaoca obrađujemo podatke Krajnjih
              korisnika koje Klijenti i Krajni korisnici ostavljaju na
              Platformi;
            </span>
          </li>
          <li aria-level="1">
            <span>
              Kada u ime Klijenata kao rukovalaca obrađujemo podatke o ličnosti
              Krajnjih korisnika koje nam dostave Klijenti kako bismo omogućili
              Klijentima isporučivanje odabranih Benefita Krajnjim korisnicima.
              Ovo u ime Klijenata koji su nas u ovu svrhu angažovali činimo u
              vidu kupovine Benefita od svojih poslovnih partnera (
            </span>
            <b>Partneri</b>
            <span>
              ) i prenosa prava na njima Klijentima, budući da Klijenti nemaju
              mogućnost da samostalno isporuče odabrane Benefite Krajnjim
              korisnicima. Na ovaj način, SBT omogućava svojim Klijentima da u
              njihovo ime, a na osnovu zaključenog ugovora o poslovnoj saradnji
              povodom omogućavanja isporučivanja odabranih Benefita Krajnjim
              korisnicima da na najbrži i najefikasniji mogući način to i
              ostvare.
            </span>
          </li>
        </ul>
        <p>
          <span>
            Klijenti su rukovaoci u obradi Vaših podataka o ličnosti s obzirom
            na to da oni utvrđuju svrhu i način na koji se Vaši podaci o
            ličnosti prikupljaju, upotrebljavaju i na drugi način obrađuju, a mi
            u njihovo ime pružamo usluge korišćenja Platforme, odnosno usluge
            omogućavanja isporučivanja odabranih Benefita Krajnjim korisnicima.
          </span>
        </p>
        <p>
          <i>
            <span>Druga obaveštenja o privatnosti na Platformi</span>
          </i>
        </p>
        <p>
          <span>
            Klijent kao rukovalac u obradi Vaših podataka o ličnosti sačinio je
            zasebno obaveštenje o privatnosti u skladu sa svojom zakonskom
            obavezom na osnovu ZZPL-a, koje Vam je dostupno prilikom logovanja
            na našu Platformu. SBT ne može ni na koji način biti odgovoran licu
            na koje se podaci odnose u slučaju da je rukovalac u svom
            obaveštenju o privatnosti propustio da informiše lica na koja se
            podaci odnose o bilo kojim okolnostima koje je bio dužan da
            transparentno predoči u svom obaveštenju o privatnosti.
          </span>
        </p>
        <p>
          <span>
            Pitanja, pritužbe i dodatne informacije u vezi sa ovim Obaveštenjem
            i zaštitom podataka o ličnosti kod SBT-a možete dobiti putem sledeće
            imejl adrese: <a href="mailto:gdpr@benefiti.rs">gdpr@benefiti.rs</a>
            .
          </span>
        </p>
        <h3>5. Podaci o ličnosti koje prikupljamo</h3>
        <p>
          <i>
            <span>
              Na osnovu ugovora o poslovnoj saradnji sa Klijentima povodom
              korišćenja Platforme
            </span>
          </i>
        </p>
        <ol>
          <li aria-level="1">
            <span className="privacy-policy-subtitle">
              A. Omogućavanje korišćenja Platforme i obuka za korišćenje
              Platforme
            </span>
          </li>
        </ol>
        <p>
          <span>
            Kada Klijenti koriste našu Platformu radi omogućavanja svojim
            zaposlenima kao Krajnjim korisnicima odabir Benefita, uvid u status
            odabranih Benefita, odnosno upravljanje njima, obezbeđujući im
            pritom različita prava na njoj u zavisnosti od svog svojstva u
            kompaniji Klijenta (admin, HR, zaposleni), obrađujemo u ime
            Klijenata podatke o ličnosti Krajnjih korisnika.
          </span>
        </p>
        <p>
          <span>
            Pored toga, SBT u ime Klijenata obrađuje podatke o ličnosti Krajnjih
            korisnik i u svrhe obučavanja za korišćenje Platforme Krajnjih
            korisnika koji će Platformu koristiti u zavisnosti od obima
            dodeljenih im prava od strane Klijenata.
          </span>
        </p>
        <p>
          <span>
            Podaci o ličnosti koje tada prikupljamo uključuju, ali se ne
            ograničavaju na sledeće kategorije podataka o ličnosti:
          </span>
        </p>
        <ul>
          <li aria-level="1">
            <i>
              <span>Podaci o ličnoj identifikaciji,</span>
            </i>
            <span> kao što su: ime i prezime, datum rođenja, pol;</span>
          </li>
          <li aria-level="1">
            <i>
              <span>Kontakt podaci</span>
            </i>
            <span>
              , kao što su: poslovna imejl adresa, privatna imejl adresa, broj
              poslovnog i privatnog fiksnog ili mobilnog telefona;
            </span>
          </li>
          <li aria-level="1">
            <i>
              <span>Podaci o radnom mestu</span>
            </i>
            <span>
              : tačan naziv i pozicija koju Krajnji korisnik ima u kompaniji
              Klijenta, datum početka rada kod Klijenta, hijerarhijski nivo kod
              Klijenta (npr. Junior, Medior, Senior, itd.), opseg prava koja
              Krajnji korisnik ima na Platformi u odnosu na svoju poziciju u
              kompaniji Klijenta (npr. Admin, HR, zaposleni), naziv tima u kome
              se Krajnji korisnik nalazi;
            </span>
          </li>
          <li aria-level="1">
            <i>
              <span>Podaci o ličnom statusu, </span>
            </i>
            <span>kao što je bračni status;</span>
          </li>
          <li aria-level="1">
            <i>
              <span>
                Podaci o aktivnom nalogu Krajnjeg korisnika na različitim
                elektronskim digitalnim servisima
              </span>
            </i>
            <span>
              , na primer onda kada Krajnji korisnik odabere Benefite koje
              omogućavaju pružaoci usluga digitalnog servisa;
            </span>
          </li>
          <li aria-level="1">
            <i>
              <span>Podatak o jedinstvenom korisničkom kodu</span>
            </i>
            <span>,</span>
            <span>
              koji SBT generiše za Krajnjeg korisnika u slučaju da izabrani
              Benefit predstavlja vaučer/kupon/kod;
            </span>
          </li>
          <li aria-level="1">
            <i>
              <span>
                Podaci o potrošačkim navikama i interesovanjima Krajnjih
                korisnika
              </span>
            </i>
            <span>
              , kao što je izbor konkretnih Benefita prilikom korišćenja
              Platforme;
            </span>
          </li>
          <li aria-level="1">
            <i>
              <span>Podaci o kulturnom identitetu Krajnjih korisnika</span>
              <span>;</span>
            </i>
          </li>
          <li aria-level="1">
            <span>Podaci o društvenom identitetu Krajnjih korisnika</span>
            <span>;</span>
          </li>
          <li aria-level="1">
            <i>
              <span>Podaci o fiziološkom identitetu Krajnih korisnika</span>
            </i>
            <span>;</span>
          </li>
          <li aria-level="1">
            <i>
              <span>Podaci o mentalnom identitetu Krajnjih korisnika</span>
            </i>
            <span>;</span>
          </li>
          <li aria-level="1">
            <i>
              <span>Ostali podaci o korišćenju Platforme</span>
            </i>
            <span>
              , kao su podaci dobijeni na osnovu uvida u aktivnosti na
              korisničkom nalogu Krajnjeg korisnika (broj tokena koji je
              Krajnjem korisniku raspoloživ, trenutni status raspoloživog broja
              tokena i promena broja tokena u odnosu na raspoloživost), kao i
              podaci o ostvarenim Benefitima.
            </span>
          </li>
        </ul>
        <p>
          <span>
            SBT u ovom slučaju obrađuje navedene podatke o ličnosti u svojstvu
            obrađivača podataka o ličnosti kako bismo omogućili Klijentima
            korišćenje Platforme u punom obimu, a na osnovu zaključenog ugovora
            sa Klijentima o poslovnoj saradnji u vidu korišćenja Platforme.
          </span>
        </p>
        <ol>
          <li aria-level="1">
            <span className="privacy-policy-subtitle">B. Tehnička podrška</span>
          </li>
        </ol>
        <p>
          <span>
            SBT kao obrađivač u ime Klijenta takođe obrađuje informacije o
            korisničkim sesijama (kao što su greške pri učitavanju i vreme
            odziva), kao i podatke u vezi sa komunikacijom sa korisničkom
            podrškom putem telefona, imejla i pisanim putem tj. u vezi a upitima
            koje Krajnji korisnici podnose (kanal komunikacije, vrsta upita,
            datum, vreme, sadržaj). Navedene podatke obrađujemo kako bismo
            Klijentima pružili korisničku podršku sa ciljem nesmetanog
            korišćenja Platforme, a na osnovu ugovora o poslovnoj saradnji
            povodom korišćenja Platforme, koji obuhvata i pružanje tehničke
            podrške od strane SBT-a.
          </span>
        </p>
        <p>
          <i>
            <span>
              Na osnovu ugovora o poslovnoj saradnji sa Klijentima povodom
              omogućavanja isporučivanja odabranih Benefita Krajnjim korisnicima
            </span>
          </i>
        </p>
        <ol>
          <li aria-level="1">
            <span className="privacy-policy-subtitle">
              C. Omogućavanje isporučivanja odabranih Benefita Krajnjim
              korisnicima
            </span>
          </li>
        </ol>
        <p>
          <span>
            Kako bismo Klijentima mogli da omogućimo isporučivanje odabranih
            Benefita Krajnjim korisnicima u ime Klijenata u ovu svrhu za koju
            smo angažovani obrađujemo podatke o ličnosti Krajnjih korisnika koje
            nam dostave Klijenti koji se odnose, ali ne ograničavaju na sledeće
            kategorije podataka o ličnosti:
          </span>
        </p>
        <ul>
          <li aria-level="1">
            <i>
              <span>Podaci o ličnoj identifikaciji</span>
            </i>
            <span>, kao što su: ime i prezime, datum rođenja, pol;</span>
          </li>
          <li aria-level="1">
            <i>
              <span>Kontakt podaci</span>
            </i>
            <span>
              , kao što su: poslovna imejl adresa, privatna imejl adresa, broj
              poslovnog i privatnog fiksnog ili mobilnog telefona;
            </span>
          </li>
          <li aria-level="1">
            <i>
              <span>Podaci o radnom mestu</span>
            </i>
            <span>
              : tačan naziv i pozicija koju Krajnji korisnik ima u kompaniji
              Klijenta, datum početka rada kod Klijenta, hijerarhijski nivo kod
              Klijenta (npr. Junior, Medior, Senior, itd.), opseg prava koja
              Krajnji korisnik ima na Platformi u odnosu na svoju poziciju u
              kompaniji Klijenta (npr. Admin, HR, zaposleni), naziv tima u kome
              se Krajnji korisnik nalazi;
            </span>
          </li>
          <li aria-level="1">
            <i>
              <span>
                Podaci o aktivnom korisničkom nalogu Krajnjeg korisnika
              </span>
            </i>
            <span>
              {" "}
              na različitim elektronskim digitalnim servisima, na primer onda
              kada Krajnji korisnik odabere Benefite koje omogućavaju pružaoci
              usluga digitalnog servisa;
            </span>
          </li>
          <li aria-level="1">
            <i>
              <span>Podatak o jedinstvenom korisničkom kodu</span>
            </i>
            <span>
              , koji SBT generiše za Krajnjeg korisnika u slučaju da izabrani
              Benefit predstavlja vaučer/kupon/kod.
            </span>
          </li>
        </ul>
        <p>
          <span>
            SBT kao obrađivač podataka o ličnosti Krajnjih korisnika posebno
            napominje i ističe da postupa u svojstvu obrađivača podataka o
            ličnosti Krajnjih korisnika koji se odnose na aktivni korisnički
            nalog na različitim elektronskim digitalnim servisima isključivo i
            jedino onda kada ove podatke obrađuje na osnovu pismenih uputstava
            rukovaoca u momentu kada je Krajnji korisnik lice zaposleno kod
            rukovaoca. SBT ne snosi odgovornost za obradu ovakvih podataka
            ukoliko je do obrade došlo na osnovu instrukcije rukovaoca nakon što
            je lice na koje se podaci odnose prestalo da bude zaposleno u
            kompaniji Klijenta, budući da SBT ne može i nije dužan samostalno da
            proverava status lica na koje se podaci odnose u kompaniji Klijenta.
          </span>
        </p>
        <h3>6. Svrha obrade i pravni osnov za obradu</h3>
        <p>
          <span>
            Klijent kao rukovalac u obradi Vaših podataka o ličnosti određuje
            svrhu i pravni osnov na osnovu kojeg se vrši obrada Vaših podataka o
            ličnosti. Obrada podataka se najčešće vrši na osnovu potpisanog
            ugovora o radu, na osnovu legitimnog interesa rukovaoca ili onda
            kada je obrada neophodna u cilju zaštite životno važnih interesa
            lica na koje se podaci odnose ili drugog fizičkog lica.
          </span>
        </p>
        <p>
          <span>
            Ako imate pitanja u vezi sa svrhom ili pravnim osnovom na osnovu
            kojeg Klijent kao rukovalac u obradi Vaših podataka obrađuje Vaše
            podatke o ličnosti ili Vam trebaju dodatne informacije u vezi sa
            ovim, molimo da se obratite Klijentu tj. Vašem poslodavcu kao
            rukovaocu u obradi Vaših podataka o ličnosti.
          </span>
        </p>

        <h3>7. Platforma</h3>
        <p>
          <span>
            Naša Platforma na osnovu Vašeg pristanka prikuplja određene podatke
            o ličnosti svih korisnika Platforme uz pomoć tehnologija koje
            Platforma koristi (
          </span>
          <i>
            <span>cookies/trackers</span>
          </i>
          <span>
            ) i skladišti ih na datotekama. Ukoliko želite da saznate više
            detalja o tome, uključujući to koje tačno podatke o ličnosti
            prikupljamo tom prilikom, molimo Vas da pročitate našu{" "}
          </span>
          <i>
            <a href="https://benefiti.rs/politikavebsajta.html" target="_blank">
              Politiku Platforme.
            </a>
          </i>
        </p>
        <h3>8. Rok čuvanja</h3>
        <p>
          <span>
            Sa jedne strane, onda kada Klijentima omogućavamo korišćenje naše
            Platforme i sa njom povezanih aktivnosti SBT čuva Vaše podatke o
            ličnosti tokom trajanja korišćenja Platforme od strane Klijenata i
            nakon toga tokom određenog vremenskog perioda kako bi se Klijentima
            u svojstvu rukovalaca omogućilo da povrate korisničke naloge
            Krajnjih korisnika. Nakon što profil Krajnjeg korisnika postane
            deaktiviran bez obzira na razlog za deaktiviranje od strane Krajnjeg
            korisnika, Klijent će doneti odluku o tome da se takvi podaci o
            ličnosti izbrišu ili anonimizuju, osim ako je zakonom propisana
            obaveza čuvanja podataka, a SBT će postupiti u skladu sa dobijenom
            instrukcijom.
          </span>
        </p>
        <p>
          <span>
            Sa druge strane, kada obrađujemo Vaše podatke o ličnosti kako bismo
            Klijentima omogućili isporučivanje odabranih Benefita Krajnjim
            korisnicima, SBT čuva Vaše podatke o ličnosti u strogo ograničenom
            vremenskom intervalu, koji obuhvata period od momenta kupovine
            Benefita od Partnera do momenta prenosa prava na Benefitima
            Klijentima. Nakon toga, Klijent će doneti odluku o tome da se takvi
            podaci o ličnosti izbrišu ili anonimizuju, osim ako je zakonom
            propisana obaveza čuvanja podataka, a SBT će postupiti u skladu sa
            dobijenom instrukcijom.
          </span>
        </p>
        <p>
          <span>
            Klijent kao rukovalac u obradi Vaših podataka o ličnosti i mi takođe
            zadržavamo Vaše podatke o ličnosti ukoliko je njihova obrada
            neophodna povodom podnošenja, ostvarivanja ili odbrane pravnog
            zahteva. U meri u kojoj je to moguće Vaš rukovalac će ograničiti
            obradu podataka u takve svrhe nakon prestanka ugovornog odnosa sa
            našom kompanijom.
          </span>
        </p>
        <p>
          <span>
            Za više informacija u vezi sa time na koji način i u kom trajanju se
            Vaši podaci o ličnosti čuvaju, kao i za više informacija u vezi sa
            Vašim pravom na brisanje i prenos podataka, molimo da kontaktirate
            Klijenta kao Vašeg rukovaoca.
          </span>
        </p>
        <h3>9. Integritet podataka o ličnosti</h3>
        <p>
          <span>
            Preduzećemo razumne korake kako bismo osigurali da su podaci o
            ličnosti koje obrađujemo pouzdani za upotrebu kojoj su namenjeni,
            tačni, potpuni i aktuelni. Vi ste odgovorni za tačnost svih podataka
            o lilnosti koje nam date. Uložićemo razumne napore da održimo
            tačnost i integritet dobijenih podataka o ličnosti i da ih po
            potrebi ažuriramo. 
          </span>
        </p>
        <h3>10. Bezbednost podataka o ličnosti</h3>
        <p>
          <span>
            Naša kompanija preduzima odgovarajuće bezbednosne mere u cilju
            zaštite Vaših podataka o ličnosti od pristupa i uvida koji nisu
            odobreni primenom ovog Obaveštenja, a u skladu sa ZZPL-om i GDPR-om.
          </span>
        </p>
        <p>
          <span>
            Sprovodimo odgovarajuće fizičke, proceduralne, tehničke,
            organizacione i kadrovske mere kako bismo dostigli odgovarajući nivo
            zaštite Vaših podataka o ličnosti koje obrađujemo. Mi primenjujemo
            najbolje industrijske standarde u zaštiti podataka. Zaštita se
            odnosi na gubitak, korišćenje protivno svrsi, neovlašćeni pristup i
            uvid, menjanje i uništenje tih podataka o ličnosti. Ipak nijedna
            mera bezbednosti ne može da garantuje da će lični podaci biti 100%
            zaštićeni, ali Benefiti u svakom momentu svojim kontrolisanim
            procesima i uz visoku odgovornost stalno unapređuju sve mere
            bezbednosti u cilju zaštite Vaših podataka o ličnosti.
          </span>
        </p>
        <h3>11. Vaša prava</h3>
        <p>
          <span>
            Kao lice na koje se podaci odnose možete da ostvarite određena prava
            prema Klijentu kao rukovaocu u odnosu na podatke o ličnosti koji se
            obrađuju o Vama.{" "}
          </span>
          <b>
            <i>
              Međutim, posebno naglašavamo da je potrebno da znate i budete
              jasno informisani o tome da je SBT samo obrađivač u odnosu na
              obradu Vaših podataka o ličnosti i da kao takav ne može da
              odgovori niti ispuni bilo koji zahtev za ostvarivanje Vaših prava
              kao lica na koja se podaci odnose, osim ukoliko nismo dobili takvu
              napismenu instrukciju.{" "}
            </i>
          </b>
          <span>
            Stoga, u slučaju da bilo kakav vid ovakvog zahteva za ostvarivanje
            Vaših prava bude upućen na našu adresu, mi ćemo ga u razumno
            najkraćem mogućem roku proslediti Klijentu kao rukovaocu.
          </span>
        </p>
        <p>
          <span>Imate sledeća prava:</span>
        </p>
        <ul>
          <li aria-level="1">
            <b>Informisanje:</b>
            <span>
              {" "}
              pravo da budete informisani o tome na koji način obrađujemo Vaše
              podatke o ličnosti;
            </span>
          </li>
          <li aria-level="1">
            <b>Pristup:</b>
            <span>
              {" "}
              pravo da zahtevate od nas pristup svim Vašim podacima o ličnosti
              koje obrađujemo;
            </span>
          </li>
          <li aria-level="1">
            <b>Ispravka/dopuna:</b>
            <span>
              {" "}
              pravo da tražite da se netačni ili nepotpuni podaci o ličnosti o
              Vama isprave bez odlaganja;
            </span>
          </li>
          <li aria-level="1">
            <b>Brisanje:</b>
            <span>
              {" "}
              pravo da zahtevate da se obrišu Vaši podaci o ličnosti bez
              odlaganja;
            </span>
          </li>
          <li aria-level="1">
            <b>Ograničenje:</b>
            <span>
              {" "}
              pravo da tražite ograničenje obrade Vaših podataka o ličnosti u
              određenim situacijama;
            </span>
          </li>
          <li aria-level="1">
            <b>Prigovor:</b>
            <span>
              {" "}
              pravo da izjavite prigovor na specifične obrade podataka o
              ličnosti;
            </span>
          </li>
          <li aria-level="1">
            <b>Prenosivost:</b>
            <span>
              {" "}
              pravo da zahtevate od nas da ove podatke prenesemo drugoj
              kompaniji, ako je obrada je zasnovana na pristanku ili na osnovu
              ugovora i obrada se vrši automatizovanim putem.
            </span>
          </li>
        </ul>
        <p>
          <span>
            Molimo Vas da sve zahteve za ostvarivanje Vaših prava prosledite na
            odgovarajuću adresu Klijenta. Tek ukoliko budu smatrali to
            adekvatnim Klijenti će u svojstvu rukovaoca nama uputiti pismenu
            instrukciju kako da postupimo sa Vašim zahtevom.
          </span>
        </p>
        <p>
          <span>
            U svakom trenutku ukoliko smatrate da su Vaša prava kao lica na koje
            se podaci odnose u bilo kom smislu, odnosno obimu ograničena ili
            povređena možete da podnesete pritužbu Povereniku za informacije od
            javnog značaja i zaštitu podataka o ličnosti.
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <span>Vaš Smart Benefit Technologies</span>
        </p>
        <br />

        <div className="checkbox-wrapper">
          <CheckboxInput
            isChecked={hasAcceptedTerms}
            onChange={() =>
              setHasAcceptedTerms(!hasAcceptedTerms ? true : false)
            }
          />
          <InputLabel
            label="I agree with the Privacy Policy"
            margin="0 0 0 10px"
            shouldHaveColon={false}
          />
        </div>
        <div className="action-buttons">
          <Button
            width="130px"
            onClick={() => handleAcceptPrivacyPolicy()}
            margin="20px 0 0 0"
            disabled={!hasAcceptedTerms}
          >
            Ok
          </Button>
          <Button
            width="130px"
            onClick={() => logout()}
            margin="20px 0 0 0"
            customClass="cancel-button"
          >
            Cancel
          </Button>
        </div>
      </div>
    </PrivacyPolicyStyles>
  );
};

PrivacyPolicy.propTypes = {
  acceptPrivacyPolicy: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    acceptPrivacyPolicy: () => dispatch(actionCreators.acceptPrivacyPolicy()),
    logout: () => dispatch(actionCreators.logout())
  };
};

export default connect(null, mapDispatchToProps)(PrivacyPolicy);
