import styled from 'styled-components'

const ProviderRowStyles = styled.div`
	border-bottom: 1px solid #d8d8d8;
		&:hover {
				transform: scale(30px);
				box-shadow: 2px 2px 10px 4px rgba(0, 0, 0, 0.25);
		}

		.providersRowContainer {
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-top: 1px solid #d8d8d8;
			padding: 30px;
			margin-top: -1px;
			cursor: pointer;
		}
		

		.imgAndTitle {
			display: flex;
			align-items: center;

			.imgContainer {
				min-height: 90px;
				min-width: 90px;
				border-radius: 4px;
				background: #1568bf;
				margin-right: 20px;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.providerNameAndCategory {
				.providerName {
					font-size: 20px;
					font-weight: 600;
					font-stretch: normal;
					font-style: normal;
					line-height: normal;
					letter-spacing: normal;
					color: #373737;
					margin: 0 0 5px 0;
				}

				.category {
					font-size: 16px;
					font-weight: normal;
					font-stretch: normal;
					font-style: normal;
					line-height: normal;
					letter-spacing: normal;
					color: #373737;
					text-transform: capitalize;
				}
			}
		}

		.rightSideContainer {
			width: 100px;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		/* .status {
			width: 100px;
			height: 30px;
			border-radius: 12.5px;
			border: solid 1px ${({ status }) => {
		switch (status) {
			case 'active':
				return	'#41c264;'
			case 'disabled':
				return '#e80000;'
			default:
				break
		}
	}};
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			color: ${({ status }) => {
		switch (status) {
			case 'active':
				return '#41c264'
			case 'disabled':
				return '#e80000'
			default:
				break
		}
	}};
			text-transform: capitalize;
		}

		.reviewsText {
			font-size: 14px;
			font-weight: 300;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #373737;
		}
	
			.usersContianer {
				width: 91px;

				.userNumber {
					font-size: 20px;
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: normal;
					letter-spacing: normal;
					color: #373737;
					height: 25px;
					display: flex;
					align-items: center;

				.newUsers {
					color: #41c264;
					padding-left: 5px;
					height: 25px;
					display: flex;
					align-items: center;

					.arrow {
						font-size: 23px;
						height: 25px;
						line-height: 25px;
						margin-bottom: 3px;
					}
				}

				.leftUsers {
					color: #e80000;	
					padding-left: 5px;
					height: 25px;
					display: flex;
					align-items: center;

					.arrow {
						font-size: 23px;
						height: 25px;
						line-height: 25px;
						margin-bottom: 3px;
					}
				}
			}	
			.label {
					font-size: 14px;
					font-weight: 300;
					font-stretch: normal;
					font-style: normal;
					line-height: normal;
					letter-spacing: normal;
					color: #373737;
				}	
		}	 */
`

export default ProviderRowStyles
