import React from "react";
import { connect } from "react-redux";

import styled from "styled-components";

import { Header } from "../../../components";

import * as actionCreators from "../../../actions/admin/companies.actions";

import { DashboardStatistics } from "./DashboardStatistics/DashboardStatistics";
import { useTranslation } from "react-i18next";
import ResetDates from "./ResetDates/ResetDates";
import { UserReport } from "./UserReport/UserReport";

const DashboardStyles = styled.section`
  width: calc(100% - 210px);
  margin-left: 210px;
  background: whitesmoke;

  .content {
    padding: 0 30px 30px 30px;
    height: 100vh;
  }

  .optionsContainer {
    width: 200px;
  }

  .title {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4b4b4b;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 20px;
  }

  .datePickerInput {
    height: 24px;
    width: 200px;
    outline: none;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    border: 1px solid #d8d8d8;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: center;
    color: #4b4b4b;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    z-index: 2;

    &:hover {
      border: 1px solid #1568bf;
      z-index: 2;
    }
  }

  .openResetDates {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 4px;
    border: solid 1px transparent;
    color: #1568bf;
    outline: #1568bf;
    background: #ffffff;
    outline-style: inset;
    outline-width: 0.5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    margin: 0;
    transition: 0.5s;
    gap: 10px;

    &:hover {
      background: #1568bf;
      color: white;
    }
  }

  .usageReportWrapper {
    position: relative;
    display: flex;
    justify-content: flex-end;
    gap: 15px;

    .openUsageReport {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      border-radius: 4px;
      border: solid 1px transparent;
      color: #1568bf;
      outline: #1568bf;
      background: #ffffff;
      outline-style: inset;
      outline-width: 0.5px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      margin: 0;
      transition: 0.5s;

      &:hover {
        background: #1568bf;
        color: white;
      }
    }
  }
`;

const Dashboard = ({ companies, setCompanies, user }) => {
  const { t } = useTranslation();

  return (
    <DashboardStyles>
      <Header headerTitle={t("Dashboard")} />
      <div className="content">
        <div className="usageReportWrapper">
          <ResetDates />
          <UserReport />
        </div>
        <DashboardStatistics />
      </div>
    </DashboardStyles>
  );
};

const mapStateToProps = state => {
  return {
    user: state.app.user,
    companies: state.companies.companies
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCompanies: companies => dispatch(actionCreators.setCompanies(companies))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
