import refundActions from "../../constants/actionTypes/hr/refunds.constants";
import { updateObject } from "../../services/updateObject";

const initialState = {
  listOfRefunds: [],
  filters: { page: 1, perPage: 15, status: undefined, branchCode: undefined },
  singleRefund: {},
  totalCount: 1,
  checkedPendingItems: [],
  branchCodes: [],
  toggleReimbursement: true
};

const refundsReducer = (state = initialState, action) => {
  switch (action.type) {
    case refundActions.SET_REFUNDS_LIST:
      return setRefunds(state, action);
    case refundActions.SET_REFUND:
      return setRefund(state, action);
    case refundActions.SET_TOTAL_COUNT:
      return setTotalCount(state, action);
    case refundActions.SET_FILTERS:
      return setFilters(state, action);
    case refundActions.SET_CHECKED_PENDING_ITEMS:
      return setCheckedPendingItems(state, action);
    case refundActions.SET_BRANCH_CODES:
      return setBranchCodes(state, action);
    case refundActions.SET_TOGGLE_REIMBURSEMENT:
      return setToggleReimbursement(state, action);
  }

  return state;
};

const setToggleReimbursement = (state, { payload }) => {
  return updateObject(state, { toggleReimbursement: payload });
};

const setBranchCodes = (state, { payload }) => {
  return updateObject(state, { branchCodes: payload });
};

const setCheckedPendingItems = (state, { payload }) => {
  return updateObject(state, { checkedPendingItems: payload });
};

const setFilters = (state, { payload }) => {
  return updateObject(state, { filters: payload });
};

const setTotalCount = (state, { payload }) => {
  return updateObject(state, { totalCount: payload });
};

const setRefunds = (state, { payload }) => {
  return updateObject(state, { listOfRefunds: payload });
};

const setRefund = (state, { payload }) => {
  return updateObject(state, { singleRefund: payload });
};

export default refundsReducer;
