class HrOnboardingPayload {
  payload = new FormData();

  constructor(data = {}) {
    data.tiers.forEach(tier => {
      this.payload.append("tiers", JSON.stringify(tier));
    });
    this.payload.append("csv", data.csvFile);
  }
}

export default HrOnboardingPayload;
