import React, { useRef, useState } from "react";
import { FilterIconSvg, TokenIcon } from "../../../../../assets/Icons";
import useClickOutside from "../../../../../hooks/useClickOutside";
import { useDispatch, useSelector } from "react-redux";
import { setFilters } from "../../../../../actions/hr/refunds.actions";
import { RefundStatusFilter } from "../../RefundStatusFilter";
import { RefundBranchCodeFilter } from "../../RefundBranchCodeFilter";
import { useTranslation } from "react-i18next";
import { transformTokenCurrencyIcons } from "../../../../../utils/transformTokensToRsd";

export const ListOfRefundsDesktopHeader = ({ handleStatus }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const statusRef = useRef(null);
  const branchRef = useRef(null);
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const [isBranchOpen, setIsBranchOpen] = useState(false);

  useClickOutside([statusRef], () => setIsStatusOpen(false));
  useClickOutside([branchRef], () => setIsBranchOpen(false));

  const { filters } = useSelector(state => state.refundsReducer);

  const handleOnClickStatus = status => {
    let payload = status;
    if (status === "all") payload = undefined;

    dispatch(setFilters({ ...filters, status: payload }));
  };

  return (
    <div className="refundListHeader" role="row">
      <div className="refundListHeaderEmpty" role="columnheader"></div>
      <div className="refundListHeaderDate" role="columnheader">
        <div className="refundListHeaderFilterWrapper">
          <div className="refundListHeaderFilterWrapperFlex">
            <span>{t("Branch")}</span>
            <button
              ref={branchRef}
              onClick={() => setIsBranchOpen(status => !status)}
              aria-haspopup="true"
              aria-expanded={isBranchOpen}
              aria-labelledby="statusFilterLabel"
            >
              <FilterIconSvg />
            </button>
          </div>
          {isBranchOpen && <RefundBranchCodeFilter style={{ top: "33px" }} />}
        </div>
      </div>
      <div className="refundListHeaderName" role="columnheader">
        {t("Name")}
      </div>
      <div className="refundListHeaderBenefit" role="columnheader">
        Benefit
      </div>
      <div className="refundListHeaderPrice" role="columnheader">
        {transformTokenCurrencyIcons()}
        {t("Price")}
      </div>
      <div className="refundListHeaderStatus" role="columnheader">
        <div className="refundListHeaderFilterWrapper">
          <div className="refundListHeaderFilterWrapperFlex">
            <span>{t("Status")}</span>
            <button
              ref={statusRef}
              onClick={() => setIsStatusOpen(status => !status)}
              aria-haspopup="true"
              aria-expanded={isStatusOpen}
              aria-labelledby="statusFilterLabel"
            >
              <FilterIconSvg />
            </button>
          </div>
          {isStatusOpen && (
            <RefundStatusFilter
              style={{ top: "33px", left: "-7px" }}
              handleOnClickStatus={handleOnClickStatus}
              handleStatus={handleStatus}
            />
          )}
        </div>
      </div>
      <div className="refundListHeaderDate" role="columnheader">
        {t("Date")}
      </div>
    </div>
  );
};
