import { apiService } from "./api.service";
import { setItem } from "../localStorage.service";
import {
  BENEFIT_TOKEN,
  BENEFIT_REFRESH_TOKEN,
  BENEFIT_ROLE
} from "../../constants/localStorage.constants";
import LoginResponse from "../../models/server/auth/login.response";
import User from "../../models/domain/User.entity";
import UserRole from '../../models/domain/UserRole.entity';
import { SystemNotificationsResponse } from "../../models/server/responses";

export const login = async payload => {
  let response = await apiService.post("/users/login", payload);

  if (response.hasError) return response;
  response = new LoginResponse(response);
  setItem(BENEFIT_TOKEN, response.accessToken);
  setItem(BENEFIT_REFRESH_TOKEN, response.refreshToken);
  setItem(BENEFIT_ROLE, response.user.authenticatedRole.key);

  return response;
};

export const azureLogin = async payload => {
  let response = await apiService.post("/users/login/azure", payload);

  if (response.hasError) return response;
  response = new LoginResponse(response);
  setItem(BENEFIT_TOKEN, response.accessToken);
  setItem(BENEFIT_REFRESH_TOKEN, response.refreshToken);
  setItem(BENEFIT_ROLE, response.user.authenticatedRole.key);

  return response;
}

export const logout = async () => {
  let response = await apiService.post("/users/logout");
  window.Intercom('shutdown');

  return response;
};

export const getUser = async () => {
  const response = await apiService.get("/users/me");
  if (response.hasError) return response;
  return new User(response.data);
};

export const resetPassword = async values => {
  const response = await apiService.post("/users/password/reset", values);

  if (response.hasError) return response;

  return response.data;
};

export const forgotPassword = async values => {
  const response = await apiService.post("/users/password/forgot", values);

  if (response.hasError) return response;

  return response.data;
};

/**
 * Returns unread system notifications.
 * @returns {SystemNotificationsResponse}
 */
export const getUnreadNotifications = async () => {
  const response = await apiService.get("/notifications");

  if (response.hasError) {
    return response;
  }

  return new SystemNotificationsResponse(response);
};

/**
 * Marks all system notifications for user as read.
 */
export const markAllAsRead = async () => {
  return await apiService.put("/notifications/mark-all-as-read");
};

/**
 * Marks system notification as read.
 * @param {number} id
 */
export const markAsRead = async id => {
  return await apiService.put(`/notifications/mark-as-read/${id}`);
};

export const acceptPrivacyPolicy = async () => {
  const response = await apiService.put(`/users/privacy-policy`);
  return response;
};

export const setAuthenticatedUserRole = async roleKey => {
  const response = await apiService.patch('/users/set-authenticated-role', { key: roleKey });
  if (response.hasError) {
    return response;
  }

  return new UserRole(response.data);
}
