import { formatToStartOfDay } from '../../../services/date.utils';
import { isEmpty, removeSpacesAndHTML } from '../../../services/general.utils';

class BonusCreatePayload {
	constructor(payload = {}) {
		this.id = payload.id || null;
		this.employees = payload.employees || [];
		this.userId = payload.userId || null;
		this.companyId = payload.companyId || null;
		this.subject = payload.subject ? removeSpacesAndHTML(payload.subject) : "";
		this.message = payload.message ? payload.message.replace(/\n/g, "\r\n") : "";
		this.tokens = payload.tokens || "";
		this.end_date = !isEmpty(payload.endDate) ? formatToStartOfDay(payload.endDate) : "";
		this.start_date = !isEmpty(payload.startDate) ? formatToStartOfDay(payload.startDate) : "";
		this.recurring = payload.recurring;
		this.recurring_type = payload.recurringType;
	}
}
export default BonusCreatePayload;
