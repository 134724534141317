import React from "react";
import ListOfRefundsMobileStyles from "./ListOfRefundsMobileStyles";
import { ListOfItemsRefundsMobile } from "./ListOfItemsRefundsMobile";

export const ListOfRefundsMobile = props => {
  const { listOfRefunds } = props;

  return (
    <ListOfRefundsMobileStyles>
      {listOfRefunds.map(refundItem => {
        return (
          <ListOfItemsRefundsMobile
            key={refundItem.id}
            refundItem={refundItem}
            {...props}
          />
        );
      })}
    </ListOfRefundsMobileStyles>
  );
};
