import React, { useEffect, useState, useRef } from "react";
import ReactTooltip from "react-tooltip";
import { toast } from "react-toastify";
import { FieldArray, Field } from "formik";
import PropTypes from "prop-types";
// assets
import {
  BenefitLogoIcon,
  TokenIcon,
  InfoIconSvg
} from "../../../../assets/Icons";
// components
import TierItem from "./TierItem";
import CurrencyField from "./CurrencyField";
// styles
import TiersFormStyles from "./TierFormStyles";
// services
import * as api from "../../../../services/api/hr/onboarding.service";
import { useTranslation } from "react-i18next";

const TiersForm = ({ values, errors }) => {
  const { t } = useTranslation();
  const [tokenValue, setTokenValue] = useState({ currency: "RSD", worth: 0 });
  const isMounted = useRef(true);

  const fetchTokenValue = async () => {
    const response = await api.getTokenValue();
    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : t("failed_to_get_token_value")
      );
    }
    if (isMounted.current) {
      setTokenValue(response);
    }
  };

  useEffect(() => {
    fetchTokenValue();

    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <TiersFormStyles>
      <div className="circleLogoContainer">
        <BenefitLogoIcon />
      </div>
      <h3 className="title">Benefiti</h3>
      <p className="text">{t("benefiti_welcome")}</p>
      <p className="text">{t("first_time_setup")}</p>
      <p className="text">{t("add_tiers_info")}</p>
      <p className="errorMsg" style={{ height: 21 }}>
        {errors && errors.tiers && typeof errors.tiers === "string"
          ? errors.tiers
          : ""}
      </p>
      <FieldArray name="tiers">
        {({ push, remove }) => (
          <>
            {values.tiers.length > 0 && (
              <>
                <div className="labelsContainer">
                  <div className="firstLabel" />
                  <div className="secondLabel">Value (RSD)</div>
                  <div className="thirdLabel">
                    {t("Tokens")}
                    <a data-tip data-for="sadFace">
                      {" "}
                      <InfoIconSvg />{" "}
                    </a>
                    <ReactTooltip
                      id="sadFace"
                      place="top"
                      effect="solid"
                      className="tooltip"
                      backgroundColor="#1568bf"
                      borderColor="#1568bf"
                      arrowColor="#1568bf"
                    >
                      <span>1 token = {tokenValue.worth} RSD</span>
                    </ReactTooltip>
                  </div>
                  <div className="forthLabel" />
                </div>
                <div className="tiersContainer" id="tiersContainer">
                  {values.tiers.map((tier, index) => (
                    // eslint-disable-next-line
                    <div className="tierItem" key={index}>
                      <Field
                        name={`tiers[${index}].name`}
                        component={TierItem}
                        type="text"
                        containerWidth={136}
                        cssClass="tierNameInput"
                        placeholder="Tier name"
                      />
                      <Field
                        name={`tiers[${index}].currency`}
                        component={CurrencyField}
                        tokenValue={tokenValue}
                        tokenValueName={`tiers[${index}].tokens`}
                      />
                      <Field
                        name={`tiers[${index}].tokens`}
                        component={TierItem}
                        currencyValueName={`tiers[${index}].currency`}
                        tokenValue={tokenValue}
                        cssClass="tokenValueInput"
                        label
                        Icon={<TokenIcon />}
                        labelName="tokens"
                      />

                      <div style={{ height: 42 }}>
                        <button
                          type="button"
                          onClick={() => remove(index)}
                          className="removeTierButton"
                        >
                          X
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}

            <button
              type="button"
              className="addTierButton"
              onClick={() => {
                push({ name: "", tokens: "", currency: "" });
                setTimeout(() => {
                  const tiersContainer = document.getElementById(
                    "tiersContainer"
                  );

                  if (tiersContainer) {
                    tiersContainer.scrollTop = tiersContainer.scrollHeight;
                  }
                }, 0);
              }}
            >
              <p style={{ margin: "0 10px 2px 0" }}>+</p>
              <p>{t("Add Tier")}</p>
            </button>
          </>
        )}
      </FieldArray>
    </TiersFormStyles>
  );
};

TiersForm.propTypes = {
  values: PropTypes.shape(),
  errors: PropTypes.shape()
};

export default TiersForm;
