import HrEmployee from "../../domain/HrEmployee.entity";

class HrEmployeesResponse {
  constructor(response = {}) {
    this.employees = response.data.employees?.map(
      employee => new HrEmployee(employee)
    );
    this.page = response.data.pages;
    this.count = response.data.count;
  }
}

export default HrEmployeesResponse;
