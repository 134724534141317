import { isEmpty } from '../../../services/general.utils';

export default class CompanyThemePayload {
    payload = new FormData();
  
    constructor(newTheme = {}) {
      this.payload.append("mode", newTheme.mode);
      this.payload.append("companyId", newTheme.companyId);
      this.payload.append("photo", newTheme.photo);
      !isEmpty(newTheme.color)
      ? this.payload.append("color", newTheme.color)
      : null;
    }
  }
