import React, { createContext, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'

import { initialState, localBenefitsReducer } from './localBenefits.reducer'
import { useHrLocalBenefitsActions } from './localBenefits.actions'

const HrLocalBenefitsContext =
	createContext({ ...initialState })


const HrLocalBenefitsProvider = ({ children }) => {
	const [localBenefitsState, dispatch] = useReducer(localBenefitsReducer, initialState)
	const localBenefitsActions = useHrLocalBenefitsActions(dispatch)

	useEffect(() => () => {}, [initialState])

	return (
		<HrLocalBenefitsContext.Provider
			value={{
				localBenefitsState,
				localBenefitsActions
			}}
		>
			{children}
		</HrLocalBenefitsContext.Provider>
	)
}

HrLocalBenefitsProvider.propTypes = {
	children: PropTypes.node
}

export { HrLocalBenefitsProvider, HrLocalBenefitsContext }
