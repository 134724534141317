import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

//styles
import TableRowActionsStyles from "./TableRowActionsStyles";
// Constants
import { UserRolesKeysEnum } from "../../../constants/roles.constants";
//services
import * as usersService from "../../../services/api/admin/users.service";
import { apiService } from "../../../services/api/api.service";
import { isAuthenticatedRole } from "../../../services/roles.utils";
import { useTranslation } from "react-i18next";

const TableRowAction = ({
  urlLink,
  baseUrl,
  deleteLink,
  resendVerificationLinkParam,
  removeRow,
  fetchData,
  hideTooltip,
  additionalLinkParams,
  deleteConfirmMessage,
  isShowActionVisable = true,
  isEditActionVisable = true,
  isDeleteActionVisable = true,
  shouldShowDeletePopup = false,
  openDisableUserPopup = () => {},
  isResendVerificationEnabled,
  deleteParam = null,
  isRowDisabled = false,
  openAddBonusModal,
  user
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleDisableUserPopupOpen = e => {
    e.stopPropagation();
    openDisableUserPopup(deleteParam);
  };

  /**
   * Handles user enabling.
   * Sends request to update user enabled status.
   * Refetches data.
   * @param {Event} e
   */
  const handleUserEnable = async e => {
    e.stopPropagation();

    const response = await usersService.enableUser(deleteParam);
    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : toast("failed_to_enable_user")
      );
    }

    toast.success(response.data.message);

    fetchData();
  };

  const resendVerificationLink = async e => {
    e.stopPropagation();
    const response = await usersService.resendVerificationLink(
      resendVerificationLinkParam
    );
    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : t("failed_to_send_ver_link")
      );
    }

    toast.success(response.data.message);
  };

  return (
    <TableRowActionsStyles>
      {isShowActionVisable && (
        <button
          type="button"
          className="button"
          onClick={e => {
            e.stopPropagation();
            history.push(`${baseUrl}/${urlLink}`);
          }}
        >
          {t("Show")}
        </button>
      )}
      {isEditActionVisable && (
        <button
          type="button"
          className="button"
          onClick={e => {
            e.stopPropagation();
            history.push(`${baseUrl}/edit/${urlLink}${additionalLinkParams}`);
          }}
        >
          Edit
        </button>
      )}
      {isAuthenticatedRole(user.authenticatedRole, UserRolesKeysEnum.HR) && (
        <button
          type="button"
          className="button"
          onClick={e => {
            e.stopPropagation();
            openAddBonusModal(
              { title: "Add Bonus", selectName: "employee" },
              Number(urlLink)
            );
          }}
        >
          Add bonus budget
        </button>
      )}
      {isResendVerificationEnabled && (
        <button
          type="button"
          className="button"
          onClick={resendVerificationLink}
        >
          Resend verification link
        </button>
      )}
      {isRowDisabled === "true" && (
        <button
          type="button"
          className="button"
          onClick={async e => handleUserEnable(e)}
        >
          Enable user
        </button>
      )}
      {(isShowActionVisable || isEditActionVisable) &&
        isDeleteActionVisable && <hr className="hr" />}
      {isDeleteActionVisable &&
        !shouldShowDeletePopup &&
        isRowDisabled === "false" && (
          <button
            type="button"
            className="button"
            onClick={async e => {
              e.stopPropagation();
              hideTooltip();
              if (deleteLink) {
                const result = confirm(deleteConfirmMessage);
                if (result) {
                  const response = await apiService.delete(
                    `${deleteLink}/${urlLink}`
                  );
                  if (response.hasError) {
                    return toast.error(
                      response.errorMessage
                        ? response.errorMessage
                        : t("failed_to_delete_user")
                    );
                  }

                  if (removeRow) {
                    removeRow(parseInt(urlLink, 0));
                  }
                  if (fetchData) {
                    fetchData();
                  }
                }
              }
            }}
          >
            Delete
          </button>
        )}
      {isDeleteActionVisable &&
        shouldShowDeletePopup &&
        isRowDisabled === "false" && (
          <button
            type="button"
            className="button"
            onClick={async e => handleDisableUserPopupOpen(e)}
          >
            Delete
          </button>
        )}
    </TableRowActionsStyles>
  );
};

TableRowAction.propTypes = {
  urlLink: PropTypes.string,
  baseUrl: PropTypes.string,
  deleteLink: PropTypes.string,
  removeRow: PropTypes.func,
  // rebuildTooltip: PropTypes.func,
  additionalLinkParams: PropTypes.string,
  fetchData: PropTypes.func,
  deleteConfirmMessage: PropTypes.string,
  hideTooltip: PropTypes.func,
  isShowActionVisable: PropTypes.bool,
  isEditActionVisable: PropTypes.bool,
  isDeleteActionVisable: PropTypes.bool
};
const mapStateToProps = state => {
  return {
    user: state.app.user
  };
};

export default connect(mapStateToProps)(TableRowAction);
