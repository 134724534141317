/* eslint-disable */

export const calculateTimeLeft = () => {
  let year = new Date().getFullYear();
  let month = new Date().getMonth() + 1;
  let difference = +new Date(`${month}/26/${year}`) - +new Date();
  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60)
    };
  }

  return Object.keys(timeLeft).length === 0
    ? "Submit in progress..."
    : `${timeLeft.days}d ${timeLeft.hours}h ${timeLeft.minutes}m ${timeLeft.seconds}s`;
};
