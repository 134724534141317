import React from 'react'
import styled from 'styled-components'

const EmployeesIconSvgStyles = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`

const EmployeesIconSvg = ({ fillColor = "#FFF", width = '16', height = '13' }) => (
	<EmployeesIconSvgStyles>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 18 13"
		>
			<path
				fill={fillColor}
				fillRule="evenodd"
				d="M7.918 10.921c-.09 0-.138-.024-.138-.114 0-.952 1.57-2.88 4.443-2.88 2.881 0 4.444 1.928 4.444 2.88 0 .09-.041.114-.13.114H7.918zm.375 1.099h7.869c1.14 0 1.685-.334 1.685-1.066 0-1.677-2.124-4.126-5.624-4.126-3.49 0-5.615 2.45-5.615 4.126 0 .732.545 1.066 1.685 1.066zm-6.91 0h4.85a1.09 1.09 0 01-.536-1.075H1.188c-.065 0-.106-.032-.106-.105 0-1.506 1.766-2.946 3.793-2.946.724 0 1.375.155 1.953.447a3.95 3.95 0 01.822-.773c-.814-.496-1.766-.748-2.775-.748C2.246 6.82 0 8.732 0 10.913c0 .74.464 1.107 1.383 1.107zm3.5-6.983c-.724 0-1.351-.708-1.351-1.57 0-.839.61-1.514 1.35-1.514.75 0 1.352.66 1.352 1.506 0 .87-.627 1.578-1.351 1.578zm7.349-.162c-.871 0-1.628-.847-1.628-1.913-.008-1.066.732-1.864 1.628-1.864.895 0 1.619.782 1.619 1.848s-.749 1.929-1.62 1.929zM4.882 6.12c1.327 0 2.426-1.205 2.426-2.661C7.308 2.019 6.234.87 4.883.87 3.548.87 2.45 2.043 2.45 3.467c0 1.456 1.106 2.653 2.433 2.653zm7.35-.147c1.53 0 2.799-1.375 2.799-3.027 0-1.652-1.253-2.946-2.8-2.946-1.546 0-2.807 1.318-2.807 2.962 0 1.644 1.27 3.011 2.808 3.011z"
			/>
		</svg>
	</EmployeesIconSvgStyles>
)

export default EmployeesIconSvg
