import * as actionTypes from "../../constants/actionTypes/hr/settings.constants";

export const setTiers = tiers => {
  return { type: actionTypes.SET_TIERS, tiers };
};

export const addTier = tier => {
  return { type: actionTypes.ADD_TIER, tier };
};

export const editTier = (tierId, tier) => {
  return { type: actionTypes.EDIT_TIER, tierId, tier };
};

export const removeTier = tierId => {
  return { type: actionTypes.REMOVE_TIER, tierId };
};
