import React, { useState } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { useFormik } from "formik";
// assets
import { CheckIcon } from "../../../../../assets/Icons";
// styles
import UpdateEmployeesWithCSVStyles from "./UpdateEmployeesWithCSV.styles";
// services
import { AddEmployeesWithCSVValidations } from "../../../../../services/validations/hr/addEmployeesWithCSV";
import { addHeightOnClick } from "../AddEmployeeWthCSV/addEmployeesCSV.services";
import * as employeesService from "../../../../../services/api/hr/employees.service";
import { BASE_CONTENT_URL } from "../../../../../constants/api.constants";
import { useDispatch } from "react-redux";
import { setListOfFailedToBulkUpdateUsers } from "../../../../../actions/hr/employees.actions";
import { useTranslation } from "react-i18next";

const UpdateEmployeesWithCSV = ({
  fetchEmployees,
  closeModal,
  openErrorPopup
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [step1Status, setStep1Status] = useState("active");
  const [step2Status, setStep2Status] = useState("inactive");
  const [step3Status, setStep3Status] = useState("inactive");
  const [disableButton, setDisableButton] = useState(false);

  const handleUpdateEmployeeError = ({ error }) => {
    const { data } = error;
    if (data) {
      if (data.errors && data.error) {
        openErrorPopup(data.error, data.errors.csv.errors);
      } else {
        openErrorPopup(data.error);
      }
    }
  };

  const updateEmployeesWithCSV = async values => {
    setDisableButton(true);
    const response = await employeesService.updateEmployeesFromCsv(values);

    if (response.data?.failedBonusUsers.length > 0) {
      dispatch(
        setListOfFailedToBulkUpdateUsers(response.data.failedBonusUsers)
      );
    }

    if (response.hasError) {
      setDisableButton(false);
      return handleUpdateEmployeeError(response);
    }

    setStep3Status("completed");
    fetchEmployees();
    setDisableButton(false);
    closeModal();
    toast.success(t("success_employees_edit"));
  };

  const formik = useFormik({
    initialValues: {
      csvFile: undefined
    },
    validate: AddEmployeesWithCSVValidations,
    onSubmit: updateEmployeesWithCSV
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    handleBlur
  } = formik;

  const downloadUpdateCSV = () => {
    window.location = `${BASE_CONTENT_URL}/content/update_example.csv`;
    setStep1Status("completed");
    setStep2Status("active");
    addHeightOnClick(document.getElementById("step2"));
    document.getElementById("step3Circle").style.transform =
      "translateY(-20px)";
    document.getElementById("step2Content").style.opacity = 1;
  };

  const uploadCSV = e => {
    if (e.target.files.length > 0) {
      setFieldValue(e.target.name, e.target.files[0]);
      setStep2Status("completed");
      setStep3Status("active");
      addHeightOnClick(document.getElementById("step3"));
      document.getElementById("step3Circle").style.transform =
        "translateY(-60px)";
      document.getElementById("step3Content").style.opacity = 1;
    }
  };

  return (
    <UpdateEmployeesWithCSVStyles
      steps={{ step1Status, step2Status, step3Status }}
    >
      <form
        style={{ minHeight: 300, marginBottom: 40 }}
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <h3 className="title">{t("update_employees")}</h3>
        <div className="employeesForm">
          <div className="step1Container">
            <div className="step1" id="step1">
              <div className="step1Circle">
                {step1Status === "completed" ? (
                  <CheckIcon width="35px" height="35px" />
                ) : (
                  1
                )}
              </div>
              <h3 className="step1Title">{t("download_template")}</h3>
              <p className="step1Text">{t("update_employees_info")}</p>
              <button
                type="button"
                className="downloadButton"
                onClick={() => downloadUpdateCSV()}
              >
                {t("download")}
              </button>
            </div>
          </div>
          <div className="step2Container">
            <div className="step2" id="step2">
              <div className="step2Circle">
                {step2Status === "completed" ? (
                  <CheckIcon width="35px" height="35px" />
                ) : (
                  2
                )}
              </div>
              <div className="step2Content" id="step2Content">
                <h3 className="step2Title">{t("step2_title_employees")}</h3>
                <p className="step2Text">{t("step2_text_1")}</p>
                <p className="step2TextTwo">{t("step2_text_2")}</p>
                <p className="requiredFields">
                  {t("required_fields_employees")}
                </p>
                <h3 className="step2Title">{t("upload_the_file")}</h3>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <button
                    onClick={() => {
                      document.getElementById("csv").click();
                    }}
                    onBlur={() => handleBlur("csvFile", true)}
                    className="uploadButton"
                    type="button"
                  >
                    {t("upload")}
                  </button>
                  <div style={{ marginLeft: 15 }}>
                    {values.csvFile && (
                      <p className="fileName">{values.csvFile.name}</p>
                    )}
                    {errors.csvFile && (
                      <p className="errorMsg" style={{ height: 10 }}>
                        {errors.csvFile && touched.csvFile
                          ? errors.csvFile
                          : ""}
                      </p>
                    )}
                  </div>
                </div>
                <input
                  type="file"
                  name="csvFile"
                  id="csv"
                  onClick={e => {
                    e.target.value = null;
                  }}
                  onChange={e => {
                    uploadCSV(e);
                  }}
                  style={{ display: "none" }}
                />
              </div>
            </div>
          </div>
          <div className="step3Container">
            <div className="step3" id="step3">
              <div className="step3Circle" id="step3Circle">
                {step3Status === "completed" ? (
                  <CheckIcon width="35px" height="35px" />
                ) : (
                  3
                )}
              </div>
              <div className="step3Content" id="step3Content">
                <h3 className="step3Title">{t("submit_the_file")}</h3>
                {disableButton ? (
                  <button className="finish" type="button" disabled>
                    {t("please_wait")}
                  </button>
                ) : (
                  <button className="finish" type="submit">
                    {t("submit_1")}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </UpdateEmployeesWithCSVStyles>
  );
};

UpdateEmployeesWithCSV.propTypes = {
  fetchEmployees: PropTypes.func,
  closeModal: PropTypes.func
};

export default UpdateEmployeesWithCSV;
