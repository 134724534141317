import styled from 'styled-components'

const EmployeeListItemExpandedStyles = styled.div`
  max-height: 800px;
  cursor: pointer;

  @keyframes expandListItem {
    from {
      transform: translateY(120px);
      height: 120px;
      opacity: 0;
    }
    to {
      transform: translateY(0);
      max-height: 800px;
      opacity: 1;
    }
  }

  .listItemExpanded {
    width: 100%;
    z-index: 2;
    transition: max-height 0.15s ease-out;  
    transition-delay: 1s;
    transform-origin: center;
    overflow: hidden;         
  }  

  .open {
    animation: expandListItem 0.4s ease-in;
    max-height: 2000px;
    border-bottom: 1px solid #F0F0F0;
  }

  .employeeDetails {
    display: flex;
    background: ${({backgroundOpen}) => backgroundOpen || '#FFF'};
    padding: 0 20px;
  }

  .genderAndLocation {
    display: flex;
    align-items: flex-start;
    width: 30%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #5F5F5F;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 30px;
    padding-left: 52px;
    
    & div:first-child {
      display: none;
    }
  }

  .expandedListItemLabel {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    color: #1D1D1D;
  }

  .expandedListItemDataContainer {
    display: flex;
    flex-direction: column;
    gap: 7px;
    margin-bottom: 10px;
  }

  .genderValue:first-letter {
    text-transform: uppercase;
  }

  .contactWrapper {
    width: 20%;
    display: flex;
    align-items: flex-start;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #5F5F5F;
    flex-direction: column;
    justify-content: flex-start;
    
    & div:first-child {
      display: none;
    }

    & div:nth-child(2) {
      display: none;
    }

    & div:last-child {
      display: none;
    }
  }

  .teamAndPositionWrapper {
    width: 13%;
    display: flex;
    align-items: flex-start;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #5F5F5F;
    flex-direction: column;
    justify-content: flex-start;

    & div:last-child {
      display: none;
    }
  }

  .birthDayAndEnrolldateWrapper, .bonusesWrapper {
    width: 16%;
    display: flex;
    align-items: flex-start;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #5F5F5F;
    flex-direction: column;
    justify-content: flex-start;
  }

  @media screen and (max-width: 1280px) {
    .genderAndLocation {
      width: 47%;

      & div:first-child {
        display: flex;
      }
    }

    .birthDayAndEnrolldateWrapper {
      display: none;
    }

    .teamAndPositionWrapper {
      & div:last-child {
        display: flex;
      }
    }

    .bonusesWrapper {
      width: 21%;
    }

    .contactWrapper {
      width: 13%;

      & div:last-child {
        display: flex;
      }
    }
  }

  @media screen and (max-width: 960px) {
    .genderAndLocation {
      width: 49%;
      padding-left: 0;
    }

    .bonusesWrapper {
      display: none;
    }

    .teamAndPositionWrapper {
      width: 18%;
    }

    .contactWrapper {

      & div:first-child {
        display: flex;
      }

      & div:nth-child(2) {
        display: flex;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .genderAndLocation {
      width: 54%;
      margin-left: 0;
    }
  }

  @media screen and (max-width: 500px) {   
    .expandedListItemLabel {
      font-size: 8px;
    }

    .genderAndLocation {
      width: calc(45%);
      display: inline-block;
      font-size: 10px;
      margin-right: 10px;

      span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;      
      }
    }

    .teamAndPositionWrapper {
      width: 35%;
      font-size: 10px;
    }

    .contactWrapper {
      font-size: 10px;
      width: 15%;

      & div:first-child {
        display: none;
      }
    }
  }
`

export default EmployeeListItemExpandedStyles;
