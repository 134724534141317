import React, { useEffect } from "react";
import PropTypes from "prop-types";

import ModalStyles from "./ModalStyles";

const Modal = ({
  children,
  closeModal,
  isCloseVisable = true,
  disableClose = false
}) => {
  useEffect(() => {
    const close = e => {
      if (e.key === "Escape" && !disableClose) {
        closeModal();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  return (
    <ModalStyles onClick={!disableClose ? closeModal : () => {}}>
      <div
        onClick={e => e.stopPropagation()}
        id="modalCard"
        className="cardContainer"
      >
        {isCloseVisable && (
          <button className="closeButton" type="button" onClick={closeModal}>
            X
          </button>
        )}

        {children}
      </div>
    </ModalStyles>
  );
};

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.elementType,
    PropTypes.array
  ]),
  closeModal: PropTypes.func,
  isCloseVisable: PropTypes.bool
};

export default Modal;
