import adminActionTypes from "../../constants/actionTypes/admin/admin.constants";

const setAdminFilters = payload => {
  return { type: adminActionTypes.SET_ADMIN_USER_FILTERS, payload };
};

const setAdminUserData = payload => {
  return { type: adminActionTypes.SET_ADMIN_USER_DATA, payload };
};

export { setAdminFilters, setAdminUserData };
