import styled from "styled-components";

const BenefitDetailsActionsStyles = styled.div`
  width: 100%;
  height: 100%;

  .tooltipLink {
    text-decoration: none;
    width: 100%;
    height: 34px;
  }

  .button {
    width: 100%;
    height: 34px;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 16px;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8f8f8f;
    text-transform: capitalize;

    &:hover {
      background: #1568bf;
      color: #fff;
    }
  }

  .hr {
    width: 100%;
    border: 0.25px solid #1568bf;
  }
`;

export default BenefitDetailsActionsStyles;
