export const budgetUtilizationConfig = {
  series: [0, 0],
  options: {
    chart: {
      type: "donut"
    },
    dataLabels: {
      enabled: false
    },
    colors: ["#0A243F", "#1B6B70"],
    fill: {
      type: "gradient"
    },
    legend: {
      show: false,
      position: "bottom",
      horizontalAlign: "left"
    },
    tooltip: {
      y: {
        // formatter(val) {
        // 	return `${val} ${renderToString(<TokenIcon height="16" />)}`
        // }
        formatter(val) {
          return `${val} currency`;
        }
      }
    },
    labels: ["Chosen month", "Previous month"]
  }
};
