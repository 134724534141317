import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import BenefitCard from "./BenefitCard";
import { openModalAnim } from "../../../../components";
import { openBenefitGroupModal } from "../../../../actions/employee/employeeBenefits.actions";
import { apiService } from "../../../../services/api/api.service";
import * as actionCreators from "../../../../actions/employee/employeeBenefits.actions";

const FavoriteBenefitsDisplayStyles = styled.div`
  .benefitList {
    display: flex;
    justify-content: center;
    max-width: 1440px;
    flex-wrap: wrap;
    margin: 30px 0 0 0;
  }
`;

export const ListOfFavoriteBenefits = props => {
  const dispatch = useDispatch();

  const { listOfFavoriteBenefits } = props;

  const openModal = async chosenBenefitGroup => {
    dispatch(openBenefitGroupModal(chosenBenefitGroup));

    const response = await apiService.get(
      `/favorites/check/benefits/${chosenBenefitGroup.id}`
    );

    let checkIfInFavoritesPayload = false;
    if (response.data.favorites?.id) checkIfInFavoritesPayload = true;

    dispatch(actionCreators.checkIfInFavorites(checkIfInFavoritesPayload));

    setTimeout(() => {
      openModalAnim();
    }, 100);
  };

  return (
    <FavoriteBenefitsDisplayStyles>
      <div className="benefitList">
        {listOfFavoriteBenefits.map(benefitGroup => {
          return (
            <BenefitCard
              benefitGroupId={benefitGroup.id}
              key={benefitGroup.id}
              benefitDetails={benefitGroup}
              onClick={() => openModal(benefitGroup)}
            />
          );
        })}
      </div>
    </FavoriteBenefitsDisplayStyles>
  );
};
