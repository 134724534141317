import styled from "styled-components";

const StyledHeader = styled.header`
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  position: relative;
  z-index: 2;

  .lngDropdown{
    position: absolute;
    right: 10px;

     .flagDropdownWrapper {
      width: unset;
      .shownFlag {
        box-shadow: none;
      }
    }
  }

  .tokenContainerMessage  { 
      margin-right:10px;
      color: #757575;
      
      b {
      margin-left: 3px;
    }
  }

  

  .memberHistory {
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 10px;
    border-radius: 4px;
    border: solid 1px transparent;
    outline: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    letter-spacing: normal;
    text-align: center;
    margin: 0;
    margin-right: 10px;
    color: #1568bf;
    border:0.5px solid #1568bf
    background: #fff;
    transition: 0.5s;

    svg {
      margin-right: 5px;
    }

    &:hover {
      background: #1568bf;
      color: #fff;
    }
  }

  .headerTitle {
    font-size: 22px;
    font-weight: 600;
    color: #1d3468;
    letter-spacing: 0px;
    line-height: 26.82px;
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .mobileTokens {
    display: none;
    background: #fff;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
    padding: 8px 10px 8px 10px;
    border-radius: 16px;

    .tooltip {
      /* height: 107px; */
      background: #282a3b !important;
      border: 1px solid #282a3b !important;
      padding: 0;
      border-radius: 4px;
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.85;
      letter-spacing: -0.22px;
      color: #ffffff;
      text-align: center;
      padding: 0 8px;
    }

    .tooltip:after {
      border-top: 8px solid #1568bf !important;
      margin-left: -3px !important;
      bottom:-7px;
    }

    .__react_component_tooltip.show {
      opacity: 0.9 !important;
      margin-top: -10px !important;
      margin-left: 0px !important;
      visibility: visible;
    }

    .__react_component_tooltip.show.tokenState {
      left: 299px !important;
      top: 14px !important;
      width: 336px;
    }
  }

  .tokenUserContainer {
    display: flex;
    align-items: center;
    cursor: pointer;

    .tokenContainer {
      display: flex;
      align-items: center;

      .desktopTokens {
        display: flex;
        align-items: center;
        border-radius: 16px;
        background: #fff;
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
          0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
        padding: 8px 10px 8px 10px;
      }
    }

    .sync {
      margin-left: 30px;
      border-radius: 16px;
      background: #fff;
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
      padding: 8px 8px 8px 16px;
      margin-right: 25px;
      //cursor: pointer;
      display: flex;
      align-items: center;
      visibility: hidden;
      // Hide Submit button
      //this is temporary until the designer gives us a better solution
      margin: 0;
      padding-left: 0;
      padding-right: 0;
    }

    .syncText {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #1568bf;
      margin-right: 13px;
    }

    .tokens {
      margin: 0 5px 0 10px;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #4b4b4b;
    }

    .tooltipContainer {
      margin: 0 0 0 0;

      .tooltip {
        background: white;
        width: 120px;
        border: 1px solid #1568bf;
        padding: 0;
        border-radius: 4px;
        font-size: 13px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.85;
        letter-spacing: -0.22px;
        color: #757575;
        text-align: center;
      }

      .tooltipRole {
        border: 1px solid #1568bf;
      }

      .tooltip:after {
        border-top: 8px solid #4b4b4b;
        margin-left: 1px !important;
        bottom:-7px;
      }

      .__react_component_tooltip.show {
        opacity: 0.9 !important;
        margin-top: -10px !important;
        margin-left: 0px !important;
        visibility: visible;
      }

      .__react_component_tooltip.show.tokenState {
        display: flex;
        justify-content: center;
        width: 336px;
      }

      .__react_component_tooltip.place-top:before {
        bottom: -18px;
        left: 50%;
        margin-left: 0px;
        border: 9px solid;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-bottom: 9px solid transparent;
        border-top: 9px solid #1568bf;
      }
    }
  }

  .avatarStatusContainer {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;

    .avatarContainer {
      border-radius: 50%;
      background: #1568bf;
      width: 34px;
      height: 34px;
      overflow: hidden;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      .nameInitials {
        color: #fff;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 500;
      }
    }

    .loginStatus {
      border-radius: 50%;
      background-color: #309282;
      position: absolute;
      border: 2px solid #f7f7f7;
      bottom: 0;
      right: 0;
      width: 9px;
      height: 9px;
      z-index: 5;
    }

    .olvyTarget {
      margin: 0 3px;
    }

    .logoutDropDown {
      cursor: auto;
      position: absolute;
      width: 380px;
      right: 0;
      top: 45px;
      border: 1px solid #ccc;
      border-color: rgba(255, 255, 255, 0.7);
      border-radius: 4px;
      box-shadow: 2px 5px 10px 2px #ccc;
      background: white;
      padding: 15px 0 15px 0;
      z-index: 9;
      display: flex;
      flex-direction: column;
      align-items: center;

      .logoutDropdownHeader {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;

        .avatarContainer {
          border-radius: 50%;
          background: #1568bf;
          width: 64px;
          height: 64px;
          overflow: hidden;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;

          .nameInitials {
            color: #fff;
            text-transform: uppercase;
            font-size: 27px;
            font-weight: 500;
          }
        }

        .userFullName {
          margin-top: 10px;
        }

        .userEmail {
          margin-top: 10px;
        }

        .userCompany {
          margin-top: 10px;
        }
      }

      .divider {
        width: 100%;
        margin: 10px 0 10px 0;
        background-color: #eee;
      }

      .fillContainer {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;

        .tooltipButton {
          cursor: pointer;
          width: 125px;
          background: white;
          color: black;
          padding: 5px 10px;
          font-size: 14px;
          font-weight: 500;
          padding: 10px 20px 10px 20px;
          border: 1px solid lightgrey;
          border-radius: 4px;

          &:hover {
            background: #eee;
          }
        }
      }

      .logoutDropdownFooter {
        padding: 10px 0 0 0;
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-evenly;

        a {
          text-decoration: none;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          color: #4b4b4b;
        }
      }
    }
  }

  @media only screen and (max-width: 767px) {
    padding: 34px 14px;
    height: unset;
    flex-wrap: ${({ wrap }) => wrap};

    .titleContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
    }

    .mobileTokens {
      display: flex;
      align-items: center;
      margin-top: 11px;

      .tooltip {
        left: 13px !important;
      }

      .tooltip:after {
        margin-left: -22px !important;
      }

      .tokens {
        margin: 0 5px 0 10px;
      }
    }

    .tokenUserContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .tokenContainer {
        display: flex;
        align-items: center;
        order: 2;

        .sync {
          margin-right: 0;
        }

        .desktopTokens {
          display: none;
        }
      }

      .tooltipContainer {
        margin: 0;
      }

      .tokens {
        margin: 0 0 0 10px;
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #4b4b4b;
      }
    }

    .mobile {
      width: 100%;

      .hamburgerIcon {
        position: absolute;
        left: 14px;
        top: 32px;
        cursor: pointer;

        svg {
          margin-right: 10px;

          &:first-child {
            margin-bottom: 5px;
          }
        }
      }
    }

    .switchPlaceholder {
      width: 70px;
      height: 20px;
    }

    .avatarStatusContainer {
      order: 1;
      margin: -25px 0px 10px 50px;
    }
  }

  @media only screen and (max-width: 356px) {
    padding: 34px 8px;

    .tokenUserContainer {
      .tokenContainer {
        .sync {
          margin-left: 0;
        }
      }
    }

    .mobileTokens {
      min-height: 41px;

      svg {
        width: 13px;
        height: 13px;
      }

      div svg {
        width: 9px;
        height: 9px;
        margin-top: 2px;
      }

      .tokens {
        margin: 0 -3px 0 5px;
        font-size: 0.7em;
      }
    }

    .avatarStatusContainer {
      margin-left: 0;

      .logoutDropDown {
        width: 100vw;
      }
    }
  }
`;

export default StyledHeader;
