import React from "react";
import { isEmpty } from "../../../../services/general.utils";

const EmployeeLocationUpdatedInfo = ({ notification }) => {
  const handleNotificationSubtitle = () => {
    return `"${notification.payload.removed_cities.map(city => city.name).join(", ")}" ${notification.payload.removed_cities.length > 1 ? 'have' : 'has'} been removed from your locations. `;
  }

  return (
    <>
      <h4 className="notification-title">
        There have been some changes regarding your location.
      </h4>
      {!isEmpty(notification.payload.removed_cities) && (<p className="notification-subtitle">
        {handleNotificationSubtitle()}
        You might have lost access to some benefits on these locations.
      </p>)}
      <p className="subtitle-description">
        Please check your benefits on My Benefits page and make sure your you are ok with these changes.
      </p>
    </>
  );
};

export default EmployeeLocationUpdatedInfo;
