import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { useFormik } from "formik";
// custom components
import { TextAndLine, Button, Card } from "../../../../components";
import BasicInfo from "../BasicInfoSection";
import Details from "../DetailsSection";
import Rank from "../RankSection";
// entity
import { HrEmployeeForm } from "../../../../models/domain";
// services
import { hrEmployeeValidations } from "../../../../services/validations/hr/hrEmployeeValidations";
import * as employeesService from "../../../../services/api/hr/employees.service";
import { useTranslation } from "react-i18next";

const AddEmployee = ({ closeForm, fetchEmployees }) => {
  const { t } = useTranslation();
  const [disableButton, setDisableButton] = useState(false);
  const isMounted = useRef(true);

  const closeAddEmployee = () => {
    const addEmployeeForm = document.getElementById("addEmployeeForm");
    const employeesList = document.getElementById("employeesList");
    if (addEmployeeForm && employeesList) {
      employeesList.classList.remove("hideEmployeeList");
      addEmployeeForm.classList.remove("showAddEmployeeForm");
    }
    setTimeout(() => {
      closeForm(false);
    }, 300);
  };

  const handleCreateEmployeeError = ({ error }) => {
    const { data } = error;
    if (data) {
      if (data.errors && data.error) {
        if (data.errors.firstName) {
          toast.error(
            `${data.error.message}: ${data.errors.firstName.keys[0]}`
          );
        }
        if (data.errors.bornDate) {
          toast.error(`${data.error.message}: ${data.errors.bornDate.keys[0]}`);
        }
        if (data.errors.firstDayAtWork) {
          toast.error(
            `${data.error.message}: ${data.errors.firstDayAtWork.keys[0]}`
          );
        }
        if (data.errors.lastName) {
          toast.error(`${data.error.message}: ${data.errors.lastName.keys[0]}`);
        }
        if (data.errors.tierId) {
          toast.error(`${data.error.message}: ${data.errors.tierId.keys[0]}`);
        }
        if (data.errors.email) {
          toast.error(`${data.error.message}: ${data.errors.email.keys[0]}`);
        }
        if (data.errors.cities) {
          toast.error(`${data.error.message}: ${data.errors.cities.keys[0]}`);
        }
      } else {
        toast.error(data.error.message);
      }
    }
  };

  const createEmployee = async values => {
    setDisableButton(true);

    const response = await employeesService.createEmployee(values);

    if (response.hasError) {
      setDisableButton(false);
      return handleCreateEmployeeError(response);
    }

    setDisableButton(false);
    toast.success(t("success_add_employee"));
    closeAddEmployee();
    fetchEmployees();
  };

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  const formik = useFormik({
    initialValues: new HrEmployeeForm(),
    validate: hrEmployeeValidations,
    onSubmit: createEmployee
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    handleChange,
    handleBlur
  } = formik;

  return (
    <Card
      boxShadow="
				0 2px 4px -1px rgba(0, 0, 0, 0.2),
				0 1px 10px 0 rgba(0, 0, 0, 0.12),
				0 4px 5px 0 rgba(0, 0, 0, 0.14)
			"
      paddingMobile="30px 15px"
    >
      <form onSubmit={handleSubmit} autoComplete="off">
        <TextAndLine title={t("basic_info")} />
        <BasicInfo
          isFormDisplay
          values={values}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
        />
        <TextAndLine title={t("Details")} />
        <Details
          isFormDisplay
          values={values}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
        />
        <TextAndLine title={t("Rank")} />
        <Rank
          isFormDisplay
          values={values}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
        />
        <div style={{ display: "flex" }}>
          {disableButton ? (
            <Button width="130px" disabled margin="0 30px 0 0">
              {t("please_wait")}
            </Button>
          ) : (
            <Button width="130px" type="submit" margin="0 30px 0 0">
              {t("Create")}
            </Button>
          )}
          <Button
            type="button"
            width="130px"
            onClick={closeAddEmployee}
            padding="10px 18px"
            margin="0"
            outline
          >
            {t("cancel")}
          </Button>
        </div>
      </form>
    </Card>
  );
};

AddEmployee.propTypes = {
  closeForm: PropTypes.func,
  fetchEmployees: PropTypes.func
};

export default AddEmployee;
