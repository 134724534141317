import React, { useState } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import { CheckIcon } from "../../../../assets/Icons";
import { addHeightOnClick } from "./addEmployeeWithCSV.services";

import AddEmployeesWithCSVStyles from "./AddEmployeeWithCSVStyles";
import { useTranslation } from "react-i18next";

const AddEmployeesWithCSV = ({
  values,
  setFieldValue,
  errors,
  touched,
  handleBlur,
  disableButton,
  stepStatusState,
  setStepStatusState
}) => {
  const { t } = useTranslation();
  const downloadCSV = () => {
    let validator = true;
    if (values.tiers.length === 0) {
      validator = false;
    }
    if (values.tiers.length > 0) {
      values.tiers.forEach(tier => {
        if (tier.name === "") {
          validator = false;
        }
        if (tier.tokens === "") {
          validator = false;
        }

        if (tier.currency === 0 || tier.currency === "0,00") {
          validator = false;
        }
      });
    }

    if (validator) {
      window.location =
        "https://app-backend.benefiti.rs/content/benefit_example.csv";
      setStepStatusState({
        ...stepStatusState,
        step1Status: "completed",
        step2Status: "active"
      });
      addHeightOnClick(document.getElementById("step2"));
      document.getElementById("step3Circle").style.transform =
        "translateY(-20px)";
      document.getElementById("step2Content").style.opacity = 1;
    } else {
      toast.warning(t("step_1_must_be_filled_warning"));
    }
  };

  const uploadCSV = e => {
    if (e.target.files.length > 0) {
      setFieldValue(e.target.name, e.target.files[0]);
      setStepStatusState({
        ...stepStatusState,
        step2Status: "completed",
        step3Status: "active"
      });
      addHeightOnClick(document.getElementById("step3"));
      document.getElementById("step3Circle").style.transform =
        "translateY(-60px)";
      document.getElementById("step3Content").style.opacity = 1;
    }
  };

  return (
    <AddEmployeesWithCSVStyles steps={{ ...stepStatusState }}>
      <h3 className="title">{t("add_employees")}</h3>
      <div className="employeesForm">
        <div className="step1Container">
          <div className="step1" id="step1">
            <div className="step1Circle">
              {stepStatusState.step1Status === "completed" ? (
                <CheckIcon width="35px" height="35px" />
              ) : (
                1
              )}
            </div>
            <h3 className="step1Title">{t("download_template")}</h3>
            <p className="step1Text">{t("download_template_info")}</p>
            <button
              type="button"
              className="downloadButton"
              onClick={() => downloadCSV()}
            >
              {t("download")}
            </button>
          </div>
        </div>
        <div className="step2Container">
          <div className="step2" id="step2">
            <div className="step2Circle">
              {stepStatusState.step2Status === "completed" ? (
                <CheckIcon width="35px" height="35px" />
              ) : (
                2
              )}
            </div>
            <div className="step2Content" id="step2Content">
              <h3 className="step2Title">{t("step2_title_employees")}</h3>
              <p className="step2Text">{t("step2_text_1")}</p>
              <p className="step2TextTwo">{t("step2_text_2")}</p>
              <p className="requiredFields">{t("required_fields_employees")}</p>
              <h3 className="step2Title">{t("step2_title_bonuses")}</h3>
              <p className="step2Text">{t("step2_text_3")}</p>
              <ul>
                <li className="step2TextTwo">{t("bonus_type_info")}</li>
                <li className="step2TextTwo">{t("bonus_start_date_info")}</li>
                <li className="step2TextTwo">{t("bonus_end_date_info")}</li>
                <li className="step2TextTwo">{t("bonus_type_default_info")}</li>
                <li className="requiredFields">
                  {t("required_fields_bonuses")}
                </li>
              </ul>
              <h3 className="step2Title">{t("upload_the_file")}</h3>
              <div style={{ display: "flex", alignItems: "center" }}>
                <button
                  onClick={() => {
                    document.getElementById("csv").click();
                  }}
                  onBlur={() => handleBlur("csvFile", true)}
                  className="uploadButton"
                  type="button"
                >
                  {t("upload")}
                </button>
                <div style={{ marginLeft: 15 }}>
                  {values.csvFile && (
                    <p className="fileName">{values.csvFile.name}</p>
                  )}
                  {errors.csvFile && (
                    <p className="errorMsg" style={{ height: 10 }}>
                      {errors.csvFile && touched.csvFile ? errors.csvFile : ""}
                    </p>
                  )}
                </div>
              </div>
              <input
                type="file"
                name="csvFile"
                id="csv"
                onClick={e => {
                  e.target.value = null;
                }}
                onChange={e => uploadCSV(e)}
                style={{ display: "none" }}
              />
            </div>
          </div>
        </div>
        <div className="step3Container">
          <div className="step3" id="step3">
            <div className="step3Circle" id="step3Circle">
              {stepStatusState.step3Status === "completed" ? (
                <CheckIcon width="35px" height="35px" />
              ) : (
                3
              )}
            </div>
            <div className="step3Content" id="step3Content">
              <h3 className="step3Title">{t("submit_the_file")}</h3>
              {disableButton ? (
                <button className="finish" type="button" disabled>
                  {t("please_wait")}
                </button>
              ) : (
                <button className="finish" type="submit">
                  {t("submit_1")}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </AddEmployeesWithCSVStyles>
  );
};

AddEmployeesWithCSV.propTypes = {
  values: PropTypes.shape(),
  errors: PropTypes.shape(),
  touched: PropTypes.shape(),
  setFieldValue: PropTypes.func,
  handleBlur: PropTypes.func,
  disableButton: PropTypes.bool
};

export default AddEmployeesWithCSV;
