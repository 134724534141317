import React from "react";
import { getIn } from "formik";
import PropTypes from "prop-types";

const TierItem = ({
  field: { name, value },
  form: { errors, touched, setFieldValue, handleChange, handleBlur },
  type,
  cssClass,
  containerWidth,
  placeholder,
  label = false,
  Icon,
  currencyValueName,
  tokenValue,
  labelName
}) => {
  const errorMessage = getIn(errors, name);
  const touchedInput = getIn(touched, name);

  const handleNumberInput = (e, previousValue) => {
    let inputValue = e.target.validity.valid
      ? parseInt(e.target.value, 0)
      : previousValue;
    if (isNaN(inputValue)) {
      inputValue = "";
    }
    setFieldValue(e.target.name, inputValue);
    setFieldValue(
      currencyValueName,
      inputValue === "" ? "" : inputValue * tokenValue.worth
    );
  };

  return (
    <div style={{ height: 57 }}>
      <p className="errorMsg" style={{ height: 10, marginBottom: 5 }}>
        {touchedInput ? errorMessage : ""}
      </p>
      <div className="tokensContainer" style={{ width: containerWidth }}>
        {Icon && (
          <div
            style={{ position: "relative", height: 24, left: 10, zIndex: 1 }}
          >
            {Icon}
          </div>
        )}
        <input
          className={cssClass}
          type={type}
          name={name}
          onBlur={handleBlur}
          onChange={e =>
            labelName === "tokens"
              ? handleNumberInput(e, value)
              : handleChange(e)
          }
          value={value}
          placeholder={placeholder}
        />
        {/* {label && <p className="label">{labelName}</p>} */}
      </div>
    </div>
  );
};

TierItem.propTypes = {
  field: PropTypes.shape(),
  form: PropTypes.shape(),
  type: PropTypes.string,
  cssClass: PropTypes.string,
  containerWidth: PropTypes.number,
  placeholder: PropTypes.string,
  labelName: PropTypes.string,
  label: PropTypes.bool,
  Icon: PropTypes.node,
  currencyValueName: PropTypes.string,
  tokenValue: PropTypes.shape()
};

export default TierItem;
