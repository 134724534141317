import { BenefitGroup, Provider, Benefit } from "../../models/domain";
import * as actionTypes from "../../constants/actionTypes/admin/providers.constants";
import { updateObject } from "../../services/updateObject";
import * as modelMapperService from "../../models/modelMapper.service";

const initalState = {
  providers: [],
  page: 1,
  count: 0,
  search: '',
};

const providersReducer = (state = initalState, action) => {
  switch (action.type) {
    case actionTypes.SET_INITAL_PROVIDERS:
      return setInitProviders(state, action);
    case actionTypes.SET_PROVIDERS_PAGE:
      return setProvidersPage(state, action);
    case actionTypes.SET_PROVIDERS_SEARCH:
      return setProvidersSearch(state, action);
    case actionTypes.EDIT_PROVIDER:
      return editProvider(state, action);
    case actionTypes.REMOVE_PROVIDER:
      return removeProvider(state, action);
    case actionTypes.ADD_BENEFIT_GROUP:
      return addBenefitGroup(state, action);
    case actionTypes.EDIT_BENEFIT_GROUP:
      return editBenefitGroup(state, action);
    case actionTypes.REMOVE_BENEFIT_GROUP:
      return deleteBenefitGroup(state, action);
    case actionTypes.ADD_BENEFIT:
      return addBenefit(state, action);
    case actionTypes.EDIT_BENEFIT:
      return editBenefit(state, action);
    case actionTypes.REMOVE_BENEFIT:
      return deleteBenefit(state, action);
  }

  return state;
};

const setInitProviders = (state, { providers, count }) => {
  return updateObject(state, {
    providers: providers,
    count: count
  });
};

const setProvidersPage = (state, { page }) => {
  return updateObject(state, {
    page: page
  });
};

const setProvidersSearch = (state, { search }) => {
  return updateObject(state, {
    search: search
  });
}

const editProvider = (state, { provider }) => {
  return updateObject(state, {
    providers: state.providers.map(prov =>
      prov.id === provider.id ? provider : prov
    )
  });
};

const removeProvider = (state, { id }) => {
  return updateObject(state, {
    providers: state.providers.filter(provider => provider.id !== id)
  });
};

const addBenefitGroup = (state, { providerId, benefitGroup }) => {
  return updateObject(state, {
    providers: state.providers.map(provider =>
      provider.id === providerId
        ? updateObject(provider, {
          benefitGroups: [...provider.benefitGroups, benefitGroup]
        })
        : provider
    )
  });
};

const editBenefitGroup = (state, { providerId, benefitGroup }) => {
  return updateObject(state, {
    providers: state.providers.map(provider =>
      provider.id === providerId
        ? updateObject(provider, {
          benefitGroups: provider.benefitGroups.map(group =>
            group.id === benefitGroup.id ? benefitGroup : group
          )
        })
        : provider
    )
  });
};

const deleteBenefitGroup = (state, { providerId, benefitGroupId }) => {
  return updateObject(state, {
    providers: state.providers.map(provider =>
      provider.id === providerId
        ? updateObject(provider, {
          benefitGroups: provider.benefitGroups.filter(
            benefitGroup => benefitGroup.id !== benefitGroupId
          )
        })
        : provider
    )
  });
};

const addBenefit = (state, { providerId, benefitGroupId, benefit }) => {
  return updateObject(state, {
    providers: state.providers.map(provider =>
      provider.id === providerId
        ? updateObject(provider, {
          benefitGroups: provider.benefitGroups.map(benefitGroup =>
            benefitGroup.id === benefitGroupId
              ? updateObject(benefitGroup, {
                benefits: [...benefitGroup.benefits, benefit]
              })
              : benefitGroup
          )
        })
        : provider
    )
  });
};

const editBenefit = (state, { providerId, benefitGroupId, benefit }) => {
  return updateObject(state, {
    providers: state.providers.map(provider =>
      provider.id === providerId
        ? updateObject(provider, {
          benefitGroups: provider.benefitGroups.map(benefitGroup =>
            benefitGroup.id === benefitGroupId
              ? updateObject(benefitGroup, {
                benefits: benefitGroup.benefits.map(ben =>
                  ben.id === benefit.id ? benefit : ben
                )
              })
              : benefitGroup
          )
        })
        : provider
    )
  });
};

const deleteBenefit = (state, { providerId, benefitGroupId, benefitId }) => {
  return updateObject(state, {
    providers: state.providers.map(provider =>
      provider.id === providerId
        ? updateObject(provider, {
          benefitGroups: provider.benefitGroups.map(benefitGroup =>
            benefitGroup.id === benefitGroupId
              ? updateObject(benefitGroup, {
                benefits: benefitGroup.benefits.filter(
                  benefit => benefit.id !== benefitId
                )
              })
              : benefitGroup
          )
        })
        : provider
    )
  });
};

export default providersReducer;
