import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import styled from "styled-components";

import Form from "./Form";

import { BenefitTag } from "../../../../../../modules/superAdmin/settings/settings.entity";
import { benefitTagValidations } from "../../../../../../services/validations/superAdmin/benefitTag.validations";
import * as benefitTagsService from "../../../../../../services/api/admin/benefitTags.service";

import * as actionCreators from "../../../../../../actions/admin/benefitTags.actions";
import { useTranslation } from "react-i18next";

const EditBenefitTagStyles = styled.div`
  padding: 20px;
  width: 400px;

  .title {
    margin-bottom: 20px;
  }
`;

const EditBenefitTag = ({ closeModal, chosenId, editBenefitTag }) => {
  const [benefitTag, setBenefitTag] = useState(new BenefitTag());
  const { t } = useTranslation();

  const handleEditBenefitTagError = ({ error }) => {
    const { data } = error;
    if (data.errors && data.error) {
      if (data.errors.name) {
        toast.error(`${data.error.message}: ${data.errors.name.keys[0]}`);
      }
      if (data.errors.description) {
        toast.error(
          `${data.error.message}: ${data.errors.description.keys[0]}`
        );
      }
    }
  };

  const updateBenefitTag = async values => {
    const response = await benefitTagsService.editBenefitTag(values, chosenId);
    if (response.hasError) {
      return handleEditBenefitTagError(response);
    }

    toast.success(t("success_update_tag"));
    editBenefitTag(response);
    closeModal();
  };

  const fetchBenefitTag = async () => {
    const response = await benefitTagsService.getBenefitTag(chosenId);
    if (response) {
      setBenefitTag(response);
    }
  };

  useEffect(() => {
    fetchBenefitTag();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: benefitTag,
    validate: benefitTagValidations,
    onSubmit: updateBenefitTag
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur
  } = formik;

  return (
    <EditBenefitTagStyles>
      <h2 className="title">{t("edit_benefit_tag")}</h2>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Form
          values={values}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          handleChange={handleChange}
          buttonText="Update"
        />
      </form>
    </EditBenefitTagStyles>
  );
};

EditBenefitTag.propTypes = {
  closeModal: PropTypes.func,
  chosenId: PropTypes.number
};

const mapDispatchToProps = dispatch => {
  return {
    editBenefitTag: benefitTag =>
      dispatch(actionCreators.editBenefitTag(benefitTag))
  };
};

export default connect(undefined, mapDispatchToProps)(EditBenefitTag);
