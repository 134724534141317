import React from "react";
import PreviewHRBenefitStyles from "./PreviewHRBenefitStyles";
import { Header } from "../../../components";
import { useTranslation } from "react-i18next";
import { PreviewHRBenefitBody } from "./PreviewHRBenefitBody/PreviewHRBenefitBody";
import { useSelector } from "react-redux";

const PreviewHRBenefit = () => {
  const { t } = useTranslation();

  const benefitGroup = useSelector(
    state => state.benefits.editPageState.benefitGroup
  );

  return (
    <PreviewHRBenefitStyles>
      <div className="previewHrBenefitsContainer">
        <Header headerTitle={t("benefit_preview", { name: "" })} />
        <PreviewHRBenefitBody benefitGroup={benefitGroup} />
      </div>
    </PreviewHRBenefitStyles>
  );
};

export default PreviewHRBenefit;
