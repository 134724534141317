import React from "react";
import { useMediaQuery } from "../../../../hooks/useMediaQuery";
import { ListOfRefundsDesktop } from "./Desktop/ListOfRefundsDesktop";
import { ListOfRefundsMobile } from "./Mobile/ListOfRefundsMobile";

export const RefundListIndex = props => {
  const isMobileView = useMediaQuery("(max-width: 576px)");

  const showComponents = () => {
    return isMobileView ? (
      <ListOfRefundsMobile {...props} />
    ) : (
      <ListOfRefundsDesktop {...props} />
    );
  };

  return <>{showComponents()}</>;
};
