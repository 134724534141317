import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Button from '../../button'
import { DotsIcon } from '../../../assets/Icons'

const SettingsTableRowStyles = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: #f7f7f7;
	border-radius: 4px;
	height: 42px;
	margin-bottom: 30px;
	overflow: hidden;
	
	&:last-child {
		margin-bottom: 0;
	}

	.inputTextContainer {
		display: flex;
		width: 117px; 
		height: 42px;
		justify-content: center; 
		align-items: center; 
		position: relative;
	}

	.text {
		width: 117px;
		text-align: center;
		font-size: 14px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: center;
		color: #8f8f8f;
		transition: all 0.4s;
		position: absolute;
		z-index: 2;
	}

	
	.dotsContainer {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 117px;
	}

	.inputsTest {
		opacity: 0; 
		left: -100px;
		position: absolute;
		transition: all 0.4s;
		width: 100px;
		border-radius: 4px;
		height: 24px;
		border: solid 1px #979797;
		padding: 0 5px;
		font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
		color: #5d5d5d;
		outline: none;
		text-align: center;
	}

	.inputsTest::-webkit-outer-spin-button,
.inputsTest::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

	.button {
		transition: all 0.8s;
		position: absolute;
		z-index: 2;
		width: 113px;
		height: 42px;
		border-radius: 4px;
		background: #1568bf;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
	}

	.submitButton {
		height: 42px;
		width: 80px;
		border: none;
		background: none;
		outline: none;
		color: #fff;
		font-size: 14px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: center;
	}

	.cancelButton {
		height: 42px;
		width: 34px;
		border: none !important;
		background: red;
		outline: none;
		color: #fff;
		font-size: 14px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: center;
		cursor: pointer;
	}
`
// tier: 'Tier 2',
// 	tokens: 20,
// 		tierUsers: 25,
// 			description: 'Junior'

const SettingsTableRow = ({
	tier,
	tokens,
	tierUsers,
	description,
	onClick,
	buttonContainerId,
	rowId,
	isFormDisplay,
	cancelEdit,
	getTier,
	onChange
}) => (
	<SettingsTableRowStyles rowId={rowId}>
		<div className="inputTextContainer">
			<div id={buttonContainerId} className="button">
				{
					!isFormDisplay ?
						<Button margin="0" width="113px" height="42px" onClick={onClick}>
							{tier}
						</Button>
						:
						<>
							<button type="button" className="submitButton" onClick={getTier}>Update</button>
							<button type="button" className="cancelButton" onClick={cancelEdit}>X</button>
						</>
				}
			</div>
			<input
				type="text"
				name="tier"
				onChange={(e) => onChange(rowId, e.target.name, e.target.value)}
				className="inputsTest"
				id={`input1${rowId}`}
				value={tier}
			/>
		</div>
		<div className="inputTextContainer">
			<p className="text" id={`text1${rowId}`}>{tokens}</p>
			<input
				type="text"
				pattern="/^[\s\d]+$/"
				name="tokens"
				onChange={(e) => onChange(rowId, e.target.name, Number(e.target.value))}
				className="inputsTest"
				id={`input2${rowId}`}
				value={tokens}
			/>
		</div>
		<div className="inputTextContainer">
			<p className="text" id={`text2${rowId}`}>{tierUsers}</p>
			<input
				type="text"
				pattern="^[0-9]+$|^$"
				name="tierUsers"
				onChange={(e) => onChange(rowId, e.target.name, Number(e.target.value))}
				className="inputsTest"
				id={`input3${rowId}`}
				value={tierUsers}
			/>
		</div>
		<div className="inputTextContainer">
			<p className="text" id={`text3${rowId}`}>{description}</p>
			<input
				type="text"
				name="description"
				onChange={(e) => onChange(rowId, e.target.name, e.target.value)}
				className="inputsTest"
				id={`input4${rowId}`}
				value={description}
			/>
		</div>
		<div className="dotsContainer">
			<DotsIcon />
		</div>
	</SettingsTableRowStyles>
)

SettingsTableRow.propTypes = {
	tier: PropTypes.string,
	tokens: PropTypes.number,
	tierUsers: PropTypes.number,
	description: PropTypes.string,
	onClick: PropTypes.func,
	cancelEdit: PropTypes.func,
	getTier: PropTypes.func,
	onChange: PropTypes.func,
	buttonContainerId: PropTypes.string,
	rowId: PropTypes.number,
	isFormDisplay: PropTypes.bool
}

export default SettingsTableRow
