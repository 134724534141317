import React from "react";
import { ListOfRefundsDesktopBody } from "./ListOfRefundsDesktopBody";

export const ListOfRefundsDesktop = props => {
  const {
    handleRefundOpen,
    listOfRefunds,
    handleStatus,
    handleCheckedOnChange,
    checkedPendingItems
  } = props;

  const listOfRefundsDesktopBodyProps = {
    listOfRefunds,
    handleRefundOpen,
    handleCheckedOnChange,
    handleStatus,
    checkedPendingItems
  };

  return (
    <div className="refundsList" role="table">
      <ListOfRefundsDesktopBody {...listOfRefundsDesktopBodyProps} />
    </div>
  );
};
