import { toast } from "react-toastify";
import supabaseClient from "./supabaseClient";

async function downloadAllFiles(folderName, setIsRefundDownloading) {
  let offset = 0;
  let allFiles = [];
  let hasMore = true;

  const toastId = toast.info(
    "Images are downloading please be patient, it can take up to 15-20 minutes depending on file size.",
    {
      autoClose: false,
      position: "top-center"
    }
  );

  while (hasMore) {
    const { data: files, error } = await supabaseClient.storage
      .from("refund-upload")
      .list(folderName, {
        limit: 500,
        offset: offset
      });

    if (error) {
      setTimeout(() => {
        toast.update(toastId, {
          type: toast.TYPE.ERROR,
          render: "Error with loading images.",
          autoClose: 5000
        });
        setIsRefundDownloading(false);
      }, 5000);

      console.error("Error with loading images.", error);
      return null;
    }

    hasMore = !!files.length;

    allFiles = allFiles.concat(files);
    offset += files.length;
  }

  if (allFiles.length === 0) {
    setTimeout(() => {
      toast.update(toastId, {
        type: toast.TYPE.INFO,
        render: "No images uploaded.",
        autoClose: 5000
      });
      setIsRefundDownloading(false);
    }, 5000);
    return null;
  }

  return { allFiles, toastId };
}

export default downloadAllFiles;
