import styled from "styled-components";

const ModalStyles = styled.div`
  height: 100vh;
  width: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 1000;

  .cardContainer {
    position: relative;
    animation: showModal 0.4s ease-in-out;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #ffffff;
    transition: all 0.35s ease-in-out;
    max-height: 700px;
    overflow: auto;
  }

  @keyframes showModal {
    from {
      transform: translateY(50px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .anim {
    transform: translateY(0);
    opacity: 1;
  }

  .closeButton {
    width: 40px;
    height: 40px;
    border: none;
    outline: none;
    font-size: 20px;
    position: absolute;
    z-index: 2;
    right: 20px;
    top: 10px;
    font-weight: 500;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 50%;
  }
  .closeButton:hover {
    color: #1568bf;
    background: rgba(255, 255, 255, 0.8);
  }

  @keyframes showModal {
    from {
      transform: translateY(50px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @media only screen and (max-width: 767px) {
    .cardContainer {
      overflow-y: scroll;
      -ms-overflow-style: none;
    }

    .cardContainer::-webkit-scrollbar {
      display: none;
    }
  }
`;

export default ModalStyles;
