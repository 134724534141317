import { removeSpacesAndHTML } from '../../../services/general.utils'

class CreateBenefitGroupReviewPayload {
    constructor(payload = {}) {
        this.benefitGroupId = payload.benefitGroupId;
        this.benefitId = payload.benefitId;
        this.rating = payload.rating;
        this.comment = payload.comment ? removeSpacesAndHTML(payload.comment) : '';
    }
  }  
  export default CreateBenefitGroupReviewPayload;
