import styled from 'styled-components'

const BenefitGroupRowStyles = styled.div`
		border-bottom: 1px solid #d8d8d8;
		
		&:hover {
				transform: scale(30px);
				box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
					0 1px 10px 0 rgba(0, 0, 0, 0.12),
					0 4px 5px 0 rgba(0, 0, 0, 0.14);
		}


	.benefitRowContainer {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-top: 1px solid #d8d8d8;
		padding: 30px;
		margin-top: -1px;
		width: 100%;
		cursor: pointer;
	}

	.imgAndTitle {
			display: flex;
			align-items: center;
			width: 320px;

			.imgContainer {
				min-height: 90px;
				min-width: 90px;
				max-height: 90px;
				max-width: 90px;
				border-radius: 4px;
				background: #1568bf;
				margin-right: 20px;
				display: flex;
				align-items: center;
				justify-content: center;
				overflow: hidden;

				.image {
					object-fit: cover;
				}
			}

			.providerNameAndCategory {
				.providerName {
					font-size: 20px;
					font-weight: 600;
					font-stretch: normal;
					font-style: normal;
					line-height: normal;
					letter-spacing: normal;
					color: #373737;
					margin: 0 0 5px 0;
				}

				.category {
					font-size: 16px;
					font-weight: normal;
					font-stretch: normal;
					font-style: normal;
					line-height: normal;
					letter-spacing: normal;
					color: #373737;
					text-transform: capitalize;
				}
			}
		}

		.actionsContainer {
			display: flex;
			width: 140px;
			justify-content: space-between;
		}
`

export default BenefitGroupRowStyles
