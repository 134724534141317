import styled from 'styled-components'

const NotificationsStyles = styled.section`
	.notificationsContainer {
		width: calc(100% - 210px);
		margin-left: 210px;
		background: #f7f7f7;
		min-height: 100vh;
		overflow-x: hidden;
	}

	.notificationsContent {
		padding: 0 30px 30px 30px;
	}

	.title {
		width: 100%;
		font-size: 24px;
    font-weight: 600;
    color: #4b4b4b;
		letter-spacing: 0px;
		text-align: center;
		margin-bottom: 30px;
	}

	.inputContainer {
		margin: 0 0 10px 0;
	}

	.textArea {
		width: 100%;
		min-width: 100%;
		max-width: 100%;
		height: 200px;
		min-height: 200px;
		max-height: 200px;
		border-radius: 4px;
		border: solid 1px #979797;
		padding: 5px 10px 5px 5px;
		font-size: 14px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		-webkit-letter-spacing: normal;
		-moz-letter-spacing: normal;
		-ms-letter-spacing: normal;
		letter-spacing: normal;
		color: #5d5d5d;
		outline: none;
	}

	.react-dropdown-select {
		min-height: 27px !important;
		height: 100% !important;
		width: 100%;
		border-radius: 4px;
		border: 1px solid #979797;
		box-shadow: none !important;
		border-color: #979797 !important;
		cursor: pointer;
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #5d5d5d;
		display: flex;
		align-items: center;
		


		
		.react-dropdown-select-content {
			height: 29px;
			flex-wrap: nowrap;
			text-overflow: ellipsis;
			overflow-x: hidden;
			width: 70px;
			white-space: nowrap;

			input {
				
				font-size: 12px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: normal;
				color: #5d5d5d;
				overflow-x: scroll;
			}
		}
	}

	.react-dropdown-select-dropdown {
		max-height: 150px;
	}


	.itemContainer {

		&:hover {
			background: #1568bf;

			.dropDownItem {
				color: #fff;
			}
		}

		.dropDownItem {
			font-size: 12px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #5d5d5d;
		}
	}
	
	.errorMsg {
		font-size: 14px;
		font-weight: 300;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		color: red;
	}

	.notificationActionsSection {
		display: flex;
		align-items: center;
		gap: 40px;
		margin: 15px 0;
	}
`

export default NotificationsStyles
