import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFilters } from "../../../actions/hr/refunds.actions";
import { useTranslation } from "react-i18next";

export const RefundBranchCodeFilter = ({ style }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { branchCodes, filters } = useSelector(state => state.refundsReducer);

  const handleOnClickBranchCode = branchCodeId => {
    let payload = branchCodeId;
    if (branchCodeId === "all") payload = undefined;

    dispatch(setFilters({ ...filters, branchCode: payload }));
  };

  return (
    <div className="refundFilter" role="menu" style={style}>
      <div className="arrow"></div>
      <div className="refundFilterItem" role="menuitem">
        <div
          className="refundFilterItemFlexCenter"
          onClick={e => {
            e.stopPropagation();
            handleOnClickBranchCode("all");
          }}
        >
          <span>{t("Show All")}</span>
        </div>
      </div>
      {branchCodes.map(branchCode => {
        return (
          <div key={branchCode.id} className="refundFilterItem" role="menuitem">
            <div
              className="refundFilterItemFlex"
              style={{ justifyContent: "center" }}
              onClick={e => {
                e.stopPropagation();
                handleOnClickBranchCode(branchCode.id);
              }}
            >
              <span>{branchCode.branchCode}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};
