class BenefitChangedPriceSystemNotificationPayload {
  constructor(systemNotificationPayloadData = {}) {
    this.id = systemNotificationPayloadData.id || "";
    this.title = systemNotificationPayloadData.title || "";
    this.tokenPrice =
      systemNotificationPayloadData.tokenPrice ||
      systemNotificationPayloadData.token_price ||
      "";
  }
}

export default BenefitChangedPriceSystemNotificationPayload;
