import { Tag } from "../../models/domain";
import * as actionTypes from "../../constants/actionTypes/admin/companyTags.constants";
import { updateObject } from "../../services/updateObject";

const initalState = {
  tags: []
};

const companyTagsReducer = (state = initalState, action) => {
  switch (action.type) {
    case actionTypes.SET_COMPANY_TAGS:
      return setCompanyTags(state, action);
    case actionTypes.ADD_COMPANY_TAG:
      return addCompanyTag(state, action);
    case actionTypes.EDIT_COMPANY_TAG:
      return editCompanyTag(state, action);
    case actionTypes.DELETE_COMPANY_TAG:
      return deleteCompanyTag(state, action);
  }

  return state;
};

const setCompanyTags = (state, action) => {
  return updateObject(state, {
    tags: action.companyTags.tags.map(companyTag =>
      typeof companyTag === Tag ? companyTag : new Tag(companyTag)
    )
  });
};

const addCompanyTag = (state, action) => {
  return updateObject(state, {
    tags: [...state.tags, new Tag(action.companyTag)]
  });
};

const editCompanyTag = (state, action) => {
  return updateObject(state, {
    tags: state.tags.map(tag =>
      tag.id === action.companyTag.id ? new Tag(action.companyTag) : tag
    )
  });
};

const deleteCompanyTag = (state, action) => {
  return updateObject(state, {
    tags: state.tags.filter(tag => tag.id !== action.id)
  });
};

export default companyTagsReducer;
