import { apiService } from "../api.service";
import { ProvidersResponse } from "../../../models/server/responses";
import {
  ProviderPayload,
  BenefitGroupPayload,
  BenefitPayload
} from "../../../models/server/payloads";
import { BenefitGroup, Benefit, Provider } from "../../../models/domain";
import { isEmpty } from "../../general.utils";

export const getInitProviders = async ({ limit, page, searchText, order, key, categoryIds }) => {
  let categoriesQuery = "";
  let queryParams = "";

  if (limit) {
    queryParams += `limit=${limit}`;
  }

  if (page) {
    queryParams += `${!isEmpty(queryParams) ? "&" : ""}page=${page}`;
  }


  if (key && order) {
    queryParams += `${!isEmpty(key) && !isEmpty(order) ? "&" : ""}order=${key} ${order}`;
  }

  if (!isEmpty(categoryIds) && Array.isArray(categoryIds)) {
    categoriesQuery = categoryIds.reduce(
      (query, category) =>
        `${!isEmpty(query) ? `${query}&` : ""}category_id=${category}`,
      ""
    );
  }

  if (!isEmpty(categoriesQuery)) {
    queryParams += `${!isEmpty(queryParams) && "&"}${categoriesQuery}`;
  }

  const response = await apiService.get(
    `/provider?${queryParams}&search=${searchText}`
  );

  if (response.hasError) return response;

  return new ProvidersResponse(response);
};

export const createProvider = async values => {
  let payloadData = new ProviderPayload(values);

  const response = await apiService.post(
    "/provider/create",
    payloadData.payload
  );

  if (response.hasError) return response;

  return new Provider(response.data);
};

export const editProvider = async (values, providerId) => {
  let payloadData = new ProviderPayload(values);

  const response = await apiService.put(
    `/provider/update/${providerId}`,
    payloadData.payload
  );

  if (response.hasError) return response;

  return new Provider(response.data);
};

export const deleteProvider = async providerId => {
  const response = await apiService.delete(`/provider/${providerId}`);

  return response;
};

export const addBenefitGroup = async (providerId, values) => {
  const payloadData = new BenefitGroupPayload(providerId, values);

  const response = await apiService.post(
    "/benefit-group/create",
    payloadData.payload
  );

  if (response.hasError) return response;

  return new BenefitGroup(response.data);
};

export const editBenefitGroup = async (providerId, benefitGroupId, values) => {
  const payloadData = new BenefitGroupPayload(providerId, values);

  const response = await apiService.put(
    `/benefit-group/${benefitGroupId}`,
    payloadData.payload
  );

  if (response.hasError) return response;

  return new BenefitGroup(response.data);
};

export const deleteBenefitGroup = async benefitGroupId => {
  const response = await apiService.delete(`/benefit-group/${benefitGroupId}`);

  return response;
};

export const addBenefit = async (benefitGroupId, values) => {
  const payloadData = new BenefitPayload(benefitGroupId, values);

  const response = await apiService.post(
    "/benefit/create",
    payloadData.payload
  );

  if (response.hasError) return response;

  return new Benefit(response.data);
};

export const editBenefit = async (
  providerId,
  benefitGroupId,
  benefitId,
  values
) => {
  const payloadData = new BenefitPayload(benefitGroupId, values, providerId);

  const response = await apiService.put(
    `/benefit/update/${benefitId}`,
    payloadData.payload
  );

  if (response.hasError) return response;

  return new Benefit(response.data);
};

export const deleteBenefit = async benefitId => {
  const response = await apiService.delete(`/benefit/${benefitId}`);

  return response;
};

export const getChosenBenefit = async benefitId => {
  const response = await apiService.get(`/benefit/${benefitId}`);

  if (response.hasError) return response;

  return new Benefit(response.data);
};

/**
 * Request for publishing drafted benefit.
 * @param {number} benefitId 
 * @returns {Response}
 */
export const publishBenefit = async benefitId => {
  const response = await apiService.patch(`/benefit/publish/${benefitId}`);

  return response;
}

/**
 * Request for publishing drafted benefit grou[].
 * @param {number} benefitGroupId 
 * @returns {Response}
 */
export const publishBenefitGroup = async benefitGroupId => {
  const response = await apiService.patch(`/benefit-group/publish/${benefitGroupId}`);

  return response;
}

/**
 * Returns vendor report data (providers, benefit groups & benefits)
 * @returns {Response}
 */
export const getVendorReport = async () => {
  const response = await apiService.get('/provider/vendor-report');

  return response;
}
