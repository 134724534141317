import React from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
//Actions
import * as actionCreators from "../../../actions/app.actions";
import * as employeeActionCreators from "../../../actions/employee/employeeBenefits.actions";
//Services
import * as userService from "../../../services/api/auth.service";
import { removeItem } from "../../../services/localStorage.service";
import getInitals from "../../../services/getInitals";
//Constants
import { BENEFIT_ROLE } from "../../../constants/localStorage.constants";
import { PriceRange } from "../../../models/domain";
import { priceRangeOptions } from "../../../builders/employee/priceRangeOptions.config";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import { LanguageDropdown } from "../../locales/LanguageDropdown";

const LogoutDropDown = ({
  user,
  logout,
  setCitiesFilter,
  setRemoteFilterToStore,
  setChosenCategoryIds,
  setFiltersPriceRange,
  resetBenefitGroupFiltersToDefault
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const isLocalesInRS = localStorage.getItem("locales") === "rs";
  /**
   * Handles logout event.
   * Resets filters to default.
   * Logs out user.
   * Logs out from MSal
   * Removes role from local storage.
   * Reroute user to /.
   * @param {Event} event
   */
  const handleLogout = async event => {
    event.stopPropagation();
    await userService.logout();
    isAuthenticated && instance.logoutPopup();
    resetFilters();
    logout();
    removeItem(BENEFIT_ROLE);
    history.replace("/");
  };

  const resetFilters = () => {
    setCitiesFilter([]);
    setRemoteFilterToStore(false);
    setChosenCategoryIds([]);
    setFiltersPriceRange(priceRangeOptions, new PriceRange());
    resetBenefitGroupFiltersToDefault();
  };

  return (
    <div id="tooltip" className="logoutDropDown">
      <div className="lngDropdown">
        <LanguageDropdown />
      </div>
      <div className="logoutDropdownHeader">
        <div className="avatarContainer">
          <p className="nameInitials">
            {getInitals(`${user.firstName} ${user.lastName}`)}
          </p>
        </div>
        <div className="userFullName">
          {user.firstName} {user.lastName}
        </div>
        <div className="userEmail">{user.email}</div>
        {user.companyName && (
          <div className="userCompany">{user.companyName}</div>
        )}
      </div>
      <div className="fillContainer">
        <button className="tooltipButton" onClick={async e => handleLogout(e)}>
          {t("logout")}
        </button>
      </div>
      <div className="logoutDropdownFooter">
        <a
          target="_blank"
          href={
            isLocalesInRS
              ? "https://benefiti.rs/obavestenjeoprivatnosti.html"
              : "https://benefiti.app/obavestenjeoprivatnosti.html"
          }
        >
          {t("privacy_policy")}
        </a>
        <a
          target="_blank"
          href={
            isLocalesInRS
              ? "https://benefiti.rs/politikavebsajta.html"
              : "https://benefiti.app/politikavebsajta.html"
          }
        >
          {t("terms")}
        </a>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(actionCreators.logout()),
    resetBenefitGroupFiltersToDefault: () =>
      dispatch(employeeActionCreators.resetBenefitGroupFiltersToDefault()),
    setChosenCategoryIds: chosenCategoryIds =>
      dispatch(employeeActionCreators.setChosenCategoryIds(chosenCategoryIds)),
    setFiltersPriceRange: (updatedPriceRangeOptions, priceRange) =>
      dispatch(
        employeeActionCreators.setFiltersPriceRange(
          updatedPriceRangeOptions,
          priceRange
        )
      ),
    setCitiesFilter: cityIds =>
      dispatch(employeeActionCreators.setCitiesFilter(cityIds)),
    setRemoteFilterToStore: isRemote =>
      dispatch(employeeActionCreators.setRemoteFilter(isRemote)),
    setFiltersPriceRange: (updatedPriceRangeOptions, priceRange) =>
      dispatch(
        employeeActionCreators.setFiltersPriceRange(
          updatedPriceRangeOptions,
          priceRange
        )
      )
  };
};

export default connect(null, mapDispatchToProps)(LogoutDropDown);
