import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";
// components
import { LeftArrowIcon } from "../../../assets/Icons";
import { Card, Modal } from "../../../components";
import TiersForm from "./TierForm/TierForm";
import AddEmployeesWithCSV from "./EmployeesForm";
import AddEmployeeWithCSVError from "../EmployeePages/Employees/errorPopup/AddEmployeeWithCSVError";
import FinishStep from "./FinishStep";
// styles
import OnboardingStyles from "./OnboardingStyles";
// services
import { onboardingValidationsSchema } from "../../../services/validations/hr/onboardingValidation";
import { changeFormSteps } from "./onboarding.services";
import * as api from "../../../services/api/hr/onboarding.service";
import { useTranslation } from "react-i18next";

const formSteps = [1, 2, 3];

const Onboarding = ({ isOnboardingDone }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [formStep, setFormStep] = useState(1);
  const [newTiersAndEmployees, setNewTiersAndEmployees] = useState(undefined);
  const [stepStatusState, setStepStatusState] = useState({
    step1Status: "active",
    step2Status: "inactive",
    step3Status: "inactive"
  });
  const [errorPopup, setErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const [errors, setErrors] = useState([]);

  /**
   * Skips onboarding.
   * Sends patch request to update user hr onboarding status.
   * Reroutes to dashboard.
   */
  const skipOnboarding = () => {
    api.finishOnboarding();
    history.push("/");
  };

  useEffect(() => {
    if (isOnboardingDone) {
      history.push("/");
      // setTimeout used to call toaster message after redirect
      setTimeout(() => {
        toast.success(t("success_onboarding_finish"));
      }, 0);
    }
    document.addEventListener("keypress", function(e) {
      if (e.keyCode === 13 || e.which === 13) {
        e.preventDefault();
        return false;
      }
    });
  }, []);

  useEffect(() => {
    changeFormSteps(formStep);
  }, [formStep]);

  const submitForm = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const response = await api.submitOnboarding(values);
    if (response.hasError) {
      return handleCreateEmployeeError(response);
    }

    setNewTiersAndEmployees(response);
    setStepStatusState({
      ...stepStatusState,
      step3Status: "completed"
    });
    setFormStep(3);
    setSubmitting(false);
  };

  const handleCreateEmployeeError = ({ error }) => {
    const { data } = error;
    if (data) {
      if (data.errors && data.error) {
        handleOpenErrorPopup(data.error, data.errors.csv.errors);
      } else {
        handleOpenErrorPopup(data.error);
      }
    }
  };

  /**
   * Handles opening of error popup
   * - Sets error popup visibility state to true.
   * - Sets error data object.
   * @param {object} errorData
   */
  const handleOpenErrorPopup = (errorMessage, errors) => {
    setErrorPopup(true);
    setErrorMessage(errorMessage);
    setErrors(errors);
  };

  /**
   * Handle closing of modal.
   */
  const handleCloseModal = () => {
    setErrorPopup(false);
  };

  return (
    <OnboardingStyles>
      <Card className="cardComponent">
        <div className="content">
          {newTiersAndEmployees === undefined && (
            <button
              type="button"
              className="skipOnboarding"
              onClick={() => skipOnboarding()}
            >
              Skip onboarding {">>"}
            </button>
          )}
          <div className="onboardingContent">
            <div className="stepsSlider">
              <Formik
                initialValues={{
                  tiers: [{ name: "", tokens: "", currency: "" }],
                  csvFile: undefined
                }}
                onSubmit={(values, actions) => submitForm(values, actions)}
                validationSchema={onboardingValidationsSchema}
              >
                {({
                  values,
                  handleChange,
                  errors,
                  setFieldValue,
                  touched,
                  setFieldTouched,
                  handleSubmit,
                  isSubmitting
                }) => (
                  <>
                    <Form
                      onSubmit={e => {
                        e.preventDefault();
                        if (JSON.stringify(errors) !== "{}") {
                          return toast.warning(t("fill_form_warning"));
                        }
                        handleSubmit();
                      }}
                      autoComplete="off"
                    >
                      <div className="stepsContainer" id="stepsContainer">
                        <TiersForm
                          id="tierForm"
                          values={values}
                          handleChange={handleChange}
                          errors={errors}
                        />
                        <AddEmployeesWithCSV
                          values={values}
                          errors={errors}
                          setNewTiersAndEmployees={setNewTiersAndEmployees}
                          setFormStep={setFormStep}
                          setFieldValue={setFieldValue}
                          touched={touched}
                          handleBlur={setFieldTouched}
                          disableButton={isSubmitting}
                          stepStatusState={stepStatusState}
                          setStepStatusState={setStepStatusState}
                          id="employeeForm"
                        />
                        <FinishStep
                          id="finishStep"
                          newTiersAndEmployees={newTiersAndEmployees}
                        />
                      </div>
                    </Form>

                    <div className="onboardingSlider">
                      <button
                        className="button"
                        type="button"
                        disabled={
                          newTiersAndEmployees !== undefined || formStep === 1
                        }
                        onClick={() => setFormStep(formStep - 1)}
                      >
                        <LeftArrowIcon />
                      </button>
                      <div className="dotsContainer">
                        {formSteps.map(state => (
                          <div
                            className={`dots ${state === formStep &&
                              "activeDot"}`}
                            key={state}
                          />
                        ))}
                      </div>
                      <button
                        className="button"
                        style={{ transform: "rotate(180deg)", height: 15 }}
                        type="button"
                        onClick={() => setFormStep(formStep + 1)}
                        disabled={
                          newTiersAndEmployees !== undefined || formStep === 3
                        }
                      >
                        <LeftArrowIcon />
                      </button>
                    </div>
                  </>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Card>

      {errorPopup && (
        <Modal isCloseVisable={false}>
          <AddEmployeeWithCSVError
            closeModal={handleCloseModal}
            cities={errorMessage.data}
            message={errorMessage.message}
            errors={errors}
          />
        </Modal>
      )}
    </OnboardingStyles>
  );
};

const mapStateToProps = state => {
  return {
    isOnboardingDone: state.app.user.isOnboardingDone
  };
};

export default connect(mapStateToProps, null)(Onboarding);
