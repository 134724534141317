import styled from "styled-components";

const RefundModalStyles = styled.section`
  min-height: 347px;
  min-width: 360px;
  max-width: calc(100% - 50px);
  position: relative;
  padding: 15px 0 15px 15px;
  font-size: 12px;

  h2 {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 20px;
    color: #5d5d5d;
  }

  section {
    display: flex;
    justify-content: space-between;
    gap: 40px;

    .leftSection {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      & > div {
        display: flex;
        margin-bottom: 10px;
        flex-direction: column;
      }
    }

    .rightSection {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      & > div {
        margin-bottom: 10px;
      }

      .rightSectionImage {
        align-items: flex-start;
        flex-direction: column;
      }

      .imageButton {
        cursor: zoom-in;
        background: transparent;
        border: none;
        font-weight: 500;

        img {
          margin-top: 10px;
          width: 200px;
          height: 200px;
          border-radius: 8px;
        }
      }
    }
  }

  .refundButtons {
    display: flex;
    gap: 20px;
    position: absolute;
    right: 0;

    & > button {
      padding: 6px 12px;
      border-radius: 4px;
      font-size: 16px;
      cursor: pointer;
      transition: 1s;
    }

    button.accept {
      color: white;
      background: #228b22;
      border: none;

      &:disabled {
        cursor: not-allowed;
      }
    }

    button.reject {
      border-color: #c32148;
      background: white;

      &:disabled {
        cursor: not-allowed;
      }

      &:hover {
        color: white;
        background: #c32148;
      }
    }
  }

  .refundStatus {
    position: absolute;
    bottom: 15px;
    left: 15px;
    display: flex;
    align-items: center;

    p {
      margin-right: 10px;
    }
    svg {
      width: 20px;
      heigh: 20px;
    }
    svg.pending {
      color: gray;
    }
    svg.accepted {
      color: green;
    }
    svg.rejected {
      color: red;
    }
  }
`;

export default RefundModalStyles;
