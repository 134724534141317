export const openAddUserForm = (setIsAddUserVisible) => {
	setIsAddUserVisible(true)
	setTimeout(() => {
		const addEmployeeForm = document.getElementById('addUserForm')
		const employeesList = document.getElementById('usersList')
		employeesList.classList.add('hideUsersList')
		addEmployeeForm.classList.add('showAddUserForm')
	}, 150)
}

export const closeAddUser = (setIsAddUserVisible) => {
	const addCompanyForm = document.getElementById('addUserForm')
	const companiesList = document.getElementById('usersList')
	companiesList.classList.remove('hideUsersList')
	addCompanyForm.classList.remove('showAddUserForm')
	setTimeout(() => {
		setIsAddUserVisible(false)
	}, 400)
}
