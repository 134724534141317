import * as actionTypes from "../../constants/actionTypes/employee/employeeBenefits.constants";
import { BenefitGroup, Category, PriceRange } from "../../models/domain";
import * as modelMapperService from "../../models/modelMapper.service";

export const employeeBenefitsSetInitialState = (
  benefitGroups,
  benefitGroupsCount,
  benefitGroupsTotalPages,
  benefitGroupsPage,
  categories
) => {
  const domainBenefitGroups = modelMapperService.mapManyToDomain(
    benefitGroups,
    BenefitGroup
  );

  const domainCategories = modelMapperService.mapManyToDomain(
    categories,
    Category
  );

  return {
    type: actionTypes.SET_INITAL_STATE,
    benefitGroups: domainBenefitGroups,
    benefitGroupsCount,
    benefitGroupsTotalPages,
    benefitGroupsPage,
    categories: domainCategories
  };
};

export const setIsFavoritePageOpen = payload => {
  return {
    type: actionTypes.SET_FAVORITE_TAB_OPEN,
    payload
  };
};

export const setListOfFavorites = payload => {
  const domainBenefitGroups = modelMapperService.mapManyToDomain(
    payload,
    BenefitGroup
  );
  return {
    type: actionTypes.SET_LIST_OF_FAVORITES,
    payload: domainBenefitGroups
  };
};

export const setUsedBenefitsRedux = payload => {
  return {
    type: actionTypes.SET_USED_BENEFITS,
    payload
  };
};

export const setCurrentlyPopularBenefit = payload => {
  return {
    type: actionTypes.SET_CURRENTLY_POPULAR_BENEFIT,
    payload
  };
};

export const setSocialProofForSingleBenefit = payload => {
  return {
    type: actionTypes.SET_SOCIAL_PROOF_FOR_SINGLE_BENEFIT_GROUP,
    payload
  };
};

export const checkIfInFavorites = payload => {
  return {
    type: actionTypes.CHECK_IF_IN_FAVORITES,
    payload
  };
};

export const setListOfBrowseBenefits = payload => {
  return {
    type: actionTypes.SET_LIST_OF_BROWSE_BENEFITS,
    payload
  };
};

export const setListOfCompanyBenefits = payload => {
  return {
    type: actionTypes.SET_LIST_OF_COMPANY_BENEFITS,
    payload
  };
};

export const updateBenefitGroup = benefitGroup => {
  return {
    type: actionTypes.UPDATE_BENEFIT_GROUP,
    benefitGroup
  };
};

export const setIsSubscribedBenefitGroupsVisible = isVisible => {
  return {
    type: actionTypes.SET_IS_SUBSCRIBED_BENEFIT_GROUPS_VISIBLE,
    isVisible
  };
};

export const setBenefitGroupType = benefitGroupType => {
  return {
    type: actionTypes.SET_BENEFIT_GROUP_TYPE,
    benefitGroupType
  };
};

export const setIsInitialStateSet = (flag = true) => {
  return {
    type: actionTypes.SET_IS_INITIAL_STATE_SET,
    flag
  };
};

export const setIsPageLoading = isLoading => {
  return {
    type: actionTypes.SET_IS_PAGE_LOADING,
    isLoading
  };
};

export const setChosenCategoryIds = chosenCategoryIds => {
  return {
    type: actionTypes.SET_CHOSEN_CATEGORY_IDS,
    chosenCategoryIds
  };
};

export const setFiltersPriceRange = (updatedPriceRangeOptions, priceRange) => {
  const domainPriceRange = modelMapperService.mapOneToDomain(
    priceRange,
    PriceRange
  );
  return {
    type: actionTypes.SET_FILTERS_PRICE_RANGE,
    updatedPriceRangeOptions,
    chosenPriceRange: domainPriceRange
  };
};

export const setCitiesFilter = cityIds => {
  return {
    type: actionTypes.SET_CITY_IDS_FILTERS,
    cityIds
  };
};

export const setRemoteFilter = isRemote => {
  return {
    type: actionTypes.SET_REMOTE_FILTER,
    isRemote
  };
};

export const setBenefitExpirationTypesFilter = expirations => {
  return {
    type: actionTypes.SET_BENEFIT_EXPIRATION_TYPES_FILTERS,
    expirations
  };
};

export const setSearchFilter = search => {
  return {
    type: actionTypes.SET_SEARCH_FILTERS,
    search
  };
};

export const setOrderSort = order => {
  return {
    type: actionTypes.SET_ORDER_SORT,
    order
  };
};

export const setKeySort = key => {
  return {
    type: actionTypes.SET_KEY_SORT,
    key
  };
};

export const setAditionalBenefitGroups = (
  benefitGroups,
  benefitGroupsCount,
  benefitGroupsTotalPages,
  benefitGroupsPage,
  benefitGroupType
) => {
  const domainBenefitGroups = modelMapperService.mapManyToDomain(
    benefitGroups,
    BenefitGroup
  );

  return {
    type: actionTypes.SET_ADITIONAL_BENEFIT_GROUPS,
    newBenefitGroups: domainBenefitGroups,
    benefitGroupsCount,
    benefitGroupsTotalPages,
    benefitGroupsPage,
    benefitGroupType
  };
};

export const setBenefitGroupsPage = benefitGroupsPage => {
  return {
    type: actionTypes.SET_BENEFIT_GROUPS_PAGE,
    benefitGroupsPage
  };
};

export const applyBenefitGroupsFilters = (
  benefitGroups,
  benefitGroupsCount,
  benefitGroupsTotalPages,
  benefitGroupType
) => {
  const domainBenefitGroups = modelMapperService.mapManyToDomain(
    benefitGroups,
    BenefitGroup
  );
  return {
    type: actionTypes.APPLY_BENEFIT_GROUPS_FILTERS,
    benefitGroups: domainBenefitGroups,
    benefitGroupsCount,
    benefitGroupsTotalPages,
    benefitGroupType
  };
};

export const resetBenefitGroupFilters = (
  benefitGroups,
  benefitGroupsCount,
  benefitGroupsTotalPages,
  benefitGroupType
) => {
  const domainBenefitGroups = modelMapperService.mapManyToDomain(
    benefitGroups,
    BenefitGroup
  );
  return {
    type: actionTypes.RESET_BENEFIT_GROUPS_FILTERS,
    benefitGroups: domainBenefitGroups,
    benefitGroupsCount,
    benefitGroupsTotalPages,
    benefitGroupType
  };
};

export const resetBenefitGroupFiltersToDefault = () => {
  return {
    type: actionTypes.RESET_BENEFIT_GROUPS_FILTERS_TO_DEFAULT
  };
};

export const openBenefitGroupModal = benefitGroup => {
  const domainBenefitGroup = modelMapperService.mapOneToDomain(
    benefitGroup,
    BenefitGroup
  );

  return {
    type: actionTypes.OPEN_BENEFIT_GROUP_MODAL,
    chosenBenefitGroup: domainBenefitGroup
  };
};

export const setChosenBenefitGroup = benefitGroup => {
  const domainBenefitGroup = modelMapperService.mapOneToDomain(
    benefitGroup,
    BenefitGroup
  );
  return {
    type: actionTypes.SET_CHOSEN_BENEFIT_GROUP,
    chosenBenefitGroup: domainBenefitGroup
  };
};

export const closeBenefitGroupModal = () => {
  return {
    type: actionTypes.CLOSE_BENEFIT_GROUP_MODAL
  };
};

export const setBenefitGroupsAndSubscribedBenefitGroups = (
  benefitGroups,
  benefitGroupsCount,
  benefitGroupsTotalPages,
  subscribedBenefitGroups
) => {
  const domainBenefitGroups = modelMapperService.mapManyToDomain(
    benefitGroups,
    BenefitGroup
  );

  const domainSubscribedBenefitGroups = modelMapperService.mapManyToDomain(
    subscribedBenefitGroups,
    BenefitGroup
  );

  return {
    type: actionTypes.SET_BENEFIT_GROUPS_AND_SUBSCRIBED_BENEFIT_GROUPS,
    benefitGroups: domainBenefitGroups,
    benefitGroupsCount,
    benefitGroupsTotalPages,
    subscribedBenefitGroups: domainSubscribedBenefitGroups
  };
};

export const setSubscribedBenefitGroups = subscribedBenefitGroups => {
  const domainSubscribedBenefitGroups = modelMapperService.mapManyToDomain(
    subscribedBenefitGroups,
    BenefitGroup
  );

  return {
    type: actionTypes.SET_SUBSCRIBED_BENEFIT_GROUPS,
    subscribedBenefitGroups: domainSubscribedBenefitGroups
  };
};

export const setIsConditionAllBenefits = isConditionAllBenefits => {
  return {
    type: actionTypes.SET_NEXT_MONTH_FILTER_CONDITION,
    isConditionAllBenefits
  };
};

export const setIsNextMonthTooltipOpen = isNextMonthTooltipOpen => {
  return {
    type: actionTypes.SET_NEXT_MONTH_TOOLTIP_IS_OPEN,
    isNextMonthTooltipOpen
  };
};
