import styled from 'styled-components'

const AuthStyles = styled.section`
  display: flex;
  width: 71.25%;
  height: 71.3%;
  min-width: 420px;
  min-height: 420px;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 2px 5px 10px 2px #888;

  .authWindow {
    overflow: hidden;
    position: relative;
    width: 530px;
    min-width: 420px;
    padding: 40px;

    .logo {
      font-size: 32px;
    }

    .formContainer {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      .form {
        width: 66.04%;

        .label {
          width: 100%;

          .inputs {
            margin: 0 0 30px 0;
            font-size: 16px;
            padding: 5px 10px 2px 10px;
            line-height: 24px;
            width: 100%;
            border-top: none;
            border-left: none;
            border-right: none;
            border-bottom: 1px solid #1568bf;
          }

          .inputs:focus {
            outline: none;
            border-bottom: 1px solid #1568bf;
          }

          input[type='number']::-webkit-inner-spin-button,
          input[type='number']::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }

        .label:focus-within {
          color: #1568bf;
        }

        .buttonContainer {
          display: flex;
          justify-content: flex-end;

          .submitButton {
            padding: 5px 20px;
            color: #fff;
            cursor: pointer;
            background: darkblue;
            font-weight: bold;
            font-size: 16px;
            border-radius: 4px;
            border: 1px solid darkblue;
          }

          .submitButton:focus {
            outline: none;
          }
        }
      }
    }
  }

  .bannerWindow {
    position: relative;
    width: 838px;
    background-image: linear-gradient(to bottom, #1488cc 3%, #1e3c72);
  }

  @media only screen and (max-width: 1279px) {

    .authWindow {
      width: 100%;
    }  

    .bannerWindow {
      display: none;
    }
  }

  @media only screen and (max-width: 420px)  {
    width: 100%;
    height: 100%;
    box-shadow: none;
    min-width: 0;

    .authWindow {
      width: 100%;
      min-width: unset;
      padding: 20px;
    }   
  }

  @media only screen and (max-height: 420px) {
    min-height: 360px;
    
    .authWindow {
      padding: 20px;
    }
  }

`

export default AuthStyles
