import styled from 'styled-components'

const ProvidersStyles = styled.section`
	.providersContainer {
		width: calc(100% - 210px);
		margin-left: 210px;
		background: whitesmoke;
		min-height: 100vh;
	}

	.providersContent {
		padding: 0 30px 30px 30px;
	}

	#providersList {
		opacity: 0;
		transition: all 0.4s ease-in-out;
	}

	.filtersHeader {
		display: flex;
    justify-content: space-between;
    align-items: baseline;
    gap: 15px;
	}

	.providersSearch {
		width: 250px !important;
	}

	.plusContainer {
		width: 24px;
		height: 24px;
		background: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 10px;
		color: #373737;
		border-radius: 50%;

		.buttonText {
			line-height: 24px;
			font-weight: 500;
			font-size: 14px;
		}
	}
	

	.addHeight {
		max-height: 4000px !important;
	}

	.benefitGroupsRow {
		display: flex;
			align-items: center;
			justify-content: space-between;
			border-top: 1px solid #d8d8d8;
			border-bottom: 1px solid #d8d8d8;
			padding: 30px;
			cursor: pointer;

			&:hover {
				transform: scale(30px);
			box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
			}	
	}
	.cardView {
			transform: scale(30px);
			box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
	}
`
export default ProvidersStyles
