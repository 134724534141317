import styled from 'styled-components'

const CompanyDetailsStyles = styled.section`
	.companyDetailsContainer {
		width: calc(100% - 210px);
		margin-left: 210px;
		background: #f7f7f7;
		min-height: 100vh;
		overflow-x: hidden;
	}
	
	.companyDetailsContent {
		padding: 0 30px 30px 30px;
	}

	#companyDetails {
		opacity: 0;
		transition: all 0.4s ease-in-out;
	}

	.companyTiersList {
		margin: 30px 0;

		.tierItem {
			margin: 0 0 10px 0;
			display: flex;
			align-items: center;
			color: #4b4b4b;
			font-size: 16px;
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			background: #f7f7f7;
			height: 40px;
			padding: 0 10px;
			width: 320px;
			border-radius: 4px;
			justify-content: space-between;

			&:last-child {
				margin: 0;
			}

			.tierName {
				margin-right: 10px;
				width: 120px;
			}

			.span {
				margin-left: 5px;
			}
		}
	}
`

export default CompanyDetailsStyles
