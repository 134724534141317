import React, { useState, useContext } from "react";
import { connect } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
// components
import ProviderForm from "../ProviderForm/ProviderForm";
// styles
import EditProviderStyles from "./EditProviderStyles";
// services
import { validateProviderForm } from "../../../../../../services/validations/addProviderFormValidation";
import * as providerService from "../../../../../../services/api/admin/providers.service";
import { useTranslation } from "react-i18next";

const EditProvider = ({
  closeModal,
  providerId,
  providers,
  fetchProviders
}) => {
  const { t } = useTranslation();
  const [disableButton, setDisableButton] = useState(false);

  const handleEditProviderError = ({ error }) => {
    const { data } = error;
    if (data.errors && data.error) {
      if (data.errors.name) {
        toast.error(`${data.error.message}: ${data.errors.name.keys[0]}`);
      }
      if (data.errors.description) {
        toast.error(
          `${data.error.message}: ${data.errors.description.keys[0]}`
        );
      }
      if (data.errors.summary) {
        toast.error(`${data.error.message}: ${data.errors.summary.keys[0]}`);
      }
      if (data.errors.photo) {
        toast.error(`${data.errors.photo.keys[0]}`);
      }
    } else {
      toast.error(data.error.message);
    }
  };

  const updateProvider = async values => {
    setDisableButton(true);

    const response = await providerService.editProvider(values, providerId);

    if (response.hasError) {
      setDisableButton(false);
      return handleEditProviderError(response);
    }

    fetchProviders();
    toast.success(t("success_update_provider"));
    setTimeout(() => {
      setDisableButton(false);
    }, 550);
    closeModal();
  };

  const formik = useFormik({
    initialValues: providers.find(provider => provider.id === providerId),
    validate: validateProviderForm,
    onSubmit: updateProvider
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    handleChange,
    handleBlur
  } = formik;

  return (
    <EditProviderStyles>
      <h2 className="title">{t("edit_provider")}</h2>
      <form className="form" autoComplete="off" onSubmit={handleSubmit}>
        <ProviderForm
          values={values}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          handleBlur={handleBlur}
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
          closeModal={closeModal}
          buttonText="Update"
          disableButton={disableButton}
        />
      </form>
    </EditProviderStyles>
  );
};

EditProvider.propTypes = {
  closeModal: PropTypes.func,
  providerId: PropTypes.number
};

const mapStateToProps = state => {
  return {
    providers: state.providers.providers
  };
};

export default connect(mapStateToProps, undefined)(EditProvider);
