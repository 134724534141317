import React from "react";
import GlobalErrorBoundaryFallbackStyles from "./GlobalErrorBoundaryFallbackStyles";

export const GlobalErrorBoundaryFallback = () => {
  const handleRedirect = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <GlobalErrorBoundaryFallbackStyles>
      <div className="mainContent">
        <p>Ooops something went wrong!</p>
        <button onClick={handleRedirect}>
          Click here to redirect to login page
        </button>
      </div>
    </GlobalErrorBoundaryFallbackStyles>
  );
};
