import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { transformTokensToRsd } from "../../../../utils/transformTokensToRsd";

export const LeftModalSection = ({ singleRefund }) => {
  const { t } = useTranslation();
  return (
    <div className="leftSection">
      <div>
        <span>Email:</span>
        <span>{singleRefund.UserBenefit.User.email}</span>
      </div>
      <div>
        <span>{t("Date")}:</span>
        <span>{moment(singleRefund.createdAt).format("DD.MM.YYYY.")}</span>
      </div>
      <div>
        <span>Benefit:</span>
        <span>{singleRefund.UserBenefit.BenefitGroup.name}</span>
      </div>
      <div>
        {t("Price")}:{" "}
        {transformTokensToRsd(singleRefund.UserBenefit.benefitPrice)}
      </div>
      <div>
        {t("Type")}: {singleRefund.UserBenefit.BenefitVoucher.name}
      </div>
    </div>
  );
};
