import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import * as benefitGroupService from "../../../../services/api/admin/benefitGroups.service";
import * as employeeBenefitsService from "../../../../services/api/employee/employeeBenefits.services";
import PreviewHRBenefitBodyStyles from "./PreviewHRBenefitBodyStyles";
import { BenefitCard, Button, Card } from "../../../../components";
import BenefitSubscribe from "../../../EmployeePages/EmployeeBenefits/benefitSubscribe";
import * as actionCreators from "../../../../actions/employee/employeeBenefits.actions";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import html2canvas from "html2canvas";

export const PreviewHRBenefitBody = props => {
  const { benefitGroup } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isDisabled, setIsDisabled] = useState(false);
  const [usedBenefits, setUsedBenefits] = useState([]);

  const { id: benefitGroupId } = useParams();

  const chosenBenefitGroup = useSelector(
    state => state.employeeBenefitsPage.pageFrontEndStates.chosenBenefitGroup
  );

  const getBenefitGroupForPreview = async benefitGroupId => {
    try {
      const response = await benefitGroupService.getBenefitGroupForPreview(
        benefitGroupId
      );

      dispatch(actionCreators.setChosenBenefitGroup(response));
    } catch (error) {
      toast.error(error);
    }
  };

  const fetchAlreadyUsedBenefits = async benefitGroupId => {
    try {
      const response = await employeeBenefitsService.getAlreadyUsedBenefits(
        benefitGroupId
      );

      setUsedBenefits(response.benefits);
    } catch (error) {
      toast.error(t("failed_to_get_used_benefits"));
    }
  };

  const downloadImage = (blob, fileName, canvas) => {
    const fakeLink = window.document.createElement("a");
    fakeLink.style = "display: none";
    fakeLink.download = fileName;

    fakeLink.href = blob;

    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);

    fakeLink.remove();
    setIsDisabled(false);
  };

  const downloadPreview = async () => {
    setIsDisabled(true);
    const previewCanvas = document.getElementById("preview-wrapper");
    const canvas = await html2canvas(previewCanvas, {
      allowTaint: true,
      useCORS: true,
      windowHeight: (window.outerHeight + window.innerHeight) * 4,
      scale: 2
    });
    const image = canvas.toDataURL("image/png", 1.0);

    downloadImage(image, "preview.png", previewCanvas);
  };

  useEffect(() => {
    getBenefitGroupForPreview(benefitGroupId);
    fetchAlreadyUsedBenefits(benefitGroupId);
  }, [benefitGroupId]);

  return (
    <div className="previewHrBenefitsContent">
      <PreviewHRBenefitBodyStyles>
        {chosenBenefitGroup && (
          <>
            <div
              id="preview-wrapper"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "20px"
              }}
            >
              <BenefitCard
                benefitGroupId={chosenBenefitGroup.id}
                benefitDetails={chosenBenefitGroup}
                onClick={() => {}}
                isPreview
              />
              <Card>
                <BenefitSubscribe
                  benefitGroup={benefitGroup}
                  isPreview
                  maxHeight="60vh"
                  usedBenefits={usedBenefits}
                />
              </Card>
            </div>

            <div className="action-buttons">
              <Button
                width="130px"
                margin="20px 0 0 0"
                onClick={() => downloadPreview()}
                disabled={isDisabled}
              >
                {t("download")}
              </Button>
            </div>
          </>
        )}
      </PreviewHRBenefitBodyStyles>
    </div>
  );
};
