import styled from "styled-components";

const TotalBudgetStyles = styled.div`
  margin-right: 20px;
  order: 2;

  .chartAndMessageContainer {
    height: 299px;
    width: 250px;
    position: relative;
    z-index: 0;
  }

  .messageContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
  }

  .text {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4b4b4b;
    text-transform: uppercase;
  }

  .legend {
    margin-top: 20px;
  }

  .item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .dot {
      min-width: 7px;
      min-height: 7px;
      border-radius: 3.5px;
      margin-right: 10px;
    }
  }
`;

export default TotalBudgetStyles;
