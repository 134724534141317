import styled, { keyframes } from "styled-components";

const fadeInLeft = keyframes`
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const fadeOutLeft = keyframes`
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
`;

const SocialProofNotificationsStyles = styled.div`
  .socialProofNotificationWrapper {
    display: flex;

    img {
      width: 50px;
      height: 50px;
      border-radius: 4px;
      margin-right: 5px;
    }
  }

  #social-proof-notification {
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 2;
    background: #ffffffff;
    width: 230px;
    padding: 12px;
    border-radius: 4px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
    cursor: pointer;
    transition: all ease 0.3s;
    filter: drop-shadow(1px 2px 8px hsl(220deg 100% 55%));
    opacity: 0;
    transform: translate3d(-100%, 0, 0);

    color: #757575;
    font-size: 13px;
    font-weigh: 400;

    span {
      font-weight: 600;
      color: #1568bf;
    }
  }

  #social-proof-notification.show {
    animation: ${fadeInLeft} 0.7s ease forwards;
  }

  #social-proof-notification.hide {
    animation: ${fadeOutLeft} 0.7s ease forwards;
  }
`;

export default SocialProofNotificationsStyles;
