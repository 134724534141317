import { User } from ".";
import { capitalizeFirstLetters, isEmpty } from "../../services/general.utils";

class Activity {
  constructor(activityData = {}) {
    this.id = activityData.id || null;
    this.action = capitalizeFirstLetters(activityData.action, "_", " ") || "";
    this.model =
      activityData.model !== activityData.action
        ? activityData.model
        : "" || "";
    this.description = activityData.description;    
    this.createdAt = activityData.createdAt
      ? new Date(activityData.createdAt)
      : new Date(activityData.created_at) || "";
    this.user = !isEmpty(activityData.User) ? new User(activityData.User) : null;
  }
}

export default Activity;
