import React from "react";
import PropTypes from "prop-types";

import { AddCompanyTag, EditCompanyTag } from "./CompanyTags";
import { AddBenefitTag, EditBenefitTag } from "./BenefitTags";
import { AddCategory, EditCategory } from "./Categories";
import { AddCity } from "./Cities/AddCities";

const FormRenderer = ({ modalType, closeModal, chosenId }) => {
  switch (modalType) {
    case "ADD_COMPANY_TAG":
      return <AddCompanyTag closeModal={closeModal} />;
    case "EDIT_COMPANY_TAG":
      return <EditCompanyTag chosenId={chosenId} closeModal={closeModal} />;
    case "ADD_BENEFIT_TAG":
      return <AddBenefitTag closeModal={closeModal} />;
    case "EDIT_BENEFIT_TAG":
      return <EditBenefitTag closeModal={closeModal} chosenId={chosenId} />;
    case "ADD_CATEGORY":
      return <AddCategory closeModal={closeModal} />;
    case "EDIT_CATEGORY":
      return <EditCategory closeModal={closeModal} chosenId={chosenId} />;
    case "ADD_CITY":
      return <AddCity closeModal={closeModal} />;
    default:
      throw new Error(`Unknow action type: ${modalType}`);
  }
};

PropTypes.FormRenderer = {
  modalType: PropTypes.string,
  chosenId: PropTypes.number,
  closeModal: PropTypes.func
};
export default FormRenderer;
