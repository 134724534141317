import React from "react";
import InfoPopUp from "../../../../../components/InfoPopUp/InfoPopUp";

const tableHead = [
  {
    id: 1,
    headName: "Name",
    field: "name",
    isSortedUp: null,
    width: "20%",
    justifyContent: "flex-start",
    isSortable: true,
    margin: ""
  },
  {
    id: 2,
    headName: "Tier",
    field: "tier",
    isSortedUp: null,
    width: "15%",
    justifyContent: "center",
    isSortable: true,
    margin: ""
  },
  {
    id: 3,
    headName: "Budget",
    field: "tokens",
    isSortedUp: null,
    width: "20%",
    justifyContent: "center",
    isSortable: false,
    margin: "",
    Component: <InfoPopUp text="remaining_tokens_total_tokens" id="popUp" />
  },
  {
    id: 4,
    headName: "Total benefits",
    field: "total_benefits",
    isSortedUp: null,
    width: "25%",
    justifyContent: "center",
    isSortable: true,
    margin: "",
    Component: (
      <InfoPopUp
        text="number_of_different_benefits"
        id="totalNumberOfBenefitsPopUp"
      />
    )
  },
  {
    id: 5,
    headName: "Benefit",
    field: "benefit",
    isSortedUp: null,
    width: "20%",
    justifyContent: "center",
    isSortable: true,
    margin: ""
  }
];

export { tableHead };
