import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const ChevronIconDownSvgStyles = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    fill: ${({ fill }) => fill};
  }
`

const ChevronIconDownSvg = ({
  width = '20px',
  height = '13px',
  fill = '#8f8f8f',
  isOpen
}) => (
  <ChevronIconDownSvgStyles width={width} height={height} fill={fill}>
    <svg
      viewBox="0 0 20 10"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 14.9 3.2"
      version="1.1"
      xmlSpace="preserve"
      stroke="current"
      style={{
        transform: isOpen ? "rotate(0.50turn)" : "rotate(0)",
        transformOrigin: '29% 40%',
        transition: "all 0.1s linear"
      }}
    >
      <path clipRule="evenodd" d="M1.22196 1.21829C1.36522 1.08046 1.55734 1.00515 1.7561 1.0089C1.95486 1.01265 2.144 1.09516 2.28196 1.23829L5.99196 5.17629L9.70196 1.23829C9.7694 1.16395 9.85107 1.1039 9.94214 1.0617C10.0332 1.0195 10.1318 0.996005 10.2321 0.992615C10.3325 0.989226 10.4324 1.00601 10.5261 1.04196C10.6199 1.07792 10.7054 1.13232 10.7777 1.20195C10.85 1.27157 10.9076 1.355 10.9471 1.44729C10.9865 1.53958 11.0071 1.63885 11.0075 1.73922C11.0079 1.83959 10.9881 1.93902 10.9494 2.03162C10.9106 2.12422 10.8537 2.2081 10.782 2.27829L6.53196 6.77829C6.462 6.85088 6.37812 6.90862 6.28534 6.94805C6.19256 6.98749 6.09278 7.00781 5.99196 7.00781C5.89115 7.00781 5.79137 6.98749 5.69858 6.94805C5.6058 6.90862 5.52192 6.85088 5.45196 6.77829L1.20196 2.27829C1.06413 2.13504 0.988815 1.94292 0.992565 1.74415C0.996315 1.54539 1.07882 1.35625 1.22196 1.21829Z" />
    </svg>
  </ChevronIconDownSvgStyles>

)

ChevronIconDownSvg.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string
}

export default ChevronIconDownSvg;

