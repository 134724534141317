import React from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import DatePickerInputStyles from './DatePickerInputStyles'

const DatePickerInput = ({ name, value, onChange, onBlur }) => (
	<DatePickerInputStyles>
		<DatePicker
			name={name}
			selected={value}
			dateFormat="dd.MM.yyyy"
			onChange={onChange}
			onBlur={onBlur}
		/>
	</DatePickerInputStyles>
)

DatePickerInput.propTypes = {
	name: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
	onChange: PropTypes.func,
	onBlur: PropTypes.func
}

export default DatePickerInput
