import * as actionTypes from "../../constants/actionTypes/hr/localBenefits.constants";

export const setLocalBenefitGroups = (benefitGroups, count) => {
  return { type: actionTypes.SET_LOCAL_BENEFIT_GROUPS, benefitGroups, count };
};

export const setLocalBenefitGroupPage = page => {
  return { type: actionTypes.SET_LOCAL_BENEFIT_GROUPS_PAGE, page };
};

export const editLocalBenefitGroup = benefitGroup => {
  return { type: actionTypes.EDIT_LOCAL_BENEFIT_GROUP, benefitGroup };
};

export const addNewBenefitToBenefitGroup = (benefitGroupId, benefit) => {
  return {
    type: actionTypes.ADD_NEW_LOCAL_BENEFIT_TO_LOCAL_BENEFIT_GROUP,
    benefitGroupId,
    benefit
  };
};

export const editLocalBenefit = (benefitGroupId, benefit) => {
  return { type: actionTypes.EDIT_LOCAL_BENEFIT, benefitGroupId, benefit };
};

export const removeLocalBenefit = (benefitGroupId, benefitId) => {
  return { type: actionTypes.DELETE_LOCAL_BENEFIT, benefitGroupId, benefitId };
};
