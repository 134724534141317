import React from 'react';
import { Category } from '../models/domain';
import { isEmpty } from "../services/general.utils"

/**
 * Renders list of benefit group categories.
 * @param {Category[]} categories 
 * @returns {HTMLParagraphElement | undefined}
 */
export const renderCategories = (
  categories
) => {
  return !isEmpty(categories) && (
    <p className="categoryText">
      {categories.map(category => category.name).join(", ")}
    </p>
  )
}