import styled from "styled-components";

const AdminUserHistoryContentStyles = styled.section`
  .adminUserHistoryHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .adminUserHistoryHeaderTokens {
      display: flex;
      gap: 10px;
      flex-direction: column;
      width: 230px;
      padding: 10px;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      border-radius: 20px;

      .adminUserHistoryHeaderTokenItem {
        display: flex;
        align-items: center;
        gap: 5px;
        color: #757575;
        font-size: 14px;
      }
    }
  }

  .adminUserHistoryBody {
    margin-top: 30px;
    display: flex;
    width: 100%;

    .adminUserHistoryBodyItem {
      position: relative;
      height: 50px;
      padding-left: 135px;

      .adminUserHistoryBodyItemLeft {
        position: absolute;
        left: -114px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 106px;
        height: 100%;

        .time {
          font-size: 12px;
          color: #493f3f;
        }

        .outerDot {
          width: 15px;
          height: 15px;
          border-radius: 15px;
          background: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .innerDot {
          width: 7px;
          height: 7px;
          border-radius: 7px;
          background: #1568bf;
        }
      }

      .adminUserHistoryBodyItemRight {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;

        .adminUserHistoryBodyItemRightText {
          color: #493f3f;
        }
      }
    }
  }

  .adminUserHistoryBodyItemWrapper {
    border-left: 1px solid rgb(214, 214, 214);
    padding-left: 15px;
    height: 100%;
    position: relative;
  }
`;

export default AdminUserHistoryContentStyles;
