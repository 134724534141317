import styled from 'styled-components'

const BenefitDetailsStyles = styled.div`
	.benefitDetailsContet {
		height: 300px;
		min-width: 200px;
	}

	.detailsActionsButton {
    border: 0;
    background-color: white;
  }

	.titleAndIcons {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;

		.title {
			font-size: 20px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #373737;
		}

		.__react_component_tooltip.type-dark.border {
      padding: 0;
      min-width: 120px;
      border-radius: 5px;
      border: 1px solid #1568bf !important;
      & > div {
        display: flex;
        align-items: center;
        flex-direction: column;
      }
    }
	}

	.description {
		height: 144px;
		overflow-y: auto;
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.83;
		letter-spacing: normal;
		color: #4b4b4b;
		margin: 10px 0;

		ul {
      margin-left: 18px;
    }
    ol {
      margin-left: 18px;
    }
	}

	.tokensContainer {
		display: flex;
		align-items: center;
		margin: 30px 0 40px 0;
	}

	.tokens {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #373737;
	}

	.durationContainer {
		display: flex;
		align-items: center;
	}

	.duration {
		font-size: 14px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #373737;
	}

	.durationValue {
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: right;
		color: #373737;
		margin-left: 10px;
	}

	.draftIndicator {
    display: flex;
    background: #DCDCDC;
    border-radius: 4px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #737373;
    padding: 5px;
		position: absolute;
    right: 25px;
  }
`

export default BenefitDetailsStyles
