import styled from 'styled-components'

const LocalBenefitsStyles = styled.section`
	width: calc(100% - 210px);
	margin-left: 210px;
	background: whitesmoke;
	min-height: 100vh;
	overflow-x: hidden;

	.localBenefitsContent {
		padding: 0 30px 30px 30px;
	}

	#localBenefitsList {
		opacity: 0;
		transition: all 0.4s ease-in-out;
	}

	.addContainer {
		width: 24px;
    height: 24px;
    background: #fff;
    border-radius: 50%;
    color: #373737;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
	}

	.buttonText {
		font-size: 14px;
    font-weight: 500;
    line-height: 24px;
	}

	.localBenefitsList {
		margin: 30px 0;
	}

	.cardView {
			transform: scale(30px);
			/* box-shadow: 2px 2px 20px 0 rgba(0, 0, 0, 0.08);	 */
			box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
					0 1px 10px 0 rgba(0, 0, 0, 0.12),
					0 4px 5px 0 rgba(0, 0, 0, 0.14);
	}

	.chosenBenefit {
		/* box-shadow: 2px 2px 10px 4px rgba(0, 0, 0, 0.25); */
		/* box-shadow: 2px 2px 20px 0 rgba(0, 0, 0, 0.08);	 */
		box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
	}
`

export default LocalBenefitsStyles
