import styled from "styled-components";

export const SubscribedBenefitGroupsStyles = styled.div`
  .benefitList {
    display: flex;
    max-width: 1440px;
    flex-wrap: wrap;
    margin: 30px 0 0 0;
  }

  .no-benefits-text {
    margin: 0 0 20px 0;
  }

  .mobileMyBenefits {
    display: none;
  }

  @media only screen and (max-width: 767px) {
    .desktopMyBenefits {
      display: none;
    }

    .mobileMyBenefits {
      display: block;

      .buttonsContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        .button {
          cursor: pointer;
          background: transparent;

          padding: 5px;
          border: 1px solid transparent;
          color: #1568bf;
        }

        .active {
          background: #1568bf;
          border: 1px solid #1568bf;
          color: #fff;
          border-radius: 20px;
          box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.108528);
        }
      }
    }
  }

  @media only screen and (max-width: 500px) {
    .button {
      font-size: 10px;
    }
  }
`;
