import React from "react";
// styles
import { AddEmployeeWithCSVErrorStyles } from "./AddEmployeeWithCSVErrorStyles";
// assets
import { ErrorIconSvg } from "../../../../../assets/Icons";
// components
import { Button } from "../../../../../components";
// utils
import { isEmpty } from "../../../../../services/general.utils";
import { useDispatch } from "react-redux";
import { setListOfFailedToBulkUpdateUsers } from "../../../../../actions/hr/employees.actions";

const UploadUpdateCSVFailed = ({ users, closeModal, errors }) => {
  const dispatch = useDispatch();

  /**
   * Get errors for render.
   * @param {Array} errors
   * @returns {Array}
   */
  const getErrorsForRender = (users, errors) => {
    const errorsToRender = [];

    if (!isEmpty(errors)) {
      errors.forEach(error => {
        if (error.email) {
          errorsToRender.push(
            `Email: ${Object.values(error.email.keys).join(", ")}`
          );
        }
        if (error.tokens) {
          errorsToRender.push(
            `Bonus Amount: ${Object.values(error.tokens.keys).join(", ")}`
          );
        }
        if (error.startDate) {
          errorsToRender.push(
            `Bonus Start Date: ${Object.values(error.startDate.keys).join(
              ", "
            )}`
          );
        }
        if (error.endDate) {
          errorsToRender.push(
            `Bonus End Date: ${Object.values(error.endDate.keys).join(", ")}`
          );
        }
      });

      return errorsToRender;
    }

    return users.map(user => user.message);
  };

  const handleClose = () => {
    closeModal();
    dispatch(setListOfFailedToBulkUpdateUsers([]));
  };

  return (
    <AddEmployeeWithCSVErrorStyles>
      <div>
        <ErrorIconSvg />
      </div>
      <div className="title">Adding bonus to these users failed</div>
      <div className="errors-wrapper">
        {(!isEmpty(users) || !isEmpty(errors)) &&
          getErrorsForRender(users, errors).map((value, index) => {
            return (
              <div key={index} className="error-message">
                {value}
              </div>
            );
          })}
      </div>

      <Button width="130px" onClick={handleClose} margin="20px 0 0 0">
        OK
      </Button>
    </AddEmployeeWithCSVErrorStyles>
  );
};

export default UploadUpdateCSVFailed;
