import React from 'react';
import PropTypes from 'prop-types';

const BLogoSvg = ({ color = "#1D1D1D99", width = "13", height = "12", opacity = "0.6" }) => (
    <svg xmlns="http://www.w3.org/2000/svg"
      width={width} 
      height={height} 
      viewBox="0 0 15 13" 
      fill="none" 
    >
      <path 
        fillRule="evenodd" 
        clipRule="evenodd" 
        d="M8.47502 10.6286H10.3521C11.4261 10.6286 11.9634 10.0323 11.9634 8.83928V8.57672C11.9634 7.9639 11.8276 7.52068 11.5565 7.24693C11.2849 6.97356 10.8836 6.8365 10.3521 6.8365H8.47502V10.6286ZM8.47502 5.16211H10.3355C10.7674 5.16211 11.0967 5.03648 11.3238 4.78447C11.5507 4.53296 11.6644 4.18823 11.6644 3.75041C11.6644 3.32352 11.5453 2.98709 11.3072 2.74085C11.069 2.49462 10.7396 2.37138 10.3189 2.37138H8.47502V5.16211ZM6.33216 11.8435V1.173C6.33216 0.965331 6.42613 0.801134 6.61458 0.68053C6.80264 0.560302 7.02413 0.5 7.27903 0.5H10.485C12.7109 0.5 13.8238 1.4632 13.8238 3.38923C13.8238 4.72454 13.353 5.59465 12.4119 5.9993C12.9767 6.21828 13.4028 6.52745 13.6909 6.92683C13.9787 7.32645 14.1228 7.90925 14.1228 8.67509V9.01981C14.1228 10.2347 13.8238 11.1186 13.2258 11.6711C12.6278 12.2237 11.8193 12.5 10.8005 12.5H7.27903C7.00202 12.5 6.775 12.4344 6.59787 12.303C6.4206 12.1717 6.33216 12.0186 6.33216 11.8435ZM0.876953 6.60107C0.945547 4.61537 1.59844 2.84362 2.97459 1.35893C3.51706 0.773621 4.08239 0.693846 4.54295 1.11156C4.96795 1.49712 4.9427 2.06924 4.47586 2.63457C2.28601 5.28648 2.30172 7.95473 4.52297 10.6407C4.94622 11.1525 4.95526 11.7264 4.54584 12.1082C4.12447 12.5013 3.51479 12.4345 3.0227 11.918C1.60472 10.4291 0.953964 8.6325 0.876953 6.60107Z" 
        fill={color} 
        fillOpacity={opacity}
      />
    </svg>            
);

BLogoSvg.propTypes = {
	color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  opacity: PropTypes.string,
};

export default BLogoSvg;
