import React from "react";
import PropTypes from "prop-types";

import { AddLocalBenefitGroup, EditLocalBenefitGroup } from "../BenefitGroup";
import { AddBenefit, EditBenefit } from "../Benefit";

const FormRenderer = ({
  modalFormType,
  setCurrentPage,
  fetchLocalBenefitGroups,
  closeModal,
  chosenBenefitGroupId,
  chosenBenefitId
}) => {
  switch (modalFormType) {
    case "addLocalBenefitGroup":
      return (
        <AddLocalBenefitGroup
          closeModal={closeModal}
          setCurrentPage={setCurrentPage}
          fetchLocalBenefitGroups={fetchLocalBenefitGroups}
        />
      );
    case "editLocalBenefitGroup":
      return (
        <EditLocalBenefitGroup
          closeModal={closeModal}
          fetchLocalBenefitGroups={fetchLocalBenefitGroups}
          chosenBenefitGroupId={chosenBenefitGroupId}
        />
      );
    case "addLocalBenefitTobenefitGroup":
      return (
        <AddBenefit
          closeModal={closeModal}
          chosenBenefitGroupId={chosenBenefitGroupId}
        />
      );
    case "editLocalBenefitOfBenefitGroup":
      return (
        <EditBenefit
          closeModal={closeModal}
          refetchData={fetchLocalBenefitGroups}
          chosenBenefitGroupId={chosenBenefitGroupId}
          chosenBenefitId={chosenBenefitId}
        />
      );
    default:
      return null;
  }
};

FormRenderer.propTypes = {
  modalFormType: PropTypes.string.isRequired,
  closeModal: PropTypes.func,
  chosenBenefitGroupId: PropTypes.number,
  chosenBenefitId: PropTypes.number,
  setCurrentPage: PropTypes.func,
  fetchLocalBenefitGroups: PropTypes.func
};

export default FormRenderer;
