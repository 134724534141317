import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const TableComponentStyles = styled.div`
  margin: ${({margin}) => margin };
`

const TableComponent = ({ children, margin = '30px 0 0 0' }) => (
	<TableComponentStyles margin={margin}>
		{children}
	</TableComponentStyles>
)

TableComponent.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.shape(),
		PropTypes.elementType,
		PropTypes.array
	]),
	margin: PropTypes.string,
}

export default TableComponent
