import React from 'react'
import PropTypes from 'prop-types'
// import styled from 'styled-components'

// const TokenIconStyles = styled.div`
// 	height: 24px;

// 	@media only screen and (max-width: 430px) {
// 		${({ isCardToken }) => isCardToken && 'height: 20px;'}

// 		svg {
// 			${({ isCardToken }) => isCardToken && 'height: 20px;'}
// 			${({ isCardToken }) => isCardToken && 'width: 20px;'}
// 		}
// 	}
// `

const TokenIcon = ({ height = '24', width = '24', margin = '0', isCardToken = false }) => (
	// <TokenIconStyles isCardToken={isCardToken}>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		width={width}
		height={height}
		style={{ margin }}
		viewBox="0 0 24 24"
	>
		<defs>
			<filter
				id="prefix__a"
				width="105%"
				height="110%"
				x="-2.5%"
				y="-2.5%"
				filterUnits="objectBoundingBox"
			>
				<feOffset
					dy="1"
					in="SourceAlpha"
					result="shadowOffsetOuter1"
				/>
				<feComposite
					in="shadowOffsetOuter1"
					in2="SourceAlpha"
					operator="out"
					result="shadowOffsetOuter1"
				/>
				<feColorMatrix
					in="shadowOffsetOuter1"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.107490166 0"
				/>
			</filter>
			<filter
				id="prefix__d"
				width="110%"
				height="115%"
				x="-5%"
				y="-5%"
				filterUnits="objectBoundingBox"
			>
				<feMorphology
					in="SourceAlpha"
					radius="2"
					result="shadowSpreadInner1"
				/>
				<feOffset
					in="shadowSpreadInner1"
					result="shadowOffsetInner1"
				/>
				<feComposite
					in="shadowOffsetInner1"
					in2="SourceAlpha"
					k2="-1"
					k3="1"
					operator="arithmetic"
					result="shadowInnerInner1"
				/>
				<feColorMatrix
					in="shadowInnerInner1"
					values="0 0 0 0 0.952672101 0 0 0 0 0.788289464 0 0 0 0 0 0 0 0 1 0"
				/>
			</filter>
			<filter
				id="prefix__e"
				width="132.9%"
				height="122.9%"
				x="-16.4%"
				y="-5.7%"
				filterUnits="objectBoundingBox"
			>
				<feOffset
					dy="1"
					in="SourceAlpha"
					result="shadowOffsetOuter1"
				/>
				<feColorMatrix
					in="shadowOffsetOuter1"
					values="0 0 0 0 0.0101619112 0 0 0 0 0.0101619112 0 0 0 0 0.0101619112 0 0 0 0.150021853 0"
				/>
			</filter>
			<filter
				id="prefix__g"
				width="117.3%"
				height="122%"
				x="-8.6%"
				y="-5.5%"
				filterUnits="objectBoundingBox"
			>
				<feOffset
					dy="1"
					in="SourceAlpha"
					result="shadowOffsetOuter1"
				/>
				<feColorMatrix
					in="shadowOffsetOuter1"
					values="0 0 0 0 0.0101619112 0 0 0 0 0.0101619112 0 0 0 0 0.0101619112 0 0 0 0.150021853 0"
				/>
			</filter>
			<path
				id="prefix__f"
				d="M0 4.763C.053 3.258.553 1.916 1.607.792c.416-.444.85-.504 1.202-.188.326.293.306.726-.051 1.154-1.678 2.009-1.666 4.03.036 6.064.324.388.33.823.017 1.112-.323.298-.79.247-1.167-.144C.558 7.662.06 6.3 0 4.763"
			/>
			<path
				id="prefix__h"
				d="M4.224 6.355c0 .903-.4 1.354-1.198 1.354H1.632V4.84h1.394c.395 0 .694.104.895.31.202.208.303.543.303 1.007v.198zM1.632 1.462h1.37c.312 0 .557.093.734.28.177.185.265.44.265.763 0 .331-.084.592-.253.783-.168.19-.413.285-.734.285H1.632V1.462zm3.875 3.446c-.214-.302-.53-.536-.95-.701.7-.307 1.049-.965 1.049-1.975C5.606.774 4.779.046 3.125.046H.743a.876.876 0 00-.494.136.427.427 0 00-.209.373v8.073c0 .133.066.249.198.348.131.1.3.15.506.15H3.36c.757 0 1.357-.21 1.802-.628.444-.418.666-1.087.666-2.006v-.26c0-.58-.107-1.021-.32-1.324z"
			/>
			<radialGradient
				id="prefix__c"
				cx="50%"
				cy="0%"
				r="97.481%"
				fx="50%"
				fy="0%"
			>
				<stop offset="0%" stopColor="#FFD300" />
				<stop offset="41.69%" stopColor="#FFD300" />
				<stop offset="100%" stopColor="#FFDE30" />
			</radialGradient>
			<circle id="prefix__b" cx="12" cy="12" r="10" />
		</defs>
		<g fill="none" fillRule="evenodd">
			<path d="M0 0h24v24H0z" />
			<use fill="#000" filter="url(#prefix__a)" xlinkHref="#prefix__b" />
			<use fill="url(#prefix__c)" xlinkHref="#prefix__b" />
			<use fill="#000" filter="url(#prefix__d)" xlinkHref="#prefix__b" />
			<circle
				cx="12"
				cy="12"
				r="9.5"
				stroke="#C37C01"
				fill="#ffd300"
				strokeLinejoin="square"
			/>
			<g transform="translate(7 7)">
				<use
					fill="#000"
					filter="url(#prefix__e)"
					xlinkHref="#prefix__f"
				/>
				<use fill="#C37C01" xlinkHref="#prefix__f" />
				<g transform="translate(4.056)">
					<use
						fill="#000"
						filter="url(#prefix__g)"
						xlinkHref="#prefix__h"
					/>
					<use fill="#C37C01" xlinkHref="#prefix__h" />
				</g>
			</g>
		</g>
	</svg>
	// </TokenIconStyles>
)

TokenIcon.propTypes = {
	isCardToken: PropTypes.bool,
	height: PropTypes.string,
	width: PropTypes.string,
	margin: PropTypes.string
}

export default TokenIcon
