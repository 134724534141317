import styled from "styled-components";
import {
  ACTIVE,
  PENDING_ACTIVATION,
  PENDING_DEACTIVATION
} from "../../builders/benefitGroups/benefitGroup.status";

const BenefitCardStyles = styled.div`
  width: 290px;
  margin: 0 30px 40px 0;
  border-radius: 4px;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.11);
  border: solid 1px #e6eaee;
  background-color: #ffffff;
  cursor: pointer;
  position: relative;
  transition: background-color 0.5s ease-in;

  &:hover {
    background-color: #a1d8f280;
  }

  .favoriteButton {
    border: none;
    background: transparent;
    cursor: pointer;
  }

  .benefitCardWrapper {
    width: 290px;
    height: 418px;
    border-radius: 4px;
    border: ${({ status }) => {
      if (status) {
        if (status === ACTIVE) {
          return "2px solid #3DBE52";
        } else if (status === PENDING_ACTIVATION) {
          return "2px solid #84988E";
        } else if (status === PENDING_DEACTIVATION) {
          return "2px solid #C53C3C";
        }
      } else {
        return "2px solid transparent";
      }
    }};
    transition: border 0.5s ease-in;

    &:hover {
      border: ${({ status }) => {
        const haveStatus =
          status === ACTIVE ||
          status === PENDING_ACTIVATION ||
          status === PENDING_DEACTIVATION;

        if (!haveStatus) {
          return "2px solid #0b75a6";
        }
      }};
    }
  }

  .mcpTooltip {
    width: 370px;
  }

  .imgContainer {
    width: 100%;
    height: 140px;
    overflow: hidden;
    position: relative;

    .benefitImage {
      position: relative;
      height: 100%;
      width: 100%;
    }

    .newIndicator {
      position: absolute;
      left: 10px;
      top: 10px;
      background: linear-gradient(
        256.87deg,
        #f4c51d 7.21%,
        #ffee97 28.6%,
        #f4c51d 92.79%
      );
      border-radius: 4px;
      font-size: 13px;
      line-height: 13px;
      letter-spacing: -0.2px;
      color: #2f68b9;
      padding: 5px;
      font-weight: 600;
    }
  }

  .topContent {
    height: 120px;
    position: relative;
    padding-top: 5px;

    .providerImageOuterCircle {
      width: 66px;
      height: 66px;
      border-radius: 50%;
      position: absolute;
      background: #fff;
      left: 8px;
      top: -30px;
      display: flex;
      align-items: center;
      justify-content: center;

      .providerImageInnerCircle {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        .providerLogo {
          position: relative;
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }
    }

    .ratingContainer {
      margin-top: 4px;
      margin-left: 10px;
      padding-right: 10px;

      .starContainer {
        font-size: 14px;
        letter-spacing: -0.2px;
        line-height: 15px;
        font-weight: 500;
        color: #87919e;

        .total {
          width: 18px;
        }
      }
    }

    .titleAndTokensContainer {
      margin-left: 10px;
      padding-right: 10px;
      margin-top: 30px;

      .title {
        height: 42px;
        font-size: 17px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 21px;
        letter-spacing: normal;
        color: #354052;
        word-break: break-word;
      }
    }
  }

  .descriptionContainer {
    height: 70px;
    margin: 10px;
    position: relative;

    .providerDescription {
      width: 100%;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.36;
      letter-spacing: normal;
      color: #354052;
      word-wrap: break-word;
    }

    .taxes {
      width: 100%;
      margin-bottom: 10px;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: italic;
      line-height: 1.36;
      letter-spacing: normal;
      color: #354052;
      word-wrap: break-word;
    }
  }

  .hrDescriptionContainer {
    height: 100px;
    margin: 20px 12px 11px 9px;
    position: relative;

    .providerDescription {
      width: 100%;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.36;
      letter-spacing: normal;
      color: #354052;
      word-wrap: break-word;
    }

    .taxes {
      width: 100%;
      margin-bottom: 6px;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: italic;
      line-height: 1.36;
      letter-spacing: normal;
      color: #354052;
      word-wrap: break-word;
    }
  }

  .locations-container {
    margin: 5px 10px;
    position: relative;

    .cities {
      font-weight: 500;
      display: inline;
      font-size: 12px;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: -0.2px;
      color: #5f5f5f;
    }
  }

  .hr-locations-container {
    position: absolute;
    margin-left: 10px;
    bottom: 44px;

    .cities {
      font-weight: 500;
      display: inline;
      font-size: 12px;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: -0.2px;
      color: #5f5f5f;
    }
  }

  .categoryTag {
    display: inline-block;
    .categoryText {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      color: #2f68b9;
    }
  }

  .priceAncCapacityContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 10px 6px 10px;
    position: relative;

    .tokensContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      svg {
        width: 25px;
        height: 25px;

        &:hover {
          fill: red;
        }
      }

      .mcpContainer {
        margin-left: 5px;
        display: flex;
        align-items: center;
        border: 1px solid #e6eaee;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 4px;
        & > span {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 500;
          font-size: 11px;
          line-height: 13px;
          color: #87919e;
        }
      }
      .tokensContent {
        display: flex;
        align-items: center;

        .price {
          opacity: 0.8;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 2;
          letter-spacing: -0.2px;
          color: #373737;
          margin-left: 4px;
        }
      }
    }
    .capacityContainer {
      display: flex;
      align-items: center;
      .price {
        opacity: 0.8;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: -0.2px;
        color: #373737;
        margin-left: 4px;
      }
    }
  }

  .enabledStatus {
    position: absolute;
    right: 11px;
    bottom: 12px;
    display: flex;

    .enabled {
      display: flex;
      align-items: center;
      gap: 5px;
      color: #41c264;
      font-size: 14px;

      .green-dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #41c264;
      }
    }
    .disabled {
      display: flex;
      align-items: center;
      gap: 5px;
      color: #ce1212;
      font-size: 14px;

      .red-dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #ce1212;
      }
    }
  }

  .activeContainer {
    height: 30px;
    min-width: 290px;
    margin: 0 -2px;
    width: 100%;
    border-radius: 0px 0px 4px 4px;
    background: ${({ status }) => {
      if (status) {
        if (status === ACTIVE) {
          return "#3DBE52";
        } else if (status === PENDING_ACTIVATION) {
          return "#84988E";
        } else if (status === PENDING_DEACTIVATION) {
          return "#C53C3C";
        }
      }
    }};
    display: flex;
    align-items: center;
    padding: 0 12px;

    .activeText {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: -0.2px;
      color: #ffffff;
      margin-left: 4px;
    }
  }

  @media only screen and (max-width: 767px) {
    margin: 0 0 30px 0;
  }

  @media only screen and (max-width: 500px) {
    width: 100%;
    margin: 0 0 30px 0;

    .benefitCardWrapper {
      width: 100%;
    }
  }

  @media screen and (max-width: 320px) {
    .activeContainer {
      min-width: 248px;
    }

    .mcpTooltip {
      width: 280px;
      font-size: 11px;
    }
  }
`;

export default BenefitCardStyles;
