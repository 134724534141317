import React from "react";
import Pagination from "react-pagination-js";
import { setFilters } from "../../../actions/hr/refunds.actions";
import { useDispatch, useSelector } from "react-redux";

export const RefundPagination = ({ filters }) => {
  const dispatch = useDispatch();
  const count = useSelector(state => state.refundsReducer.totalCount);

  const handlePageChange = page => {
    dispatch(setFilters({ ...filters, page }));
  };

  return (
    <div className="paginationWrapper">
      <Pagination
        currentPage={filters.page}
        totalSize={count}
        sizePerPage={filters.perPage}
        changeCurrentPage={page => handlePageChange(page)}
        theme="border-bottom"
      />
    </div>
  );
};
