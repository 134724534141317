class UploadPDFPayload {
  payload = new FormData();
  constructor(values = {}) {
    this.payload.append("benefit_group_id", values.benefitGroupId);
    this.payload.append("voucher_id", values.voucherId);
    this.payload.append("file", values.pdfFile);
  }
}

export default UploadPDFPayload;
