import styled from "styled-components";

const VoucherReimbursementStyles = styled.div`
  width: 650px; 
  max-width: 100%;
  height: 700px;
  max-height: 100vh;
  padding: 60px 30px;

  .recipe {
    padding: 20px;
    border: solid 2px #2F68B9;
    border-radius: 4px;
    box-shadow: 0px 4px 4px 0px #00000040;
    line-height: 2rem;

    h2 {
      text-align: center;
      margin: 10px;
    }
  }

  .recipeButtons {
    text-align: center;
    margin: 10%;
    display: flex;
    flex-direction: column;
  }

  @media only screen and (max-width: 600px){
    padding: 40px 10px;

    .recipe {
      padding: 15px;
    }
  }

  @media only screen and (max-width: 370px){
    h2 {
      font-size: 1em;
    }
    p {
      font-size: 0.677rem;
    }
    .mobileButton {
      font-size: 0.8rem;
    }
  }

`;

export default VoucherReimbursementStyles