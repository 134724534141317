const moment = require("moment");

function isWithinLastMonth(dateString) {
  const dateToCheck = moment(dateString);
  const oneMonthAgo = moment().subtract(1, "months");
  const currentDate = moment();

  // Check if the date is within the last 1 months
  return dateToCheck.isBetween(oneMonthAgo, currentDate, null, "[]");
}

export default isWithinLastMonth;
