//Entities
import { PngPhoto } from "../../services/global.entities";
// Constants
import { CompanyThemeModeEnum } from "../../constants/companyThemeMode.constants";

class CompanyTheme {  
  constructor(themeData = {}) {
    this.id = themeData.id || null;
    this.mode = themeData.mode || CompanyThemeModeEnum.LIGHT;
    this.photo = themeData.Photo ? new PngPhoto(themeData.Photo) : null;
    this.companyId = themeData.companyId  || null;
    this.color = themeData.color || null;
  }
}

export default CompanyTheme;