import { splitStringByDelimiter } from "../../services/general.utils";
import Benefit from "./Benefit.entity";
import BenefitGroup from "./BenefitGroup.entity";

class UserBenefit {
  constructor(userBenefitData = {}) {
    this.subscribed = userBenefitData.subscribed || null;
    this.unsubscribe = userBenefitData.unsubscribe || null;
    this.Status = userBenefitData.Status || null;
    this.startDate =
      userBenefitData.startDate || userBenefitData.start_date || null;
    this.endDate = userBenefitData.endDate || userBenefitData.end_date || null;
    this.title = userBenefitData.Benefit ? userBenefitData.Benefit.title : "";
    this.benefitId = userBenefitData.benefitId ? userBenefitData.benefitId : "";
    this.benefitPrice =
      userBenefitData.benefitPrice || userBenefitData.benefit_price || 0;
    this.expiration = userBenefitData.Benefit
      ? splitStringByDelimiter(userBenefitData.Benefit.expiration, "_")
      : "";
    this.benefitGroup = userBenefitData.BenefitGroup
      ? new BenefitGroup(userBenefitData.BenefitGroup)
      : null;
    this.benefit = userBenefitData.Benefit
      ? new Benefit(userBenefitData.Benefit)
      : null;
    this.quantity = userBenefitData.quantity || 1;
  }
}

export default UserBenefit;
