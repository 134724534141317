import React from "react";
import { getIn } from "formik";
import PropTypes from "prop-types";

const CurrencyField = ({
  field: { name, value },
  form: { errors, touched, setFieldValue, handleChange, handleBlur },
  tokenValue,
  tokenValueName
}) => {
  const errorMessage = getIn(errors, name);
  const touchedInput = getIn(touched, name);

  const handleNumberInput = (e, previousValue) => {
    let inputValue = e.target.validity.valid
      ? parseInt(e.target.value, 0)
      : previousValue;
    if (isNaN(inputValue)) {
      inputValue = "";
    }
    setFieldValue(e.target.name, inputValue);
    if (inputValue % tokenValue.worth === 0) {
      setFieldValue(
        tokenValueName,
        inputValue === "" ? "" : inputValue / tokenValue.worth
      );
    } else {
      setFieldValue(tokenValueName, "");
    }
  };

  return (
    <div style={{ height: 57 }}>
      <p className="errorMsg" style={{ height: 10, marginBottom: 5 }}>
        {touchedInput ? errorMessage : ""}
      </p>
      <div className="currencyContainer">
        <input
          className="currencyValueInput"
          name={name}
          type="string"
          value={value}
          onChange={handleNumberInput}
          onBlur={handleBlur}
          // disabled
        />
        <p className="label">{tokenValue.currency}</p>
      </div>
    </div>
  );
};

CurrencyField.propTypes = {
  field: PropTypes.shape(),
  form: PropTypes.shape(),
  tokenValue: PropTypes.shape(),
  tokenValueName: PropTypes.string
};

export default CurrencyField;
