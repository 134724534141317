import { toast } from "react-toastify";
import { axiosInstance } from "../../../services/http/axiosConfig";

export const getCompanies = async ({
  limit,
  page,
  searchText,
  orderColumns
}) => {
  try {
    const searchQuery = searchText ? `&search=${searchText}` : "";
    let orderQuery = "";
    if (orderColumns.length > 0) {
      orderQuery = orderColumns.reduce(
        (acc, column) =>
          `${acc}&order=${column.field} ${column.isSortedUp ? "ASC" : "DESC"}`,
        ""
      );
    }

    const { data } = await axiosInstance({
      method: "get",
      url: `/company/get?limit=${limit}&page=${page}${searchQuery}${orderQuery}`
    });
    if (data) {
      return {
        ...data,
        companies: data.companies
      };
    }
  } catch (error) {
    if (error && error.response) {
      const { data } = error.response;
      if (data) {
        toast.error(data.error.message);
      }
    } else {
      toast.error("Something went wrong");
    }
  }
};

export const getCompanyTags = async () => {
  try {
    const { data, statusText } = await axiosInstance({
      method: "get",
      url: "/company/tags"
    });

    if (data) {
      return {
        statusText,
        tags: data.tags
      };
    }
  } catch (error) {
    if (error && error.response) {
      const { data } = error.response;
      if (data) {
        toast.error(data.error.message);
      }
    } else {
      toast.error("Something went wrong");
    }
  }
};

export const createCompany = async newCompany => {
  const payload = new FormData();
  let paymentDeadlineDaysNumber;
  if (newCompany.paymentDeadline) {
    paymentDeadlineDaysNumber = parseInt(
      newCompany.paymentDeadline.substr(
        0,
        newCompany.paymentDeadline.indexOf(" ")
      ),
      0
    );
  }

  payload.append("name", newCompany.name);
  payload.append("address", newCompany.address);
  payload.append("pib", newCompany.pib);
  payload.append("identification_number", newCompany.identificationNumber);
  payload.append("contact", newCompany.contact);

  payload.append("invoice_email", newCompany.invoiceEmail);
  payload.append("status", newCompany.status.toLowerCase());
  payload.append("payment_deadline", paymentDeadlineDaysNumber);
  payload.append("photo", newCompany.photo);
  newCompany.tags.forEach(tag => {
    payload.append("tags", tag.id);
  });
  if (paymentDeadlineDaysNumber) {
    try {
      const { data } = await axiosInstance({
        method: "post",
        url: "/company/create",
        data: payload
      });
      if (data) {
        return data;
      }
    } catch (error) {
      if (error && error.response) {
        const { data } = error.response;
        if (data) {
          if (data.errors && data.error) {
            if (data.errors.name) {
              toast.error(`${data.error.message}: ${data.errors.name.keys[0]}`);
            }
            if (data.errors.address) {
              toast.error(
                `${data.error.message}: ${data.errors.address.keys[0]}`
              );
            }
            if (data.errors.pib) {
              toast.error(`${data.error.message}: ${data.errors.pib.keys[0]}`);
            }
            if (data.errors.idNumber) {
              toast.error(
                `${data.error.message}: ${data.errors.idNumber.keys[0]}`
              );
            }
            if (data.errors.contact) {
              toast.error(
                `${data.error.message}: ${data.errors.contact.keys[0]}`
              );
            }
            if (data.errors.invoiceEmail) {
              toast.error(
                `${data.error.message}: ${data.errors.invoiceEmail.keys[0]}`
              );
            }
            if (data.errors.status) {
              toast.error(
                `${data.error.message}: ${data.errors.status.keys[0]}`
              );
            }
            if (data.errors.paymentDeadline) {
              toast.error(
                `${data.error.message}: ${data.errors.paymentDeadline.keys[0]}`
              );
            }
            if (data.errors.photo) {
              toast.error(
                `${data.error.message}: ${data.errors.photo.keys[0]}`
              );
            }
          } else {
            toast.error(data.error.message);
          }
        }
      } else {
        toast.error("Something went wrong");
      }
    }
  } else {
    toast.error("Something is wrong with payment deadline");
  }
};

export const deleteCompany = async id => {
  try {
    const { data } = await axiosInstance({
      method: "delete",
      url: `/company/delete/${id}`
    });
    if (data) {
      return data;
    }
  } catch (error) {
    if (error && error.response) {
      const { data } = error.response;
      if (data) {
        toast.error(data.error.message);
      }
    } else {
      toast.error("Something went wrong");
    }
  }
};

export const getSelectedCompany = async id => {
  try {
    const { data } = await axiosInstance({
      method: "get",
      url: `/company/${id}`
    });
    if (data) {
      return data;
    }
  } catch (error) {
    if (error && error.response) {
      const { data } = error.response;
      if (data) {
        toast.error(data.error.message);
      }
    } else {
      toast.error("Something went wrong");
    }
  }
};

export const updateCompany = async company => {
  let paymentDeadlineDaysNumber;
  if (company.paymentDeadline) {
    paymentDeadlineDaysNumber = parseInt(
      company.paymentDeadline.substr(0, company.paymentDeadline.indexOf(" ")),
      0
    );
  }
  const payload = new FormData();
  payload.append("name", company.name);
  payload.append("address", company.address);
  payload.append("pib", company.pib);
  payload.append("identification_number", company.identificationNumber);
  payload.append("contact", company.contact);

  payload.append("invoice_email", company.invoiceEmail);
  payload.append("status", company.status.toLowerCase());
  payload.append("payment_deadline", paymentDeadlineDaysNumber);
  if (company.photo instanceof File) {
    payload.append("photo", company.photo);
  }
  company.tags.forEach(tag => {
    payload.append("tags", tag.id);
  });

  if (paymentDeadlineDaysNumber) {
    try {
      const { status } = await axiosInstance({
        method: "put",
        url: `/company/update/${company.id}`,
        data: payload
      });
      return {
        status
      };
    } catch (error) {
      if (error && error.response) {
        const { data } = error.response;
        if (data) {
          if (data.errors && data.error) {
            if (data.errors.name) {
              toast.error(`${data.error.message}: ${data.errors.name.keys[0]}`);
            }
            if (data.errors.address) {
              toast.error(
                `${data.error.message}: ${data.errors.address.keys[0]}`
              );
            }
            if (data.errors.pib) {
              toast.error(`${data.error.message}: ${data.errors.pib.keys[0]}`);
            }
            if (data.errors.idNumber) {
              toast.error(
                `${data.error.message}: ${data.errors.idNumber.keys[0]}`
              );
            }
            if (data.errors.contact) {
              toast.error(
                `${data.error.message}: ${data.errors.contact.keys[0]}`
              );
            }
            if (data.errors.invoiceEmail) {
              toast.error(
                `${data.error.message}: ${data.errors.invoiceEmail.keys[0]}`
              );
            }
            if (data.errors.status) {
              toast.error(
                `${data.error.message}: ${data.errors.status.keys[0]}`
              );
            }
            if (data.errors.paymentDeadline) {
              toast.error(
                `${data.error.message}: ${data.errors.paymentDeadline.keys[0]}`
              );
            }
            if (data.errors.photo) {
              toast.error(
                `${data.error.message}: ${data.errors.photo.keys[0]}`
              );
            }
          } else {
            toast.error(data.error.message);
          }
        }
      } else {
        toast.error("Something went wrong");
      }
    }
  } else {
    toast.error("Something is wrong with payment deadline");
  }
};
