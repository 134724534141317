import styled from "styled-components";

const DetailsStyles = styled.div`
  margin: 60px 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 30px;

  .title {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4b4b4b;
    margin-bottom: 30px;
  }

  .labelAndValidation {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .infoContainer {
    .inputContainer {
      width: 300px;
    }
  }

  .familyContainer {
    .relationshipContainer {
      width: 150px;
    }

    .checkboxesContainer {
      display: flex;
      align-items: flex-end;

      .checkboxContainer {
        height: 27px;
        display: flex;
        align-items: center;
        height: 100%;
        margin-right: 60px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .citiesContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    /*
		.relationshipContainer {
			width: 150px;
			margin-right: 20px;
		}

		.checkboxesContainer {
			display: flex;
			align-items: flex-end;

		.checkboxContainer {
			height: 27px;
			display: flex;
			align-items: center;
			height: 100%;
			margin-right: 60px;

			&:last-child {
				margin-right: 0;
			}
		}
	}

	.description {
		margin-top: 10px;
		font-size: 16px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #5d5d5d;
		background: #d8d8d8;
		padding: 5px;
		border-radius: 4px;
		overflow: hidden;
		text-transform: capitalize;
		ul {
        margin-left: 18px;
    }
    ol {
      margin-left: 18px;
    }
	}*/

    .checkboxStatusChecker {
      width: 20px;
      height: 21px;
      border-radius: 4px;
      border: solid 1px #979797;
      margin-bottom: 5px;
    }

    .haveChildren {
      background: ${({ haveChildren }) => (haveChildren ? "#d8d8d8" : "#fff")};
    }

    .isMarried {
      background: ${({ isMarried }) => (isMarried ? "#d8d8d8" : "#fff")};
    }

    .textInfo {
      font-size: 16px;
      min-height: 29px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #4b4b4b;
      background: #f7f7f7;
      padding: 5px 5px;
      overflow: hidden;
      border-radius: 4px;
    }
  }

  @media screen and (max-width: 500px) {
    .infoContainer {
      width: 100%;
      .inputContainer {
        width: 100%;
      }
    }

    .familyContainer {
      width: 100%;

      .relationshipContainer {
        width: 100%;
      }
    }

    .citiesContainer {
      width: 100%;
    }
  }
`;

export default DetailsStyles;
