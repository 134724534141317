export const SET_INITAL_STATE = "EMPLOYEE_BENEFITS_SET_INITAL_STATE";
export const UPDATE_BENEFIT_GROUP = "EMPLOYEE_UPDATE_BENEFIT_GROUP";
export const SET_IS_SUBSCRIBED_BENEFIT_GROUPS_VISIBLE =
  "EMPLOYEE_BENEFITS_SET_IS_SUBSCRIBED_BENEFIT_GROUPS_VISIBLE";

export const SET_BENEFIT_GROUP_TYPE = "SET_BENEFIT_GROUP_TYPE";
export const SET_IS_INITIAL_STATE_SET = "SET_IS_INITIAL_STATE_SET";
export const SET_IS_PAGE_LOADING = "SET_IS_PAGE_LOADING";
export const SET_CHOSEN_CATEGORY_IDS =
  "EMPLOYEE_BENEFITS_SET_CHOSEN_CATEGORY_IDS";
export const SET_CITY_IDS_FILTERS = "SET_CITY_IDS_FILTERS";
export const SET_REMOTE_FILTER = "SET_REMOTE_FILTER";
export const SET_FILTERS_PRICE_RANGE =
  "EMPLOYEE_BENEFITS_SET_FILTERS_PRICE_RANGE";
export const SET_BENEFIT_EXPIRATION_TYPES_FILTERS =
  "SET_BENEFIT_EXPIRATION_TYPES_FILTERS";
export const SET_SEARCH_FILTERS = "SET_SEARCH_FILTERS";
export const SET_ORDER_SORT = "SET_ORDER_SORT";
export const SET_KEY_SORT = "SET_KEY_SORT";
export const SET_ADITIONAL_BENEFIT_GROUPS =
  "EMPLOYEE_BENEFITS_SET_ADITIONAL_BENEFIT_GROUPS";
export const SET_BENEFIT_GROUPS_PAGE =
  "EMPLOYEE_BENEFITS_SET_BENEFIT_GROUPS_PAGE";
export const APPLY_BENEFIT_GROUPS_FILTERS =
  "EMPLOYEE_BENEFITS_APPLY_BENEFIT_GROUPS_FILTERS";
export const RESET_BENEFIT_GROUPS_FILTERS =
  "EMPLOYEE_BENEFITS_RESET_BENEFIT_GROUPS_FILTERS";
export const RESET_BENEFIT_GROUPS_FILTERS_TO_DEFAULT =
  "RESET_BENEFIT_GROUPS_FILTERS_TO_DEFAULT";
export const OPEN_BENEFIT_GROUP_MODAL =
  "EMPLOYEE_BENEFITS_OPEN_BENEFIT_GROUP_MODAL";
export const SET_CHOSEN_BENEFIT_GROUP = "SET_CHOSEN_BENEFIT_GROUP";
export const CLOSE_BENEFIT_GROUP_MODAL =
  "EMPLOYEE_BENEFITS_CLOSE_BENEFIT_GROUP_MODAL";
export const SET_BENEFIT_GROUPS_AND_SUBSCRIBED_BENEFIT_GROUPS =
  "EMPLOYEE_BENEFITS_SET_BENEFIT_GROUPS_AND_SUBSCRIBED_BENEFIT_GROUPS";
export const SET_SUBSCRIBED_BENEFIT_GROUPS =
  "EMPLOYEE_SET_SUBSCRIBED_BENEFIT_GROUPS";
export const SET_NEXT_MONTH_FILTER_CONDITION =
  "EMPLOYEE_BENEFITS_SET_NEXT_MONTH_FILTER_CONDITION";
export const SET_NEXT_MONTH_TOOLTIP_IS_OPEN =
  "EMPLOYEE_BENEFITS_SET_NEXT_MONTH_TOOLTIP_IS_OPEN ";
export const SET_LIST_OF_BROWSE_BENEFITS = "SET_LIST_OF_BROWSE_BENEFITS";
export const SET_LIST_OF_COMPANY_BENEFITS = "SET_LIST_OF_COMPANY_BENEFITS";
export const SET_FAVORITE_TAB_OPEN = "SET_FAVORITE_TAB_OPEN";
export const SET_LIST_OF_FAVORITES = "SET_LIST_OF_FAVORITES";
export const CHECK_IF_IN_FAVORITES = "CHECK_IF_IN_FAVORITES";
export const SET_USED_BENEFITS = "SET_USED_BENEFITS";
export const SET_SOCIAL_PROOF_FOR_SINGLE_BENEFIT_GROUP =
  "SET_SOCIAL_PROOF_FOR_SINGLE_BENEFIT_GROUP";
export const SET_CURRENTLY_POPULAR_BENEFIT = "SET_CURRENTLY_POPULAR_BENEFIT";
