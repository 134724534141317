import React from "react";
import PropTypes from "prop-types";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// components
import {
  Input,
  InputLabel,
  TextAndLine,
  MyDropZone,
  Button
} from "../../../../../../components";
import ToggleButton from "./toggleButton";
// styles
import ProviderFormStyles from "./ProviderFormStyles";
// Utils
import { convertStringToEditorState } from "../../../../../../services/textEditor.utils";
import { useTranslation } from "react-i18next";

const ProviderForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  buttonText,
  setFieldTouched,
  setFieldValue,
  closeModal,
  disableButton
}) => {
  const { t } = useTranslation();
  const toggleIsActive = () => {
    const enabledStatus = !values.isActive;
    setFieldValue("isActive", enabledStatus);
  };

  return (
    <ProviderFormStyles>
      <TextAndLine title={t("basic_info")} />
      <div style={{ margin: "20px 0" }}>
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: 24
            }}
          >
            <InputLabel
              customCssClass="labelStyles"
              margin=" 0 0 5px 0"
              label={t("Name")}
            />
            <div className="errorMsg" style={{ marginBottom: 5 }}>
              {errors.name && touched.name ? errors.name : ""}
            </div>
          </div>
          <Input
            name="name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            shouldHaveHight={false}
            customCssClass="inputStyles"
          />
        </div>
        <div className="summaryContainer">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: 24
            }}
          >
            <InputLabel
              customCssClass="labelStyles"
              margin=" 0 0 5px 0"
              label={t("Summary")}
            />
            <div className="errorMsg" style={{ marginBottom: 5 }}>
              {errors.summary && touched.summary ? errors.summary : ""}
            </div>
          </div>
          <textarea
            name="summary"
            value={values.summary}
            className="descriptionInput"
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="descriptionContainer">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: 24
            }}
          >
            <InputLabel
              customCssClass="labelStyles"
              margin=" 0 0 5px 0"
              label={t("Description")}
            />
            <div className="errorMsg" style={{ marginBottom: 5 }}>
              {errors.description && touched.description
                ? errors.description
                : ""}
            </div>
          </div>
          <Editor
            onBlur={() => {
              setFieldTouched("description", true);
            }}
            editorState={convertStringToEditorState(values.description)}
            editorStyle={{
              fontSize: 14
            }}
            stripPastedStyles={true}
            onEditorStateChange={editorState =>
              setFieldValue("description", editorState)
            }
            toolbar={{
              options: [
                "inline",
                "fontSize",
                "list",
                "textAlign",
                "colorPicker",
                "link",
                "emoji",
                "remove",
                "history"
              ],
              fontSize: {
                options: [10, 11, 12, 13, 14, 16, 18]
              }
            }}
          />
        </div>
      </div>
      <TextAndLine title={t("Photo")} />
      <div style={{ margin: "20px 0" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: 24
          }}
        >
          <InputLabel
            customCssClass="labelStyles"
            margin=" 0 0 5px 0"
            label=""
            shouldHaveColon={false}
          />
          <div className="errorMsg" style={{ marginBottom: 5 }}>
            {errors.photo && touched.photo ? errors.photo : ""}
          </div>
        </div>
        <MyDropZone
          name="photo"
          onBlur={setFieldTouched}
          photo={values.photo}
          handleChange={setFieldValue}
        />
      </div>
      <div style={{ margin: "20px 0" }}>
        <ToggleButton isActive={values.isActive} onToggle={toggleIsActive} />
      </div>
      <div style={{ display: "flex", margin: "20px 0 10px 0" }}>
        {disableButton ? (
          <Button width="130px" disabled margin="0 30px 0 0">
            {t("please_wait")}
          </Button>
        ) : (
          <Button width="130px" type="submit" margin="0 30px 0 0">
            {t(buttonText)}
          </Button>
        )}
        <Button
          width="130px"
          onClick={() => closeModal()}
          margin="0 0 0 0"
          outline
        >
          {t("cancel")}
        </Button>
      </div>
    </ProviderFormStyles>
  );
};

ProviderForm.propTypes = {
  values: PropTypes.shape(),
  errors: PropTypes.shape(),
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  setFieldValue: PropTypes.func,
  touched: PropTypes.shape(),
  setFieldTouched: PropTypes.func,
  buttonText: PropTypes.string,
  closeModal: PropTypes.func,
  disableButton: PropTypes.bool
};

export default ProviderForm;
