import React from 'react'
import PropTypes from 'prop-types'

import InitialsCircleStyles from './InitialCircleStyles'

import getInitals from '../../services/getInitals'

const InitialsCircle = ({
	size = 25,
	background = '#1568bf',
	color = '#fff',
	fontSize = 48,
	value,
	isFirstLetter = false
}) => (
	<InitialsCircleStyles
		size={size}
		background={background}
		color={color}
		fontSize={fontSize}
	>
		{getInitals(value, isFirstLetter)}
	</InitialsCircleStyles>
)

InitialsCircle.propTypes = {
	size: PropTypes.number,
	fontSize: PropTypes.number,
	value: PropTypes.string,
	background: PropTypes.string,
	color: PropTypes.string,
	isFirstLetter: PropTypes.bool
}

export default InitialsCircle
