import styled from "styled-components";

export const BenefitSubscribeQuantityStyles = styled.div`
  position: relative;
  height: 46px;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: flex-end;
  margin: 0 5px;
  
  .decrementButton {
    width: 33px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border: 1px solid #1568bf;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
  }

  .incrementButton {
    width: 33px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border: 1px solid #1568bf;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
  }

  .quantityInput {
    text-align: center;
    width: 70px;
    height: 26px;
  }
`;
