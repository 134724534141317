import { PngPhoto } from '../../../services/global.entities'


class Tier {
	constructor(tierData = {}) {
		this.id = tierData.id || null
		this.name = tierData.name || ''
		this.companyId = tierData.companyId || null
		this.tokens = tierData.tokens || 0
	}
}

class Tag {
	constructor(tagData = {}) {
		this.id = tagData.id || null
		this.name = tagData.name || ''
		this.tokens = tagData.tokens || 0
	}
}


class NewCompany {
	constructor(newCompanyData = {}) {
		function setTiers(tiers = []) {
			return tiers.map(tier => new Tier(tier))
		}
		function setTags(tags = []) {
			return tags.map(tag => new Tag(tag))
		}
		this.id = newCompanyData.id || null
		this.name = newCompanyData.name || ''
		this.address = newCompanyData.address || ''
		this.pib = newCompanyData.pib || ''
		this.identificationNumber = newCompanyData.identification_number || ''
		this.contact = newCompanyData.contact || ''
		this.invoiceEmail = newCompanyData.invoiceEmail || ''
		this.status = newCompanyData.status || undefined
		this.photo = newCompanyData.Photo ? new PngPhoto(newCompanyData.Photo) : undefined
		this.paymentDeadline = newCompanyData.paymentDeadline ? `${newCompanyData.paymentDeadline} days` : undefined
		this.tags = setTags(newCompanyData.Tags) || [new Tag()]
		this.tiers = setTiers(newCompanyData.Tiers) || [new Tier()]
	}
}

class SingleCompany {
	constructor(singleCompany = {}) {
		this.id = singleCompany.id || null
		this.name = singleCompany.name || ''
		this.pib = singleCompany.pib || ''
		this.address = singleCompany.address || ''
		this.identificationNumber = singleCompany.identification_number || ''
		this.photo = singleCompany.Photo ? new PngPhoto(singleCompany.Photo) : undefined
		this.contact = singleCompany.contact || ''
		this.invoiceEmail = singleCompany.invoiceEmail || ''
		this.status = singleCompany.status || ''
		this.paymentDeadline = singleCompany.paymentDeadline ? `${singleCompany.paymentDeadline} days` : ''
		this.tags = singleCompany.Tags ? singleCompany.Tags.map(tag => new Tag(tag)) : []
		this.tiers = singleCompany.Tiers ? singleCompany.Tiers.map(tier => new Tier(tier)) : []
	}
}


class Company {
	constructor(companyData = {}) {
		function setTags(tags = []) {
			return tags.map(tag => new Tag(tag))
		}
		function setTiers(tiers = []) {
			return tiers.map(tier => new Tier(tier))
		}
		this.id = companyData.id || null
		this.name = companyData.name || ''
		this.pib = companyData.pib || null
		this.ein = companyData.identification_number || null
		this.contact = companyData.contact || ''
		this.invoiceEmail = companyData.invoiceEmail || ''
		this.status = companyData.status || undefined
		this.paymentDeadline = companyData.paymentDeadline ? `${companyData.paymentDeadline} days` : undefined
		this.address = companyData.address || ''
		this.photo = new PngPhoto(companyData.Photo)
		this.tiers = setTiers(companyData.Tiers)
		this.tags = setTags(companyData.Tags) || [new Tag()]
	}
}

export { Company, NewCompany, Tag, SingleCompany }
