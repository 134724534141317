import React, { useContext, useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { BeatLoader } from "react-spinners";
// components
import {
  Header,
  Modal,
  openModalAnim,
  closeModalAnim
} from "../../../components";
import UsedTokens from "./UsedTokens/UsedTokens";
import TotalBudget from "./TotalBudget";
import CategoryBudget from "./CategoryBudget";
import TierBudget from "./TierBudget";
import BudgetUtilization from "./BudgetUtilization";
import TopBenefits from "./TopBenefits";
import UsageWidget from "./UsageWidget/UsageWidget";
import { CombinationsWidget } from "./combinationsWidget";
import UsageReportGenerator from "./UsageReport/UsageReportGenerator";
import RefundReportGenerator from "./RefundReport/RefundReportGenerator";
import SyncWidget from "./SyncWidget/SyncWidget";
import PrivacyPolicy from "../../../components/privacyPolicyModal";
// styles
import HrDashboardStyles from "./HrDashboardStyles";
import { override } from "../../../services/loadingOverride.styles";
import { useTranslation } from "react-i18next";

const HrDashboard = ({ user }) => {
  const { t } = useTranslation();
  const showPrivacyPolicyModal = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tierBudgetLoading, setTierBudgetLoading] = useState(true);
  const [totalBudgetLoading, setTotalBudgetLoading] = useState(true);
  const [budgetUtilizationLoading, setBudgetUtilizationLoading] = useState(
    true
  );
  const [categoryBudgetLoading, setCategoryBudgetLoading] = useState(false);
  const [usedTokensLoading, setUsedTokensLoading] = useState(true);
  const [topBenefitsLoading, setTopBenefitsLoading] = useState(true);
  const [usageLoading, setUsageLoading] = useState(true);
  const [syncLoading, setSyncLoading] = useState(true);
  const isMounted = useRef(true);

  useEffect(() => {
    if (isMounted.current) {
      setIsLoading(true);
      if (
        !tierBudgetLoading &&
        !totalBudgetLoading &&
        !budgetUtilizationLoading &&
        !categoryBudgetLoading &&
        !usedTokensLoading &&
        !topBenefitsLoading &&
        !usageLoading &&
        !syncLoading
      ) {
        setTimeout(() => {
          if (isMounted.current) {
            setIsLoading(false);
            setTimeout(() => {
              const dashboarsContainer = document.getElementById(
                "dashboarsContainer"
              );
              if (isMounted.current && dashboarsContainer) {
                dashboarsContainer.style.opacity = 1;
              }
            }, 100);
          }
        }, 100);
      }
    }

    showPrivacyPolicyModal.current = true;
    if (user.authenticatedRole && !user.hasAcceptedPrivacyPolicy) {
      setTimeout(() => {
        openModalAnim();
      }, 500);
    }
    return () => () => {
      isMounted.current = false;
    };
  }, [
    tierBudgetLoading,
    totalBudgetLoading,
    budgetUtilizationLoading,
    categoryBudgetLoading,
    usedTokensLoading,
    topBenefitsLoading,
    usageLoading,
    syncLoading
  ]);

  return (
    <HrDashboardStyles>
      <Header headerTitle={t("Dashboard")} />
      <div className="dashboardContainer">
        {isLoading && (
          <div
            style={{
              height: "calc(100vh - 180px)",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <BeatLoader
              css={override}
              size={25}
              color="#123abc"
              loading={isLoading}
            />
          </div>
        )}

        <div id="dashboarsContainer">
          <div className="graphsContainer">
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <TierBudget
                tierBudgetLoading={tierBudgetLoading}
                setTierBudgetLoading={setTierBudgetLoading}
              />
              <TotalBudget
                totalBudgetLoading={totalBudgetLoading}
                setTotalBudgetLoading={setTotalBudgetLoading}
              />
              <BudgetUtilization
                budgetUtilizationLoading={budgetUtilizationLoading}
                setBudgetUtilizationLoading={setBudgetUtilizationLoading}
              />
              <CategoryBudget
                categoryBudgetLoading={categoryBudgetLoading}
                setCategoryBudgetLoading={setCategoryBudgetLoading}
              />
              <UsedTokens
                usedTokensLoading={usedTokensLoading}
                setUsedTokensLoading={setUsedTokensLoading}
              />
              <UsageWidget
                companyName={user.companyName}
                setUsageLoading={setUsageLoading}
              />
              <UsageReportGenerator />
              <RefundReportGenerator />
              <SyncWidget
                companyName={user.companyName}
                setSyncLoading={setSyncLoading}
              />
            </div>
            <div>
              <TopBenefits
                topBenefitsLoading={topBenefitsLoading}
                setTopBenefitsLoading={setTopBenefitsLoading}
              />
              <CombinationsWidget companyName={user.companyName} />
            </div>
          </div>
        </div>
      </div>

      {!isLoading &&
        showPrivacyPolicyModal.current &&
        user.authenticatedRole &&
        !user.hasAcceptedPrivacyPolicy && (
          <Modal isCloseVisable={false}>
            <PrivacyPolicy />
          </Modal>
        )}
    </HrDashboardStyles>
  );
};

const mapStateToProps = state => {
  return {
    user: state.app.user
  };
};

export default connect(mapStateToProps, undefined)(HrDashboard);
