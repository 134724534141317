import styled from "styled-components";

const BasicInfoStyles = styled.div`
  margin: 0 0 37px 0;

  .benefitLogo {
    width: 100%;
    height: 140px;
    overflow: hidden;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }

  .img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .content {
    padding: 0 30px;
    position: relative;

    .imgContainerOuter {
      position: absolute;
      top: -30px;
      z-index: 2;
      width: 98px;
      height: 98px;
      border-radius: 49px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;

      .imgContainerInner {
        width: 92px;
        height: 92px;
        border-radius: 46px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .providerImg {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }

    .textContent {
      margin-left: 112px;
      padding-top: 11px;

      .title {
        font-size: 24px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #354052;
      }

      .benefitGroupDescription {
        width: 80%;
        max-width: 1064px;
        color: #919ba8;
        word-break: break-word;
        font-size: 14px;
        ol {
          margin-left: 18px;
        }
        ul {
          margin-left: 18px;
        }
      }

      .taxes {
        width: 80%;
        max-width: 1064px;
        color: #919ba8;
        word-break: break-word;
        font-size: 14px;
        font-style: italic;
        margin-top: 10px;
      }

      .benefitGroupSummary {
        width: 80%;
        max-width: 1064px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.36;
        letter-spacing: normal;
        color: #919ba8;
        word-break: break-word;
      }

      .locations {
        font-weight: 500;
        color: black;
        margin-top: 15px;
        word-wrap: break-word;
        font-size: 14px;
        font-stretch: normal;
        line-height: 1.36;
      }
    }
  }
`;

export default BasicInfoStyles;
