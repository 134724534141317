// Entities
import { City } from "../../domain";

class CitiesResponse {
  constructor(response = {}) {
    this.cities = response.data.cities.map(city => new City(city));
    this.pages = response.data.pages;
    this.count = response.data.count;
  }
}

export default CitiesResponse;
