import styled from "styled-components";

const TopBenefitsStyles = styled.div`
  .pieChartAndMessageContainer {
    z-index: 0;
    height: 250px;
    width: 300px;
    position: relative;
  }

  .barChartAndMessageContainer {
    height: 350px;
    width: 300px;
    position: relative;
  }

  .apexcharts-canvas {
    z-index: 1;
  }

  .apexcharts-tooltip-y-group {
    display: flex;
    align-items: center;
  }

  .apexcharts-tooltip-z-group {
    display: flex;
    align-items: center;
  }

  .apexcharts-tooltip-text-value,
  .apexcharts-tooltip-text-z-value {
    display: flex !important;
    align-items: center;
  }

  .messageContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    z-index: 2;
  }

  .text {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4b4b4b;
    text-transform: uppercase;
  }

  .inputContainer {
    display: flex;
    align-items: center;
  }
`;

export default TopBenefitsStyles;
