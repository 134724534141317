import { actionTypes } from './user.reducer'

export const useUserActions = (dispatch) => {
	const setUser = (user) => {
		dispatch({ type: actionTypes.setLoggedInUser, payload: { user } })
	}

	const setIsOnboardingDoneToTrue = () => {
		dispatch({ type: actionTypes.setOnboardingToTrue })
	}

	const logoutUser = () => {
		dispatch({ type: actionTypes.loagoutUser })
	}

	const subscribe = (benefitTokenPrice) => {
		dispatch({ type: actionTypes.subscribe, benefitTokenPrice })
	}

	const unsubscribe = (benefitTokenPrice) => {
		dispatch({ type: actionTypes.unsubscribe, benefitTokenPrice })
	}

	const sync = () => {
		dispatch({ type: actionTypes.syncEmployee })
	}

	return {
		setUser,
		setIsOnboardingDoneToTrue,
		logoutUser,
		subscribe,
		unsubscribe,
		sync
	}
}
