import { apiService } from "../api.service";
import { TiersResponse } from "../../../models/server/responses";
import { TokenWorth, Tier, Company, CompanyTheme } from "../../../models/domain";
import { CompanyBudgetRolloverPayload, TierPayload, CompanyThemePayload } from "../../../models/server/payloads";

export const getCompanyTiers = async () => {
  const response = await apiService.get("/company/tiers");

  if (response.hasError) return response;

  return new TiersResponse(response);
};

export const addTier = async values => {
  const response = await apiService.post("/company/tier/create", new TierPayload(values));

  if (response.hasError) return response;

  return new Tier(response.data);
};

export const editTier = async (selectedTierId, values) => {
  const response = await apiService.put(
    `/company/tier/${selectedTierId}`,
    new TierPayload(values)
  );

  if (response.hasError) return response;

  return new Tier(response.data);
};

export const removeTier = async selectedTierId => {
  const response = await apiService.delete(`/company/tier/${selectedTierId}`);

  return response;
};

export const getTokenWorth = async () => {
  const response = await apiService.get("/hr/token");

  if (response.hasError) return response;

  return new TokenWorth(response.data);
};

export const onboardingFinish = async () => {
  const response = await apiService.patch("/users/hr/onboarding");

  return response;
};

export const getCompanyWithBudgetRollover = async () => {
  const response = await apiService.get("/company/budget-rollover/get");

  if (response.hasError) return response;

  return new Company(response.data);
}

export const updateBudgetRollover = async (budgetRolloverData) => {
  let payload = new CompanyBudgetRolloverPayload(budgetRolloverData);

  const response = await apiService.post(
    `/company/budget-rollover`,
    payload
  );

  if (response.hasError) return response;

  return response;
}

export const addCompanyTheme = async values => {
  const theme =  new CompanyThemePayload(values);
  const response = await apiService.post(`/company/theme/create`, theme.payload);

  if (response.hasError) return response;

  return new CompanyTheme(response.data);
};

export const editCompanyTheme = async (selectedCompanyThemeId, values) => {
  const theme =  new CompanyThemePayload(values);
  const response = await apiService.put(`/company/theme/${selectedCompanyThemeId}`, theme.payload);

  if (response.hasError) return response;

  return new CompanyTheme(response.data);
};

export const removeCompanyTheme = async (selectedCompanyThemeId) => {
  const response = await apiService.delete(`/company/theme/${selectedCompanyThemeId}`);

  return response;
};