export const tableHeadConfig = [
  {
    id: 1,
    field: "name",
    headName: "Name",
    isSortedUp: null,
    width: "28%",
    justifyContent: "flex-start",
    margin: "",
    isSortable: true,
    orderPriority: 0
  },
  {
    id: 2,
    field: "pib",
    headName: "Pib",
    isSortedUp: null,
    width: "20%",
    justifyContent: "center",
    margin: "",
    isSortable: false,
    orderPriority: 0
  },
  {
    id: 3,
    field: "payment_deadline",
    headName: "Payment Deadline",
    isSortedUp: null,
    width: "20%",
    justifyContent: "center",
    margin: "",
    isSortable: false,
    orderPriority: 0
  },
  {
    id: 4,
    field: "invoice_email",
    headName: "Invoice email",
    isSortedUp: null,
    width: "20%",
    justifyContent: "center",
    margin: "",
    isSortable: false,
    orderPriority: 0
  },
  {
    id: 5,
    field: "",
    headName: "",
    isSortedUp: null,
    width: "4%",
    justifyContent: "center",
    margin: "",
    isSortable: false,
    orderPriority: 0
  }
];
