import React from "react";
import { Range, getTrackBackground } from "react-range";
import PropTypes from "prop-types";
// Styles
import TwoThumbsStyles from "./TwoThumbs.styles";
// Constants
import {
  PRICE_MAX,
  PRICE_MIN,
  PRICE_STEP
} from "../../constants/filter.constants";
import { transformTokensToRsd } from "../../utils/transformTokensToRsd";

const TwoThumbs = ({ values, onRangeChange, rtl }) => {
  return (
    <TwoThumbsStyles>
      <Range
        values={values}
        step={transformTokensToRsd(PRICE_STEP)}
        min={transformTokensToRsd(PRICE_MIN)}
        max={transformTokensToRsd(PRICE_MAX)}
        rtl={rtl}
        onChange={values => onRangeChange(values)}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            className="sliderContainer"
          >
            <div
              ref={props.ref}
              className="slider"
              style={{
                background: getTrackBackground({
                  values,
                  colors: ["#ccc", "#2F68B9", "#ccc"],
                  min: transformTokensToRsd(PRICE_MIN),
                  max: transformTokensToRsd(PRICE_MAX),
                  rtl
                })
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props }) => (
          <div {...props} className="thumb" style={{ ...props.style }}></div>
        )}
      />
    </TwoThumbsStyles>
  );
};

TwoThumbs.propTypes = {
  values: PropTypes.array,
  onRangeChange: PropTypes.func,
  rtl: PropTypes.bool
};

export default TwoThumbs;
