import * as actionTypes from "../../constants/actionTypes/admin/companies.constants";
import { Company } from "../../models/domain";
import * as modelMapperService from "../../models/modelMapper.service";

export const setCompanies = (companies, count) => {
  companies = modelMapperService.mapManyToDomain(
    companies,
    Company
  );
  return { type: actionTypes.SET_COMPANIES, companies, count };
};

export const addNewCompany = company => {
  company = modelMapperService.mapOneToDomain(company, Company);
  return { type: actionTypes.ADD_NEW_COMPANY, company };
};

export const deleteCompany = id => {
  return { type: actionTypes.DELETE_COMPANY, id };
};

export const setSelectedCompany = id => {
  return { type: actionTypes.SET_SELECTED_COMPANY, id };
};

export const setPage = page => {
  return { type: actionTypes.SET_PAGE, page };
};

export const onSearchChange = search => {
  return { type: actionTypes.SEARCH_CHANGE, search };
};
