import React, { createContext, useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'

import { initalBenefitTagState, benefitTagsReducer } from './reducers/benefitTag.reducer'
import { initialCompanyTagsState, companyTagsReducer } from './reducers/companyTag.reducer'
import { initalCategoriesState, categoriesReducer } from './reducers/categories.reducer'

import { useBenefitTagActions } from './actions/benefitTags.actions'
import { useCompanyTagsActions } from './actions/companyTags.actions'
import { useCategoriesActions } from './actions/categories.actions'

const SettingsContext =
	createContext({ ...initalBenefitTagState, ...initialCompanyTagsState, ...initalCategoriesState })

const SettingsProvider = ({ children }) => {
	// state
	const [companyTags, dispatchCompanyTags] =
		useReducer(companyTagsReducer, initialCompanyTagsState)
	const [benefitTags, dispatchBenefitTags] =
		useReducer(benefitTagsReducer, initalBenefitTagState)
	const [categoriesState, dispatchCategoriesState] =
		useReducer(categoriesReducer, initalCategoriesState)
	// actions
	const benefitTagsActions = useBenefitTagActions(dispatchBenefitTags)
	const companyTagsActions = useCompanyTagsActions(dispatchCompanyTags)
	const categoriesActions = useCategoriesActions(dispatchCategoriesState)

	useEffect(() => { }, [initalBenefitTagState, initialCompanyTagsState, initalCategoriesState])

	return (
		<SettingsContext.Provider
			value={{
				companyTags,
				companyTagsActions,
				benefitTags,
				benefitTagsActions,
				categoriesState,
				categoriesActions
			}}
		>
			{children}
		</SettingsContext.Provider>
	)
}

SettingsProvider.propTypes = {
	children: PropTypes.node
}

export { SettingsContext, SettingsProvider }
