import {
  EmployeeBenefits,
  HrDashboard,
  HrBenefits,
  EditHrBenefit,
  Employees,
  EmployeeDetails,
  EditEmployee,
  LocalBenefits,
  Onboarding,
  Settings,
  Providers,
  SuperAdminDashboard,
  Companies,
  CompanyDetails,
  EditCompany,
  UsersList,
  UserDetails,
  EditUser,
  SuperAdminSettings,
  Notifications,
  Activity,
  Login,
  ForgotPassword,
  ResetPassword,
  SetPassword,
  PresentationPage,
  UserHistory,
  Refunds,
  AdminUserHistory
} from "../pages";
import PreviewHRBenefit from "../pages/HrPages/PreviewHRBenefit/PreviewHRBenefit";
import { PreviewProviders } from "../pages/HrPages/PreviewHRBenefit/PreviewProviders";

const adminRoutes = [
  {
    path: "/",
    component: SuperAdminDashboard,
    exact: true,
    fullLayout: false
  },
  {
    path: "/providers",
    component: Providers,
    exact: false,
    fullLayout: false
  },
  {
    path: "/benefits/preview/:id",
    component: PreviewProviders,
    exact: false,
    fullLayout: false
  },
  {
    path: "/activity",
    component: Activity,
    exact: false,
    fullLayout: false
  },
  {
    path: "/companies/edit/:id",
    component: EditCompany,
    exact: false,
    fullLayout: false
  },
  {
    path: "/companies/:id",
    component: CompanyDetails,
    exact: false,
    fullLayout: false
  },
  {
    path: "/companies",
    component: Companies,
    exact: true,
    fullLayout: false
  },
  {
    path: "/users",
    component: UsersList,
    exact: true,
    fullLayout: false
  },
  {
    path: "/users/:id",
    component: UserDetails,
    exact: true,
    fullLayout: false
  },
  {
    path: "/users/edit/:id/:company",
    component: EditUser,
    exact: true,
    fullLayout: false
  },
  {
    path: "/settings",
    component: SuperAdminSettings,
    exact: false,
    fullLayout: false
  },
  {
    path: "/notifications",
    component: Notifications,
    exact: false,
    fullLayout: false
  },
  {
    path: "/users/history/:id",
    component: AdminUserHistory,
    exact: true,
    fullLayout: false
  }
];

const hrRoutes = [
  {
    path: "/",
    component: HrDashboard,
    exact: true,
    fullLayout: false
  },
  {
    path: "/benefits",
    component: HrBenefits,
    exact: true,
    fullLayout: false
  },
  {
    path: "/benefits/edit/:id",
    component: EditHrBenefit,
    exact: false,
    fullLayout: false
  },
  {
    path: "/benefits/preview/:id",
    component: PreviewHRBenefit,
    exact: false,
    fullLayout: false
  },
  {
    path: "/company-benefits",
    component: LocalBenefits,
    exact: false,
    fullLayout: false
  },
  {
    path: "/employees/edit/:id",
    component: EditEmployee,
    exact: false,
    fullLayout: false
  },
  {
    path: "/employees/:id",
    component: EmployeeDetails,
    exact: false,
    fullLayout: false
  },
  {
    path: "/employees",
    component: Employees,
    exact: true,
    fullLayout: false
  },
  {
    path: "/refunds",
    component: Refunds,
    exact: true,
    fullLayout: false
  },
  {
    path: "/settings",
    component: Settings,
    exact: false,
    fullLayout: false
  },
  {
    path: "/onboarding",
    component: Onboarding,
    exact: false,
    fullLayout: true
  }
];

const memberRoutes = [
  {
    path: "/",
    component: EmployeeBenefits,
    exact: true,
    fullLayout: true
  },
  {
    path: "/history",
    component: UserHistory,
    exact: true,
    fullLayout: true
  }
];

const publicRoutes = [
  {
    path: "/",
    component: Login,
    exact: true,
    fullLayout: true
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
    exact: false,
    fullLayout: true
  },
  {
    path: "/reset-password",
    component: ResetPassword,
    exact: false,
    fullLayout: true
  },
  {
    path: "/set-password",
    component: SetPassword,
    exact: false,
    fullLayout: true
  },
  {
    path: "/presentation-page",
    component: PresentationPage,
    exact: false,
    fullLayout: true
  }
];

export const routesConfig = {
  member: memberRoutes,
  hr: hrRoutes,
  admin: adminRoutes,
  public: publicRoutes
};
