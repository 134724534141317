import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

import FormModalStyles from "./FormModalStyles";

const FormModal = ({ closeModal, children }) => {
  const ref = useRef(null);

  const handleKeyDown = event => {
    if (event.key !== "Escape") return;

    closeModal();
  };

  useEffect(() => {
    ref.current.focus();
  }, []);

  return (
    <FormModalStyles onClick={closeModal}>
      <div
        ref={ref}
        className="modal"
        id="modal"
        onClick={e => e.stopPropagation()}
        tabIndex={-1}
        onKeyDown={handleKeyDown}
      >
        <div className="closeButton" id="closeButton" onClick={closeModal}>
          X
        </div>
        {children}
      </div>
    </FormModalStyles>
  );
};

FormModal.propTypes = {
  closeModal: PropTypes.func,
  children: PropTypes.node
};

export default FormModal;
