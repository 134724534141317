import React, { useRef, useState } from "react";
import { apiService } from "../../../../services/api/api.service";
import { Card } from "../../../../components";
import { SelectCompaniesComponent } from "../DashboardStatistics/DashboardStatisticsNavbar/SelectCompaniesComponent";
import { useTranslation } from "react-i18next";
import moment from "moment";

const ResetDates = () => {
  const { t } = useTranslation();
  const [showRollover, setShowRollover] = useState(false);
  const [rolloverInfo, setRolloverInfo] = useState(undefined);

  const getIfCompanyHasRollover = async companyId => {
    try {
      const response = await apiService.get(
        `/settings/company-rollover?companyId=${companyId}`
      );

      setRolloverInfo(response.data.companyRollover);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectCompany = company => {
    const companyId = company === "All Companies" ? null : company;

    if (!companyId) return;
    getIfCompanyHasRollover(companyId);
  };

  return (
    <div style={{ position: "relative" }}>
      <button
        className="openResetDates"
        onClick={() => setShowRollover(state => !state)}
      >
        {t("show_rollover_dates")}
      </button>
      {showRollover && (
        <div
          className="rolloverDatesContainer"
          style={{
            width: "214px",
            position: "absolute",
            top: "45px",
            zIndex: 20
          }}
        >
          <Card>
            <div>
              <h1 className="title">{t("rollover_resets")}</h1>
              <SelectCompaniesComponent onSelect={handleSelectCompany} />
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "14px",
                  color: "#373737"
                }}
              >
                {rolloverInfo !== undefined
                  ? rolloverInfo !== null
                    ? moment(rolloverInfo.rolloverEndDate).format("DD.MM.YYYY")
                    : t("no_rollover")
                  : ""}
              </p>
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};

export default ResetDates;
