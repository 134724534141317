import React, {
	createContext,
	useReducer,
	useEffect
} from 'react'
import PropTypes from 'prop-types'

import { companiesInitalState, companiesReducer } from './companies.reducer'
import { useCompaniesActions } from './companies.actions'

const CompaniesContext =
	createContext({ ...companiesInitalState })

const CompaniesProvider = ({ children }) => {
	const [companiesState, dispatchCompaniesState] =
		useReducer(companiesReducer, companiesInitalState)
	const companiesActions = useCompaniesActions(dispatchCompaniesState)

	useEffect(() => { }, [companiesState])

	return (
		<CompaniesContext.Provider
			value={{
				companiesState,
				companiesActions
			}}
		>
			{children}
		</CompaniesContext.Provider>
	)
}

CompaniesProvider.propTypes = {
	children: PropTypes.node
}

export { CompaniesProvider, CompaniesContext }
