class VoucherSubscribeUnsubscribePayload {
  constructor(payload = {}) {
    this.benefit_group_id = payload.benefitGroupId || null;
    this.benefit_id = payload.benefitId || null;
    this.subscribe = payload.subscribe || false;
    this.voucher_id = payload.voucherId || false;
    this.voucher_custom_price = payload.voucherCustomPrice || null;
    this.quantity = payload.quantity || null;
    this.voucher_is_reimbursement = payload.voucherIsReimbursement || false;
  }
}

export default VoucherSubscribeUnsubscribePayload;
