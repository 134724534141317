import styled from 'styled-components'


const UsageWidgetStyles = styled.div`
	margin: 0 20px 20px 0;
	width: 550px;
	order: 6;

	.statisticsContainer {
		width: 100%;
		height: calc(100% - 30px);
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-evenly;
	}

	.statisticItem {
		width: 33%;
		height: 50%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.statisticName {
			font-size: 13px;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #4a4a4a;
			text-align: center;
			text-transform: uppercase;
			margin-bottom: 10px;
		}
	}

	@media screen and (max-width: 1626px) {
		width: 640px;
		margin-bottom: 20px;
		order: 5;
	}

`

export default UsageWidgetStyles
