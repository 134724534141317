export const SET_HR_BENEFIT_GROUPS = "SET_HR_BENEFIT_GROUPS";
export const SET_HR_CATEGORIES_FILTERS = "SET_HR_CATEGORIES_FILTERS";
export const SET_HR_TAGS_FILTERS = "SET_HR_TAGS_FILTERS";
export const SET_HR_ENABLED_BENEFIT_GROUP_FILTERS = "SET_HR_ENABLED_BENEFIT_GROUP_FILTERS";
export const SET_HR_BENEFITS_KEYWORD_SEARCH = "SET_HR_BENEFITS_KEYWORD_SEARCH";
export const SET_HR_BENEFITS_CHOSEN_CATEGORIES_FILTERS = "SET_HR_BENEFITS_CHOSEN_CATEGORIES_FILTERS";
export const SET_HR_BENEFITS_CHOSEN_TAGS_FILTERS = "SET_HR_BENEFITS_CHOSEN_TAGS_FILTERS";
export const SET_HR_BENEFITS_FILTERS_TO_DEFAULT = "";
export const SET_HR_BENEFITS_PAGE = "SET_HR_BENEFITS_PAGE";
export const SET_HR_EDIT_BENEFITS_INITIAL_STATE =
  "SET_HR_EDIT_BENEFITS_INITIAL_STATE";
export const TOGGLE_BENEFIT_GROUP = "TOGGLE_BENEFIT_GROUP";
export const TOGGLE_BENEFIT_GROUP_INSTALMENTS = "TOGGLE_BENEFIT_GROUP_INSTALMENTS";
export const SET_HR_BENEFIT_GROUP_USERS_PAGE =
  "SET_HR_BENEFIT_GROUP_USERS_PAGE";
export const SET_HR_BENEFIT_GROUP_USERS = "SET_HR_BENEFIT_GROUP_USERS";
