import refundActions from "../../constants/actionTypes/hr/refunds.constants";

const setRefunds = payload => {
  return { type: refundActions.SET_REFUNDS_LIST, payload };
};

const setRefund = payload => {
  return { type: refundActions.SET_REFUND, payload };
};

const setTotalCount = payload => {
  return { type: refundActions.SET_TOTAL_COUNT, payload };
};

const setFilters = payload => {
  return { type: refundActions.SET_FILTERS, payload };
};

const setCheckedPendingItems = payload => {
  return { type: refundActions.SET_CHECKED_PENDING_ITEMS, payload };
};

const setBranchCodes = payload => {
  return { type: refundActions.SET_BRANCH_CODES, payload };
};

const setToggleReimbursement = payload => {
  return { type: refundActions.SET_TOGGLE_REIMBURSEMENT, payload };
};

export {
  setRefunds,
  setRefund,
  setTotalCount,
  setFilters,
  setCheckedPendingItems,
  setBranchCodes,
  setToggleReimbursement
};
