import styled from "styled-components";

const PriceFilterStyles = styled.div`
  margin-bottom: -16px;

  .inputWrapper {
    display: flex;
    align-items: center;
    gap: 8px; 
    margin-top: 18px;

    .inputContainer {
      position: relative;

      label {
        position: absolute;
        left: 18px; 
        top: 7px;
      }

      input {
        width: 50px;
        padding-left: 50px;
        width: 119px;
        height: 40px;
        border: 1px solid #E3E3E3;
        border-radius: 8px;
        color: #777777;
        font-size: 16px; 
        outline: none;
      }

      span {
        position: absolute;
        right: 20px;
        top: 10px;
      }
    }
  }
`

export default PriceFilterStyles;