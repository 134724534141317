/**
 * Checks is value empty, null or undefined.
 * If value is array, checks if the length of the array is greater than zero.
 * @param value - Value to be checked.
 * @returns Is value empty boolean.
 */
export const isEmpty = (value) => {
  if (
    value === null ||
    value === undefined ||
    value === '' ||
    value === 'null' ||
    value === 'undefined'
  ) {
    return true;
  }

  if (Array.isArray(value)) {
    return value.length === 0;
  }

  return false;
};

/**
 * Splits passed string, loops through array of split words,
 * capitalizes first letters and returns joined string.
 * @param {chosenString} string - chosen string to be capitalized
 * @param {splitDelimiter} string - split delimiter
 * @param {joinDelimiter} string - join delimiter
 * @returns {string} - formatted string
 */
export const capitalizeFirstLetters = (
  chosenString,
  splitDelimiter,
  joinDelimiter
) => {
  const split = chosenString.split(splitDelimiter);
  for (let i = 0; i < split.length; i++) {
    split[i] = split[i][0].toUpperCase() + split[i].substr(1);
  }
  const joined = split.join(joinDelimiter);

  return joined;
};

/**
 * Checks if passed string contains all capital letters.
 * @param {string} str
 * @returns {boolean}
 */
export const isStringAllCaps = str => {
  return !/[a-z]/.test(str) && /[A-Z]/.test(str);
};

/**
 * 
 * @param {string} stringToBeSplit 
 * @param {string} delimiter 
 * @returns {string}
 */
export const splitStringByDelimiter = (stringToBeSplit, delimiter) => {
  return stringToBeSplit.split(delimiter).join(' ');
}

/**
 * Checks if passed string is valid email.
 * @param {string} email 
 * @returns {boolean} 
 */
export const isValidEmail = (email) => {
  const re =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  return email.match(re);
}

/**
 * Trim spaces and remove more than one space from a string,
 * Remove HTML tags from string
 * @param {string} string 
 * @returns {string} 
 */
export const removeSpacesAndHTML = (string) => {
  const reSpace = /\s+/g;
  const reTag = /<.+?>/g;

  return string.replace(reTag, '').replace(reSpace, ' ').trim();
}

/**
 * Remove style from HTML string
 * @param {string} htmlString 
 * @returns {string} 
 */
export const removeStyleFromHtmlString = (htmlString) => {
  const reStyle = /style=".*?"/gm;

  return htmlString.replace(reStyle, '');
}

/**
 * Sorts given array by given key.
 * @param {Array} array 
 * @param {string} key 
 * @returns {Array}
 */
export const sortByKey = (array, key) => {
  return array.sort((a, b) => {
    const x = a[key];
    const y = b[key];
    return ((x < y) ? -1 : ((x > y) ? 1 : 0));
  });
}

/**
 * Get array of unique objects, remove duplicate objects by id.
 * @param {Array} array 
 * @returns {Array}
 */
export const uniqueArrayOfObjects = (array) => {
  return array.filter(
    (value, index, arr) => arr.findIndex(value1 => (value1.id === value.id)) === index)
}

/**Filter an array by a value of a key.
 * @param {Array} array - The array to filter.
 * @param {String} key - The key of the object you want to filter by.
 * @param {String} value - The value to filter by.
 * @returns {Array} - An array of filtered objects. 
 */
export const filterArrayByValueOfKey = (array, key, value) => {
  return array.filter(e => e[key] === value)
}
