import moment from "moment";

const createSupaBaseStorageFolder = companyId => {
  const currentDate = moment(new Date());
  const nextMonth = currentDate.add(1, "months");
  const formattedMonth = nextMonth.format("MM");
  return `${companyId}_${formattedMonth}_${currentDate.year()}`;
};

export default createSupaBaseStorageFolder;
