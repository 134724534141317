import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";

import { resetPasswordValidation } from "../../../services/validations/publicPages/resetPassword.validation";
import { LoginFormStyles } from "../login/LoginFormStyles";
import { BenefitLogo } from "../../../assets/Icons";
import Button from "../../button";
import * as authService from "../../../services/api/auth.service";
import Unmasked from "../../../assets/images/002-visibility.png";
import Masked from "../../../assets/images/003-visibility-1.png";
import { useTranslation } from "react-i18next";

export const ResetPassword = ({ email, hash }) => {
  const { t } = useTranslation();
  const [maskedNewPassword, setMaskedNewPassword] = useState(true);
  const [maskedNewPasswordConfirm, setMaskedNewPasswordConfirm] = useState(
    true
  );
  const history = useHistory();

  const handleResetPassword = async (values, { resetForm }) => {
    const response = await authService.resetPassword(values);

    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : t("failed_to_reset_password")
      );
    }

    resetForm();
    toast.success(response.message);
    history.push("/");
  };

  const formik = useFormik({
    initialValues: {
      email,
      password: "",
      passwordConfirmation: "",
      hash
    },
    validate: resetPasswordValidation,
    onSubmit: handleResetPassword
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur
  } = formik;

  return (
    <LoginFormStyles>
      <div className="authWindow">
        <BenefitLogo color="#1568bf" />
        <div className="formContainer">
          <form onSubmit={handleSubmit} className="form" autoComplete="off">
            <label className="label" htmlFor="email">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              >
                Email:
                <div className="errorMsg">
                  {errors.email && touched.email ? errors.email : ""}
                </div>
              </div>
              <input
                className="inputs"
                id="email"
                name="email"
                type="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </label>
            <label className="label" htmlFor="password">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              >
                New Password:
                <div className="errorMsg">
                  {errors.password && touched.password ? errors.password : ""}
                </div>
              </div>
              <div style={{ position: "relative" }}>
                <input
                  className="inputs"
                  id="password"
                  name="password"
                  type={maskedNewPassword ? "password" : "text"}
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <img
                  src={maskedNewPassword ? Masked : Unmasked}
                  onClick={() => setMaskedNewPassword(!maskedNewPassword)}
                  alt="masked"
                  style={{
                    position: "absolute",
                    cursor: "pointer",
                    right: -30
                  }}
                />
              </div>
            </label>

            <label className="label" htmlFor="passwordConfirmation">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              >
                Confirm Password:
                <div className="errorMsg">
                  {errors.passwordConfirmation && touched.passwordConfirmation
                    ? errors.passwordConfirmation
                    : ""}
                </div>
              </div>
              <div style={{ position: "relative" }}>
                <input
                  className="inputs"
                  id="passwordConfirmation"
                  name="passwordConfirmation"
                  type={maskedNewPasswordConfirm ? "password" : "text"}
                  value={values.passwordConfirmation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <img
                  src={maskedNewPasswordConfirm ? Masked : Unmasked}
                  onClick={() =>
                    setMaskedNewPasswordConfirm(!maskedNewPasswordConfirm)
                  }
                  alt="masked"
                  style={{
                    position: "absolute",
                    cursor: "pointer",
                    right: -30
                  }}
                />
              </div>
            </label>

            <div className="buttonContainer">
              <div>
                <Link to="/" className="forgotPassword">
                  Login
                </Link>
              </div>
              <Button type="submit" margin="0" padding="10px 22px">
                Reset Password
              </Button>
            </div>
          </form>
        </div>
      </div>
      <div className="bannerWindow" />
    </LoginFormStyles>
  );
};

ResetPassword.propTypes = {
  email: PropTypes.string,
  hash: PropTypes.string
};

export default ResetPassword;
