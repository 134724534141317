import { LocalBenefitGroup } from "../../domain";

class LocalBenefitsResponse {
  constructor(response = {}) {
    this.groups = response.data.groups.map(
      group => new LocalBenefitGroup(group)
    );
    this.page = response.data.pages;
    this.count = response.data.count;
  }
}

export default LocalBenefitsResponse;
