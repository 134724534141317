import React from "react";
import PropTypes from "prop-types";
// assets
import { TokenIcon } from "../../../../../assets/Icons";
// components
import {
  Button,
  SelectInput,
  MultiselectInput
} from "../../../../../components";
import BonusBudgetDateInput from "./BonusBudgetDateInput";
import DateInput from "./DateInput";
// styles
import FormStyles from "./FormStyles";
import { useTranslation } from "react-i18next";

const Form = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  tokenWorth,
  setFieldValue,
  title,
  buttonText,
  disableButton,
  inputName,
  textareaName,
  selectName,
  selectOptions,
  isNotCompanyBonusBudget,
  fetchMoreData,
  hasMore,
  handleSearchEmployees
}) => {
  const { t } = useTranslation();
  const handleNumberInput = e => {
    let inputValue = e.target.validity.valid
      ? parseInt(e.target.value, 0)
      : values.tokens;

    if (isNaN(inputValue)) {
      inputValue = "";
    }

    if (e.target.name === "tokens") {
      setFieldValue("tokens", inputValue);
      setFieldValue(
        "currency",
        inputValue === "" ? "" : inputValue * tokenWorth.worth
      );
    }

    if (e.target.name === "currency") {
      setFieldValue("currency", inputValue);
      if (inputValue % tokenWorth.worth === 0) {
        setFieldValue(
          "tokens",
          inputValue === "" ? "" : inputValue / tokenWorth.worth
        );
      } else {
        setFieldValue("tokens", "");
      }
    }
  };

  return (
    <FormStyles>
      <h2 className="title">{title}</h2>
      <div className="selectContainer">
        <div className="labelAndValidation">
          <p className="label">{t(selectName)}:</p>
          <div className="errorMsg">
            {errors[selectName] && touched[selectName]
              ? errors[selectName]
              : ""}
          </div>
        </div>
        {title === t("Add Bonus") ? (
          <SelectInput
            maxWidth
            name={selectName}
            onBlur={handleBlur}
            options={selectOptions}
            value={selectOptions.find(
              option => option.id === values.employees[0].id
            )}
            handleChange={option => setFieldValue("employees", [option])}
            customHeight="41px"
            shouldHaveFullWidth
          />
        ) : (
          <MultiselectInput
            fetchMoreData={fetchMoreData}
            hasMore={hasMore}
            name={selectName}
            onBlur={handleBlur}
            handleInputChange={handleSearchEmployees}
            options={selectOptions}
            handleChange={option => setFieldValue("employees", option)}
            selectedValues={values.employees}
            shouldHaveFullWidth={true}
            customHeight="41px"
            dropdownMenuHeight="150px"
          />
        )}
      </div>
      {isNotCompanyBonusBudget ? (
        <DateInput
          values={values}
          setFieldValue={setFieldValue}
          isNotCompanyBonusBudget={isNotCompanyBonusBudget}
        />
      ) : (
        <BonusBudgetDateInput
          values={values}
          handleChange={handleChange}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          selectName={selectName}
          errors={errors}
          touched={touched}
        />
      )}
      <div className="nameContainer">
        <div className="labelAndValidation">
          <p className="label">{t(inputName)}:</p>
          <div className="errorMsg">
            {errors[inputName] && touched[inputName] ? errors[inputName] : ""}
          </div>
        </div>
        <input
          type="text"
          name={inputName}
          className="input"
          value={values[inputName]}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      <div className="descriptionContainer">
        <div className="labelAndValidation">
          <p className="label">{t(textareaName)}:</p>
          <div className="errorMsg">
            {errors[textareaName] && touched[textareaName]
              ? errors[textareaName]
              : ""}
          </div>
        </div>
        <textarea
          name={textareaName}
          className="textArea"
          value={values[textareaName]}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      <div className="tokensAndCurrencyContainer">
        <div className="tokensContainer">
          <p className="label">{t("enter_the_value")}</p>
          <div className="inputContainer">
            <input
              type="number"
              className="numbersInputs"
              name="currency"
              onChange={handleNumberInput}
              style={{ paddingRight: 47 }}
              value={values.currency}
            />
            <p style={{ position: "absolute", right: 11, opacity: 0.6 }}>
              currency
            </p>
          </div>
          <p className="tokenWorth">1 token = {tokenWorth.worth} currency</p>
        </div>
        <div className="tokensContainer">
          <div className="labelAndValidation">
            <p className="label"># {t("of_tokens")}:</p>
            <div className="errorMsg">
              {errors.tokens && touched.tokens ? errors.tokens : ""}
            </div>
          </div>
          <div className="inputContainer">
            <div
              style={{
                width: 24,
                height: 24,
                marginLeft: 11,
                position: "relative",
                zIndex: 2
              }}
            >
              <TokenIcon />
            </div>
            <input
              type="number"
              name="tokens"
              style={{ paddingLeft: 36 }}
              className="numbersInputs"
              value={values.tokens}
              onChange={handleNumberInput}
              onBlur={handleBlur}
            />
          </div>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {disableButton ? (
          <Button width="130px" disabled margin="20px 0 0 0">
            {t("please_wait")}
          </Button>
        ) : (
          <Button type="submit" width="130px" margin="20px 0 0 0">
            {t(buttonText)}
          </Button>
        )}
      </div>
    </FormStyles>
  );
};

Form.propTypes = {
  values: PropTypes.shape(),
  errors: PropTypes.shape(),
  touched: PropTypes.shape(),
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  tokenWorth: PropTypes.shape(),
  setFieldValue: PropTypes.func,
  title: PropTypes.string,
  buttonText: PropTypes.string,
  disableButton: PropTypes.bool,
  inputName: PropTypes.string,
  textareaName: PropTypes.string,
  selectName: PropTypes.string,
  selectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  )
};

export default Form;
