// Utils
import { isEmpty } from "../../../services/general.utils";
// Domain models
import { City } from "../../domain";

class EmployeeLocationUpdatedInfoPayload {
  constructor(systemNotificationPayloadData = {}) {
    this.removed_cities = !isEmpty(systemNotificationPayloadData.removed_cities)
      ? systemNotificationPayloadData.removed_cities.map(city => new City(city))
      : []
  }
}

export default EmployeeLocationUpdatedInfoPayload;
