import { JpgPhoto } from '../../../services/global.entities';

class Benefit {
  constructor(benefit = {}) {
    this.id = benefit.id || null;
    this.title = benefit.title || '';
    this.description = benefit.description || '';
    this.expiration = benefit.expiration || '';
    this.tokenPrice = benefit.tokenPrice || 0;
    // this.photo = benefit.Photo ? new JpgPhoto(benefit.Photo) : null;
    this.benefit_group_id = benefit.benefitGroupId || null;
  }
}

class LocalBenefitGroupForm {
  constructor(benefitGroupFormData = {}) {
    this.name = benefitGroupFormData.name || '';
    this.description = benefitGroupFormData.description || '';
    this.photo = benefitGroupFormData.Photo
      ? new JpgPhoto(benefitGroupFormData.Photo)
      : undefined;
  }
}

class LocalBenefitForm {
  constructor(localBenefitFormData = {}) {
    this.id = localBenefitFormData.id || null;
    this.title = localBenefitFormData.title || '';
    this.description = localBenefitFormData.description || '';
    this.expiration = localBenefitFormData.expiration || '';
    // this.photo = localBenefitFormData.Photo
    //   ? new JpgPhoto(localBenefitFormData.Photo)
    //   : null;
    this.benefit_group_id = localBenefitFormData.benefitGroupId || null;
  }
}

class BenefitGroup {
  constructor(benefitGroupData = {}) {
    this.id = benefitGroupData.id || null;
    this.name = benefitGroupData.name || '';
    this.description = benefitGroupData.description || '';
    this.capacity = benefitGroupData.capacity || parseInt(0, 0);
    this.photo = benefitGroupData.Photo
      ? new JpgPhoto(benefitGroupData.Photo)
      : new JpgPhoto();
    this.benefits = benefitGroupData.Benefit
      ? benefitGroupData.Benefit.map(benefit => new Benefit(benefit))
      : [];
  }
}

export { BenefitGroup, LocalBenefitGroupForm, LocalBenefitForm, Benefit };
