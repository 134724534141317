import { apiService } from "../api.service";

export const getStatistics = async (company, searchDateString, reportType) => {
  const query =
    company === "All Companies"
      ? `/dashboard/report/statistics?date=${searchDateString}&report_type=${reportType}`
      : `/dashboard/report/statistics?company_id=${company}&date=${searchDateString}&report_type=${reportType}`;

  const response = await apiService.get(query);

  if (response.hasError) {
    return response;
  }

  return response;
};
