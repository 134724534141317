/*eslint-disable*/
import React, { useState } from "react";
import PropTypes from "prop-types";
//assets
import { AttachmentIcon } from "../../assets/Icons";
//styles
import ImageUploadStyles from "./ImageUploadStyles";
//services
import { showOverlay, hideOverlay, charge } from "./imageUpload.services";
import { readURL } from "../../services/fileReader";
import { getUrl } from "../../services/imageHandler";
import { isEmpty } from "../../services/general.utils";
import { useTranslation } from "react-i18next";

const ImageUpload = ({ photo, handleChange, name, onBlur, setFieldError }) => {
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [hideText, setHideText] = useState(false);

  const handleBlur = () => {
    onBlur("photo", true);
  };

  return (
    <ImageUploadStyles
      onMouseEnter={() => {
        showOverlay();
        setHideText(true);
      }}
      onMouseLeave={() => {
        setHideText(false);
        if (!isDialogOpen) {
          hideOverlay();
        }
      }}
    >
      {photo &&
        (photo instanceof File ? (
          <img
            src={readURL(photo, "benefitPhoto")}
            id="benefitPhoto"
            alt="company"
            className="image"
          />
        ) : (
          <img src={getUrl(photo)} alt="photoImage" className="image" />
        ))}
      {isEmpty(photo) && !isDialogOpen && !hideText && <h3>{t("no_image")}</h3>}
      <div
        id="overlay"
        className="fileInputOverlay"
        onClick={() => {
          handleBlur();
          setIsDialogOpen(true);
          document.getElementById("fileInput").click();
        }}
      >
        <div style={{ marginBottom: 5 }}>
          <AttachmentIcon />
        </div>
        {t("upload_image")}
      </div>
      <input
        type="file"
        name={name}
        id="fileInput"
        onClick={() => charge(setIsDialogOpen)}
        className="fileInput"
        onChange={e => {
          setIsDialogOpen(false);
          if (e.target.files.length > 0) {
            handleChange(e.target.name, e.target.files[0]);
          }
          hideOverlay();
        }}
      />
    </ImageUploadStyles>
  );
};

ImageUpload.propTypes = {
  photo: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  handleChange: PropTypes.func,
  onBlur: PropTypes.func.isRequired,
  name: PropTypes.string
};

export default ImageUpload;
