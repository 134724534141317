import React from "react";
import { useTranslation } from "react-i18next";

export const RightModalSection = ({ image }) => {
  const { t } = useTranslation();
  return (
    <div className="rightSection">
      <div className="rightSectionImage">
        {t("Image")}:
        <button
          className="imageButton"
          aria-label="View Image"
          onClick={() => window.open(image.imageSrc)}
        >
          <img src={image.logo} />
        </button>
      </div>
    </div>
  );
};
