import * as actionTypes from "../../constants/actionTypes/admin/benefitTags.constants";

export const setBenefitTags = benefitTags => {
  return { type: actionTypes.SET_BENEFIT_TAGS, benefitTags };
};

export const addBenefitTag = benefitTag => {
  return { type: actionTypes.ADD_BENEFIT_TAG, benefitTag };
}

export const editBenefitTag = benefitTag => {
  return { type: actionTypes.EDIT_BENEFIT_TAG, benefitTag };
}

export const deleteBenefitTag = id => {
  return { type: actionTypes.DELETE_BENEFIT_TAG, id };
}