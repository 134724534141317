import React, { useRef, useState } from "react";
import LanguageDropdownStyles from "./LanguageDropdownStyles";
import { useTranslation } from "react-i18next";
import { PlanetIcon } from "./Icons/PlanetIcon";
import useClickOutside from "../../hooks/useClickOutside";

export const LanguageDropdown = () => {
  const tooltip = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useClickOutside([tooltip], () => setIsDropdownOpen(false));

  const { t, i18n } = useTranslation();
  const language = localStorage.getItem("locales");

  const handleDropdownOpen = () => setIsDropdownOpen(state => !state);

  const handleLanguageChange = language => {
    i18n.changeLanguage(language);
    localStorage.setItem("locales", language);
  };

  return (
    <LanguageDropdownStyles>
      <div className="flagDropdownWrapper">
        <button
          className="shownFlag"
          ref={tooltip}
          onClick={handleDropdownOpen}
        >
          <PlanetIcon />
          <span className="shownLanguage">{t(language)}</span>
        </button>
        {isDropdownOpen && (
          <div className="flagDropdown">
            <button
              className="languageItem"
              onClick={() => handleLanguageChange("rs")}
              disabled={language === "rs"}
            >
              {/* {CountryFlags("rs")} */}
              <span className="shownLanguage">Srpski</span>
            </button>
            <button
              className="languageItem"
              disabled={language === "en"}
              onClick={() => handleLanguageChange("en")}
            >
              {/* {CountryFlags("en")} */}
              <span className="shownLanguage">English</span>
            </button>
          </div>
        )}
      </div>
    </LanguageDropdownStyles>
  );
};
