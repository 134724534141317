import styled from "styled-components";

const DashboardStatisticsNavbarStyles = styled.section`
  margin-bottom: 30px;
  width: 100%;

  .dashboardNavbarFirstLayer {
    margin-top: 10px;

    .uniqueUsersCountWrapper {
      position: relative;
      width: 160px;

      .uniqueUsersCountDropdown {
        position: absolute;
        background: #f5f5f5;
        width: 260px;
        z-index: 12;
        padding: 20px;
        border-radius: 8px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      }
    }

    .uniqueUsersCount {
      border: 1px solid #1568bf;
      border-radius: 8px;
      width: 160px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 24px;
      color: #1568bf;
      cursor: pointer;
      transition: 0.5s ease-in;

      &:hover {
        color: white;
        background: #1568bf;
      }

      span {
        font-size: 16px;
        font-weight: 500;
      }

      p {
        font-size: 24px;
        font-weight: 700;
      }
    }
  }

  .dashboardNavbarSecondLayer {
    margin-top: 20px;

    .datePickerWrapper {
      position: relative;
      display: flex;
      gap: 20px;
      align-items: center;

      .dateWrapper {
        position: relative;
      }

      #companyId {
        height: 40px;
        width: 180px;

        .react-dropdown-select {
          height: 100%;
        }
      }

      .react-datepicker {
        position: absolute;
        z-index: 12;
        top: 45px;
      }
    }

    .openDatePicker {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      border-radius: 4px;
      border: solid 1px transparent;
      color: #1568bf;
      outline: #1568bf;
      background: #ffffff;
      outline-style: inset;
      outline-width: 0.5px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      margin: 0;
      transition: 0.5s;

      &:hover {
        background: #1568bf;
        color: white;
      }
    }
  }
`;

export default DashboardStatisticsNavbarStyles;
