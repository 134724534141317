/* eslint-disable */
import React from "react";

const WatchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="20"
      fill="none"
      viewBox="0 0 18 20"
    >
      <path
        fill="#1568BF"
        d="M8.87 19.4c4.597 0 8.385-3.797 8.385-8.394a8.32 8.32 0 00-1.828-5.194l.756-.765a.856.856 0 00.263-.589.743.743 0 00-.756-.747.816.816 0 00-.597.246l-.739.738a8.364 8.364 0 00-4.702-2.03v-.976c0-.439-.351-.808-.782-.808-.43 0-.782.369-.782.808v.976C3.852 3.061.485 6.682.485 11.005c0 4.597 3.797 8.394 8.385 8.394zm0-1.213a7.159 7.159 0 01-7.172-7.181A7.151 7.151 0 018.87 3.834a7.17 7.17 0 017.18 7.172 7.155 7.155 0 01-7.18 7.18zm0-5.942c.686 0 1.23-.545 1.23-1.23 0-.492-.272-.906-.729-1.125V5.54a.488.488 0 00-.5-.493.488.488 0 00-.502.492v4.342c-.448.228-.73.65-.73 1.134 0 .676.546 1.23 1.231 1.23z"
      ></path>
    </svg>
  );
}

export default WatchIcon;
