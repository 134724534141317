import React from "react";
import DatePicker from "react-datepicker";
import { parseISO } from "date-fns";
import PropTypes from "prop-types";
// components
import { SelectInput, CheckboxInput } from "../../../../../components";
// utils
import { isEmpty } from "../../../../../services/general.utils";
import {
  formatToStartOfDay,
  startFutureMonthFromDate
} from "../../../../../services/date.utils";
import { useTranslation } from "react-i18next";
import LOCALES from "../../../../../constants/locales.constants";

const BonusBudgetDateInput = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  selectName
}) => {
  const { t } = useTranslation();
  const recurrenceTypeOptions = [
    {
      id: 1,
      name: LOCALES[localStorage.getItem("locales") || "rs"]["monthly"],
      key: "monthly"
    },
    {
      id: 2,
      name: LOCALES[localStorage.getItem("locales") || "rs"]["three_months"],
      key: "three_months"
    },
    {
      id: 3,
      name: LOCALES[localStorage.getItem("locales") || "rs"]["six_months"],
      key: "six_months"
    },
    {
      id: 4,
      name: LOCALES[localStorage.getItem("locales") || "rs"]["yearly"],
      key: "yearly"
    }
  ];

  const handleDateChange = selectedDate => {
    if (isNaN(selectedDate) || isEmpty(selectedDate)) {
      return;
    }

    // You who reads this in the future, don't touch this, dates in javascript with datepickers are real hard, okay?
    // This exists because the javascript date object automatically sets selected date to utc, so we have to offset it
    // So the selected value in the datepicker is valid
    if (selectedDate) {
      selectedDate.setHours((-1 * selectedDate.getTimezoneOffset()) / 60);
    }
    setFieldValue("endDate", formatToStartOfDay(selectedDate));
  };

  const handleStartDateChange = selectedDate => {
    if (isNaN(selectedDate) || isEmpty(selectedDate)) {
      return;
    }
    if (selectedDate) {
      selectedDate.setHours((-1 * selectedDate.getTimezoneOffset()) / 60);
    }
    setFieldValue("startDate", formatToStartOfDay(selectedDate));
  };

  const filterStartDateBasedOnRecurrence = date => {
    return date.getDate() <= 25;
  };

  return (
    <>
      <div className="datepickerWrapper">
        <div className="labelAndValidation">
          <p className="label">{t("Bonus Start Date")}:</p>
        </div>
        {values.recurring && values.recurringType !== "yearly" ? (
          <DatePicker
            className="datePickerInput"
            name="startDate"
            id="datePicker"
            selected={values.startDate}
            dateFormat="dd/MM/yyyy"
            onChange={selectedDate => handleStartDateChange(selectedDate)}
            minDate={new Date()}
            value={parseISO(values.startDate)}
            showYearDropdown
            placeholderText="DD/MM/YYYY"
            filterDate={filterStartDateBasedOnRecurrence}
          />
        ) : (
          <DatePicker
            className="datePickerInput"
            name="startDate"
            id="datePicker"
            selected={values.startDate}
            dateFormat="dd/MM/yyyy"
            onChange={selectedDate => handleStartDateChange(selectedDate)}
            minDate={new Date()}
            value={parseISO(values.startDate)}
            showYearDropdown
            placeholderText="DD/MM/YYYY"
          />
        )}
        <p className="dateDisclaimer">{t("bonus_start_date_disclaimer")}</p>
      </div>
      <div className="datepickerWrapper">
        <div className="labelAndValidation">
          <p className="label">{t("Bonus End Date")}:</p>
        </div>
        <DatePicker
          className="datePickerInput"
          name="endDate"
          id="datePicker"
          selected={values.endDate}
          dateFormat="dd/MM/yyyy"
          onChange={selectedDate => handleDateChange(selectedDate)}
          minDate={startFutureMonthFromDate(new Date(), 2)}
          value={parseISO(values.endDate)}
          showYearDropdown
          placeholderText="DD/MM/YYYY"
        />
        <p className="dateDisclaimer">{t("bonus_end_date_disclaimer")}</p>
      </div>
      <div className="checkbox-wrapper">
        <CheckboxInput
          name="recurring"
          value={values.recurring}
          isChecked={values.recurring}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <label className="label" htmlFor="recurring">
          {t("Recurring Bonus")}
        </label>
      </div>
      {values.recurring && (
        <div style={{ margin: "20px 0" }}>
          <SelectInput
            maxWidth
            name={selectName}
            onBlur={handleBlur}
            options={recurrenceTypeOptions}
            value={recurrenceTypeOptions.find(
              option => option.key === values.recurringType
            )}
            handleChange={option => setFieldValue("recurringType", option.key)}
            customHeight="41px"
            shouldHaveFullWidth
          />
          <div className="errorMsg">
            {errors.recurringType && touched.recurringType
              ? errors.recurringType
              : ""}
          </div>
        </div>
      )}
    </>
  );
};

BonusBudgetDateInput.propTypes = {
  values: PropTypes.shape(),
  errors: PropTypes.shape(),
  touched: PropTypes.shape(),
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  setFieldValue: PropTypes.func,
  selectName: PropTypes.string
};

export default BonusBudgetDateInput;
