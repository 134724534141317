import { Company, NewCompany, Tag } from '../companies.entity'

const companiesInitalState = {
	allTags: [new Tag()],
	selectedCompany: new Company(),
	newCompany: new NewCompany(),
	companies: [new Company()]

}

const actionTypes = {
	setCompanies: 'SET_COMPANIES',
	setCompanyTags: 'SET_COMPANY_TAGS',
	addNewCompany: 'ADD_NEW_COMPANY',
	// addCompanyTag: 'ADD_COMPANY_TAG',
	// removeCompanyTag: 'REMOVE_COMPANY_TAG',
	createCompany: 'CREATE_COMPANY',
	removeCompany: 'REMOVE_COMPANY',
	// removePhoto: 'REMOVE_PHOTO',
	// handleChange: 'HANDLE_CHANGE',
	deleteCompany: 'DELETE_COMPANY',
	setSelectedCompany: 'SET_SELECTED_COMPANY',
	setSingleCompany: 'SET_SINGLE_COMPANY',
	setCompany: 'SET_COMPANY'
}

const companiesReducer = (state = companiesInitalState, action) => {
	switch (action.type) {
		case actionTypes.setCompanies:
			return {
				...state,
				newCompany: new NewCompany(),
				companies: action.payload.map(company => new Company(company))
			}
		case actionTypes.setCompanyTags:
			return {
				...state,
				allTags: action.payload.map(tag => new Tag(tag))
			}
		// case actionTypes.addCompanyTag:
		// 	return {
		// 		...state,
		// 		newCompany: {
		// 			...state.newCompany,
		// 			tags: [...state.newCompany.tags, action.tag]
		// 		}
		// 	}
		// case actionTypes.removeCompanyTag:
		// 	return {
		// 		...state,
		// 		newCompany: {
		// 			...state.newCompany,
		// 			tags: state.newCompany.tags.filter(tag => tag.id !== action.chosenTagId)
		// 		}
		// 	}
		// case actionTypes.removePhoto:
		// 	return {
		// 		...state,
		// 		newCompany: {
		// 			...state.newCompany,
		// 			photo: undefined
		// 		}
		// 	}
		// case actionTypes.handleChange:
		// 	return {
		// 		...state,
		// 		newCompany: {
		// 			...state.newCompany,
		// 			[action.name]: action.value
		// 		}
		// 	}
		case actionTypes.addNewCompany:
			return {
				...state,
				newCompany: new NewCompany(),
				companies: [...state.companies, new Company(action.payload)]
			}
		case actionTypes.deleteCompany:
			return {
				...state,
				companies: state.companies.filter(company => company.id !== action.chosenId)
			}
		case actionTypes.setSelectedCompany:
			return {
				...state,
				selectedCompany: state.companies.find(company => company.id === action.chosenId)
			}
		case actionTypes.setSingleCompany:
			return {
				...state,
				selectedCompany: new Company(action.payload)
			}
		case actionTypes.setCompany:
			return {
				...state,
				newCompany: new NewCompany(action.payload)
			}
		default:
			throw new Error(`Unknow action type: ${action.type}`)
	}
}

export { companiesInitalState, companiesReducer, actionTypes }
