// Utils
import { isEmpty, removeSpacesAndHTML } from "../../../services/general.utils";
import { convertEditorStateToString } from "../../../services/textEditor.utils";

export class BenefitGroupPayload {
  payload = new FormData();

  constructor(providerId, benefitGroup = {}) {
    this.payload.append(
      "name",
      benefitGroup.name && removeSpacesAndHTML(benefitGroup.name)
    );
    !isEmpty(benefitGroup.description)
      ? this.payload.append(
          "description",
          convertEditorStateToString(benefitGroup.description)
        )
      : null;
    this.payload.append(
      "summary",
      benefitGroup.summary && removeSpacesAndHTML(benefitGroup.summary)
    );
    benefitGroup.photo && this.payload.append("photo", benefitGroup.photo);
    this.payload.append("provider_id", providerId);
    !isEmpty(benefitGroup.companyId)
      ? this.payload.append("company_id", benefitGroup.companyId)
      : undefined;
    this.payload.append(
      "category_ids",
      JSON.stringify(benefitGroup.categories?.map(category => category.id))
    );
    this.payload.append(
      "capacity",
      benefitGroup.capacity === "" ? null : benefitGroup.capacity
    );
    !isEmpty(benefitGroup.taxes)
      ? this.payload.append("taxes", removeSpacesAndHTML(benefitGroup.taxes))
      : this.payload.append("taxes", null);
    !isEmpty(benefitGroup.cities)
      ? this.payload.append(
          "city_ids",
          JSON.stringify(benefitGroup.cities.map(city => city.id))
        )
      : null;
    this.payload.append(
      "is_remote",
      benefitGroup.isRemote ? benefitGroup.isRemote : false
    );
    this.payload.append(
      "is_draft",
      benefitGroup.isDraft ? benefitGroup.isDraft : false
    );
  }
}

export default BenefitGroupPayload;
