export const readURL = (file, tagID) => {
	if (file) {
		const reader = new FileReader()

		reader.onload = function t(e) {
			const imageEl = document.getElementById(tagID)
			if (imageEl) {
				imageEl.src = e.target.result
			}
		}

		reader.readAsDataURL(file)
	}
}
