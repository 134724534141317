import JSZip from "jszip";
import processDelegation from "./processDelegation";

async function processTransform(
  items,
  pauseTime,
  onProgress,
  downloadFolder,
  setIsRefundDownloading
) {
  const totalImages = items.length;

  const zip = new JSZip();

  for (let index = 0; index < totalImages; index += 1) {
    // Call processDelegation for each item
    await processDelegation(
      zip,
      items[index],
      index,
      pauseTime,
      totalImages,
      onProgress,
      downloadFolder,
      setIsRefundDownloading
    );
  }

  const zipBlob = await zip.generateAsync({ type: "blob" });

  return zipBlob;
}

export default processTransform;
