export const calculateCompanySubscriptions = data => {
  let companyAnagram = {};

  data.forEach(sub => {
    companyAnagram[sub.User.Company.name] =
      companyAnagram[sub.User.Company.name] + 1 || 1;
  });

  return companyAnagram;
};
