/* eslint-disable */
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const CategoryFilterStyles = styled.div`
  display: flex;
  flex-direction: column;

  .checkLabelContainer {
    display: flex;
    align-items: center;
    margin-top: 10px;

    &:first-child {
      margin-top: 0;
    }

    .filterCheck {
      width: 12px;
      height: 12px;
      border-radius: 4px;
      cursor: pointer;
    }

    .checkLabel {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      color: #4b4b4b;
      margin-left: 10px;
      white-space: nowrap;
      text-transform: uppercase;
    }

    .changeColor {
      color: #1568bf;
    }
  }

  @media only screen and (max-width: 1099px) {
    .checkLabelContainer {
      margin-top: 15px !important;
    }
  }
`;

const CategoryFilters = ({
  eventProtectClass,
  categories = [],
  handleChange,
  chosenCategories
}) => {
  const { t } = useTranslation();
  return (
    <CategoryFilterStyles className={eventProtectClass}>
      {categories.length > 0 &&
        categories.map(category => (
          <div
            className={`checkLabelContainer ${eventProtectClass}`}
            key={category.id}
          >
            <input
              type="checkbox"
              name="test"
              onChange={() => handleChange(category.id)}
              checked={chosenCategories.includes(category.id)}
              className={`filterCheck ${eventProtectClass}`}
            />
            <p
              className={`checkLabel ${chosenCategories.includes(category.id) &&
                "changeColor"} ${eventProtectClass}`}
            >
              {t(category.name)}
            </p>
          </div>
        ))}
    </CategoryFilterStyles>
  );
};

CategoryFilters.propTypes = {
  eventProtectClass: PropTypes.string,
  categories: PropTypes.array,
  chosenCategories: PropTypes.array,
  handleChange: PropTypes.func
};

export default CategoryFilters;
