import styled from "styled-components";

export const PreviewModalStyles = styled.div`
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 30px 30px 30px;

  .canvas-padding {
    padding: 80px;
  }

  .html2canvas-container { width: 3000px !important; height: 3000px !important; }
`;
