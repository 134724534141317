import React from 'react'

const PendingDeactivationIcon = ({
  opacity = "0.1",
  fill = "#FFF",
  stroke = "#FFF"
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v20H0z" />
      <circle
        cx="10"
        cy="10"
        r="7.583"
        fill={fill}
        fillOpacity={opacity}
        strokeDasharray="3"
        stroke={stroke}
        strokeOpacity="0.4"
        strokeWidth="1.5"
      />
    </g>
  </svg>
)

export default PendingDeactivationIcon;
