import React from "react";
import ReactTooltip from "react-tooltip";

import { InfoIconSvg } from "../../../../assets/Icons";

import ToggleButton from "./toggleButton";

import { BenefitGroupInstalmentsSectionStyles } from "./BenefitGroupInstalmentsSection.styles";
import { useTranslation } from "react-i18next";

const BenefitGroupInstalmentsSection = ({ onToggle, enableInstalments }) => {
  const { t } = useTranslation();

  return (
    <BenefitGroupInstalmentsSectionStyles enableInstalments={enableInstalments}>
      <ToggleButton onToggle={onToggle} />
      <a data-tip data-for="benefitInstalments" style={{ cursor: "pointer" }}>
        <InfoIconSvg />
      </a>
      <ReactTooltip
        id="benefitInstalments"
        place="top"
        effect="solid"
        className="tooltip"
        backgroundColor="#1568bf"
        borderColor="#1568bf"
        arrowColor="#1568bf"
      >
        <span className="spanText">{t("disable_installments_warning")}</span>
      </ReactTooltip>
    </BenefitGroupInstalmentsSectionStyles>
  );
};

export default BenefitGroupInstalmentsSection;
