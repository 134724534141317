import * as actionTypes from "../../constants/actionTypes/admin/users.constants";
import { updateObject } from "../../services/updateObject";

const initialState = {
  users: [],
  page: 1,
  count: 1,
  pages: 1,
  search: ""
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_USERS:
      return setUsers(state, action);
    case actionTypes.SET_PAGE:
      return setPage(state, action);
    case actionTypes.SEARCH_CHANGE:
      return setSearch(state, action);
  }
  return state;
};

const setUsers = (state, action) => {
  return updateObject(state, {
    users: action.payload.users,
    count: action.payload.count,
    pages: action.payload.pages
  });
};

const setPage = (state, action) => {
  return updateObject(state, {
    page: action.payload
  });
};

const setSearch = (state, action) => {
  return updateObject(state, {
    search: action.payload
  });
};

export default usersReducer;
