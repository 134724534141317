import React from "react";
import PropTypes from "prop-types";
import { Editor } from "react-draft-wysiwyg";
import { toast } from "react-toastify";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// Components
import {
  TextAndLine,
  Input,
  InputLabel,
  Button,
  CheckboxInput,
  SelectInput
} from "../../../../../components";
import Vouchers from "../../../../SuperAdminPages/Providers/shared/Benefit/BenefitForm/Vouchers";
import BenefitFormStyles from "./BenefitFormStyles";

// Services
import * as providersService from "../../../../../services/api/admin/providers.service";

// Utils
import { convertStringToEditorState } from "../../../../../services/textEditor.utils";

// Constants
import { EXPIRATION_OPTIONS } from "../../../../../constants/benefitExpiration.constants";
import { useTranslation } from "react-i18next";

const BenefitForm = ({
  closeModal,
  buttonText,
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  handleChange,
  handleBlur,
  disableButton,
  isEdit = false
}) => {
  const { t } = useTranslation();
  /**
   * Handles change of draft status for benefit.
   */
  const handleBenefitPublishedStatus = () => {
    const draft = !values.isDraft;
    setFieldValue("isDraft", draft);
  };

  const handleNumberInput = e => {
    let inputValue = e.target.validity.valid
      ? parseInt(e.target.value, 0)
      : values.tokenPrice;
    if (isNaN(inputValue)) {
      inputValue = "";
    }
    setFieldValue("tokenPrice", inputValue);
  };

  const handleMcpNumberInput = e => {
    let inputValue = e.target.validity.valid
      ? parseInt(e.target.value, 0)
      : values.minimumContractPeriod;
    if (isNaN(inputValue)) {
      inputValue = "";
    }
    setFieldValue("minimumContractPeriod", inputValue);
  };

  /**
   * Published drafted benefit.
   * If request fails, returns toast error message.
   * If request is successful, fires toast message, closes modal.
   */
  const publishBenefit = async () => {
    const response = await providersService.publishBenefit(values.id);

    if (response.hasError) {
      return toast.error(t("failed_to_publish_benefit"));
    }

    toast.success(t("success_publish_benefit"));
    closeModal();
  };

  const filteredEXPOptions = () => {
    if (values.expiration === "voucher") {
      return EXPIRATION_OPTIONS.filter(o => o.name === "voucher");
    } else {
      return EXPIRATION_OPTIONS.filter(o => o.name !== "voucher");
    }
  };

  return (
    <BenefitFormStyles>
      <TextAndLine title={t("basic_info")} />
      <div className="basicInfoSection">
        <div className="topInputsContainer">
          <div className="inputContainer" style={{ marginRight: 10 }}>
            <div className="labelAndValidation">
              <InputLabel
                label={t("Name")}
                margin="0 0 5px 0"
                customCssClass="labelStyles"
              />
              <div className="errorMsg">
                {errors.title && touched.title ? errors.title : ""}
              </div>
            </div>
            <Input
              shouldHaveHight={false}
              name="title"
              value={values.title}
              onChange={handleChange}
              onBlur={handleBlur}
              customCssClass="inputStyles"
            />
          </div>
        </div>
        <div className="inputsContainer">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <InputLabel
              customCssClass="labelStyles"
              margin="0 0 5px 0"
              label={t("expiration_type")}
            />
            <div className="errorMsg" style={{ marginBottom: 5 }}>
              {errors.expiration && touched.expiration ? errors.expiration : ""}
            </div>
          </div>
          <SelectInput
            name="expiration"
            onBlur={handleBlur}
            options={isEdit ? filteredEXPOptions() : EXPIRATION_OPTIONS}
            selectedValues={EXPIRATION_OPTIONS}
            value={values.expiration}
            handleChange={option => setFieldValue("expiration", option.name)}
            customId="expirationId"
            shouldHaveHight={false}
          />
        </div>
        {values.expiration === "month" && (
          <div style={{ width: "100%", margin: "0 0 10px 0" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <InputLabel
                customCssClass="labelStyles"
                margin="0 0 5px 0"
                label={t("min_contract_period")}
              />
            </div>
            <Input
              name="minimumContractPeriod"
              pattern="[0-9]*"
              value={values.minimumContractPeriod}
              onChange={handleMcpNumberInput}
              onBur={handleBlur}
              shouldHaveHight={false}
              customCssClass="inputStyles"
            />
          </div>
        )}
        {values.expiration !== "voucher" && (
          <div style={{ width: "100%", margin: "0 0 10px 0" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <InputLabel
                customCssClass="labelStyles"
                margin="0 0 5px 0"
                label={t("ben_price_in_tokens")}
              />
              <div className="errorMsg" style={{ marginBottom: 5 }}>
                {errors.tokenPrice && touched.tokenPrice
                  ? errors.tokenPrice
                  : ""}
              </div>
            </div>
            <Input
              name="tokenPrice"
              pattern="[0-9]*"
              min={1}
              value={values.tokenPrice}
              onChange={handleNumberInput}
              onBur={handleBlur}
              shouldHaveHight={false}
              customCssClass="inputStyles"
            />
          </div>
        )}
        <div className="bottomInputContainer">
          <div className="labelAndValidation">
            <InputLabel
              label={t("Summary")}
              margin="0 0 5px 0"
              customCssClass="labelStyles"
            />
            <div className="errorMsg">
              {errors.summary && touched.summary ? errors.summary : ""}
            </div>
          </div>
          <textarea
            name="summary"
            className="summary"
            value={values.summary}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="descriptionContainer">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: 24
            }}
          >
            <InputLabel
              customCssClass="labelStyles"
              margin=" 0 0 5px 0"
              label={t("Description")}
            />
            <div className="errorMsg" style={{ marginBottom: 5 }}>
              {errors.description && touched.description
                ? errors.description
                : ""}
            </div>
          </div>
          <Editor
            onBlur={() => {
              setFieldTouched("description", true);
            }}
            editorState={convertStringToEditorState(values.description)}
            editorStyle={{
              fontSize: 14
            }}
            stripPastedStyles={true}
            onEditorStateChange={editorState =>
              setFieldValue("description", editorState)
            }
            toolbar={{
              options: [
                "inline",
                "fontSize",
                "list",
                "textAlign",
                "colorPicker",
                "link",
                "emoji",
                "remove",
                "history"
              ],
              fontSize: {
                options: [10, 11, 12, 13, 14, 16, 18]
              }
            }}
          />
        </div>
        {values.expiration === "voucher" && (
          <Vouchers
            values={values}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
          />
        )}
        {!isEdit && (
          <div style={{ display: "flex", padding: "5px" }}>
            <CheckboxInput
              isChecked={values.isDraft}
              onChange={() => handleBenefitPublishedStatus()}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: 24
              }}
            >
              <InputLabel
                customCssClass="labelStyles"
                margin="0 0 0 10px"
                label={t("save_as_draft")}
                shouldHaveColon={false}
              />
            </div>
          </div>
        )}
        {isEdit && values.isDraft && (
          <span className="publishBenefitLink" onClick={() => publishBenefit()}>
            {t("publish_benefit")}
          </span>
        )}
      </div>
      <div className="buttonContainer">
        {disableButton ? (
          <Button width="130px" disabled margin="0 30px 0 0">
            {t("please_wait")}
          </Button>
        ) : (
          <Button width="130px" type="submit" margin="0 30px 0 0">
            {t(buttonText)}
          </Button>
        )}
        <Button
          width="130px"
          onClick={() => closeModal()}
          margin="0 0 0 0"
          outline
        >
          {t("cancel")}
        </Button>
      </div>
    </BenefitFormStyles>
  );
};

BenefitForm.propTypes = {
  closeModal: PropTypes.func,
  buttonText: PropTypes.string,
  values: PropTypes.shape(),
  errors: PropTypes.shape(),
  touched: PropTypes.shape(),
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  setFieldError: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  disableButton: PropTypes.bool
};

export default BenefitForm;
