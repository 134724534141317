import { toast } from "react-toastify";

async function downloadZip(props) {
  const {
    zipBlob: blob,
    zipFileName: filename,
    zipNumFile: index,
    idOfToast: toastId,
    setIsRefundDownloading,
    listOfImages
  } = props;

  if (index === listOfImages.length) {
    const toastOptions = {
      type: toast.TYPE.INFO,
      autoClose: 5000,
      type: toast.TYPE.SUCCESS,
      render: "All refund file upload parts are downloaded"
    };
    toast.update(toastId, toastOptions);
    setIsRefundDownloading(false);
  }

  const blobUrl = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = blobUrl;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(blobUrl);
}

export default downloadZip;
