import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import { AddUserFormStyles } from "./AddUserFormStyles";
import {
  Button,
  IntitialsCircle,
  Input,
  InputLabel,
  TextAndLine,
  SelectInput,
  MultiselectInput,
  CheckboxInput
} from "../..";
import { addUserFormValidation } from "../../../services/validations/addUserFormValidation";
import { MemberFields } from "./MemberFields";
import Employee from "../../../models/domain/Employee.entity";
import * as api from "../../../services/api/admin/users.service";
import { UserRolesKeysEnum } from "../../../constants/roles.constants";
import { doesRoleExist } from "../../../services/roles.utils";
import { useTranslation } from "react-i18next";

export const AddUserForm = ({ backButtonFunc, fetchUsers }) => {
  const { t } = useTranslation();
  const [companyOptions, setCompanyOptions] = useState([]);
  const [companiesPage, setCompaniesPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [roles, setRoles] = useState([]);

  const handleRegistration = async values => {
    const response = await api.createUser(values);
    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : t("failed_to_create_user")
      );
    } else {
      toast.success(t("success_user_create"));
      fetchUsers();
      backButtonFunc();
    }
  };

  const fetchCompanies = async () => {
    const response = await api.getCompanyOptions(companiesPage);
    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : t("failed_to_get_companies")
      );
    }

    setCompanyOptions(response.companies);
    setTotalPages(response.pages);
  };

  /**
   * Refetches paginated companies.
   * Fired from on scroll event from select input.
   * Sets new companies to state, along with page number.
   */
  const refetchCompanies = async () => {
    let currentPage = companiesPage;
    let newPage = (currentPage += 1);

    if (newPage > totalPages) return;

    setCompaniesPage(newPage);

    const response = await api.getCompanyOptions(newPage);
    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : t("failed_to_get_companies")
      );
    }

    let newCompanies = [];
    setTimeout(() => {
      newCompanies = [...companyOptions, ...response.companies];
      setTimeout(() => {
        setCompanyOptions(newCompanies);
      }, 100);
    }, 100);
  };

  const fetchRoles = async () => {
    const response = await api.getRolesForDropdown();
    if (response.hasError) {
      return toast.error(
        response.errorMessage ? response.errorMessage : t("failed_to_get_roles")
      );
    }

    setRoles(response);
  };

  useEffect(() => {
    fetchCompanies();
    fetchRoles();
  }, []);

  const formik = useFormik({
    initialValues: new Employee(),
    validate: addUserFormValidation,
    onSubmit: handleRegistration
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue
  } = formik;

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <AddUserFormStyles>
        <TextAndLine title={t("user_info")} />
        <div className="userInfoContainer">
          <IntitialsCircle
            size={126}
            value={`${values.firstName} ${values.lastName}`}
          />
          <div className="inputContainer">
            <div className="bottomInputs">
              <div style={{ margin: "10px 10px 0 0", width: 230 }}>
                <div className="labelAndValidation">
                  <InputLabel margin="0 0 5px 0" label={t("Roles")} />
                  <div className="errorMsg" style={{ marginBottom: 5 }}>
                    {errors.roles && touched.roles ? errors.roles : ""}
                  </div>
                </div>
                <MultiselectInput
                  name="roles"
                  onBlur={handleBlur}
                  options={roles}
                  handleChange={option => setFieldValue("roles", option)}
                  selectedValues={values.roles}
                  shouldHaveFullWidth={true}
                  customHeight="27px"
                />
              </div>
              {(doesRoleExist(values?.roles, UserRolesKeysEnum.MEMBER) ||
                doesRoleExist(values?.roles, UserRolesKeysEnum.HR)) && (
                <div style={{ margin: "10px 10px 0 0", width: 230 }}>
                  <div className="labelAndValidation">
                    <InputLabel margin="0 0 5px 0" label={t("Company")} />
                    <div className="errorMsg" style={{ marginBottom: 5 }}>
                      {errors.companyId && touched.companyId
                        ? errors.companyId
                        : ""}
                    </div>
                  </div>
                  <SelectInput
                    name="id"
                    onBlur={handleBlur}
                    options={companyOptions}
                    value={companyOptions.find(
                      company => company.id === values.companyId
                    )}
                    handleChange={option => {
                      setFieldValue("companyId", option.id);
                    }}
                    fetchMoreData={refetchCompanies}
                    customHeight="27px"
                    shouldHaveFullWidth
                  />
                </div>
              )}
            </div>
            <div className="topInputs">
              <div style={{ margin: "0 10px 0 0", width: 230 }}>
                <div className="labelAndValidation">
                  <InputLabel label={t("first_name")} margin="0 0 5px 0" />
                  <div className="errorMsg" style={{ marginBottom: 5 }}>
                    {errors.firstName && touched.firstName
                      ? errors.firstName
                      : ""}
                  </div>
                </div>
                <Input
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div style={{ width: 230 }}>
                <div className="labelAndValidation">
                  <InputLabel margin="0 0 5px 0" label={t("last_name")} />
                  <div className="errorMsg" style={{ marginBottom: 5 }}>
                    {errors.lastName && touched.lastName ? errors.lastName : ""}
                  </div>
                </div>
                <Input
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
            <div className="middleInputs">
              <div style={{ margin: "0 10px 0 0", width: 230 }}>
                <div className="labelAndValidation">
                  <InputLabel margin="0 0 5px 0" label="Email" />
                  <div className="errorMsg" style={{ marginBottom: 5 }}>
                    {errors.email && touched.email ? errors.email : ""}
                  </div>
                </div>
                <Input
                  name="email"
                  type="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
            <div className="middleInputs">
              <div
                style={{
                  display: "flex",
                  margin: "0 10px 0 0",
                  width: 230,
                  gap: "10px"
                }}
              >
                <CheckboxInput
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isChecked={values.formalEmail}
                  name="formalEmail"
                  width="18px"
                  height="18px"
                />
                <label className="label">{t("formal_mail_flag")}</label>
              </div>
            </div>
          </div>
        </div>
        {doesRoleExist(values?.roles, UserRolesKeysEnum.MEMBER) && (
          <>
            <TextAndLine title={t("additional_members_fields")} />
            <MemberFields
              handleBlur={handleBlur}
              values={values}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
              handleChange={handleChange}
              companyId={values.companyId}
            />
          </>
        )}
        <div className="buttonsContainer">
          <Button margin="0 30px 0 0" padding="10px 25px" type="submit">
            {t("add_user")}
          </Button>
          <Button
            onClick={backButtonFunc}
            margin="0"
            padding="10px 25px"
            outline
          >
            {t("cancel")}
          </Button>
        </div>
      </AddUserFormStyles>
    </form>
  );
};

AddUserForm.propTypes = {
  backButtonFunc: PropTypes.func,
  fetchUsers: PropTypes.func
};
