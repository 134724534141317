import React from 'react'
import styled from 'styled-components'

const InfoIconSvgStyles = styled.div`
	width: 12px;
	height: 12px;
	margin-left: 8px;	
	display: flex;
	align-items: center;
	justify-content: center;
	svg {
		width: 100%;
		height: 100%;
		fill: #a1a3a7;
	}
`

const InfoIconSvg = () => (
	<InfoIconSvgStyles>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			x="0"
			y="0"
			enableBackground="new 0 0 512 512"
			version="1.1"
			viewBox="0 0 512 512"
			xmlSpace="preserve"
		>
			<path d="M256 0C114.623 0 0 114.615 0 256s114.623 256 256 256c141.385 0 256-114.615 256-256S397.385 0 256 0zm23.772 354.743h-51.2V223.086h51.2v131.657zm-25.6-153.601c-14.139 0-25.6-11.454-25.6-25.6s11.461-25.6 25.6-25.6c14.139 0 25.6 11.454 25.6 25.6 0 14.147-11.461 25.6-25.6 25.6z" />
		</svg>
	</InfoIconSvgStyles>
)

export default InfoIconSvg
