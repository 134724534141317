import {
  BENEFIT_REFRESH_TOKEN,
  BENEFIT_ROLE,
  BENEFIT_TOKEN
} from "../constants/localStorage.constants";

export const getItem = key => {
  return JSON.parse(localStorage.getItem(key));
};

export const setItem = (key, value) => {
  return localStorage.setItem(key, JSON.stringify(value));
};

export const removeItem = key => {
  return localStorage.removeItem(key);
};

export const removeAuthItemsFromLocalStorage = () => {
  localStorage.removeItem(BENEFIT_REFRESH_TOKEN);
  localStorage.removeItem(BENEFIT_TOKEN);
  localStorage.removeItem(BENEFIT_ROLE);
};
