export const translateSort = order => {
  if (!order) return;

  const ascOrDesc = {
    DESC: {
      rs: "Opadajuće",
      en: "Descending"
    },
    ASC: {
      rs: "Rastuće",
      en: "Ascending"
    }
  };

  return ascOrDesc[order][localStorage.getItem("locales")];
};
