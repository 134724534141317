import { EditorState, ContentState, convertToRaw } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";

/**
 * Converts string to rich text editor state.
 * @param {EditorState | undefined | string} content
 * @returns {EditorState | undefined | string}
 */
export const convertStringToEditorState = content => {
  if (content instanceof EditorState) {
    return content;
  }
  if (typeof content === "string") {
    const blocksFromHtml = htmlToDraft(content);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    return EditorState.createWithContent(contentState);
  }

  return undefined;
};

/**
 * Converts editor state to html string.
 * @param {EditorState} content
 * @returns {string}
 */
export const convertEditorStateToString = content => {
  if (content instanceof EditorState) {
    return draftToHtml(convertToRaw(content.getCurrentContent()));
  }

  return content;
}

/**
 * Checks if benefit group description exists.
 * If backend returns only empty html tag, returns false.
 * @param {string} benefitGroupDescription 
 * @returns {boolean}
 */
export const doesDescriptionExist = (description) => {
  const tagRegex = /<.+?>/g;
  const formattedDescription = description.replace(tagRegex, '').trim();

  return !!formattedDescription;
}