class BenefitTag {
  constructor(benefitTagData = {}) {
    this.id = benefitTagData.id || null;
    this.name = benefitTagData.name || "";
  }
}

class CompanyTag {
  constructor(companyTagData = {}) {
    this.id = companyTagData.id || null;
    this.name = companyTagData.name || "";
  }
}

class Category {
  constructor(benefitCategoryData = {}) {
    this.id = benefitCategoryData.id || null;
    this.name = benefitCategoryData.name || "";
    this.description = benefitCategoryData.description || "";
  }
}

class City {
  constructor(cityData = {}) {
    this.id = cityData.id || null;
    this.name = cityData.name || "";
  }
}

export { BenefitTag, CompanyTag, Category, City };
