import styled from "styled-components";

export const BenefitGroupFiltersStyles = styled.div`
  .availableProviders {
    margin-bottom: 20px;
  }

  .filtersContainer {
    min-width: 310px;
    margin-left: 30px;

    .filterTitle {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #757575;
      margin-bottom: 15px;
    }

    .searchWrapper {
      margin-bottom: 22px;
    }

    .filtersListContainer {
      margin: 22px 0;
      padding-bottom: 16px;
      border-bottom: 1px solid #e6eaee;
    }

    .checkLabelContainer {
      display: flex;
      align-items: center;
      margin-top: 10px;

      &:first-child {
        margin-top: 0;
      }

      .filterCheck {
        width: 12px;
        height: 12px;
        border-radius: 4px;
        cursor: pointer;
      }

      .checkLabel {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        color: #4b4b4b;
        margin-left: 10px;
        white-space: nowrap;
        text-transform: uppercase;
      }

      .changeColor {
        color: #1568bf;
      }
    }

    .filtersButtonsContainer {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      max-width: 300px;
    }

    .cancelFilters {
      display: none;
    }
  }

  @media only screen and (max-width: 1099px) {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 2;
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
    visibility: hidden;

    .filtersContainer {
      position: fixed;
      top: 0;
      right: 0;
      min-height: 100vh;
      z-index: 2;
      transition: all 0.3s ease-in-out;
      transform: translateX(100%);

      .filtersCard {
        height: 100vh;
        overflow-y: auto;
      }

      .cancelFilters {
        display: unset;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .filtersCard {
      padding: 30px 30px 100px 30px;
    }
  }

  @media screen and (max-width: 320px) {
    .filtersContainer {
      min-width: 280px;
    }

    .filtersCard {
      padding: 30px 10px 100px 10px;
    }
  }
`;
