import { apiService } from "../api.service";
import {
  BenefitGroupsResponse,
  TiersResponse,
  EmployeesResponse
} from "../../../models/server/responses";

import { AvailableTiersRequest } from "../../../models/server/requests";

import { BenefitGroup } from "../../../models/domain";
import { isEmpty } from "../../general.utils";

export const getBenefitGroups = async ({
  limit,
  page,
  searchQuery,
  categoryIds,
  tagIds,
  enabledBenefitGroup
}) => {
  let categoriesQuery = "";
  let benefitTagsQuery = "";
  let queryParams = "";

  if (limit) {
    queryParams += `limit=${limit}`;
  }

  if (page) {
    queryParams += `${!isEmpty(queryParams) ? "&" : ""}page=${page}`;
  }

  if (!isEmpty(categoryIds) && Array.isArray(categoryIds)) {
    categoriesQuery = categoryIds.reduce(
      (query, category) =>
        `${!isEmpty(query) ? `${query}&` : ""}category_id=${category}`,
      ""
    );
  }

  if (!isEmpty(categoriesQuery)) {
    queryParams += `${!isEmpty(queryParams) && "&"}${categoriesQuery}`;
  }

  if (!isEmpty(tagIds) && Array.isArray(tagIds)) {
    benefitTagsQuery = tagIds.reduce(
      (query, tag) => `${!isEmpty(query) ? `${query}&` : ""}tag_id=${tag}`,
      ""
    );
  }

  if (!isEmpty(benefitTagsQuery)) {
    queryParams += `${!isEmpty(queryParams) && "&"}${benefitTagsQuery}`;
  }

  if (!isEmpty(enabledBenefitGroup)) {
    queryParams += `${
      !isEmpty(queryParams) ? "&" : ""
    }enabled=${enabledBenefitGroup}`;
  }

  let response = await apiService.get(
    `/benefit-group?${queryParams}&search=${searchQuery}`
  );

  if (response.hasError) return response;
  return new BenefitGroupsResponse(response);
};

export const getBenefitGroup = async id => {
  let response = await apiService.get(`/benefit-group/${id}`);

  if (response.hasError) return response;
  return new BenefitGroup(response.data);
};

export const getTiers = async () => {
  const response = await apiService.get(`/company/tiers`);
  if (response.hasError) return response;
  return new TiersResponse(response);
};

export const getBenefitGroupUsers = async ({
  benefitGroupId,
  limit,
  page,
  orderColumns,
  searchQuery
}) => {
  let orderQuery = "";
  if (orderColumns.length > 0) {
    orderQuery = orderColumns.reduce(
      (acc, column) =>
        `${acc}&order=${column.field} ${column.isSortedUp ? "ASC" : "DESC"}`,
      ""
    );
  }

  let response = await apiService.get(
    `/benefit-group/employee?benefit_group_id=${benefitGroupId}&page=${page}&limit=${limit}${orderQuery}&search=${searchQuery}`
  );
  if (response.hasError) return response;
  return new EmployeesResponse(response);
};

export const setAvailableTiers = async ({
  benefitGroupId,
  availableTiers,
  enableInstalments
}) => {
  const payload = new AvailableTiersRequest({
    benefitGroupId,
    availableTiers,
    enableInstalments
  });
  const response = await apiService.post("/company/tier/available", payload);
  return response;
};
