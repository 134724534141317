import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import RESOURCES from "./language";

const resources = {
  en: { translation: RESOURCES.ENG },
  rs: { translation: RESOURCES.RS }
};

const locales = localStorage.getItem("locales");

i18n.use(initReactI18next).init({
  debug: process.env.NODE_ENV === "production",
  resources,
  fallbackLng: locales || "rs",
  interpolation: { escapeValue: false }
});
