import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

import itemRenderer from "./itemRendered";

import SelectInputStyles from "./SelectInputStyles";

import { getSelectedValue } from "./selectInput.services";
import { useTranslation } from "react-i18next";

const SelectInput = ({
  value,
  selectedValues,
  disabled = false,
  getNameValue = false,
  onChange = () => {},
  handleChange,
  onBlur,
  name,
  defaultOption,
  customId,
  shouldHaveHight = true,
  options = [],
  clearable = false,
  placeholder = "Select Item",
  shouldHaveMinWidth = false,
  customHeight = "100%",
  customValuePosition = "0",
  fetchMoreData = () => {},
  shouldHaveFullWidth,
  minWidth = "auto",
  isClearable = false,
  menuPlacement,
  searchable = true,
  shouldHaveFullHeight = false
}) => {
  const { t } = useTranslation();

  const getValues = () => {
    if (defaultOption) {
      return [options[defaultOption]];
    }
    if (selectedValues && selectedValues.length > 0) {
      return value ? [getSelectedValue(selectedValues, value)] : undefined;
    }
    if (!selectedValues && value) {
      return value;
    }
    return [];
  };

  /**
   * Handles on scroll event in multiselect dropdown.
   * Fires when list is scrolled to the end.
   * Fetches more data for the list.
   * Request is debounced.
   */
  const handleOnScroll = () => {
    setTimeout(() => {
      fetchMoreData();
    }, 300);
  };

  return (
    <SelectInputStyles
      id={customId}
      shouldHaveHight={shouldHaveHight}
      shouldHaveMinWidth={shouldHaveMinWidth}
      customHeight={customHeight}
      shouldHaveFullWidth={shouldHaveFullWidth}
      shouldHaveFullHeight={shouldHaveFullHeight}
      minWidth={minWidth}
      customValuePosition={customValuePosition}
    >
      <Select
        defaultValue={getValues()}
        value={getValues()}
        clearable={clearable}
        placeholder={t(placeholder)}
        options={options}
        className="react-dropdown-select"
        classNamePrefix="react-dropdown"
        onMenuScrollToBottom={handleOnScroll}
        getOptionLabel={options => options["name"]}
        getOptionValue={options => options["id"]}
        isDisabled={disabled}
        isSearchable={searchable}
        searchBy="name"
        dropdownPosition="auto"
        menuPlacement={menuPlacement}
        onChange={values => {
          handleChange(values);
        }}
        onBlur={onBlur}
        itemRenderer={
          itemRenderer
            ? (item, itemIndex, props, state, methods) =>
                itemRenderer(item, itemIndex, props, state, methods)
            : undefined
        }
        isClearable={isClearable}
      />
    </SelectInputStyles>
  );
};

SelectInput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.shape()
  ]),
  selectedValues: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.shape())
  ]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  customId: PropTypes.string,
  handleChange: PropTypes.func,
  defaultOption: PropTypes.number,
  onBlur: PropTypes.func,
  shouldHaveHight: PropTypes.bool,
  name: PropTypes.string,
  getNameValue: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

export default SelectInput;
