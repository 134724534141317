import styled from "styled-components";

export const BenefitGroupUsersTableStyles = styled.div`
  padding: 0 30px;

  .header {
    display: flex;
    justify-content: space-between;
  }

  .tableHead {
    padding: 0 30px;
    display: flex;
    align-items: center;
  }

  .tableRow {
    background: #f7f7f7;
    padding: 30px;
    margin: 20px 0 0 0;
    border-radius: 4px;
  }
`;
