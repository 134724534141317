// Utils
import { isEmpty, removeSpacesAndHTML } from "../../../services/general.utils";
import { convertEditorStateToString } from "../../../services/textEditor.utils";
import { Voucher } from "../../domain";

export class LocalBenefitPayload {
  payload = new FormData();

  constructor(benefitGroupId, benefit = {}) {
    this.payload.append(
      "title",
      benefit.title && removeSpacesAndHTML(benefit.title)
    );
    this.payload.append(
      "summary",
      benefit.summary && removeSpacesAndHTML(benefit.summary)
    );
    !isEmpty(benefit.description)
      ? this.payload.append(
          "description",
          convertEditorStateToString(benefit.description)
        )
      : null;
    this.payload.append("expiration", benefit.expiration);
    if (benefit.expiration === "month") {
      benefit.minimumContractPeriod
        ? this.payload.append(
            "minimum_contract_period",
            parseInt(benefit.minimumContractPeriod)
          )
        : null;
    }
    if (benefit.expiration !== "voucher") {
      this.payload.append("token_price", benefit.tokenPrice);
    }
    if (benefit.expiration === "voucher") {
      this.payload.append(
        "vouchers",
        JSON.stringify(benefit.vouchers.map(voucher => new Voucher(voucher)))
      );
    }
    benefit.photo && this.payload.append("photo", benefit.photo);
    this.payload.append("benefit_group_id", benefitGroupId);
    this.payload.append("is_draft", benefit.isDraft);
    this.payload.append(
      "quantity",
      benefit.quantity === "" ? null : benefit.quantity
    );
  }
}

export default LocalBenefitPayload;
