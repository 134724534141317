import { apiService } from "../api.service";
import { LogsResponse } from "../../../models/server/responses";
import { isEmpty } from "../../general.utils";

export const getLogs = async ({
  dateQuery,
  limit,
  page,
  searchText,
  companyIds,
  roleIds
}) => {
  let queryParams = "";
  let companiesQuery = "";
  let rolesQuery = "";

  if (dateQuery) {
    queryParams += `date=${dateQuery}&order=created desc&`;
  }

  if (limit) {
    queryParams += `limit=${limit}`;
  }

  if (page) {
    queryParams += `${!isEmpty(queryParams) ? "&" : ""}page=${
      searchText !== "" ? 1 : page
    }`;
  }

  if (!isEmpty(companyIds) && Array.isArray(companyIds)) {
    companiesQuery = companyIds.reduce(
      (query, company) =>
        `${!isEmpty(query) ? `${query}&` : ""}company_id=${company}`,
      ""
    );
  }

  if (!isEmpty(roleIds) && Array.isArray(roleIds)) {
    rolesQuery = roleIds.reduce(
      (query, role) => `${!isEmpty(query) ? `${query}&` : ""}role_id=${role}`,
      ""
    );
  }

  if (!isEmpty(companiesQuery)) {
    queryParams += `${!isEmpty(queryParams) ? "&" : null}${companiesQuery}`;
  }

  if (!isEmpty(rolesQuery)) {
    queryParams += `${!isEmpty(queryParams) ? "&" : null}${rolesQuery}`;
  }

  if (searchText !== "") queryParams += `&search=${searchText}`;

  const response = await apiService.get(
    `/dashboard/log-activity?${queryParams}`
  );

  if (response.hasError) {
    return response;
  }

  return new LogsResponse(response);
};
