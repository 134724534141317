// Utils
import { isEmpty, removeSpacesAndHTML } from "../../../services/general.utils";
import { convertEditorStateToString } from '../../../services/textEditor.utils';

export class ProviderPayload {
  payload = new FormData();

  constructor(newProvider = {}) {
    this.payload.append("name", newProvider.name && removeSpacesAndHTML(newProvider.name));
    !isEmpty(newProvider.description)
      ? this.payload.append("description", convertEditorStateToString(newProvider.description))
      : null;
    this.payload.append("summary", newProvider.summary && removeSpacesAndHTML(newProvider.summary));
    this.payload.append("photo", newProvider.photo);
    this.payload.append("is_active", newProvider.isActive);
  }
}

export default ProviderPayload;
