import { apiService } from "../api.service";
import Category from "../../../models/domain/Category.entity";
import CategoriesResponse from "../../../models/server/responses/Categories.response";
import { CategoryPayload } from "../../../models/server/payloads";

export const getCategories = async () => {
  let response = await apiService.get(`/benefit-group/categories`);

  if (response.hasError) return response;

  return new CategoriesResponse(response);
};

export const addCategory = async values => {
  let response = await apiService.post(
    "/benefit-group/category/create",
    new CategoryPayload(values)
  );

  if (response.hasError) return response;

  return new Category(response.data);
};

export const editCategory = async (values, chosenId) => {
  let response = await apiService.put(
    `/benefit-group/category/${chosenId}`,
    new CategoryPayload(values)
  );

  if (response.hasError) return response;

  return new Category(response.data);
};

export const removeCategory = async id => {
  let response = await apiService.delete(`/benefit-group/category/${id}`);

  return response;
};
