import { BenefitGroup, LocalBenefitGroupForm, Benefit } from '../localBenefits.entity'

const initialState = {
	localBenefitGroups: [],
	localBenefitGroupForm: new LocalBenefitGroupForm(),
	localBenefit: null
}

const actionTypes = {
	setLocalBenefitGroups: 'SET_LOCAL_BENEFIT_GROUPS',
	addNewLocalBenefitGroup: 'ADD_NEW_LOCAL_BENEFIT_GROUP',
	editLocalBenefitGroup: 'EDIT_LOCAL_BENEFIT_GROUP',
	deleteLocalBenefitGroup: 'DELETE_LOCAL_BENEFIT_GROUP',
	addNewLocalBenefitToBenefitGroup: 'ADD_NEW_LOCAL_BENEFIT_TO_LOCAL_BENEFIT_GROUP',
	editLocalBenefit: 'EDIT_LOCAL_BENEFIT',
	removeLocalBenefit: 'DELETE_LOCAL_BENEFIT'
}

const localBenefitsReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.setLocalBenefitGroups:
			return {
				...state,
				localBenefitGroups: action.payload.map(benefitGroup => new BenefitGroup(benefitGroup))
			}
		case actionTypes.addNewLocalBenefitGroup:
			return {
				...state,
				localBenefitGroups: [...state.localBenefitGroups, new BenefitGroup(action.payload)]
			}
		case actionTypes.editLocalBenefitGroup:
			return {
				...state,
				localBenefitGroups: state.localBenefitGroups.map(benefitGroup =>
					(benefitGroup.id === action.chosenId ?
						new BenefitGroup(action.payload)
						:
						benefitGroup)
				)
			}
		case actionTypes.deleteLocalBenefitGroup:
			return {
				...state,
				localBenefitGroups: state.localBenefitGroups
					.filter(benefitGroup => benefitGroup.id !== action.chosenBenefitGroupId)
			}
		case actionTypes.addNewLocalBenefitToBenefitGroup:
			return {
				...state,
				localBenefitGroups: state.localBenefitGroups.map(benefitGroup =>
					(benefitGroup.id === action.benefitGroupId ?
						{
							...benefitGroup,
							benefits: [...benefitGroup.benefits, new Benefit(action.payload)]
						}
						:
						benefitGroup)
				)
			}
		case actionTypes.editLocalBenefit:
			return {
				...state,
				localBenefitGroups: state.localBenefitGroups.map(benefitGroup =>
					(benefitGroup.id === action.benefitGroupId ?
						{
							...benefitGroup,
							benefits: benefitGroup.benefits.map(benefit =>
								(benefit.id === action.benefitId ?
									new Benefit(action.payload)
									:
									benefit)
							)
						}
						:
						benefitGroup)
				)
			}
		case actionTypes.removeLocalBenefit:
			return {
				...state,
				localBenefitGroups: state.localBenefitGroups
					.map(benefitGroup => (benefitGroup.id === action.benefitGroupId ?
						{
							...benefitGroup,
							benefits: benefitGroup.benefits.filter(benefit => benefit.id !== action.benefitId)
						}
						:
						benefitGroup)
					)
			}
		default:
			throw new Error(`Unknow action type: ${action.type}`)
	}
}

export { initialState, actionTypes, localBenefitsReducer }
