import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
// styles
import { NotificationsStyles } from "./notifications.styles";
// assets
import { BenefitLogoIcon } from "../../../../assets/Icons";
// components
import { Button } from "../../../../components";
// services
import * as api from "../../../../services/api/auth.service";
// actions
import * as actionCreators from "../../../../actions/app.actions";
import NotificationsContent from "./NotificationsContent";
import { useTranslation } from "react-i18next";

const Notification = ({ notification, popSystemNotifications }) => {
  const { t } = useTranslation();

  const handleMarkNotificationAsRead = async id => {
    const response = await api.markAsRead(id);
    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : t("failed_to_mark_as_read")
      );
    }

    popSystemNotifications();
  };

  return (
    <NotificationsStyles>
      <div className="circleLogoContainer">
        <BenefitLogoIcon />
      </div>
      <NotificationsContent notification={notification} />
      <Button
        width="130px"
        onClick={() => handleMarkNotificationAsRead(notification.id)}
        margin="20px 0 0 0"
      >
        Ok
      </Button>
    </NotificationsStyles>
  );
};

Notification.propTypes = {
  notification: PropTypes.object
};

const mapDispatchToProps = dispatch => {
  return {
    popSystemNotifications: () =>
      dispatch(actionCreators.popSystemNotifications())
  };
};

export default connect(null, mapDispatchToProps)(Notification);
