import styled from "styled-components";

const AddEmployeesWithCSVStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  width: 500px;

  .title {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.08;
    letter-spacing: normal;
    text-align: center;
    color: #4b4b4b;
    margin-bottom: 30px;
  }

  .employeesForm {
    width: 403px;
  }

  .step1Container {
    position: relative;
  }

  .step1 {
    border-left: 3px solid
      ${({ steps: { step1Status } }) => {
    if (step1Status === "completed") {
      return "#1568bf";
    }
    return "#d8d8d8";
  }};
    padding: 0 0 25px 63px;
    transition: 0.3s all ease-in-out;

    .step1Circle {
      width: 50px;
      height: 50px;
      border: 3px solid
        ${({ steps: { step1Status } }) => {
    if (step1Status === "completed") {
      return "#1568bf";
    }
    if (step1Status === "active") {
      return "#1568bf";
    }
    return "#d8d8d8";
  }};
      border-radius: 25px;
      background-color: ${({ steps: { step1Status } }) => {
    if (step1Status === "completed") {
      return "#1568bf";
    }
    return "#fff";
  }};
      position: absolute;
      color: #1568bf;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.23px;
      left: -23px;
      top: -15px;
    }

    .step1Title {
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.18px;
      color: #4b4b4b;
      margin-bottom: 15px;
    }

    .step1Text {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.18px;
      color: #4b4b4b;
      margin-bottom: 15px;
    }

    .downloadButton {
      min-width: 130px;
      height: 40px;
      border-radius: 4px;
      border: 1px solid #1568bf;
      background-color: #1568bf;
      display: flex;
      font-size: 14px;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.18px;
      outline: none;
      color: #ffffff;
      cursor: pointer;
    }
  }

  .step2Container {
    position: relative;
    z-index: 3;
  }

  .step2 {
    border-left: 3px solid
      ${({ steps: { step2Status } }) => {
    if (step2Status === "completed") {
      return "#1568bf";
    }
    return "#d8d8d8";
  }};
    padding: 0 0 25px 63px;
    height: 0;
    overflow: hidden;
    transition: 0.3s all ease-in-out;

    .step2Circle {
      width: 50px;
      height: 50px;
      border: 3px solid
        ${({ steps: { step2Status } }) => {
    if (step2Status === "completed") {
      return "#1568bf";
    }
    if (step2Status === "active") {
      return "#1568bf";
    }
    return "#d8d8d8";
  }};
      border-radius: 25px;
      background-color: ${({ steps: { step2Status } }) => {
    if (step2Status === "completed") {
      return "#1568bf";
    }
    return "#ffffff";
  }};
      position: absolute;
      color: ${({ steps: { step2Status } }) => {
    if (step2Status === "active") {
      return "#1568bf";
    }
    return "#d8d8d8";
  }};
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.23px;
      left: -23px;
      top: -15px;
    }

    .step2Content {
      opacity: 0;
      transition: 0.3s all ease-in-out;
    }

    .step2Title {
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.18px;
      color: #4b4b4b;
      margin-bottom: 15px;
      transition: opacity 0.3s ease-in-out;
    }

    .step2Text {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.18px;
      color: #4b4b4b;
      margin-bottom: 15px;
    }

    .step2TextTwo {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.18px;
      color: #1568bf;
      margin-bottom: 15px;
    }

    .requiredFields {
			font-size: 14px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: -0.18px;
			color: red;
			margin-bottom: 15px;
		} 

    .uploadButton {
      min-width: 130px;
      height: 40px;
      border-radius: 4px;
      border: 1px solid #1568bf;
      background-color: #1568bf;
      display: flex;
      font-size: 14px;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.18px;
      color: #ffffff;
      cursor: pointer;
      outline: none;
    }

    .fileName {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.18px;
      color: #4b4b4b;
    }
  }

  .step3Container {
    position: relative;
    z-index: 4;
  }

  .step3 {
    padding: 0 0 0 63px;
    border-left: 3px solid
      ${({ steps: { step3Status } }) => {
    if (step3Status === "completed") {
      return "#1568bf";
    }
    if (step3Status === "active") {
      return "#1568bf";
    }
    return "#d8d8d8";
  }};
    height: 0;
    overflow: hidden;
    transition: 0.3s all ease-in-out;

    .step3Circle {
      width: 50px;
      height: 50px;
      border: 3px solid
        ${({ steps: { step3Status } }) => {
    if (step3Status === "completed") {
      return "#1568bf";
    }
    if (step3Status === "active") {
      return "#1568bf";
    }
    return "#d8d8d8";
  }};
      border-radius: 25px;
      background-color: ${({ steps: { step3Status } }) => {
    if (step3Status === "completed") {
      return "#1568bf";
    }
    return "#fff";
  }};
      position: absolute;
      color: ${({ steps: { step3Status } }) => {
    if (step3Status === "active") {
      return "#1568bf";
    }
    return "#d8d8d8";
  }};
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.23px;
      left: -23px;
      bottom: -60px;
      transition: 0.3s all ease-in-out;
    }

    .step3Content {
      opacity: 1;
      transition: 0.3s all ease-in-out;
    }

    .step3Title {
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.18px;
      color: #4b4b4b;
      margin-bottom: 15px;
      transition: opacity 0.3s ease-in-out;
    }

    .finish {
      min-width: 130px;
      height: 40px;
      border-radius: 4px;
      border: 1px solid #1568bf;
      background-color: #1568bf;
      outline: none;
      display: flex;
      font-size: 14px;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.18px;
      color: #ffffff;
      cursor: pointer;
    }

    .finish:disabled {
      background: #d8d8d8;
      color: #989898;
      border: 1px solid #d8d8d8;
    }
  }
`;

export default AddEmployeesWithCSVStyles;
