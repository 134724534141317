import React from "react";
import { SYSTEM_NOTIFICAITON_TYPES } from "../../../../constants/systemNotifications.constants";
import BenefitChangedExpirationType from "./BenefitChangedExpirationType";
import BenefitChangedPrice from "./BenefitChangedPrice";
import BenefitGroupChangedLocation from "./BenefitGroupChangedLocation";
import BenefitGroupLocationUpdatedInfo from "./BenefitGroupLocationUpdatedInfo";
import EmployeeChangedLocation from "./EmployeeChangedLocation";
import EmployeeLocationUpdatedInfo from "./EmployeeLocationUpdatedInfo";
import EmployeeReassignedTier from "./EmployeeReassignedTier";
import EmployeeSubscribedToMcpBenefit from "./EmployeeSubscribedToMcpBenefit";
import TierBudgetDecreased from "./TierBudgetDecreased";

const NotificationPayloadRender = ({ notification }) => {
  return (
    <div className="notification-render">
      {notification.notificationType ===
        SYSTEM_NOTIFICAITON_TYPES.BENEFIT_CHANGED_PRICE && (
          <BenefitChangedPrice notification={notification} />
        )}

      {notification.notificationType ===
        SYSTEM_NOTIFICAITON_TYPES.EMPLOYEE_REASSIGNED_TIER && (
          <EmployeeReassignedTier notification={notification} />
        )}

      {notification.notificationType ===
        SYSTEM_NOTIFICAITON_TYPES.DECREASED_TIER_BUDGET && (
          <TierBudgetDecreased notification={notification} />
        )}

      {notification.notificationType ===
        SYSTEM_NOTIFICAITON_TYPES.BENEFIT_GROUP_CHANGED_LOCATION && (
          <BenefitGroupChangedLocation notification={notification} />
        )}
      {notification.notificationType ===
        SYSTEM_NOTIFICAITON_TYPES.EMPLOYEE_CHANGED_LOCATION && (
          <EmployeeChangedLocation notification={notification} />
        )}
      {notification.notificationType ===
        SYSTEM_NOTIFICAITON_TYPES.EMPLOYEE_LOCATION_UPDATED_INFO && (
          <EmployeeLocationUpdatedInfo notification={notification} />
        )}
      {notification.notificationType ===
        SYSTEM_NOTIFICAITON_TYPES.BENEFIT_GROUP_LOCATION_UPDATED_INFO && (
          <BenefitGroupLocationUpdatedInfo notification={notification} />
        )}
      {notification.notificationType ===
        SYSTEM_NOTIFICAITON_TYPES.BENEFIT_CHANGED_EXPIRATION_TYPE && (
          <BenefitChangedExpirationType notification={notification} />
        )}
      {notification.notificationType ===
        SYSTEM_NOTIFICAITON_TYPES.EMPLOYEE_SUBSCRIBED_TO_MCP_BENEFIT && (
          <EmployeeSubscribedToMcpBenefit notification={notification} />
        )}
    </div>
  );
};

export default NotificationPayloadRender;
