import { AvailableTierPayload } from "../payloads";

class AvailableTiersRequest {
  constructor(payload = {}) {
    this.benefit_group_id = payload.benefitGroupId;
    this.available = payload.availableTiers.map(
      tierId => new AvailableTierPayload(tierId)
    );
    this.enable_instalments = payload.enableInstalments
  }
}

export default AvailableTiersRequest;
