import moment from "moment";
import { isEmpty, removeSpacesAndHTML } from "../../../services/general.utils";

class EmployeePayload {
  constructor(employeePayload = {}) {
    this.id = employeePayload.id || null;
    this.first_name =
      (employeePayload.firstName && removeSpacesAndHTML(employeePayload.firstName)) 
      || employeePayload.first_name 
      || "";
    this.last_name =
      (employeePayload.lastName && removeSpacesAndHTML(employeePayload.lastName)) 
      || employeePayload.last_name 
      || "";
    this.gender = employeePayload.gender.toLowerCase() || "";
    this.uniqueId = employeePayload.uniqueId || "";
    this.companyBranchId = employeePayload.branchCode || "";
    this.relationship = employeePayload.relationship.toLowerCase() || "";
    this.team = employeePayload.team ? removeSpacesAndHTML(employeePayload.team) : "";
    this.description = employeePayload.description ? removeSpacesAndHTML(employeePayload.description) : "";
    this.born_date = employeePayload.born_date ? moment(employeePayload.born_date).format("DD.MM.YYYY") : null;
    this.first_day_at_work = employeePayload.firstDayAtWork ? moment(employeePayload.firstDayAtWork).format("DD.MM.YYYY") : null;
    this.contact = employeePayload.contact || "";
    this.email = employeePayload.email || "";
    this.should_employee_have_hr_role = !isEmpty(employeePayload.shouldEmployeeHaveHRRole) ? employeePayload.shouldEmployeeHaveHRRole : false;
    this.tier_id =
      typeof employeePayload.tier === "object"
        ? employeePayload.tier.id
        : employeePayload.tier || "";
    this.company_id = employeePayload.companyId || "";
    this.city_ids = employeePayload.cities
      ? employeePayload.cities.map(city => city.id)
      : [];
  }
}

export default EmployeePayload;
