import styled from "styled-components";

const TableHeadColumnStyles = styled.div`
  display: flex;
  align-items: center;
  width: ${({ width }) => width};
  justify-content: ${({ justifyContent }) => justifyContent};
  margin: ${({ margin }) => margin};
  text-align: ${({ textAlign }) => textAlign};

  .tableHeadColumnName {
    margin-right: ${({ isSortable }) => isSortable && "10px"};
    font-size: ${({ fontSize }) => fontSize};
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #5d5d5d;
  }

  .arrowsContainer {
    display: flex;

    .arrowButtons {
      display: flex;
      align-items: center;
      border: none;
      outline: none;
      background: none;

      .arrowUpImg {
        width: 10px;
        transform: rotate(180deg);
        cursor: pointer;
      }

      .arrowImg {
        width: 10px;
        cursor: pointer;
      }
    }
  }
`;

export default TableHeadColumnStyles;
