import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ToggleButtonComponentStyles from "./ToggleButtonComponentStyles";
import { useTranslation } from "react-i18next";

const ToggleButtonComponent = ({ enableInstalments, onToggle }) => {
  const { t } = useTranslation();

  return (
    <ToggleButtonComponentStyles enableInstalments={enableInstalments}>
      <div className="activationCheckBox">
        {enableInstalments && <p className="activityOnText">{t("on")}</p>}
        <div className="dot" onClick={onToggle} />
        {!enableInstalments && <p className="activationOffText">{t("off")}</p>}
      </div>
      {enableInstalments ? (
        <p className="benefitActivityStatusText">{t("installments_enabled")}</p>
      ) : (
        <p className="benefitActivityStatusText">
          {t("installments_disabled")}
        </p>
      )}
    </ToggleButtonComponentStyles>
  );
};

ToggleButtonComponent.propTypes = {
  isActive: PropTypes.bool,
  onToggle: PropTypes.func,
  tiers: PropTypes.number
};

const mapStateToProps = state => {
  return {
    enableInstalments:
      state.benefits.editPageState.benefitGroup.enableInstalments
  };
};

export default connect(mapStateToProps, null)(ToggleButtonComponent);
