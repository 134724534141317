import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import { parseISO } from "date-fns";

// Components
import {
  InputLabel,
  SelectInput,
  // CheckboxInput,
  Input,
  PhoneNumberInput,
  LocationsMultiselectInput
} from "../..";

// Services
import { genderOptions, relationshipOptions } from "./addUserForm.service";
import { getChosenTiers } from "../../../modules/superAdmin/users/users.services";
import * as api from "../../../services/api/admin/users.service";
import * as employeeService from "../../../services/api/hr/employees.service";

// Models
import { useTranslation } from "react-i18next";

const MemberFieldsStyles = styled.div`
  margin: 30px 0;

  .fieldsContainer {
    margin: 0 0 10px 0;
    display: flex;
  }

  .datepickerWrapper {
    .react-datepicker-wrapper {
      width: 100% !important;
    }
  }

  .datePickerInput {
    height: 27px;
    width: 100%;
    border-radius: 3px;
    border: 1px solid #979797;
    outline: none;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: center;
    color: #4b4b4b;
    cursor: pointer;
    position: relative;
  }
`;

export const MemberFields = ({
  handleBlur,
  values,
  errors,
  touched,
  setFieldValue,
  handleChange,
  companyId,
  isEditUserForm = false
}) => {
  const { t } = useTranslation();
  const [chosenTiers, setChosenTiers] = useState([]);
  const [cities, setCities] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [resetTierInput, setResetTierInput] = useState(true);
  const [branchCodeOptions, setBranchCodeOptions] = useState([]);

  const fetchTiers = async () => {
    const response = await api.getTierOptions(companyId);
    if (response.hasError) {
      return toast.error(response.errorMessage);
    }

    setResetTierInput(false);
    if (!isEditUserForm) {
      setFieldValue("tier", "");
    }
    setChosenTiers(response);
    setResetTierInput(true);
  };

  /**
   * Returns list of company branches from server and sets it to state.
   * Is fired every time if page changes.
   */
  const fetchCompanyBranches = async () => {
    const response = await api.getCompanyBranches(companyId);
    if (response.hasError) {
      return toast.error(response.errorMessage);
    }

    const branchOptions = response.branches.map(branch => ({
      id: branch.id,
      name: branch.branchCode
    }));
    setBranchCodeOptions(branchOptions);
  };

  /**
   * Returns list of cities from server and sets it to state.
   * Is fired every time if search query or page changes.
   */
  const fetchCities = async () => {
    const response = await employeeService.getCities(search, page);

    if (response.hasError) {
      return toast.error(response.errorMessage);
    }

    setCities(response.cities);
    setTotalPages(response.pages);
  };

  /**
   * Refetches cities list from server and sets it to state.
   * If page number increments to a value that is greater than there are total pages
   * function exits.
   * Updates page number & sets it to state.
   */
  const refetchCities = async () => {
    let currentPage = page;
    let newPage = (currentPage += 1);

    if (newPage > totalPages) return;

    setPage(newPage);

    const response = await employeeService.getCities(search, newPage);

    if (response.hasError) {
      return toast.error(response.errorMessage);
    }

    let newCities = [];
    setTimeout(() => {
      newCities = [...cities, ...response.cities];
      setTimeout(() => {
        setCities(newCities);
      }, 100);
    }, 100);
  };

  const handleInputChange = newValue => {
    setPage(1);
    setSearch(newValue);

    return newValue;
  };

  const handleSelect = option => {
    setFieldValue("cities", option);
  };

  useEffect(() => {
    if (companyId && companyId !== "") {
      fetchTiers();
    }
    if (companyId && companyId !== "") {
      fetchCompanyBranches();
    }
  }, [companyId]);

  useEffect(() => {
    fetchCities();
  }, [page, search]);

  return (
    <MemberFieldsStyles>
      <div className="fieldsContainer">
        <div style={{ margin: "0 10px 0 0", width: 230 }}>
          <div className="labelAndValidation">
            <InputLabel margin="0 0 5px 0" label={t("Birthday")} />
            <div className="errorMsg" style={{ marginBottom: 5 }}>
              {errors.bornDate && touched.bornDate ? errors.bornDate : ""}
            </div>
          </div>
          <div className="datepickerWrapper">
            <DatePicker
              className="datePickerInput"
              name="bornDate"
              id="datePicker"
              selected={values.bornDate}
              dateFormat="dd/MM/yyyy"
              onChange={selectedDate => setFieldValue("bornDate", selectedDate)}
              maxDate={new Date()}
              value={parseISO(values.bornDate)}
              showYearDropdown
              placeholderText="DD/MM/YYYY"
            />
          </div>
        </div>
        <div style={{ margin: "0 10px 0 0", width: 230 }}>
          <div className="labelAndValidation">
            <InputLabel margin="0 0 5px 0" label={t("Enroll")} />
            <div className="errorMsg" style={{ marginBottom: 5 }}>
              {errors.firstDayAtWork && touched.firstDayAtWork
                ? errors.firstDayAtWork
                : ""}
            </div>
          </div>
          <div className="datepickerWrapper">
            <DatePicker
              className="datePickerInput"
              name="firstDayAtWork"
              id="datePicker"
              selected={values.firstDayAtWork}
              dateFormat="dd/MM/yyyy"
              onChange={selectedDate =>
                setFieldValue("firstDayAtWork", selectedDate)
              }
              maxDate={new Date()}
              value={parseISO(values.firstDayAtWork)}
              showYearDropdown
              placeholderText="DD/MM/YYYY"
            />
          </div>
        </div>
      </div>
      <div className="fieldsContainer">
        <div style={{ margin: "0 10px 0 0", width: 230 }}>
          <div className="labelAndValidation">
            <InputLabel margin="0 0 5px 0" label={t("Branch Code")} />
            <div className="errorMsg" style={{ marginBottom: 5 }}>
              {errors.branchCode && touched.branchCode ? errors.branchCode : ""}
            </div>
          </div>
          <SelectInput
            name="branchCode"
            onBlur={handleBlur}
            options={branchCodeOptions}
            selectedValues={branchCodeOptions}
            value={values.branchCode}
            handleChange={option => setFieldValue("branchCode", option.id)}
            shouldHaveFullWidth
            customHeight="27px"
          />
        </div>
        <div style={{ margin: "0 10px 0 0", width: 230 }}>
          <div className="labelAndValidation">
            <InputLabel margin="0 0 5px 0" label={t("Unique ID")} />
            <div className="errorMsg" style={{ marginBottom: 5 }}>
              {errors.uniqueId && touched.uniqueId ? errors.uniqueId : ""}
            </div>
          </div>
          <Input
            name="uniqueId"
            onBlur={handleBlur}
            value={values.uniqueId}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="fieldsContainer">
        <div style={{ margin: "0 10px 0 0", width: 230 }}>
          <div className="labelAndValidation">
            <InputLabel margin="0 0 5px 0" label={t("Gender")} />
            <div className="errorMsg" style={{ marginBottom: 5 }}>
              {errors.gender && touched.gender ? errors.gender : ""}
            </div>
          </div>
          <SelectInput
            name="gender"
            onBlur={handleBlur}
            options={genderOptions}
            selectedValues={genderOptions}
            value={values.gender}
            handleChange={option => setFieldValue("gender", option.name)}
            shouldHaveFullWidth
            customHeight="27px"
          />
        </div>
        <div style={{ margin: "0 10px 0 0", width: 230 }}>
          <div className="labelAndValidation">
            <InputLabel margin="0 0 5px 0" label={t("Team")} />
            <div className="errorMsg" style={{ marginBottom: 5 }}>
              {errors.team && touched.team ? errors.team : ""}
            </div>
          </div>
          <Input
            name="team"
            onBlur={handleBlur}
            value={values.team}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="fieldsContainer">
        <div style={{ margin: "0 10px 0 0", width: 230 }}>
          <div className="labelAndValidation">
            <InputLabel margin="0 0 5px 0" label={t("Phone")} />
            <div className="errorMsg" style={{ marginBottom: 5 }}>
              {errors.contact && touched.contact ? errors.contact : ""}
            </div>
          </div>
          <PhoneNumberInput
            name="contact"
            onBlur={handleBlur}
            value={values.contact}
            onChange={phone => setFieldValue("contact", phone)}
          />
        </div>
        <div style={{ margin: "0 10px 0 0", width: 230 }}>
          <div className="labelAndValidation">
            <InputLabel margin="0 0 5px 0" label={t("Description")} />
            <div className="errorMsg" style={{ marginBottom: 5 }}>
              {errors.description && touched.description
                ? errors.description
                : ""}
            </div>
          </div>
          <Input
            name="description"
            onBlur={handleBlur}
            value={values.description}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="fieldsContainer">
        <div style={{ margin: "0 10px 0 0", width: 230 }}>
          <div className="labelAndValidation">
            <InputLabel margin="0 0 5px 0" label={t("Relationship")} />
            <div className="errorMsg" style={{ marginBottom: 5 }}>
              {errors.relationship && touched.relationship
                ? errors.relationship
                : ""}
            </div>
          </div>
          <SelectInput
            name="relationship"
            onBlur={handleBlur}
            options={relationshipOptions}
            selectedValues={relationshipOptions}
            value={values.relationship}
            handleChange={option => setFieldValue("relationship", option.name)}
            shouldHaveFullWidth
            customHeight="27px"
          />
        </div>
        {resetTierInput && (
          <div style={{ margin: "0 10px 0 0", width: 230 }}>
            <div className="labelAndValidation">
              <InputLabel margin="0 0 5px 0" label={t("Tier")} />
              <div className="errorMsg" style={{ marginBottom: 5 }}>
                {errors.tier && touched.tier ? errors.tier : ""}
              </div>
            </div>
            <SelectInput
              name="tier"
              onBlur={handleBlur}
              options={chosenTiers}
              selectedValues={chosenTiers}
              value={values.tier}
              handleChange={option => setFieldValue("tier", option.id)}
              shouldHaveFullWidth
              customHeight="27px"
            />
          </div>
        )}
      </div>
      <div className="fieldsContainer">
        <div style={{ margin: "0 10px 0 0", width: 230 }}>
          <div className="labelAndValidation">
            <InputLabel margin="0 0 5px 0" label={t("Locations")} />
            <div className="errorMsg" style={{ marginBottom: 5 }}>
              {errors.cities && touched.cities ? errors.cities : ""}
            </div>
          </div>
          <LocationsMultiselectInput
            name="cities"
            options={cities}
            selectedValues={values.cities}
            onBlur={handleBlur}
            handleChange={option => handleSelect(option)}
            handleInputChange={handleInputChange}
            fetchMoreData={refetchCities}
            shouldHaveFullWidth
            customHeight="27px"
          />
        </div>
      </div>
    </MemberFieldsStyles>
  );
};

MemberFields.propTypes = {
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  values: PropTypes.shape(),
  touched: PropTypes.shape(),
  errors: PropTypes.shape(),
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentRole: PropTypes.string,
  isEditUserForm: PropTypes.bool
};
