import  styled from 'styled-components'

export const VouchersStyles = styled.div`
    width: 100%;

    .voucherContainer {
        padding: 20px 0;

        .voucherHead {
            display: flex;
            align-items: center;
            margin-bottom: 9px;
        }

        .voucher {
            display: flex;
            align-items: center;
            background: #F7F7F7;
            border-radius: 4px;
            margin-bottom: 5px;
            padding: 5px;

            &:last-child {
                margin-bottom: 0;
            }

            .voucherNameContainer {
                width: 381px;
                background: #FFFFFF;
                border: 1px solid #D5DBDD;
                border-radius: 4px;
                margin: 0 5px 0 0;
                display: flex;
                align-items: center;
                height: 34px;
                padding: 0 8px;

                .voucherNameInput {
                    width: 100%;
                    outline: none;
                    border: none;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 20px;
                    color: #1568BF;
                }
            }

            .voucherPriceContainer {
                width: 84px;
                background: #FFFFFF;
                border: 1px solid #D5DBDD;
                border-radius: 4px;
                margin: 0 5px 0 0;
                display: flex;
                align-items: center;
                height: 34px;
                padding: 0 8px;

                svg {
                    width: 40px;
                }

                .voucherPriceInput {
                    margin-left: 10px;
                    width: 100%;
                    outline: none;
                    border: none;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 24px;
                    letter-spacing: -0.233333px;
                    color: #373737;
                    mix-blend-mode: normal;
                    opacity: 0.8;
                }
            }

            .removeTierButton {
                width: 32px;
                height: 32px;
                border-radius: 4px;
                border: 1px solid #1568bf;
                background-color: #1568bf;
                display: flex;
                align-items: center;
                justify-content: center;
                outline: none;
                cursor: pointer;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #ffffff;
                display: flex;
                align-items: center;
            }
        }

        .addVoucherButton {
            background: #FFFFFF;
            border: 1px solid #1568BF;
            border-radius: 4px;
            cursor:  pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 34px;
            width: 381px;
            color: #1568BF;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            margin-top: 20px;
        }
    }
`
