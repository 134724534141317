import styled from 'styled-components'

const DetailsSectionStyles = styled.div`
	.photoAndStatusContainer {
		margin: ${({ isFormDisplay }) => (isFormDisplay ? '60px 0' : '30px 0')};
		width: 350px;

		.labelAndValidation {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}
`

export default DetailsSectionStyles
