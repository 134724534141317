import { combineReducers } from "redux";
import appReducer from "./app.reducer";
import usersReducer from "./admin/users.reducer";
import benefitTagsReducer from "./admin/benefitTags.reducer";
import companyTagsReducer from "./admin/companyTags.reducer";
import categoriesReducer from "./admin/categories.reducer";
import companiesReducer from "./admin/companies.reducer";
import providersReducer from "./admin/providers.reducer";
import benefitsReducer from "./hr/benefits.reducer";
import localBenefitsReducer from "./hr/localBenefits.reducer";
import employeesReducer from "./hr/employees.reducer";
import tiersReducer from "./hr/tiers.reducer";
import employeeBenefitsReducer from "./employee/employeeBenefits.reducer";
import refundsReducer from "./hr/refunds.reducer";
import adminDashboardReducer from "./admin/adminDashboard.reducer";
import adminReducer from "./admin/admin.reducer";

export const rootReducer = combineReducers({
  app: appReducer,
  usersPage: usersReducer,
  benefitTags: benefitTagsReducer,
  companyTags: companyTagsReducer,
  categories: categoriesReducer,
  companies: companiesReducer,
  providers: providersReducer,
  benefits: benefitsReducer,
  localBenefits: localBenefitsReducer,
  employees: employeesReducer,
  tiers: tiersReducer,
  employeeBenefitsPage: employeeBenefitsReducer,
  refundsReducer: refundsReducer,
  adminDashboard: adminDashboardReducer,
  admin: adminReducer
});
