import React, { useContext } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
// import StarRatings from 'react-star-ratings'
// assets
import { TrashIcon, EditIconSvg } from "../../../../assets/Icons";
// styles
import ProviderRowStyles from "./ProviderRowStyles";
// services
import * as providerService from "../../../../services/api/admin/providers.service";
import * as actionCreators from "../../../../actions/admin/providers.actions";
import { addHeightOnClick, addCardView } from "../providersAnimations";
import { getUrl } from "../../../../services/imageHandler";
// context
import { useTranslation } from "react-i18next";

const ProviderRow = ({
  provider,
  children,
  openModal,
  setChosenProvider,
  setChosenBenefitGroup,
  setChosenBenefit,
  removeProvider
}) => {
  const { t } = useTranslation();

  return (
    <ProviderRowStyles
      status={provider.status}
      className="row"
      id={`providerRow${provider.id}`}
    >
      <div
        className="providersRowContainer"
        onClick={() => {
          setChosenProvider();
          if (provider.benefitGroups.length > 0) {
            setChosenBenefitGroup(provider.benefitGroups[0].id);
            if (provider.benefitGroups[0].benefits.length > 0) {
              setChosenBenefit(provider.benefitGroups[0].benefits[0].id);
            } else {
              setChosenBenefit(null);
            }
          } else {
            setChosenBenefitGroup(null);
            setChosenBenefit(null);
          }
          addHeightOnClick(provider.id);
          addCardView(provider.id);
        }}
      >
        <div className="imgAndTitle">
          <div className="imgContainer">
            <img
              src={getUrl(provider.photo)}
              alt="provider logo"
              style={{ width: 70 }}
            />
          </div>
          <div className="providerNameAndCategory">
            <h2 className="providerName">{provider.name}</h2>
            {/* <p className="category">{provider.category}</p> */}
          </div>
        </div>

        <div className="rightSideContainer">
          <div
            onClick={e => {
              e.stopPropagation();
              setChosenProvider();
              openModal("editProvider");
            }}
          >
            <EditIconSvg />
          </div>
          <div
            onClick={async e => {
              e.stopPropagation();
              if (provider.benefitGroups.length > 0) {
                toast.info(t("provider_delete_info"));
              } else {
                const result = confirm(t("proved_delete_question"));
                if (result) {
                  const response = await providerService.deleteProvider(
                    provider.id
                  );

                  if (response.hasError) {
                    return toast.error(response.error.data.error.messagee);
                  }

                  removeProvider(provider.id);
                }
              }
            }}
          >
            <TrashIcon stroke="#8f8f8f" />
          </div>
        </div>
      </div>
      {children}
    </ProviderRowStyles>
  );
};

ProviderRow.propTypes = {
  provider: PropTypes.shape(),
  children: PropTypes.node,
  // openSubRow: PropTypes.func,
  // index: PropTypes.number,
  openModal: PropTypes.func,
  setChosenProvider: PropTypes.func,
  // deleteProvider: PropTypes.func,
  setChosenBenefitGroup: PropTypes.func,
  setChosenBenefit: PropTypes.func
  // chosenBenefitGroupId: PropTypes.number
};

const mapDispatchToProps = dispatch => {
  return {
    removeProvider: id => dispatch(actionCreators.removeProvider(id))
  };
};

export default connect(undefined, mapDispatchToProps)(ProviderRow);
