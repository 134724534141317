import { User } from "../../domain";

class UsersResponse {
  constructor(response = {}) {
    this.users = response.data.users.map(user => new User(user));
    this.pages = response.data.pages;
    this.count = response.data.count;
  }
}

export default UsersResponse;
