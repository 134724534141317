import { apiService } from "../api.service";
import {
  Budget,
  TotalBudget,
  BudgetSpending,
  CategoryBudget,
  CompanyUsage,
  SyncedEmployees,
  Combination
} from "../../../models/domain";
import { createRefundStatisticsDownloadDate } from "../../../utils/createRefundStatisticsDownloadDate";
import downloadAllFiles from "../../../utils/supabaseUtils/downloadAllFiles";
import generatePublicURls from "../../../utils/supabaseUtils/generatePublicURls";
import downloadImages from "../../../utils/supabaseUtils/downloadImages";

export const fetchBudgetPerTiers = async searchDateString => {
  const response = await apiService.get(
    `/dashboard/graph/budget-via-tiers?date=${searchDateString}`
  );

  if (response.hasError) return response;

  return new Budget(response.data);
};

export const fetchTotalBudget = async searchDateString => {
  const response = await apiService.get(
    `/dashboard/graph/total-budget?date=${searchDateString}`
  );

  if (response.hasError) return response;

  return new TotalBudget(response.data);
};

export const fetchBudgetSpending = async searchDateString => {
  const response = await apiService.get(
    `/dashboard/graph/budget-utilization?date=${searchDateString}`
  );

  if (response.hasError) return response;

  return new BudgetSpending(response.data);
};

export const fetchBudgetPerCategories = async searchDateString => {
  const response = await apiService.get(
    `/dashboard/graph/budget-via-category?date=${searchDateString}`
  );

  if (response.hasError) return response;

  return new CategoryBudget(response.data);
};

export const fetchCompanyUsage = async () => {
  const response = await apiService.get("/dashboard/widget/company/stats");

  if (response.hasError) return response;

  return new CompanyUsage(response.data);
};

export const getStatistics = async (searchDateString, reportType) => {
  const response = await apiService.get(
    `/dashboard/hr/report/statistics?date=${searchDateString}&report_type=${reportType}`
  );

  return response;
};

export const getRefundStatistics = async (
  reportType,
  companyId,
  setIsRefundDownloading,
  setDownloadPercentage
) => {
  const downloadDate = createRefundStatisticsDownloadDate(reportType);

  const downloadFolder = `${companyId}_${downloadDate}`;

  const downloadResponse = await downloadAllFiles(
    downloadFolder,
    setIsRefundDownloading
  );

  if (!downloadResponse?.allFiles) return;

  const { allFiles, toastId } = downloadResponse;

  const { listOfImages, toastId: idOfToast } = generatePublicURls({
    listOfImages: allFiles,
    folderName: downloadFolder,
    toastId
  });

  downloadImages({
    listOfImages,
    idOfToast,
    downloadFolder,
    setIsRefundDownloading,
    setDownloadPercentage
  });
};

export const fetchSyncedEmployees = async () => {
  const response = await apiService.get("/dashboard/widget/sync/benefits");

  if (response.hasError) return response;

  return new SyncedEmployees(response.data);
};

export const fetchTopBenefitsData = async searchDateString => {
  const response = await apiService.get(
    `/dashboard/graph/top-benefits?date=${searchDateString}`
  );

  if (response.hasError) return response;

  return new Budget(response.data);
};

export const fetchUniqueUsedCombinations = async () => {
  const response = await apiService.get(
    "/dashboard/widget/unique/used/combination"
  );

  if (response.hasError) return response;

  return new Combination(response.data);
};

export const fetchUniqueCombinationsPerTiers = async () => {
  const response = await apiService.get("/dashboard/widget/unique/combination");

  if (response.hasError) return response;

  return new Combination(response.data);
};
