class BudgetBreakdown {
  constructor(budgetBreakdownData = {}) {
    this.monthlySpent = budgetBreakdownData.monthlySpent || budgetBreakdownData.monthly_spent || 0;
    this.oneTimeSpent = budgetBreakdownData.oneTimeSpent || budgetBreakdownData.one_time_spent || 0;
    this.instalmentsSpent = budgetBreakdownData.instalmentsSpent || budgetBreakdownData.instalments_spent || 0;
    this.mcpSpent = budgetBreakdownData.mcpSpent || budgetBreakdownData.mcp_spent || 0;
    this.vouchersSpent = budgetBreakdownData.vouchersSpent || budgetBreakdownData.vouchers_spent || 0;
  }
}

export default BudgetBreakdown;
