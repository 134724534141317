import { BenefitTag } from "../../settings.entity";

const initalBenefitTagState = [];

const actionTypes = {
  setBenefitTags: "SET_BENEFIT_TAGS",
  addBenefitTags: "ADD_BENEFIT_TAGS",
  editBenefitTags: "EDIT_BENEFIT_TAGS",
  deleteBenefitTags: "DELETE_BENEFIT_TAGS"
};

const benefitTagsReducer = (state = initalBenefitTagState, action) => {
  switch (action.type) {
    case actionTypes.setBenefitTags:
      return action.payload.benefitTags.map(
        benefitTag => new BenefitTag(benefitTag)
      );
    case actionTypes.addBenefitTags:
      return [...state, new BenefitTag(action.payload)];
    case actionTypes.editBenefitTags:
      return state.map(tag =>
        tag.id === action.id ? new BenefitTag(action.payload) : tag
      );
    case actionTypes.deleteBenefitTags:
      return state.filter(tag => tag.id !== action.id);
    default:
      throw new Error(`Unknow action type: ${action.type}`);
  }
};

export { benefitTagsReducer, actionTypes, initalBenefitTagState };
