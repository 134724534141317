import React from "react";

import styled from "styled-components";
import { CountryFlags } from "../../components/locales/Icons";

const FlagCurrencyStyles = styled.div`
  height: 20px;

  img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    box-shadow: 0px 0px 5px 1px #888;
  }
`;

export const FlagCurrency = () => {
  return <FlagCurrencyStyles>{CountryFlags("rs")}</FlagCurrencyStyles>;
};
