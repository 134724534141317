import { getTableHeadHREmployees } from '../../../../../services/getTableHead.util';

let priorityCounter = 0;

/**Table header reducer. Return state by action type. 
 * @param {Array} state - An array of objects.
 * @param {Object} action - An object.
 * @returns {Array} - An array of active objects. 
 */
export const tableHeaderReducer = (state, action) => {
  const newState = getTableHeadHREmployees(action.type, state);
  
  const tableHeaders = newState.map(tableHeader => {
    if (tableHeader.id === action.type) {
      return {
        ...tableHeader,
        isSortedUp: !tableHeader.isSortedUp,
        orderPriority: priorityCounter
      };
    };

    if (action.type === "unsort") {
      return {
        ...tableHeader,
        isSortedUp: null,
        orderPriority: priorityCounter
      };
    }

    return {
      ...tableHeader
    };
  });

  priorityCounter += 1;
  return tableHeaders;
};
