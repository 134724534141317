/**
 * Calculates benefit price with VAT.
 * @param price - benefit price in tokens.
 * @param vatRate - VAT rate.
 * @returns {number} - Benefit price with VAT.
 */
export const calculatePriceWithVat = (price, vatRate) => {
  const vatAmount = price * (vatRate / 100);
  return Math.floor(price + vatAmount);
};
