import React from "react";
import PropTypes from "prop-types";

import { CheckIcon } from "../../assets/Icons";

import CheckboxHolder from "./CheckboxInputStyles";

const CheckboxInput = ({
  isChecked = false,
  name,
  onChange,
  disabled,
  onBlur,
  readOnly = false,
  width,
  height,
  cursor
}) => (
  <CheckboxHolder
    onClick={e => e.stopPropagation()}
    isChecked={isChecked}
    disabled={disabled}
    width={width}
    height={height}
    cursor={cursor}
  >
    <input
      type="checkbox"
      name={name}
      disabled={disabled}
      onChange={onChange}
      checked={isChecked}
      onBlur={onBlur}
      readOnly={readOnly}
      className="checkbox"
    />
    {isChecked && <CheckIcon />}
  </CheckboxHolder>
);

CheckboxInput.propTypes = {
  isChecked: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  cursor: PropTypes.string
};

export default CheckboxInput;
