import { Benefit } from "../../domain";

class BenefitsResponse {
  constructor(response = {}) {
    this.benefits = response.data.items.map(
      benefit => new Benefit(benefit)
      );
  }
}

export default BenefitsResponse;