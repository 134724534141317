/* eslint-disable */
import React, { useState, Fragment, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'


import FilterArrowIcon from '../../assets/images/categoryFilters/rectangle.png'
import  CustomTooltip  from '../CustomTooltip'

const FilterInputStyles = styled.div`
  display: flex;
  align-items: center;
  margin: ${({ margin }) => margin};
  position: relative;

  .inputName {
    color: #757575;
    font-size: 16px;
    margin-right: 10px;
    font-weight: 500;
  }

  .sortType {
    color: #757575;
    font-size: 16px;
    margin-right: 21px;
    font-weight: 500;
    display: flex;
    min-width: 30px;
  }

  .arrow {
    cursor: pointer;
  }

  .arrowUp {
    transform: rotate(180deg);
  }

  .filterOpener {
    border: none;
    cursor: pointer;
    outline:  none;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 19px;
  }

  


`
const FilterInput = ({ margin, inputName, eventProtectClass, isTooltipOpen, openTooltip, filterComponent }) => {

	return (
		<FilterInputStyles className={eventProtectClass} margin={margin}>
			<p className="inputName">{inputName}</p>

      <Fragment>
        {/* <p className="sortType">{sortTypeDown}</p> */}
          <button
          type="button"
          className={`filterOpener ${eventProtectClass}`}
          onClick={(e) => {
            e.stopPropagation()
            openTooltip()
          }}
        >
          
          <img src={FilterArrowIcon} alt="arrowDown" className={`arrow ${eventProtectClass}`}  />
          
          
        </button>
        
      </Fragment>
      {isTooltipOpen && 
        <CustomTooltip  customClass={eventProtectClass}>
          {filterComponent}
        </CustomTooltip>
      }
      
      
          
		</FilterInputStyles>
	)
}

FilterInput.propTypes = {
	margin: PropTypes.string,
	inputName: PropTypes.string,
	sortTypeDown: PropTypes.string,
  sortTypeUp: PropTypes.string,
  filterComponent: PropTypes.node,
  isTooltipOpen: PropTypes.bool
}

export default FilterInput
