import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Item from "./Item";

const ListStyles = styled.div`
  border: 1px solid #1568bf;
  border-radius: 4px;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  margin: 30px 0 60px 0;
`;

const List = ({ items = [], removeItem, editItem }) => (
  <ListStyles>
    {items.map(item => (
      <Item
        key={item.id}
        name={item.name}
        removeItem={() => removeItem(item.id)}
        editItem={() => editItem(item.id)}
      />
    ))}
  </ListStyles>
);

List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  removeItem: PropTypes.func.isRequired,
  editItem: PropTypes.func.isRequired
};

export default List;
