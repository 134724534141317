import React, { createContext, useReducer } from 'react'
import PropTypes from 'prop-types'

import { userInitalState, userReducer } from './user.reducer'
import { useUserActions } from './user.actions'

const UserContext =
	createContext(userInitalState)

const UserProvider = ({ children }) => {
	const [user, dispatchUser] = useReducer(userReducer, userInitalState)
	const userActions = useUserActions(dispatchUser)

	return (
		<UserContext.Provider
			value={{
				user,
				userActions
			}}
		>
			{children}
		</UserContext.Provider>
	)
}

UserProvider.propTypes = {
	children: PropTypes.node
}

export { UserProvider, UserContext }
