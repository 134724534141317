import { BrowserAuthError } from "@azure/msal-browser";
import { toast } from "react-toastify";

export const handleAzureLoginError = (error) => {
  if (error instanceof BrowserAuthError) {
    console.error('BrowserAuthError:', error.errorCode, error.errorMessage);
    if (error.errorCode === 'popup_window_error') {
      return toast.error('Popup window error! Please retry');
    
    } else if (error.errorCode === 'user_cancelled') {
      return toast.error('User cancel error! Please retry');

    } else if (error.errorCode === 'token_renewal_error') {
      return toast.error('Token renewal error! Please reauthenticate');

    } else if (error.errorCode === 'login_hint_error') {
      return toast.error('Login hint error! Please reauthenticate');

    } else if (error.errorCode === 'interaction_in_progress') {
      return toast.error('Interaction in progress! Please make sure you do not down the popup window');

    } else {
      return toast.error('Browser Auth error! Please retry');
    }
  } else {
    console.error('An unexpected error occurred:', error);
    return toast.error('An unexpected error occurred! Please retry');
  };
};