import styled from 'styled-components'

const SearchInputStyles = styled.div`
  width: ${({ searchWrapperWidth }) => searchWrapperWidth};

  .MuiIconButton-root {
    display: none;
  }

  .MuiInputBase-root {
      color: ${({ placeholderColor }) => placeholderColor};
    }

  .searchEmployees {
    background: #F7F9FC;
    border-radius: 0 8px 8px 0;
    box-shadow: 0 0;    
    padding-left: 0;
  }
`

export default SearchInputStyles;
