import { User } from '../user.entity'

const userInitalState = new User()

const actionTypes = {
	setLoggedInUser: 'SET_USER',
	setOnboardingToTrue: 'SET_ONBOARDING_TO_TRUE',
	loagoutUser: 'LOGOUT_USER',
	subscribe: 'SUBSCRIBE',
	unsubscribe: 'UNSUBSCRIBE',
	syncEmployee: 'SYNC_EMPLOYEE'
}

const userReducer = (state = userInitalState, action) => {
	switch (action.type) {
		case actionTypes.setLoggedInUser:
			return new User(action.payload.user)
		case actionTypes.setOnboardingToTrue:
			return {
				...state,
				isOnboardingDone: true
			}
		case actionTypes.loagoutUser:
			return new User()
		case actionTypes.subscribe:
			return {
				...state,
				usedTokens: state.usedTokens + action.benefitTokenPrice
			}
		case actionTypes.unsubscribe:
			return {
				...state,
				usedTokens: state.usedTokens - action.benefitTokenPrice
			}
		case actionTypes.syncEmployee:
			return {
				...state,
				isSync: true
			}
		default:
			throw new Error(`Unknow action type: ${action.type}`)
	}
}

export { userInitalState, userReducer, actionTypes }
