import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import PropTypes from "prop-types";
import FinishStepStyles from "./FinishStepStyles";
import * as actionCreators from "../../../../actions/app.actions";
import * as api from "../../../../services/api/hr/onboarding.service";
import { useTranslation } from "react-i18next";

const FinishStep = ({ finishOnboarding, newTiersAndEmployees }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const submitOnboarding = async () => {
    const response = await api.finishOnboarding();
    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : t("failed_to_finish_onboarding")
      );
    }

    finishOnboarding();
    history.push("/");
  };

  return (
    <FinishStepStyles>
      <h3 className="title">You are all done!</h3>
      <p className="text">
        {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit.
				Nam condimentum elit vel diam mattis, quis faucibus dolor dignissim.
				Nam condimentum elit vel diam mattis. Morbi congue blandit turpis. */}
      </p>
      <div className="recapSection">
        <h5 className="recapTitle">Recap:</h5>
        <p className="recapText">
          {newTiersAndEmployees ? newTiersAndEmployees.newTiers : 0} tiers made
        </p>
        <p className="recapText">
          {newTiersAndEmployees ? newTiersAndEmployees.newEmployees : 0}{" "}
          employees added
        </p>
      </div>
      <button
        className="finishButton"
        disabled={!newTiersAndEmployees}
        onClick={() => submitOnboarding()}
        type="button"
      >
        Finish
      </button>
    </FinishStepStyles>
  );
};

FinishStep.propTypes = {
  newTiersAndEmployees: PropTypes.shape()
};

const mapDispatchToProps = dispatch => {
  return {
    finishOnboarding: () => dispatch(actionCreators.finishOnboarding())
  };
};

export default connect(null, mapDispatchToProps)(FinishStep);
