const openAddCompany = (setIsAddCompanyOpen) => {
	setIsAddCompanyOpen(true)
	setTimeout(() => {
		const addCompanyForm = document.getElementById('addCompanyForm')
		const companiesList = document.getElementById('companiesList')
		companiesList.classList.add('hideCompanyList')
		addCompanyForm.classList.add('showAddCompanyForm')
	}, 150)
}

const closeAddCompany = (setIsAddCompanyOpen) => {
	const addCompanyForm = document.getElementById('addCompanyForm')
	const companiesList = document.getElementById('companiesList')
	companiesList.classList.remove('hideCompanyList')
	addCompanyForm.classList.remove('showAddCompanyForm')
	setTimeout(() => {
		setIsAddCompanyOpen(false)
	}, 400)
}

export { openAddCompany, closeAddCompany }
