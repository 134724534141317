import validator from "validator";
import { removeSpacesAndHTML } from "../../general.utils";
import { findLocales } from "../../../utils/helpers/findLocales";

export const validateCompanyForm = ({
  name,
  pib,
  address,
  idNumber,
  contact,
  invoiceEmail,
  paymentDeadline,
  photo
}) => {
  const errors = {};
  const isInLanguage = findLocales("rs");

  if (!name.trim()) {
    errors.name = isInLanguage ? "*Obavezno polje" : "* Required";
  } else if (removeSpacesAndHTML(name).length > 20) {
    errors.name = isInLanguage ? "Predugačko" : "Too long";
  } else if (!removeSpacesAndHTML(name)) {
    errors.name = isInLanguage
      ? "HTML tag nije dozvoljen"
      : "HTML tag is not allowed";
  }

  if (!pib) {
    errors.pib = isInLanguage ? "*Obavezno polje" : "* Required";
  } else if (typeof pib === "number") {
    if (pib.toString().split("").length !== 9) {
      errors.pib = isInLanguage ? "Moraju biti 9 cifara" : "9 digits";
    }
  }

  if (!address.trim()) {
    errors.address = isInLanguage ? "*Obavezno polje" : "* Required";
  } else if (!removeSpacesAndHTML(address)) {
    errors.address = isInLanguage
      ? "HTML tag nije dozvoljen"
      : "HTML tag is not allowed";
  }

  if (!idNumber) {
    errors.idNumber = isInLanguage ? "*Obavezno polje" : "* Required";
  } else if (typeof idNumber === "string") {
    if (idNumber.length !== 8) {
      errors.idNumber = isInLanguage ? "Moraju biti 8 cifara" : "8 digits";
    }
  }

  if (!contact) {
    errors.contact = isInLanguage ? "*Obavezno polje" : "* Required";
  }

  if (!invoiceEmail) {
    errors.invoiceEmail = isInLanguage ? "*Obavezno polje" : "* Required";
  } else if (!validator.isEmail(invoiceEmail)) {
    errors.invoiceEmail = isInLanguage
      ? "Neispravna email adresa"
      : "Invalid email";
  }

  if (!paymentDeadline) {
    errors.paymentDeadline = isInLanguage ? "*Obavezno polje" : "* Required";
  }

  if (!photo) {
    errors.photo = isInLanguage ? "*Obavezno polje" : "* Required";
  }
  // else if (photo.type !== 'image/png') errors.photo = 'Only PNG is acceptable'

  return errors;
};
