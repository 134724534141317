import styled from "styled-components";

export const NextMonthDropDownStyles = styled.div`
  margin-left: 30px;
  display: flex;
  align-items: center;

  .nextMonthBenefitsCondition {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #757575;
    white-space: nowrap;
    margin-right: 8px;
  }

  .tooltipContainer {
    position: relative;
    .arrowButtons {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background: transparent;
      cursor: pointer;
      width: 10px;
    }

    .tooltip {
      position: absolute;
      background: #282a3b !important;
      border-radius: 4px;
      padding: 10px;
      right: -15px;
      bottom: -78px;

      .pointerBorder {
        width: 0;
        height: 0;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-bottom: 9px solid #282a3b;
        position: absolute;
        border-radius: 2px;
        right: 11px;
        top: -9px;
      }

      .pointer {
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid #282a3b;
        position: absolute;
        border-radius: 2px;
        right: 12px;
        top: -8px;
      }

      .tooltipOption {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        white-space: nowrap;
        color: #ffffff;
        margin-bottom: 10px;
        cursor: pointer;
      }

      .tooltipOption:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    margin-left: 0;

    .tooltipContainer {
      z-index: 2;
    }
  }
`;
