import { Employee } from "../../domain";

class EmployeesResponse {
  constructor(response = {}) {
    this.employees = response.data.employees.map(
      employee => new Employee(employee)
    );
    this.count = response.data.count;
    this.pages = response.data.pages;
  }
}

export default EmployeesResponse;
