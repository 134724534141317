import React from "react";

const EmployeeChangedLocation = ({ notification }) => {
  return (
    <>
      <h4 className="notification-title">
        There have been changes regarding your location!
      </h4>
      <p className="notification-subtitle">
        You have been automatically unsubscribed from affected benefits.
      </p>
      <p className="subtitle-description">
        Please, make sure to visit My Benefits or Browse Benefits page and choose which benefits would you prefer to subscribe to for the next month, according to the new available location.
      </p>
    </>
  );
};

export default EmployeeChangedLocation;
