import { Activity } from "../../domain";

class LogsResponse {
  constructor(response = {}) {
    this.logs = response.data.logs.map(log => new Activity(log));
    this.count = response.data.count;
    this.pages = response.data.pages;
  }
}

export default LogsResponse;
