import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";
//assets
import {
  TokenIcon,
  SyncIcon,
  InfoIconSvg,
  NotificationBellSvg,
  HamburgerIconSvg,
  BLogoSvg,
  ClockIcon,
  GoBackSvg,
  FlagCurrency
} from "../../assets/Icons";
//components
import LogoutDropdown from "./logoutDropdown/LogoutDropdown";
import SwitchPlatformsDropdown from "./switchPlatformsDropdown/SwitchPlatformsDropdown";
import BudgetBreakdownDropdown from "./budgetBreakdownDropdown/BudgetBreakdownDropdown";
import { CompanyThemeLogo } from "../";
// actions
import * as actionCreators from "../../actions/hr/employees.actions";
//services
import getInitals from "../../services/getInitals";
import { filterArrayByValueOfKey, isEmpty } from "../../services/general.utils";
// hooks
import useWindowDimensions from "../../hooks/useWindowDimensions";
//styles
import StyledHeader from "./HeaderStyles";
import { getUserHistory } from "../../services/api/admin/users.service";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import { monthsInSerbian } from "../../constants/monthsInSerbian.constants";
import { TimerForLastDay } from "../timerForLastDay/TimerForLastDay";
import { useTranslation } from "react-i18next";
import {
  transformTokenCurrencyIcons,
  transformTokensToRsd
} from "../../utils/transformTokensToRsd";

const Header = ({
  headerTitle,
  svgIcon,
  isTokenVisable = false,
  openSyncModal,
  user,
  isSidebarMenuOpen,
  toggleSidebarMenu
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [isBreakdownOpen, setIsBreakdownOpen] = useState(false);

  const { pathname } = useLocation();
  const { width } = useWindowDimensions();

  const { firstName, vocativeName } = useSelector(state => state.app.user);

  const handleClick = e => {
    const avatar = document.getElementById("avatar");
    if (avatar && !avatar.contains(e.target)) {
      setIsDropDownOpen(false);
    }
  };

  const handleHistory = async () => {
    history.push(headerTitle === t("history") ? "/" : "/history");
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
  });

  const isInSerbianLanguage = localStorage.getItem("locales");

  return (
    <StyledHeader
      wrap={
        pathname.match("employees") || pathname.match("refunds")
          ? "wrap-reverse"
          : "nowrap"
      }
    >
      <div className="titleContainer">
        {headerTitle ? (
          <h1 className="headerTitle">
            {svgIcon}
            {headerTitle}
          </h1>
        ) : (
          user?.authenticatedRole && (
            <CompanyThemeLogo
              color="#1568bf"
              theme={filterArrayByValueOfKey(user.themes, "mode", user.mode)[0]}
              colorTheme="#1D1D1D"
            />
          )
        )}
        {isTokenVisable && (
          <div className="mobileTokens">
            {transformTokenCurrencyIcons()}
            <p className="tokens">
              {transformTokensToRsd(
                user.remainingTokens >= 0 ? user.remainingTokens : 0
              )}
              /{transformTokensToRsd(user.totalTokens)}
            </p>
            <div className="tooltipContainer">
              <a data-tip data-for="sadFace">
                <InfoIconSvg />
              </a>
              <ReactTooltip
                id="sadFace"
                place="top"
                effect="solid"
                className="tooltip tokenState"
                backgroundColor="#1568bf"
                borderColor="#1568bf"
                arrowColor="#1568bf"
              >
                <span>{t("rt_tt_ratio")}</span>
              </ReactTooltip>
            </div>
          </div>
        )}
      </div>

      <div
        className={
          pathname.match("employees") || pathname.match("refunds")
            ? "tokenUserContainer mobile"
            : "tokenUserContainer"
        }
      >
        {isTokenVisable && width > 768 && (
          <>
            <div className="tokenContainer">
              <div className="tokenContainerMessage">
                {t("choose_benefits_message", {
                  name: vocativeName !== "" ? vocativeName : firstName
                })}
                <b>
                  {isInSerbianLanguage === "rs"
                    ? monthsInSerbian[
                        moment()
                          .add(1, "month")
                          .format("MMMM")
                      ]
                    : moment()
                        .add(1, "month")
                        .format("MMMM")}
                </b>
                <span
                  style={{
                    width: "20px",
                    display: "inline-block",
                    position: "relative",
                    top: "1px"
                  }}
                  className="tooltipContainer"
                >
                  <a data-tip data-for="new-tooltip">
                    <InfoIconSvg />
                  </a>
                  <ReactTooltip
                    id="new-tooltip"
                    place="top"
                    effect="solid"
                    className="tooltip tokenState"
                    backgroundColor="#1568bf"
                    borderColor="#1568bf"
                    arrowColor="#1568bf"
                  >
                    <TimerForLastDay />
                  </ReactTooltip>
                </span>
              </div>
              <div>
                <div
                  className="desktopTokens"
                  style={{ width: "180px" }}
                  onClick={e => {
                    e.stopPropagation();
                    setIsBreakdownOpen(!isBreakdownOpen);
                  }}
                >
                  {transformTokenCurrencyIcons()}
                  <p className="tokens">
                    {transformTokensToRsd(
                      user?.remainingTokens >= 0 ? user?.remainingTokens : 0
                    )}
                    /{transformTokensToRsd(user?.totalTokens)}
                  </p>
                  <div className="tooltipContainer">
                    <a data-tip data-for="sadFace">
                      <InfoIconSvg />
                    </a>
                    <ReactTooltip
                      id="sadFace"
                      place="top"
                      effect="solid"
                      className="tooltip tokenState"
                      backgroundColor="#1568bf"
                      borderColor="#1568bf"
                      arrowColor="#1568bf"
                    >
                      <span>{t("rt_tt_ratio")}</span>
                    </ReactTooltip>
                  </div>
                </div>
              </div>
              <div className="sync" onClick={() => {}}>
                <p className="syncText">Submit</p>
                <SyncIcon />
              </div>
            </div>
            {isBreakdownOpen && (
              <BudgetBreakdownDropdown
                user={user}
                budgetBreakdown={user.budgetBreakdown}
                setIsBreakdownOpen={setIsBreakdownOpen}
              />
            )}
          </>
        )}
        {(pathname.match("employees") || pathname.match("refunds")) &&
          width < 767 && (
            <div
              className="hamburgerIcon"
              onClick={() => toggleSidebarMenu(isSidebarMenuOpen)}
            >
              <HamburgerIconSvg />
              <BLogoSvg color="#1568bf" width="26" height="24" opacity="1" />
            </div>
          )}
        {user.authenticatedRole.name === "Member" && width >= 768 && (
          <button onClick={handleHistory} className="memberHistory">
            {headerTitle === t("history") ? <GoBackSvg /> : <ClockIcon />}
            {headerTitle === t("history") ? t("BTD") : t("history")}
          </button>
        )}
        {user.authenticatedRole.name === "Member" && width < 768 && (
          <button
            style={{ marginRight: "87px", marginBottom: "10px" }}
            onClick={handleHistory}
            className="memberHistory"
          >
            {headerTitle === t("history") ? <GoBackSvg /> : <ClockIcon />}
            {headerTitle === t("history") ? t("BTD") : t("history")}
          </button>
        )}
        {!isEmpty(user?.roles) ? (
          <SwitchPlatformsDropdown user={user} />
        ) : (
          <div className="switchPlaceholder"></div>
        )}
        <div id="avatar" className="avatarStatusContainer">
          <div id="olvy-target" className="olvyTarget">
            <NotificationBellSvg />
          </div>
          <div
            className="avatarContainer"
            onClick={e => {
              e.stopPropagation();
              setIsDropDownOpen(!isDropDownOpen);
            }}
          >
            <p className="nameInitials">
              {getInitals(`${user.firstName} ${user.lastName}`)}
            </p>
          </div>
          <span className="loginStatus" />
          {isDropDownOpen && <LogoutDropdown user={user} />}
        </div>
      </div>
    </StyledHeader>
  );
};

Header.propTypes = {
  headerTitle: PropTypes.string,
  isTokenVisable: PropTypes.bool,
  openSyncModal: PropTypes.func,
  user: PropTypes.shape(),
  isSidebarMenuOpen: PropTypes.bool,
  toggleSidebarMenu: PropTypes.func
};

const mapStateToProps = state => {
  return {
    user: state.app.user,
    isSidebarMenuOpen: state.employees.isSidebarMenuOpen
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleSidebarMenu: isSidebarOpen =>
      dispatch(actionCreators.toggleSidebarMenu(isSidebarOpen))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
