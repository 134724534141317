import React from 'react'
import PropTypes from 'prop-types'

import InputFileButtonWrapper from './FileInputStyles'

import { AttachmentIcon } from '../../assets/Icons'


const InputFileButton = ({ text, id, handleChange, name, width = '200px' }) => (
	<InputFileButtonWrapper width={width}>
		<div
			className="fileUpload"
			onClick={() => document.getElementById(id).click()}
		>
			<div style={{ position: 'absolute' }}>
				<AttachmentIcon />
			</div>
			<p className="text">{text}</p>
		</div>
		<input
			type="file"
			name={name}
			id={id}
			onChange={handleChange}
			style={{ display: 'none' }}
		/>
	</InputFileButtonWrapper>
)

InputFileButton.propTypes = {
	handleChange: PropTypes.func,
	name: PropTypes.string,
	text: PropTypes.string,
	id: PropTypes.string,
	width: PropTypes.string
}

export default InputFileButton
