import styled from "styled-components";

const SidebarMenuStyles = styled.div`
  width: 210px;
  height: 100vh;
  background: #2c2e38;
  position: fixed;
  overflow: hidden;
  background-image: linear-gradient(to bottom, #1568bf, #1e3c72);
  z-index: 100;

  .sidebarBanner {
    position: absolute;
  }

  .lngDropdown {
    display: flex;
    justify-content: center;
  }

  .darkOverlay {
    width: 100%;
    height: 100%;
    position: absolute;
    mix-blend-mode: multiply;
    background-color: rgba(9, 102, 194, 0.25);
  }

  .logoContainer {
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-right: 20px;

    .closeSidebarMenuButton {
      margin-right: 10px;
      color: white;
      font-weight: 800;
      background: transparent;
      border: transparent;
      outline: none;
      padding: 5px;
      cursor: pointer;
    }

    .logo {
      color: #fff;
      font-size: 24px;
      font-weight: 600;

      path {
        fill: white;
      }
    }
  }
  .sidebarTabsContainer {
    height: 100%;
    width: 100%;
    position: relative;
  }

  .logoutTab {
    position: absolute;
    bottom: 15.416%;
    justify-content: flex-start !important;
  }

  .navLink {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .navLink:hover {
    background: #f1f1f426;
  }

  .isActive {
    background: #f1f1f426;
  }

  @media screen and (max-width: 767px) {
    display: ${({ display }) => display};
  }
`;

export default SidebarMenuStyles;
