import styled from 'styled-components'

const DatePickerInputStyles = styled.div`
	height: 27px;
	width: 100%;

	.react-datepicker-wrapper{
			width: 100%;
			height: 100%;
		.react-datepicker__input-container{
			width: 100%;
			height: 100%;
			input {
				width: 100%;
				height: 100%;
				outline: none;
				border-radius: 4px;
				border: solid 1px #979797;
				padding: 0 10px;
				font-size: 14px;
				font-weight: 500;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				-webkit-letter-spacing: normal;
				-moz-letter-spacing: normal;
				-ms-letter-spacing: normal;
				letter-spacing: normal;
				color: #5d5d5d;
				position: relative;
				text-align: center;
			}
		}
	}	
`

export default DatePickerInputStyles
