import styled from 'styled-components'

const BenefitFormStyles = styled.div`

	.publishBenefitLink {
    color: #1e3c72;
    cursor: pointer;
    font-size: 15px;
  }
	.basicInfoSection {
		margin: 20px 0;
		width: 100%;
	}

	.labelAndValidation {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 24px;
	}

	.labelStyles {
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.71;
		letter-spacing: -0.23px;
		color: #373737;
	}

	.inputStyles {
		height: 46px;
		border-radius: 4px;
		border: solid 1px #d5dbdd;
		background-color: #ffffff;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.71;
		letter-spacing: -0.23px;
		color: #373737;
	}

	.topInputsContainer {
		

		.inputContainer {
			min-width: 195px;
			width: 100%;
			margin-bottom: 10px;
		}
	}

	.middleInputContainer {
		margin-bottom: 10px;


		.react-dropdown-select {
			height: 46px;
			border-radius: 4px;
			border: solid 1px #d5dbdd !important;
			background-color: #ffffff;
			font-size: 14px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.71;
			letter-spacing: -0.23px;
			color: #373737;
			width: 100%;
			box-shadow: none !important;
			cursor: pointer;
			display: flex;
			align-items: center;
		
			.react-dropdown-select-content {
				height: 100%;
				display: flex;
				padding: 0 5px;
				align-items: center;
				flex-wrap: nowrap;
				text-overflow: ellipsis;
				overflow-x: hidden;
				width: 70px;
				white-space: nowrap;

				input {
					font-size: 12px;
					font-weight: normal;
					font-stretch: normal;
					font-style: normal;
					line-height: normal;
					letter-spacing: normal;
					color: #5d5d5d;
					overflow-x: scroll;
				}
			}

			.react-dropdown-select-dropdown-handle {
				height: 100%;
				display: flex;
				align-items: center;
				margin: 0 !important;
			}
		}

		.react-dropdown-select-dropdown {
			max-height: 150px;
		}

		.itemContainer {

			&:hover {
				background: #1568bf;

				.dropDownItem {
					color: #fff;
				}
			}

			.dropDownItem {
				font-size: 12px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: normal;
				color: #5d5d5d;
			}
		}
	}

	.bottomInputContainer {
		width: 100%;

		.summary {
			width: 100%;
			min-width: 100%;
			max-width: 100%;
			height: 100px;
			overflow-y: auto;
			min-height: 100px;
			max-height: 100px;
			border-radius: 4px;
			border: solid 1px #d5dbdd;
			background-color: #ffffff;
			padding: 12px 14px;
			font-size: 14px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.71;
			letter-spacing: -0.23px;
			color: #373737;
			outline: none;
			resize: none;
		}
	}

	.photoSectionContainer {
		margin: 20px 0;
		width: 100%;
	}

	.buttonContainer {
		display: flex;
		margin-bottom: 10px;
	}
	
	.descriptionContainer {
		width: 100%;
		margin: 10px 0;

		.rdw-editor-wrapper {
			.rdw-editor-toolbar {
				.rdw-emoji-wrapper {
					.rdw-emoji-modal {
						left: -20px !important;
					}	
				}
				.rdw-link-wrapper {
					.rdw-link-modal {
						font-size: 14px;
						.rdw-link-modal-label {
							font-size: 14px;
						}
					}
				}
			}
			.rdw-editor-main {
				width: 100%;
				min-width: 100%;
				max-width: 100%;
				height: 150px;
				overflow-y: auto;
				min-height: 150px;
				border-radius: 4px;
				border: solid 1px #d5dbdd;
				background-color: #ffffff;
				padding: 12px 14px;
				font-size: 14px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.71;
				letter-spacing: -0.23px;
				color: #373737;
				outline: none;
				resize: none;
				.DraftEditor-root {
					z-index: 0;
				}
			}
		}
	}
`

export default BenefitFormStyles
