import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback
} from "react";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import DatePicker from "react-datepicker";
// assets
import ActivityLog from "../../../assets/images/activity-log.png";
// components
import {
  Header,
  Card,
  SearchInput,
  MultiselectInput
  // IntitialsCircle
} from "../../../components";
// styles
import { override } from "../../../services/loadingOverride.styles";
import ActivityStyles from "./ActivityStyles";
// services
import * as activityService from "../../../services/api/admin/activity.service";
import * as api from "../../../services/api/admin/users.service";
import { isEmpty } from "../../../services/general.utils";
//utils
import {
  formatDateForPlaceholder,
  formatDateForSearch
} from "../../../services/date.utils";
import debounce from "../../../utils/helpers/debounce";
// consts
const initalDate = new Date();
const initalSearchDateString = formatDateForSearch(initalDate);
const LIMIT = 20;
import { useTranslation } from "react-i18next";

const Activity = () => {
  const { t } = useTranslation();
  const [logs, setLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [date, setDate] = useState(initalDate);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchDateString, setSearchDateString] = useState(
    initalSearchDateString
  );
  const [isNextDayDisabled, setIsNextDayDisabled] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [chosenCompanies, setChosenCompanies] = useState([]);
  const [chosenCompanyIds, setChosenCompanyIds] = useState([]);
  const [companiesPage, setCompaniesPage] = useState(1);
  const [totalCompaniesPages, setTotalCompaniesPages] = useState(1);
  const [roles, setRoles] = useState([]);
  const [chosenRoles, setChosenRoles] = useState([]);
  const [chosenRoleIds, setChosenRoleIds] = useState([]);
  const [keywordSearch, setKeywordSearch] = useState("");

  const isMounted = useRef(true);
  const observer = useRef();
  const areFiltersLoaded = useRef(false);
  const lastActivityLogRef = useCallback(
    node => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && pageNumber < totalPages) {
          setPageNumber(prevPageNumber => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [pageNumber, totalPages]
  );

  const toggleDisableNextDayButton = (date, setIsNextDayDisabled) => {
    if (date.setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0)) {
      setIsNextDayDisabled(true);
    } else {
      setIsNextDayDisabled(false);
    }
  };

  const fetchFilters = async () => {
    await fetchCompanies();
    await fetchRoles();

    areFiltersLoaded.current = true;
  };

  const fetchCompanies = async () => {
    const response = await api.getCompanyOptions(companiesPage);
    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : t("failed_to_get_companies")
      );
    }

    setCompanies(response.companies);
    setTotalCompaniesPages(response.pages);
  };

  /**
   * Refetches paginated companies.
   * Fired from on scroll event from select input.
   * Sets new companies to state, along with page number.
   */
  const refetchCompanies = async () => {
    let currentPage = companiesPage;
    let newPage = (currentPage += 1);

    if (newPage > totalCompaniesPages) return;

    setCompaniesPage(newPage);

    const response = await api.getCompanyOptions(newPage);
    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : t("failed_to_get_companies")
      );
    }

    let newCompanies = [];
    setTimeout(() => {
      newCompanies = [...companies, ...response.companies];
      setTimeout(() => {
        setCompanies(newCompanies);
      }, 100);
    }, 100);
  };

  const fetchRoles = async () => {
    const response = await api.getRolesForDropdown();
    if (response.hasError) {
      return toast.error(
        response.errorMessage ? response.errorMessage : t("failed_to_get_roles")
      );
    }

    setRoles(response);
  };

  /**
   * Handles select of element from multiselect.
   * Sets selected elements' ids to state.
   * @param {Company} options - Selected options, emmited from multiselect.
   */
  const handleSetChosenCompanies = options => {
    let selectedCompanyIds = [];

    if (options && options.length) {
      selectedCompanyIds = options.map(category => category.id);
    }

    setChosenCompanies(options);
    setChosenCompanyIds(selectedCompanyIds);
  };

  /**
   * Handles select of element from multiselect.
   * Sets selected elements' ids to state.
   * @param {UserRole} options - Selected options, emmited from multiselect.
   */
  const handleSetChosenRoles = options => {
    let selectedRoleIds = [];

    if (options && options.length) {
      selectedRoleIds = options.map(role => role.id);
    }

    setChosenRoles(options);
    setChosenRoleIds(selectedRoleIds);
  };

  const handleDateChange = chosenDate => {
    setDate(chosenDate);
    setSearchDateString(formatDateForSearch(chosenDate));
  };

  const nextDay = () => {
    const incrementedDay = new Date(date.setDate(date.getDate() + 1));
    setDate(incrementedDay);
    setSearchDateString(formatDateForSearch(incrementedDay));
  };

  const prevDay = () => {
    const decrementedDate = new Date(date.setDate(date.getDate() - 1));
    setDate(decrementedDate);
    setSearchDateString(formatDateForSearch(decrementedDate));
  };

  const handleFetchAfterSearch = search => {
    setKeywordSearch(search);
    fetchLogs(undefined, undefined, undefined, undefined, search);
  };

  const debouncedSearch = debounce(handleFetchAfterSearch, 500);

  const fetchLogs = async (
    dateQuery = searchDateString,
    page = pageNumber,
    companyIds = chosenCompanyIds,
    roleIds = chosenRoleIds,
    searchText = keywordSearch
  ) => {
    const response = await activityService.getLogs({
      page,
      limit: LIMIT,
      companyIds,
      roleIds,
      dateQuery,
      searchText
    });

    if (response && isMounted.current) {
      setLogs(response.logs);
      setTotalPages(response.pages);
      setIsLoading(false);
      setTimeout(() => {
        const logsCard = document.getElementById("logsCard");
        if (logsCard && isMounted.current) {
          logsCard.style.opacity = 1;
        }
      }, 100);
    }
  };

  const fetchAditionalLogs = async (
    page = pageNumber,
    companyIds = chosenCompanyIds,
    roleIds = chosenRoleIds,
    searchText = keywordSearch,
    dateQuery = searchDateString
  ) => {
    const dto = {
      page,
      limit: LIMIT,
      companyIds,
      roleIds,
      dateQuery,
      searchText
    };
    const response = await activityService.getLogs(dto);

    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : t("failed_to_get_additional_logs")
      );
    }

    setLogs(prevLogs => [...prevLogs, ...response.logs]);
  };

  const renderLog = log => {
    if (isEmpty(log.user.roles)) {
      if (log.model === "") {
        return log.user.company.id !== null
          ? `${log.user.firstName} ${log.user.lastName} (${
              log.user.company.name
            })  ${log.action}. ${
              !isEmpty(log.description)
                ? ` Changed fields: ${log.description}.`
                : ""
            } `
          : `${log.user.firstName} ${log.user.lastName} ${log.action}. ${
              !isEmpty(log.description)
                ? ` Changed fields: ${log.description}.`
                : ""
            }`;
      }

      return log.user.company.id !== null
        ? `${log.user.firstName} ${log.user.lastName} (${
            log.user.company.name
          })  ${log.action} ${log.model}. ${
            !isEmpty(log.description)
              ? ` Changed fields: ${log.description}.`
              : ""
          }`
        : `${log.user.firstName} ${log.user.lastName}  ${log.action} ${
            log.model
          }. ${
            !isEmpty(log.description)
              ? ` Changed fields: ${log.description}.`
              : ""
          }`;
    }

    if (log.model === "") {
      return log.user.company.id !== null
        ? `${log.user.firstName} ${log.user.lastName} (${log.user.roles
            .map(role => role.name)
            .join(", ")}, ${log.user.company.name})  ${log.action}. ${
            !isEmpty(log.description)
              ? ` Changed fields: ${log.description}.`
              : ""
          }`
        : `${log.user.firstName} ${log.user.lastName} (${log.user.roles
            .map(role => role.name)
            .join(", ")})  ${log.action}. ${
            !isEmpty(log.description)
              ? ` Changed fields: ${log.description}.`
              : ""
          }`;
    }

    return log.user.company.id !== null
      ? `${log.user.firstName} ${log.user.lastName} (${log.user.roles
          .map(role => role.name)
          .join(", ")}, ${log.user.company.name})  ${log.action} ${
          log.model
        }. ${
          !isEmpty(log.description)
            ? ` Changed fields: ${log.description}.`
            : ""
        }`
      : `${log.user.firstName} ${log.user.lastName} (${log.user.roles
          .map(role => role.name)
          .join(", ")})  ${log.action} ${log.model}. ${
          !isEmpty(log.description)
            ? ` Changed fields: ${log.description}.`
            : ""
        }`;
  };

  useEffect(() => {
    fetchAditionalLogs();
  }, [pageNumber]);

  useEffect(() => {
    setPageNumber(1);
    fetchLogs();
    fetchFilters();
    toggleDisableNextDayButton(date, setIsNextDayDisabled);
  }, [date]);

  useEffect(() => {
    if (areFiltersLoaded.current === true) fetchLogs();
  }, [chosenCompanyIds, chosenRoleIds]);

  useEffect(() => {
    if (isLoading === false) {
      const datePicker = document.getElementById("datePicker");
      datePicker.setAttribute("readOnly", true);
    }
  }, [isLoading]);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  return (
    <ActivityStyles>
      <div className="activityContainer">
        <Header headerTitle={t("Activity")} />
        <div className="activityContent">
          {isLoading ? (
            <div
              style={{
                height: "calc(100vh - 180px)",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <BeatLoader
                css={override}
                size={25}
                color="#123abc"
                loading={isLoading}
              />
            </div>
          ) : (
            <Card padding="30px" id="logsCard">
              <div className="filtersWrapper">
                <div
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    justifyContent: "space-between",
                    gap: "15px"
                  }}
                >
                  <div className="companiesFilterWrapper">
                    <MultiselectInput
                      name="companies"
                      options={companies}
                      shouldHaveFullHeight={true}
                      placeholder={t("filter_by_companies")}
                      handleChange={option => handleSetChosenCompanies(option)}
                      selectedValues={chosenCompanies}
                      fetchMoreData={refetchCompanies}
                    />
                  </div>
                  <div className="companiesFilterWrapper">
                    <MultiselectInput
                      name="roles"
                      options={roles}
                      shouldHaveFullHeight={true}
                      placeholder={t("filter_by_roles")}
                      handleChange={option => handleSetChosenRoles(option)}
                      selectedValues={chosenRoles}
                    />
                  </div>
                </div>
                <SearchInput
                  fetchData={handleFetchAfterSearch}
                  setSearch={debouncedSearch}
                  search={keywordSearch}
                  customClass="logsSearch"
                  setPagginationPage={setPageNumber}
                  useTimeout={false}
                />
              </div>
              <div className="header">
                <div className="datePickerHolder">
                  <button
                    type="button"
                    className="previousDayButton"
                    onClick={() => prevDay()}
                  >
                    {t("prev_day")}
                  </button>
                  <DatePicker
                    className="datePickerInput"
                    id="datePicker"
                    selected={date}
                    dateFormat="dd/MM/yyyy"
                    onChange={chosenDate => handleDateChange(chosenDate)}
                    required
                    maxDate={new Date()}
                    value={date}
                  />
                  <button
                    type="button"
                    className="nextDayButton"
                    id="nextDayButton"
                    disabled={isNextDayDisabled}
                    onClick={() => nextDay()}
                  >
                    {t("next_day")}
                  </button>
                </div>
              </div>
              <div className="cardHeight">
                {logs.length > 0 ? (
                  <div className="content">
                    <div className="listContainer">
                      <div className="list">
                        {logs.map((log, index) => {
                          if (logs.length === index + 1) {
                            return (
                              <div
                                ref={lastActivityLogRef}
                                className="item"
                                key={log.id}
                              >
                                <div className="dotAndDateContainer">
                                  <p className="time">
                                    {`${`0${log.createdAt.getHours()}`.slice(
                                      -2
                                    )}:${`0${log.createdAt.getMinutes()}`.slice(
                                      -2
                                    )}`}
                                  </p>
                                  <div className="outerDot">
                                    <div className="innerDot" />
                                  </div>
                                </div>
                                <div className="logAndAvatar">
                                  <p className="log">{renderLog(log)}</p>
                                </div>
                              </div>
                            );
                          }
                          return (
                            <div className="item" key={log.id + "item"}>
                              <div className="dotAndDateContainer">
                                <p className="time">
                                  {`${`0${log.createdAt.getHours()}`.slice(
                                    -2
                                  )}:${`0${log.createdAt.getMinutes()}`.slice(
                                    -2
                                  )}`}
                                </p>
                                <div className="outerDot">
                                  <div className="innerDot" />
                                </div>
                              </div>
                              <div className="logAndAvatar">
                                <p className="log">{renderLog(log)}</p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      minHeight: 460
                    }}
                  >
                    <img src={ActivityLog} alt="activity placeholder" />
                    <p className="text">
                      {t("no_activity_logs")}
                      {formatDateForPlaceholder(date)}
                    </p>
                  </div>
                )}
              </div>
            </Card>
          )}
        </div>
      </div>
    </ActivityStyles>
  );
};

export default Activity;
