import { toast } from "react-toastify";

export const handleCompanyThemeFormError = ({ error }) => {
  const { data } = error;
  if (data.errors) {      
    if (data.errors.photo) {
      toast.error(`${data.errors.photo.keys[0]}`);
    }
  } else {
    toast.error(data.error.message);
  }
};
