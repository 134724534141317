/**
 * Downloads blob file.
 * @param {any} content - Data for blob file.
 * @param {string} filename
 * @param {string} contentType
 */
export const downloadBlob = (content, filename, contentType) => {
  // Create a blob
  const blob = new Blob(["\ufeff", content], { type: contentType });
  const url = URL.createObjectURL(blob);

  // Create a link to download it
  let downloadLink = document.createElement("a");
  downloadLink.href = url;
  downloadLink.setAttribute("download", filename);
  downloadLink.click();
};

/**
 * Creates download links and downloads base64 encoded string file.
 * @param {string} data
 * @param {string} filename
 */
export const downloadCsvFromBase64EncodedString = (data, filename) => {
  const element = document.createElement("a");
  element.setAttribute("href", "data:text/plain;base64," + data);
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};
