import styled from 'styled-components'

const EmployeeDetailsStyles = styled.section`
	.employeeDetailsContainer {
		width: calc(100% - 210px);
		margin-left: 210px;
		background: whitesmoke;
		min-height: 100vh;
	}

	.employeeDetailsContent {
		padding: 0 30px 30px 30px;
		display: flex;
	}

	#employeeContentCard {
		opacity: 0;
		transition: all 0.4s ease-in-out;
	}

	.tableHead {
    padding: 0 30px;
    display: flex;
    align-items: center;
	}
	
	.tableBodyContainer {
    background: #f7f7f7;
    padding: 30px;
    margin: 20px 0 0 0;
    border-radius: 4px;
  }
`

export default EmployeeDetailsStyles
