import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

const EmployeeSubscribedToMcpBenefit = ({ notification }) => {
  const { t } = useTranslation();
  return (
    <>
      <h4 className="notification-title">
        {t("subscribed_success_to_mcp_title")}
      </h4>
      <p className="notification-subtitle">
        {t("subscribed_success_to_mcp_info")}
      </p>
      <p className="subtitle-description">
        {t("subscribed_success_to_mcp_info2", {
          minimumContractPeriod: notification.payload.minimumContractPeriod
        })}
        {moment(notification.payload.minimumContractPeriodExpiration).format(
          "DD. MMMM"
        )}
      </p>
    </>
  );
};

export default EmployeeSubscribedToMcpBenefit;
