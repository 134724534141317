import { css } from '@emotion/core'

const override = css`
  display: block;
  margin: 0 auto;
  border-color: #1568bf;
`

const subscribe = css`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border-color: #1568bf;
  background-color:rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
`

export { override, subscribe }
