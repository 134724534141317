/* eslint-disable */
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const CustomTooltipStyles = styled.div`
    position: absolute;
    top:25px;
    right: -25px;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #1568bf !important;
    padding: 8px;
  

  .pointerBorder {
    width: 0; 
    height: 0; 
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid #1568bf;
    position: absolute;
    border-radius: 2px;
    right: 19px;
    top: -9px;
  }

  .pointer {
    width: 0; 
    height: 0; 
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #fff;
    position: absolute;
    border-radius: 2px;
    right: 20px;
    top: -8px;
  }
`


const CustomTooltip =  ({children, customClass}) => {
    return (
        <CustomTooltipStyles className={customClass}>
            <div  className={`pointerBorder ${customClass}`}></div>
            <div className={`pointer ${customClass}`}></div>
            {children}
        </CustomTooltipStyles>
    )
}

CustomTooltip.propTypes = {
    children: PropTypes.node,
    customClass: PropTypes.string
}

export default CustomTooltip
