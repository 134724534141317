import styled from "styled-components";

const TiersFormStyles = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 500px;

  .circleLogoContainer {
    width: 126px;
    height: 126px;
    margin-bottom: 30px;
  }

  .title {
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.81;
    letter-spacing: normal;
    text-align: center;
    color: #4b4b4b;
    margin-bottom: 30px;
  }

  .text {
    width: 532px;
    padding: 0 20px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: center;
    color: #4b4b4b;
    margin-bottom: 10px;
  }

  .tiersContainer {
    padding-top: 10px;
    max-height: 180px;
    width: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 4px;
    margin-bottom: 20px;
    background-color: #f7f7f7;
  }

  .labelsContainer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.18;
    letter-spacing: -0.18px;
    color: #373737;

    .firstLabel {
      width: 138px;
    }

    .secondLabel {
      width: 138px;
    }

    .thirdLabel {
      width: 100px;
      display: flex;
      align-items: center;

      .iconContainer {
        width: 12px;
        height: 12px;
        background: #a1a3a7;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-size: 6px;
        font-weight: bold;
        margin-left: 8px;
      }

      .tooltip {
        width: 118px;
        /* height: 107px; */
        background: #282a3b !important;
        border: 1px solid #282a3b !important;
        padding: 0;
        border-radius: 4px;
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.85;
        letter-spacing: -0.22px;
        color: #ffffff;
        text-align: center;
      }

      .tooltip:after {
        border-top: 8px solid #282a3b !important;
        margin-left: -3px !important;
      }
    }

    .forthLabel {
      width: 37px;
    }
  }

  .tierItem {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 37px;
    margin-bottom: 20px;
  }

  .tierNameInput {
    width: 138px;
    height: 34px;
    border: solid 1px #979797;
    border-radius: 4px;
    outline: none;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1568bf;
    padding: 0 5px;
  }

  .tokensContainer {
    display: flex;
    align-items: center;
    height: 42px;
    position: relative;
    width: 100px;

    .tokenValueInput {
      width: 100%;
      height: 34px;
      margin-right: 11px;
      border-radius: 4px;
      border: solid 1px #979797;
      outline: none;
      padding: 0 5px 0 37px;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #5d5d5d;
      position: absolute;
    }
  }

  .currencyContainer {
    height: 42px;
    display: flex;
    align-items: center;
    position: relative;
    width: 150px;

    .currencyValueInput {
      width: 100%;
      height: 34px;
      margin-right: 11px;
      border-radius: 4px;
      border: solid 1px #979797;
      outline: none;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #5d5d5d;
      position: absolute;
      padding: 0 38px 0 10px;
    }
  }

  .label {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #5d5d5d;
    position: absolute;
    right: 10px;
  }

  .removeTierButton {
    width: 34px;
    height: 34px;
    border-radius: 4px;
    border: 1px solid #1568bf;
    background-color: #1568bf;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    display: flex;
    align-items: center;
    margin: 11px 0 0 10px;
  }

  .addTierButton {
    width: 209px;
    min-height: 27px;
    border-radius: 4px;
    border: 1px solid #1568bf;
    background-color: #1568bf;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    cursor: pointer;

    p {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      display: flex;
      align-items: center;
    }
  }

  input::placeholder {
    color: #0000004d;
  }

  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: none;
  }
`;

export default TiersFormStyles;
