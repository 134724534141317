import React from 'react'

const ActiveIcon = ({
	opacity = "0.1",
	fill = "#FFF",
	stroke = "#FFF"
}) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="20"
		height="20"
		viewBox="0 0 20 20"
	>
		<g fill="none" fillRule="evenodd">
			<path d="M0 0h20v20H0z" />
			<circle
				cx="10"
				cy="10"
				r="7.583"
				fill={fill}
				fillOpacity={opacity}
				stroke={stroke}
				strokeOpacity="0.4"
				strokeWidth="1.5"
			/>
			<path
				fill="#FFF"
				fillRule="nonzero"
				d="M8.664 13.594l-2.709-2.709a.418.418 0 010-.589l.59-.589a.416.416 0 01.589 0l1.824 1.824 3.908-3.907a.417.417 0 01.59 0l.589.589a.419.419 0 010 .59l-4.792 4.79a.415.415 0 01-.59 0z"
			/>
		</g>
	</svg>
)

export default ActiveIcon
