import { apiService } from "../api.service";
import { BenefitGroup } from "../../../models/domain";

export const getChosenBenefitGroup = async benefitGroupId => {
  const response = await apiService.get(`/benefit-group/${benefitGroupId}`);

  if (response.hasError) return response;

  return new BenefitGroup(response.data);
};

export const refreshBenefitGroup = async benefitGroupId => {
  const response = await apiService.get(`/benefit-group/refresh/${benefitGroupId}`);

  if (response.hasError) return response;

  return new BenefitGroup(response.data);
};

/**
 * Marks new benefit group as read.
 * @param {number} benefitGroupId 
 * @returns {Object}
 */
export const markNewBenefitGroupAsSeen = async benefitGroupId => {
  const payload = { benefit_group_id: benefitGroupId };
  const response = await apiService.post(`/benefit-group/new/mark-as-read`, payload);

  if (response.hasError) return response;

  return response;
}

export const getBenefitGroupForPreview = async benefitGroupId => {
  const response = await apiService.get(`/benefit-group/preview/${benefitGroupId}`);

  if (response.hasError) return response;

  return new BenefitGroup(response.data);
}
