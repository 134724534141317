import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
// components
import BenefitsList from "./BenefitsList";
import BenefitDetails from "./BenefitDetails";
// services
import {
  removeHeightOnClick,
  removeCardView
} from "../BenefitGropRow/benefitGroupRowAnimation.services";
import { useTranslation } from "react-i18next";

const BenefitGroupSubRowStyles = styled.div`
  .subRowContainer {
    padding: 5px 30px 0 30px;
  }

  .subRowContentContainer {
    overflow: hidden;
    transition: height 0.3s ease-out, border-width 0.1s linear;
    height: 0;
    position: relative;
  }

  .subRowContent {
    display: flex;
  }
`;

const BenefitGroupSubRow = ({
  benefitGroup,
  openModal,
  setChosenBenefitId,
  chosenBenefitGroupId,
  chosenBenefitId,
  fetchData
}) => {
  const { t } = useTranslation();
  const [chosenBenefit, setChosenBenefit] = useState(undefined);

  useEffect(() => {
    if (benefitGroup.benefits.length > 0) {
      const findChosenBenefit = benefitGroup.benefits.find(
        benefit => benefit.id === chosenBenefitId
      );

      if (findChosenBenefit) {
        setChosenBenefit(findChosenBenefit);
        setChosenBenefitId(findChosenBenefit.id);
      } else {
        setChosenBenefit(benefitGroup.benefits[0]);
        setChosenBenefitId(benefitGroup.benefits[0].id);
      }
    } else {
      setChosenBenefit(undefined);
      setChosenBenefitId(undefined);
    }
  }, [chosenBenefitGroupId, benefitGroup]);

  const setBenefit = benefit => {
    setChosenBenefit(benefit);
  };
  return (
    <BenefitGroupSubRowStyles className="benefitGroupRow" id="benefitGroupRow">
      <div className="subRowContainer">
        <div
          className="subRowContentContainer"
          id={`subRowContentContainer${benefitGroup.id}`}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: 6
            }}
          >
            <div
              style={{ fontSize: 20, cursor: "pointer" }}
              onClick={() => {
                removeHeightOnClick(benefitGroup.id);
                removeCardView(benefitGroup.id);
                document.getElementById(
                  `subRowContentContainer${benefitGroup.id}`
                ).style.borderTop = "";
              }}
            >
              x
            </div>
          </div>
          <div className="subRowContent">
            <BenefitsList
              chosenBenefitId={chosenBenefit && chosenBenefit.id}
              benefits={benefitGroup.benefits}
              setChosenBenefitId={setChosenBenefitId}
              openModal={openModal}
              setBenefit={setBenefit}
              chosenBenefitGroupId={chosenBenefitGroupId}
            />
            <div style={{ width: "48%" }}>
              {chosenBenefit && (
                <BenefitDetails
                  benefit={chosenBenefit}
                  openModal={openModal}
                  setChosenBenefitId={setChosenBenefitId}
                  fetchData={fetchData}
                />
              )}
            </div>
          </div>
          <div style={{ height: 30 }} />
        </div>
      </div>
    </BenefitGroupSubRowStyles>
  );
};

BenefitGroupSubRow.propTypes = {
  benefitGroup: PropTypes.shape(),
  setChosenBenefitId: PropTypes.func,
  openModal: PropTypes.func,
  chosenBenefitGroupId: PropTypes.number,
  chosenBenefitId: PropTypes.number
};

export default BenefitGroupSubRow;
