import React from "react";
import PropTypes from "prop-types";
// componenets
import AddTier from "./AddTier";
import EditTier from "./EditTier";

const FormRenderer = ({ modalType, tokenWorth, selectedTier, closeModal }) => {
  switch (modalType) {
    case "addTier":
      return <AddTier tokenWorth={tokenWorth} closeModal={closeModal} />;
    case "editTier":
      return (
        <EditTier
          tokenWorth={tokenWorth}
          closeModal={closeModal}
          selectedTier={selectedTier}
        />
      );
    default:
      throw new Error(`Unknow action type: ${modalType}`);
  }
};

FormRenderer.propTypes = {
  modalType: PropTypes.string,
  addTier: PropTypes.func,
  editTier: PropTypes.func,
  selectedTier: PropTypes.shape(),
  closeModal: PropTypes.func,
  tokenWorth: PropTypes.shape()
};

export default FormRenderer;
