import React from "react";
import PropTypes from "prop-types";

// Components
import { Table, TableHead } from "../..";
import { TokenIcon } from "../../../assets/Icons";

// Services
import { isEmpty } from "../../../services/general.utils";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

// Config builders
import employeeActiveBenefitsTableHead from "../../../pages/HrPages/EmployeePages/userBenefits.tableHeader";

// Styles
import EmployeeActiveBenefitsStyles from "./EmployeeActiveBenefitsStyles";
import { useTranslation } from "react-i18next";
import {
  transformTokenCurrencyIcons,
  transformTokensToRsd
} from "../../../utils/transformTokensToRsd";

const EmployeeActiveBenefits = ({ singleEmployee, backgroundOpen }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  return (
    !isEmpty(singleEmployee?.userBenefits) && (
      <EmployeeActiveBenefitsStyles backgroundOpen={backgroundOpen}>
        {width > 1280 && (
          <Table margin="0">
            <div className="tableHeadActiveBenefits">
              {employeeActiveBenefitsTableHead.map(
                ({ id, headName, width, justifyContent, margin }) => (
                  <TableHead
                    key={id}
                    tableHeadColName={t(headName)}
                    colWidth={width}
                    justifyContent={justifyContent}
                    margin={margin}
                    fontSize="12px"
                  />
                )
              )}
            </div>
          </Table>
        )}
        {singleEmployee.userBenefits.map(
          ({
            benefitId,
            title,
            benefitGroup,
            benefitPrice,
            expiration,
            quantity
          }) => (
            <div key={benefitId} className="employeeActiveBenefitStyle">
              <div className="item name">
                <h4>{t("benefit_name")}</h4>
                <div className="content">
                  <span></span>
                  <p>{title}</p>
                </div>
              </div>
              <div className="item provider">
                <h4>{t("Provider")}</h4>
                <div className="content">{benefitGroup.provider.name}</div>
              </div>
              <div className="item benefitGroup">
                <h4>{t("Benefit Group")}</h4>
                <div className="content">{benefitGroup.name} </div>
              </div>
              <div className="item tokens">
                <h4>{t("Price")}</h4>
                <div className="content">
                  {transformTokenCurrencyIcons()}
                  <p>{transformTokensToRsd(benefitPrice * quantity)}</p>
                </div>
              </div>
              <div className="item expiration">
                <h4>{t("Expiration")}</h4>
                <div className="content">{expiration}</div>
              </div>
            </div>
          )
        )}
      </EmployeeActiveBenefitsStyles>
    )
  );
};

EmployeeActiveBenefits.propTypes = {
  singleEmployee: PropTypes.shape({}),
  backgroundOpen: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

export default EmployeeActiveBenefits;
