let priorityCounter = 0

export const tableHeaderReducer = (state, action) => {
	const tableHeaders = state.map(tableHeader => {
		if (tableHeader.id === action.type) {
			return {
				...tableHeader,
				isSortedUp: !tableHeader.isSortedUp,
				orderPriority: priorityCounter
			}
		}
		return {
			...tableHeader
		}
	})
	priorityCounter += 1
	return tableHeaders
}
