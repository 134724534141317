import { UserRolesKeysEnum } from "../../../constants/roles.constants";
import { isEmpty, removeSpacesAndHTML } from "../../../services/general.utils";
import { doesRoleExist } from "../../../services/roles.utils";
import EmployeePayload from "./Employee.payload";

class UserPayload {
  constructor(userPayload = {}) {
    this.id = userPayload.id || null;
    this.first_name = userPayload.firstName ? removeSpacesAndHTML(userPayload.firstName) : "";
    this.last_name = userPayload.lastName ? removeSpacesAndHTML(userPayload.lastName) : "";
    this.vocative_name = !isEmpty(userPayload.vocativeName) ? removeSpacesAndHTML(userPayload.vocativeName) : "";
    this.formal_email = !isEmpty(userPayload.formalEmail) ? userPayload.formalEmail : null;
    this.email = userPayload.email || "";
    this.company_id = userPayload.companyId || "";
    this.role_ids = userPayload.roles.map(role => role.id) || [];
    !isEmpty(userPayload.password)
      ? this.password = userPayload.password || ""
      : undefined;

    if (doesRoleExist(userPayload.roles, UserRolesKeysEnum.MEMBER)) {
      const employee = {
        id: userPayload.employee_id || null,
        born_date: userPayload.bornDate || "",
        firstDayAtWork: userPayload.firstDayAtWork ||
          userPayload.enroll_date ||
          userPayload.firstDayAtWork ||
          null,
        companyId: userPayload.companyId || "",
        relationship: userPayload.relationship?.toLowerCase() || "",
        gender: userPayload.gender?.toLowerCase() || "",
        uniqueId: userPayload.uniqueId || "",
        branchCode: userPayload.branchCode || "",
        description: userPayload.description || "",
        team: userPayload.team ? removeSpacesAndHTML(userPayload.team) : "",
        contact: userPayload.contact || "",
        cities: userPayload.cities,
        tier: userPayload.tier || null
      };
      this.employee =
        employee
          ? new EmployeePayload(employee)
          : null;
    }
  }
}

export default UserPayload;
