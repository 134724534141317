class BenefitGroupReview {
    constructor(reviewData = {}) {
      this.id = reviewData.id || null;
      this.comment = reviewData.comment || '';
      this.rating = reviewData.rating || 0;
      this.benefitGroupId = reviewData.benefitGroupId || null;
      this.benefitId = reviewData.benefitId || null;
      this.userId = reviewData.userId || null;
      this.reviewStatus = reviewData.reviewStatus;
     }
  }

export default BenefitGroupReview;