import { Tag } from "../../domain";

class TagsResponse {
  constructor(response = {}) {
    this.tags = response.data.tags.map(tag => new Tag(tag));
    this.pages = response.data.pages;
    this.count = response.data.count;
  }
}

export default TagsResponse;
