import adminDashboardActionTypes from "../../constants/actionTypes/admin/dashboard.constants";
import { updateObject } from "../../services/updateObject";
import {
  currentDate,
  startOfTheCurrentMonth
} from "../../utils/helpers/timeHelpers";

const initialState = {
  filters: {
    startDate: startOfTheCurrentMonth(),
    endDate: currentDate(),
    companyId: null
  },
  statistics: null,
  listOfUserBenefits: []
};

const adminDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case adminDashboardActionTypes.SET_ADMIN_DASHBOARD_FILTERS:
      return setAdminDashboardFilters(state, action);
    case adminDashboardActionTypes.SET_ADMIN_DASHBOARD_STATISTICS:
      return setAdminDashboardStatistics(state, action);
    case adminDashboardActionTypes.SET_ADMIN_DASHBOARD_USER_BENEFITS:
      return setAdminDashboardUserBenefits(state, action);
  }

  return state;
};

const setAdminDashboardUserBenefits = (state, action) => {
  return updateObject(state, { listOfUserBenefits: action.payload });
};

const setAdminDashboardFilters = (state, action) => {
  return updateObject(state, { filters: action.payload });
};
const setAdminDashboardStatistics = (state, action) => {
  return updateObject(state, { statistics: action.payload });
};

export default adminDashboardReducer;
