import React from 'react';
import styled from 'styled-components';

const MicrosoftIconSvgStyles = styled.div`
  svg {
		margin: 5px 12px 0px 12px;
  }	
	@media screen and (max-width:356px) {
		svg {
			width: 20px;
			height: 20px;
		}
	}
`;

const MicrosoftIconSvg = () => (
  <MicrosoftIconSvgStyles>
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 220 220">
      <path d="M104.868 104.868H0V0h104.868v104.868z" fill="#f1511b"/>
      <path d="M220.654 104.868H115.788V0h104.866v104.868z" fill="#80cc28"/>
      <path d="M104.865 220.695H0V115.828h104.865v104.867z" fill="#00adef"/>
      <path d="M220.654 220.695H115.788V115.828h104.866v104.867z" fill="#fbbc09"/>
    </svg>
  </MicrosoftIconSvgStyles>
);

export default MicrosoftIconSvg;