const statusOptions = [
	{
		id: 1,
		name: 'Live'
	},
	{
		id: 2,
		name: 'Blocked'
	},
	{
		id: 3,
		name: 'Disabled'
	},
	{
		id: 4,
		name: 'Test phase'
	}
]

const paymentDeadlineOptions = [
	{
		id: 1,
		name: '7 days'
	},
	{
		id: 2,
		name: '14 days'
	},
	{
		id: 3,
		name: '21 days'
	},
	{
		id: 4,
		name: '30 days'
	}
]

export { statusOptions, paymentDeadlineOptions }
