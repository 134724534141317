import styled from "styled-components";

const PreviewHRBenefitBodyStyles = styled.div`
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .canvas-padding {
    padding: 80px;
  }

  .html2canvas-container {
    width: 3000px !important;
    height: 3000px !important;
  }
`;

export default PreviewHRBenefitBodyStyles;
