import styled, { keyframes } from "styled-components";

const fadeInDown = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const BenefitSubscribeStyles = styled.div`
  width: 650px;
  padding: 0;
  max-height: ${({ maxHeight }) => maxHeight};
  overflow-y: auto;
  overflow-x: hidden;

  .titleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  #social-proof {
    animation: ${fadeInDown} 0.7s ease-in-out;
  }

  .socialProofForSingleBenefit {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: flex-start;
    position: absolute;
    right: 0;
    background: #ffffff;
    bottom: -45px;
    z-index: 1;
    width: 300px;
    border-radius: 4px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
    height: 45px;
    color: #1568bf;
    padding: 5px;
    font-weight: 500;

    .socialProofForSingleBenefitCountWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 0 1 33%;

      .socialProofForSingleBenefitNumberOfSubscribes {
        font-size: 18px;
        font-weight: 700;
      }

      .socialProofForSingleBenefitUsers {
        font-size: 10px;
      }
    }

    .socialProofForSingleBenefitDescription {
      flex: 0 1 66%;
      font-size: 10px;
      color: #757575;
    }

    .socialProofForSingleBenefitName {
      font-weight: 600;
      color: #1568bf;
    }
  }

  .addOrRemoveFromFavoritesButton {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 4px;
    border: solid 1px transparent;
    color: #1568bf;
    outline: #1568bf;
    background: #ffffff;
    outline-style: inset;
    outline-width: 0.5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    margin: 0;
    margin-right: 10px;
    margin-top: 40px;
    margin-bottom: 8px;
    transition: 0.5s;

    &:hover {
      background: #1568bf;
      color: #fff;
      outline: unset;
    }
  }

  .categoryTag {
    display: inline-block;
    margin-bottom: 5px;
    .categoryText {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 13px;
      color: #2f68b9;
    }
  }

  .vouchersContainer {
    display: none;
  }

  .inputContainer {
    position: relative;
    .priceDisclaimer {
      color: #373737;
      position: absolute;
      top: -23px;
      width: 250px;
      font-size: 11px;
    }
  }

  .imageContainer {
    width: 100%;
    height: 305px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .imageContainer img {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
  }

  .topContainer {
    padding: 12px 32px 0 32px;
    position: relative;
    margin-bottom: 20px;

    .providerImageOuterCircle {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      position: absolute;
      background: #fff;
      left: 32px;
      top: -40px;
      display: flex;
      align-items: center;
      justify-content: center;

      .providerImageInnerCircle {
        width: 74px;
        height: 74px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        .providerLogo {
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }
    }

    .title {
      font-size: 24px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 19px;
      letter-spacing: -0.2px;
      color: #000000;
      margin-top: 40px;
      margin-bottom: 8px;
    }

    .ratingContainer {
      width: 190px;
      height: 40px;
      font-size: 14px;
      letter-spacing: -0.2px;

      .starContainer {
        line-height: 18px;
        font-weight: 500;
        color: #87919e;
      }

      .link {
        line-height: 18px;
        font-weight: 400;
        color: #2f68b9;
        cursor: pointer;
      }
    }

    @keyframes showModal {
      from {
        transform: translateY(50px);
        opacity: 0;
      }
      to {
        transform: translateY(0);
        opacity: 1;
      }
    }

    .ratingCardContainer {
      position: relative;
      animation: showModal 0.4s ease-in-out;
      border-radius: 4px;
      //box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      background-color: #ffffff;
      transition: all 0.35s ease-in-out;
    }

    .anim {
      transform: translateY(0);
      opacity: 1;
    }

    .summary {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.36;
      letter-spacing: normal;
      color: #000000;
      word-wrap: break-word;
      margin: 10px 0;
    }

    .locations {
      font-weight: 500;
      color: #87919e;
      margin-top: 15px;
      word-wrap: break-word;
      font-size: 14px;
      font-stretch: normal;
      line-height: 18px;
    }

    .description {
      margin: 10px 0 10px 0;
      line-height: 1.36;
      word-wrap: break-word;
      color: #000000;
      font-size: 14px;
      ul {
        margin-left: 18px;
      }
      ol {
        margin-left: 18px;
      }
    }
  }

  .benefitItemsContainer {
    padding: 20px;
    overflow-y: visible;
  }

  .labels {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;

    .label {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: -0.2px;
      color: #b4b4b4;
    }

    .packages {
      width: 39%;
    }

    .duration {
      width: 11%;
    }

    .tokensLabel {
      width: 23%;
    }
  }

  .subscribedBenefitIndicator {
    border-color: #1568bf !important;
  }

  .benefitStatusActiveIndicator {
    background-color: rgba(65, 194, 100, 0.08) !important;
    border: 1px solid #41c264 !important;
    box-sizing: border-box;
    border-radius: 4px;
  }

  .benefitStatusPendingActivationIndicator {
    background-color: rgba(132, 152, 142, 0.08) !important;
    border: 1px solid #84988e !important;
    box-sizing: border-box;
    border-radius: 4px;
  }

  .benefitStatusPendingDeactivationIndicator {
    background-color: rgba(197, 60, 60, 0.08) !important;
    border: 1px solid #c53c3c !important;
    box-sizing: border-box;
    border-radius: 4px;
  }

  .benefiItemCard {
    width: 100%;
    padding: 15px 15px 20px 15px;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.11);
    border: solid 1px #e6eaee;
    background-color: #ffffff;
    margin-bottom: 12px;
    position: relative;

    .fileName {
      position: absolute;
      right: 0;
      bottom: -16px;
      color: #505050;
      font-size: 11px;
    }

    .labelValues {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .benefitName {
        width: 39%;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #354052;
      }
    }
  }

  .benefitSummary {
    margin-top: 15px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    color: #000000;
    word-wrap: break-word;
  }

  .benefitDescription {
    margin-top: 15px;
    line-height: 1.36;
    word-wrap: break-word;
    color: #000000;
    font-size: 14px;
    ul {
      margin-left: 18px;
    }
    ol {
      margin-left: 18px;
    }
  }

  .benefitExpirationAndTokens {
    display: flex;
    margin-top: 12px;
    .duration {
      min-width: 11%;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: -0.2px;
      color: #373737;
      opacity: 0.8;
      margin-right: 2px;
    }
    .tokenContainer {
      display: flex;
      align-items: center;

      .tokens {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: -0.2px;
        color: #373737;
        opacity: 0.8;
        margin-left: 4px;
      }
    }
  }

  .benefitStatusWrap {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;

    .benefitStatusActive {
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: -0.2px;
      color: #41c264;
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .benefitStatusPendingDeactivation {
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: -0.2px;
      color: #c53c3c;
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .benefitStatusPendingActivation {
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: -0.2px;
      color: #84988e;
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
    height: 100vh;
    max-height: 100vh;

    .vouchersContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 15px 15px 70px 15px;
      overflow-y: auto;

      .vouchersTitle {
        font-size: 24px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        margin: 0 0 27px 0;
      }

      .chosenVoucher {
        border: 1px solid #1568bf !important;
      }

      .voucherItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        border: 1px solid #d8d8d8;
        border-radius: 4px;
        margin-bottom: 10px;
        cursor: pointer;

        .voucherInputContainer {
          display: flex;
          align-items: center;

          .filterCheck {
            width: 12px;
            height: 12px;
            border-radius: 4px;
            cursor: pointer;
          }

          .checkLabel {
            width: 50px;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 15px;
            color: #4b4b4b;
            margin-left: 10px;
            margin-right: 10px;
            white-space: nowrap;
            text-transform: uppercase;
          }

          .changeColor {
            color: #1568bf;
          }

          .priceLabel {
            width: 15px;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 15px;
            color: #4b4b4b;
            margin-left: 10px;
            margin-right: 10px;
            white-space: nowrap;
            text-transform: uppercase;
          }
        }
      }
    }

    .benefitItemsContainer {
      padding: 0 10px;
      margin-bottom: 100px;
    }

    .labels {
      padding: 0 10px;
    }

    .benefiItemCard {
      padding: 10px;

      .labelValues {
        .benefitName {
          font-size: 14px;
        }
      }

      .benefitDescription {
        font-size: 12px;
      }
    }

    .redeemButton {
      margin-right: 10px;
      margin-left: 0;
    }
  }

  .remainingQuantity {
    font-weight: 500;
    font-size: 13px;
    letter-spacing: -0.2px;
    color: #616161;
  }
  .vatDisclaimer {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.2px;
    color: #373737;
    opacity: 0.8;
  }
  @media only screen and (max-width: 500px) {
    .redeemButton {
      margin-top: 10px;
      margin-bottom: 10px;
      float: right;
    }

    .benefiItemCard .labelValues .benefitName {
      width: 50%;
    }
  }
`;

export default BenefitSubscribeStyles;
