import styled from "styled-components";

export const AddEmployeeWithCSVErrorStyles = styled.div`
  width: 546px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 30px 30px 30px;

  .circleLogoContainer {
    width: 126px;
    height: 126px;
    margin-bottom: 30px;
  }

  .title {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.81;
    letter-spacing: normal;
    color: #4b4b4b;
    margin-bottom: 30px;
  }

  .error-message {
    margin: 0 0 20px 0;
  }

  .text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: center;
    color: #4b4b4b;
    margin-bottom: 10px;
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
    height: 100vh;
  }
`;
