import { removeSpacesAndHTML } from '../../../services//general.utils';

class CategoryPayload {
    constructor(payload = {}) {        
        this.id = payload.id || null;
        this.name = payload.name ? removeSpacesAndHTML(payload.name) : "";
        this.description = payload.description ? removeSpacesAndHTML(payload.description) : "";
    }
  }  
  export default CategoryPayload;
