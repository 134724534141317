import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Card,
  InputLabel,
  Modal,
  openModalAnim,
  SelectInput
} from "../../../../components";
import DatePicker from "react-datepicker";
import { reportTypeDropdownOptions } from "../reportTypeDropdownOptions";
import PrivacyPolicy from "../../../../components/privacyPolicyModal";
import { formatDateForSearch } from "../../../HrPages/HrDashboard/dashboad.services";
import * as dashboardService from "../../../../services/api/admin/dashboard.service";
import { downloadBlob } from "../../../../services/files.utils";
import * as companiesService from "../../../../services/api/admin/companies.service";
import { toast } from "react-toastify";
import { setCompanies } from "../../../../actions/admin/companies.actions";
import { useDispatch, useSelector } from "react-redux";

export const UserReport = () => {
  const dispatch = useDispatch();

  const [showReport, setShowReport] = useState(false);
  const [reportType, setReportType] = useState(undefined);
  const [company, setCompany] = useState(undefined);
  const [date, setDate] = useState(new Date());
  const [searchDateString, setSearchDateString] = useState(
    formatDateForSearch(new Date())
  );
  const isMounted = useRef(false);
  const [companiesPage, setCompaniesPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const { t } = useTranslation();
  const user = useSelector(state => state.app.user);
  const companies = useSelector(state => state.companies.companies);

  const handleDateChange = chosenDate => {
    setDate(chosenDate);
    setSearchDateString(formatDateForSearch(chosenDate));
  };

  const getStatistics = async () => {
    const response = await dashboardService.getStatistics(
      company,
      searchDateString,
      reportType
    );

    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : t("failed_to_get_statistics")
      );
    }

    downloadBlob(response.data, "export.csv", "text/csv;charset=utf-8;");
  };

  /**
   * Refetches paginated companies.
   * Fired from on scroll event from select input.
   * Sets new companies to state, along with page number.
   */
  const refetchCompanies = async () => {
    let currentPage = companiesPage;
    let newPage = (currentPage += 1);

    if (newPage > totalPages) return;

    setCompaniesPage(newPage);

    const response = await companiesService.getCompanies({ page: newPage });
    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : t("failed_to_get_companies")
      );
    }

    let newCompanies = [];
    setTimeout(() => {
      newCompanies = [...companies, ...response.companies];
      setTimeout(() => {
        console.log(newCompanies);

        dispatch(setCompanies(newCompanies));
      }, 100);
    }, 100);
  };
  const getCompanies = async () => {
    const response = await companiesService.getCompanies({
      page: companiesPage
    });

    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : t("failed_to_get_companies")
      );
    }

    const formattedResponse = [
      { id: "All Companies", name: "All Companies" },
      ...response.companies
    ];

    dispatch(setCompanies(formattedResponse));
    setTotalPages(response.pages);
  };

  useEffect(() => {
    isMounted.current = true;
    getCompanies();
  }, []);

  useEffect(() => {
    if (
      isMounted.current &&
      user.authenticatedRole &&
      !user.hasAcceptedPrivacyPolicy
    ) {
      setTimeout(() => {
        openModalAnim();
      }, 500);
    }
  }, []);

  return (
    <div>
      <button
        className="openUsageReport"
        onClick={() => setShowReport(state => !state)}
      >
        {t("show_usage_report")}
      </button>
      {showReport && (
        <div
          style={{
            width: "260px",
            position: "absolute",
            right: 0,
            top: "45px",
            zIndex: 20
          }}
        >
          <Card>
            <div className="optionsContainer">
              <h1 className="title">{t("gen_usr_rep")}</h1>
              <div>
                <InputLabel label={t("Choose month")} margin="0 0 5px 0" />
                <DatePicker
                  className="datePickerInput"
                  id="datePickerTierBudget"
                  selected={date}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  onChange={chosenDate => handleDateChange(chosenDate)}
                  value={date}
                  maxDate={new Date()}
                />
              </div>
              <div style={{ marginTop: 10 }}>
                <InputLabel
                  label={t("select_report_type")}
                  margin="0 0 5px 0"
                />
                <SelectInput
                  name="reportTypes"
                  options={reportTypeDropdownOptions(t)}
                  selectedValues={reportTypeDropdownOptions(t)}
                  value={reportType}
                  handleChange={option => setReportType(option.id)}
                  customId="reportTypeId"
                  shouldHaveHight={false}
                  shouldHaveFullWidth
                  customHeight="27px"
                />
              </div>
              <div style={{ marginTop: 10 }}>
                <InputLabel label={t("Select company")} margin="0 0 5px 0" />
                <SelectInput
                  name="companies"
                  options={companies}
                  selectedValues={companies}
                  value={company}
                  handleChange={option => {
                    setCompany(option.id);
                  }}
                  fetchMoreData={refetchCompanies}
                  customId="companyId"
                  shouldHaveHight={false}
                  shouldHaveFullWidth
                  customHeight="27px"
                />
              </div>
              <Button
                disabled={!company || !searchDateString || !reportType}
                onClick={() => getStatistics()}
                margin="20px 0 0 0"
                width="100%"
              >
                {t("download")}
              </Button>
            </div>
          </Card>

          {isMounted.current &&
            user.authenticatedRole &&
            !user.hasAcceptedPrivacyPolicy && (
              <Modal isCloseVisable={false}>
                <PrivacyPolicy />
              </Modal>
            )}
        </div>
      )}
    </div>
  );
};
