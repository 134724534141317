import styled from 'styled-components'

const ImageUploadStyles = styled.div`
	width: 100%;
	height: 100%;
	border-radius: 4px;
	position: relative;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #373737;
	font-size: 14px;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	background: #d8d8d8;

	.fileInputOverlay {
		position: absolute;
		width: 100%;
		height: 100%;
		background: black;
		opacity: 0.7;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		color: #fff;
		transform: translateY(100%);
		transition: all 0.3s ease-in-out;
		cursor: pointer;
	}

	.fileInput {
		opacity: 0;
		position: absolute;
		visibility: hidden;
		cursor: pointer;
	}

	.showOverlay {
		transform: translateY(0);
	}

	.image {
		/* width: 100%;
		height: 100%; */
		object-fit: cover;
	}
`

export default ImageUploadStyles
