import styled from "styled-components";

export const SwitchPlatformsDropdownStyles = styled.div`
  display: flex;
  align-items: center;

  .lngDropdown {
    position: static !important;
    margin-left: 15px;

    .flagDropdownWrapper {
      width: unset;
      .shownFlag {
        box-shadow: none;
        background: #f5f5f5;
      }
    }
  }

  .roleTooltip {
    display: flex;
    align-items: center;
  }

  .roleSwitchContainer {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #757575;
    white-space: nowrap;
    margin-right: 8px;
  }

  .tooltipContainer {
    position: relative;
    .arrowButtons {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background: transparent;
      cursor: pointer;
      width: 10px;
    }

    .tooltip {
      position: absolute;
      background: #757575;
      border-radius: 4px;
      padding: 10px;
      right: -15px;
      top: 21px;

      .pointerBorder {
        width: 0;
        height: 0;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-bottom: 9px solid #4b4b4b;
        position: absolute;
        border-radius: 2px;
        right: 11px;
        top: -9px;
      }

      .pointer {
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid white;
        position: absolute;
        border-radius: 2px;
        right: 12px;
        top: -8px;
      }

      .tooltipOption {
        width: 100%;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 15px;
        white-space: nowrap;
        color: #757575;
        cursor: pointer;
        padding: 6px;

        &:hover {
          background: #1568bf;
          color: white;
          opacity: 0.9;
        }
      }

      .tooltipOption:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    margin-left: 0;
    margin-right: 85px;

    .tooltipContainer {
      z-index: 2;
    }
  }

  @media only screen and (max-width: 360px) {
    margin-left: -50px;

    .roleSwitchContainer {
      font-size: 12px;
    }
  }
`;
