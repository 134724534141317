const setOnboarding = (onboarding) => {
	if (onboarding === true) {
		return true
	}
	if (onboarding === false) {
		return false
	}
	if (onboarding === undefined) {
		return null
	}
}

class User {
	constructor(userData = {}) {
		this.id = userData.id || null
		this.role = userData.role || ''
		this.firstName = userData.firstName || ''
		this.lastName = userData.lastName || ''
		this.companyName = userData.companyName || ''
		this.maximumTokens = userData.maximumTokens || 0
		this.usedTokens = userData.usedTokens || 0
		this.isOnboardingDone = setOnboarding(userData.onboarding)
		this.serverOnboarding = setOnboarding(userData.onboarding)
		this.isSync = userData.sync || false
	}
}

export { User }
