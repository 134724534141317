import validator from "validator";
import { findLocales } from "../../../utils/helpers/findLocales";

export const validateForgotPassword = ({ email }) => {
  const isInLanguage = findLocales("rs");

  const errors = {};
  if (!email) {
    errors.email = isInLanguage ? "*Obavezno polje" : "* Required";
  } else if (!validator.isEmail(email)) {
    errors.email = isInLanguage ? "Neispravna email adresa" : "Invalid email";
  }

  return errors;
};
