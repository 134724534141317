import React from "react";

const BenefitGroupChangedLocation = ({ notification }) => {
  return (
    <>
      <h4 className="notification-title">
        There have been changes regarding the location of some benefits you were subscribed to
      </h4>
      <p className="notification-subtitle">
        You have been automatically unsubscribed from these benefits.
      </p>
      <p className="subtitle-description">
        Please, make sure to visit My Benefits or Browse Benefits page and choose which benefits would you prefer to subscribe to for the next month.
      </p>
    </>
  );
};

export default BenefitGroupChangedLocation;
