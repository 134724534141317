import styled from 'styled-components'

const UsedTokensStyles = styled.div`
margin: 0 20px 20px 0;
order: 5;

 .apexcharts-donut-slice-1:hover {
	 /* fill: '#4880ff'; */
	 opacity: 1
 }

 .apexcharts-donut-slice-2:hover {
	opacity: 1;
 }

 .graphContainer {
	 display: flex;
	 flex-direction: column;
	 align-items: center;
	 justify-content: center;
	 width: 170px;
 }

 @media screen and (max-width: 1626px) {
		order: 6;
	}
 
`

export default UsedTokensStyles
