import styled from 'styled-components'

const SettingsStyles = styled.section`
	.tagsSectionContainer {
		width: calc(100% - 210px);
		margin-left: 210px;
		background: #f1f1f4;
		min-height: 100vh;
	}

	.tagsContent {
		padding: 0 30px 30px 30px;
		display: flex;
	}

	#settingsCard {
		opacity: 0;
		transition: all 0.4s ease-in-out;
	}
`

export default SettingsStyles
