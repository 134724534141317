const collapseSection = element => {
  const sectionHeight = element.scrollHeight;
  const elementTransition = element.style.transition;
  element.style.transition = "";
  requestAnimationFrame(() => {
    element.style.height = `${sectionHeight}px`;
    element.style.transition = elementTransition;
    requestAnimationFrame(() => {
      element.style.height = `${0}px`;
    });
  });
};

const expandSection = element => {
  // const allElemements = document.getElementsByClassName('testDiv')
  // const rows = document.getElementsByClassName('row')
  // for (let i = 0; i < allElemements.length; i++) {
  // 	allElemements[i].style.height = '0px'
  // 	allElemements[i].style.borderTop = '0px solid #d8d8d8'
  // }

  // for (let i = 0; i < rows.length; i++) {
  // 	if (rows[i].classList.contains('cardView')) {
  // 		rows[i].classList.remove('cardView')
  // 	}
  // }

  const sectionHeight = element.scrollHeight;
  element.style.height = `${sectionHeight}px`;
};

const addHeightOnClick = element => {
  expandSection(element);
};

const removeHeightOnClick = element => {
  collapseSection(document.getElementById(`test${element}`));
};

export { addHeightOnClick, removeHeightOnClick };
