import { updateObject } from "../../services/updateObject";
import { HrEmployee } from "../../models/domain";
import * as actionTypes from "../../constants/actionTypes/hr/employees.constants";

const initialState = {
  employees: [],
  page: 1,
  count: 0,
  search: "",
  isSidebarMenuOpen: false,
  failedToUpdateUserList: [],
  dropdownEmployees: {
    listOfEmployees: [],
    filters: { page: 1, limit: 5 },
    meta: { totalPages: 1 }
  }
};

const hrEmployeesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_HR_EMPLOYEES:
      return setHrEmployees(state, action);
    case actionTypes.SET_PAGE:
      return setHrEmployeePage(state, action);
    case actionTypes.SET_SEARCH_CHANGE:
      return setHrEmployeeSearch(state, action);
    case actionTypes.TOGGLE_SIDEBAR_MENU:
      return toggleSidebarMenu(state, action);
    case actionTypes.TOGGLE_SIDEBAR_MENU:
      return toggleSidebarMenu(state, action);
    case actionTypes.SET_FAILED_TO_BULK_UPDATE_USERS:
      return setListOfFailedToBulkUpdateUsers(state, action);
    case actionTypes.SET_LIST_OF_DROPDOWN_EMPLOYEES:
      return setListOfDropdownEmployees(state, action);
    case actionTypes.SET_DROPDOWN_EMPLOYEES_FILTER:
      return setDropdownEmployeesFilter(state, action);
    case actionTypes.SET_DROPDOWN_EMPLOYEES_META:
      return setDropdownEmployeesMeta(state, action);
  }

  return state;
};

const setDropdownEmployeesMeta = (state, action) => {
  return updateObject(state, {
    dropdownEmployees: updateObject(state.dropdownEmployees, {
      meta: updateObject(state.dropdownEmployees.meta, {
        totalPages: action.payload
      })
    })
  });
};

const setDropdownEmployeesFilter = (state, action) => {
  return updateObject(state, {
    dropdownEmployees: updateObject(state.dropdownEmployees, {
      filters: updateObject(state.dropdownEmployees.filters, action.payload)
    })
  });
};

const setListOfDropdownEmployees = (state, action) => {
  return updateObject(state, {
    dropdownEmployees: updateObject(state.dropdownEmployees, {
      listOfEmployees: action.payload
    })
  });
};

const setListOfFailedToBulkUpdateUsers = (state, action) => {
  return updateObject(state, {
    failedToUpdateUserList: action.payload
  });
};

const setHrEmployees = (state, { employees, count }) => {
  const employeesFiltered = employees.map(employee =>
    typeof employee === HrEmployee ? employee : new HrEmployee(employee)
  );
  return updateObject(state, {
    employees: employeesFiltered,
    count: count
  });
};

const setHrEmployeePage = (state, { page }) => {
  return updateObject(state, {
    page: page
  });
};

const setHrEmployeeSearch = (state, { search }) => {
  return updateObject(state, {
    search: search
  });
};

const toggleSidebarMenu = (state, { isSidebarOpen }) => {
  return updateObject(state, {
    isSidebarMenuOpen: !isSidebarOpen
  });
};

export default hrEmployeesReducer;
