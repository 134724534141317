import moment from "moment";

export const handleChartOptions = (data, t) => {
  const dataForSeries = Object.entries(data).map(([key, value]) => {
    return { x: key, y: value.length };
  });

  return [
    {
      name: t("Subscriptions"),
      data: dataForSeries.sort((a, b) =>
        moment(a.x, "YYYY-MM-DD").diff(moment(b.x, "YYYY-MM-DD"))
      )
    }
  ];
};
