import Category from '../../models/domain/Category.entity';
import * as actionTypes from '../../constants/actionTypes/admin/categories.constants';
import { updateObject } from '../../services/updateObject';

const initialState = {
  categories: []
} 

const categoriesReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_CATEGORIES:
			return setCategories(state, action);
		case actionTypes.ADD_CATEGORY:
			return addCategory(state, action);
		case actionTypes.EDIT_CATEGORY:
      return editCategory(state, action);
		case actionTypes.DELETE_CATEGORY:
			return deleteCategory(state, action);
	}

  return state;
}

const setCategories = (state, action) => {
  return updateObject(state, {
      categories: action.categories.map(category => typeof category === Category ? category : new Category(category))
  })
}

const addCategory = (state, action) => {
  return updateObject(state, {
    categories: [...state.categories, new Category(action.category)]
  })
}

const editCategory = (state, action) => {
  return updateObject(state, {
    categories: state.categories.map(category => category.id === action.category.id ? new Category(action.category) : category)
  })
}

const deleteCategory = (state, action) => {
  return updateObject(state, {
    categories: state.categories.filter(category => category.id !== action.id)
  })
}

export default categoriesReducer;