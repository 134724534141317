import { apiService } from "../api.service";
import { TagsResponse } from "../../../models/server/responses";
import { TagPayload } from "../../../models/server/payloads";
import { Tag } from "../../../models/domain";

export const getCompanyTags = async () => {
  let response = await apiService.get(`/company/tags`);

  if (response.hasError) return response;

  return new TagsResponse(response);
};

export const getCompanyTag = async id => {
  let response = await apiService.get(`/company/tag/${id}`);

  if (response.hasError) return response;

  return new Tag(response.data);
};

export const addCompanyTag = async values => {
  let response = await apiService.post("/company/tag/create", new TagPayload(values));

  if (response.hasError) return response;

  return new Tag(response.data);
};

export const editCompanyTag = async (values, chosenId) => {
  let response = await apiService.put(`/company/tag/${chosenId}`, new TagPayload(values));

  if (response.hasError) return response;

  return new Tag(response.data);
};

export const removeCompanyTag = async id => {
  let response = await apiService.delete(`/company/tag/${id}`);

  return response;
};
