import React from "react";
import PropTypes from "prop-types";

import ProviderToggleButtonComponentStyles from "./ProviderToggleButton.styles";
import { useTranslation } from "react-i18next";

const ToggleButton = ({ isActive, onToggle }) => {
  const { t } = useTranslation();
  return (
    <ProviderToggleButtonComponentStyles isActive={isActive}>
      <div className="activationCheckBox">
        {isActive && <p className="activityOnText">{t("on")}</p>}
        <div className="dot" onClick={onToggle} />
        {!isActive && <p className="activationOffText">{t("off")}</p>}
      </div>
      {isActive ? (
        <p className="benefitActivityStatusText">{t("provider_enabled")}</p>
      ) : (
        <p className="benefitActivityStatusText">{t("provider_disabled")}</p>
      )}
    </ProviderToggleButtonComponentStyles>
  );
};

ToggleButton.propTypes = {
  isActive: PropTypes.bool,
  onToggle: PropTypes.func
};

export default ToggleButton;
