import { isEmpty } from "../../services/general.utils";

class TierForm {
  constructor(tierData = {}) {
    this.id = tierData.id || null;
    this.name = tierData.name || "";
    this.description = tierData.description || "";
    this.tokens = !isEmpty(tierData.tokens) ? tierData.tokens : "";
    this.currency = !isEmpty(tierData.currency) ? tierData.currency : "";
  }
}

export default TierForm;
