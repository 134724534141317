export const queryBuilder = ({ baseQuery, queryObject }) => {
  const query = new URL(baseQuery, "http://www.example.com");

  if (queryObject) {
    Object.entries(queryObject).forEach(([key, value]) => {
      if (value === undefined || value === null) return;
      query.searchParams.append(key, value);
    });
  }

  const decodedPathnameWithQueryParams = decodeURIComponent(query.toString());

  return decodedPathnameWithQueryParams.replace("http://www.example.com", "");
};
