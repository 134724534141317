import styled from 'styled-components'

const RankStyles = styled.div`
	margin: 0 0 60px 0;
	display: flex;
	flex-direction: column;

	.hrCheckbox {
    display: flex;
    margin: 10px 0 0 0;
  }

  .tierAndInfoContainer {
    margin: 60px 0 20px 0;
    display: flex;
    gap: 30px;
  }

	.tierContainer {
		width: 217px;

		.title {
			margin-bottom: 15px;
			font-size: 16px;
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #4b4b4b;
		}
	}
	.labelAndValidation {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		height: 20px;
	}

	.infoContainer {
		.title {
			font-size: 12px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #4b4b4b;
		}

		.text {
			width: 230px;
			/* height: 57px; */
			/* padding-bottom: 5px; */
			display: flex;
			align-items: flex-end;
			font-size: 12px;
			font-weight: 300;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #5d5d5d;
		}
	}

	.tierContainerResult {
		width: 100%;
		min-height: 29px;
		border-radius: 4px;
		font-size: 14px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #5d5d5d;
		display: flex;
		align-items: center;
		padding: 5px;
		background: #f7f7f7;
		overflow: hidden;

	}

  @media screen and (max-width: 500px) {
    .tierAndInfoContainer {
      flex-wrap: wrap;
    }

    .tierContainer {
      width: 100%;
    }
  }
	
`

export default RankStyles
