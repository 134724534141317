import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import axios from 'axios'

import { Button } from '../../components'

const Styles = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .text {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: #4B4B4B;
        margin: 5px 0;
        text-align: center;
    }
`

const PresentationPage = () => {
    const [data, setData] = useState({
        combinations: 0,
        unique: 0
    })

    const fetchData = () => {
        axios.get('https://demo.benefit.bstorm.app/api/v1/dashboard/temp-combinations')
            .then(response => {
                setData({
                    combinations: response.data.combinations,
                    unique: response.data.unique
                })
            })
            .catch(error => {
                alert(error)
            })
    }


    const resetData = () => {
        axios.post('https://demo.benefit.bstorm.app/api/v1/dashboard/temp-delete')
            .then(response => {
                setData({
                    combinations: 0,
                    unique: 0
                })
            })
            .catch(error => {
                alert(error)
            })
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <Styles>
            <div>
                <div>
                    <p className="text">Submited combinations</p>
                    <p className="text" style={{fontWeight: 'bold'}}>{data.combinations}</p>
                </div>
                <div>   
                    <p className="text">Unique combinations</p>
                    <p className="text" style={{fontWeight: 'bold'}}>{data.unique}</p>
                </div>
                
                <Button
                    margin="20px auto 0 auto"
                    width="200px"
                    onClick={fetchData}
                >
                    GET COMBINATIONS
                </Button>

                <Button
                    margin="20px auto 0 auto"
                    width="200px"
                    onClick={() => resetData()}
                >
                    Reset
                </Button>
            </div>
        </Styles>
    )
}

export default PresentationPage
