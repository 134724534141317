import { findLocales } from "../../../utils/helpers/findLocales";
import { isEmpty, removeSpacesAndHTML } from "../../general.utils";

export const tierFormValidation = ({ name, description, tokens }) => {
  const errors = {};
  const isInLanguage = findLocales("rs");

  if (!name.trim()) {
    errors.name = isInLanguage ? "*Obavezno polje" : "* Required";
  } else if (removeSpacesAndHTML(name).length > 20) {
    errors.name = isInLanguage ? "Predugo" : "Too long";
  } else if (!removeSpacesAndHTML(name)) {
    errors.name = isInLanguage
      ? "HTML oznaka nije dozvoljena"
      : "HTML tag is not allowed";
  }

  if (description && removeSpacesAndHTML(description).length > 100) {
    errors.description = isInLanguage ? "Predugo" : "Too long";
  }

  if (isEmpty(tokens)) {
    errors.tokens = isInLanguage ? "*Obavezno polje" : "* Required";
  }

  return errors;
};
