import React from "react";
import PropTypes from "prop-types";

import BudgetRolloverToggleStyles from "./BudgetRolloverToggle.styles";
import { useTranslation } from "react-i18next";

const CurrencyToggle = ({ enableCurrency, onToggle }) => {
  const { t } = useTranslation();

  return (
    <BudgetRolloverToggleStyles enableRollover={enableCurrency}>
      <div className="activationCheckBox">
        {enableCurrency && <p className="activityOnText">{t("on")}</p>}
        <div className="dot" onClick={onToggle} />
        {!enableCurrency && <p className="activationOffText">{t("off")}</p>}
      </div>
      {enableCurrency ? (
        <p className="benefitActivityStatusText">{t("currency is enabled")}</p>
      ) : (
        <p className="benefitActivityStatusText">{t("currency is disabled")}</p>
      )}
    </BudgetRolloverToggleStyles>
  );
};

CurrencyToggle.propTypes = {
  enableCurrency: PropTypes.bool,
  onToggle: PropTypes.func
};

export default CurrencyToggle;
