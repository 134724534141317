import * as actionTypes from "../../constants/actionTypes/hr/benefits.constants";
import { updateObject } from "../../services/updateObject";
import { BenefitGroup } from "../../models/domain";

const initialState = {
  benefitsPage: {
    benefitGroups: [],
    page: 1,
    count: 1
  },
  filters: {
    categories: [],
    chosenCategories: [],
    chosenCategoryIds: [],
    tags: [],
    chosenBenefitTags: [],
    chosenBenefitTagIds: [],
    enabledBenefitGroup: "",
    keywordSearch: ""
  },
  editPageState: {
    isBenefitGroupActive: false,
    tiers: [],
    benefitGroup: new BenefitGroup(),
    users: [],
    page: 1,
    count: 1
  }
};

const benefitsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_HR_BENEFIT_GROUPS:
      return setBenefitGroups(state, action);
    case actionTypes.SET_HR_CATEGORIES_FILTERS:
      return setCategories(state, action);
    case actionTypes.SET_HR_TAGS_FILTERS:
      return setTags(state, action);
    case actionTypes.SET_HR_ENABLED_BENEFIT_GROUP_FILTERS:
      return setEnabledBenefitGroup(state, action);
    case actionTypes.SET_HR_BENEFITS_KEYWORD_SEARCH:
      return setKeywordSearch(state, action);
    case actionTypes.SET_HR_BENEFITS_CHOSEN_CATEGORIES_FILTERS:
      return setChosenCategories(state, action);
    case actionTypes.SET_HR_BENEFITS_CHOSEN_TAGS_FILTERS:
      return setChosenTags(state, action);
    case actionTypes.SET_HR_BENEFITS_FILTERS_TO_DEFAULT:
      return resetBenefitFiltersToDefault(state);
    case actionTypes.SET_HR_BENEFITS_PAGE:
      return setPage(state, action);
    case actionTypes.SET_HR_EDIT_BENEFITS_INITIAL_STATE:
      return setEditHrBenfitsInitialState(state, action);
    case actionTypes.TOGGLE_BENEFIT_GROUP:
      return toggleBenefitGroup(state, action);
    case actionTypes.TOGGLE_BENEFIT_GROUP_INSTALMENTS:
      return toggleBenefitGroupInstalments(state, action);
    case actionTypes.SET_HR_BENEFIT_GROUP_USERS:
      return setHrBenefitGroupUsers(state, action);
    case actionTypes.SET_HR_BENEFIT_GROUP_USERS_PAGE:
      return setHrBenefitGroupUsersPage(state, action);
  }
  return state;
};

const setBenefitGroups = (state, { benefitGroups, count }) => {
  return updateObject(state, {
    benefitsPage: updateObject(state.benefitsPage, {
      benefitGroups,
      count
    })
  });
};

const setCategories = (state, { categories }) => {
  return updateObject(state, {
    filters: updateObject(state.filters, {
      categories
    })
  });
};

const setTags = (state, { tags }) => {
  return updateObject(state, {
    filters: updateObject(state.filters, {
      tags
    })
  });
};

const setEnabledBenefitGroup = (state, { enabledBenefitGroup }) => {
  return updateObject(state, {
    filters: updateObject(state.filters, {
      enabledBenefitGroup
    })
  });
};

const setKeywordSearch = (state, { keywordSearch }) => {
  return updateObject(state, {
    filters: updateObject(state.filters, {
      keywordSearch
    })
  });
};

const setChosenCategories = (
  state,
  { chosenCategories, chosenCategoryIds }
) => {
  return updateObject(state, {
    filters: updateObject(state.filters, {
      chosenCategories,
      chosenCategoryIds
    })
  });
};

const setChosenTags = (state, { chosenBenefitTags, chosenBenefitTagIds }) => {
  return updateObject(state, {
    filters: updateObject(state.filters, {
      chosenBenefitTags,
      chosenBenefitTagIds
    })
  });
};

const resetBenefitFiltersToDefault = state => {
  return updateObject(state, {
    filters: updateObject(state.filters, {
      categories: [],
      chosenCategories: [],
      chosenCategoryIds: [],
      tags: [],
      chosenBenefitTags: [],
      chosenBenefitTagIds: [],
      enabledBenefitGroup: "",
      keywordSearch: ""
    })
  });
};

const setPage = (state, { page }) => {
  return updateObject(state, {
    benefitsPage: updateObject(state.benefitsPage, {
      page
    })
  });
};

const setEditHrBenfitsInitialState = (state, { benefitGroup, tiers }) => {
  return updateObject(state, {
    editPageState: updateObject(state.editPageState, {
      benefitGroup,
      tiers,
      isBenefitGroupActive:
        benefitGroup.availableTiers.length > 0 ? true : false
    })
  });
};

const toggleBenefitGroup = (state, { isBenefitGroupActive }) => {
  return updateObject(state, {
    editPageState: updateObject(state.editPageState, {
      isBenefitGroupActive
    })
  });
};

const toggleBenefitGroupInstalments = (state, { enableInstalments }) => {
  return updateObject(state, {
    editPageState: updateObject(state.editPageState, {
      benefitGroup: updateObject(state.editPageState.benefitGroup, {
        enableInstalments
      })
    })
  });
};

const setHrBenefitGroupUsers = (state, { users, count }) => {
  return updateObject(state, {
    editPageState: updateObject(state.editPageState, {
      users,
      count
    })
  });
};

const setHrBenefitGroupUsersPage = (state, { page }) => {
  return updateObject(state, {
    editPageState: updateObject(state.editPageState, {
      page
    })
  });
};

export default benefitsReducer;
