// Entities
import Voucher from "./Voucher.entity";

// Utils
import { isEmpty } from "../../services/general.utils";

class Benefit {
  constructor(benefitData = {}) {
    this.id = benefitData.id || null;
    this.title = benefitData.title || "";
    this.description = benefitData.description || "";
    this.summary = benefitData.summary || "";
    this.tokenPrice = benefitData.tokenPrice || benefitData.token_price || "";
    this.expiration = benefitData.expiration
      ? benefitData.expiration.split("_").join(" ")
      : "" || "";
    this.vouchers = benefitData.BenefitVoucher
      ? benefitData.BenefitVoucher.map(voucher => new Voucher(voucher))
      : [];
    this.tags = benefitData.Tags ? benefitData.Tags.map(tag => tag.id) : [];
    this.isSubscribed =
      benefitData.isSubscribed || benefitData.is_subscribed || false;
    this.shouldBeIndicatedAsActive =
      benefitData.shouldBeIndicatedAsActive ||
      benefitData.subscribed_benefit_id ||
      null;
    this.minimumContractPeriod =
      benefitData.minimumContractPeriod ||
      benefitData.minimum_contract_period ||
      "";
    this.vat = benefitData.vat || "";
    this.shortName = benefitData.shortName || benefitData.short_name || "";
    this.isActive = benefitData.isActive || benefitData.is_active || false;
    this.isDraft = !isEmpty(benefitData.isDraft) ? benefitData.isDraft : false;
    this.subscribedPrice =
      benefitData.subscribedPrice || benefitData.subscribed_price || null;
    this.quantity = benefitData.quantity || "";
    this.takenQuantity =
      benefitData.takenQuantity || benefitData.taken_quantity || null;
    this.instalments = benefitData.instalments || null;
    this.totalQuantityRemaining = !isEmpty(benefitData.totalQuantityRemaining)
      ? benefitData.totalQuantityRemaining
      : null;
    this.benefitGroupId = benefitData.benefitGroupId;
    this.name = benefitData.name;
    this.usageInstructions =
      benefitData.usageInstructions || benefitData.usage_instructions || "";
    // this.photo = benefitData.Photo ? new JpgPhoto(benefitData.Photo) : new JpgPhoto()
  }
}

export default Benefit;
