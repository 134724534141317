export const SET_INITAL_PROVIDERS = "SET_INITAL_PROVIDERS";
export const SET_PROVIDERS_PAGE = "SET_PROVIDERS_PAGE";
export const SET_PROVIDERS_SEARCH = "SET_PROVIDERS_SEARCH";
export const ADD_PROVIDER = "ADD_PROVIDER";
export const EDIT_PROVIDER = "EDIT_PROVIDER";
export const REMOVE_PROVIDER = "REMOVE_PROVIDER";
export const ADD_BENEFIT_GROUP = "PROVIDERS_ADD_BENEFIT_GROUP";
export const EDIT_BENEFIT_GROUP = "PROVIDERS_EDIT_BENEFIT_GROUP";
export const REMOVE_BENEFIT_GROUP = "PROVIDERS_REMOVE_BENEFIT_GROUP";
export const ADD_BENEFIT = "PROVIDERS_ADD_BENEFIT";
export const EDIT_BENEFIT = "PROVIDERS_EDIT_BENEFIT";
export const REMOVE_BENEFIT = "PROVIDERS_REMOVE_BENEFIT";
