const showOverlay = () => {
	const overlay = document.getElementById('overlay')
	if (overlay) {
		overlay.classList.add('showOverlay')
	}
}

const hideOverlay = () => {
	const overlay = document.getElementById('overlay')
	if (overlay && overlay.classList.contains('showOverlay')) {
		overlay.classList.remove('showOverlay')
	}
}


function checkOnCancel(setIsDialogOpen) {
	setIsDialogOpen(false)
	document.body.onfocus = null
	hideOverlay()
}


function charge(setIsDialogOpen) {
	document.body.onfocus = () => { setTimeout(checkOnCancel(setIsDialogOpen), 0) }
}

export { showOverlay, hideOverlay, checkOnCancel, charge }
