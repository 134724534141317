import React from "react";

const BenefitChangedPrice = ({ notification }) => {
  return (
    <>
      <h4 className="notification-title">
        There have been changes regarding the prices of some benefits you were
        subscribed to
      </h4>
      <p className="notification-subtitle">
        You have been automatically unsubscribed from these benefits.
      </p>
      <p className="subtitle-description">
        Please, make sure to visit My Benefits or Browse Benefits page and
        choose which benefits would you prefer to subscribe to for the next
        month, according to the price changes and your available tokens.
      </p>
    </>
  );
};

export default BenefitChangedPrice;
