import { apiService } from "../api.service";
import { TokenValue } from "../../../models/domain";
import { HrOnboardingPayload } from "../../../models/server/payloads";
import { HrOnboardingResponse } from "../../../models/server/responses";

export const getTokenValue = async () => {
  const response = await apiService.get("/hr/token");
  if (response.hasError) return response;

  return new TokenValue(response.data);
};

export const submitOnboarding = async values => {
  const payloadData = new HrOnboardingPayload(values);
  const response = await apiService.post(
    "/hr/add-employee-from-excel",
    payloadData.payload
  );
  if (response.hasError) return response;
  return new HrOnboardingResponse(response);
};

export const finishOnboarding = async () => {
  const response = await apiService.patch("/users/hr/onboarding");
  return response;
};
