import {
  LocalBenefitGroup,
  LocalBenefitGroupForm,
  LocalBenefit
} from "../../models/domain";
import * as actionTypes from "../../constants/actionTypes/hr/localBenefits.constants";
import { updateObject } from "../../services/updateObject";

const initialState = {
  localBenefitGroups: [],
  localBenefitGroupForm: new LocalBenefitGroupForm(),
  localBenefit: null,
  page: 1,
  count: 0
};

const localBenefitsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_LOCAL_BENEFIT_GROUPS:
      return setLocalBenefitGroups(state, action);
    case actionTypes.SET_LOCAL_BENEFIT_GROUPS_PAGE:
      return setLocalBenefitGroupPage(state, action);
    case actionTypes.EDIT_LOCAL_BENEFIT_GROUP:
      return editLocalBenefitGroup(state, action);
    case actionTypes.ADD_NEW_LOCAL_BENEFIT_TO_LOCAL_BENEFIT_GROUP:
      return addNewBenefitToBenefitGroup(state, action);
    case actionTypes.EDIT_LOCAL_BENEFIT:
      return editLocalBenefit(state, action);
    case actionTypes.DELETE_LOCAL_BENEFIT:
      return removeLocalBenefit(state, action);
  }

  return state;
};

const setLocalBenefitGroups = (state, { benefitGroups, count }) => {
  const filteredBenefitGroups = benefitGroups.map(group =>
    group instanceof LocalBenefitGroup ? group : new LocalBenefitGroup(group)
  );
  return updateObject(state, {
    localBenefitGroups: filteredBenefitGroups,
    count: count
  });
};

const setLocalBenefitGroupPage = (state, { page }) => {
  return updateObject(state, {
    page: page
  });
};

const editLocalBenefitGroup = (state, { benefitGroup }) => {
  const newBenefitGroup =
    typeof benefitGroup === LocalBenefitGroup
      ? benefitGroup
      : new LocalBenefitGroup(benefitGroup);
  return updateObject(state, {
    localBenefitGroups: state.localBenefitGroups.map(group =>
      group.id === benefitGroup.id ? newBenefitGroup : group
    )
  });
};

const addNewBenefitToBenefitGroup = (state, { benefit, benefitGroupId }) => {
  const newBenefit =
    typeof benefit === LocalBenefit ? benefit : new LocalBenefit(benefit);
  return updateObject(state, {
    localBenefitGroups: state.localBenefitGroups.map(benefitGroup =>
      benefitGroup.id === benefitGroupId
        ? updateObject(benefitGroup, {
            benefits: [...benefitGroup.benefits, newBenefit]
          })
        : benefitGroup
    )
  });
};

const editLocalBenefit = (state, { benefit, benefitGroupId }) => {
  const newBenefit =
    typeof benefit === LocalBenefit ? benefit : new LocalBenefit(benefit);
  return updateObject(state, {
    localBenefitGroups: state.localBenefitGroups.map(benefitGroup =>
      benefitGroup.id === benefitGroupId
        ? updateObject(benefitGroup, {
            benefits: benefitGroup.benefits.map(ben =>
              ben.id === benefit.id ? newBenefit : ben
            )
          })
        : benefitGroup
    )
  });
};

const removeLocalBenefit = (state, { benefitGroupId, benefitId }) => {
  return updateObject(state, {
    localBenefitGroups: state.localBenefitGroups.map(benefitGroup =>
      benefitGroup.id === benefitGroupId
        ? updateObject(benefitGroup, {
            benefits: benefitGroup.benefits.filter(
              benefit => benefit.id !== benefitId
            )
          })
        : benefitGroup
    )
  });
};

export default localBenefitsReducer;
