import React, { useContext } from "react";
import { connect } from "react-redux";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import styled from "styled-components";

import Form from "./Form";

import { Category } from "../../../../../../modules/superAdmin/settings/settings.entity";
import { categoryValidations } from "../../../../../../services/validations/superAdmin/category.validations";
import { axiosInstance } from "../../../../../../services/http/axiosConfig";
import { SettingsContext } from "../../../../../../modules/superAdmin/settings/context/settings.context";
import * as categoriesService from "../../../../../../services/api/admin/categories.service";

import * as actionCreators from "../../../../../../actions/admin/categories.actions";
import { useTranslation } from "react-i18next";

const EditCategoryStyles = styled.div`
  padding: 20px;
  width: 400px;

  .title {
    margin-bottom: 20px;
  }
`;

const EditCategory = ({ closeModal, chosenId, editCategory, categories }) => {
  const { t } = useTranslation();

  const handleEditCategoryError = ({ error }) => {
    const { data } = error;
    if (data.errors && data.error) {
      if (data.errors.name) {
        toast.error(`${data.error.message}: ${data.errors.name.keys[0]}`);
      }
      if (data.errors.description) {
        toast.error(
          `${data.error.message}: ${data.errors.description.keys[0]}`
        );
      }
    }
  };

  const updateCategory = async values => {
    const response = await categoriesService.editCategory(values, chosenId);
    if (response.hasError) {
      return handleEditCategoryError(response);
    }

    editCategory(response);
    toast.success(t("success_update_category"));
    closeModal();
  };

  const formik = useFormik({
    initialValues: new Category(
      categories.find(category => category.id === chosenId)
    ),
    validate: categoryValidations,
    onSubmit: updateCategory
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur
  } = formik;

  return (
    <EditCategoryStyles>
      <h2 className="title">{t("edit_category")}</h2>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Form
          values={values}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          handleChange={handleChange}
          buttonText="Update"
        />
      </form>
    </EditCategoryStyles>
  );
};

EditCategory.propTypes = {
  closeModal: PropTypes.func,
  chosenId: PropTypes.number
};

const mapStateToProps = state => {
  return {
    categories: state.categories.categories
  };
};

const mapDispatchToProps = dispatch => {
  return {
    editCategory: category => dispatch(actionCreators.editCategory(category))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCategory);
