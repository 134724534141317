export const monthsInSerbian = {
  January: "Januar",
  February: "Februar",
  March: "Mart",
  April: "April",
  May: "Maj",
  June: "Jun",
  July: "Jul",
  August: "Avgust",
  September: "Septembar",
  October: "Oktobar",
  November: "Novembar",
  December: "Decembar"
};
