import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// Components
import {
  TextAndLine,
  Input,
  InputLabel,
  MyDropZone,
  Button,
  MultiselectInput
} from "../../../../../components";
// Services
import * as categoriesService from "../../../../../services/api/admin/categories.service";
import * as categoriesActionCreators from "../../../../../actions/admin/categories.actions";
// Utils
import { convertStringToEditorState } from "../../../../../services/textEditor.utils";
import { useTranslation } from "react-i18next";

const BenefitGroupFormStyles = styled.div`
  .basicInfo {
    margin: 20px 0 30px 0;
  }

  .labelStyles {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: -0.23px;
    color: #373737;
  }

  .inputStyles {
    height: 46px;
    border-radius: 4px;
    border: solid 1px #d5dbdd;
    background-color: #ffffff;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: -0.23px;
    color: #373737;
  }

  .capacityInput {
    position: absolute;
    text-align: center;
    padding: 0 46px;
  }

  .decrementButton {
    position: absolute;
    left: 0;
    z-index: 2;
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border: 1px solid #1568bf;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
  }

  .incrementButton {
    position: absolute;
    right: 0;
    z-index: 2;
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border: 1px solid #1568bf;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
  }

  .labelAndValidation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 24px;
  }

  .photoContainer {
    margin: 20px 0;
  }

  .buttonContainer {
    display: flex;
    margin-bottom: 10px;
  }

  .summary {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: 139px;
    overflow-y: auto;
    min-height: 139px;
    max-height: 139px;
    border-radius: 4px;
    border: solid 1px #d5dbdd;
    background-color: #ffffff;
    padding: 12px 14px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: -0.23px;
    color: #373737;
    outline: none;
    resize: none;
  }

  .descriptionContainer {
    width: 100%;
    margin: 10px 0;

    .rdw-editor-wrapper {
      .rdw-editor-toolbar {
        .rdw-emoji-wrapper {
          .rdw-emoji-modal {
            left: -20px !important;
          }
        }
        .rdw-link-wrapper {
          .rdw-link-modal {
            font-size: 14px;
            .rdw-link-modal-label {
              font-size: 14px;
            }
          }
        }
      }
      .rdw-editor-main {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        height: 150px;
        overflow-y: auto;
        min-height: 150px;
        border-radius: 4px;
        border: solid 1px #d5dbdd;
        background-color: #ffffff;
        padding: 12px 14px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: -0.23px;
        color: #373737;
        outline: none;
        resize: none;
        .DraftEditor-root {
          z-index: 0;
        }
      }
    }
  }
`;

const BenefitGroupForm = ({
  closeModal,
  values,
  handleChange,
  setFieldValue,
  errors,
  touched,
  handleBlur,
  setFieldTouched,
  setFieldError,
  buttonText,
  disableButton,
  isHr = false,
  categories,
  setCategories
}) => {
  const { t } = useTranslation();
  const fetchCategories = async () => {
    const response = await categoriesService.getCategories();

    if (response.hasError) {
      return toast.error(response.error.data.error.message);
    }

    setCategories(response.categories);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <BenefitGroupFormStyles>
      <TextAndLine title={t("basic_info")} />
      <div className="basicInfo">
        <div className="labelAndValidation">
          <InputLabel
            margin="0 0 5px 0"
            label={t("Name")}
            customCssClass="labelStyles"
          />
          <div className="errorMsg" style={{ marginBottom: 5 }}>
            {errors.name && touched.name ? errors.name : ""}
          </div>
        </div>
        <Input
          margin="0 0 10px 0"
          name="name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          shouldHaveHight={false}
          customCssClass="inputStyles"
        />
        <div
          style={{
            margin: "0 0 10px 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <div style={{ width: 225 }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: 24
              }}
            >
              <InputLabel
                customCssClass="labelStyles"
                margin="0 0 5px 0"
                label={t("Category")}
              />
              <div className="errorMsg" style={{ marginBottom: 5 }}>
                {errors.categories && touched.categories
                  ? errors.categories
                  : ""}
              </div>
            </div>
            <MultiselectInput
              name="categories"
              options={categories}
              placeholder={t("select_categories")}
              handleChange={option => setFieldValue("categories", option)}
              selectedValues={values.categories}
              shouldHaveFullWidth
              shouldHaveFullHeigth
            />
          </div>
        </div>
        {!isHr && (
          <div style={{ margin: "0 0 10px 0" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: 24
              }}
            >
              <InputLabel
                customCssClass="labelStyles"
                margin=" 0 0 5px 0"
                label={t("Taxes")}
              />
            </div>
            <Input
              name="taxes"
              value={values.taxes}
              onChange={handleChange}
              onBlur={handleBlur}
              shouldHaveHight={false}
              customCssClass="inputStyles"
            />
          </div>
        )}
        <div className="labelAndValidation">
          <InputLabel
            margin="0 0 5px 0"
            label={t("Summary")}
            customCssClass="labelStyles"
          />
          <div className="errorMsg" style={{ marginBottom: 5 }}>
            {errors.summary && touched.summary ? errors.summary : ""}
          </div>
        </div>
        <textarea
          className="summary"
          name="summary"
          value={values.summary}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <div className="descriptionContainer">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: 24
            }}
          >
            <InputLabel
              customCssClass="labelStyles"
              margin=" 0 0 5px 0"
              label={t("Description")}
            />
            <div className="errorMsg" style={{ marginBottom: 5 }}>
              {errors.description && touched.description
                ? errors.description
                : ""}
            </div>
          </div>
          <Editor
            onBlur={() => {
              setFieldTouched("description", true);
            }}
            editorState={convertStringToEditorState(values.description)}
            editorStyle={{
              fontSize: 14
            }}
            stripPastedStyles={true}
            onEditorStateChange={editorState =>
              setFieldValue("description", editorState)
            }
            toolbar={{
              options: [
                "inline",
                "fontSize",
                "list",
                "textAlign",
                "colorPicker",
                "link",
                "emoji",
                "remove",
                "history"
              ],
              fontSize: {
                options: [10, 11, 12, 13, 14, 16, 18]
              }
            }}
          />
        </div>
      </div>

      <TextAndLine title={t("Photo")} />
      <div className="photoContainer">
        <div className="labelAndValidation">
          <InputLabel margin="0 0 5px 0" label="" shouldHaveColon={false} />
          <div className="errorMsg" style={{ marginBottom: 5 }}>
            {errors.photo && touched.photo ? errors.photo : ""}
          </div>
        </div>
        <MyDropZone
          name="photo"
          onBlur={setFieldTouched}
          photo={values.photo}
          handleChange={setFieldValue}
        />
        {/* <InputLabel margin="5px 0 0 0" label="Upload image as png, jpeg or jpg file." /> */}
      </div>

      <div className="buttonContainer">
        {disableButton ? (
          <Button width="130px" disabled margin="0 30px 0 0">
            {t("please_wait")}
          </Button>
        ) : (
          <Button width="130px" type="submit" margin="0 30px 0 0">
            {t(buttonText)}
          </Button>
        )}
        <Button width="130px" onClick={() => closeModal()} margin="0" outline>
          {t("cancel")}
        </Button>
      </div>
    </BenefitGroupFormStyles>
  );
};

BenefitGroupForm.propTypes = {
  closeModal: PropTypes.func,
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  handleBlur: PropTypes.func,
  setFieldTouched: PropTypes.func,
  setFieldError: PropTypes.func,
  values: PropTypes.shape(),
  errors: PropTypes.shape(),
  touched: PropTypes.shape(),
  buttonText: PropTypes.string,
  disableButton: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    categories: state.categories.categories
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCategories: categories =>
      dispatch(categoriesActionCreators.setCategories(categories))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BenefitGroupForm);
