import React from "react";

const TierBudgetDecreased = ({ notification }) => {
  return (
    <>
      <h4 className="notification-title">
        There have been changes regarding the budget of the tier you are
        belonging to!
      </h4>
      <p className="notification-subtitle">
        You have been automatically unsubscribed from all benefits.
      </p>
      <p className="subtitle-description">
        Please, make sure to visit My Benefits or Browse Benefits page and
        choose which benefits would you prefer to subscribe to for the next
        month, according to the new available budget.
      </p>
    </>
  );
};

export default TierBudgetDecreased;
