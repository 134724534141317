import React from "react";
import styled from "styled-components";

import { EmptyListIcon } from "../../assets/Icons";

const ListPlaceHolderStyles = styled.div`
  height: 100%;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .emptyText {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    letter-spacing: normal;
    color: #919ba8;
    word-break: break-all;
  }
`;

const isInSerbian = localStorage.getItem("locales");
const textPlaceholder =
  isInSerbian === "rs" ? "Lista je prazna" : "List is empty";

const ListPlaceHolder = ({ text = textPlaceholder }) => (
  <ListPlaceHolderStyles>
    <EmptyListIcon />
    <p className="emptyText">{text}</p>
  </ListPlaceHolderStyles>
);

export default ListPlaceHolder;
