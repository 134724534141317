import axios from 'axios'

const { REACT_APP_DEV_API_URL } = process.env

axios.defaults.withCredentials = true


const axiosInstance = axios.create({
	baseURL: `${REACT_APP_DEV_API_URL}/api/v1`
})

export { axiosInstance }
