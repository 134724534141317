import { toast } from "react-toastify";
import { axiosInstance } from "../../../services/http/axiosConfig";
import { Tier } from "./users.entity";

export const getUsers = async ({ limit, page, searchText, orderColumns }) => {
  try {
    const searchQuery = searchText ? `&search=${searchText}` : "";
    let orderQuery = "";
    if (orderColumns.length > 0) {
      orderQuery = orderColumns.reduce(
        (acc, column) =>
          `${acc}&order=${column.field} ${column.isSortedUp ? "ASC" : "DESC"}`,
        ""
      );
    }

    // & role=hr admin

    const { data } = await axiosInstance({
      method: "get",
      url: `/users?limit=${limit}&page=${page}${searchQuery}${orderQuery}`
    });
    if (data) {
      return data;
    }
  } catch (error) {
    if (error && error.response) {
      const { data } = error.response;
      if (data) {
        toast.error(data.error.message);
      }
    } else {
      toast.error("Something went wrong");
    }
  }
};

export const getChosenTiers = async companyId => {
  try {
    const { data } = await axiosInstance({
      method: "get",
      url: `/company/tiers?company_id=${companyId}`
    });
    if (data) {
      return data.items.map(item => new Tier(item));
    }
  } catch (error) {
    if (error && error.response) {
      const { data } = error.response;
      if (data) {
        toast.error(data.error.message);
      }
    } else {
      toast.error("Something went wrong");
    }
  }
};

export const getCompanies = async () => {
  try {
    const { data, statusText } = await axiosInstance({
      method: "get",
      url: "/company/get"
    });
    if (data) {
      return {
        companies: data.companies,
        statusText
      };
    }
  } catch (error) {
    if (error && error.response) {
      const { data } = error.response;
      if (data) {
        toast.error(data.error.message);
      }
    } else {
      toast.error("Something went wrong");
    }
  }
};

// export const createUser = async payload => {
// 	try {
// 		const { data } = await axiosInstance({
// 			method: 'post',
// 			url: '/users/register',
// 			data: payload
// 		})
// 		if (data) {
// 			return data
// 		}
// 	} catch (error) {
// 		alert(error)
// 	}
// }

export const getSingleUser = async id => {
  try {
    const { data } = await axiosInstance({
      method: "get",
      url: `/users/${id}`
    });
    if (data) {
      return data;
    }
  } catch (error) {
    if (error && error.response) {
      const { data } = error.response;
      if (data) {
        toast.error(data.error.message);
      }
    } else {
      toast.error("Something went wrong");
    }
  }
};
