import { toast } from "react-toastify";

export const handleAddBonusError = ({ error }) => {
    const { data } = error;
    if (data.errors && data.error) {
      if (data.errors.companyId) {
        toast.error(`${data.error.message}: ${data.errors.companyId.keys[0]}`);
      }
      if (data.errors.employeeId) {
        toast.error(`${data.error.message}: ${data.errors.employeeId.keys[0]}`);
      }
      if (data.errors.tokens) {
        toast.error(`${data.error.message}: ${data.errors.tokens.keys[0]}`);
      }
      if (data.errors.subject) {
        toast.error(`${data.error.message}: ${data.errors.subject.keys[0]}`);
      }
      if (data.errors.message) {
        toast.error(
          `${data.error.message}: ${data.errors.message.keys[0]}`
        );
      }
    } else {
      toast.error(data.error.message);
    }
  };