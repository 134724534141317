import React from 'react'
import PropTypes from 'prop-types'
// componenets
import { Button, IntitialsCircle, Input, InputLabel, TextAndLine, SelectInput } from '../../../../components'
// styles
import FormAndDetailsStyles from './FormStyles'

const FormAndDetails = ({
	onChange,
	user,
	userRoles,
	companies,
	backButtonFunc,
	buttonFunc,
	buttonText,
	isFormDisplay = false,
	isEditUserForm = false
}) => (
	<FormAndDetailsStyles>
		<TextAndLine title="User info" />
		<div className="userInfoContainer">
			<IntitialsCircle
				size={126}
				value={`${user.firstName} ${user.lastName}`}
			/>
			<div className="inputContainer">
				<div className="topInputs">
					<div style={{ margin: '0 10px 0 0', width: 230 }}>
						<InputLabel label="First name" margin="0 0 5px 0" />
						{isFormDisplay ?
							<Input name="firstName" value={user.firstName} onChange={e => onChange(e.target.name, e.target.value)} />
							:
							<p className="displayText">{user.firstName}</p>
						}
					</div>
					<div style={{ width: 230 }}>
						<InputLabel margin="0 0 5px 0" label="Last name" />
						{isFormDisplay ?
							<Input name="lastName" value={user.lastName} onChange={e => onChange(e.target.name, e.target.value)} />
							:
							<p className="displayText">{user.lastName}</p>
						}
					</div>
				</div>
				<div className="middleInputs">
					<div style={{ margin: '0 10px 0 0', width: `${isEditUserForm ? 470 : 230}px` }}>
						<InputLabel margin="0 0 5px 0" label="Email" />
						{isFormDisplay ?
							<Input type="email" name="email" value={user.email} onChange={e => onChange(e.target.name, e.target.value)} />
							:
							<p className="displayText">{user.email}</p>
						}
					</div>
					{!isEditUserForm &&
						<div style={{ width: 230 }}>
							<InputLabel margin="0 0 5px 0" label={isFormDisplay ? 'Password' : 'Email Confirmed'} />
							{isFormDisplay ?
								<Input type="password" name="password" onChange={e => onChange(e.target.name, e.target.value)} />
								:
								<p className="displayText">{user.emailConfirmed ? 'Confirmed' : 'Not Confirmed'}</p>
							}
						</div>
					}

				</div>
				<div className="bottomInputs">
					<div style={{ margin: '0 10px 0 0', width: 230 }}>
						<InputLabel margin="0 0 5px 0" label="Role" />
						{isFormDisplay ?
							<SelectInput
								options={userRoles}
								selectedValues={user.authenticatedUserRoleId && userRoles}
								value={isEditUserForm ? user.authenticatedRole : user.authenticatedUserRoleId}
								name="roleId"
								onChange={onChange}
							/>
							:
							<p className="displayText">{user.authenticatedRole}</p>
						}
					</div>
					<div style={{ width: 230 }}>
						<InputLabel margin="0 0 5px 0" label="Company" />
						{isFormDisplay ?
							<SelectInput
								options={companies}
								selectedValues={user.companyId && companies}
								value={isEditUserForm && user.Company ? user.Company.name : user.companyId}
								name="companyId"
								onChange={onChange}
							/>
							:
							<p className="displayText">{user.Company ? user.Company.name : 'No company relation'}</p>
						}
					</div>
				</div>
			</div>
		</div>
		<div className="buttonsContainer">
			{isFormDisplay &&
				<Button
					margin="0 30px 0 0"
					padding="10px 25px"
					type={isFormDisplay ? 'submit' : 'button'}
					onClick={() => buttonFunc && buttonFunc()}
				>
					{buttonText}
				</Button>
			}
			<Button
				onClick={() => backButtonFunc()}
				margin="0"
				padding="10px 25px"
				outline
			>
				Back
			</Button>
		</div>
	</FormAndDetailsStyles>
)

FormAndDetails.propTypes = {
	onChange: PropTypes.func,
	user: PropTypes.shape(),
	userRoles: PropTypes.arrayOf(PropTypes.shape()),
	companies: PropTypes.arrayOf(PropTypes.shape()),
	backButtonFunc: PropTypes.func,
	buttonFunc: PropTypes.func,
	buttonText: PropTypes.string,
	isFormDisplay: PropTypes.bool,
	isEditUserForm: PropTypes.bool
}

export default FormAndDetails
