import React from "react";

export const ShowRefundStatus = ({ handleStatus, status }) => {
  return (
    <div className="refundStatus" role="status" aria-live="polite">
      <p>Status</p>
      {handleStatus(status)}
    </div>
  );
};
