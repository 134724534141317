import { JpgPhoto } from "../../services/global.entities";

class LocalBenefitGroupForm {
  constructor(benefitGroupFormData = {}) {
    this.name = benefitGroupFormData.name || "";
    this.categories = benefitGroupFormData.categories || [];
    this.description = benefitGroupFormData.description || "";
    this.summary = benefitGroupFormData.summary || "";
    this.photo = benefitGroupFormData.Photo
      ? new JpgPhoto(benefitGroupFormData.Photo)
      : undefined;
  }
}

export default LocalBenefitGroupForm;
