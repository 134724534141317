import { formatDateForPlaceholder } from "../../services/date.utils";

class CompanyBudgetRollover {
  constructor(rolloverData = {}) {
    this.companyid = rolloverData.companyId || "";
    this.rolloverDuration = rolloverData.rolloverDuration || "";
    this.rolloverEndDate = rolloverData.rolloverEndDate || "";
    this.rolloverStartDate = rolloverData.rolloverStartDate || "";
    this.scheduleDeleteAt = rolloverData.scheduleDeleteAt ? formatDateForPlaceholder(new Date(rolloverData.scheduleDeleteAt)) : "";
  }
}

export default CompanyBudgetRollover;
