import React from "react";
// styles
import { AddEmployeeWithCSVErrorStyles } from "./AddEmployeeWithCSVErrorStyles";
// assets
import { ErrorIconSvg } from "../../../../../assets/Icons";
// components
import { Button } from "../../../../../components";
// utils
import { isEmpty } from "../../../../../services/general.utils";

const AddEmployeeWithCSVError = ({ message, errors, cities, closeModal }) => {

  /**
   * Get errors for render.
   * @param {Array} errors 
   * @returns {Array}
   */
  const getErrorsForRender = (errors) => {
    const errorsToRender = [];

    !isEmpty(errors) && errors.map(error => {
      if (error.email) {
        errorsToRender.push(`Email: ${Object.values(error.email.keys).join(', ')}`)
      }
      if (error.firstName) {
        errorsToRender.push(`First Name: ${Object.values(error.firstName.keys).join(', ')}`)
      }
      if (error.lastName) {
        errorsToRender.push(`Last Name: ${Object.values(error.lastName.keys).join(', ')}`)
      }
      if (error.gender) {
        errorsToRender.push(`Gender: ${Object.values(error.gender.keys).join(', ')}`)
      }
      if (error.tier) {
        errorsToRender.push(`Tier: ${Object.values(error.tier.keys).join(', ')}`)
      }
      if (error.description) {
        errorsToRender.push(`Description: ${Object.values(error.description.keys).join(', ')}`)
      }
      if (error.team) {
        errorsToRender.push(`Team: ${Object.values(error.team.keys).join(', ')}`)
      }
      if (error.relationship) {
        errorsToRender.push(`Relationship: ${Object.values(error.relationship.keys).join(', ')}`)
      }
      if (error.bornDate) {
        errorsToRender.push(`Birthday: ${Object.values(error.bornDate.keys).join(', ')}`)
      }
      if (error.firstDayAtWork) {
        errorsToRender.push(`Enroll date: ${Object.values(error.firstDayAtWork.keys).join(', ')}`)
      }
      if (error.contact) {
        errorsToRender.push(`Phone: ${Object.values(error.phone.keys).join(', ')}`)
      }
      if (error.children) {
        errorsToRender.push(`Children: ${Object.values(error.children.keys).join(', ')}`)
      }
      if (error.locations) {
        errorsToRender.push(`Locations: ${Object.values(error.locations.keys).join(', ')}`)
      }
      if (error.tokens) {
        errorsToRender.push(`Bonus Amount: ${Object.values(error.tokens.keys).join(', ')}`)
      }
      if (error.startDate) {
        errorsToRender.push(`Bonus Start Date: ${Object.values(error.startDate.keys).join(', ')}`)
      }
      if (error.endDate) {
        errorsToRender.push(`Bonus End Date: ${Object.values(error.endDate.keys).join(', ')}`)
      }
      if (error.recurringType) {
        errorsToRender.push(`Bonus Recurring Type: ${Object.values(error.recurringType.keys).join(', ')}`)
      }
    })

    return errorsToRender;
  }

  return (
    <AddEmployeeWithCSVErrorStyles>
      <div>
        <ErrorIconSvg />
      </div>
      <div className="title">
        Upload Failed
      </div>
      {!isEmpty(message) && (
        <div className="error-message">
          {message}
        </div>)}
      <div className="errors-wrapper">
        {!isEmpty(errors) && getErrorsForRender(errors).map((error, index) => {
          return (<div key={index} className="error-message">
            {error}
          </div>)
        })}
      </div>
      {!isEmpty(cities) && (<div className="available-locations">
        <b>Available locations: </b>
        <br />
        {cities.map(city => city.name).join(", ")}
      </div>)}
      <Button
        width="130px"
        onClick={() => closeModal()}
        margin="20px 0 0 0"
      >
        OK
      </Button>
    </AddEmployeeWithCSVErrorStyles>
  );
};

export default AddEmployeeWithCSVError;
