import UserBenefit from "./UserBenefit.entity";
import { City, User, Tier, CompanyBranch } from ".";
class Employee {
  constructor(employeeData = {}) {
    this.id = employeeData.id || null;
    this.firstName =
      employeeData.firstName ||
      employeeData.first_name ||
      employeeData.User?.firstName ||
      employeeData.User?.first_name ||
      "";
    this.lastName =
      employeeData.lastName ||
      employeeData.last_name ||
      employeeData.User?.lastName ||
      employeeData.User?.last_name ||
      "";
    this.email = employeeData.email || employeeData.User?.email || "";
    this.companyId = employeeData.companyId || "";
    this.children = employeeData.children || false;
    this.bornDate = employeeData.bornDate ? new Date(employeeData.bornDate) : "" || "";
    this.firstDayAtWork = employeeData.firstDayAtWork ? new Date(employeeData.firstDayAtWork) : "" || "";
    this.team = employeeData.team || "";
    this.contact = employeeData.contact || "";
    this.description = employeeData.description || "";
    this.relationship = employeeData.relationship || "";
    this.tier = employeeData.tier || new Tier(employeeData.Tier) || "";
    this.gender = employeeData.gender || "";
    this.uniqueId = employeeData.uniqueId || "";
    this.CompanyBranch = new CompanyBranch(employeeData.CompanyBranch) || '';
    this.branchCode = employeeData.companyBranchId || "";
    this.roleIds = employeeData.roleIds || employeeData.role_ids || [];
    this.roles = employeeData.roles || null;
    this.availableTokens =
      parseInt(employeeData.availableTokens, 0) ||
      parseInt(employeeData.available_tokens, 0) ||
      0;
    this.usedToken =
      parseInt(employeeData.usedToken, 0) ||
      parseInt(employeeData.used_token, 0) ||
      0;
    this.remainingTokens =
      parseInt(employeeData.remainingTokens, 0) ||
      parseInt(employeeData.remaining_tokens, 0) ||
      0;
    if (this.remainingTokens < 0) {
      this.remainingTokens = 0;
    }
    this.userBenefits = employeeData.UserBenefits
      ? new UserBenefit(employeeData.UserBenefits[0])
      : new UserBenefit();
    this.activeBenefitsCount =
      employeeData.activeBenefitsCount ||
      employeeData.active_benefits_count ||
      0;
    this.user = employeeData.user ? new User(employeeData.user) : new User();
    this.cities =
      employeeData.cities ?
        employeeData.cities.map(city => new City(city)) :
        [];
    this.rolloverTokens =
      parseInt(employeeData.rolloverTokens, 0) ||
      parseInt(employeeData.rollover_tokens, 0) ||
      0;
    this.totalTokens =
      parseInt(employeeData.totalTokens, 0) ||
      parseInt(employeeData.total_tokens, 0) ||
      0;

  }
}

export default Employee;
