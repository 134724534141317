import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
// eslint-disable-next-line
import TableRowStyles from "./TableRowStyles";
import TableRowActions from "./TableRowActions";
import { DotsIcon, UserDisabledIconFillSvg, UserDisabledIconSvg } from "../../../assets/Icons";

import getInitals from "../../../services/getInitals";

// let myTooltip = null

const TableRow = ({
  findItem,
  columnOne = "Unkown",
  urlLink,
  deleteLink,
  resendVerificationLinkParam,
  removeRow,
  fetchData,
  columnTwo,
  columnThree,
  baseUrl,
  tokens,
  columnFour = "Unknown",
  columnFive,
  columnSix,
  isLinkEnabled = false,
  isFirstLetter = false,
  isActionsEnabled = false,
  isResendVerificationEnabled = false,
  isShowActionsVisable,
  isEditActionsVisable,
  isDeleteActionsVisable,
  shouldShowDeletePopup = false,
  openDisableUserPopup = () => { },
  deleteConfirmMessage,
  additionalLinkParams,
  showTooltip = false,
  deleteParam = null,
  isRowDisabled = false,
  openAddBonusModal,
  tableStylings = {
    firstColWidth: "20%",
    firstColJustifyContent: "flex-start",
    firstColPadding: "0",
    firstColMargin: "0",
    secondColWidth: "20%",
    secondColJustifyContent: "center",
    secondColPadding: "0",
    secondColMargin: "0",
    thirdColWidth: "30%",
    thirdColJustifyContent: "center",
    thirdColPadding: "0",
    thirdColMargin: "0",
    forthColWidth: "20%",
    forthColJustifyContent: "flex-start",
    forthColPadding: "0",
    forthColMargin: "0 0 0 6%",
    fifthColWidth: "20%",
    fifthColJustifyContent: "flex-start",
    fifthColPadding: "0",
    fifthColMargin: "0 0 0 6%",
    sixthColWidth: "20%",
    sixthColJustifyContent: "flex-start",
    sixthColPadding: "0",
    sixthColMargin: "0 0 0 6%",
    actionsColWidth: "4%",
    actionsColJustifyContent: "center",
    actionsColPadding: "0",
    actionsColMargin: "0"
  }
}) => {
  const [isActive, setIsActive] = useState(false);
  const myTooltip = useRef();

  const onClickDots = () => {
    setIsActive(true);
    ReactTooltip.rebuild();
  };

  const handleBlur = () => {
    setIsActive(false);
    // ReactTooltip.hide()
    ReactTooltip.rebuild();
  };

  return (
    <TableRowStyles
      onClick={() => findItem && findItem()}
      tableStylings={tableStylings}
      findItem={findItem}
      isLinkEnabled={isLinkEnabled}
    >
      <div className="nameCol">
        <div className="employeeAvatar">
          {getInitals(columnOne, isFirstLetter)}
        </div>
        <span>{columnOne !== "" ? columnOne : "Unknown"}</span>
        {isRowDisabled && (
          <>
            <span
              data-tip
              data-for={`emailTooltip-${columnOne}`}
              style={{ position: 'absolute', top: '13px', left: '16px' }}
            >
              <UserDisabledIconSvg />
            </span>
            <ReactTooltip
              id={`emailTooltip-${columnOne}`}
              place="top"
              effect="solid"
              className="email-tooltip"
              backgroundColor="#000000"
              borderColor="#000000"
              arrowColor="#000000"
            >
              <span>User is disabled</span>
            </ReactTooltip>
          </>
        )}
      </div>
      <p className="tierCol">{columnTwo}</p>
      <p className="tokensCol">
        {tokens}
        {columnThree !== undefined && tokens !== undefined && "/"}
        {columnThree}
      </p>
      <div
        data-tip
        data-for={`emailTooltip-${columnFour}`}
        className="emailCol"
      >
        {showTooltip && (
          <ReactTooltip
            id={`emailTooltip-${columnFour}`}
            place="top"
            effect="solid"
            className="email-tooltip"
            backgroundColor="#000000"
            borderColor="#000000"
            arrowColor="#000000"
          >
            <span>{columnFour}</span>
          </ReactTooltip>
        )}
        <p>{columnFour}</p>
      </div>
      <p className="fifthCol"><span>{columnFive}</span></p>
      <p className="sixthCol">{columnSix}</p>
      {isActionsEnabled && (
        <>
          <button
            type="button"
            className="actionsCol"
            data-tip={`${urlLink}|${deleteLink}|${additionalLinkParams}|${resendVerificationLinkParam}|${deleteParam}|${isRowDisabled}`}
            data-event={isActive ? "focus" : "click"}
            data-event-off={!isActive ? "focusout" : ""}
            onClick={e => {
              e.stopPropagation();
              onClickDots();
            }}
            onBlur={() => handleBlur()}
          >
            <DotsIcon />
          </button>
          <ReactTooltip
            place="bottom"
            effect="solid"
            // eslint-disable-next-line
            ref={ref => (myTooltip.current = ref)}
            className="tooltip"
            getContent={dataTip => {
              if (!dataTip) {
                return "";
              }
              const [
                urlLinkId,
                removeLink,
                additionalParams,
                resendVerificationLinkParam,
                deleteParamId,
                isRowDisabledParam
              ] = dataTip.split("|");

              return (
                <TableRowActions
                  deleteLink={removeLink}
                  baseUrl={baseUrl}
                  urlLink={urlLinkId}
                  resendVerificationLinkParam={resendVerificationLinkParam}
                  removeRow={removeRow}
                  fetchData={fetchData}
                  additionalLinkParams={additionalParams}
                  deleteConfirmMessage={deleteConfirmMessage}
                  hideTooltip={() => {
                    myTooltip.current.tooltipRef = null;
                    ReactTooltip.hide();
                  }}
                  isShowActionVisable={isShowActionsVisable}
                  isEditActionVisable={isEditActionsVisable}
                  isDeleteActionVisable={isDeleteActionsVisable}
                  isResendVerificationEnabled={isResendVerificationEnabled}
                  shouldShowDeletePopup={shouldShowDeletePopup}
                  openDisableUserPopup={openDisableUserPopup}
                  deleteParam={deleteParamId}
                  isRowDisabled={isRowDisabledParam}
                  openAddBonusModal={openAddBonusModal}
                />
              );
            }}
            border
            clickable
          />
        </>
      )}
    </TableRowStyles>
  );
};

TableRow.propTypes = {
  findItem: PropTypes.func,
  columnOne: PropTypes.node,
  columnTwo: PropTypes.node,
  columnThree: PropTypes.node,
  tokens: PropTypes.number,
  columnFour: PropTypes.node,
  columnFive: PropTypes.node,
  columnSix: PropTypes.node,
  baseUrl: PropTypes.string,
  isLinkEnabled: PropTypes.bool,
  isFirstLetter: PropTypes.bool,
  isActionsEnabled: PropTypes.bool,
  urlLink: PropTypes.string,
  deleteLink: PropTypes.string,
  removeRow: PropTypes.func,
  fetchData: PropTypes.func,
  tableStylings: PropTypes.shape(),
  isShowActionsVisable: PropTypes.bool,
  isEditActionsVisable: PropTypes.bool,
  isDeleteActionsVisable: PropTypes.bool,
  additionalLinkParams: PropTypes.string.isRequired,
  deleteConfirmMessage: PropTypes.string
};

export default TableRow;
