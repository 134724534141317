import React from 'react';
import { Input } from '../../../../../../components';
import { BenefitSubscribeQuantityStyles } from './BenefitSubscribeQuantity.styles';

const BenefitSubscribeQuantity = ({ setQuantity, isPreview, quantity, handleSetQuantity, handleKeyDown }) => (
  <BenefitSubscribeQuantityStyles>
    <button
      type="button"
      onClick={() => setQuantity(quantity - 1)}
      disabled={isPreview || quantity === 1 || quantity === 0}
      className="decrementButton"
    >
      -
    </button>
    <Input
      name="quantity"
      pattern="^\d+$"
      value={quantity}
      onChange={(e) => handleSetQuantity(e)}
      onBlur={(e) => handleSetQuantity(e)}
      onKeyDown={handleKeyDown}
      shouldHaveHight={false}
      min={1}
      max={999}
      customCssClass="inputStyles quantityInput"
      disabled={isPreview}
    />
    <button
      type="button"
      onClick={() => setQuantity(quantity + 1)}
      disabled={isPreview || quantity === ""}
      className="incrementButton"
    >
      +
    </button>
  </BenefitSubscribeQuantityStyles>
)

export default BenefitSubscribeQuantity;
