import adminDashboardActionTypes from "../../constants/actionTypes/admin/dashboard.constants";

const setAdminDashboardFilters = payload => {
  return {
    type: adminDashboardActionTypes.SET_ADMIN_DASHBOARD_FILTERS,
    payload
  };
};
const setAdminDashboardStatistics = payload => {
  return {
    type: adminDashboardActionTypes.SET_ADMIN_DASHBOARD_STATISTICS,
    payload
  };
};

const setAdminDashboardUserBenefits = payload => {
  return {
    type: adminDashboardActionTypes.SET_ADMIN_DASHBOARD_USER_BENEFITS,
    payload
  };
};

export {
  setAdminDashboardFilters,
  setAdminDashboardStatistics,
  setAdminDashboardUserBenefits
};
