import styled from 'styled-components'

const TextAndLineStyles = styled.div`
  display: flex;
  align-items: center;
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};

  .text {
    font-size: 16px;
    font-weight: 500;
    margin-right: ${({ title }) => (title ? '30px' : '0')};
    color: #1568bf;
    text-transform: uppercase;
    white-space: nowrap;
  }

  .line {
    width: 100%;
    border: 0.5px solid #d8d8d8;
  }
`

export default TextAndLineStyles
