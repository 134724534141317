import { openModalAnim, closeModalAnim } from '../../../components/modal'

const openModal = ({ setModalType, modalType }) => {
	setModalType(modalType)
	setTimeout(() => {
		openModalAnim()
	}, 100)
}

const closeModal = ({ setModalType }) => {
	closeModalAnim()
	setTimeout(() => {
		setModalType(undefined)
	}, 350)
}

export {
	openModal,
	closeModal
}
