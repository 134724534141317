import * as yup from "yup";

const SUPPORTED_FORMATS = ["csv"];

yup.addMethod(yup.array, "unique", function (message, mapper = a => a) {
  return this.test(
    "unique",
    message,
    list => list.length === new Set(list.map(mapper)).size
  );
});

export const onboardingValidationsSchema = yup.object().shape({
  tiers: yup
    .array()
    .of(
      yup.object().shape({
        name: yup
          .string()
          .required("* Required")
          .max(20, "Too long"),
        currency: yup
          .number()
          .required("* Required")
          .test(
            "is-incorrect",
            "Must be divisible by 20",
            val => val % 20 === 0
          ),
        tokens: yup.string().required("* Required")
      })
    )
    .unique("Tier name is taken", a => a.name)
    .min(1, "There must be at least 1 tier created"),
  csvFile: yup
    .mixed()
    .required("A file is required")
    .test(
      "fileFormat",
      "You can upload only .csv file format",
      value =>
        value &&
        value.name &&
        SUPPORTED_FORMATS.includes(value.name.split(".").pop())
    )
});
