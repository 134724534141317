/* eslint-disable */
import React, { useState, useEffect } from 'react'
import styled  from  'styled-components'
import {calculateTimeLeft} from './countDown.service'

// const year = new Date().getFullYear();
// const month = new Date().getMonth() + 1;
// const date  = new Date(`${month}/01/${year}`)

const SubscribeCountDownStyles = styled.div`
    margin-left: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #4B4B4B;
    width: 130px;
    display: flex;
    justify-content: space-between;
`


const SubscribeCountDown = ({isSync}) => {
    const [timer, setTimer] = useState(calculateTimeLeft())

    useEffect(() => {
        if (!isSync) {
            const countDown = setTimeout(() => {
                setTimer(calculateTimeLeft());
              }, 1000);
              // Clear timeout if the component is unmounted
              return () => clearTimeout(countDown);
        }
        
    })

    
    return (
        <SubscribeCountDownStyles>
            {isSync ?
                'Sync in  progress...'
                :
                timer
            }
        </SubscribeCountDownStyles>
    )
}

export default SubscribeCountDown
