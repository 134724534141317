import styled from 'styled-components'

const SubRowStyles = styled.div`
	.benefitGroupContainer {
		padding: 5px 30px 0 30px;
	}

	.testDiv {
		overflow:hidden;
    transition:height 0.3s ease-out, border-width 0.1s linear;
		height: 0;
		position: relative;
	}

	.subRowContent {
		display: flex;
		flex-wrap: wrap;
		padding: 20px 0;
	}

	.contentContainer {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		overflow-x: auto;
		overflow-y: hidden;
	}

	.firstTwo {
		display: flex;
	}

	/* provider */

	.providerDetails {
		height: 350px;
		width: 390px;
		border-right: 1px dotted #d8d8d8;
		padding: 5px 30px 0 10px;
	}

	.providerDetailsCard {
		width: 350px;
		height: 344px;
		border-radius: 4px;
		border: solid 1px #d8d8d8;
		background-color: #ffffff;
		padding: 20px;
		overflow: hidden;
	}

	.providerTitle {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 10px;
	}

	.providerDescription {
		height: calc(100% - 50px);
		width: 100%;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.providerDescriptionText {
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.67;
		letter-spacing: normal;
		color: #373737;
		overflow-wrap: break-word;
	}

	/* end of provider */

	/* benefit groups */

	.benefitGroupsContainer {
		/* height: 339px; */
		height: 350px;
		width: 380px;
		padding: 0 30px;
		border-right: 1px dotted #d8d8d8;
	}

	/* end of benefit groups */

	.secondTwo {
		display: flex;
	}
	/* benefits */

	.benefitsContainer {
		height: 350px;
		width: 380px;
		padding: 0 30px;
		border-right: 1px dotted #d8d8d8;
	}


	.benefitGroupColumn {
		width: 380px;
		height: 282px;
		overflow-y: scroll;
	}

	.addBenefitGroup {
		width: calc(100% - 8px);
		height: 44px;
		border-radius: 4px;
		border: solid 1px #d8d8d8;
		margin-top: 20px;
		margin-left: 4px;
		display: flex;
		align-items: center;
		padding: 0 12px;
		cursor: pointer;
	}
	.circle {
		width: 24px;
		height: 24px;
		border-radius: 50%;
		background: #1568bf;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		margin-right: 13px;
	}

	.buttonText {
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.38;
		letter-spacing: -0.4px;
		color: #373737;
	}

	.benefitList {
		width: 350px;
		height: 280px;
		overflow-y: scroll;
	}

	/* end of benefits */
	
@media screen and (max-width: 1868px) {
  .subRowContent {
		justify-content: center;
	}

	.contentContainer {
		width: 800px;
	}

	.firstTwo {
		margin-bottom: 20px;
	}

	.benefitGroupsContainer {
		border-right: none;
		width: 390px;
	}

	.benefitsContainer {
		width: 390px;
    padding: 0 30px 0 10px;
	}
}
`

export default SubRowStyles
