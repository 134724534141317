import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// components
import {
  TextAndLine,
  Input,
  InputLabel,
  SelectInput,
  Button,
  MyDropZone,
  LocationsMultiselectInput,
  CheckboxInput,
  MultiselectInput
} from "../../../../../../components";
// styles
import { override } from "../../../../../../services/loadingOverride.styles";
import BenefitGroupFormStyles from "./BenefitGroupFormStyles";
// services
import * as categoriesService from "../../../../../../services/api/admin/categories.service";
import * as employeeService from "../../../../../../services/api/hr/employees.service";
import * as categoriesActionCreators from "../../../../../../actions/admin/categories.actions";
import * as providersService from "../../../../../../services/api/admin/providers.service";
import { convertStringToEditorState } from "../../../../../../services/textEditor.utils";
import * as api from "../../../../../../services/api/admin/users.service";

// Utils
import { isEmpty } from "../../../../../../services/general.utils";
import { useTranslation } from "react-i18next";

const BenefitGroupForm = ({
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  handleChange,
  buttonText,
  handleBlur,
  closeModal,
  disableButton,
  setCategories,
  categories,
  isEdit
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [cities, setCities] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [companiesPage, setCompaniesPage] = useState(1);
  const [totalCompaniesPages, setTotalCompaniesPages] = useState(1);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchCategories = async () => {
    const response = await categoriesService.getCategories();

    if (response.hasError) {
      return toast.error(response.error.data.error.message);
    }

    setCategories(response.categories);
    setIsLoading(false);
  };

  const fetchCompanies = async () => {
    const response = await api.getCompanyOptions(companiesPage);
    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : t("failed_to_get_companies")
      );
    }

    setCompanies(response.companies);
    setTotalCompaniesPages(response.pages);
  };

  /**
   * Refetches paginated companies.
   * Fired from on scroll event from select input.
   * Sets new companies to state, along with page number.
   */
  const refetchCompanies = async () => {
    let currentPage = companiesPage;
    let newPage = (currentPage += 1);

    if (newPage > totalCompaniesPages) return;

    setCompaniesPage(newPage);

    const response = await api.getCompanyOptions(newPage);
    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : t("failed_to_get_companies")
      );
    }

    let newCompanies = [];
    setTimeout(() => {
      newCompanies = [...companies, ...response.companies];
      setTimeout(() => {
        setCompanies(newCompanies);
      }, 100);
    }, 100);
  };

  /**
   * Returns list of cities from server and sets it to state.
   * Is fired every time if search query or page changes.
   */
  const fetchCities = async () => {
    const response = await employeeService.getCities(search, page);

    if (response.hasError) {
      return toast.error(response.errorMessage);
    }

    setCities(response.cities);
    setTotalPages(response.pages);
  };

  /**
   * Refetches cities list from server and sets it to state.
   * If page number increments to a value that is greater than there are total pages
   * function exits.
   * Updates page number & sets it to state.
   */
  const refetchCities = async () => {
    let currentPage = page;
    let newPage = (currentPage += 1);

    if (newPage > totalPages) return;

    setPage(newPage);

    const response = await employeeService.getCities(search, newPage);

    if (response.hasError) {
      return toast.error(response.errorMessage);
    }

    let newCities = [];
    setTimeout(() => {
      newCities = [...cities, ...response.cities];
      setTimeout(() => {
        setCities(newCities);
      }, 100);
    }, 100);
  };

  const handleInputChange = newValue => {
    setPage(1);
    setSearch(newValue);

    return newValue;
  };

  const handleSelect = option => {
    setFieldValue("cities", option);
  };

  const handleCompanySelect = option => {
    if (isEmpty(option)) {
      setFieldValue("companyId", null);
      setSelectedCompany(null);

      return;
    }

    setFieldValue("companyId", option.id);
    setSelectedCompany(option);
  };

  useEffect(() => {
    fetchCities();
  }, [page, search]);

  useEffect(() => {
    fetchCategories();
    fetchCompanies();
  }, []);

  const handleNumberInput = e => {
    let inputValue = e.target.validity.valid
      ? parseInt(e.target.value, 0)
      : values.tokenPrice;
    if (isNaN(inputValue)) {
      inputValue = "";
    }
    setFieldValue("capacity", inputValue);
  };

  /**
   * Handles change of draft status for benefit.
   */
  const handleBenefitGroupPublishedStatus = () => {
    const draft = !values.isDraft;
    setFieldValue("isDraft", draft);
  };

  /**
   * Published drafted benefit group.
   * If request fails, returns toast error message.
   * If request is successfull, fires toast message, closes modal.
   */
  const publishBenefitGroup = async () => {
    const response = await providersService.publishBenefitGroup(values.id);

    if (response.hasError) {
      return toast.error(t("failed_to_publish_benefit"));
    }

    toast.success(t("success_publish_benefit"));
    closeModal();
  };

  return (
    <BenefitGroupFormStyles>
      {isLoading ? (
        <div
          style={{
            height: "calc(100vh - 180px)",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <BeatLoader
            css={override}
            size={25}
            color="#123abc"
            loading={isLoading}
          />
        </div>
      ) : (
        <>
          <TextAndLine title={t("basic_info")} />
          <div style={{ margin: "20px 0" }}>
            <div style={{ margin: "0 0 10px 0" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: 24
                }}
              >
                <InputLabel
                  customCssClass="labelStyles"
                  margin=" 0 0 5px 0"
                  label={t("Name")}
                />
                <div className="errorMsg" style={{ marginBottom: 5 }}>
                  {errors.name && touched.name ? errors.name : ""}
                </div>
              </div>
              <Input
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                shouldHaveHight={false}
                customCssClass="inputStyles"
              />
            </div>
            <div
              style={{
                margin: "0 0 10px 0",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <div style={{ width: 225 }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: 24
                  }}
                >
                  <InputLabel
                    customCssClass="labelStyles"
                    margin="0 0 5px 0"
                    label={t("Category")}
                  />
                  <div className="errorMsg" style={{ marginBottom: 5 }}>
                    {errors.categories && touched.categories
                      ? errors.categories
                      : ""}
                  </div>
                </div>
                <MultiselectInput
                  name="categories"
                  options={categories}
                  placeholder={t("select_categories")}
                  handleChange={option => setFieldValue("categories", option)}
                  selectedValues={values.categories}
                  shouldHaveFullWidth
                  shouldHaveFullHeigth
                />
              </div>
              <div style={{ width: 225 }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: 24
                  }}
                >
                  <InputLabel
                    customCssClass="labelStyles"
                    margin="0 0 5px 0"
                    label={t("Company")}
                  />
                  <div className="errorMsg" style={{ marginBottom: 5 }}>
                    {errors.companyId && touched.companyId
                      ? errors.companyId
                      : ""}
                  </div>
                </div>
                <SelectInput
                  name="categories"
                  options={companies}
                  placeholder="Select company"
                  handleChange={option => handleCompanySelect(option)}
                  value={companies.find(c => c.id === values.companyId)}
                  fetchMoreData={refetchCompanies}
                  shouldHaveHight={false}
                  isClearable={true}
                />
              </div>
            </div>
            <div style={{ margin: "0 0 10px 0" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: 24
                }}
              >
                <InputLabel
                  customCssClass="labelStyles"
                  margin=" 0 0 5px 0"
                  label={t("Taxes")}
                />
                <div className="errorMsg" style={{ marginBottom: 5 }}>
                  {errors.taxes && touched.taxes ? errors.taxes : ""}
                </div>
              </div>
              <Input
                name="taxes"
                value={values.taxes}
                onChange={handleChange}
                onBlur={handleBlur}
                shouldHaveHight={false}
                customCssClass="inputStyles"
              />
            </div>
            <div style={{ margin: "0 0 10px 0", display: "flex" }}>
              <CheckboxInput
                isChecked={values.isRemote}
                onChange={() => setFieldValue("isRemote", !values.isRemote)}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: 24
                }}
              >
                <InputLabel
                  customCssClass="labelStyles"
                  margin="0 0 0 10px"
                  label={t("Remote")}
                  shouldHaveColon={false}
                />
              </div>
            </div>
            <div style={{ margin: "10px 0 10px 0" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: 24
                }}
              >
                <InputLabel
                  customCssClass="labelStyles"
                  margin=" 0 0 5px 0"
                  label={t("locations")}
                />
                <div className="errorMsg" style={{ marginBottom: 5 }}>
                  {errors.cities && touched.cities ? errors.cities : ""}
                </div>
              </div>
              <LocationsMultiselectInput
                name="cities"
                options={cities}
                selectedValues={values.cities}
                onChange={handleChange}
                onBlur={handleBlur}
                shouldHaveHight={false}
                shouldHaveFullWidth={true}
                fetchMoreData={refetchCities}
                handleChange={option => handleSelect(option)}
                handleInputChange={handleInputChange}
              />
            </div>
            <div style={{ margin: "0 0 10px 0" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: 24
                }}
              >
                <InputLabel
                  customCssClass="labelStyles"
                  margin=" 0 0 5px 0"
                  label={t("Summary")}
                />
                <div className="errorMsg" style={{ marginBottom: 5 }}>
                  {errors.summary && touched.summary ? errors.summary : ""}
                </div>
              </div>
              <textarea
                className="summary"
                name="summary"
                value={values.summary}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
          <div className="descriptionContainer">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: 24
              }}
            >
              <InputLabel
                customCssClass="labelStyles"
                margin=" 0 0 5px 0"
                label={t("Description")}
              />
              <div className="errorMsg" style={{ marginBottom: 5 }}>
                {errors.description && touched.description
                  ? errors.description
                  : ""}
              </div>
            </div>
            <Editor
              onBlur={() => {
                setFieldTouched("description", true);
              }}
              editorState={convertStringToEditorState(values.description)}
              editorStyle={{
                fontSize: 14
              }}
              stripPastedStyles={true}
              onEditorStateChange={editorState =>
                setFieldValue("description", editorState)
              }
              toolbar={{
                options: [
                  "inline",
                  "fontSize",
                  "list",
                  "textAlign",
                  "colorPicker",
                  "link",
                  "emoji",
                  "remove",
                  "history"
                ],
                fontSize: {
                  options: [10, 11, 12, 13, 14, 16, 18]
                }
              }}
            />
          </div>
          {!isEdit && (
            <div style={{ display: "flex", padding: "5px", margin: "20px 0" }}>
              <CheckboxInput
                isChecked={values.isDraft}
                onChange={() => handleBenefitGroupPublishedStatus()}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: 24
                }}
              >
                <InputLabel
                  customCssClass="labelStyles"
                  margin="0 0 0 10px"
                  label={t("save_ben_gr_as_draft")}
                  shouldHaveColon={false}
                />
              </div>
            </div>
          )}
          {isEdit && values.isDraft && (
            <span
              style={{ display: "flex", padding: "5px", margin: "20px 0" }}
              className="publishBenefitLink"
              onClick={() => publishBenefitGroup()}
            >
              {t("publish_ben_group")}
            </span>
          )}
          <TextAndLine title={t("Photo")} />
          <div style={{ margin: "20px 0" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: 24
              }}
            >
              <InputLabel
                customCssClass="labelStyles"
                margin=" 0 0 5px 0"
                label=""
                shouldHaveColon={false}
              />
              <div className="errorMsg" style={{ marginBottom: 5 }}>
                {errors.photo && touched.photo ? errors.photo : ""}
              </div>
            </div>
            <MyDropZone
              name="photo"
              onBlur={setFieldTouched}
              photo={values.photo}
              handleChange={setFieldValue}
            />
          </div>
          <div style={{ display: "flex", marginBottom: 10 }}>
            {disableButton ? (
              <Button width="130px" disabled margin="0 30px 0 0">
                {t("please_wait")}
              </Button>
            ) : (
              <Button width="130px" type="submit" margin="0 30px 0 0">
                {t(buttonText)}
              </Button>
            )}
            <Button
              width="130px"
              onClick={() => closeModal()}
              margin="0"
              outline
            >
              {t("cancel")}
            </Button>
          </div>
        </>
      )}
    </BenefitGroupFormStyles>
  );
};

BenefitGroupForm.propTypes = {
  values: PropTypes.shape(),
  errors: PropTypes.shape(),
  touched: PropTypes.shape(),
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  closeModal: PropTypes.func,
  buttonText: PropTypes.string,
  disableButton: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    categories: state.categories.categories
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCategories: categories =>
      dispatch(categoriesActionCreators.setCategories(categories))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BenefitGroupForm);
