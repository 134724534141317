// Domain models
import { Voucher } from "../../domain";

// Utils
import { isEmpty, removeSpacesAndHTML } from "../../../services/general.utils";
import { convertEditorStateToString } from "../../../services/textEditor.utils";

export class BenefitPayload {
  payload = new FormData();

  constructor(benefitGroupId, benefit = {}) {
    benefitGroupId && this.payload.append("id", benefitGroupId);
    this.payload.append(
      "title",
      benefit.title && removeSpacesAndHTML(benefit.title)
    );
    !isEmpty(benefit.description)
      ? this.payload.append(
          "description",
          convertEditorStateToString(benefit.description)
        )
      : null;
    this.payload.append(
      "summary",
      benefit.summary && removeSpacesAndHTML(benefit.summary)
    );
    this.payload.append("expiration", benefit.expiration);
    if (benefit.expiration !== "voucher") {
      this.payload.append("token_price", benefit.tokenPrice);
    }
    if (benefit.expiration === "voucher") {
      this.payload.append(
        "vouchers",
        JSON.stringify(benefit.vouchers.map(voucher => new Voucher(voucher)))
      );
    }
    if (benefit.expiration === "month") {
      benefit.minimumContractPeriod
        ? this.payload.append(
            "minimum_contract_period",
            parseInt(benefit.minimumContractPeriod)
          )
        : null;
    }
    benefit.vat ? this.payload.append("vat", parseInt(benefit.vat)) : null;
    !isEmpty(benefit.shortName)
      ? this.payload.append(
          "short_name",
          removeSpacesAndHTML(benefit.shortName)
        )
      : null;
    benefit.photo && this.payload.append("photo", benefit.photo);
    this.payload.append("benefit_group_id", benefitGroupId);
    benefit.tags.map(tag => {
      this.payload.append("tags", tag);
    });
    this.payload.append("is_draft", JSON.stringify(benefit.isDraft));
    this.payload.append(
      "quantity",
      benefit.quantity === "" ? null : benefit.quantity
    );
    this.payload.append(
      "instalments",
      benefit.instalments === "" ? null : benefit.instalments
    );
    !isEmpty(benefit.usageInstructions)
      ? this.payload.append(
          "usage_instructions",
          convertEditorStateToString(benefit.usageInstructions)
        )
      : null;
  }
}

export default BenefitPayload;
