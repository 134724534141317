import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const TrashContainer = styled.div`
  /* align-items: center; */
  cursor: pointer;
  /* display: flex; */

  svg {
    stroke: ${({ stroke }) => stroke};
  }
`

const TrashIcon = ({ width = '14px', stroke = '#1568bf', cursor = 'pointer' }) => (
	<TrashContainer cursor={cursor} stroke={stroke}>
		<svg width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 19">
			<g
				fill="none"
				fillRule="evenodd"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.718"
				transform="translate(-844 -125) translate(290) translate(40 116) translate(1) translate(514 10)"
			>
				<path d="M0 3.4L1.66666667 3.4 15 3.4" />
				<path d="M13.333 3.4v11.9c0 .939-.746 1.7-1.666 1.7H3.333c-.92 0-1.666-.761-1.666-1.7V3.4m2.5 0V1.7c0-.939.746-1.7 1.666-1.7h3.334c.92 0 1.666.761 1.666 1.7v1.7M5.833 7.65v5.1M9.167 7.65v5.1" />
			</g>
		</svg>
	</TrashContainer>
)

TrashIcon.propTypes = {
	width: PropTypes.string,
	stroke: PropTypes.string,
	cursor: PropTypes.string,
	styles: PropTypes.shape({})
}

export default TrashIcon
