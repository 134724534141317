import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { TextAndLine, Button } from "../../../../components";
import List from "./List";

const SectionStyles = styled.div``;

const Section = ({
  textAndLineTitle,
  buttonOnCLick,
  buttonText,
  listItems,
  editItem,
  removeItem
}) => (
  <SectionStyles>
    <TextAndLine title={textAndLineTitle} />
    <Button margin="60px 0 0 0" onClick={buttonOnCLick}>
      {buttonText}
    </Button>
    <List items={listItems} editItem={editItem} removeItem={removeItem} />
  </SectionStyles>
);

Section.propTypes = {
  textAndLineTitle: PropTypes.string,
  buttonOnCLick: PropTypes.func,
  buttonText: PropTypes.string,
  listItems: PropTypes.arrayOf(PropTypes.shape()),
  editItem: PropTypes.func,
  removeItem: PropTypes.func
};

export default Section;
