import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

// components
import {
  Button,
  Header,
  Card,
  Modal,
  openModalAnim,
  closeModalAnim
} from "../../../components";
import BasicInfo from "./BasicInfo/BasicInfo";
import EditBenefitOptions from "./EditBenefitOptions/EditBenefitOptions";
import BenefitActivitySection from "./BenefitActivitySection/BenefitActivitySection";
import BenefitGroupUsersTable from "./BenefitGroupUsersTable/BenefitGroupUsersTable";
import PreviewModal from "../../SuperAdminPages/Providers/PreviewModal/PreviewModal";
import BenefitGroupInstalmentsSection from "./BenefitGroupInstalmentsSection/BenefitGroupInstalmentsSection";
// styling component
import { override } from "../../../services/loadingOverride.styles";
import EditHrBenefitStyles from "./EditHrBenefitsStyles";
// services
import * as actionCreators from "../../../actions/hr/benefits.actions";
import * as api from "../../../services/api/hr/benefits.service";
import { PUBLIC } from "../../../builders/benefitGroups/benefitGroup.types";
import { useTranslation } from "react-i18next";

const EditHrBenefit = ({
  match,
  benefitGroup,
  isBenefitGroupActive,
  tiers,
  toggleBenefitGroup,
  setEditHrBenfitsInitialState,
  toggleBenefitGroupInstalments
}) => {
  const { t } = useTranslation();
  const [availableTiers, setAvailableTiers] = useState([]);
  const [didDisableTier, setDidDisableTier] = useState(false);
  const [canFindAvailableTiers, setCanFindAvailableTiers] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const isMounted = useRef(true);
  const [previewModal, setPreviewModal] = useState(false);

  const fetchAllInitialData = async () => {
    const [benefitGroupResponse, tiersResponse] = await Promise.all([
      api.getBenefitGroup(match.params.id),
      api.getTiers()
    ]);

    if (benefitGroupResponse.hasError) {
      return toast.error(
        benefitGroupResponse.errorMessage
          ? benefitGroupResponse.errorMessage
          : t("failed_to_get_benefit_groups")
      );
    }

    if (tiersResponse.hasError) {
      return toast.error(
        tiersResponse.errorMessage
          ? tiersResponse.errorMessage
          : t("failed_to_get_tiers")
      );
    }

    setEditHrBenfitsInitialState(benefitGroupResponse, tiersResponse.tiers);
    setCanFindAvailableTiers(true);
  };

  const handleChange = id => {
    if (availableTiers.includes(id)) {
      setAvailableTiers(availableTiers.filter(tierId => tierId !== id));
      setDidDisableTier(true);
    } else {
      setAvailableTiers([...availableTiers, id]);
    }
  };

  const handleCheckUncheckAll = (listOfTierIds, isSomeChecked) => {
    if (isSomeChecked) {
      setAvailableTiers([]);
      return;
    }
    setAvailableTiers(listOfTierIds);
  };

  const findAvailableTiers = () => {
    const chosenAvailableTiers = [];
    tiers.forEach(tier => {
      if (benefitGroup.availableTiers.includes(tier.id)) {
        chosenAvailableTiers.push(tier.id);
      }
    });
    setAvailableTiers(chosenAvailableTiers);
  };

  // this function is called after component did mount only once
  if (canFindAvailableTiers) {
    findAvailableTiers();
    setCanFindAvailableTiers(false);
    setIsLoading(false);
    // setTimeout delays animation untill content is ready to be displayed (set to state)
    setTimeout(() => {
      const editEmployeeContentCard = document.getElementById(
        "benefitGroupCard"
      );
      if (editEmployeeContentCard && isMounted.current) {
        editEmployeeContentCard.style.opacity = 1;
      }
    }, 100);
  }

  useEffect(() => {
    fetchAllInitialData();
    return () => {
      isMounted.current = false;
    };
  }, []);

  /**
   * Handles submit of selected tiers for benefit group access.
   * Side effects:
   *  - Returns toast warning if group is active and there are no selected tiers.
   *  - Fires a swal modal box that fires request for available tiers.
   *  - Navigates to benefits page.
   * @param {Event} e
   * @returns {import("react-toastify/dist/types").Toast}
   */
  const handleSubmit = async e => {
    if (isBenefitGroupActive && availableTiers.length === 0) {
      return toast.warn(t("select_tiers_warning"));
    }

    const description =
      benefitGroup.type === PUBLIC && (!isBenefitGroupActive || didDisableTier)
        ? `If you make these changes, users that have been 
        subscribed to the benefits they don't have access to 
        anymore, will be automatically unsubscribed from these benefits and they will not be able to use them during the following month.`
        : "";
    e.preventDefault();
    swal({
      title: "Are you sure you want to save these changes?",
      text: description,
      buttons: true,
      dangerMode: true
    }).then(async value => {
      if (!value) return;
      const response = await api.setAvailableTiers({
        benefitGroupId: match.params.id,
        availableTiers,
        enableInstalments: benefitGroup.enableInstalments
      });
      if (response.hasError) {
        return toast.error(
          response.errorMessage
            ? response.errorMessage
            : t("failed_to_update_benefit_group")
        );
      }
      history.push("/benefits");
      toast.success(response.data.message);
    });
  };

  const handleToggleBenefitGroupInstalments = () => {
    const enabledStatus = !benefitGroup.enableInstalments;
    toggleBenefitGroupInstalments(enabledStatus);
  };

  const openModal = () => {
    history.push(`/benefits/preview/${match.params.id}`);
  };

  const closeModal = () => {
    closeModalAnim();
    setTimeout(() => {
      setPreviewModal(false);
    }, 600);
  };

  return (
    <EditHrBenefitStyles>
      <div className="editHrBenefitsContainer">
        <Header headerTitle={t("benefit_group_management")} />
        {isLoading ? (
          <div
            style={{
              height: "calc(100vh - 180px)",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <BeatLoader
              css={override}
              size={25}
              color="#123abc"
              loading={isLoading}
            />
          </div>
        ) : (
          <div className="editHrBenefitsContent">
            <Card id="benefitGroupCard" padding="0 0 30px 0">
              <form onSubmit={e => handleSubmit(e)}>
                <BasicInfo
                  urlSizeCover="url2x"
                  disableTiersComponent={
                    <BenefitActivitySection
                      onToggle={() => {
                        setAvailableTiers([]);
                        toggleBenefitGroup(!isBenefitGroupActive);
                      }}
                    />
                  }
                />
                <div style={{ padding: "0 30px" }}>
                  <EditBenefitOptions
                    availableTiers={availableTiers}
                    handleChange={handleChange}
                    handleCheckUncheckAll={handleCheckUncheckAll}
                  />
                  <BenefitGroupInstalmentsSection
                    onToggle={() => {
                      handleToggleBenefitGroupInstalments();
                    }}
                    enableInstalments={benefitGroup.enableInstalments}
                  />
                  <div className="buttonsContainer">
                    <div className="submitContainer">
                      <Button
                        type="submit"
                        disabled={tiers.length === 0}
                        margin="0 45px 0 0"
                        width="85px"
                        height="35px"
                      >
                        {t("save")}
                      </Button>
                      <Button
                        outline
                        margin="0"
                        onClick={() => history.push("/benefits")}
                        width="85px"
                        height="35px"
                      >
                        {t("cancel")}
                      </Button>
                    </div>
                    <Button
                      margin="0 0 0 0"
                      width="85px"
                      height="35px"
                      onClick={e => {
                        e.stopPropagation();
                        openModal();
                      }}
                    >
                      {t("preview")}
                    </Button>
                  </div>
                </div>
              </form>
              <BenefitGroupUsersTable benefitGroupId={match.params.id} />
            </Card>

            {previewModal && (
              <Modal closeModal={closeModal}>
                <PreviewModal
                  benefitGroup={benefitGroup}
                  benefitGroupId={+match.params.id}
                />
              </Modal>
            )}
          </div>
        )}
      </div>
    </EditHrBenefitStyles>
  );
};

EditHrBenefit.propTypes = {
  match: PropTypes.shape()
};

const mapStateToProps = state => {
  return {
    benefitGroup: state.benefits.editPageState.benefitGroup,
    isBenefitGroupActive: state.benefits.editPageState.isBenefitGroupActive,
    tiers: state.benefits.editPageState.tiers
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setEditHrBenfitsInitialState: (benefitGroup, tiers) =>
      dispatch(
        actionCreators.setEditHrBenfitsInitialState(benefitGroup, tiers)
      ),
    toggleBenefitGroup: isBenefitGroupActive =>
      dispatch(actionCreators.toggleBenefitGroup(isBenefitGroupActive)),
    toggleBenefitGroupInstalments: enableInstalments =>
      dispatch(actionCreators.toggleBenefitGroupInstalments(enableInstalments))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditHrBenefit);
