import React, { createContext, useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'

import { initProvidersState, providersReducer } from './providers.reducer'
import { useProvidersActions } from './providers.actions'

const ProvidersContext =
	createContext(...initProvidersState)

const ProvidersProvider = ({ children }) => {
	const [providersState, dispatchProviders] =
		useReducer(providersReducer, initProvidersState)
	const providersActions = useProvidersActions(dispatchProviders)

	useEffect(() => { }, [initProvidersState])

	return (
		<ProvidersContext.Provider
			value={{
				providersState,
				providersActions
			}}
		>
			{children}
		</ProvidersContext.Provider>
	)
}

ProvidersProvider.propTypes = {
	children: PropTypes.node
}

export { ProvidersProvider, ProvidersContext }
