import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
// components
import { Header, Card } from '../../../../components'
import Details from '../Form/FormAndDetails'
// styles
import UserDetailsStyles from './UserDetailsStyles'
// services
import { getSingleUser } from '../../../../modules/superAdmin/users/users.services'
// context
import { UsersContext } from '../../../../modules/superAdmin/users/context/users.context'
import { UserContext } from '../../../../modules/user/context/user.context'


const UserDetails = ({ match }) => {
	const {
		usersState: { selectedUser },
		usersActions: { setSingleUser }
	} = useContext(UsersContext)
	const { user } = useContext(UserContext)
	const history = useHistory()

	const fetchUser = async () => {
		const response = await getSingleUser(match.params.id)

		if (response) {
			setSingleUser(response)
		}
	}

	useEffect(() => {
		if (selectedUser.id === null) {
			fetchUser()
		}
	}, [])


	return (
		<UserDetailsStyles>
			<div className="userDetailsContainer">
				<Header
					headerTitle="User Details"
					userFirstName={user.firstName}
					userLastName={user.lastName}
				/>
				<div className="userDetailsContent">
					<Card>
						<Details
							user={selectedUser}
							buttonText="Edit"
							backButtonFunc={() => history.push('/app/users/superAdmin_users')}
						/>
					</Card>
				</div>
			</div>
		</UserDetailsStyles>
	)
}

UserDetails.propTypes = {
	match: PropTypes.shape()
}

export default UserDetails
