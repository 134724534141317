/**
 * Maps plain object to domain model.
 * @param {object} variable - Object to be mapped
 * @param {any} DomainClass - Domain model class
 * @returns {any} - Instace of domain model class
 */
export const mapOneToDomain = (variable, DomainClass) => {
  return variable instanceof DomainClass ? variable : new DomainClass(variable);
};

/**
 * Maps iterable instace of plain objects to domain model.
 * @param {any} iterable - Array, list or any iterable instance of plain objects
 * @param {any} DomainClass - Domain model class
 * @returns {any} - Iterable instance of domain model objects
 */
export const mapManyToDomain = (iterable, DomainClass) => {
  return iterable.map(variable =>
    variable instanceof DomainClass ? variable : new DomainClass(variable)
  );
};
