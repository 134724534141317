import styled from "styled-components";

const UsersListStyles = styled.section`
  .usersSearch {
    width: 100% !important;
  }

  .filterWrapper{
    margin-left:10px;
  }

.buttonsWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-width: 450px;                
  }
  
  .searchIcon {
    background: #F7F9FC;
    padding: 12px 0 8px 10px;
    border-radius: 8px 0 0 8px;
    margin-right: -10px;
    z-index: 2;
  }

  .filterButton {
		display: flex;
		gap: 10px;
    width: 108px;
    padding: 14px 20px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: #1D1D1D;
    cursor: pointer;
    background: transparent;
    border: 1px solid transparent;
    outline: none;
	}

  .appliedFiltersContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;    
    padding: 10px 16px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;

    .appliedFilters {
      display: flex;
      align-items: center;
      gap: 10px;
      overflow-x: auto;
      max-width: 100%;
      flex-wrap: wrap;      

      .appliedFilterPill {
        display: flex;
        align-items: center;
        gap: 19px;
        background-color: #F0F4F9;
        color: #1D1D1D;
        padding: 6px 8px;
        border-radius: 23px;

          .removeFilter {
            cursor: pointer;
          }
      }
    }

    .clearButton {
      width: 80px;
      color: #2F68B9;
      text-align: right;
      background-color: transparent;
      border: 1px solid transparent;
      outline: none;
      cursor: pointer;
    }
  }

  @keyframes showModal {
    from {
      transform: translateX(320px);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  .filtersContainer.mobile {
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-end;
      width: 310px;
      padding: 90px 30px 30px 30px;
      position: fixed;
      top: 0;
      right: 0;
      height: 100%;
      background: rgb(255, 255, 255);
      border-radius: 4px;
      box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px, rgb(0 0 0 / 12%) 0px 1px 10px 0px, 
                  rgb(0 0 0 / 14%) 0px 4px 5px 0px;
      z-index: 2;      
      animation: showModal 0.4s ease-in-out;
      transition: all 0.35s ease-in-out;  
      transition-delay: 0.1s;
      > div {
        width: 100%;
      }
      .closeFiltersButton {
        display: unset;
        width: 40px;
        height: 40px;
        color: rgba(0, 0, 0, 0.54);
        border: none;
        outline: none;
        font-size: 20px;
        position: absolute;
        z-index: 2;
        right: 15px;
        top: 30px;
        font-weight: 500;
        cursor: pointer;
        background: rgba(255, 255, 255, 0.6);
        border-radius: 50%;
        &:hover {
          color: #1568bf;
          background: rgba(255, 255, 255, 0.8);
        }
      }
      .selectsContainer {
      flex-direction: column;
      width: 100%;
      > div {
        width: 100%;
      }      
     }      
    } 

  .companiesFilterWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 30px;;
  }

  .addSign {    
    margin-right: 8px;
    font-size: 28px;
    line-height: 28px;
    font-weight: 400;
  }

  .buttonText {
      font-size: 16px;
      font-weight: 600;
      line-height: 26px;
    }

    #addUserForm {
      position: absolute;
      width: calc(100% - 60px);
      transform: translateX(110%);
      transition: all 0.4s ease-in-out;
      opacity: 0;
    }

    .showAddUserForm {
      transform: translateY(0) !important;
      opacity: 1 !important;
    }

    .hideUsersList {
      transform: translateX(-110%);
      opacity: 0;
    }

  .secondMenu {
    width: 100%
    padding: 8px 20px;

    .flexContainer {
      display: flex;
      align-items: center;
    }

    p {
      font-size: 14px;
      line-height: 16px;
      font-weight: 500;
      color: #1D1D1D;
    }    
    
    .selected {
      width: 90px;
      color: ${props => props.selectedColor};
      margin: 0 11px;
    }  

    .trashButton {
		  gap: 10px;
      width: 119px;
      height: 48px;
      padding: 12px 16px;
      cursor: pointer;
      background: transparent;
      border: 1px solid transparent;
      outline: none;
    }
  }  

  .usersListContainer {
    width: calc(100% - 210px);
    margin-left: 210px;
    background: #f7f7f7;
    min-height: 100vh;
    overflow-x: hidden;

    .usersListContent {
      padding: 0 30px 30px 30px;
      position: relative;
      display: flex;
    }

    .tableHead {
      padding: 14px 30px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #94ABD1;
      border-top: 1px solid #F0F0F0;
    }

    #usersList {
      transition: all 0.4s ease-in-out;
      width: 100%;
    }

    .tableBodyContainer {
      background: #f7f7f7;
      padding: 0 0 30px 0;
      margin: 20px 0 0 0;
      border-radius: 4px;
    }       
  }
`;

export default UsersListStyles;
