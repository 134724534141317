import { findLocales } from "../../utils/helpers/findLocales";

export const validateNotificationForm = ({ subject, message, companies }) => {
  const isInLanguage = findLocales("rs");

  const errors = {};

  if (!subject) {
    errors.subject = isInLanguage ? "*Obavezno polje" : "* Required";
  } else if (subject.length < 5) {
    errors.subject = isInLanguage
      ? "Tema mora imati najmanje 5 karaktera"
      : "Subject must be at least 5 characters long";
  }

  if (!message) {
    errors.message = isInLanguage ? "*Obavezno polje" : "* Required";
  } else if (message.length < 5) {
    errors.message = isInLanguage
      ? "Poruka mora imati najmanje 5 karaktera"
      : "Message must be at least 5 characters long";
  }

  if (!companies) {
    errors.companies = isInLanguage ? "*Obavezno polje" : "* Required";
  } else if (companies.length < 1) {
    errors.companies = isInLanguage
      ? "Primalac mora biti barem 1 kompanija"
      : "Recipient must be at least 1 company";
  }

  return errors;
};
