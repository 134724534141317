import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import styled from "styled-components";

import Form from "./Form";

import { CompanyTag } from "../../../../../../modules/superAdmin/settings/settings.entity";
import { companyTagValidations } from "../../../../../../services/validations/superAdmin/companyTag.validations";
import { SettingsContext } from "../../../../../../modules/superAdmin/settings/context/settings.context";
import * as companyTagsService from "../../../../../../services/api/admin/companyTags.service";

import * as actionCreators from "../../../../../../actions/admin/companyTags.actions";
import { useTranslation } from "react-i18next";

const EditCompanyTagStyles = styled.div`
  padding: 20px;
  width: 400px;

  .title {
    margin-bottom: 20px;
  }
`;

const EditCompanyTag = ({ closeModal, chosenId, editCompanyTag }) => {
  const [chosenTag, setChosenTag] = useState(new CompanyTag());
  const { t } = useTranslation();

  const handleEditBenefitTagError = ({ error }) => {
    const { data } = error;
    if (data.errors && data.error) {
      if (data.errors.name) {
        toast.error(`${data.error.message}: ${data.errors.name.keys[0]}`);
      }
    }
  };

  const updateCompanyTag = async values => {
    const response = await companyTagsService.editCompanyTag(values, chosenId);
    if (response.hasError) {
      return handleEditBenefitTagError(response);
    }

    editCompanyTag(response);
    toast.success(t("success_update_company_tag"));
    closeModal();
  };

  const fetchCompanyTag = async () => {
    const response = await companyTagsService.getCompanyTag(chosenId);
    if (response) {
      setChosenTag(new CompanyTag(response));
    }
  };

  useEffect(() => {
    fetchCompanyTag();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: chosenTag,
    validate: companyTagValidations,
    onSubmit: updateCompanyTag
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur
  } = formik;

  return (
    <EditCompanyTagStyles>
      <h2 className="title">{t("edit_company_tag")}</h2>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Form
          values={values}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          handleChange={handleChange}
          buttonText="Update"
        />
      </form>
    </EditCompanyTagStyles>
  );
};

EditCompanyTag.propTypes = {
  closeModal: PropTypes.func,
  chosenId: PropTypes.number
};

const mapDispatchToProps = dispatch => {
  return {
    editCompanyTag: companyTag =>
      dispatch(actionCreators.editCompanyTag(companyTag))
  };
};

export default connect(undefined, mapDispatchToProps)(EditCompanyTag);
