import SystemNotification from "../../domain/SystemNotification.entity";

class SystemNotificationsResponse {
  constructor(response = {}) {
    this.notifications = response.data.items?.map(
      notification => new SystemNotification(notification)
    );
  }
}

export default SystemNotificationsResponse;
