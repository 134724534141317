class CompanyUsage {
  constructor(usage = {}) {
    this.employeeNotOnPlatform =
      usage.employeeNotOnPlatform || usage.employee_not_on_platform || 0;
    this.employeeOnPlatform =
      usage.employeeOnPlatform || usage.employee_on_platform || 0;
    this.totalEmployee = usage.totalEmployee || usage.total_employee || 0;
    this.totalHr = usage.totalHr || usage.total_hr || 0;
    this.totalUsers = usage.totalUsers || usage.total_users || 0;
  }
}

export default CompanyUsage;
