import React from 'react'

const UserDisabledIconSvg = ({
  width = '14',
  height = '14'
}) => (
  <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.6 12.2C7.35696 12.2 8.07349 12.053 8.74961 11.7591C9.42572 11.4651 10.021 11.0609 10.5354 10.5465C11.0572 10.032 11.4651 9.43675 11.7591 8.76063C12.053 8.08452 12.2 7.3643 12.2 6.6C12.2 5.82835 12.053 5.10446 11.7591 4.42835C11.4724 3.75223 11.0719 3.15696 10.5575 2.64252C10.043 2.12808 9.44777 1.72756 8.77165 1.44094C8.09554 1.14698 7.37165 1 6.6 1C5.8357 1 5.11549 1.14698 4.43937 1.44094C3.76325 1.72756 3.16798 2.13176 2.65354 2.65354C2.13911 3.16798 1.73491 3.76325 1.44094 4.43937C1.14698 5.10814 1 5.82835 1 6.6C1 7.37165 1.14698 8.09554 1.44094 8.77165C1.73491 9.44777 2.13911 10.043 2.65354 10.5575C3.16798 11.0719 3.76325 11.4724 4.43937 11.7591C5.11549 12.053 5.8357 12.2 6.6 12.2ZM3.75591 7.27244C3.57218 7.27244 3.41417 7.20263 3.28189 7.06299C3.14961 6.93071 3.08346 6.77638 3.08346 6.6C3.08346 6.42362 3.14961 6.26562 3.28189 6.12598C3.41417 5.9937 3.57218 5.92756 3.75591 5.92756H9.42205C9.60577 5.92756 9.76378 5.9937 9.89606 6.12598C10.0357 6.26562 10.1055 6.42362 10.1055 6.6C10.1055 6.77638 10.0357 6.93071 9.89606 7.06299C9.76378 7.20263 9.60577 7.27244 9.42205 7.27244H3.75591Z" fill="#C53C3C" />
    <path fillRule="evenodd" clipRule="evenodd" d="M9.89606 7.06299C10.0357 6.93071 10.1055 6.77638 10.1055 6.6C10.1055 6.42362 10.0357 6.26562 9.89606 6.12598C9.76378 5.9937 9.60577 5.92756 9.42205 5.92756H3.75591C3.57218 5.92756 3.41417 5.9937 3.28189 6.12598C3.14961 6.26562 3.08346 6.42362 3.08346 6.6C3.08346 6.77638 3.14961 6.93071 3.28189 7.06299C3.41417 7.20263 3.57218 7.27244 3.75591 7.27244H9.42205C9.60577 7.27244 9.76378 7.20263 9.89606 7.06299ZM9.14833 12.6761C8.343 13.0263 7.49039 13.2 6.6 13.2C5.70519 13.2 4.85015 13.0274 4.0448 12.6779C3.25323 12.3417 2.55155 11.8697 1.94644 11.2646C1.34208 10.6602 0.867177 9.95997 0.523875 9.17038C0.172665 8.3626 0 7.50231 0 6.6C0 5.69845 0.172391 4.84097 0.524662 4.03883C0.867428 3.25107 1.34123 2.55226 1.94393 1.94894C2.54887 1.33601 3.25129 0.859079 4.04486 0.522043C4.8502 0.172591 5.70522 0 6.6 0C7.50074 0 8.3596 0.172064 9.16616 0.52204C9.95776 0.858228 10.6595 1.33028 11.2646 1.93541C11.8697 2.54053 12.3417 3.24221 12.6779 4.03378C13.0279 4.84036 13.2 5.69924 13.2 6.6C13.2 7.49635 13.0268 8.35279 12.6761 9.15936C12.332 9.95089 11.8522 10.6519 11.24 11.2561C10.6362 11.8592 9.93683 12.3333 9.14833 12.6761ZM10.5354 10.5465C10.021 11.0609 9.42572 11.4651 8.74961 11.7591C8.07349 12.053 7.35696 12.2 6.6 12.2C5.8357 12.2 5.11549 12.053 4.43937 11.7591C3.76325 11.4724 3.16798 11.0719 2.65354 10.5575C2.13911 10.043 1.73491 9.44777 1.44094 8.77165C1.14698 8.09554 1 7.37165 1 6.6C1 5.82835 1.14698 5.10814 1.44094 4.43937C1.73491 3.76325 2.13911 3.16798 2.65354 2.65354C3.16798 2.13176 3.76325 1.72756 4.43937 1.44094C5.11549 1.14698 5.8357 1 6.6 1C7.37165 1 8.09554 1.14698 8.77165 1.44094C9.44777 1.72756 10.043 2.12808 10.5575 2.64252C11.0719 3.15696 11.4724 3.75223 11.7591 4.42835C12.053 5.10446 12.2 5.82835 12.2 6.6C12.2 7.3643 12.053 8.08452 11.7591 8.76063C11.4651 9.43675 11.0572 10.032 10.5354 10.5465Z" fill="#F7F7F7" />
  </svg>
)

export default UserDisabledIconSvg;
