const expandSection = element => {
  const sectionHeight = element.scrollHeight;
  element.style.height = `${sectionHeight}px`;
};

const addHeightOnClick = element => {
  expandSection(element);
};

export { addHeightOnClick };
