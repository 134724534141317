import { toast } from "react-toastify";
import createSupaBaseStorageFolder from "../createSupaBaseStorageFolder";
import supabaseClient from "../supabaseClient";

const bucketsSwap = async ({ oldStatus, status, fileName, companyId }) => {
  const folderName = createSupaBaseStorageFolder(companyId);

  const folderRefundPending = `${folderName}_pending_refund`;
  const acceptedRefundFolder = folderName;

  try {
    const isTheFileInTheSameBucket = oldStatus === null && status === false;
    if (isTheFileInTheSameBucket) return;

    const { error: moveError } = await supabaseClient.storage
      .from("refund-upload")
      .move(
        `${status ? folderRefundPending : acceptedRefundFolder}/${fileName}`,
        `${status ? acceptedRefundFolder : folderRefundPending}/${fileName}`
      );

    if (moveError) throw moveError;
  } catch (error) {
    toast.error(error.message);
  }
};

export default bucketsSwap;
