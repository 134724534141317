import { BenefitGroup } from "../../domain";

class SubscribedBenefitGroupsResponse {
  constructor(response = {}) {
    this.groups = response.data.items.map(
      benefitGroup => new BenefitGroup(benefitGroup)
    );
  }
}

export default SubscribedBenefitGroupsResponse;
