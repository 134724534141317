import React, { useMemo, useRef } from "react";
// Components
import BudgetBreakdownDropdownStyles from "./BudgetBreakdownDropdown.styles";
import { TokenIcon } from "../../../assets/Icons";
// Hooks
import useClickOutside from "../../../hooks/useClickOutside";
import { useTranslation } from "react-i18next";
import {
  transformTokenCurrencyIcons,
  transformTokensToRsd
} from "../../../utils/transformTokensToRsd";

const BudgetBreakdownDropdown = ({
  user,
  budgetBreakdown,
  setIsBreakdownOpen
}) => {
  const { t } = useTranslation();
  const budgetBreakdownTooltip = useRef(null);
  useClickOutside([budgetBreakdownTooltip], () => setIsBreakdownOpen(false));

  const totalSpent = useMemo(
    () => user.availableTokens - user.remainingTokens,
    [user.remainingTokens]
  );

  return (
    <BudgetBreakdownDropdownStyles>
      <div id="tooltip" className="budgetBreakdown">
        <div className="budgetBreakdownHeader">
          <div className="budgetLabel">{t("Budget")}</div>
          <div className="budget">
            {transformTokensToRsd(user.remainingTokens)}/
            {transformTokensToRsd(user.totalTokens)}{" "}
            {transformTokenCurrencyIcons()}
          </div>
        </div>
        <div className="budgetBreakdownContent">
          <div className="contentWrapper">
            <div className="breakdownLabel">{t("sub_monthly")}</div>
            <div className="breakdownContent">
              <span className="amount">
                {transformTokensToRsd(budgetBreakdown.monthlySpent)}
              </span>{" "}
              {transformTokenCurrencyIcons()}
            </div>
          </div>
          <div className="contentWrapper">
            <div className="breakdownLabel">{t("sub_one_time")}</div>
            <div className="breakdownContent">
              <span className="amount">
                {transformTokensToRsd(budgetBreakdown.oneTimeSpent)}
              </span>{" "}
              {transformTokenCurrencyIcons()}
            </div>
          </div>
          <div className="contentWrapper">
            <div className="breakdownLabel">{t("sub_vouchers")}</div>
            <div className="breakdownContent">
              <span className="amount">
                {transformTokensToRsd(budgetBreakdown.vouchersSpent)}
              </span>{" "}
              {transformTokenCurrencyIcons()}
            </div>
          </div>
          <div className="contentWrapper">
            <div className="breakdownLabel">{t("Installments")}</div>
            <div className="breakdownContent">
              <span className="amount">
                {transformTokensToRsd(budgetBreakdown.instalmentsSpent)}
              </span>
              {transformTokenCurrencyIcons()}
            </div>
          </div>
          <div className="contentWrapper">
            <div className="breakdownLabel">MCP</div>
            <div className="breakdownContent">
              <span className="amount">
                {transformTokensToRsd(budgetBreakdown.mcpSpent)}
              </span>
              {transformTokenCurrencyIcons()}
            </div>
          </div>
          <hr className="divider" />
          <div className="totalSpentWrapper">
            <div className="totalSpentContent">
              <span className="totalSpentLabel">{t("total_spent")}</span>{" "}
              <span className="amount">{transformTokensToRsd(totalSpent)}</span>
              {transformTokenCurrencyIcons()}
            </div>
          </div>
        </div>
      </div>
    </BudgetBreakdownDropdownStyles>
  );
};

export default BudgetBreakdownDropdown;
