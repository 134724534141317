import { BenefitGroup, Provider, Benefit } from '../providers.entity'

const initProvidersState = []

const actionTypes = {
	setInitProviders: 'SET_INITAL_PROVIDERS',
	addProvider: 'ADD_PROVIDER',
	editProvider: 'EDIT_PROVIDER',
	removeProvider: 'REMOVE_PROVIDER',
	addBenefitGroup: 'ADD_BENEFIT_GROUP',
	editBenefitGroup: 'EDIT_BENEFIT_GROUP',
	removeBenefitGroup: 'REMOVE_BENEFIT_GROUP',
	addBenefit: 'ADD_BENEFIT',
	editBenefit: 'EDIT_BENEFIT',
	removeBenefit: 'REMOVE_BENEFIT'
}

const providersReducer = (state = initProvidersState, action) => {
	switch (action.type) {
		case actionTypes.setInitProviders:
			return action.payload.map(provider => new Provider(provider))
		case actionTypes.addProvider:
			return [...state, new Provider(action.payload)]
		case actionTypes.editProvider:
			return state.map(provider => (provider.id === action.providerId ?
				new Provider(action.payload) : provider))
		case actionTypes.removeProvider:
			return state.filter(provider => provider.id !== action.providerId)
		case actionTypes.addBenefitGroup:
			return state.map(provider => (provider.id === action.providerId ?
				{
					...provider,
					benefitGroups: [...provider.benefitGroups, new BenefitGroup(action.payload)]
				}
				:
				provider)
			)
		case actionTypes.editBenefitGroup:
			return state.map(provider => (provider.id === action.providerId ?
				{
					...provider,
					benefitGroups: provider.benefitGroups.map(benfitGroup =>
						(benfitGroup.id === action.benefitGroupId ?
							new BenefitGroup(action.payload)
							:
							benfitGroup)
					)
				}
				:
				provider)
			)
		case actionTypes.removeBenefitGroup:
			return state.map(provider => (provider.id === action.providerId ?
				{
					...provider,
					benefitGroups: provider.benefitGroups
						.filter(benefitGroup => benefitGroup.id !== action.benefitGroupId)
				}
				:
				provider)
			)
		case actionTypes.addBenefit:
			return state.map(provider => (provider.id === action.providerId ?
				{
					...provider,
					benefitGroups: provider.benefitGroups.map(benefitGroup =>
						(benefitGroup.id === action.benefitGroupId ?
							{
								...benefitGroup,
								benefits: [...benefitGroup.benefits, new Benefit(action.payload)]
							}
							:
							benefitGroup
						)
					)
				}
				:
				provider
			))
		case actionTypes.editBenefit:
			return state.map(provider => (provider.id === action.providerId ?
				{
					...provider,
					benefitGroups: provider.benefitGroups.map(benefitGroup =>
						(benefitGroup.id === action.benefitGroupId ?
							{
								...benefitGroup,
								benefits: benefitGroup.benefits.map(benefit => (benefit.id === action.benefitId ?
									new Benefit(action.payload)
									:
									benefit)
								)
							}
							:
							benefitGroup
						)
					)
				}
				:
				provider
			))
		case actionTypes.removeBenefit:
			return state.map(provider => (provider.id === action.providerId ?
				{
					...provider,
					benefitGroups: provider.benefitGroups.map(benefitGroup =>
						(benefitGroup.id === action.benefitGroupId ?
							{
								...benefitGroup,
								benefits: benefitGroup.benefits.filter(benefit => benefit.id !== action.benefitId)
							}
							:
							benefitGroup)
					)
				}
				:
				provider)
			)
		default:
			throw new Error(`Unknow action type: ${action.type}`)
	}
}


export { initProvidersState, actionTypes, providersReducer }
