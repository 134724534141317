import React from 'react';

const FilterIconSvg = () => (
  <svg 
    width="16" 
    height="18" 
    viewBox="0 0 16 18" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      d="M11.9999 16.88C12.0399 17.18 11.9399 17.5 11.7099 17.71C11.3199 18.1 10.6899 18.1 10.2999 17.71L6.28986 13.7C6.05986 13.47 5.95986 13.16 5.99986 12.87V7.75L1.20986 1.62C0.869858 1.19 0.949858 0.56 1.37986 0.22C1.56986 0.08 1.77986 0 1.99986 0H15.9999C16.2199 0 16.4299 0.08 16.6199 0.22C17.0499 0.56 17.1299 1.19 16.7899 1.62L11.9999 7.75V16.88ZM4.03986 2L7.99986 7.06V12.58L9.99986 14.58V7.05L13.9599 2H4.03986Z" 
      fill="#668CCC"
    />
  </svg>
)

export default FilterIconSvg;