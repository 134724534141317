const initialCompanyTagsSectionState = {
	isAddTagOpen: false,
	tagObj: undefined
}

const companyTagsSectionActionsTypes = {
	openAddTagInput: 'OPEN_ADD_TAG_INPUT',
	handleTagChange: 'HANDLE_TAG_CHANGE',
	closeAddTagInput: 'CLOSE_ADD_INPUT_TAG'
}

const companyTagsSectionReducer = (state = initialCompanyTagsSectionState, action) => {
	switch (action.type) {
		case companyTagsSectionActionsTypes.openAddTagInput:
			return {
				...state,
				isAddTagOpen: true
			}
		case companyTagsSectionActionsTypes.handleTagChange:
			return {
				...state,
				tagObj: action.tag
			}
		case companyTagsSectionActionsTypes.closeAddTagInput:
			return {
				...state,
				isAddTagOpen: false
			}
		default:
			throw new Error(`Unknow action type: ${action.type}`)
	}
}

export { initialCompanyTagsSectionState, companyTagsSectionReducer, companyTagsSectionActionsTypes }
