// services
import { apiService } from "../api.service";
// global handlers
import { isEmpty } from "../../general.utils";
// response modals
import {
  BenefitGroupsResponse,
  BenefitsResponse,
  CategoriesResponse,
  SubscribedBenefitGroupsResponse
} from "../../../models/server/responses";
// payload modals
import {
  CreateBenefitGroupReviewPayload,
  SubscribeUnsubscribePayload,
  UploadPDFPayload,
  VoucherSubscribeUnsubscribePayload
} from "../../../models/server/payloads";
import { PUBLIC } from "../../../builders/benefitGroups/benefitGroup.types";
import sendImageToSupabase from "../../../utils/supabaseUtils/sendImageToSupabase";

const LIMIT = 10;

export const getBenefitGroups = async ({
  chosenCategories,
  priceRange,
  chosenCities,
  chosenBenefitExpirationTypes,
  limit = LIMIT,
  page,
  remoteFilter = false,
  type = PUBLIC,
  search,
  order,
  key
}) => {
  let queryParams = "";
  let categoriesQuery = "";
  let citiesQuery = "";
  let benefitExpirationTypesQuery = "";

  if (limit) {
    queryParams += `limit=${limit}`;
  }

  if (page) {
    queryParams += `${!isEmpty(queryParams) ? "&" : ""}page=${page}`;
  }

  if (key && order) {
    queryParams += `${
      !isEmpty(key) && !isEmpty(order) ? "&" : ""
    }order=${key} ${order}`;
  }

  if (priceRange && typeof priceRange === "object") {
    queryParams += `${!isEmpty(queryParams) ? "&" : ""}price_from=${
      priceRange.min
    }${priceRange.max < 1000 ? `&price_to=${priceRange.max}` : ""}`;
  }

  if (!isEmpty(chosenCategories) && Array.isArray(chosenCategories)) {
    categoriesQuery = chosenCategories.reduce(
      (query, category) =>
        `${!isEmpty(query) ? `${query}&` : ""}category_id=${category}`,
      ""
    );
  }

  if (!isEmpty(chosenCities) && Array.isArray(chosenCities)) {
    citiesQuery = chosenCities
      .filter(id => id !== 0)
      .reduce(
        (query, city) => `${!isEmpty(query) ? `${query}&` : ""}city_id=${city}`,
        ""
      );
  }

  if (
    !isEmpty(chosenBenefitExpirationTypes) &&
    Array.isArray(chosenBenefitExpirationTypes)
  ) {
    benefitExpirationTypesQuery = chosenBenefitExpirationTypes.reduce(
      (query, benefitExpirationType) =>
        `${
          !isEmpty(query) ? `${query}&` : ""
        }expiration=${benefitExpirationType}`,
      ""
    );
  }

  if (!isEmpty(categoriesQuery)) {
    queryParams += `${!isEmpty(queryParams) && "&"}${categoriesQuery}`;
  }

  if (isEmpty(citiesQuery) && !remoteFilter) {
    queryParams += `${!isEmpty(queryParams) && "&"}${citiesQuery}`;
  }

  if (!isEmpty(citiesQuery) && !remoteFilter) {
    queryParams += `${!isEmpty(queryParams) &&
      "&"}${citiesQuery}&is_remote=${remoteFilter}`;
  }

  if (isEmpty(citiesQuery) && remoteFilter) {
    queryParams += `&is_remote=${remoteFilter}`;
  }

  if (!isEmpty(citiesQuery) && remoteFilter) {
    queryParams += `${!isEmpty(queryParams) &&
      "&"}${citiesQuery}&is_remote=${remoteFilter}`;
  }

  if (!isEmpty(benefitExpirationTypesQuery)) {
    queryParams += `${!isEmpty(queryParams) &&
      "&"}${benefitExpirationTypesQuery}`;
  }

  if (!isEmpty(search)) {
    queryParams += `${!isEmpty(queryParams) && "&"}search=${search}`;
  }

  if (!isEmpty(type)) {
    queryParams += `${!isEmpty(queryParams) && "&"}type=${type}`;
  }

  const response = await apiService.get(
    `/benefit-group/employee-benefits?${queryParams}`
  );
  if (response.hasError) return response;
  return new BenefitGroupsResponse(response);
};

export const getCategories = async () => {
  const response = await apiService.get("/benefit-group/categories");
  if (response.hasError) return response;
  return new CategoriesResponse(response);
};

export const getCategoriesForEmployeePlatform = async () => {
  const response = await apiService.get("/benefit-group/employee/categories");
  if (response.hasError) return response;
  return new CategoriesResponse(response);
};

export const getSubscribedBenefits = async () => {
  const response = await apiService.get("/benefit-group/used");
  if (response.hasError) return response;
  return new SubscribedBenefitGroupsResponse(response);
};

export const getAlreadyUsedBenefits = async id => {
  const response = await apiService.get("/employee/used-benefits/" + id);
  if (response.hasError) return response;
  return new BenefitsResponse(response);
};

export const createBenefitGroupReview = async values => {
  const payload = new CreateBenefitGroupReviewPayload(values);
  const response = await apiService.post(
    "benefit-group/review/create",
    payload
  );
  return response;
};

export const finishOnboarding = async () => {
  const response = await apiService.patch("/users/employee/onboarding");
  return response;
};

export const subscribeOrUnsubscribeToBenefit = async values => {
  const payload = new SubscribeUnsubscribePayload(values);
  const response = await apiService.post(
    "/employee/benefit-subscribe",
    payload
  );
  return response;
};

export const subscribeOrUnsubscribeToVoucher = async values => {
  const payload = new VoucherSubscribeUnsubscribePayload(values);
  const response = await apiService.post(
    "/employee/benefit-subscribe",
    payload
  );
  return response;
};

export const syncEmployeeUser = async () => {
  const response = await apiService.post("/employee/sync");
  return response;
};

export const getEmployeeTokenCount = async () => {
  return await apiService.get("/employee/token-count");
};

export const uploadPDF = async (values, companyId) => {
  const payloadData = new UploadPDFPayload(values);

  try {
    const response = await apiService.post(
      "/employee/upload",
      payloadData.payload
    );

    sendImageToSupabase(response.data.content, values.pdfFile, companyId);
  } catch (error) {
    toast.error(response.error.data.error.message);
  }
};

export const getBenefitsForBenefitGroup = async (
  benefitGroupId,
  priceRange,
  chosenBenefitExpirationTypes
) => {
  let queryParams = "";
  let benefitExpirationTypesQuery = "";

  if (priceRange && typeof priceRange === "object") {
    queryParams += `${!isEmpty(queryParams) ? "&" : ""}price_from=${
      priceRange.min
    }${priceRange.max < 1000 ? `&price_to=${priceRange.max}` : ""}`;
  }

  if (
    !isEmpty(chosenBenefitExpirationTypes) &&
    Array.isArray(chosenBenefitExpirationTypes)
  ) {
    benefitExpirationTypesQuery = chosenBenefitExpirationTypes.reduce(
      (query, benefitExpirationType) =>
        `${
          !isEmpty(query) ? `${query}&` : ""
        }expiration=${benefitExpirationType}`,
      ""
    );
  }

  if (!isEmpty(benefitExpirationTypesQuery)) {
    queryParams += `${!isEmpty(queryParams) &&
      "&"}${benefitExpirationTypesQuery}`;
  }

  const response = await apiService.get(
    `/benefit/benefit-group/${benefitGroupId}?${queryParams}`
  );
  if (response.hasError) return response;
  return new BenefitsResponse(response);
};
