import React, {
  useContext,
  useState,
  useReducer,
  useEffect,
  useRef
} from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css";
// components
import {
  Header,
  Card,
  Button,
  Table,
  TableHead,
  TableRow,
  SearchInput,
  CompaniesListItem
} from "../../../../components";
import AddCompany from "../AddCompany";
// builders
import { tableHeaderReducer, tableHeadConfig } from "./builder";
// styles
import CompaniesStyles from "./CompaniesStyles";
import { override } from "../../../../services/loadingOverride.styles";
// context
import { CompaniesContext } from "../../../../modules/superAdmin/companies/context/companies.context";
import { UserContext } from "../../../../modules/user/context/user.context";
// services
import { getOrderColumns } from "../../../../services/getOrderColumns";
import { openAddCompany } from "../companies.services";
import * as companiesService from "../../../../services/api/admin/companies.service";
import * as actionCreators from "../../../../actions/admin/companies.actions";
import { isEmpty } from "../../../../services/general.utils";
import { useTranslation } from "react-i18next";
// configs
const LIMIT = 35;

const Companies = ({
  setPage,
  page,
  count,
  onSearchChange,
  search,
  setCompanies,
  deleteCompany,
  companies
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [isAddCompanyOpen, setIsAddCompanyOpen] = useState(false);
  const [tableHeaders, dispatch] = useReducer(
    tableHeaderReducer,
    tableHeadConfig
  );
  const isMounted = useRef(true);
  const history = useHistory();

  const fetchData = async (searchText = search) => {
    const response = await companiesService.getCompanies({
      limit: LIMIT,
      page,
      searchText,
      orderColumns: getOrderColumns(tableHeaders)
    });

    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : t("failed_to_get_companies")
      );
    }

    if (isMounted.current) {
      setCompanies(response.companies, response.count);
      setIsLoading(false);
      setTimeout(() => {
        const companiesList = document.getElementById("companiesList");
        if (isMounted.current && companiesList) {
          companiesList.style.opacity = 1;
        }
      }, 100);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, tableHeaders]);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [companies.length]);

  const toggleSortFunc = id => dispatch({ type: id });

  return (
    <CompaniesStyles>
      <div className="companiesContainer">
        <Header
          headerTitle={isAddCompanyOpen ? t("add_company") : t("Companies")}
        />
        <div className="companiesContent">
          {isLoading ? (
            <div
              style={{
                height: "calc(100vh - 180px)",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <BeatLoader
                css={override}
                size={25}
                color="#123abc"
                loading={isLoading}
              />
            </div>
          ) : (
            <Card id="companiesList" padding="0 0 30px 0">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "30px"
                }}
              >
                <SearchInput
                  fetchData={fetchData}
                  setSearch={onSearchChange}
                  search={search}
                  setPagginationPage={setPage}
                  searchWrapperWidth="50%"
                />
                <Button
                  margin="0"
                  onClick={() => openAddCompany(setIsAddCompanyOpen)}
                >
                  <div className="plusContainer">
                    <p className="buttonText">+</p>
                  </div>
                  {t("add_company")}
                </Button>
              </div>
              <div className="tableHead">
                {tableHeaders.map(
                  ({
                    id,
                    headName,
                    isSortable,
                    isSortedUp,
                    width,
                    justifyContent,
                    margin
                  }) => (
                    <TableHead
                      key={id}
                      id={id}
                      tableHeadColName={t(headName)}
                      isSortedUp={isSortedUp}
                      isSortable={isSortable}
                      colWidth={width}
                      justifyContent={justifyContent}
                      margin={margin}
                      toggleSortFunc={toggleSortFunc}
                    />
                  )
                )}
              </div>

              <div className="usersListWrapper">
                {!isEmpty(companies) ? (
                  companies.map(
                    ({
                      id,
                      name,
                      pib,
                      paymentDeadline,
                      invoiceEmail,
                      idNumber,
                      address,
                      contact,
                      tags
                    }) => (
                      <CompaniesListItem
                        key={id}
                        isFirstLetter
                        companyId={id}
                        companyName={name}
                        pib={pib}
                        paymendDeadline={paymentDeadline}
                        invoiceEmail={invoiceEmail}
                        removeRow={deleteCompany}
                        fetchData={fetchData}
                        idNumber={idNumber}
                        address={address}
                        contact={contact}
                        tags={tags}
                      />
                    )
                  )
                ) : (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    No results found
                  </div>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 10
                }}
              >
                <Pagination
                  currentPage={page}
                  totalSize={count}
                  sizePerPage={LIMIT}
                  changeCurrentPage={page => setPage(page)}
                  theme="border-bottom"
                />
              </div>
            </Card>
          )}
          {isAddCompanyOpen && (
            <div id="addCompanyForm">
              <AddCompany setIsAddCompanyOpen={setIsAddCompanyOpen} />
              <div style={{ height: 30 }} />
            </div>
          )}
        </div>
      </div>
    </CompaniesStyles>
  );
};

const mapStateToProps = state => {
  return {
    companies: state.companies.companies,
    page: state.companies.page,
    count: state.companies.count,
    search: state.companies.search
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCompanies: (companies, count) =>
      dispatch(actionCreators.setCompanies(companies, count)),
    deleteCompany: id => dispatch(actionCreators.deleteCompany(id)),
    setPage: page => dispatch(actionCreators.setPage(page)),
    onSearchChange: search => dispatch(actionCreators.onSearchChange(search))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Companies);
