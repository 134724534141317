import { tableHeaderListHREmployees } from '../pages/HrPages/EmployeePages/Employees/builderResponsive';

/**Is the type between the limits
 * @param {Number} a - Left border.
 * @param {Number} b - Right border.
 * @param {Number} type - The action  to apply.
 * @returns {Boolean} - True if type is between borders. 
 */
const between = (a, b, type) => type > a && type <= b;

/**Switch state by an action.
 * @param {Number} type - The action type to apply (screen width || column id ).
 * @param {Array} state - An array of objects.
 * @returns {Array} - An array of active objects. 
 */
export const getTableHeadHREmployees = (type, state = []) => {
  switch(true) {
    case between(1280, 6000, type): // screen width
    return tableHeaderListHREmployees[0];
    case between(960, 1280, type): // screen width
    return tableHeaderListHREmployees[1];
    case between(766, 960, type): // screen width
    return tableHeaderListHREmployees[2];
    case between(10, 766, type): // screen width
    return tableHeaderListHREmployees[0];
    case between(0, 10, type): // column id
    return state;
    case (type === "unsort"): // unset sorted column
    return state;
    default:    
    throw new Error();     			
  };
};
