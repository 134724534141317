class CategoryBudget {
  constructor(budgetData = {}) {
    this.actualCategoryBudget =
      budgetData.actualCategoryBudget ||
      budgetData.actual_category_budget ||
      [];
    this.lastCategoryBudget =
      budgetData.lastCategoryBudget || budgetData.last_category_budget || [];
  }
}

export default CategoryBudget;
