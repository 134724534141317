import styled from "styled-components";

const HrDashboardStyles = styled.section`
  width: calc(100% - 210px);
  margin-left: 210px;
  background: whitesmoke;
  min-height: 100vh;

  .dashboardContainer {
    padding: 0 30px 30px 30px;
  }

  #dashboarsContainer {
    opacity: 0;
    transition: all 0.4s ease-in-out;
  }

  .graphsContainer {
    display: flex;
    align-items: flex-start;
    width: 1350px;
  }

  .titleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }

  .title {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4b4b4b;
    text-transform: uppercase;
    text-align: center;
  }

  .datePickerInput {
    height: 24px;
    width: 100px;
    outline: none;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    border: 1px solid #d8d8d8;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: center;
    color: #4b4b4b;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    z-index: 1;

    &:hover {
      border: 1px solid #1568bf;
      z-index: 2;
    }
  }

  input[type="date"]::-webkit-clear-button {
    display: none;
    -webkit-appearance: none;
  }

  input[type="date"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: none;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    -webkit-appearance: none;
    position: absolute;
    left: 5px;
    width: 5px;
    color: rgba(0, 0, 0, 0);
    opacity: 0;
    cursor: pointer;
  }

  .description {
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4b4b4b;
  }

  @media screen and (max-width: 1626px) {
    .graphsContainer {
      width: 1000px;
    }
  }
`;

export default HrDashboardStyles;
