import React from "react";
import ActivityLog from "../../../assets/images/activity-log.png";
import { useTranslation } from "react-i18next";

export const EmptyHistory = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: 460
      }}
    >
      <img src={ActivityLog} alt="History placeholder" />
      <p className="text">{t("user_did_not_choose")}</p>
    </div>
  );
};
