import React, { useState } from "react";
import ToggleSliderStyles from "./ToggleSliderStyles";

const ToggleSlider = props => {
  const { isChecked = true, onClick } = props;

  return (
    <ToggleSliderStyles>
      <div className="toggle-slider">
        <input
          type="checkbox"
          id="toggle"
          checked={isChecked}
          onChange={() => onClick(!isChecked)}
        />
        <label htmlFor="toggle" className="slider" />
      </div>
    </ToggleSliderStyles>
  );
};

export default ToggleSlider;
