import { User, Company, NewUser } from '../users.entity'

const initalUsersState = {
	companies: [new Company()],
	newUser: new NewUser(),
	selectedUser: new User(),
	users: [new User()]
}

const actionTypes = {
	setUsers: 'SET_USERS',
	setCompanies: 'SET_COMPANIES',
	handleChange: 'SET_HANDLE_CHANGE',
	handleAddUser: 'SET_ADD_USER',
	selectedUser: 'SELECTED_USER',
	setSingleUser: 'SET_SINGLE_USER'
}

const usersReducer = (state = initalUsersState, action) => {
	switch (action.type) {
		case actionTypes.setUsers:
			return {
				...state,
				users: action.payload.map(user => new User(user))
			}
		case actionTypes.setCompanies:
			return {
				...state,
				companies: action.payload.map(company => new Company(company))
			}
		case actionTypes.handleChange:
			return {
				...state,
				newUser: {
					...state.newUser,
					[action.name]: action.value
				}
			}
		case actionTypes.handleAddUser:
			return {
				...state,
				newUser: new NewUser(),
				users: [...state.users, new User(action.payload)]
			}
		case actionTypes.selectedUser:
			return {
				...state,
				selectedUser: state.users.find(user => user.id === action.chosenId)
			}
		case actionTypes.setSingleUser:
			return {
				...state,
				selectedUser: new User(action.payload)
			}
		default:
			throw new Error(`Unknow action type: ${action.type}`)
	}
}

export { actionTypes, initalUsersState, usersReducer }
