import * as actionTypes from "../../constants/actionTypes/employee/employeeBenefits.constants";
import { updateObject } from "../../services/updateObject";
import { priceRangeOptions } from "../../builders/employee/priceRangeOptions.config";
import { PriceRange } from "../../models/domain";
import { PUBLIC } from "../../builders/benefitGroups/benefitGroup.types";

const initialState = {
  benefitsBrowse: {
    benefitGroups: [],
    benefitGroupsPage: 1,
    benefitGroupsCount: 0,
    benefitGroupsTotalPages: 0
  },
  benefitsCompany: {
    benefitGroups: [],
    benefitGroupsPage: 1,
    benefitGroupsCount: 0,
    benefitGroupsTotalPages: 0
  },
  subscribedBenefits: {
    subscribedBenefitGroups: [],
    isConditionAllBenefits: true,
    isNextMonthTooltipOpen: false
  },
  favoriteBenefits: {
    listOfFavoriteBenefits: [],
    listOfFavoriteBenefitsInObject: {},
    isInFavorites: false
  },
  filters: {
    categories: [],
    chosenCategoryIds: [],
    appliedChosenCategoryIds: [],
    cityIds: [],
    expirations: [],
    isRemote: false,
    chosenPriceRange: new PriceRange(),
    appliedChosenPriceRange: new PriceRange(),
    search: "",
    order: "",
    key: "",
    isApplyFilterClicked: false
  },
  pageFrontEndStates: {
    isLoading: true,
    isPageLoading: false,
    isSubscribedBenefitGroupsVisible: false,
    isFavoriteTab: false,
    benefitGroupType: PUBLIC,
    isModalOpen: false,
    chosenBenefitGroup: null
  },
  usedBenefits: [],
  socialProof: {
    subscribedToSingleBenefitGroup: {},
    currentlyPopularBenefit: {}
  }
};

const employeeBenefitsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_INITAL_STATE:
      return employeeBenefitsSetInitalState(state, action);
    case actionTypes.UPDATE_BENEFIT_GROUP:
      return updateBenefitGroup(state, action);
    case actionTypes.SET_IS_SUBSCRIBED_BENEFIT_GROUPS_VISIBLE:
      return setIsSubscribedBenefitGroupsVisible(state, action);
    case actionTypes.SET_BENEFIT_GROUP_TYPE:
      return setBenefitGroupType(state, action);
    case actionTypes.SET_IS_INITIAL_STATE_SET:
      return setIsInitialStateSet(state, action);
    case actionTypes.SET_IS_PAGE_LOADING:
      return setIsPageLoading(state, action);
    case actionTypes.SET_CHOSEN_CATEGORY_IDS:
      return setChosenCategoryIds(state, action);
    case actionTypes.SET_CITY_IDS_FILTERS:
      return setCitiesFilter(state, action);
    case actionTypes.SET_REMOTE_FILTER:
      return setRemoteFilter(state, action);
    case actionTypes.SET_FILTERS_PRICE_RANGE:
      return setFiltersPriceRange(state, action);
    case actionTypes.SET_BENEFIT_EXPIRATION_TYPES_FILTERS:
      return setBenefitExpirationTypesFilter(state, action);
    case actionTypes.SET_SEARCH_FILTERS:
      return setSearchFilter(state, action);
    case actionTypes.SET_ORDER_SORT:
      return setOrderSort(state, action);
    case actionTypes.SET_KEY_SORT:
      return setKeySort(state, action);
    case actionTypes.SET_ADITIONAL_BENEFIT_GROUPS:
      return setAditionalBenefitGroups(state, action);
    case actionTypes.SET_BENEFIT_GROUPS_PAGE:
      return setBenefitGroupsPage(state, action);
    case actionTypes.APPLY_BENEFIT_GROUPS_FILTERS:
      return applyBenefitGroupsFilters(state, action);
    case actionTypes.RESET_BENEFIT_GROUPS_FILTERS:
      return resetBenefitGroupFilters(state, action);
    case actionTypes.RESET_BENEFIT_GROUPS_FILTERS_TO_DEFAULT:
      return resetBenefitGroupFiltersToDefault(state, action);
    case actionTypes.OPEN_BENEFIT_GROUP_MODAL:
      return openBenefitGroupModal(state, action);
    case actionTypes.SET_CHOSEN_BENEFIT_GROUP:
      return setChosenBenefitGroup(state, action);
    case actionTypes.CLOSE_BENEFIT_GROUP_MODAL:
      return closeBenefitGroupModal(state, action);
    case actionTypes.SET_BENEFIT_GROUPS_AND_SUBSCRIBED_BENEFIT_GROUPS:
      return setBenefitGroupsAndSubscribedBenefitGroups(state, action);
    case actionTypes.SET_NEXT_MONTH_FILTER_CONDITION:
      return setIsConditionAllBenefits(state, action);
    case actionTypes.SET_NEXT_MONTH_TOOLTIP_IS_OPEN:
      return setIsNextMonthTooltipOpen(state, action);
    case actionTypes.SET_SUBSCRIBED_BENEFIT_GROUPS:
      return setSubscribedBenefitGroups(state, action);
    case actionTypes.SET_LIST_OF_COMPANY_BENEFITS:
      return setListOfCompanyBenefits(state, action);
    case actionTypes.SET_LIST_OF_BROWSE_BENEFITS:
      return setListOfBrowseBenefits(state, action);
    case actionTypes.SET_FAVORITE_TAB_OPEN:
      return setIsFavoriteTabOpen(state, action);
    case actionTypes.SET_LIST_OF_FAVORITES:
      return setListOfFavoriteBenefits(state, action);
    case actionTypes.CHECK_IF_IN_FAVORITES:
      return checkIfInFavorites(state, action);
    case actionTypes.SET_USED_BENEFITS:
      return setUsedBenefits(state, action);
    case actionTypes.SET_SOCIAL_PROOF_FOR_SINGLE_BENEFIT_GROUP:
      return setSocialProofForSingleBenefit(state, action);
    case actionTypes.SET_CURRENTLY_POPULAR_BENEFIT:
      return setCurrentlyPopularBenefit(state, action);
  }
  return state;
};

const setCurrentlyPopularBenefit = (state, { payload }) => {
  return updateObject(state, {
    socialProof: updateObject(state.socialProof, {
      currentlyPopularBenefit: payload
    })
  });
};

const setSocialProofForSingleBenefit = (state, { payload }) => {
  return updateObject(state, {
    socialProof: updateObject(state.socialProof, {
      subscribedToSingleBenefitGroup: payload
    })
  });
};

const setUsedBenefits = (state, { payload }) => {
  return updateObject(state, {
    usedBenefits: payload
  });
};

const checkIfInFavorites = (state, { payload }) => {
  return updateObject(state, {
    favoriteBenefits: updateObject(state.favoriteBenefits, {
      isInFavorites: payload
    })
  });
};

const setIsFavoriteTabOpen = (state, { payload }) => {
  return updateObject(state, {
    pageFrontEndStates: updateObject(state.pageFrontEndStates, {
      isFavoriteTab: payload
    })
  });
};

const setListOfFavoriteBenefits = (state, { payload }) => {
  const favoritesAnagram = {};

  if (payload.length > 0) {
    payload.forEach(favorite => {
      favoritesAnagram[favorite.id] = favorite;
    });
  }

  return updateObject(state, {
    favoriteBenefits: updateObject(state.favoriteBenefits, {
      listOfFavoriteBenefits: payload,
      listOfFavoriteBenefitsInObject: favoritesAnagram
    })
  });
};

const setListOfBrowseBenefits = (state, { payload }) => {
  return updateObject(state, {
    benefitsBrowse: {
      benefitGroups: payload.groups,
      benefitGroupsCount: payload.count,
      benefitGroupsTotalPages: payload.pages,
      benefitGroupsPage: 1
    }
  });
};

const setListOfCompanyBenefits = (state, { payload }) => {
  return updateObject(state, {
    benefitsCompany: {
      benefitGroups: payload.groups,
      benefitGroupsCount: payload.count,
      benefitGroupsTotalPages: payload.pages,
      benefitGroupsPage: state.benefitsCompany.benefitGroupsPage
    }
  });
};

const employeeBenefitsSetInitalState = (
  state,
  {
    benefitGroups,
    benefitGroupsCount,
    benefitGroupsTotalPages,
    benefitGroupsPage,
    categories
  }
) => {
  return updateObject(state, {
    benefitsBrowse: updateObject(state.benefitsBrowse, {
      benefitGroups,
      benefitGroupsCount,
      benefitGroupsTotalPages,
      benefitGroupsPage
    }),
    filters: updateObject(state.filters, {
      categories
    }),
    pageFrontEndStates: updateObject(state.pageFrontEndStates, {
      isLoading: false
    })
  });
};

const updateBenefitGroup = (state, { benefitGroup }) => {
  return updateObject(state, {
    benefitsBrowse: updateObject(state.benefitsBrowse, {
      benefitGroups: state.benefitsBrowse.benefitGroups.map(group =>
        group.id === benefitGroup.id ? benefitGroup : group
      )
    }),
    benefitsCompany: updateObject(state.benefitsCompany, {
      benefitGroups: state.benefitsCompany.benefitGroups.map(group => {
        delete benefitGroup.provider;
        return group.id === benefitGroup.id
          ? { ...group, ...benefitGroup }
          : group;
      })
    })
  });
};

export const setSubscribedBenefitGroups = (
  state,
  { subscribedBenefitGroups }
) => {
  return updateObject(state, {
    subscribedBenefits: updateObject(state.subscribedBenefits, {
      subscribedBenefitGroups: subscribedBenefitGroups
    })
  });
};

const setIsSubscribedBenefitGroupsVisible = (state, { isVisible }) => {
  return updateObject(state, {
    pageFrontEndStates: updateObject(state.pageFrontEndStates, {
      isSubscribedBenefitGroupsVisible: isVisible
    })
  });
};

const setBenefitGroupType = (state, { benefitGroupType }) => {
  return updateObject(state, {
    pageFrontEndStates: updateObject(state.pageFrontEndStates, {
      benefitGroupType: benefitGroupType
    })
  });
};

const setIsInitialStateSet = (state, { flag }) => {
  return updateObject(state, {
    pageFrontEndStates: updateObject(state.pageFrontEndStates, {
      isInitialStateSet: flag
    })
  });
};

const setIsPageLoading = (state, { isLoading }) => {
  return updateObject(state, {
    pageFrontEndStates: updateObject(state.pageFrontEndStates, {
      isPageLoading: isLoading
    })
  });
};

const setChosenCategoryIds = (state, { chosenCategoryIds }) => {
  return updateObject(state, {
    filters: updateObject(state.filters, {
      chosenCategoryIds
    })
  });
};

const setCitiesFilter = (state, { cityIds }) => {
  return updateObject(state, {
    filters: updateObject(state.filters, {
      cityIds
    })
  });
};

const setRemoteFilter = (state, { isRemote }) => {
  return updateObject(state, {
    filters: updateObject(state.filters, {
      isRemote
    })
  });
};

const setFiltersPriceRange = (
  state,
  { updatedPriceRangeOptions, chosenPriceRange }
) => {
  return updateObject(state, {
    filters: updateObject(state.filters, {
      priceRangeOptions: updatedPriceRangeOptions,
      chosenPriceRange
    })
  });
};

const setBenefitExpirationTypesFilter = (state, { expirations }) => {
  return updateObject(state, {
    filters: updateObject(state.filters, {
      expirations
    })
  });
};

const setSearchFilter = (state, { search }) => {
  return updateObject(state, {
    filters: updateObject(state.filters, {
      search
    })
  });
};

const setOrderSort = (state, { order }) => {
  return updateObject(state, {
    filters: updateObject(state.filters, {
      order
    })
  });
};

const setKeySort = (state, { key }) => {
  return updateObject(state, {
    filters: updateObject(state.filters, {
      key
    })
  });
};

const setAditionalBenefitGroups = (
  state,
  {
    newBenefitGroups,
    benefitGroupsCount,
    benefitGroupsTotalPages,
    benefitGroupsPage,
    benefitGroupType
  }
) => {
  return benefitGroupType === PUBLIC
    ? updateObject(state, {
        benefitsBrowse: updateObject(state.benefitsBrowse, {
          benefitGroups: [
            ...state.benefitsBrowse.benefitGroups,
            ...newBenefitGroups
          ],
          benefitGroupsCount,
          benefitGroupsTotalPages,
          benefitGroupsPage,
          benefitGroupType
        })
      })
    : updateObject(state, {
        benefitsCompany: updateObject(state.benefitsCompany, {
          benefitGroups: [
            ...state.benefitsCompany.benefitGroups,
            ...newBenefitGroups
          ],
          benefitGroupsCount,
          benefitGroupsTotalPages,
          benefitGroupsPage,
          benefitGroupType
        })
      });
};

const setBenefitGroupsPage = (state, { benefitGroupsPage }) => {
  return updateObject(state, {
    benefitsBrowse: updateObject(state.benefitsBrowse, {
      benefitGroupsPage
    })
  });
};

const applyBenefitGroupsFilters = (
  state,
  {
    benefitGroups,
    benefitGroupsCount,
    benefitGroupsTotalPages,
    benefitGroupType
  }
) => {
  return benefitGroupType === PUBLIC
    ? updateObject(state, {
        benefitsBrowse: updateObject(state.benefitsBrowse, {
          benefitGroups,
          benefitGroupsCount,
          benefitGroupsTotalPages,
          benefitGroupsPage: 1,
          benefitGroupType
        }),
        filters: updateObject(state.filters, {
          appliedChosenCategoryIds: state.filters.chosenCategoryIds,
          appliedChosenPriceRange: state.filters.chosenPriceRange,
          isApplyFilterClicked: !state.filters.isApplyFilterClicked
        })
      })
    : updateObject(state, {
        benefitsCompany: updateObject(state.benefitsCompany, {
          benefitGroups,
          benefitGroupsCount,
          benefitGroupsTotalPages,
          benefitGroupsPage: 1,
          benefitGroupType
        }),
        filters: updateObject(state.filters, {
          appliedChosenCategoryIds: state.filters.chosenCategoryIds,
          appliedChosenPriceRange: state.filters.chosenPriceRange,
          isApplyFilterClicked: !state.filters.isApplyFilterClicked
        })
      });
};

const resetBenefitGroupFilters = (
  state,
  {
    benefitGroups,
    benefitGroupsCount,
    benefitGroupsTotalPages,
    benefitGroupType
  }
) => {
  return benefitGroupType === PUBLIC
    ? updateObject(state, {
        benefitsBrowse: updateObject(state.benefitsBrowse, {
          benefitGroups,
          benefitGroupsCount,
          benefitGroupsTotalPages,
          benefitGroupsPage: 1,
          benefitGroupType
        }),
        filters: updateObject(state.filters, {
          chosenCategoryIds: [],
          appliedChosenCategoryIds: [],
          chosenPriceRange: new PriceRange(),
          appliedChosenPriceRange: new PriceRange(),
          priceRangeOptions: priceRangeOptions,
          search: "",
          isApplyFilterClicked: !state.filters.isApplyFilterClicked
        })
      })
    : updateObject(state, {
        benefitsCompany: updateObject(state.benefitsCompany, {
          benefitGroups,
          benefitGroupsCount,
          benefitGroupsTotalPages,
          benefitGroupsPage: 1,
          benefitGroupType
        }),
        filters: updateObject(state.filters, {
          chosenCategoryIds: [],
          appliedChosenCategoryIds: [],
          chosenPriceRange: new PriceRange(),
          appliedChosenPriceRange: new PriceRange(),
          priceRangeOptions: priceRangeOptions,
          search: "",
          isApplyFilterClicked: !state.filters.isApplyFilterClicked
        })
      });
};

const resetBenefitGroupFiltersToDefault = state => {
  return updateObject(state, {
    filters: updateObject(state.filters, {
      chosenCategoryIds: [],
      appliedChosenCategoryIds: [],
      chosenPriceRange: new PriceRange(),
      appliedChosenPriceRange: new PriceRange(),
      priceRangeOptions: priceRangeOptions,
      search: "",
      isApplyFilterClicked: !state.filters.isApplyFilterClicked
    })
  });
};

const openBenefitGroupModal = (state, { chosenBenefitGroup }) => {
  return updateObject(state, {
    pageFrontEndStates: updateObject(state.pageFrontEndStates, {
      isModalOpen: true,
      chosenBenefitGroup
    })
  });
};

const setChosenBenefitGroup = (state, { chosenBenefitGroup }) => {
  return updateObject(state, {
    pageFrontEndStates: updateObject(state.pageFrontEndStates, {
      chosenBenefitGroup
    })
  });
};

const closeBenefitGroupModal = (state, action) => {
  return updateObject(state, {
    pageFrontEndStates: updateObject(state.pageFrontEndStates, {
      isModalOpen: false,
      chosenBenefitGroup: null
    })
  });
};

const setBenefitGroupsAndSubscribedBenefitGroups = (
  state,
  {
    benefitGroups,
    benefitGroupsCount,
    benefitGroupsTotalPages,
    subscribedBenefitGroups
  }
) => {
  return updateObject(state, {
    benefitsBrowse: updateObject(state.benefitsBrowse, {
      benefitGroups,
      benefitGroupsCount,
      benefitGroupsTotalPages,
      benefitGroupsPage: 1
    }),
    subscribedBenefits: updateObject(state.subscribedBenefits, {
      subscribedBenefitGroups
    })
  });
};

const setIsConditionAllBenefits = (state, { isConditionAllBenefits }) => {
  return updateObject(state, {
    subscribedBenefits: updateObject(state.subscribedBenefits, {
      isConditionAllBenefits,
      isNextMonthTooltipOpen: false
    })
  });
};

const setIsNextMonthTooltipOpen = (state, { isNextMonthTooltipOpen }) => {
  return updateObject(state, {
    subscribedBenefits: updateObject(state.subscribedBenefits, {
      isNextMonthTooltipOpen
    })
  });
};

export default employeeBenefitsReducer;
