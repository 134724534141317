import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const AttachmentIconWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: ${props => props.cursor};
`

const AttachmentIcon = ({
	width = '16px',
	stroke = '#fff',
	cursor = 'pointer'
}) => (
	<AttachmentIconWrapper cursor={cursor}>
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" width={width}>
			<path
				fill="none"
				fillRule="evenodd"
				stroke={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.581"
				d="M42 34.5l-7.564 7.135c-1.93 1.82-5.059 1.82-6.989 0-1.93-1.82-1.93-4.77 0-6.59l7.565-7.135c1.286-1.213 3.372-1.213 4.659 0a2.984 2.984 0 010 4.394l-7.573 7.134a1.718 1.718 0 01-2.33 0 1.492 1.492 0 010-2.197l6.989-6.583"
				transform="translate(-565 -1451) translate(475 40) translate(0 1323) translate(65 62)"
			/>
		</svg>
	</AttachmentIconWrapper>
)

AttachmentIcon.propTypes = {
	width: PropTypes.string,
	stroke: PropTypes.string,
	cursor: PropTypes.string
}

export default AttachmentIcon
