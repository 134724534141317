import { Tier } from "../../models/domain";
import * as actionTypes from "../../constants/actionTypes/hr/settings.constants";
import { updateObject } from "../../services/updateObject";

const initalState = {
  tiers: []
};

const tiersReducer = (state = initalState, action) => {
  switch (action.type) {
    case actionTypes.SET_TIERS:
      return setTiers(state, action);
    case actionTypes.ADD_TIER:
      return addTier(state, action);
    case actionTypes.EDIT_TIER:
      return editTier(state, action);
    case actionTypes.REMOVE_TIER:
      return removeTier(state, action);
  }

  return state;
};

const setTiers = (state, { tiers }) => {
  return updateObject(state, {
    tiers: tiers.map(tier => (typeof tier === Tier ? tier : new Tier(tier)))
  });
};

const addTier = (state, { tier }) => {
  return updateObject(state, {
    tiers: [...state.tiers, typeof tier === Tier ? tier : new Tier(tier)]
  });
};

const editTier = (state, { tierId, tier }) => {
  const domainTier = tier instanceof Tier ? tier : new Tier(tier);
  return updateObject(state, {
    tiers: state.tiers.map(foundTier =>
      foundTier.id === tierId ? domainTier : foundTier
    )
  });
};

const removeTier = (state, { tierId }) => {
  return updateObject(state, {
    tiers: state.tiers.filter(tier => parseInt(tier.id) !== parseInt(tierId))
  });
};

export default tiersReducer;
