import React from 'react'

const BenefitLogoIconSvg = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		width="100%"
		height="100%"
		viewBox="0 0 126 126"
	>
		<defs>
			<path id="prefix__a" d="M.339.392h49.596v77.793H.339z" />
		</defs>
		<g fill="none" fillRule="evenodd">
			<rect width="126" height="126" fill="#1568BF" rx="10" />
			<path
				fill="#FEFEFE"
				d="M20.432 64.643c.45-12.888 4.737-24.385 13.77-34.021 3.563-3.8 7.275-4.317 10.298-1.605 2.789 2.502 2.624 6.214-.44 9.884-14.378 17.21-14.275 34.527.307 51.957 2.78 3.322 2.838 7.047.15 9.525-2.765 2.55-6.768 2.118-9.999-1.236-9.309-9.661-13.58-21.321-14.086-34.504"
			/>
			<g transform="translate(55.181 23.838)">
				<mask id="prefix__b" fill="#fff">
					<use xlinkHref="#prefix__a" />
				</mask>
				<path
					fill="#FEFEFE"
					d="M36.188 54.453c0 7.734-3.42 11.6-10.258 11.6H13.98V41.471h11.95c3.384 0 5.94.887 7.667 2.66 1.726 1.774 2.591 4.647 2.591 8.62v1.702zM13.98 12.524h11.738c2.68 0 4.776.797 6.292 2.394 1.516 1.596 2.275 3.778 2.275 6.545 0 2.839-.724 5.073-2.169 6.705-1.445 1.632-3.543 2.446-6.292 2.446H13.98v-18.09zm33.206 29.531c-1.834-2.589-4.548-4.592-8.143-6.012 5.992-2.624 8.989-8.265 8.989-16.922 0-12.486-7.085-18.73-21.257-18.73H6.365c-1.621 0-3.031.392-4.228 1.172-1.2.78-1.798 1.844-1.798 3.192v69.173c0 1.136.563 2.128 1.691 2.98 1.127.851 2.573 1.278 4.336 1.278h22.42c6.485 0 11.632-1.792 15.439-5.375 3.807-3.581 5.71-9.312 5.71-17.187v-2.235c0-4.965-.917-8.743-2.75-11.334z"
					mask="url(#prefix__b)"
				/>
			</g>
		</g>
	</svg>
)

export default BenefitLogoIconSvg
