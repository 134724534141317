import styled from 'styled-components'

const BenefitsListStyles = styled.div`
	width: 48%;
	padding-right: 30px;
	margin-right: 30px;
	border-right: 1px solid #d8d8d8;

	/* list */
	.benefitList {
		min-width: 300px;
		width: 100%;
		height: 300px;
		padding: 4px;
		overflow-y: auto;
	}

	/* item */

	.item {
		width: 100%;
		height: 70px;
		border-radius: 4px;
		border: solid 1px #d8d8d8;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 10px;
		margin-top: 30px;
		cursor: pointer;

		&:first-child {
			margin-top: 0;
		}

		.title {
			font-size: 16px;
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.38;
			letter-spacing: -0.4px;
			color: #373737;
		}

		.desc {
			font-size: 12px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.33;
			letter-spacing: -0.2px;
			color: #373737;
			width: 160px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.addBenefit {
		width: calc(100% - 8px);
		min-width: 300px;
		height: 44px;
		border-radius: 4px;
		border: solid 1px #d8d8d8;
		margin: 20px 0 0 4px;
		display: flex;
		align-items: center;
		padding: 0 12px;
		cursor: pointer;
		position: relative;

	}
	.circle {
		min-width: 24px;
		min-height: 24px;
		border-radius: 50%;
		background: #1568bf;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		margin-right: 13px;
	}

	.buttonText {
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.38;
		letter-spacing: -0.4px;
		color: #373737;
		text-align: center;
		position: absolute;
		width: 100%;
	}

`

export default BenefitsListStyles
