import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Header, Card } from "../../../../components";
import EditUserForm from "../../../../components/forms/user/EditUserForm";
import { useTranslation } from "react-i18next";

const EditUserStyles = styled.section`
  .editUserContainer {
    width: calc(100% - 210px);
    margin-left: 210px;
    background: #f7f7f7;
    min-height: 100vh;
    overflow-x: hidden;
  }

  .editUserContent {
    padding: 0 30px 30px 30px;
  }
`;

const EditUser = ({ match }) => {
  const { t } = useTranslation();

  return (
    <EditUserStyles>
      <div className="editUserContainer">
        <Header headerTitle={t("edit_user")} />
        <div className="editUserContent">
          <Card>
            <EditUserForm
              routeParams={{
                company: match.params.company,
                chosenId: match.params.id
              }}
            />
          </Card>
        </div>
      </div>
    </EditUserStyles>
  );
};

EditUser.propTypes = {
  match: PropTypes.shape()
};

export default EditUser;
