// Utils
import { isEmpty, removeSpacesAndHTML} from "../../../services/general.utils";
import { convertEditorStateToString } from '../../../services/textEditor.utils';

export class LocalBenefitGroupPayload {
  payload = new FormData();

  constructor(benefitGroup = {}) {
    this.payload.append("name", benefitGroup.name && removeSpacesAndHTML(benefitGroup.name));
    !isEmpty(benefitGroup.description)
      ? this.payload.append("description", convertEditorStateToString(benefitGroup.description))
      : null;
    this.payload.append("category_ids", JSON.stringify(benefitGroup.categories?.map(category => category.id)));
    this.payload.append("summary", benefitGroup.summary && removeSpacesAndHTML(benefitGroup.summary));
    benefitGroup.photo && this.payload.append("photo", benefitGroup.photo);
  }
}

export default LocalBenefitGroupPayload;
