import React from "react";
import { BeatLoader } from "react-spinners";
import { override } from "../../../../services/loadingOverride.styles";

export const LoadingFavoriteBenefits = ({ isLoading }) => {
  return (
    <div
      style={{
        height: "calc(100vh - 180px)",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <BeatLoader
        css={override}
        size={25}
        color="#123abc"
        loading={isLoading}
      />
    </div>
  );
};
