import chunk from "../helpers/chunk";
import { toast } from "react-toastify";
import throttle from "../helpers/throttle";
import processTransform from "./processTransform";
import downloadZip from "./downloadZip";
import regulateNumberOfDownloads from "./regulateNumberOfDownloads";

async function downloadImages(props) {
  const {
    listOfImages: listOfTransformedImages,
    idOfToast,
    downloadFolder,
    setIsRefundDownloading,
    setDownloadPercentage
  } = props;

  const totalImagesNum = listOfTransformedImages.length;
  const numOfDownloads = regulateNumberOfDownloads(listOfTransformedImages);
  const CHUNK_SIZE = Math.ceil(totalImagesNum / numOfDownloads);
  const listOfImages = chunk(listOfTransformedImages, CHUNK_SIZE);

  let totalProcessedItems = 0;

  const throttledFunction = throttle(progress => {
    setDownloadPercentage(progress);
  }, 500);

  const onProgress = () => {
    totalProcessedItems += 1;
    const overallProgress = (totalProcessedItems / totalImagesNum) * 100;
    throttledFunction(overallProgress.toFixed(2));
  };

  for (const [index, item] of listOfImages.entries()) {
    toast.update(idOfToast, {
      type: toast.TYPE.INFO,
      render: `Downloading part ${index + 1}/${listOfImages.length}`
    });

    const zipBlob = await processTransform(
      item,
      1,
      onProgress,
      downloadFolder,
      setIsRefundDownloading
    );

    const zipFileName = `refund_download_part_${index + 1}.zip`;
    downloadZip({
      zipBlob,
      zipFileName,
      zipNumFile: index + 1,
      idOfToast,
      setIsRefundDownloading,
      listOfImages
    });
  }
}

export default downloadImages;
