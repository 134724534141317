import generatePublicUrlOfImage from "./generatePublicUrlOfImage";

function generatePublicURls({ listOfImages, toastId, folderName }) {
  return {
    listOfImages: listOfImages.map(image => {
      return {
        url: generatePublicUrlOfImage(image.name, folderName).data.publicUrl,
        name: image.name
      };
    }),
    toastId
  };
}

export default generatePublicURls;
