import styled from "styled-components";

export const AddUserFormStyles = styled.div`
  .userInfoContainer {
    display: flex;
    align-items: center;
    margin: 60px 0;
    position: relative;

    .inputContainer {
      margin-left: 30px;

      .errorMsg {
        font-size: 12px;
        color: red;
      }

      .topInputs {
        display: flex;
        margin-bottom: 10px;
      }

      .middleInputs {
        display: flex;
        margin-bottom: 10px;
      }

      .userDisabledContainer {
        margin-top: 20px;
        .userDisabledTitle {
          font-weight: 400;
          font-size: 14px;
          color: #C53C3C;
        }
      }

      .bottomInputs {
        display: flex;
        margin-bottom: 10px;
      }
    }
  }

  .labelAndValidation {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .buttonsContainer {
    display: flex;
  }

  .displayText {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4b4b4b;
    background: #d8d8d8bf;
    width: 100%;
    height: 24px;
    display: flex;
    align-items: center;
    padding: 0 5px;
  }

  .resendVerificationLink {
    position: absolute;
    right: 0;
    top: -40px;
    cursor: pointer;
    font-size: 14px;
    color: #5d5d5d;

    &:hover {
      color: #505050;
    }
  }

  .label {
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    letter-spacing: normal;
    color: #5d5d5d;
  }
`;
