import styled from 'styled-components'

const AttachedFileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 26px;
  border: solid 1px #979797;
  border-radius: 4px;
	margin: 0 0 0 30px;
	height: 40px;
	width: 516px;

  .iconContentContainer {
    display: flex;
    align-items: center;

    .content {
      margin: 0 0 0 17px;
      .name {
        line-height: 24px;
        margin: 0 0 -4px 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
				width: 320px;
				font-size: 14px;
				font-weight: 600;
				font-stretch: normal;
				font-style: normal;
				letter-spacing: normal;
				color:  #979797;
      }

      .size {
        font-size: 12px;
				font-weight: 600;
				font-stretch: normal;
				font-style: normal;
				color:  #979797;
        line-height: 22px;
        letter-spacing: 0.46px;
        margin: 0 0 0 0;
      }
    }
  }
`

export default AttachedFileWrapper
