import styled from "styled-components";

export const BenefitSubscribeButtonsContainerStyles = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: ${({ doesHaveMinimumContractPeriod, enableInstalments }) =>
    !!doesHaveMinimumContractPeriod || !!enableInstalments
      ? "space-between"
      : "flex-end"};

  .voucherInputContainer {
    width: 183px;
  }

  .__react_component_tooltip {
    top: 0 !important;
    left: 0 !important;
    position: absolute !important;
  }

  .tokensContainer {
    width: 48%;
    margin: 0 0 7px 10px;
    position: relative;
  }

  .inputContainer {
    margin: 5px 0 0 0;
  }

  .voucherErrorMsg {
    margin-top: -30px !important;
    position: absolute;
    width: 300px;
  }

  .benefitErrorMsg {
    margin-bottom: 85px;
    position: absolute;
    width: 300px;
    right: 0px;
  }

  .tokenWorth {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.85;
    letter-spacing: -0.22px;
    color: #626262;
  }

  .label {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: -0.23px;
    color: #373737;
    position: absolute;
    top: 93%;
    min-width: 120px;
  }

  .subscribedButton {
    width: 111px;
    height: 34px;
    border-radius: 4px;
    background-color: #1568bf;
    border: 1px solid #1568bf;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline: none;

    .circle {
      width: 13px;
      height: 13px;
      border-radius: 6.5px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;

      .unsubscribeLine {
        width: 9px;
        height: 3px;
        border: 1px solid #1568bf;
        background: #1568bf;
      }
    }

    .subscribedText {
      width: 78px;
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      margin-left: 5px;
    }

    &:hover {
      background-color: white;
      border: 1px solid #1568bf;
      color: #1568bf;
      .subscribedText {
        color: #1568bf;
      }
    }
  }

  .minimum-contract-period {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.2px;
    color: #373737;
    opacity: 0.8;
    margin-top: 10px;
  }

  .voucherName {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    margin-right: 5px;
  }

  .voucherPrice {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    margin-left: 10px;
  }

  .desktopVoucherContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
  }

  .desktopVoucher {
    display: flex;
    align-items: center;
  }
  .benefitPrice {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    margin-left: 4px;
  }

  #voucherInput {
    height: 31px;
    .react-dropdown-select {
      height: 31px !important;
      border-radius: 4px !important;
      border: solid 1px #d5dbdd !important;
      background-color: #ffffff !important;
      font-size: 14px !important;
      font-weight: normal !important;
      font-stretch: normal !important;
      font-style: normal !important;
      line-height: 1.71 !important;
      letter-spacing: -0.23px !important;
      color: #373737 !important;
      width: 100% !important;
      box-shadow: none !important;
      cursor: pointer !important;
      display: flex !important;
      align-items: center !important;

      .react-dropdown-select-content {
        height: 100% !important;
        display: flex !important;
        padding: 0 5px !important;
        align-items: center !important;
        flex-wrap: nowrap !important;
        text-overflow: ellipsis !important;
        overflow: hidden !important;
        width: 70px !important;
        white-space: nowrap !important;

        input {
          font-size: 12px !important;
          font-weight: normal !important;
          font-stretch: normal !important;
          font-style: normal !important;
          line-height: normal !important;
          letter-spacing: normal !important;
          color: #5d5d5d !important;
          overflow-x: scroll !important;
        }
      }

      .react-dropdown-select-dropdown-handle {
        height: 100%;
        display: flex;
        align-items: center;
        margin: 0 !important;
      }
    }
  }

  .unsubscribedButton {
    width: 100px;
    padding: 9px;
    border-radius: 4px;
    border: solid 1px #1568bf;
    background-color: #ffffff;
    outline: none;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1568bf;
    margin-top: 10px;

    &:disabled {
      border: solid 1px #d8d8d8;
      background-color: whitesmoke;
      color: #d8d8d8;
    }
  }

  .redeemButton {
    width: 86px;
    padding: 9px;
    border-radius: 4px;
    border: solid 1px #1568bf;
    background-color: #ffffff;
    outline: none;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1568bf;

    &:hover {
      background-color: #1568bf;
      color: white;
    }

    &:disabled {
      border: solid 1px #d8d8d8;
      background-color: whitesmoke;
      color: #d8d8d8;
    }
  }

  @media only screen and (max-width: 520px) {
    flex-wrap: wrap;

    .voucherErrorMsg {
      width: 220px;
      margin-left: -70px;
      font-size: 11px;
    }

    .predefined {
      width: 300px;
      font-size: 12px;
      left: -110px;
    }

    .benefitErrorMsg {
      margin-bottom: 53px;
      left: 0px;
      bottom: -5px;
    }

    .minimum-contract-period {
      margin-top: 0;
      margin-bottom: 10px;
    }

    .unsubscribedButton {
      margin-top: 10px;
    }
  }

  @media only screen and (max-width: 420px) {
    .tokensContainer {
      margin: 35px 0 15px 0;
      position: relative;
    }

    .voucherErrorMsg {
      width: 300px;
      margin: -30px 0 120px 0;
      font-size: 11px;
    }

    .predefined {
      width: 240px;
      font-size: 12px;
      left: 0;
    }

    .desktopVoucher {
      flex-wrap: wrap;
    }
  }

  @media only screen and (max-width: 356px) {
    .benefitErrorMsg {
      bottom: 40px;
      font-size: 11px;
    }
  }

  @media only screen and (max-width: 320px) {
    .voucherInputContainer {
      width: 155px;
    }

    .predefinedContainer {
      margin: 20px 0 0 0;
    }

    .voucherErrorMsg {
      font-size: 10px;
      width: 250px;
    }

    .predefined {
      bottom: -120px;
    }
    .benefitErrorMsg {
      font-size: 10px;
    }
  }
`;
