import styled from "styled-components";

const FormModalStyles = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  background: #0000004d;
  z-index: 5;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;

  .modal {
    width: 545px;
    height: 100%;
    position: relative;
    max-width: 50%;
    min-width: 380px;
    background: #fff;
    border-radius: 4px;
    padding: 0 0 20px 30px;
    opacity: 0;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

    .closeButton {
      background-color: #fff;
      border-radius: 50%;
      cursor: pointer;
      height: 40px;
      opacity: 0;
      overflow: hidden;
      text-align: center;
      transform: translateY(-100%);
      transition: transform 0.2s ease, opacity 0.2s ease, visibility 0.2s ease;
      visibility: hidden;
      width: 40px;
      position: absolute;
      font-size: 20px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #737373;
      left: -59px;
      top: 0;
    }

    :focus-visible {
      outline: unset;
    }
  }

  .showModal {
    opacity: 1 !important;
    transform: translateX(0) !important;
  }

  .showCloseButton {
    opacity: 1 !important;
    transform: translateY(21px) !important;
    visibility: visible !important;
  }
`;

export default FormModalStyles;
