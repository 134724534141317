import styled from "styled-components";

const FinishStepStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 500px;

  .title {
    width: 213px;
    height: 27px;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.08;
    letter-spacing: normal;
    color: #4b4b4b;
    margin-bottom: 30px;
  }
  .text {
    width: 531px;
    height: 66px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: center;
    color: #4b4b4b;
    margin-bottom: 84px;
  }

  .recapSection {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .recapTitle {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: center;
    color: #4b4b4b;
    margin-bottom: 30px;
  }

  .recapText {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: center;
    color: #4b4b4b;
  }

  .finishButton {
    width: 111px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #1568bf;
    background: #1568bf;
    display: flex;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.18px;
    color: #ffffff;
    outline: none;
    cursor: pointer;
    margin-top: 84px;
  }

  .finishButton:disabled {
    background: #d8d8d8;
    border: 1px solid #d8d8d8;
    color: #989898;
    pointer-events: none;
  }
`;

export default FinishStepStyles;
