import Category from "../../domain/Category.entity";

class CategoriesResponse {
  constructor(response = {}) {
    this.categories = response.data.categories.map(category => new Category(category));
    this.pages = response.data.pages;
    this.count = response.data.count;
  }
}

export default CategoriesResponse;