import { apiService } from "../api.service";
import { LocalBenefitsResponse } from "../../../models/server/responses";
import {
  LocalBenefitGroupPayload,
  LocalBenefitPayload
} from "../../../models/server/payloads";
import { LocalBenefitGroup, LocalBenefit } from "../../../models/domain";

export const getLocalBenefitGroups = async ({ limit, page, searchQuery }) => {
  const response = await apiService.get(
    `/benefit-group/local?limit=${limit}&page=${page}&search=${searchQuery}`
  );

  if (response.hasError) return response;

  return new LocalBenefitsResponse(response);
};

export const addLocalBenefitGroup = async values => {
  const payloadData = new LocalBenefitGroupPayload(values);

  const response = await apiService.post(
    "/benefit-group/local/create",
    payloadData.payload
  );

  if (response.hasError) return response;

  return new LocalBenefitGroup(response.data);
};

export const editLocalBenefitGroup = async (values, chosenBenefitGroupId) => {
  const payloadData = new LocalBenefitGroupPayload(values);

  const response = await apiService.put(
    `/benefit-group/local/${chosenBenefitGroupId}`,
    payloadData.payload
  );

  if (response.hasError) return response;

  return new LocalBenefitGroup(response.data);
};

export const deleteBenefitGroup = async benefitGroupId => {
  const response = await apiService.delete(`/benefit-group/local/${benefitGroupId}`);

  return response;
};

export const createNewLocalBenefit = async (values, chosenBenefitGroupId) => {
  const payloadData = new LocalBenefitPayload(chosenBenefitGroupId, values);

  const response = await apiService.post(
    "/benefit/local/create",
    payloadData.payload
  );

  if (response.hasError) return response;

  return new LocalBenefit(response.data);
};

export const editLocalBenefit = async (
  values,
  chosenBenefitId,
  chosenBenefitGroupId
) => {
  const payloadData = new LocalBenefitPayload(chosenBenefitGroupId, values);

  const response = await apiService.put(
    `/benefit/local/update/${chosenBenefitId}`,
    payloadData.payload
  );

  if (response.hasError) return response;

  return new LocalBenefit(response.data);
};

export const deleteLocalBenefit = async benefitId => {
  const response = await apiService.delete(`/benefit/local/delete/${benefitId}`);

  return response;
};
