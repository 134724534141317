import styled from 'styled-components'

const InputStyles = styled.div`
	width: ${({ width }) => width};
	height: ${({ shouldHaveHight }) => shouldHaveHight && '27px'};
	margin: ${({ margin }) => margin};

	.input {
		width: 100%;
		height: 100%;
		width: ${({ inputWidth }) => inputWidth};
		min-height: ${({ customHeight }) => customHeight};
		outline: none;
		border-radius: 4px;
		border: solid 1px #979797;
		padding: 0 10px;
		font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    letter-spacing: normal;
		color: #5d5d5d;
		position: relative;
		display: flex;
		align-items: center;
	}

	input[type=date] {
		text-align: center;
		font-family: 'Montserrat', sans-serif;
	}

	input[type=date]::-webkit-calendar-picker-indicator {
    -webkit-appearance: none;
		position: absolute;
		left: 5px;
		width: 5px;
		color: rgba(0, 0, 0, 0);
		opacity: 0;
		cursor: pointer;
	}

	input[type="date"]:before {
		background: none;
		font-family: 'FontAwesome';
		content: '\f073';
		/* This is the calendar icon in FontAwesome */
		width: 15px;
		height: 100%;
		position: absolute;
		left: 5px;
		cursor: pointer;
		color: #999;
		display: flex;
		align-items: center;
	}

	input[type=number]::-webkit-clear-button {
		display: none;
    -webkit-appearance: none;
	}

	input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: none;
	}

	input[type=number] {
			-moz-appearance:textfield;
	}

	input[type=date]::-webkit-clear-button {
		display: none;
    -webkit-appearance: none;
	}

	input[type=date]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: none;
	}
`

export default InputStyles
