import styled from "styled-components";

const EditBenefitOptionsStyles = styled.div`
  margin: 30px 0 60px 0;

  .tiersLabel {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4b4b4b;
    margin-bottom: 30px;
  }

  .checkboxesContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 0 20px 0;
  }

  .checkboxContainer {
    display: flex;
    align-items: center;
    width: 200px;
    height: 100%;
    margin: 0 36px 20px 0;
  }

  .message {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: center;
    color: #4b4b4b;
  }
`;

export default EditBenefitOptionsStyles;
