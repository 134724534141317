import React, { useState } from "react";

import LoginStyles from "./LoginStyles";
import LoginForm from "../../components/forms/login/LoginForm";
import AzureLogin from "./AzureLogin";

const Login = () => {
  const [isBenefitLogin, setIsBenefitLogin] = useState(false);

  return (
    <LoginStyles>
      {isBenefitLogin ? (
        <LoginForm setIsBenefitLogin={setIsBenefitLogin} />
      ) : (
        <AzureLogin setIsBenefitLogin={setIsBenefitLogin} />
      )}
    </LoginStyles>
  );
};

export default Login;
