import { User, Tier, City, CompanyBranch } from ".";
import { isEmpty } from "../../services/general.utils";

class HrEmployee {
  constructor(employeeData = {}) {
    this.id = employeeData.id || null;
    this.user = employeeData.User
      ? new User(employeeData.User)
      : new User(employeeData.user);
    this.tier = employeeData.Tier
      ? new Tier(employeeData.Tier)
      : new Tier(employeeData.tier);
    this.availableTokens =
      employeeData.availableTokens || employeeData.available_tokens || 0;
    this.tokensUsed = employeeData.usedToken || employeeData.used_token || 0;
    this.remainingTokens =
      employeeData.remainingTokens || employeeData.remaining_tokens || 0;
    if (this.remainingTokens < 0) {
      this.remainingTokens = 0;
    }
    this.budgetSpentPercentage =
      employeeData.budgetSpentPercentage ||
      employeeData.budget_spent_percentage ||
      0;
    this.cities = employeeData.cities
      ? employeeData.cities.map(city => new City(city))
      : [];
    this.gender = !isEmpty(employeeData.gender) ? employeeData.gender : "";
    this.uniqueId = !isEmpty(employeeData.uniqueId)
      ? employeeData.uniqueId
      : "";
    this.CompanyBranch = !isEmpty(employeeData.CompanyBranch)
      ? new CompanyBranch(employeeData.CompanyBranch)
      : "";
    this.branchCode = !isEmpty(employeeData.branchCode)
      ? employeeData.branchCode
      : "";
    this.contact = employeeData.contact || "";
    this.description = employeeData.description || "";
    this.relationship = employeeData.relationship || "";
    this.bornDate = employeeData.bornDate
      ? new Date(employeeData.bornDate)
      : "" || "";
    this.firstDayAtWork = employeeData.firstDayAtWork
      ? new Date(employeeData.firstDayAtWork)
      : "" || "";
    this.team = employeeData.team || "";
    this.bonusAmount =
      employeeData.bonusAmount || employeeData.bonus_amount || "";
    this.totalTokens = employeeData.totalTokens;
    this.rolloverTokens = employeeData.rolloverTokens;
    this.isVerified = employeeData.isVerified || false;
  }
}

export default HrEmployee;
