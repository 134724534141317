import styled from 'styled-components'

const CompaniesStyles = styled.section`
	.companiesContainer {
		width: calc(100% - 210px);
		margin-left: 210px;
		background: #f7f7f7;
		min-height: 100vh;
		overflow-x: hidden;
	}

	.companiesContent {
		padding: 0 30px 30px 30px;
		display: flex;
		position: relative;
	}

	#companiesList {
		opacity: 0;
		transition: all 0.4s ease-in-out;
  }

	.plusContainer {
		width: 24px;
		height: 24px;
		background: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 10px;
		color: #373737;
		border-radius: 50%;

		.buttonText {
			line-height: 24px;
			font-weight: 500;
			font-size: 14px;
		}
	}

	.tableHead {
		padding: 14px 30px;
    display: flex;
    align-items: center;
		border-bottom: 1px solid #94ABD1;
		border-top: 1px solid #F0F0F0;
	}
	
	.tableBodyContainer {
    background: #f7f7f7;
    padding: 30px;
    margin: 20px 0 0 0;
    border-radius: 4px;
	}
	
	#addCompanyForm {
		position: absolute;
		width: calc(100% - 60px);
		transform: translateX(110%);
		transition: all 0.4s ease-in-out;
		opacity: 0;
	}

	.showAddCompanyForm {
    transform: translateY(0) !important;
    opacity: 1 !important;
	}

	.hideCompanyList {
		transform: translateX(-110%);
		opacity: 0;
	}
`

export default CompaniesStyles
