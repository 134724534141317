import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import ReactApexChart from "react-apexcharts";
// components
import { Card } from "../../../../components";
// styles
import UsedTokensStyles from "./UsedTokensStyles";
// config
import { bugetSpentConfig } from "./spentTokens.config";
import { budgetLeftConfig } from "./budgetLeft.config";
// services
import { formatDateForSearch } from "../dashboad.services";
import * as dashboardService from "../../../../services/api/hr/dashboard.service";
import { useTranslation } from "react-i18next";

const UsedTokens = ({ usedTokensLoading, setUsedTokensLoading }) => {
  const { t } = useTranslation();

  const [graphs, setGraphsData] = useState({
    bugetSpentConfig,
    budgetLeftConfig
  });
  const [date, setDate] = useState(new Date());
  const [searchDateString, setSearchDateString] = useState(
    formatDateForSearch(new Date())
  );
  const isMounted = useRef(false);

  const handleDateChange = chosenDate => {
    setDate(chosenDate);
    setSearchDateString(formatDateForSearch(chosenDate));
  };

  const fetchData = async () => {
    const [totalBudegetRes, spentBudgetRes] = await Promise.all([
      dashboardService.fetchTotalBudget(searchDateString),
      dashboardService.fetchBudgetSpending(searchDateString)
    ]);
    if (isMounted.current && totalBudegetRes && spentBudgetRes) {
      setUsedTokensLoading(false);
    }

    let leftBudgetValue = 0;
    let spentBudgetValue = 0;

    if (isMounted.current && totalBudegetRes && spentBudgetRes) {
      leftBudgetValue = Math.round(
        ((parseInt(totalBudegetRes.budget, 0) -
          parseInt(spentBudgetRes.actualSpentBudget, 0)) *
          100) /
          parseInt(totalBudegetRes.budget, 0)
      );

      spentBudgetValue = Math.round(
        (parseInt(spentBudgetRes.actualSpentBudget, 0) * 100) /
          parseInt(totalBudegetRes.budget, 0)
      );
    }

    if (totalBudegetRes && spentBudgetRes) {
      if (isMounted.current) {
        if (!isNaN(spentBudgetValue) && !isNaN(leftBudgetValue)) {
          setGraphsData({
            ...graphs,
            bugetSpentConfig: {
              ...graphs.bugetSpentConfig,
              series: [spentBudgetValue]
            },
            budgetLeftConfig: {
              ...graphs.budgetLeftConfig,
              series: [leftBudgetValue]
            }
          });
        } else {
          setGraphsData({
            ...graphs,
            bugetSpentConfig: {
              ...graphs.bugetSpentConfig,
              series: [0]
            },
            budgetLeftConfig: {
              ...graphs.budgetLeftConfig,
              series: [0]
            }
          });
        }
      }
    }
  };

  useEffect(() => {
    isMounted.current = true;
    fetchData();
    return () => {
      isMounted.current = false;
    };
  }, [date]);

  useEffect(() => {
    if (usedTokensLoading === false) {
      const datePicker = document.getElementById("datePicker");
      if (datePicker) {
        datePicker.setAttribute("readOnly", true);
      }
    }
  }, [usedTokensLoading]);

  return (
    <UsedTokensStyles>
      <Card
        boxShadow="
					0 2px 4px -1px rgba(0, 0, 0, 0.2),
					0 1px 10px 0 rgba(0, 0, 0, 0.12),
					0 4px 5px 0 rgba(0, 0, 0, 0.14)
				"
      >
        <div className="titleContainer">
          <DatePicker
            className="datePickerInput"
            id="datePicker"
            selected={date}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            onChange={chosenDate => handleDateChange(chosenDate)}
            value={date}
            maxDate={new Date()}
          />
          <h3 className="title">{t("tokens_used")}</h3>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <div className="graphContainer">
            <ReactApexChart
              options={graphs.bugetSpentConfig.options}
              series={graphs.bugetSpentConfig.series}
              type="radialBar"
              width={170}
              height={130}
            />
            <p className="description">{t("spent_budget")}</p>
          </div>
          <div className="graphContainer">
            <ReactApexChart
              options={graphs.budgetLeftConfig.options}
              series={graphs.budgetLeftConfig.series}
              type="radialBar"
              width={170}
              height={130}
            />
            <p className="description">{t("budget_unused")}</p>
          </div>
        </div>
      </Card>
    </UsedTokensStyles>
  );
};

UsedTokens.propTypes = {
  usedTokensLoading: PropTypes.bool,
  setUsedTokensLoading: PropTypes.func
};

export default UsedTokens;
