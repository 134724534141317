import React from "react";
import PropTypes from "prop-types";
// components
import { MyDropZone, InputLabel } from "../../../../../components";
// styles
import DetailsSectionStyles from "./DetailsSection.styles";
import { useTranslation } from "react-i18next";

const DetailsSection = ({
  values,
  errors,
  touched,
  setIsDelete,
  setFieldTouched,
  setFieldValue
}) => {
  const { t } = useTranslation();

  return (
    <DetailsSectionStyles>
      <div className="photoAndStatusContainer">
        <div className="labelAndValidation">
          <InputLabel margin="0 0 5px 0" label={t("Company logo")} />
          <div className="errorMsg">
            {errors.photo && touched.photo ? errors.photo : ""}
          </div>
        </div>
        <div className="imgContainer">
          <MyDropZone
            name="photo"
            onBlur={setFieldTouched}
            photo={values.photo}
            handleChange={setFieldValue}
            setIsDelete={setIsDelete}
          />
        </div>
        <InputLabel
          margin="5px 0 0 0"
          label={t("upload_logo_info")}
          shouldHaveColon={false}
        />
      </div>
    </DetailsSectionStyles>
  );
};

DetailsSection.propTypes = {
  values: PropTypes.shape(),
  errors: PropTypes.shape(),
  touched: PropTypes.shape(),
  setIsDelete: PropTypes.func,
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func
};

export default DetailsSection;
