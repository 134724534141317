import { isEmpty, removeSpacesAndHTML } from '../../../services//general.utils';

class TierPayload {
    constructor(payload = {}) {
        this.id = payload.id || null;
        this.currency = payload.currency || "";
        this.description = payload.description ? removeSpacesAndHTML(payload.description) : "";
        this.name = payload.name ? removeSpacesAndHTML(payload.name) : "";
        this.tokens = !isEmpty(payload.tokens) ? payload.tokens : "";
    }
}
export default TierPayload;
