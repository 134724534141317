import styled from "styled-components";

const EditHrBenefitStyles = styled.section`
  height: 100vh;
  .editHrBenefitsContainer {
    width: calc(100% - 210px);
    margin-left: 210px;
    background: whitesmoke;
    min-height: 100vh;
  }

  .editHrBenefitsContent {
    padding: 0 30px 30px 30px;
  }

  #benefitGroupCard {
    opacity: 0;
    transition: all 0.4s ease-in-out;
  }

  .buttonsContainer {
    display: flex;
    justify-content: space-between;
  }

  .submitContainer {
    display: flex;
  }
`;

export default EditHrBenefitStyles;
