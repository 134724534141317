export const SET_HR_EMPLOYEES = "SET_HR_EMPLOYEES";
export const SET_PAGE = "SET_HR_PAGE";
export const SET_SEARCH_CHANGE = "SET_HR_SEARCH_CHANGE";
export const ADD_HR_EMPLOYEE = "ADD_HR_EMPLOYEE";
export const TOGGLE_SIDEBAR_MENU = "TOGGLE_SIDEBAR_MENU";
export const SET_FAILED_TO_BULK_UPDATE_USERS =
  "SET_FAILED_TO_BULK_UPDATE_USERS";
export const SET_LIST_OF_DROPDOWN_EMPLOYEES = "SET_LIST_OF_DROPDOWN_EMPLOYEES";
export const SET_DROPDOWN_EMPLOYEES_FILTER = "SET_DROPDOWN_EMPLOYEES_FILTER";
export const SET_DROPDOWN_EMPLOYEES_META = "SET_DROPDOWN_EMPLOYEES_META";
