export const getOrderColumns = (tableHeaders) => tableHeaders
	.filter(tHeader =>
		tHeader.isSortable && tHeader.isSortedUp !== null
	)
	.map(tHeader => ({
		field: tHeader.field,
		isSortedUp: tHeader.isSortedUp,
		orderPriority: tHeader.orderPriority
	}))
	.sort((a, b) => b.orderPriority - a.orderPriority)
