import Voucher from "./Voucher.entity";

class LocalBenefitForm {
  constructor(localBenefitFormData = {}) {
    this.id = localBenefitFormData.id || null;
    this.title = localBenefitFormData.title || "";
    this.tokenPrice = localBenefitFormData.tokenPrice || "";
    this.description = localBenefitFormData.description || "";
    this.summary = localBenefitFormData.summary || "";
    this.expiration = localBenefitFormData.expiration
      ? localBenefitFormData.expiration.split("_").join(" ")
      : "" || "";
    this.minimumContractPeriod =
      localBenefitFormData.minimumContractPeriod ||
      localBenefitFormData.minimum_contract_period ||
      "";
    this.vouchers = localBenefitFormData.BenefitVoucher
      ? localBenefitFormData.BenefitVoucher.map(voucher => new Voucher(voucher))
      : [];
    // this.photo = localBenefitFormData.Photo
    //   ? new JpgPhoto(localBenefitFormData.Photo)
    //   : null;
    this.benefit_group_id = localBenefitFormData.benefitGroupId || null;
    this.is_draft =
      localBenefitFormData.isDraft || localBenefitFormData.is_draft || false;
    this.quantity = localBenefitFormData.quantity || "";
  }
}

export default LocalBenefitForm;
