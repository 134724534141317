import React, { useEffect, useState, useRef, useContext } from "react";
import { BeatLoader } from "react-spinners";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
// components
import {
  Card,
  Button,
  Header,
  TextAndLine,
  EmployeeActiveBenefits
} from "../../../../components";
import BasicInfo from "../BasicInfoSection";
import Details from "../DetailsSection";
import Rank from "../RankSection";
// styles
import EditEmployeeStyles from "./EditEmployeeStyles";
import { override } from "../../../../services/loadingOverride.styles";
// entity
import { HrEmployeeForm } from "../../../../models/domain";
// services
import * as employeesService from "../../../../services/api/hr/employees.service";
import { hrEmployeeValidations } from "../../../../services/validations/hr/hrEmployeeValidations";
import { useTranslation } from "react-i18next";

const EditEmployee = ({ match }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [singleEmployee, setSingleEmployee] = useState(new HrEmployeeForm());
  const [disableButton, setDisableButton] = useState(false);

  const history = useHistory();
  const isMounted = useRef(true);

  const handleEditEmployeeError = ({ error }) => {
    const { data } = error;
    if (data.errors && data.error) {
      if (data.errors.firstName) {
        toast.error(`${data.error.message}: ${data.errors.firstName.keys[0]}`);
      }
      if (data.errors.lastName) {
        toast.error(`${data.error.message}: ${data.errors.lastName.keys[0]}`);
      }
      if (data.errors.bornDate) {
        toast.error(`${data.error.message}: ${data.errors.bornDate.keys[0]}`);
      }
      if (data.errors.firstDayAtWork) {
        toast.error(
          `${data.error.message}: ${data.errors.firstDayAtWork.keys[0]}`
        );
      }
      if (data.errors.tierId) {
        toast.error(`${data.error.message}: ${data.errors.tierId.keys[0]}`);
      }
      if (data.errors.email) {
        toast.error(`${data.error.message}: ${data.errors.email.keys[0]}`);
      }
      if (data.errors.cities) {
        toast.error(`${data.error.message}: ${data.errors.cities.keys[0]}`);
      }
    } else {
      toast.error(data.error.message, {
        autoClose: 15000
      });
    }
  };

  const updateEmployee = async values => {
    setDisableButton(true);
    const response = await employeesService.editEmployee(
      values,
      match.params.id
    );

    if (response.hasError) {
      setDisableButton(false);
      return handleEditEmployeeError(response);
    }

    setDisableButton(false);
    toast.success(t("success_employee_edit"));
    history.push("/employees");
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: singleEmployee,
    validate: hrEmployeeValidations,
    onSubmit: updateEmployee
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    handleChange,
    handleBlur
  } = formik;

  const fetchEmployee = async () => {
    const response = await employeesService.getChosenCompanyEmployee(
      match.params.id
    );

    if (response.hasError) {
      return toast.error(response.error.data.error.message);
    }

    if (isMounted.current && response) {
      setTimeout(() => {
        setSingleEmployee(response);
        setIsLoading(false);
        setTimeout(() => {
          const editEmployeeContentCard = document.getElementById(
            "editEmployeeContentCard"
          );
          if (editEmployeeContentCard && isMounted.current) {
            editEmployeeContentCard.style.opacity = 1;
          }
        }, 100);
      }, 100);
    }
  };

  useEffect(() => {
    fetchEmployee();
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <EditEmployeeStyles>
      <div className="editEmployeeContainer">
        <Header headerTitle={t("Edit Employee")} />
        {isLoading ? (
          <div
            style={{
              height: "calc(100vh - 180px)",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <BeatLoader
              css={override}
              size={25}
              color="#123abc"
              loading={isLoading}
            />
          </div>
        ) : (
          <div className="editEmployeeContent">
            <Card
              id="editEmployeeContentCard"
              boxShadow="
									0 2px 4px -1px rgba(0, 0, 0, 0.2),
									0 1px 10px 0 rgba(0, 0, 0, 0.12),
									0 4px 5px 0 rgba(0, 0, 0, 0.14)
								"
              paddingMobile="30px 15px"
            >
              <form onSubmit={handleSubmit}>
                <TextAndLine title={t("basic_info")} />
                <BasicInfo
                  isFormDisplay
                  isEditEmployee={true}
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  fetchData={fetchEmployee}
                />
                <TextAndLine title={t("Details")} />
                <Details
                  isFormDisplay
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                />
                <TextAndLine title={t("Rank")} />
                <Rank
                  isFormDisplay
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                />
                <div className="buttonsContainer">
                  {disableButton ? (
                    <Button width="130px" disabled margin="0 30px 0 0">
                      {t("please_wait")}
                    </Button>
                  ) : (
                    <Button width="130px" type="submit" margin="0 30px 0 0">
                      {t("Update")}
                    </Button>
                  )}
                  <Button
                    type="button"
                    onClick={() => history.push("/employees")}
                    width="130px"
                    padding="10px 18px"
                    margin="0"
                    outline
                  >
                    {t("cancel")}
                  </Button>
                </div>
              </form>
              <EmployeeActiveBenefits singleEmployee={singleEmployee} />
            </Card>
          </div>
        )}
      </div>
    </EditEmployeeStyles>
  );
};

EditEmployee.propTypes = {
  match: PropTypes.shape()
};

export default EditEmployee;
