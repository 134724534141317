import React from "react";
import PropTypes from "prop-types";

import AddProvider from "../Provider/AddProvider";
import EditProvider from "../Provider/EditProvider";

import AddBenefitGroup from "../BenefitGroup/AddBenefitGroup";
import EditBenefitGroup from "../BenefitGroup/EditBenefitGroup";

import AddBenefit from "../Benefit/AddBenefit";
import EditBenefit from "../Benefit/EditBenefit";

const FormRenderer = ({
  modalType,
  closeModal,
  providerId,
  setChosenBenefitGroupId,
  benefitGroupId,
  benefitId,
  fetchProviders,
  setCurrentPage
}) => {
  switch (modalType) {
    case "addProvider":
      return (
        <AddProvider
          closeModal={closeModal}
          fetchProviders={fetchProviders}
          setCurrentPage={setCurrentPage}
        />
      );
    case "editProvider":
      return (
        <EditProvider
          closeModal={closeModal}
          fetchProviders={fetchProviders}
          providerId={providerId}
        />
      );
    case "addBenefitGroup":
      return (
        <AddBenefitGroup
          closeModal={closeModal}
          providerId={providerId}
          setChosenBenefitGroupId={setChosenBenefitGroupId}
        />
      );
    case "editBenefitGroup":
      return (
        <EditBenefitGroup
          closeModal={closeModal}
          providerId={providerId}
          benefitGroupId={benefitGroupId}
        />
      );
    case "addBenefit":
      return (
        <AddBenefit
          closeModal={closeModal}
          providerId={providerId}
          benefitGroupId={benefitGroupId}
        />
      );
    case "editBenefit":
      return (
        <EditBenefit
          closeModal={closeModal}
          providerId={providerId}
          benefitGroupId={benefitGroupId}
          benefitId={benefitId}
        />
      );
    default:
      throw new Error("Unknow action type");
  }
};

FormRenderer.propTypes = {
  modalType: PropTypes.string,
  closeModal: PropTypes.func,
  providerId: PropTypes.number,
  benefitGroupId: PropTypes.number,
  setChosenBenefitGroupId: PropTypes.func,
  benefitId: PropTypes.number,
  fetchProviders: PropTypes.func,
  setCurrentPage: PropTypes.func
};

export default FormRenderer;
