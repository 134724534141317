import styled from "styled-components";

const TierItemStyles = styled.div`
  padding: 20px 15px 15px 15px;
  height: 181px;
  width: 381px;
  margin: 0 16px 16px 0;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
  cursor: pointer;

  /* &:hover {
		box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
	} */

  .titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: -0.45px;
      color: #373737;
    }
  }

  .tokensMembersContainer {
    display: flex;
    align-items: center;

    .tokensAndMembers {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: -0.23px;
      color: #373737;
    }
  }

  .description {
    margin-top: 21px;
    opacity: 0.7;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: -0.5px;
    color: #373737;
  }

  .actionsCol {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8f8f8f;
    cursor: pointer;
    position: relative;
  }

  .tooltip {
    width: 120px;
    /* height: 107px; */
    background: #f7f7f7;
    border: 1px solid #1568bf !important;
    padding: 0;
    border-radius: 4px;
  }

  .button {
    width: 100%;
    height: 34px;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 16px;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8f8f8f;
    text-transform: capitalize;

    &:hover {
      background: #1568bf;
      color: #fff;
    }
  }
`;

export default TierItemStyles;
