import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// components
import {
  TextAndLine,
  Input,
  InputLabel,
  SelectInput,
  Button,
  CheckboxInput
} from "../../../../../../components";
import Vouchers from "./Vouchers";
// styles
import BenefitFormStyles from "./BenefitFormStyles";
// services
import * as benefitTagsService from "../../../../../../services/api/admin/benefitTags.service";
import * as benefitTagActionCreators from "../../../../../../actions/admin/benefitTags.actions";
import * as providersService from "../../../../../../services/api/admin/providers.service";
// Utils
import { convertStringToEditorState } from "../../../../../../services/textEditor.utils";
// Constants
import { EXPIRATION_OPTIONS } from "../../../../../../constants/benefitExpiration.constants";
import { useTranslation } from "react-i18next";

const BenefitForm = ({
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  handleBlur,
  closeModal,
  handleChange,
  buttonText,
  isEdit,
  disableButton,
  benefitTags,
  setBenefitTags
}) => {
  const { t } = useTranslation();
  const [showBenefitTagInput, setShowBenefitTagInput] = useState(false);
  const [benefitTagObj, setBenefitTagObj] = useState(undefined);
  const [selectedTag, setSelectedTag] = useState("");

  const handleTagChange = (name, id) => {
    const chosenTag = benefitTags.find(tag => tag.id === id);
    setBenefitTagObj(chosenTag);
  };

  const fetchBenefitTags = async () => {
    const response = await benefitTagsService.getBenefitTags();

    if (response.hasError) {
      return toast.error(response.error.data.error.message);
    }

    setBenefitTags(response);
  };

  useEffect(() => {
    fetchBenefitTags();
  }, []);

  const filteredEXPOptions = () => {
    if (values.expiration === "voucher") {
      return EXPIRATION_OPTIONS.filter(o => o.name === "voucher");
    } else {
      return EXPIRATION_OPTIONS.filter(o => o.name !== "voucher");
    }
  };

  const filteredTags = getChosenIds =>
    benefitTags.filter(t =>
      getChosenIds === "getChosenIds"
        ? values.tags.includes(t.id)
        : !values.tags.includes(t.id)
    );

  const sortTagsByChosenOrder = chosenTags => {
    const result = [];
    chosenTags.forEach(ct => {
      result[values.tags.indexOf(ct.id)] = ct;
    });
    return result;
  };

  const showTagInput = () => {
    setShowBenefitTagInput(true);
    setTimeout(() => {
      const inputContainer = document.getElementById("benefitTagsInput");
      if (inputContainer) {
        inputContainer.classList.add("showBenefitsTagsInputContainer");
      }
    }, 100);
  };

  const addTag = name => {
    const inputContainer = document.getElementById("benefitTagsInput");
    if (
      inputContainer &&
      inputContainer.classList.contains("showBenefitsTagsInputContainer")
    ) {
      inputContainer.classList.remove("showBenefitsTagsInputContainer");
    }

    setTimeout(() => {
      setShowBenefitTagInput(false);
      setFieldValue("tags", [...values.tags, benefitTagObj.id]);
      setBenefitTagObj(undefined);
    }, 400);
  };

  const removeTag = (name, index) => {
    const filteredArray = values.tags.filter((tag, i) => i !== index);
    setFieldValue("tags", filteredArray);
  };

  const handleNumberInput = e => {
    let inputValue = e.target.validity.valid
      ? parseInt(e.target.value, 0)
      : values.tokenPrice;
    if (isNaN(inputValue)) {
      inputValue = "";
    }
    setFieldValue("tokenPrice", inputValue);
  };

  const handleVatNumberInput = e => {
    let inputValue;
    if (e.target.validity.valid) {
      let replacedValue = e.target.value.replace(/[^0-9]*/g, "");
      inputValue = parseInt(replacedValue);
    }
    if (isNaN(inputValue)) {
      inputValue = undefined;
    }

    if (inputValue >= 20) {
      inputValue = 20;
    }
    setFieldValue("vat", inputValue);
  };

  const handleMcpNumberInput = e => {
    let inputValue = e.target.validity.valid
      ? parseInt(e.target.value, 0)
      : values.minimumContractPeriod;
    if (isNaN(inputValue)) {
      inputValue = "";
    }
    setFieldValue("minimumContractPeriod", inputValue);
  };

  const handleInstalmentsNumberInput = e => {
    let inputValue = e.target.validity.valid
      ? parseInt(e.target.value, 0)
      : values.instalments;
    if (isNaN(inputValue)) {
      inputValue = "";
    }
    setFieldValue("instalments", inputValue);
  };

  /**
   * Handles change of draft status for benefit.
   */
  const handleBenefitPublishedStatus = () => {
    const draft = !values.isDraft;
    setFieldValue("isDraft", draft);
  };

  /**
   * Published drafted benefit.
   * If request fails, returns toast error message.
   * If request is successfull, fires toast message, closes modal.
   */
  const publishBenefit = async () => {
    const response = await providersService.publishBenefit(values.id);

    if (response.hasError) {
      return toast.error(t("failed_to_publish_benefit"));
    }

    toast.success(t("success_publish_benefit"));
    closeModal();
  };

  return (
    <BenefitFormStyles>
      <TextAndLine title={t("basic_info")} />
      <div style={{ margin: "20px 0" }}>
        <div className="benefitsInfoTopContainer">
          <div className="inputsContainer">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <InputLabel
                customCssClass="labelStyles"
                margin="0 0 5px 0"
                label={t("Name")}
              />
              <div className="errorMsg" style={{ marginBottom: 5 }}>
                {errors.title && touched.title ? errors.title : ""}
              </div>
            </div>
            <Input
              name="title"
              value={values.title}
              onChange={handleChange}
              onBlur={handleBlur}
              shouldHaveHight={false}
              customCssClass="inputStyles"
            />
          </div>
          <div className="inputsContainer">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <InputLabel
                customCssClass="labelStyles"
                margin="0 0 5px 0"
                label={t("expiration_type")}
              />
              <div className="errorMsg" style={{ marginBottom: 5 }}>
                {errors.expiration && touched.expiration
                  ? errors.expiration
                  : ""}
              </div>
            </div>
            <SelectInput
              name="expiration"
              onBlur={handleBlur}
              options={isEdit ? filteredEXPOptions() : EXPIRATION_OPTIONS}
              selectedValues={EXPIRATION_OPTIONS}
              value={values.expiration}
              handleChange={option => setFieldValue("expiration", option.name)}
              customId="expirationId"
              shouldHaveHight={false}
            />
          </div>
        </div>

        <div style={{ width: "100%", margin: "0 0 10px 0" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <InputLabel
              customCssClass="labelStyles"
              margin="0 0 5px 0"
              label={t("Quantity")}
            />
            <div className="errorMsg" style={{ marginBottom: 5 }}>
              {errors.quantity && touched.quantity ? errors.quantity : ""}
            </div>
          </div>
          <Input
            name="quantity"
            type="number"
            value={values.quantity}
            onChange={handleChange}
            onBlur={handleBlur}
            shouldHaveHight={false}
            customCssClass="inputStyles"
            pattern="[0-9]*"
            min={1}
          />
        </div>

        <div style={{ width: "100%", margin: "0 0 10px 0" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <InputLabel
              customCssClass="labelStyles"
              margin="0 0 5px 0"
              label={t("short_name")}
            />
          </div>
          <Input
            name="shortName"
            value={values.shortName}
            onChange={handleChange}
            onBlur={handleBlur}
            shouldHaveHight={false}
            customCssClass="inputStyles"
          />
          <small className="shortNameDisclaimer">{t("short_name_info")}</small>
        </div>

        {values.expiration === "month" && (
          <div style={{ width: "100%", margin: "0 0 10px 0" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <InputLabel
                customCssClass="labelStyles"
                margin="0 0 5px 0"
                label={t("min_contract_period")}
              />
            </div>
            <Input
              name="minimumContractPeriod"
              pattern="[0-9]*"
              value={values.minimumContractPeriod}
              onChange={handleMcpNumberInput}
              onBur={handleBlur}
              shouldHaveHight={false}
              customCssClass="inputStyles"
            />
          </div>
        )}

        {values.expiration === "one time" && (
          <div style={{ width: "100%", margin: "0 0 10px 0" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <InputLabel
                customCssClass="labelStyles"
                margin="0 0 5px 0"
                label={t("num_of_installments")}
              />
            </div>
            <Input
              name="instalments"
              pattern="[0-9]*"
              value={values.instalments}
              onChange={handleInstalmentsNumberInput}
              onBur={handleBlur}
              shouldHaveHight={false}
              customCssClass="inputStyles"
            />
          </div>
        )}

        {values.expiration !== "voucher" && (
          <div style={{ width: "100%", margin: "0 0 10px 0" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <InputLabel
                customCssClass="labelStyles"
                margin="0 0 5px 0"
                label={t("ben_price_in_tokens")}
              />
              <div className="errorMsg" style={{ marginBottom: 5 }}>
                {errors.tokenPrice && touched.tokenPrice
                  ? errors.tokenPrice
                  : ""}
              </div>
            </div>
            <Input
              name="tokenPrice"
              pattern="[0-9]*"
              min={1}
              value={values.tokenPrice}
              onChange={handleNumberInput}
              onBur={handleBlur}
              shouldHaveHight={false}
              customCssClass="inputStyles"
            />
          </div>
        )}

        <div style={{ width: "100%", margin: "0 0 10px 0" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <InputLabel
              customCssClass="labelStyles"
              margin="0 0 5px 0"
              label={t("VAT")}
            />
          </div>
          <Input
            name="vat"
            pattern="[0-9]*"
            value={values.vat}
            min={1}
            max={20}
            onChange={handleVatNumberInput}
            onBur={handleBlur}
            shouldHaveHight={false}
            customCssClass="inputStyles"
          />
        </div>

        <div
          style={{
            width: "100%",
            margin: values.expiration === "voucher" ? "0 0 10px 0" : 0
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <InputLabel
              customCssClass="labelStyles"
              margin="0 0 5px 0"
              label={t("Summary")}
            />
            <div className="errorMsg" style={{ marginBottom: 5 }}>
              {errors.summary && touched.summary ? errors.summary : ""}
            </div>
          </div>
          <textarea
            className="summary"
            name="summary"
            value={values.summary}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="descriptionContainer">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: 24
            }}
          >
            <InputLabel
              customCssClass="labelStyles"
              margin=" 0 0 5px 0"
              label={t("Description")}
            />
            <div className="errorMsg" style={{ marginBottom: 5 }}>
              {errors.description && touched.description
                ? errors.description
                : ""}
            </div>
          </div>
          <Editor
            onBlur={() => {
              setFieldTouched("description", true);
            }}
            editorState={convertStringToEditorState(values.description)}
            editorStyle={{
              fontSize: 14
            }}
            stripPastedStyles={true}
            onEditorStateChange={editorState =>
              setFieldValue("description", editorState)
            }
            toolbar={{
              options: [
                "inline",
                "fontSize",
                "list",
                "textAlign",
                "colorPicker",
                "link",
                "emoji",
                "remove",
                "history"
              ],
              fontSize: {
                options: [10, 11, 12, 13, 14, 16, 18]
              }
            }}
          />
        </div>
        <div className="descriptionContainer">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <InputLabel
              customCssClass="labelStyles"
              margin="0 0 5px 0"
              label={t("usg_instr")}
            />
            <div className="errorMsg" style={{ marginBottom: 5 }}>
              {errors.usageInstructions && touched.usageInstructions
                ? errors.usageInstructions
                : ""}
            </div>
          </div>
          <Editor
            onBlur={() => {
              setFieldTouched("usageInstructions", true);
            }}
            editorState={convertStringToEditorState(values.usageInstructions)}
            editorStyle={{
              fontSize: 14
            }}
            stripPastedStyles={true}
            onEditorStateChange={editorState =>
              setFieldValue("usageInstructions", editorState)
            }
            toolbar={{
              options: [
                "inline",
                "fontSize",
                "list",
                "textAlign",
                "colorPicker",
                "link",
                "emoji",
                "remove",
                "history"
              ],
              fontSize: {
                options: [10, 11, 12, 13, 14, 16, 18]
              }
            }}
          />
        </div>
        {values.expiration === "voucher" && (
          <Vouchers
            values={values}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
            isAdmin
          />
        )}
        {!isEdit && (
          <div style={{ display: "flex", padding: "5px" }}>
            <CheckboxInput
              isChecked={values.isDraft}
              onChange={() => handleBenefitPublishedStatus()}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: 24
              }}
            >
              <InputLabel
                customCssClass="labelStyles"
                margin="0 0 0 10px"
                label={t("save_as_draft")}
                shouldHaveColon={false}
              />
            </div>
          </div>
        )}
        {isEdit && values.isDraft && (
          <span className="publishBenefitLink" onClick={() => publishBenefit()}>
            {t("publish_benefit")}
          </span>
        )}
      </div>
      <TextAndLine title={t("Benefit tags")} />
      <div style={{ margin: "20px 0" }}>
        <div className="benefitTagsContainer">
          <Button
            height="27px"
            disabled={showBenefitTagInput}
            width="100px"
            onClick={() => showTagInput()}
            padding="0"
            margin="0 20px 0 0"
          >
            {t("add_tag")}
          </Button>
          {showBenefitTagInput && (
            <div id="benefitTagsInput" className="benefitsTagsInputContainer">
              <div style={{ width: "calc(100% - 120px)" }}>
                <InputLabel
                  customCssClass="labelStyles"
                  label={t("Benefit tags")}
                  margin="0 0 5px 0"
                />
                <SelectInput
                  options={filteredTags()}
                  value={selectedTag}
                  handleChange={option => {
                    handleTagChange("", option.id);
                    setSelectedTag(option);
                  }}
                />
              </div>
              <Button
                height="27px"
                disabled={!benefitTagObj}
                onClick={() => {
                  addTag("tags");
                  setSelectedTag("");
                }}
                width="100px"
                padding="0"
                margin="0 0 0 20px"
              >
                {t("submit_tag")}
              </Button>
            </div>
          )}
        </div>

        <div
          style={{
            width: "100%",
            height: 150,
            border: "1px solid #979797",
            borderRadius: 4
          }}
        >
          {filteredTags("getChosenIds").length > 0 && (
            <div className="tagsContainer">
              {sortTagsByChosenOrder(filteredTags("getChosenIds")).map(
                (tag, index) => (
                  <div className="tagButton" key={tag.name}>
                    <p className="tagName">{tag.name}</p>
                    <button
                      onClick={() => removeTag("tags", index)}
                      className="removeTagButton"
                      type="button"
                    >
                      X
                    </button>
                  </div>
                )
              )}
            </div>
          )}
        </div>
      </div>

      <div style={{ display: "flex", marginBottom: 10 }}>
        {disableButton ? (
          <Button width="130px" disabled margin="0 30px 0 0">
            {t("please_wait")}
          </Button>
        ) : (
          <Button width="130px" type="submit" margin="0 30px 0 0">
            {t(buttonText)}
          </Button>
        )}

        <Button width="130px" onClick={() => closeModal()} margin="0" outline>
          {t("cancel")}
        </Button>
      </div>
    </BenefitFormStyles>
  );
};

BenefitForm.propTypes = {
  values: PropTypes.shape(),
  errors: PropTypes.shape(),
  touched: PropTypes.shape(),
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  handleBlur: PropTypes.func,
  closeModal: PropTypes.func,
  handleChange: PropTypes.func,
  buttonText: PropTypes.string,
  disableButton: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    benefitTags: state.benefitTags.tags
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setBenefitTags: benefitTags =>
      dispatch(benefitTagActionCreators.setBenefitTags(benefitTags))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BenefitForm);
