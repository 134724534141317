import React from "react";
import PropTypes from "prop-types";

import TextAndLineStyles from "./TextAndLineStyles";

const TextAndLine = ({ children, title, margin = "0", padding = "0" }) => (
  <TextAndLineStyles margin={margin} padding={padding} title={title}>
    <p className="text">{title}</p>
    <hr className="line" />
    {children}
  </TextAndLineStyles>
);

TextAndLine.propTypes = {
  title: PropTypes.string,
  margin: PropTypes.string,
  padding: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.elementType,
    PropTypes.array
  ])
};

export default TextAndLine;
