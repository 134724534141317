import styled from "styled-components";

const EmployeeActiveBenefitsStyles = styled.div`
  margin: 12px;
  background: whitesmoke;
  max-height: 350px;
  overflow-y: scroll;

  .tableHeadActiveBenefits {
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #94abd1;
    border-top: 1px solid #f0f0f0;
    background: ${({ backgroundOpen }) => backgroundOpen || "#FFF"};
  }

  .employeeActiveBenefitStyle {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    padding: 12px;
    margin-bottom: 2px;
    background: ${({ backgroundOpen }) => backgroundOpen || "#FFF"};
    border: 1px solid ${({ backgroundOpen }) => backgroundOpen || "#FFF"};
  }
  .name {
    width: 29%;
  }

  .provider {
    width: 23%;
  }

  .benefitGroup {
    width: 25%;
  }

  .tokens {
    width: 10%;
    img {
      width: 16px;
      height: 16px;
    }
  }

  .expiration {
    width: 10%;
  }

  h4 {
    font-family: Montserrat;
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0px;
    text-align: left;
    color: #1d1d1d;
    margin-bottom: 4px;
    display: none;
  }

  .content {
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: #5f5f5f;

    span {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: #3166b9;
    }

    p {
      width: calc(100% - 18px);
    }
  }

  @media screen and (max-width: 1280px) {
    h4 {
      display: block;
    }
  }

  @media screen and (max-width: 1024px) {
    .name {
      width: 36%;
    }

    .provider {
      width: 30%;
    }

    .benefitGroup {
      width: 30%;
    }

    .tokens {
      width: 100%;
    }
  }

  @media screen and (max-width: 500px) {
    margin-bottom: 0;

    .employeeActiveBenefitStyle {
      padding: 12px 0;
      margin-bottom: 10px;
    }

    .content {
      margin-bottom: 12px;
    }
    .name {
      width: 100%;
    }

    .provider {
      width: 48%;
    }

    .benefitGroup {
      width: 48%;
    }

    .tokens {
      width: 30%;
    }

    .expiration {
      width: 70%;
    }
  }
`;
export default EmployeeActiveBenefitsStyles;
