import React, { useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import ArrowDown from "../../../assets/images/categoryFilters/rectangle.png";
import { SwitchPlatformsDropdownStyles } from "./switchPlatformsDropdown.styles";

import useClickOutside from "../../../hooks/useClickOutside";

import * as actionCreators from "../../../actions/app.actions";
import * as api from "../../../services/api/auth.service";
import { isAuthenticatedRole } from "../../../services/roles.utils";
import { setItem } from "../../../services/localStorage.service";
import { BENEFIT_ROLE } from "../../../constants/localStorage.constants";
import { UserRolesKeysEnum } from "../../../constants/roles.constants";
import { useTranslation } from "react-i18next";
import { LanguageDropdown } from "../../locales/LanguageDropdown";

const SwitchPlatformsDropdown = ({ user, switchUserRole }) => {
  const { t } = useTranslation();
  const tooltip = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const history = useHistory();

  const { authenticatedRole } = useSelector(state => state.app.user);

  /**
   * Handles switching of user roles.
   * Sets selected role to state.
   * If user switches to HR that hasn't completed onboarding process:
   *  Replaces current route with /onboarding.
   * In every other case, switches to /.
   * @param {object} role
   */
  const handleSwitchUserRole = async role => {
    const userRole = await api.setAuthenticatedUserRole(role.key);
    setItem(BENEFIT_ROLE, role.key);
    switchUserRole(userRole);

    if (
      isAuthenticatedRole(userRole, UserRolesKeysEnum.HR) &&
      !user.isOnboardingDone
    ) {
      history.replace("/onboarding");
    } else {
      history.replace("/");
    }
  };

  /**
   * Handle role switch dropdown open/close.
   * @param {Event} event
   */
  const handleDropdownOpen = event => {
    event.preventDefault();
    setIsDropdownOpen(true);
  };

  useClickOutside([tooltip], () => setIsDropdownOpen(false));

  return (
    <SwitchPlatformsDropdownStyles>
      <div
        className="roleTooltip"
        ref={tooltip}
        onClick={e => handleDropdownOpen(e)}
      >
        <p className="roleSwitchContainer">
          {t(user?.authenticatedRole?.name)}
        </p>
        {user?.roles?.length > 1 && (
          <div className="tooltipContainer">
            <button type="button" className="arrowButtons">
              <img src={ArrowDown} alt={"arrowDown"} />
            </button>
            {isDropdownOpen && (
              <div className="tooltip tooltipRole">
                <div className="pointerBorder"></div>
                <div className="pointer"></div>
                {user.roles
                  .filter(role => role.key !== user.authenticatedRole.key)
                  .map(role => (
                    <p
                      className="tooltipOption"
                      key={role.id}
                      onClick={() => handleSwitchUserRole(role)}
                    >
                      {t(role.name)}
                    </p>
                  ))}
              </div>
            )}
          </div>
        )}
      </div>
      {authenticatedRole?.id !== 2 && (
        <div className="lngDropdown">
          <LanguageDropdown />
        </div>
      )}
    </SwitchPlatformsDropdownStyles>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    switchUserRole: roleObject =>
      dispatch(actionCreators.switchUserRole(roleObject))
  };
};

SwitchPlatformsDropdown.propTypes = {
  user: PropTypes.shape({}),
  switchUserRole: PropTypes.func
};

export default connect(undefined, mapDispatchToProps)(SwitchPlatformsDropdown);
