import React from 'react'
import styled from 'styled-components'

const HelpIconSvgStyles = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`

const HelpIconSvg = () => (
	<HelpIconSvgStyles>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="13"
			height="13"
			viewBox="0 0 13 13"
		>
			<path
				fill="#FFF"
				fillRule="evenodd"
				d="M6.34 9.73c.385 0 .73-.313.73-.707a.719.719 0 00-.73-.707c-.402 0-.74.314-.74.707 0 .386.346.707.74.707m.016-1.928c.361 0 .578-.225.578-.506v-.04c0-.346.21-.563.635-.844.595-.386 1.077-.755 1.077-1.543 0-1.117-.989-1.68-2.081-1.68-1.11 0-1.84.5-2.033 1.053-.04.113-.065.226-.065.338 0 .313.258.49.49.49.41 0 .467-.217.708-.482.24-.29.506-.426.851-.426.53 0 .876.297.876.755 0 .426-.273.62-.811.989-.434.305-.804.643-.804 1.285v.04c0 .378.201.571.579.571m.128 3.945a5.231 5.231 0 01-5.247-5.263c0-2.924 2.322-5.263 5.24-5.263a5.252 5.252 0 015.27 5.263 5.235 5.235 0 01-5.263 5.263m0 1.222c3.544 0 6.492-2.941 6.492-6.485C12.976 2.941 10.02 0 6.476 0 2.941 0 0 2.94 0 6.484s2.949 6.485 6.484 6.485"
			/>
		</svg>
	</HelpIconSvgStyles>
)

export default HelpIconSvg
