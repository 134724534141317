import styled from "styled-components";

const GlobalErrorBoundaryFallbackStyles = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .mainContent {
    display: flex;
    display: flex;
    flex-direction: column;
    gap: 20px;

    p {
      font-size: 30px;
      color: #1568bf;
      font-weight: 700;
    }

    button {
      border: 1px solid #1568bf;
      padding: 10px 5px;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      border-radius: 8px;
      color: #1568bf;
      background: white;

      &:hover {
        background: #1568bf;
        color: white;
      }
    }
  }
`;

export default GlobalErrorBoundaryFallbackStyles;
