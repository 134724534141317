import styled from 'styled-components';

const DisableUsersModalStyles = styled.div`
  .contentWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .disableUserTitle {
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 26px;
    text-align: center;
    color: #4b4b4b;
    margin-bottom: 20px;
  }

  .datePickerInput {
    height: 32px;
    width: 194px;
    outline: none;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    border-color: 1px solid #d8d8d8;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: center;
    color: #4b4b4b;
    cursor: pointer;
    position: relative;
    margin-top: 20px;

    &:hover {
      border: 1px solid #1568bf;
      z-index: 2;
    }
  }

  width: 544px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 46px 30px 30px 30px;

  @media only screen and (max-width: 767px) {
    width: 100%;
    height: 100vh;
  }
`;

export default DisableUsersModalStyles;