import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import { Card } from "../../../../../components";

// Services
import { truncate } from "../../../../../services/truncateString";

// Components
import BenefitDetailsActions from "../../../../SuperAdminPages/Providers/shared/BenefitDetails/BenefitDetailsActions/BenefitDetailsActions";
// Icons
import { TokenIcon, DotsIcon } from "../../../../../assets/Icons";

import BenefitDetailsStyles from "./BenefitDetailsStyles";
import { useTranslation } from "react-i18next";
import {
  transformTokenCurrencyIcons,
  transformTokensToRsd
} from "../../../../../utils/transformTokensToRsd";

const BenefitDetails = ({
  benefit,
  setChosenBenefitId,
  openModal,
  fetchData
}) => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const myTooltip = useRef();

  const onClickDots = () => {
    setIsActive(true);
    ReactTooltip.rebuild();
  };

  const handleBlur = () => {
    setIsActive(false);
    ReactTooltip.rebuild();
  };

  const openEditBenefit = benefitId => {
    setChosenBenefitId(Number(benefitId));
    openModal("editLocalBenefitOfBenefitGroup");
  };

  const isInRSD = localStorage.getItem("isValueInRsd");

  return (
    <BenefitDetailsStyles>
      {benefit && (
        <Card
          padding="30px"
          boxShadow="
					0 2px 4px -1px rgba(0, 0, 0, 0.2),
					0 1px 10px 0 rgba(0, 0, 0, 0.12),
					0 4px 5px 0 rgba(0, 0, 0, 0.14)
				"
        >
          <div className="benefitDetailsContet">
            <div className="titleAndIcons">
              <h3 className="title">{truncate(benefit.title, 20)}</h3>
              {benefit.isDraft && (
                <span className="draftIndicator">{t("Draft")}</span>
              )}
              <button
                type="button"
                className="actionsCol detailsActionsButton"
                data-tip={`${benefit.id}|${benefit.isDraft}`}
                data-event={isActive ? "focus" : "click"}
                data-event-off={!isActive ? "focusout" : ""}
                onClick={e => {
                  e.stopPropagation();
                  onClickDots();
                }}
                onBlur={() => handleBlur()}
              >
                <DotsIcon />
              </button>
              <ReactTooltip
                place="bottom"
                effect="solid"
                // eslint-disable-next-line
                ref={ref => (myTooltip.current = ref)}
                className="tooltip"
                getContent={dataTip => {
                  if (!dataTip) {
                    return "";
                  }

                  const [id, draft] = dataTip.split("|");

                  return (
                    <BenefitDetailsActions
                      openEditBenefit={() => openEditBenefit(id)}
                      benefitId={id}
                      isDraft={JSON.parse(draft)}
                      hideTooltip={() => {
                        myTooltip.current.tooltipRef = null;
                        ReactTooltip.hide();
                      }}
                      fetchData={fetchData}
                    />
                  );
                }}
                border
                clickable
              />
            </div>
            <p className="description">{benefit.summary}</p>
            <div className="tokensContainer">
              <p className="tokens">
                <span style={{ fontWeight: 500 }}>{t("Budget")}:</span>
              </p>
              <p className="tokens" style={{ margin: "0 10px" }}>
                {transformTokensToRsd(benefit.tokenPrice)}
              </p>
              {transformTokenCurrencyIcons()}
            </div>
            <div className="durationContainer">
              <p className="duration">{t("duration_type")}:</p>
              <p className="durationValue">{t(benefit.expiration)}</p>
            </div>
          </div>
        </Card>
      )}
    </BenefitDetailsStyles>
  );
};

BenefitDetails.propTypes = {
  benefit: PropTypes.shape(),
  setChosenBenefitId: PropTypes.func,
  openModal: PropTypes.func
};

export default BenefitDetails;
