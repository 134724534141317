class Company {
	constructor(companyData = {}) {
		this.id = companyData.id || null
		this.name = companyData.name || 'No Company relations'
	}
}

class Tier {
	constructor(tierData = {}) {
		this.id = tierData.id || null
		this.name = tierData.name || ''
	}
}

class NewUser {
	constructor(newUserData = {}) {
		this.firstName = newUserData.firstName || ''
		this.lastName = newUserData.lastName || ''
		this.email = newUserData.email || ''
		this.password = newUserData.password || ''
		this.roleId = newUserData.roleId || undefined
		this.companyId = newUserData.companyId || undefined
	}
}

class User {
	constructor(userData = {}) {
		this.id = userData.id || null
		this.firstName = userData.firstName || ''
		this.lastName = userData.lastName || ''
		this.email = userData.email || ''
		this.emailConfirmed = userData.emailConfirmed || false
		this.role = userData.role || ''
		this.employeeId = userData.employeeId || ''
		this.company = userData.Company ? new Company(userData.Company) : new Company()
	}
}

export { User, Company, NewUser, Tier }
