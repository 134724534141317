import React from "react";
import PropTypes from "prop-types";
// components
import { Card } from "../../../../components";
// services
import { closeAddUser } from "../users.services";
// context
import { AddUserForm } from "../../../../components/forms/user/AddUserForm";

const AddUser = ({ setIsAddUserVisible, fetchUsers }) => {
  return (
    <Card>
      <AddUserForm
        backButtonFunc={() => closeAddUser(setIsAddUserVisible)}
        fetchUsers={fetchUsers}
      />
    </Card>
  );
};

AddUser.propTypes = {
  setIsAddUserVisible: PropTypes.func,
  fetchUsers: PropTypes.func
};

export default AddUser;
