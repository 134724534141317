import React, { useState } from "react";
import styled from "styled-components";
import { Card, Button, SelectInput, InputLabel } from "../../../../components";
import * as dashboardService from "../../../../services/api/hr/dashboard.service";
import { reportTypeDropdownOptions } from "../../../SuperAdminPages/Dashboard/reportTypeDropdownOptions";
import { connect, useSelector } from "react-redux";
import debounce from "../../../../utils/helpers/debounce";

import {
  setDownloadPercentage,
  setIsRefundDownloading
} from "../../../../actions/app.actions";
import { useTranslation } from "react-i18next";

const UsageReportGeneratorStyles = styled.div`
  margin: 0 20px 20px 0;
  order: 7;
  width: 400px;
`;

const RefundReport = props => {
  const {
    setDownloadPercentage,
    isDownloading,
    setIsRefundDownloading,
    downloadPercent
  } = props;
  const { t } = useTranslation();
  const [reportType, setReportType] = useState(undefined);

  const companyId = useSelector(state => state.app.user.companyId);

  const getStatistics = () => {
    setIsRefundDownloading(true);
    dashboardService.getRefundStatistics(
      reportType,
      companyId,
      setIsRefundDownloading,
      setDownloadPercentage
    );
  };

  const debouncedGetStatistic = debounce(getStatistics, 777);

  return (
    <UsageReportGeneratorStyles>
      <Card>
        <div className="titleContainer">
          <h3 className="title">{t("gen_ref_rep")}</h3>
        </div>
        {isDownloading ? (
          <div>
            <div
              style={{
                height: "122px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <div>
                {t("downloading")}: {downloadPercent}%
              </div>
            </div>
          </div>
        ) : (
          <>
            <div style={{ marginTop: 10, marginBottom: 10 }}>
              <InputLabel label={t("select_report_type")} margin="0 0 5px 0" />
              <SelectInput
                name="reportTypes"
                options={reportTypeDropdownOptions(t)}
                selectedValues={reportTypeDropdownOptions(t)}
                value={reportType}
                handleChange={option => setReportType(option.id)}
                customId="reportTypeId"
                shouldHaveHight={false}
                shouldHaveFullWidth
                customHeight="27px"
              />
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                margin="0"
                width="200px"
                disabled={!reportType || isDownloading}
                onClick={debouncedGetStatistic}
              >
                {t("download")}
              </Button>
            </div>
          </>
        )}
      </Card>
    </UsageReportGeneratorStyles>
  );
};

const mapStateToProps = state => {
  return {
    isDownloading: state.app.isDownloading,
    downloadPercent: state.app.downloadPercent
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setIsRefundDownloading: payload =>
      dispatch(setIsRefundDownloading(payload)),
    setDownloadPercentage: payload => dispatch(setDownloadPercentage(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RefundReport);
