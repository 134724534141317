import styled from 'styled-components'

const AddBenefitGroupStyles = styled.div`
	.title {
		width: 100%;
		font-size: 24px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #4b4b4b;
		margin: 30px 0;
	}

	.form {
		width: 481px;
		height: calc(100vh - 120px);	
		overflow-y: auto;
		overflow-x: hidden;
	}
`

export default AddBenefitGroupStyles
