import styled from "styled-components";

const OnboardingStyles = styled.section`
  background: #f7f7f7;
  width: 100%;
  height: 100vh;
  padding: 20px;

  .cardComponent {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .content {
    height: calc(100vh - 80px);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .skipOnboarding {
    position: absolute;
    right: 0;
    top: 0;
    color: #0000ee;
    text-decoration: underline;
    text-transform: uppercase;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    outline: none;
  }

  .onboardingContent {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .stepsSlider {
      width: 500px;
      height: 590px;
      overflow-x: hidden;
    }

    .stepsContainer {
      width: 1520px;
      height: 590px;
      display: flex;
      transition: 0.3s all ease-in-out;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  .onboardingSlider {
    display: flex;
    width: 400px;
    height: 25px;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: -15px;
    left: calc(50% - 200px);
  }

  .dotsContainer {
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dots {
    width: 7px;
    height: 7px;
    border-radius: 3.5px;
    border: 1px solid #4b4b4b;
  }

  .activeDot {
    border: 1px solid #1568bf;
    background: #1568bf;
  }

  .button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
  }
`;

export default OnboardingStyles;
