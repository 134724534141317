import { Company, Tag } from "../../models/domain";
import * as actionTypes from "../../constants/actionTypes/admin/companies.constants";
import { updateObject } from "../../services/updateObject";

const initialState = {
  allTags: [new Tag()],
  selectedCompany: new Company({ id: "all", name: "All" }),
  singleCompany: new Company(),
  companies: [new Company()],
  page: 1,
  count: 0,
  search: ""
};

const companiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_COMPANIES:
      return setCompanies(state, action);
    case actionTypes.ADD_NEW_COMPANY:
      return addNewCompany(state, action);
    case actionTypes.DELETE_COMPANY:
      return deleteCompany(state, action);
    case actionTypes.SET_SELECTED_COMPANY:
      return setSelectedCompany(state, action);
    case actionTypes.SET_PAGE:
      return setPage(state, action);
    case actionTypes.SEARCH_CHANGE:
      return setSearch(state, action);
  }

  return state;
};

const setCompanies = (state, { companies, count }) => {
  return updateObject(state, {
    companies: companies,
    count: count
  });
};

const addNewCompany = (state, { company }) => {
  return updateObject(state, {
    companies: [...state.companies, company]
  });
};

const deleteCompany = (state, { id }) => {
  return updateObject(state, {
    companies: state.companies.filter(company => company.id !== id)
  });
};

const setSelectedCompany = (state, { id }) => {
  return updateObject(state, {
    selectedCompany: state.companies.find(company => company.id === id)
  });
};

const setPage = (state, { page }) => {
  return updateObject(state, {
    page: page
  });
};

const setSearch = (state, { search }) => {
  return updateObject(state, {
    search: search
  });
};

export default companiesReducer;
