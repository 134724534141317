import styled from "styled-components";

const TwoThumbsStyles = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 8px;

  .sliderContainer {
    height: 36px;
    display: flex;
    width: 100%;     
    
    .slider {      
      height: 5px;
      width: 100%;
      border-radius: 4px;
      align-self: center;
      position: relative;
    }

    .thumb {
      height: 16px;
      width: 16px;
      border-radius: 50%;
      background-color: #2F68B9;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: none;
    }
  }
    
`;

export default TwoThumbsStyles;