import React from "react";
import PropTypes from "prop-types";
// components
import { TextAndLine, ImageUpload, InputLabel } from "../../../../components";
// styles
import DetailsSectionStyles from "./DetailsSectionStyles";
// services
import { getUrl } from "../../../../services/imageHandler";
import { useTranslation } from "react-i18next";

const DetailsSection = ({
  isFormDisplay = false,
  values,
  errors,
  touched,
  setFieldError,
  setFieldTouched,
  setFieldValue
}) => {
  const { t } = useTranslation();

  return (
    <DetailsSectionStyles isFormDisplay={isFormDisplay}>
      <TextAndLine title={t("Details")} />
      <div className="photoAndStatusContainer">
        <div className="labelAndValidation">
          <InputLabel margin="0 0 5px 0" label={t("Company logo")} />
          {isFormDisplay && (
            <div className="errorMsg" style={{ marginBottom: 5 }}>
              {errors.photo && touched.photo ? errors.photo : ""}
            </div>
          )}
        </div>
        <div style={{ height: 225, overflow: "hidden", background: "#d8d8d8" }}>
          {isFormDisplay ? (
            <ImageUpload
              setFieldError={setFieldError}
              name="photo"
              onBlur={setFieldTouched}
              photo={values.photo}
              handleChange={setFieldValue}
            />
          ) : (
            <img
              src={getUrl(values.photo)}
              alt={t("Company logo")}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          )}
        </div>
        <InputLabel
          margin="5px 0 0 0"
          label={t("upload_logo")}
          shouldHaveColon={false}
        />
      </div>
    </DetailsSectionStyles>
  );
};

DetailsSection.propTypes = {
  isFormDisplay: PropTypes.bool,
  values: PropTypes.shape(),
  errors: PropTypes.shape(),
  touched: PropTypes.shape(),
  setFieldError: PropTypes.func,
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func
};

export default DetailsSection;
