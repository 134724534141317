import User from "../../domain/User.entity";

class LoginResponse {
  constructor(response = {}) {
    this.accessToken = response.data.auth.access_token;
    this.refreshToken = response.data.auth.refresh_token;
    this.user = new User(response.data.user);
  }
}

export default LoginResponse;
