import styled from 'styled-components'

const DetailsSectionStyles = styled.div`
	.photoAndStatusContainer {
		margin: 30px 0;

		.labelAndValidation {
			display: flex;
			align-items: center;
			justify-content: space-between;
            
        .errorMsg {
          margin-bottom: 5px;
        }
		}

    .imgContainer {
      & > div {
        width: 360px;
        height: 150px;
      }

      .image {
        width: 85%;
        height: 100%; 
        object-fit: contain;
      }

      svg:hover {
        stroke: #ce1212;
      }
    }
	}
`

export default DetailsSectionStyles