import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";

import { validateForgotPassword } from "../../../services/validations/publicPages/forgotPassword.validation";
import { LoginFormStyles } from "../login/LoginFormStyles";
import { BenefitLogo } from "../../../assets/Icons";
import Button from "../../button";
import * as authService from "../../../services/api/auth.service";
import { useTranslation } from "react-i18next";

export const ForgotPasswordForm = () => {
  const { t } = useTranslation();

  const handleSubmitForgotPassword = async (values, { resetForm }) => {
    const response = await authService.forgotPassword(values);

    if (response.hasError) {
      return toast.error(
        response.errorMessage ? response.errorMessage : "Something went wrong"
      );
    }

    resetForm();
    toast.success(response.message);
  };

  const formik = useFormik({
    initialValues: {
      email: ""
    },
    validate: validateForgotPassword,
    onSubmit: handleSubmitForgotPassword
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur
  } = formik;

  return (
    <LoginFormStyles>
      <div className="authWindow">
        <BenefitLogo color="#1568bf" />
        <div className="formContainer">
          <form onSubmit={handleSubmit} className="form" autoComplete="off">
            <label className="label" htmlFor="email">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              >
                Email:
                <div className="errorMsg">
                  {errors.email && touched.email ? errors.email : ""}
                </div>
              </div>
              <input
                className="inputs"
                id="email"
                name="email"
                type="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </label>

            <div className="buttonContainer">
              <div>
                <Link to="/" className="forgotPassword">
                  {t("go_back")}
                </Link>
              </div>
              <Button type="submit" margin="0" padding="10px 22px">
                {t("submit_1")}
              </Button>
            </div>
          </form>
        </div>
      </div>
      <div className="bannerWindow" />
    </LoginFormStyles>
  );
};

export default ForgotPasswordForm;
