class Tier {
  constructor(tierData = {}) {
    this.id = tierData.id || null;
    this.name = tierData.name || "";
    this.description = tierData.description || "";
    this.companyId = tierData.companyId || null;
    this.tokens = tierData.tokens || 0;
    this.countEmployee = tierData.countEmployee || 0;
  }
}

export default Tier;
