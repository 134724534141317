import UserRole from "../../domain/UserRole.entity";

class RolesResponse {
  constructor(response = {}) {
    this.roles = response.data.roles?.map(
      role => new UserRole(role)
    );
  }
}

export default RolesResponse;
