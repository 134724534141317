import React from "react";
import ReactTooltip from "react-tooltip";
// styles
import { BenefitSubscribeButtonsContainerStyles } from "./benefitSubscribeButtonsContainer.styles";
// components
import BenefitSubscribeButton from "./BenefitsSubscribeButton";
import VoucherSubscribeButton from "./VoucherSubscribeButton";
// builder constants
import { BenefitExpirationTypeEnum } from "../../../../../constants/benefit.constants";
import { isEmpty } from "../../../../../services/general.utils";
import { useTranslation } from "react-i18next";

const BenefitSubscribeButtonsContainer = ({
  benefit,
  status,
  isPreview = false,
  enableInstalments,
  setIsLoading,
  benefitGroupType,
  setShowPDFBill,
  setVoucherReimbursementData
}) => {
  const { t } = useTranslation();

  return (
    <BenefitSubscribeButtonsContainerStyles
      doesHaveMinimumContractPeriod={benefit.minimumContractPeriod}
      enableInstalments={enableInstalments}
    >
      {!isEmpty(benefit.minimumContractPeriod) && (
        <>
          <small
            className="minimum-contract-period"
            data-tip
            data-for="mcpTooltip"
            data-tooltip-place="bottom"
          >
            {t("mcp_period_info", { month: benefit.minimumContractPeriod })}
          </small>
          <ReactTooltip
            id="mcpTooltip"
            place="bottom"
            effect="solid"
            className="mcpTooltip"
            backgroundColor="black"
            borderColor="black"
            arrowColor="black"
          >
            <span style={{ color: "black" }}>{t("mcp_take_warning")}</span>
          </ReactTooltip>
        </>
      )}
      {enableInstalments && benefit.instalments && (
        <small className="minimum-contract-period">{t("mcp_take_info")}</small>
      )}
      {benefit && benefit.expiration === BenefitExpirationTypeEnum.VOUCHER ? (
        <VoucherSubscribeButton
          benefit={benefit}
          status={status}
          isPreview={isPreview}
          setIsLoading={setIsLoading}
          setShowPDFBill={setShowPDFBill}
          setVoucherReimbursementData={setVoucherReimbursementData}
        />
      ) : (
        <BenefitSubscribeButton
          benefit={benefit}
          status={status}
          isPreview={isPreview}
          setIsLoading={setIsLoading}
          benefitGroupType={benefitGroupType}
        />
      )}
    </BenefitSubscribeButtonsContainerStyles>
  );
};

export default BenefitSubscribeButtonsContainer;
