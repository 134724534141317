import { apiService } from "../api.service";
import { TagsResponse } from "../../../models/server/responses";
import { TagPayload } from "../../../models/server/payloads";
import { Tag } from "../../../models/domain";

export const getBenefitTags = async () => {
  let response = await apiService.get(`/benefit/tags`);

  if (response.hasError) return response;

  return new TagsResponse(response);
};

export const getBenefitTag = async id => {
  let response = await apiService.get(`/benefit/tag/${id}`);

  if (response.hasError) return response;

  return new Tag(response.data);
};

export const addBenefitTag = async values => {
  let response = await apiService.post("/benefit/tag", new TagPayload(values));

  if (response.hasError) return response;

  return new Tag(response.data);
};

export const editBenefitTag = async (values, chosenId) => {
  let response = await apiService.put(`/benefit/tag/${chosenId}`, new TagPayload(values));

  if (response.hasError) return response;

  return new Tag(response.data);
};

export const removeBenefitTag = async id => {
  let response = await apiService.delete(`/benefit/tag/${id}`);

  return response;
};
