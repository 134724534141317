import BenefitGroup from "./BenefitGroup.entity";
import { PngPhoto } from "../../services/global.entities";
import { isEmpty } from "../../services/general.utils";

class Provider {
  constructor(providerData = {}) {
    this.id = providerData.id || null;
    this.name = providerData.name || "";
    this.description = providerData.description || "";
    this.summary = providerData.summary || "";
    this.isActive = !isEmpty(providerData.isActive) ? providerData.isActive : true;
    this.photo = providerData.Photo ? new PngPhoto(providerData.Photo) : null;
    this.benefitGroups = providerData.BenefitGroup
      ? providerData.BenefitGroup.map(
        benefitGroup => new BenefitGroup(benefitGroup)
      )
      : [];
  }
}

export default Provider;
