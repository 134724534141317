import { findLocales } from "../../../utils/helpers/findLocales";
import { isEmpty, removeSpacesAndHTML } from "../../general.utils";

export const addBonusFormValidation = ({
  subject,
  message,
  tokens,
  employeeId,
  employees,
  recurring,
  recurringType
}) => {
  const errors = {};
  const isInLanguage = findLocales("rs");

  if (subject && removeSpacesAndHTML(subject).length > 50) {
    errors.subject = isInLanguage ? "Predugo" : "Too long";
  }

  if (message && removeSpacesAndHTML(message).length > 1000) {
    errors.message = isInLanguage ? "Predugo" : "Too long";
  }

  if (!tokens) {
    errors.tokens = isInLanguage ? "*Obavezno polje" : "* Required";
  }

  if (!employeeId && (!employees || employees.length === 0)) {
    errors.employees = isInLanguage ? "*Obavezno polje" : "* Required";
  }

  if (recurring) {
    if (isEmpty(recurringType)) {
      errors.recurringType = isInLanguage ? "*Obavezno polje" : "* Required";
    }
  }

  return errors;
};
