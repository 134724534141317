import { EmployeeUser, HrUser, AdminUser, Company } from "./editUser.entities";
import { User, Employee } from "../../../models/domain";
// sync functions

export const findUserRole = role => {
  if (role === "Member") return new Employee();
  return new User();
};

// consts

export const relationshipOptions = [
  { id: 1, name: "Single" },
  { id: 2, name: "Married" },
  { id: 3, name: "Divorced" }
];

export const genderOptions = [
  { id: 1, name: "Male" },
  { id: 2, name: "Female" },
  { id: 3, name: "Other" }
];
