import { convertEditorStateToString } from "../../textEditor.utils";
import { isEmpty, removeSpacesAndHTML } from "../../general.utils";
import { findLocales } from "../../../utils/helpers/findLocales";

export const validateHrLocalBenefitGroupCreate = ({
  name,
  categories,
  description,
  photo,
  capacity,
  summary
}) => {
  const errors = {};
  const isInLanguage = findLocales("rs");

  if (!name.trim()) {
    errors.name = isInLanguage ? "*Obavezno polje" : "* Required";
  } else if (removeSpacesAndHTML(name).length > 30) {
    errors.name = isInLanguage ? "Predugo" : "Too long";
  } else if (!removeSpacesAndHTML(name)) {
    errors.name = isInLanguage
      ? "HTML oznaka nije dozvoljena"
      : "HTML tag is not allowed";
  }

  if (isEmpty(categories)) {
    errors.categories = isInLanguage ? "*Obavezno polje" : "* Required";
  }

  const descriptionString = convertEditorStateToString(description);
  const tagRegex = /<.+?>/g;
  const formattedDescription = descriptionString.replace(tagRegex, "").trim();
  if (formattedDescription.length > 1001) {
    errors.description = isInLanguage ? "Predugo" : "Too long";
  }

  if (!summary.trim()) {
    errors.summary = isInLanguage ? "*Obavezno polje" : "* Required";
  } else if (removeSpacesAndHTML(summary).length < 20) {
    errors.summary = isInLanguage ? "Prekratko" : "Too short";
  } else if (removeSpacesAndHTML(summary).length > 90) {
    errors.summary = isInLanguage ? "Predugo" : "Too long";
  } else if (!removeSpacesAndHTML(summary)) {
    errors.summary = isInLanguage
      ? "HTML oznaka nije dozvoljena"
      : "HTML tag is not allowed";
  }

  if (!photo) {
    errors.photo = isInLanguage ? "*Obavezno polje" : "* Required";
  }

  return errors;
};
export const validateHrLocalBenefitCreate = ({
  title,
  tokenPrice,
  expiration,
  description,
  summary,
  vouchers,
  quantity
}) => {
  const errors = {};
  const isInLanguage = findLocales("rs");

  if (!title.trim()) {
    errors.title = isInLanguage ? "*Obavezno polje" : "* Required";
  } else if (removeSpacesAndHTML(title).length > 30) {
    errors.title = isInLanguage ? "Predugo" : "Too long";
  } else if (!removeSpacesAndHTML(title)) {
    errors.title = isInLanguage
      ? "HTML oznaka nije dozvoljena"
      : "HTML tag is not allowed";
  }

  if (!expiration) {
    errors.expiration = isInLanguage ? "*Obavezno polje" : "* Required";
  }

  if (expiration !== "voucher") {
    if (tokenPrice === "" || tokenPrice === null || tokenPrice === undefined) {
      errors.tokenPrice = isInLanguage ? "*Obavezno polje" : "* Required";
    }
  }

  if (expiration === "voucher") {
    if (vouchers.length === 0) {
      errors.vouchers = isInLanguage ? "Dodajte vaučer" : "Please add voucher";
    } else {
      vouchers.forEach(voucher => {
        if (voucher.name === "" || voucher.price === "") {
          errors.vouchers = isInLanguage
            ? "Morate popuniti sva polja"
            : "You need to fill all the fields";
        }
      });
    }
  }

  const descriptionString = convertEditorStateToString(description);
  const tagRegex = /<.+?>/g;
  const formattedDescription = descriptionString.replace(tagRegex, "").trim();
  if (formattedDescription.length > 1001) {
    errors.description = isInLanguage ? "Predugo" : "Too long";
  }

  if (!summary.trim()) {
    errors.summary = isInLanguage ? "*Obavezno polje" : "* Required";
  } else if (removeSpacesAndHTML(summary).length < 20) {
    errors.summary = isInLanguage ? "Prekratko" : "Too short";
  } else if (removeSpacesAndHTML(summary).length > 90) {
    errors.summary = isInLanguage ? "Predugo" : "Too long";
  } else if (!removeSpacesAndHTML(summary)) {
    errors.summary = isInLanguage
      ? "HTML oznaka nije dozvoljena"
      : "HTML tag is not allowed";
  }

  if (!isEmpty(quantity) && quantity <= 0) {
    errors.quantity = isInLanguage
      ? "Minimalna vrednost za količinu je 1"
      : "Minimum value for quantity is 1";
  }

  // if (!photo) {
  //   errors.photo = '* Required';
  // }

  return errors;
};
