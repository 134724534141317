import styled from "styled-components";

const ToggleSliderStyles = styled.div`
  .toggle-slider {
    position: relative;
    width: 52px;
    height: 22px;
  }

  .slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ed3f55;
    border-radius: 34px;
    cursor: pointer;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 2px;
    background-color: white;
    border-radius: 50%;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"]:checked + .slider {
    background-color: #19cc95;
  }

  input[type="checkbox"]:checked + .slider:before {
    transform: translateX(26px);
  }
`;

export default ToggleSliderStyles;
