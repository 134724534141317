import styled from 'styled-components'

const PhoneNumberInputStyles = styled.div`
	width: 100%;
	height: 27px;

	.react-tel-input{
		width: 100%;
		height: 100%;

		 input {
			width: 100% !important;
			height: 100% !important;
			outline: none !important;
			border-radius: 4px !important;
			border: solid 1px #979797 !important;
			font-size: 14px !important;
			font-weight: 500 !important;
			font-stretch: normal !important;
			font-style: normal !important;
			line-height: normal !important;
			-webkit-letter-spacing: normal !important;
			-moz-letter-spacing: normal !important;
			-ms-letter-spacing: normal !important;
			letter-spacing: normal !important;
			color: #5d5d5d !important;
			position: relative !important;
		 }

		 .flag-dropdown {
				border: solid 1px #979797 !important;
				border-radius: 4px 0 0 4px !important;

				.selected-flag {
					border-radius: 4px 0 0 4px !important;
				}
			}
	}
`

export default PhoneNumberInputStyles
