import styled from 'styled-components'

const CompanyThemeFormStyles = styled.section`
  padding: 20px 0;
	margin: 20px 0;
  position: relative;

  .themeListContainer {
	  padding: 20px;
  } 

  .photoContainer {
    display: flex;
    align-items: center;
  }
`

export default CompanyThemeFormStyles 