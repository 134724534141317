import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const InputLabelStyles = styled.p`
  font-size: ${({ fontSize }) => fontSize};
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
	color: #5d5d5d;
	margin: ${({ margin }) => margin};
`

const InputLabel = ({ label, margin, fontSize = '12px', customCssClass, shouldHaveColon = true }) => (
	<InputLabelStyles margin={margin} fontSize={fontSize} className={customCssClass}>
		{label}{shouldHaveColon ? ':' : ''}
	</InputLabelStyles>
)

InputLabel.propTypes = {
	label: PropTypes.string,
	margin: PropTypes.string,
	customCssClass: PropTypes.string,
	shouldHaveColon: PropTypes.bool,
	fontSize: PropTypes.string
}


export default InputLabel
