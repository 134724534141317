import styled from "styled-components";

const HrBenefitsStyles = styled.section`
  .filtersHeader {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .hrBenefitsContainer {
    width: calc(100% - 210px);
    margin-left: 210px;
    background: whitesmoke;
    min-height: 100vh;
  }

  .contentSection {
    padding: 0 30px 30px 30px;
  }

  #benefitGroupList {
    opacity: 0;
    transition: all 0.4s ease-in-out;
  }

  .benefitList {
    display: flex;
    max-width: 1300px;
    flex-wrap: wrap;
    margin: 30px 0 0 0;

    &:last-child {
      margin: 30px 0 0 0;
    }
  }

  .filtersHeader {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    .filters {
      display: flex;
      gap: 25px;
      align-items: baseline;
    }
  }

  .benefitsSearch {
		width: 250px !important;
	}
`;

export default HrBenefitsStyles;
