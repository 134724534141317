const openModalAnim = () => {
	const getEl = document.getElementById('modalCard')
	if (getEl) {
		getEl.classList.add('anim')
	}
}

const closeModalAnim = () => {
	const getEl = document.getElementById('modalCard')
	if (getEl) {
		getEl.classList.remove('anim')
	}
}

const openRatingModalAnim = () => {
	const getEl = document.getElementById('ratingModalCard')
	if (getEl) {
		getEl.classList.add('anim')
	}
}

const closeRatingModalAnim = () => {
	const getEl = document.getElementById('ratingModalCard')
	if (getEl) {
		getEl.classList.remove('anim')
	}
}

export { openModalAnim, closeModalAnim, openRatingModalAnim, closeRatingModalAnim }
