import React from 'react'
import PropTypes from 'prop-types'

import { ButtonHolder } from './ButtonStyles'

const Button = ({
	children,
	type = 'button',
	onClick,
	padding = '12px',
	margin = '30px',
	mobileMargin,
	outline = false,
	height,
	disabled = false,
	width,
	mobileWidth,
	customId,
	customClass,
	borderRadius = "4px",
	borderRight,
	transparent = false,
	color,
	gap, 
}) => (
	<ButtonHolder
		type={type}
		margin={margin}
		mobileMargin={mobileMargin}
		outline={outline}
		padding={padding}
		onClick={onClick}
		height={height}
		width={width}
		mobileWidth={mobileWidth}
		id={customId}
		className={customClass}
		disabled={disabled}
		borderRadius={borderRadius}
		borderRight={borderRight}
		transparent={transparent}
		color={color}
		gap={gap}
	>
		{children}
	</ButtonHolder>
)

Button.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.elementType,
		PropTypes.array,
		PropTypes.element
	]),
	padding: PropTypes.string,
	margin: PropTypes.string,
	mobileMargin: PropTypes.string,
	onClick: PropTypes.func,
	type: PropTypes.string,
	outline: PropTypes.bool,
	disabled: PropTypes.bool,
	height: PropTypes.string,
	width: PropTypes.string,
	mobileWidth: PropTypes.string,
	customId: PropTypes.string,
	customClass: PropTypes.string,
	borderRadius: PropTypes.string,
	borderRight: PropTypes.string,
	transparent: PropTypes.bool,
	color: PropTypes.string,
	gap: PropTypes.string,
}

export default Button
