import { apiService } from "../api.service";
import {
  HrEmployeesResponse,
  TiersResponse,
  BranchesResponse
} from "../../../models/server/responses";
import {
  EmployeePayload,
  EmployeeFromCsvPayload,
  BonusCreatePayload
} from "../../../models/server/payloads";
import { HrEmployeeDetails } from "../../../models/domain";
import { isEmpty } from "../../general.utils";
import CitiesResponse from "../../../models/server/responses/Cities.response";
import { queryBuilder } from "../../../utils/queryBuilder";

export const getCompanyEmployees = async ({
  limit,
  page,
  searchText,
  orderColumns,
  tierIds,
  chosenBudgetRanges
}) => {
  const budgetsPercents = chosenBudgetRanges
    ? chosenBudgetRanges.reduce(
        (query, range) =>
          `${!isEmpty(query) ? `${query}&` : ""}from=${
            range.from
          }${`&to=${range.to}`}`,
        ""
      )
    : "";

  const tiers = Array.isArray(tierIds)
    ? tierIds.reduce(
        (query, tier) => `${!isEmpty(query) ? `${query}&` : ""}tier_id=${tier}`,
        ""
      )
    : "";

  const sortColumns = orderColumns.map(order => {
    return `order=${order.field}&direction=${
      order.isSortedUp ? "ASC" : "DESC"
    }`;
  });

  let queryBuild = queryBuilder({
    baseQuery: "/hr/employees",
    queryObject: {
      limit,
      page,
      search: searchText
    }
  });

  queryBuild += `&${tiers}`;
  queryBuild += `${tiers ? (sortColumns ? "&" : "") : ""}${sortColumns}`;
  queryBuild += `${
    tiers || sortColumns ? (budgetsPercents ? "&" : "") : ""
  }${budgetsPercents}`;

  const response = await apiService.get(queryBuild);

  if (response.hasError) return response;

  return new HrEmployeesResponse(response);
};

export const getCompanyTiers = async () => {
  const response = await apiService.get("/company/tiers");

  if (response.hasError) return response;

  return new TiersResponse(response);
};

export const getCompanyBranches = async () => {
  const response = await apiService.get("/company/branches");

  if (response.hasError) return response;

  return new BranchesResponse(response);
};

export const createEmployee = async values => {
  const payloadData = new EmployeePayload(values);

  const response = await apiService.post("/hr/create-employee", payloadData);

  return response;
};

export const editEmployee = async (values, id) => {
  const payloadData = new EmployeePayload(values);

  const response = await apiService.put(
    `/hr/update-employee/${id}`,
    payloadData
  );

  return response;
};

export const createEmployeesFromCsv = async values => {
  const payloadData = new EmployeeFromCsvPayload(values);

  const response = await apiService.post(
    "/hr/add-employee-from-excel",
    payloadData.payload
  );

  return response;
};

export const updateEmployeesFromCsv = async values => {
  const payloadData = new EmployeeFromCsvPayload(values);

  const response = await apiService.put(
    "/hr/update-employee-from-excel",
    payloadData.payload
  );

  return response;
};

export const getChosenCompanyEmployee = async id => {
  const response = await apiService.get(`/employee/${id}`);

  if (response.hasError) return response;

  return new HrEmployeeDetails(response.data);
};

/**
 * Returns list of cities.
 * @param {number} page
 * @param {number} limit
 * @param {string} search
 * @returns {CitiesResponse}
 */
export const getCities = async (search, page = 1, limit = 20) => {
  const response = await apiService.get(
    `/cities?page=${page}&limit=${limit}&search=${search}`
  );

  if (response.hasError) return response;

  return new CitiesResponse(response);
};

/**
 * Returns list of cities with no limit, pagination or search.
 * @returns {CitiesResponse}
 */
export const getAllCities = async () => {
  const response = await apiService.get(`/cities?page=1&limit=999&search=`);

  if (response.hasError) return response;

  return new CitiesResponse(response);
};

export const addBonus = async values => {
  const payloadData = new BonusCreatePayload(values);

  const response = await apiService.post("/hr/create-bonuses", payloadData);

  return response;
};

export const getCompanyBonusBudget = async () => {
  const response = await apiService.get("/company/bonus-budget/");

  if (response.hasError) return response;
  return response;
};
