import { BenefitExpirationTypeEnum } from "./benefit.constants";

const EN = {
  newest: "Newest / Recently added",
  benefit_group_name: "Benefit Group Name",
  best_rated: "Best Rated",
  one_time: BenefitExpirationTypeEnum.ONE_TIME,
  monthly: BenefitExpirationTypeEnum.MONTH,
  voucher: BenefitExpirationTypeEnum.VOUCHER,
  married: "Married",
  single: "Single",
  divorced: "Divorced",
  male: "Male",
  female: "Female",
  other: "Other",
  created_at: "Created At",
  provider_name: "Provider name",
  three_months: "Three Months",
  six_months: "Six Months",
  yearly: "Yearly"
};

const RS = {
  newest: "Najnovije",
  benefit_group_name: "Ime benefit grupe",
  best_rated: "Najbolje ocenjeno",
  one_time: "Jednokratni",
  monthly: "Mesečni",
  voucher: "Vaučer",
  married: "Oženjen/Udata",
  single: "Slobodan/Slobodna",
  divorced: "Razveden/Razvedena",
  male: "Muški",
  female: "Ženski",
  other: "Drugo",
  created_at: "Kreirano",
  provider_name: "Naziv provajdera",
  three_months: "Tri meseca",
  six_months: "Šest meseci",
  yearly: "Godišnje"
};

const LOCALES = { rs: RS, en: EN };

export default LOCALES;
