import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactHtmlParser from 'react-html-parser';

import BasicInfoStyles from "./BasicInfoStyles";

// Utils
import { getUrl } from "../../../../services/imageHandler";

// Renderers
import { renderLocations } from "../../../../renderers/locations.renderer";
import { doesDescriptionExist } from "../../../../services/textEditor.utils";

const BasicInfo = ({
  benefitGroupLogo = "",
  providerPhoto = "",
  benefitGroupName = "",
  disableTiersComponent,
  benefitGroupDescription = "",
  taxes = "",
  benefitGroupSummary,
  benefitGroupLocations = [],
  isRemote,
  urlSizeCover = 'url'
}) => {
  return (
    <BasicInfoStyles>
      <div className="benefitLogo">
        <img
          src={getUrl(benefitGroupLogo, urlSizeCover)}
          alt="benefit group"
          className="img"
        />
      </div>
      <div className="content">
        <div className="imgContainerOuter">
          <div className="imgContainerInner">
            <img
              src={getUrl(providerPhoto)}
              alt="Provider"
              className="providerImg"
            />
          </div>
        </div>
        <div className="textContent">
          <h2 className="title">{benefitGroupName}</h2>
          <div style={{ margin: "16px 0 27px 0" }}>{disableTiersComponent}</div>
          {doesDescriptionExist(benefitGroupDescription)
            ? (
              <div className="benefitGroupDescription">
                {
                  ReactHtmlParser(benefitGroupDescription)
                }
              </div>
            )
            : (<p className="benefitGroupSummary">{benefitGroupSummary}</p>)
          }
          <div className="taxes">{taxes}</div>
          {renderLocations(benefitGroupLocations, isRemote)}
        </div>
      </div>
    </BasicInfoStyles>
  )
};

BasicInfo.propTypes = {
  benefitGroupLogo: PropTypes.shape(),
  providerPhoto: PropTypes.shape(),
  benefitGroupDescription: PropTypes.string,
  benefitGroupName: PropTypes.string,
  disableTiersComponent: PropTypes.node
};

const mapStateToProps = state => {
  return {
    benefitGroupName: state.benefits.editPageState.benefitGroup.name,
    providerPhoto: state.benefits.editPageState.benefitGroup.provider?.photo,
    benefitGroupLogo: state.benefits.editPageState.benefitGroup.photo,
    benefitGroupSummary: state.benefits.editPageState.benefitGroup.summary,
    benefitGroupDescription:
      state.benefits.editPageState.benefitGroup.description,
    taxes: state.benefits.editPageState.benefitGroup.taxes,
    benefitGroupLocations: state.benefits.editPageState.benefitGroup.cities,
    isRemote: state.benefits.editPageState.benefitGroup.isRemote
  };
};

export default connect(mapStateToProps, null)(BasicInfo);
