import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import { Card } from "../../../../components";
import UsageWidgetStyles from "./UsageWidgetStyles";
import * as dashboardService from "../../../../services/api/hr/dashboard.service";
import { useTranslation } from "react-i18next";

const UsageWidget = ({ companyName, setUsageLoading }) => {
  const { t } = useTranslation();
  const [values, setValues] = useState({});

  const handleFetchCompanyUsage = async () => {
    const response = await dashboardService.fetchCompanyUsage();
    setUsageLoading(false);

    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : t("failed_to_get_company_usage")
      );
    }

    setValues(response);
  };

  useEffect(() => {
    handleFetchCompanyUsage();
  }, []);

  return (
    <UsageWidgetStyles>
      <Card padding="19px">
        <div style={{ height: 185 }}>
          <div className="titleContainer">
            <h3 className="title">{companyName}</h3>
            <h3 className="title">{t("usage_statistics")}</h3>
          </div>
          <div className="statisticsContainer">
            <div className="statisticItem">
              <h4 className="statisticName">{t("total_users")}</h4>
              <p>{values.totalUsers}</p>
            </div>
            <div className="statisticItem">
              <h4 className="statisticName">HR</h4>
              <p>{values.totalHr}</p>
            </div>
            <div className="statisticItem">
              <h4 className="statisticName">{t("Employees")}</h4>
              <p>{values.totalEmployee}</p>
            </div>
            <div className="statisticItem">
              <h4 className="statisticName">
                {t("employees_using_the_system")}
              </h4>
              <p>{values.employeeOnPlatform}</p>
            </div>
            <div className="statisticItem">
              <h4 className="statisticName">
                {t("employees_not_using_the_system")}
              </h4>
              <p>{values.employeeNotOnPlatform}</p>
            </div>
          </div>
        </div>
      </Card>
    </UsageWidgetStyles>
  );
};

UsageWidget.propTypes = {
  companyName: PropTypes.string,
  setUsageLoading: PropTypes.func
};

export default UsageWidget;
