import styled from "styled-components";

const BasicInfoStyles = styled.div`
  .datepickerWrapper {
    .react-datepicker-wrapper {
      width: 100% !important;
    }
  }

  .datePickerInput {
    height: 27px;
    width: 100%;
    border-radius: 3px;
    border: 1px solid #979797;
    outline: none;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: center;
    color: #4b4b4b;
    cursor: pointer;
    position: relative;
  }

  margin: 60px 0;
  display: flex;
  position: relative;
  align-items: flex-start;

  .resendVerificationLink {
    position: absolute;
    right: 0;
    top: -40px;
    cursor: pointer;
    font-size: 14px;
    color: #5d5d5d;

    &:hover {
      color: #505050;
    }
  }

  .employeeIntialsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 30px;
    position: relative;
    height: 200px;

    .employeeIntials {
      width: 126px;
      height: 126px;
      background-color: #1568bf;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 48px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
    }
  }

  .userDisabledContainer {
    margin-top: 20px;
    .userDisabledTitle {
      font-weight: 400;
      font-size: 14px;
      color: #c53c3c;
    }
  }

  .labelAndValidation {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .inputContainers {
    width: calc(100% - 156px);
  }

  .topInputsContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;

    .inputContainerFirstRow {
      width: 165px;
    }
  }

  .middleInputsContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin: 20px 0;

    .emailInputContainer {
      width: 340px;
    }

    .firstDayAtWork {
      width: 165px;
    }
  }

  .bottomInputs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;

    .phoneInputContainer {
      width: 195px;
    }

    .teamContainer,
    .uniqueIdContainer {
      width: 135px;
    }

    .genderContainer,
    .branchCodeContainer {
      width: 165px;
    }
  }

  .infoText {
    width: 100%;
    font-size: 16px;
    min-height: 29px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4b4b4b;
    background: #f7f7f7;
    padding: 5px 5px;
    overflow: hidden;
    border-radius: 4px;
  }

  @media screen and (max-width: 1000px) {
    .middleInputsContainer {
      .emailInputContainer {
        width: 165px;
      }
    }

    .bottomInputs {
      width: calc(100% + 156px);
      margin-left: -156px;
    }
  }

  @media screen and (max-width: 767px) {
    .middleInputsContainer {
      .emailInputContainer {
        width: 340px;
      }
    }

    .bottomInputs {
      width: 100%;
      margin-left: 0;
    }
  }

  @media screen and (max-width: 600px) {
    .topInputsContainer {
      .inputContainerFirstRow {
        width: 100%;
      }
    }

    .bottomInputs {
      width: calc(100% + 156px);
      margin-left: -156px;
    }

    .middleInputsContainer {
      width: calc(100% + 156px);
      margin-left: -156px;
      flex-wrap: wrap-reverse;

      .firstDayAtWork {
        width: 100%;
        margin-left: 156px;
      }

      .emailInputContainer {
        width: 100%;
      }
    }

    .userDisabledContainer {
      width: calc(100% + 156px);
      margin-left: -156px;
    }
  }

  @media screen and (max-width: 500px) {
    .bottomInputs {
      width: calc(100% + 156px);
      margin-left: -156px;

      .phoneInputContainer {
        width: 100%;
      }

      .teamContainer {
        width: 100%;
      }

      .uniqueIdContainer {
        width: 100%;
      }

      .genderContainer {
        width: 100%;
      }

      .branchCodeContainer {
        width: 100%;
      }
    }
  }
`;

export default BasicInfoStyles;
