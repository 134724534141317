import { isEmpty } from "../../services/general.utils";

class Voucher {
  constructor(voucherData = {}) {
    this.id = voucherData.id || null;
    this.name = voucherData.name || "";
    this.price = voucherData.price || 0;
    this.isSubscribed =
      voucherData.isSubscribed || voucherData.is_subscribed || false;
    this.predefinedValuesOnly = !isEmpty(voucherData.predefinedValuesOnly)
      ? voucherData.predefinedValuesOnly
      : true;
    this.isReimbursement = !isEmpty(voucherData.isReimbursement)
      ? voucherData.isReimbursement
      : false;
  }
}

export default Voucher;
