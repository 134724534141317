import { Provider } from "../../domain";

class ProvidersResponse {
  constructor(response = {}) {
    this.providers = response.data.providers.map(
      provider => new Provider(provider)
    );
    this.pages = response.data.pages;
    this.count = response.data.count;
  }
}

export default ProvidersResponse;
