import { CompanyTag } from '../../settings.entity'

const initialCompanyTagsState = []

const actionTypes = {
	setCompanyTags: 'SET_COMPANY_TAGS',
	addCompanyTag: 'ADD_COMPANY_TAG',
	editCompanyTag: 'EDIT_COMPANY_TAG',
	deleteCompanyTag: 'DELETE_COMPANY_TAG'
}


const companyTagsReducer = (state = initialCompanyTagsState, action) => {
	switch (action.type) {
		case actionTypes.setCompanyTags:
			return action.payload.companyTags.map(companyTag => new CompanyTag(companyTag))
		case actionTypes.addCompanyTag:
			return [...state, new CompanyTag(action.payload)]
		case actionTypes.editCompanyTag:
			return state.map(tag => (tag.id === action.id ? new CompanyTag(action.payload) : tag))
		case actionTypes.deleteCompanyTag:
			return state.filter(tag => tag.id !== action.id)
		default:
			throw new Error(`Unknow action type: ${action.type}`)
	}
}

export { companyTagsReducer, actionTypes, initialCompanyTagsState }
