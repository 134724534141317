import { SYSTEM_NOTIFICAITON_TYPES } from "../../constants/systemNotifications.constants";
import {
  EmployeeChangedTierSystemNotificationPayload,
  BenefitChangedPriceSystemNotificationPayload,
  TierChangedBudgetSystemNotificationPayload,
  BenefitGroupChangedLocationPayload,
  EmployeeLocationUpdatedInfoPayload,
  BenefitGroupLocationUpdatedInfoPayload,
  BenefitChangedExpirationTypeSystemNotificationPayload,
  EmployeeSubscribedToMcpBenefitPayload
} from "../server/payloads";
import EmployeeChangedLocationPayload from "../server/payloads/EmployeeChangedLocation.payload";

class SystemNotification {
  constructor(systemNotificationData = {}) {
    this.id = systemNotificationData.id || null;
    this.userId =
      systemNotificationData.userId || systemNotificationData.user_id || null;
    this.notificationType =
      systemNotificationData.notificationType ||
      systemNotificationData.notification_type ||
      "";
    switch (this.notificationType) {
      case SYSTEM_NOTIFICAITON_TYPES.BENEFIT_CHANGED_PRICE:
        this.payload = new BenefitChangedPriceSystemNotificationPayload(
          systemNotificationData.payload
        );
        break;
      case SYSTEM_NOTIFICAITON_TYPES.DECREASED_TIER_BUDGET:
        this.payload = new TierChangedBudgetSystemNotificationPayload(
          systemNotificationData.payload
        );
        break;
      case SYSTEM_NOTIFICAITON_TYPES.EMPLOYEE_REASSIGNED_TIER:
        this.payload = new EmployeeChangedTierSystemNotificationPayload(
          systemNotificationData.payload
        );
        break;
      case SYSTEM_NOTIFICAITON_TYPES.BENEFIT_GROUP_CHANGED_LOCATION:
        this.payload = new BenefitGroupChangedLocationPayload(
          systemNotificationData.payload
        );
        break;
      case SYSTEM_NOTIFICAITON_TYPES.EMPLOYEE_CHANGED_LOCATION:
        this.payload = new EmployeeChangedLocationPayload(
          systemNotificationData.payload
        );
        break;
      case SYSTEM_NOTIFICAITON_TYPES.EMPLOYEE_LOCATION_UPDATED_INFO:
        this.payload = new EmployeeLocationUpdatedInfoPayload(
          systemNotificationData.payload
        );
        break;
      case SYSTEM_NOTIFICAITON_TYPES.BENEFIT_GROUP_LOCATION_UPDATED_INFO:
        this.payload = new BenefitGroupLocationUpdatedInfoPayload(
          systemNotificationData.payload
        );
        break;
      case SYSTEM_NOTIFICAITON_TYPES.BENEFIT_CHANGED_EXPIRATION_TYPE:
        this.payload = new BenefitChangedExpirationTypeSystemNotificationPayload(
          systemNotificationData.payload
        );
        break;
      case SYSTEM_NOTIFICAITON_TYPES.EMPLOYEE_SUBSCRIBED_TO_MCP_BENEFIT:
        this.payload = new EmployeeSubscribedToMcpBenefitPayload(
          systemNotificationData.payload
        );
        break;
      default:
        return null;
    }
    this.isRead =
      systemNotificationData.isRead || systemNotificationData.is_read || false;
  }
}

export default SystemNotification;
