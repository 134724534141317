import React from 'react'
import PropTypes from 'prop-types'
import 'react-phone-input-2/lib/style.css'
import PhoneInput from 'react-phone-input-2'

import PhoneNumberInputStyles from './PhoneNumberInputStyles'

const PhoneNumberInput = ({ defaultCountry = 'rs', value = '', name, onChange, onBlur }) => (
	<PhoneNumberInputStyles>
		<PhoneInput
			country={defaultCountry}
			onChange={onChange}
			value={value}
			name={name}
			onBlur={() => onBlur && onBlur(name)}
		/>
	</PhoneNumberInputStyles>
)

PhoneNumberInput.propTypes = {
	defaultCountry: PropTypes.string,
	value: PropTypes.string,
	name: PropTypes.string,
	onChange: PropTypes.func,
	onBlur: PropTypes.func
}

export default PhoneNumberInput
