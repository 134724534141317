import React, { useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Button } from "../components";
import { useTranslation } from "react-i18next";

const NotFoundStyles = styled.section`
  width: 100%;
  background: smokewhite;
  min-height: 100vh;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title {
    font-size: 64px;
    color: #1568bf;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.81;
    letter-spacing: normal;
    margin: 0 0 30px 0;
  }

  .para {
    font-size: 32px;
    color: #1568bf;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.81;
    letter-spacing: normal;
    margin: 0 0 30px 0;
  }
`;

const NotFound = ({ removeSideBar, showSideBar }) => {
  const { t } = useTranslation();

  const isMounted = useRef(true);
  const history = useHistory();

  useEffect(() => {
    if (isMounted.current && removeSideBar) {
      removeSideBar();
    }

    return () => {
      isMounted.current = false;
      if (showSideBar) {
        showSideBar();
      }
    };
  }, []);

  return (
    <NotFoundStyles>
      <h2 className="title">404</h2>
      <p className="para">{t("404_desc")}</p>
      <Button margin="30px 0 0 0" onClick={() => history.replace("/")}>
        {t("go_back")}
      </Button>
    </NotFoundStyles>
  );
};

NotFound.propTypes = {
  removeSideBar: PropTypes.func,
  showSideBar: PropTypes.func
};

export default NotFound;
