class BudgetSpending {
  constructor(budgetData = {}) {
    this.actualSpentBudget =
      budgetData.actualSpentBudget || budgetData.actual_spent_budget || 0;
    this.lastSpentBudget =
      budgetData.lastSpentBudget || budgetData.last_spent_budget || 0;
  }
}

export default BudgetSpending;
