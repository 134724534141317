import React from "react";

const SortIconSvg = () => (
  <svg 
    width="20" 
    height="18" 
    viewBox="0 0 20 18" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      d="M16 18L12 14H15V4H12L16 0L20 4H17V14H20M0 16V14H10V16M0 10V8H7V10M0 4V2H4V4H0Z" 
      fill="#668CCC"
    />
  </svg>    
);

export default SortIconSvg;