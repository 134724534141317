import React from "react";
import PropTypes from "prop-types";

import BudgetRolloverToggleStyles from "./BudgetRolloverToggle.styles";
import { useTranslation } from "react-i18next";

const BudgetRolloverToggle = ({ enableRollover, onToggle }) => {
  const { t } = useTranslation();

  return (
    <BudgetRolloverToggleStyles enableRollover={enableRollover}>
      <div className="activationCheckBox">
        {enableRollover && <p className="activityOnText">{t("on")}</p>}
        <div className="dot" onClick={onToggle} />
        {!enableRollover && <p className="activationOffText">{t("off")}</p>}
      </div>
      {enableRollover ? (
        <p className="benefitActivityStatusText">{t("Rollover is enabled")}</p>
      ) : (
        <p className="benefitActivityStatusText">{t("Rollover is disabled")}</p>
      )}
    </BudgetRolloverToggleStyles>
  );
};

BudgetRolloverToggle.propTypes = {
  enableRollover: PropTypes.bool,
  onToggle: PropTypes.func
};

export default BudgetRolloverToggle;
