export const reportTypeDropdownOptions = t => [
  {
    id: 1,
    name: t("chosen_for_this_month")
  },
  {
    id: 2,
    name: t("chosen_for_next_month")
  }
];
