import styled from 'styled-components'

const ProviderFormStyles = styled.div`

	.labelStyles {
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.71;
		letter-spacing: -0.23px;
		color: #373737;
	} 

	.inputStyles {
		height: 46px;
		border-radius: 4px;
		border: solid 1px #d5dbdd;
		background-color: #ffffff;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.71;
		letter-spacing: -0.23px;
		color: #373737;
	}

	.summaryContainer {
		width: 100%;
		margin: 10px 0;

		.descriptionInput {
			width: 100%;
			min-width: 100%;
			max-width: 100%;
			height: 100px;
			overflow-y: auto;
			min-height: 100px;
			max-height: 100px;
			border-radius: 4px;
			border: solid 1px #d5dbdd;
			background-color: #ffffff;
			padding: 12px 14px;
			font-size: 14px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.71;
			letter-spacing: -0.23px;
			color: #373737;
			outline: none;
			resize: none;
		}
	}

	.descriptionContainer {
		width: 100%;
		margin: 10px 0;

		.rdw-editor-wrapper {
			.rdw-editor-toolbar {
				.rdw-emoji-wrapper {
					.rdw-emoji-modal {
						left: -20px !important;
					}	
				}
				.rdw-link-wrapper {
					.rdw-link-modal {
						font-size: 14px;
						.rdw-link-modal-label {
							font-size: 14px;
						}
					}
				}
			}
			.rdw-editor-main {
				width: 100%;
				min-width: 100%;
				max-width: 100%;
				height: 150px;
				overflow-y: auto;
				min-height: 150px;
				border-radius: 4px;
				border: solid 1px #d5dbdd;
				background-color: #ffffff;
				padding: 12px 14px;
				font-size: 14px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.71;
				letter-spacing: -0.23px;
				color: #373737;
				outline: none;
				resize: none;
				.DraftEditor-root {
					z-index: 0;
				}
			}
		}
	}
`

export default ProviderFormStyles
