import { BenefitGroup } from "../../domain";

class BenefitGroupsResponse {
  constructor(response = {}) {
    this.groups = response.data.groups.map(group => new BenefitGroup(group));
    this.pages = response.data.pages;
    this.count = response.data.count;
  }
}

export default BenefitGroupsResponse;
