const refundsNamespace = "[refunds]";

const refundActions = {
  SET_REFUNDS_LIST: `${refundsNamespace}_SET_REFUNDS_LIST`,
  SET_REFUND: `${refundsNamespace}_SET_REFUND`,
  SET_TOTAL_COUNT: `${refundsNamespace}_SET_TOTAL_COUNT`,
  SET_FILTERS: `${refundsNamespace}_SET_FILTERS`,
  SET_CHECKED_PENDING_ITEMS: `${refundsNamespace}_SET_CHECKED_PENDING_ITEMS`,
  SET_BRANCH_CODES: `${refundsNamespace}_SET_BRANCH_CODES`,
  SET_TOGGLE_REIMBURSEMENT: `${refundsNamespace}_SET_TOGGLE_REIMBURSEMENT`
};

export default refundActions;
