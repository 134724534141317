import { toast } from "react-toastify";
import supabaseClient from "./supabaseClient";
import createSupaBaseStorageFolder from "./createSupaBaseStorageFolder";

const sendImageToSupabase = async (fileName, file, companyId) => {
  const folderName = createSupaBaseStorageFolder(companyId);

  try {
    const { error } = await supabaseClient.storage
      .from("refund-upload")
      .upload(`${folderName}_pending_refund/${fileName}`, file);

    if (error) throw new Error(error);
  } catch (error) {
    toast.error(error.message);
  }
};

export default sendImageToSupabase;
