import { findLocales } from "../../../utils/helpers/findLocales";
import { removeSpacesAndHTML } from "../../general.utils";

export const categoryValidations = ({ name }) => {
  const isInLanguage = findLocales("rs");

  const errors = {};

  if (!name.trim()) {
    errors.name = isInLanguage ? "*Obavezno polje" : "* Required";
  } else if (!removeSpacesAndHTML(name)) {
    errors.name = isInLanguage
      ? "HTML tag nije dozvoljen"
      : "HTML tag is not allowed";
  }

  return errors;
};
