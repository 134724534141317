import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Input, InputLabel, Button } from "../../../../../../components";
import { useTranslation } from "react-i18next";

const FormStyles = styled.div`
  .labelErrorContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .description {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: 200px;
    min-height: 200px;
    max-height: 200px;
    border-radius: 4px;
    border: solid 1px #979797;
    padding: 5px 10px 5px 5px;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    letter-spacing: normal;
    color: #5d5d5d;
    outline: none;
    resize: none;
    overflow-y: auto;
  }
`;

const Form = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  buttonText
}) => {
  const { t } = useTranslation();
  return (
    <FormStyles>
      <div style={{ marginBottom: 10 }}>
        <div className="labelErrorContainer">
          <InputLabel margin="0 0 5px 0" label={t("Name")} />
          <div className="errorMsg" style={{ marginBottom: 5 }}>
            {errors.name && touched.name ? errors.name : ""}
          </div>
        </div>
        <Input
          name="name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      <div>
        <div className="labelErrorContainer">
          <InputLabel margin="0 0 5px 0" label={t("Description")} />
        </div>
        <textarea
          className="description"
          name="description"
          value={values.description}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      <Button margin="20px 0 0 0" type="submit">
        {t(buttonText)}
      </Button>
    </FormStyles>
  );
};

Form.propTypes = {
  values: PropTypes.shape(),
  errors: PropTypes.shape(),
  touched: PropTypes.shape(),
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  buttonText: PropTypes.string
};

export default Form;
