/* eslint-disable */
import React from "react";

const SyncIcon  = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        fill="#1568BF"
        fillRule="evenodd"
        d="M7.283 19.459c.385.385.982.348 1.703.024l14.033-6.264c.323-.15.59-.305.764-.479.348-.348.348-.783.006-1.124-.18-.18-.441-.33-.777-.48L8.923 4.842c-.64-.28-1.249-.341-1.64.05-.479.479-.299 1.02.08 1.71l3.046 5.568-3.02 5.53c-.392.715-.591 1.275-.106 1.76zm4.12-7.825L8.501 6.221c-.1-.186-.106-.255-.056-.305.056-.055.13-.068.298.013l11.616 5.227c.435.193.882.353 1.308.505l.357.129-.238-.026c-.548-.06-1.204-.13-1.663-.136l-8.72.006zm-2.908 6.5c-.094.181-.112.262-.056.318.056.056.136.05.298-.025L20.365 13.2c.365-.168.75-.306 1.12-.44.19-.069.378-.136.558-.206-.597.075-1.398.168-1.92.168l-8.72-.006-2.908 5.419z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default SyncIcon;
