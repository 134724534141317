export const bugetSpentConfig = {
	series: [0],
	options: {
		chart: {
			animations: {
				enabled: true,
				easing: 'easeinout',
				speed: 800,
				animateGradually: {
					enabled: true,
					delay: 1500
				},
				dynamicAnimation: {
					enabled: true,
					speed: 800
				}
			},
			height: 350,
			type: 'radialBar',
			toolbar: {
				show: false
			}
		},
		plotOptions: {
			radialBar: {
				startAngle: 0,
				endAngle: -360,
				hollow: {
					margin: 0,
					size: '70%',
					background: '#fff',
					image: undefined,
					imageOffsetX: 0,
					imageOffsetY: 0,
					position: 'front',
					dropShadow: {
						enabled: true,
						top: 3,
						left: 0,
						blur: 4,
						opacity: 0.24
					}
				},
				track: {
					background: '#d8d8d8',
					strokeWidth: '75%',
					margin: 0, // margin is in pixels
					dropShadow: {
						enabled: false,
						top: -3,
						left: 0,
						blur: 4,
						opacity: 0.5
					}
				},

				dataLabels: {
					show: true,
					name: {
						offsetY: -10,
						show: false,
						color: '#888',
						fontSize: '17px'
					},
					value: {
						formatter(val) {
							return `${parseInt(val, 0)} %`
						},
						offsetY: 7,
						color: '#111',
						fontSize: '18px',
						show: true
					}
				}
			}
		},
		fill: {
			type: 'gradient',
			gradient: {
				shade: 'dark',
				type: 'horizontal',
				shadeIntensity: 0.5,
				gradientToColors: ['#1e3c72'],
				inverseColors: true,
				opacityFrom: 1,
				opacityTo: 1,
				stops: [0, 100]
			}
		},
		stroke: {
			lineCap: 'round'
		},
		labels: ['']
	}
}
