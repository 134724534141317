const imageSize = {
  original: "URL",
  image2X: "URL@2X",
  image3X: "URL@3X",
  image4X: "URL@4X",
  image5X: "URL@5X",
  image6X: "URL@6X",
  image7X: "URL@7X",
  image8X: "URL@8X",
  image9X: "URL@9X"
};

const imageFormat = {
  png: "png",
  jpg: "jpeg"
};

const getUrl = (photo, requiredImage) => {
  if (photo && requiredImage && photo[requiredImage]) {
    return photo[requiredImage];
  }

  if (photo && photo.url) {
    return photo.url;
  }

  if (photo && photo.url2x) {
    return photo.url2x;
  }

  if (photo && photo.url3x) {
    return photo.url3x;
  }

  if (photo && photo.url4x) {
    return photo.url4x;
  }

  if (photo && photo.url5x) {
    return photo.url5x;
  }

  if (photo && photo.url6x) {
    return photo.url6x;
  }

  if (photo && photo.url7x) {
    return photo.url7x;
  }

  if (photo && photo.url8x) {
    return photo.url8x;
  }

  if (photo && photo.url9x) {
    return photo.url9x;
  }

  return "";
};

const findSize = (key, sizeArray) => {
  switch (key) {
    case imageSize.original:
      return sizeArray.find(size => size === 1440);
    case imageSize.image2X:
      return sizeArray.find(size => size === 1080);
    case imageSize.image3X:
      return sizeArray.find(size => size === 720);
    case imageSize.image4X:
      return sizeArray.find(size => size === 540);
    case imageSize.image5X:
      return sizeArray.find(size => size === 360);
    case imageSize.image6X:
      return sizeArray.find(size => size === 240);
    case imageSize.image7X:
      return sizeArray.find(size => size === 180);
    case imageSize.image8X:
      return sizeArray.find(size => size === 120);
    case imageSize.image9X:
      return sizeArray.find(size => size === 90);
    default:
      throw new Error(`Unknow action type: ${key}`);
  }
};

const findType = (format, typeArray) => {
  const chosenType = typeArray.find(type => type === format);
  if (chosenType) {
    return chosenType;
  }
  if (typeArray) {
    return typeArray[0];
  }

  return "";
};

const createUrl = (key, format, photo) => {
  if (photo.base && photo.name && photo.size && photo.type && photo.path) {
    if (findSize(key, photo.size) && findType(format, photo.type)) {
      return `${photo.base}${photo.path}/${photo.name}@${findSize(
        key,
        photo.size
      )}.${findType(format, photo.type)}`;
    }
    return "";
  }

  return "";
};

export { getUrl, createUrl, imageFormat, imageSize };
