export const changeFormSteps = formStep => {
  const container = document.getElementById("stepsContainer");
  switch (formStep) {
    case 1:
      container.style.transform = "translate(0px)";
      break;
    case 2:
      container.style.transform = "translate(-500px)";
      break;
    case 3:
      container.style.transform = "translate(-1000px)";
      break;
    default:
      console.log(`Step doesn't exists`);
  }
};
