import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
import DatePicker from "react-datepicker";
// componenets
import { Card } from "../../../../components";
// styles
import CategoryBudgetStyles from "./CategoryBudgetStyles";
// config
import { categoryBudgetConfig } from "./categoryBudget.config";
// services
import * as dashboardService from "../../../../services/api/hr/dashboard.service";
import { formatDateForSearch } from "../dashboad.services";
import { useTranslation } from "react-i18next";

const CategoryBudget = ({
  categoryBudgetLoading,
  setCategoryBudgetLoading
}) => {
  const isInSerbian = localStorage.getItem("locales") === "rs";

  const { t } = useTranslation();
  const [graphData, setGraphData] = useState(categoryBudgetConfig);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [date, setDate] = useState(new Date());
  const [searchDateString, setSearchDateString] = useState(
    formatDateForSearch(new Date())
  );
  const isMounted = useRef(true);

  const handleDateChange = chosenDate => {
    setDate(chosenDate);
    setSearchDateString(formatDateForSearch(chosenDate));
  };

  const fetchData = async () => {
    const response = await dashboardService.fetchBudgetPerCategories(
      searchDateString
    );

    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : t("failed_to_get_budget_per_categories")
      );
    }

    if (isMounted.current) {
      setCategoryBudgetLoading(false);
    }

    let chosenCategoriesNames = [];
    let chosenMonthBudget = {
      name: isInSerbian ? "Budžet za odabrani mesec" : "Chosen month budget",
      data: []
    };
    let previousMonthBudget = {
      name: isInSerbian ? "Budžet za prethodni mesec" : "Previous month budget",
      data: []
    };

    if (
      isMounted.current &&
      response &&
      response.actualCategoryBudget &&
      response.lastCategoryBudget
    ) {
      if (
        response.actualCategoryBudget.length ===
        response.lastCategoryBudget.length
      ) {
        response.actualCategoryBudget.forEach(item => {
          chosenCategoriesNames.push(item.name);
          chosenMonthBudget.data.push(item.spentBudget);
        });
        response.lastCategoryBudget.forEach(item => {
          previousMonthBudget.data.push(item.spentBudget);
        });
      }
    } else {
      chosenCategoriesNames = [];
      chosenMonthBudget = {
        name: isInSerbian ? "Budžet za odabrani mesec" : "Chosen month budget",
        data: []
      };
      previousMonthBudget = {
        name: isInSerbian
          ? "Budžet za prethodni mesec"
          : "Previous month budget",
        data: []
      };
    }

    if (
      isMounted.current &&
      errorMessage !== undefined &&
      chosenCategoriesNames.length > 0
    ) {
      setErrorMessage(undefined);
    } else if (isMounted.current && chosenCategoriesNames.length === 0) {
      setErrorMessage("No data to compare");
    }
    if (isMounted.current && response) {
      setGraphData({
        ...graphData,
        series: [chosenMonthBudget, previousMonthBudget],
        options: {
          ...graphData.options,
          xaxis: {
            categories: chosenCategoriesNames
          }
        }
      });
    }
  };

  useEffect(() => {
    isMounted.current = true;
    fetchData();
    return () => {
      isMounted.current = false;
    };
  }, [date]);

  useEffect(() => {
    if (categoryBudgetLoading === false) {
      const datePicker = document.getElementById("datePickerCategoryGraph");
      if (datePicker) {
        datePicker.setAttribute("readOnly", true);
      }
    }
  }, [categoryBudgetLoading]);

  return (
    <CategoryBudgetStyles>
      <Card
        boxShadow="
					0 2px 4px -1px rgba(0, 0, 0, 0.2),
					0 1px 10px 0 rgba(0, 0, 0, 0.12),
					0 4px 5px 0 rgba(0, 0, 0, 0.14)
				"
      >
        <div className="titleContainer">
          <DatePicker
            className="datePickerInput"
            id="datePickerCategoryGraph"
            selected={date}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            onChange={chosenDate => handleDateChange(chosenDate)}
            value={date}
            maxDate={new Date()}
          />
          <h3 className="title">{t("budget_spent_by_category")}</h3>
        </div>
        <div
          className="chartAndMessageContainer"
          onClick={e => e.stopPropagation()}
        >
          {errorMessage && (
            <div className="messageContainer">
              <p className="text">{errorMessage}</p>
            </div>
          )}
          <ReactApexChart
            options={graphData.options}
            series={graphData.series}
            type="bar"
            width={580}
            height={285}
          />
        </div>
      </Card>
    </CategoryBudgetStyles>
  );
};

CategoryBudget.propTypes = {
  categoryBudgetLoading: PropTypes.bool,
  setCategoryBudgetLoading: PropTypes.func
};

export default CategoryBudget;
