import { Tag } from "../../models/domain";
import * as actionTypes from "../../constants/actionTypes/admin/benefitTags.constants";
import { updateObject } from "../../services/updateObject";

const initalState = {
  tags: []
};

const benefitTagsReducer = (state = initalState, action) => {
  switch (action.type) {
    case actionTypes.SET_BENEFIT_TAGS:
      return setBenefitTags(state, action);
    case actionTypes.ADD_BENEFIT_TAG:
      return addBenefitTag(state, action);
    case actionTypes.EDIT_BENEFIT_TAG:
      return editBenefitTag(state, action);
    case actionTypes.DELETE_BENEFIT_TAG:
      return deleteBenefitTag(state, action);
  }

  return state;
};

const setBenefitTags = (state, action) => {
  return updateObject(state, {
    tags: action.benefitTags.tags.map(benefitTag =>
      typeof benefitTag === Tag ? benefitTag : new Tag(benefitTag)
    )
  });
};

const addBenefitTag = (state, action) => {
  return updateObject(state, {
    tags: [...state.tags, new Tag(action.benefitTag)]
  });
};

const editBenefitTag = (state, action) => {
  return updateObject(state, {
    tags: state.tags.map(tag =>
      tag.id === action.benefitTag.id ? new Tag(action.benefitTag) : tag
    )
  });
};

const deleteBenefitTag = (state, action) => {
  return updateObject(state, {
    tags: state.tags.filter(tag => tag.id !== action.id)
  });
};

export default benefitTagsReducer;
