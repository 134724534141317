import React from "react";
import PropTypes from "prop-types";

const UserBenefitsCount = ({ count }) => {
  return <>{count}</>;
};

UserBenefitsCount.propTypes = {
  count: PropTypes.number
};

export default UserBenefitsCount;
