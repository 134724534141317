import styled from 'styled-components'

const EditCompanyStyles = styled.section`
.editCompanyContainer {
		width: calc(100% - 210px);
		margin-left: 210px;
		background: #f7f7f7;
		min-height: 100vh;
		overflow-x: hidden;
	}
	
	.editCompanyContent {
		padding: 0 30px 30px 30px;
	}

	#editCompany {
		opacity: 0;
		transition: all 0.4s ease-in-out;
	}
`

export default EditCompanyStyles
