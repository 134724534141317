import moment from "moment";

const startOfTheCurrentMonth = () => {
  return moment()
    .startOf("month")
    .format("YYYY-MM-DD");
};

const currentDate = () => {
  return moment().format("YYYY-MM-DD");
};

export { startOfTheCurrentMonth, currentDate };
