class Bonus {
  constructor(bonusData = {}) {
    this.id = bonusData.id || null;
    this.employees = bonusData.employees || [];
    this.employeeId = bonusData.employeeId || null;
    this.companyId = bonusData.companyId || null;
    this.subject = bonusData.subject || "";
    this.message = bonusData.message || "";
    this.tokens = bonusData.tokens || "";
    this.currency = bonusData.currency || "";
    this.endDate = bonusData.endDate || bonusData.end_date || "";
    this.startDate = bonusData.startDate || bonusData.start_date || "";
    this.recurring = bonusData.recurring;
  }
}

export default Bonus;
