import moment from "moment";
import React from "react";

const formatDate = dateInIsoString => {
  return moment(dateInIsoString).format("DD.MM.YYYY HH:mm");
};

export const AdminUserHistoryBody = props => {
  const { listOfData } = props;

  return (
    <div className="adminUserHistoryBody">
      <div className="adminUserHistoryBodyList">
        {listOfData.map(adminHistoryItem => {
          return Object.entries(adminHistoryItem).map(([key, value]) => {
            return (
              <div className="adminUserHistoryBodyItem" key={key}>
                <div className="adminUserHistoryBodyItemWrapper">
                  <div className="adminUserHistoryBodyItemLeft">
                    <p className="time">{formatDate(key)}</p>
                    <div className="outerDot">
                      <div className="innerDot"></div>
                    </div>
                  </div>
                  <div className="adminUserHistoryBodyItemRight">
                    <p className="adminUserHistoryBodyItemRightText">{value}</p>
                  </div>
                </div>
              </div>
            );
          });
        })}
      </div>
    </div>
  );
};
