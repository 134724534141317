import React from 'react'
import styled from 'styled-components'

const DashboardIconSvgStyles = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`

const DashboardIconSvg = () => (
	<DashboardIconSvgStyles>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="13"
			viewBox="0 0 16 13"
		>
			<path
				fill="#FFF"
				fillRule="evenodd"
				d="M2.206 11.272c-.618 0-.97-.326-.97-.978V5.228h6.147v6.044H2.206zm11.513 0H8.542V5.228h6.147v5.066c0 .652-.352.978-.97.978zM1.236 2.223c0-.643.352-.978.97-.978H13.72c.618 0 .97.335.97.978v1.915H1.236V2.223zm.953 10.294h11.547c1.468 0 2.19-.721 2.19-2.163v-8.19C15.925.72 15.203 0 13.735 0H2.19C.73 0 0 .721 0 2.163v8.19c0 1.443.73 2.164 2.19 2.164z"
			/>
		</svg>
	</DashboardIconSvgStyles>
)

export default DashboardIconSvg
