import React from 'react'
import styled from 'styled-components'

const LogoutIconSvgStyles = styled.div`
  height: 100%;
	width: 16px;
  display: flex;
  align-items: center;
`

const LogoutIconSvg = () => (
	<LogoutIconSvgStyles>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="12"
			viewBox="0 0 16 12"
		>
			<path
				fill="#FFF"
				fillRule="evenodd"
				d="M15.15 9.835V2.217c0-1.452-.714-2.165-2.14-2.165H6.48c-1.427 0-2.14.713-2.14 2.165v1.826h1.23V2.234c0-.603.332-.96.969-.96h6.411c.645 0 .968.357.968.96v7.584c0 .611-.323.96-.968.96H6.54c-.637 0-.968-.349-.968-.96v-1.81H4.339v1.827c0 1.443.714 2.165 2.14 2.165h6.53c1.427 0 2.14-.722 2.14-2.165M9.885 6.03a.611.611 0 00-.595-.611H2.666l-1.019.05.391-.356 1.01-.934a.56.56 0 00.179-.408c0-.322-.22-.56-.527-.56a.512.512 0 00-.399.187L.204 5.588C.05 5.743 0 5.869 0 6.03c0 .153.051.28.204.433l2.097 2.2c.11.11.23.178.4.178.305 0 .526-.23.526-.552a.579.579 0 00-.178-.416l-1.011-.934-.39-.357 1.018.051H9.29a.61.61 0 00.595-.603"
			/>
		</svg>
	</LogoutIconSvgStyles>
)

export default LogoutIconSvg
