import React, { createContext, useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'

import { initalUsersState, usersReducer } from './users.reducer'

import { useUserActions } from './users.actions'

const UsersContext =
	createContext({ ...initalUsersState })

const UsersProvider = ({ children }) => {
	const [usersState, dispatchUsersState] = useReducer(usersReducer, initalUsersState)

	const usersActions = useUserActions(dispatchUsersState)

	useEffect(() => { }, [initalUsersState])

	return (
		<UsersContext.Provider
			value={{
				usersState,
				usersActions
			}}
		>
			{children}
		</UsersContext.Provider>
	)
}

UsersProvider.propTypes = {
	children: PropTypes.node
}

export { UsersContext, UsersProvider }
