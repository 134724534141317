export const priceRangeOptions = [
  {
    id: 1,
    min: 0,
    max: 50,
    checked: false
  },
  {
    id: 2,
    min: 51,
    max: 100,
    checked: false
  },
  {
    id: 3,
    min: 101,
    max: 150,
    checked: false
  },
  {
    id: 4,
    min: 151,
    max: 200,
    checked: false
  },
  {
    id: 5,
    min: 201,
    max: 250,
    checked: false
  },
  {
    id: 6,
    min: 251,
    max: 300,
    checked: false
  },
  {
    id: 7,
    min: 301,
    max: 1000,
    checked: false
  }
];
