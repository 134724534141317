import React, { useRef } from "react";
import { connect } from "react-redux";

import ArrowDown from "../../../../../assets/images/categoryFilters/rectangle.png";
import { NextMonthDropDownStyles } from "./nextMonthDropDown.styles";

import useClickOutside from "../../../../../hooks/useClickOutside";

import * as actionCreators from "../../../../../actions/employee/employeeBenefits.actions";
import { useTranslation } from "react-i18next";

const NextMonthDropDown = ({
  isConditionAllBenefits,
  isNextMonthTooltipOpen,
  setIsConditionAllBenefits,
  setIsNextMonthTooltipOpen
}) => {
  const { t } = useTranslation();
  const tooltip = useRef(null);

  useClickOutside([tooltip], () => setIsNextMonthTooltipOpen(false));
  return (
    <NextMonthDropDownStyles>
      <p className="nextMonthBenefitsCondition">
        {isConditionAllBenefits ? t("SAB") : t("SOC")}
      </p>
      <div className="tooltipContainer" ref={tooltip}>
        <button
          type="button"
          className="arrowButtons"
          onClick={() => setIsNextMonthTooltipOpen(true)}
        >
          <img src={ArrowDown} alt={"arrowDown"} />
        </button>
        {isNextMonthTooltipOpen && (
          <div className="tooltip">
            <div className="pointerBorder"></div>
            <div className="pointer"></div>
            <p
              className="tooltipOption"
              onClick={() => setIsConditionAllBenefits(false)}
            >
              {t("SOC")}
            </p>
            <p
              className="tooltipOption"
              onClick={() => setIsConditionAllBenefits(true)}
            >
              {t("SAB")}
            </p>
          </div>
        )}
      </div>
    </NextMonthDropDownStyles>
  );
};

const mapStateToProps = state => {
  return {
    isConditionAllBenefits:
      state.employeeBenefitsPage.subscribedBenefits.isConditionAllBenefits,
    isNextMonthTooltipOpen:
      state.employeeBenefitsPage.subscribedBenefits.isNextMonthTooltipOpen
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setIsConditionAllBenefits: isConditionAllBenefits =>
      dispatch(
        actionCreators.setIsConditionAllBenefits(isConditionAllBenefits)
      ),
    setIsNextMonthTooltipOpen: isNextMonthTooltipOpen =>
      dispatch(actionCreators.setIsNextMonthTooltipOpen(isNextMonthTooltipOpen))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NextMonthDropDown);
