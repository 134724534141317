import styled from 'styled-components'

const LoginStyles = styled.section`
	min-height: 100vh;
	height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
	align-items: center;
	
	@media only screen and (max-height: 420px) {
		height: unset;
		padding: 20px 0;
    overflow-y: scroll;
  }
`

export default LoginStyles
