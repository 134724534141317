import React from "react";
import { ListPlaceHolder } from "../../../../components";

export const EmptyFavoriteBenefits = ({ textPlaceholder }) => {
  return (
    <div style={{ height: "calc(100vh - 240px" }}>
      <ListPlaceHolder text={textPlaceholder} />
    </div>
  );
};
