import React from 'react'
import PropTypes from 'prop-types'
import BenefitLogo from '../../assets/Icons/BenefitLogoSvg';
//services
import { getUrl } from "../../services/imageHandler";
//styles
import CompanyThemeLogoStyles from './CompanyThemeLogo.styles';

const CompanyThemeLogo = ({ color = '#fff', theme = null, colorTheme }) => (  
  <CompanyThemeLogoStyles color={colorTheme} >
		{getUrl(theme?.photo)
      ? <div className="companyThemeContainer">
          <div className="logo">
            <img src={getUrl(theme?.photo)}/> 
          </div>
          <div className="text" >
            <p>Powered by</p> 
            <BenefitLogo color={colorTheme} width="50" height="15" opacity="0.4" />            
          </div>
        </div>
			: <BenefitLogo color={color}/>}
	</CompanyThemeLogoStyles>
)

CompanyThemeLogo.propTypes = {
	color: PropTypes.string,
	theme: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.number,
      mode: PropTypes.oneOf(['light', 'dark', 'custom']),
      photo: PropTypes.oneOfType([
        PropTypes.shape({}),
        PropTypes.instanceOf(null)
      ]),
      companyId: PropTypes.number,
      color: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(null)
      ]),
    }), 
    PropTypes.instanceOf(null)
  ]),
  colorTheme: PropTypes.string,  
}

export default CompanyThemeLogo
