class DropDownOption {
  constructor(data = {}) {
    this.id = data.id || null;
    this.name =
      `${data.user.firstName} ${data.user.lastName} (${data.user.email})` || "";
    this.userId = data.user.id || null;
  }

  static createOptions = dataArray =>
    dataArray.map(data => new DropDownOption(data));
}

export default DropDownOption;
