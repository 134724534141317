const onChangeParam = (getNameValue, values) => {
	if (getNameValue) {
		if (values[0]) {
			return values[0].name
		}
		return undefined
	}
	if (values[0]) {
		return values[0].id
	}
	return undefined
}

const getSelectedValue = (selectedValues, value) => {
	const selectedValue = selectedValues.find(sv => {
		if (typeof value === 'number') {
			return sv.id === value
		}
		if (typeof value === 'string') {
			if (sv.name.toLowerCase() === value.toLowerCase()) {
				return sv.name.toLowerCase() === value.toLowerCase()
			}
			return undefined
		}
		return undefined
	})

	return selectedValue
}

export { onChangeParam, getSelectedValue }
