import React from 'react'
import styled from 'styled-components'

const StarIconStyles = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	svg {
		width: 25px;
		height: 25px;
	}
`

const StarIcon = () => (
	<StarIconStyles>

		<svg
			xmlns="http://www.w3.org/2000/svg"
			x="0"
			y="0"
			enableBackground="new 0 0 512.002 512.002"
			version="1.1"
			viewBox="0 0 512.002 512.002"
			xmlSpace="preserve"
		>
			<linearGradient
				id="SVGID_1_"
				x1="236.938"
				x2="236.938"
				y1="458.428"
				y2="-71.182"
				gradientTransform="matrix(1.0667 0 0 -1.0667 3.267 557.535)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#FFCF95" />
				<stop offset="0.427" stopColor="#FFC954" />
				<stop offset="1" stopColor="#FFC200" />
			</linearGradient>
			<path
				fill="url(#SVGID_1_)"
				d="M274.84 23.212l67.722 137.218 151.429 22.004c17.232 2.503 24.112 23.68 11.644 35.835L396.058 325.077l25.867 150.817c2.944 17.162-15.07 30.25-30.482 22.147L256 426.835l-135.442 71.206c-15.412 8.102-33.426-4.985-30.482-22.147l25.867-150.817L6.367 218.268c-12.469-12.155-5.588-33.33 11.644-35.835l151.429-22.004 67.721-137.217c7.707-15.615 29.972-15.615 37.679 0z"
			/>
			<linearGradient
				id="SVGID_2_"
				x1="361.338"
				x2="201.238"
				y1="390.131"
				y2="390.131"
				gradientTransform="matrix(1.0667 0 0 -1.0667 3.267 557.535)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#FFC200" stopOpacity="0" />
				<stop offset="0.203" stopColor="#FB0" stopOpacity="0.203" />
				<stop offset="0.499" stopColor="#FFA700" stopOpacity="0.499" />
				<stop offset="0.852" stopColor="#F80" stopOpacity="0.852" />
				<stop offset="1" stopColor="#FF7800" />
			</linearGradient>
			<path
				fill="url(#SVGID_2_)"
				d="M342.56 160.43L274.84 23.212c-3.853-7.807-11.346-11.711-18.839-11.711V271.29l86.559-110.86z"
			/>
			<linearGradient
				id="SVGID_3_"
				x1="388.687"
				x2="346.287"
				y1="144.838"
				y2="353.638"
				gradientTransform="matrix(1.0667 0 0 -1.0667 3.267 557.535)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#FFC200" stopOpacity="0" />
				<stop offset="0.203" stopColor="#FB0" stopOpacity="0.203" />
				<stop offset="0.499" stopColor="#FFA700" stopOpacity="0.499" />
				<stop offset="0.852" stopColor="#F80" stopOpacity="0.852" />
				<stop offset="1" stopColor="#FF7800" />
			</linearGradient>
			<path
				fill="url(#SVGID_3_)"
				d="M396.058 325.077l109.575-106.81c6.151-5.996 7.586-14.185 5.399-21.247L256 271.289l140.058 53.788z"
			/>
			<linearGradient
				id="SVGID_4_"
				x1="183.098"
				x2="349.158"
				y1="35.109"
				y2="201.169"
				gradientTransform="matrix(1.0667 0 0 -1.0667 3.267 557.535)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#FFC200" stopOpacity="0" />
				<stop offset="0.203" stopColor="#FB0" stopOpacity="0.203" />
				<stop offset="0.499" stopColor="#FFA700" stopOpacity="0.499" />
				<stop offset="0.852" stopColor="#F80" stopOpacity="0.852" />
				<stop offset="1" stopColor="#FF7800" />
			</linearGradient>
			<path
				fill="url(#SVGID_4_)"
				d="M413.63 496.393L256 271.289v155.546l135.442 71.206c7.734 4.066 16.121 2.794 22.188-1.648z"
			/>
			<linearGradient
				id="SVGID_5_"
				x1="64.678"
				x2="187.078"
				y1="245.108"
				y2="142.708"
				gradientTransform="matrix(1.0667 0 0 -1.0667 3.267 557.535)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#FFC200" stopOpacity="0" />
				<stop offset="0.203" stopColor="#FB0" stopOpacity="0.203" />
				<stop offset="0.499" stopColor="#FFA700" stopOpacity="0.499" />
				<stop offset="0.852" stopColor="#F80" stopOpacity="0.852" />
				<stop offset="1" stopColor="#FF7800" />
			</linearGradient>
			<path
				fill="url(#SVGID_5_)"
				d="M256 271.289l-140.058 53.788-25.866 150.817c-1.467 8.55 2.271 16.087 8.294 20.499L256 271.289z"
			/>
			<linearGradient
				id="SVGID_6_"
				x1="156.433"
				x2="106.833"
				y1="414.02"
				y2="273.22"
				gradientTransform="matrix(1.0667 0 0 -1.0667 3.267 557.535)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#FFC200" stopOpacity="0" />
				<stop offset="0.203" stopColor="#FB0" stopOpacity="0.203" />
				<stop offset="0.499" stopColor="#FFA700" stopOpacity="0.499" />
				<stop offset="0.852" stopColor="#F80" stopOpacity="0.852" />
				<stop offset="1" stopColor="#FF7800" />
			</linearGradient>
			<path
				fill="url(#SVGID_6_)"
				d="M.968 197.021L256 271.289l-86.56-110.86-151.429 22.005C9.28 183.702 3.214 189.767.968 197.021z"
			/>
			<linearGradient
				id="SVGID_7_"
				x1="343.14"
				x2="-21.26"
				y1="399.316"
				y2="244.116"
				gradientTransform="matrix(1.0667 0 0 -1.0667 3.267 557.535)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#FFC200" stopOpacity="0" />
				<stop offset="0.203" stopColor="#FB0" stopOpacity="0.203" />
				<stop offset="0.499" stopColor="#FFA700" stopOpacity="0.499" />
				<stop offset="0.852" stopColor="#F80" stopOpacity="0.852" />
				<stop offset="1" stopColor="#FF7800" />
			</linearGradient>
			<path
				fill="url(#SVGID_7_)"
				d="M115.943 325.077L6.367 218.268c-6.151-5.996-7.586-14.185-5.399-21.247L256 271.289l-140.057 53.788z"
			/>
		</svg>
	</StarIconStyles>

)

export default StarIcon
