import styled from "styled-components"

const CompanyThemeLogoStyles = styled.div`
	width: 160px;
	height: 50px;
  display: flex;
  align-items: center;
  
  .companyThemeContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .logo {
      //height: 28px;   
      height: 34px; 

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    } 

    .text {
      display: flex;
      align-items: center;
      margin-top: 3.5px;
      
      p {
        font-size: 10px;
        font-weight: 400;
        line-height: 12.19px;
        margin: 0 4px 4px 0;        
        color: ${ props => props.color ? props.color : '#1D1D1D' };
        opacity: 0.6;
      }
    }
  }		
`
export default CompanyThemeLogoStyles