import { Category } from '../../settings.entity'

const initalCategoriesState = []

const actionTypes = {
	setCategories: 'SET_CATEGORIES',
	addCategories: 'ADD_CATEGORIES',
	editCategories: 'EDIT_CATEGORIES',
	deleteCategories: 'DELETE_CATEGORIES'
}

const categoriesReducer = (state = initalCategoriesState, action) => {
	switch (action.type) {
		case actionTypes.setCategories:
			return action.payload.map(category => new Category(category))
		case actionTypes.addCategories:
			return [...state, new Category(action.payload)]
		case actionTypes.editCategories:
			return state.map(category =>
				(category.id === action.id ? new Category(action.payload) : category)
			)
		case actionTypes.deleteCategories:
			return state.filter(category => category.id !== action.id)
		default:
			throw new Error(`Unknow action type: ${action.type}`)
	}
}

export { initalCategoriesState, actionTypes, categoriesReducer }
