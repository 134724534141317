import moment from 'moment';

export const formatDateForPlaceholder = date => {
  const year = date.getFullYear();
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);
  return `${day}/${month}/${year}`;
};

export const formatDateForSearch = date => {
  const year = date.getFullYear();
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);
  return `${month}/${day}/${year}`;
};

/**
 * Increments given month by 1 and starts it from the first day.
 * @param {Date} date 
 * @param {number} forward 
 * @returns {Date}
 */
export const startFutureMonthFromDate = (date, forward = 1) => moment
  .utc(date)
  .add(forward, 'months')
  .startOf('month')
  .toDate();

/**
 * Formats date to start of day.
 * @param {Date} date 
 * @returns {Date}
 */
export const formatToStartOfDay = (date) => moment
  .utc(date)
  .startOf('day')
  .toDate();
