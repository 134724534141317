import styled from "styled-components";

export const BenefitGroupInstalmentsSectionStyles = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 20px 0;

  .benefitActivityInfo {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4b4b4b;
    width: 204px;
  }
  .iconContainer {
    width: 12px;
    height: 12px;
    background: #a1a3a7;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 6px;
    font-weight: bold;
    margin-left: 8px;
  }

  .tooltip {
    width: 232px;
    background: #282a3b !important;
    border: 1px solid #282a3b !important;
    padding: 0 5px;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -0.22px;
    color: #ffffff;
  }

  .__react_component_tooltip.show {
    opacity: 1;
    margin-left: 0;
    visibility: visible;
  }

  .tooltip:after {
    border-top: 8px solid #282a3b !important;
    margin-left: -3px !important;
  }
`;
