class SubscribeUnsubscribePayload {
  constructor(payload = {}) {
    this.benefit_group_id = payload.benefitGroupId || null;
    this.benefit_id = payload.benefitId || null;
    this.subscribe = payload.subscribe || false;
    this.quantity = payload.quantity || 1;
  }
}

export default SubscribeUnsubscribePayload;
