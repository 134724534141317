import Company from "../../domain/Company.entity";

class CompaniesResponse {
  constructor(response = {}) {
    this.companies = response.data.companies?.map(
      company => new Company(company)
    );
    this.pages = response.data.pages;
    this.count = response.data.count;
  }
}

export default CompaniesResponse;
