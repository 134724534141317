import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const WrapperCardComponentStyles = styled.div`
  padding: ${({ padding }) => padding};
  background: #fff;
  border-radius: 4px;
	box-shadow: ${({ boxShadow }) => (
		boxShadow || '0 2px 4px -1px rgba(0, 0, 0, 0.2),0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14)'
	)};

	width: 100%;
	margin: ${({ margin }) => margin};


	@media only screen and (max-width: 600px) {
		padding: ${({ paddingMobile }) => paddingMobile};
	}
`

const WrapperCardComponent = ({
    children, 
    boxShadow, 
    padding = '30px', 
    margin = '0', 
    id = '', className, 
    paddingMobile = '30px 15px 0 15px' 
}) => (
	<WrapperCardComponentStyles
		id={id}
		boxShadow={boxShadow}
		padding={padding}
		margin={margin}
		className={className}
    	paddingMobile={paddingMobile}
	>
		{children}
	</WrapperCardComponentStyles>
)

WrapperCardComponent.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.shape(),
		PropTypes.elementType,
		PropTypes.array
	]),
	padding: PropTypes.string,
	margin: PropTypes.string,
	id: PropTypes.string,
	className: PropTypes.string,
	boxShadow: PropTypes.string,
	paddingMobile: PropTypes.string,
}

export default WrapperCardComponent
