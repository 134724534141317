// entity
import { Tag, Tier, CompanyBudgetRollover } from ".";
// services
import { isEmpty } from "../../services/general.utils";
import { PngPhoto } from "../../services/global.entities";
// constants
import { CompanyStatusEnum } from "../../constants/companyStatus.constants";

class Company {
  constructor(company = {}) {
    this.id = company.id || null;
    this.name = company.name || "";
    this.pib = company.pib || "";
    this.address = company.address || "";
    this.idNumber = company.idNumber || company.id_number || "";
    this.photo = company.Photo ? new PngPhoto(company.Photo) : undefined;
    this.contact = company.contact || "";
    this.invoiceEmail = company.invoiceEmail || company.invoice_email || "";
    this.status = company.status || CompanyStatusEnum.LIVE;
    this.paymentDeadline =
      company.paymentDeadline || company.payment_deadline
        ? `${company.paymentDeadline || company.payment_deadline} days`
        : "";
    this.vatEnabled = company.vatEnabled || company.vat_enabled || false;
    this.tags = !isEmpty(company.Tags)
      ? company.Tags.map(tag => new Tag(tag))
      : [];
    this.tiers = !isEmpty(company.Tiers)
      ? company.Tiers.map(tier => new Tier(tier))
      : [];
    this.isRolloverEnabled =
      company.isRolloverEnabled || company.is_rollover_enabled || false;
    this.companyBudgetRollover = company.CompanyBudgetRollover
      ? new CompanyBudgetRollover(company.CompanyBudgetRollover)
      : undefined;
    this.bonusBudgetEnabled =
      company.bonusBudgetEnabled || company.bonus_budget_enabled || false;
    this.onlySSOLoginEnabled =
      company.onlySSOLoginEnabled || company.only_sso_login_enabled || false;
    this.hasReimbursement = company.hasReimbursement || false;
    this.viewInRsd = company.viewInRsd || false;
  }
}

export default Company;
