import styled from 'styled-components'

const FormStyles = styled.div`
	.userInfoContainer {
		display: flex;
		align-items: center;
		margin: 60px 0;

		.inputContainer {
			margin-left: 30px;

			.topInputs {
				display: flex;
				margin-bottom: 10px;
			}

			.middleInputs {
				display: flex;
				margin-bottom: 10px;
			}

			.bottomInputs {
				display: flex;
			}
		}		
	}

	.buttonsContainer {
		display: flex;
	}

	.displayText {
		font-size: 14px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #4b4b4b;	
		background: #d8d8d8bf;
		width: 100%;
		height: 24px;
		display: flex;
		align-items: center;
		padding: 0 5px;
	}
`

export default FormStyles
