import styled from "styled-components";

const BudgetBreakdownDropdownStyles = styled.header`
  .budgetBreakdown {
    position: absolute;
    width: 430px;
    right: 17%;
    top: 80px;
    border-color: rgba(255, 255, 255, 0.7);
    border-radius: 4px;
    box-shadow: 2px 5px 10px 2px #ccc;
    background: white;
    z-index: 9;
    display: flex;
    flex-direction: column;
    align-items: center;

    .breakdownContent {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    .breakdownLabel {
      font-weight: 600;
      font-size: 16px;
      letter-spacing: -0.2px;
      color: #354052;
    }

    .amount {
      font-weight: 600;
      font-size: 16px;
      letter-spacing: -0.2px;
      color: #373737;
    }

    .budgetBreakdownHeader {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      background: #3F7BD0;
      border-radius: 3px 3px 0 0;
      width: 100%;

      .budgetLabel {
        font-weight: 600;
        font-size: 16px;
        letter-spacing: -0.2px;
        color: #FFFFFF;
      }

      .budget {
        font-weight: 600;
        font-size: 16px;
        letter-spacing: -0.2px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }
    
    .budgetBreakdownContent {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 10px 0 0 0;

      .contentWrapper {
        display: flex;
        justify-content: space-between;
        padding: 10px 20px 10px 20px;
      }

      .totalSpentWrapper {
        display: flex;
        justify-content: end;
        padding: 10px 20px 10px 20px;
        
        .totalSpentContent {
          display: flex;
          align-items: center;
          gap: 5px;

          .totalSpentLabel {
            font-weight: 600;
            font-size: 14px;
            letter-spacing: -0.2px;
            color: #354052;
          }
        }
      }
    }

    .divider {
      width: 90%;
      align-self: center;
      border-top: 1px solid #8c8b8b;
    }
}
`;

export default BudgetBreakdownDropdownStyles;
