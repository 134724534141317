import styled from "styled-components";

export const PrivacyPolicyStyles = styled.div`
  max-width: 720px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 30px 30px 30px;

  .circleLogoContainer {
    width: 126px;
    height: 126px;
    margin-bottom: 30px;
  }

  .privacy-policy-content {
    max-height: 500px;
    overflow-x: auto;
    padding: 30px;

    h2 {
      padding: 10px 0 20px 0;
    }

    h3 {
      margin-left: 10px;
      padding: 10px 0 20px 0;
    }

    p {
      padding: 10px 0 10px 0;
    }

    & > ol {
      list-style: none;
      padding: 10px 0 10px 0;

      li {
        padding: 10px 0 10px 0;
        margin-left: 10px;
      }
    }

    ul {
      padding: 10px 0 10px 0;

      li {
        margin-left: 10px;
        padding: 10px 0 10px 0;
      }
    }

    .checkbox-wrapper {
      display: flex;
      align-items: center;
    }

    .action-buttons {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }
  }

  .cancel-button {
    background: crimson;
  }

  .title {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.81;
    letter-spacing: normal;
    color: #4b4b4b;
    margin-bottom: 30px;
  }

  .text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: center;
    color: #4b4b4b;
    margin-bottom: 10px;
  }

  .privacy-policy-subtitle {
    font-size: 16px;
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
    height: 100vh;
  }
`;
