// Entities
import Voucher from "./Voucher.entity";

// Utils
import { isEmpty } from "../../services/general.utils";

class LocalBenefit {
  constructor(benefit = {}) {
    this.id = benefit.id || null;
    this.title = benefit.title || "";
    this.description = benefit.description || "";
    this.summary = benefit.summary || "";
    this.expiration = benefit.expiration
      ? benefit.expiration.split("_").join(" ")
      : "" || "";
    this.tokenPrice = benefit.tokenPrice || benefit.token_price || 0;
    this.minimumContractPeriod =
      benefit.minimumContractPeriod || benefit.minimum_contract_period || "";
    this.vouchers = benefit.BenefitVoucher
      ? benefit.BenefitVoucher.map(voucher => new Voucher(voucher))
      : [];
    // this.photo = benefit.Photo ? new JpgPhoto(benefit.Photo) : null;
    this.benefitGroupId =
      benefit.benefitGroupId || benefit.benefit_group_id || null;
    this.isDraft = !isEmpty(benefit.isDraft) ? benefit.isDraft : false;
    this.quantity = benefit.quantity || "";
  }
}

export default LocalBenefit;
