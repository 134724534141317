export const relationshipOptions = [
	{ id: 1, name: 'Single' },
	{ id: 2, name: 'Married' },
	{ id: 3, name: 'Divorced' }
]

export const genderOptions = [
	{ id: 1, name: 'Male' },
	{ id: 2, name: 'Female' },
	{ id: 3, name: 'Other' }
]
