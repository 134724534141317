import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const ErrorIconSvgStyles = styled.div``

const ErrorIconSvg = ({ color = '#fff' }) => (
  <ErrorIconSvgStyles>
    <svg
      width="70"
      height="50"
      viewBox="0 0 30 30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="red"
        d="M3.29297 21.4375H20.6953C21.2422 21.4375 21.7148 21.3164 22.1133 21.0742C22.5195 20.832 22.832 20.5078 23.0508 20.1016C23.2695 19.6953 23.3789 19.2461 23.3789 18.7539C23.3789 18.5273 23.3477 18.3047 23.2852 18.0859C23.2305 17.8672 23.1406 17.6523 23.0156 17.4414L14.2969 1.89062C14.0547 1.4375 13.7227 1.09766 13.3008 0.871094C12.8867 0.644531 12.4492 0.53125 11.9883 0.53125C11.543 0.53125 11.1094 0.644531 10.6875 0.871094C10.2656 1.09766 9.92969 1.4375 9.67969 1.89062L0.960938 17.4414C0.726562 17.8789 0.609375 18.3164 0.609375 18.7539C0.609375 19.2461 0.71875 19.6953 0.9375 20.1016C1.15625 20.5078 1.46484 20.832 1.86328 21.0742C2.26953 21.3164 2.74609 21.4375 3.29297 21.4375ZM12.0117 14.0547C11.4648 14.0547 11.1875 13.7734 11.1797 13.2109L11.0391 7.17578C11.0312 6.91016 11.1172 6.69141 11.2969 6.51953C11.4766 6.33984 11.7109 6.25 12 6.25C12.2812 6.25 12.5117 6.33984 12.6914 6.51953C12.8711 6.69922 12.9609 6.92188 12.9609 7.1875L12.8086 13.2109C12.8086 13.7734 12.543 14.0547 12.0117 14.0547ZM12.0117 17.6875C11.6914 17.6875 11.4141 17.5781 11.1797 17.3594C10.9531 17.1406 10.8398 16.8789 10.8398 16.5742C10.8398 16.2617 10.9531 15.9961 11.1797 15.7773C11.4141 15.5508 11.6914 15.4375 12.0117 15.4375C12.3242 15.4375 12.5938 15.5469 12.8203 15.7656C13.0547 15.9844 13.1719 16.2539 13.1719 16.5742C13.1719 16.8867 13.0547 17.1523 12.8203 17.3711C12.5859 17.582 12.3164 17.6875 12.0117 17.6875Z"
      />
    </svg>
  </ErrorIconSvgStyles>
)

ErrorIconSvg.propTypes = {
  color: PropTypes.string
}

export default ErrorIconSvg;
