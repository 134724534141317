import React, { useState, useContext } from "react";
import { connect } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
// components
import BenefitGroupForm from "../BenefitGroupForm";
// styles
import AddBenefitGroupStyles from "./AddBenefitGroupStyles";
// entity
import { BenefitGroup } from "../../../../../../models/domain";
// services
import { validateBenefitGroupForm } from "../../../../../../services/validations/benefitGroupFormValidation";
import * as providersService from "../../../../../../services/api/admin/providers.service";
import * as actionCreators from "../../../../../../actions/admin/providers.actions";
import { useTranslation } from "react-i18next";

const AddBenefitGroup = ({
  setChosenBenefitGroupId,
  providerId,
  closeModal,
  addBenefitGroup
}) => {
  const { t } = useTranslation();
  const [disableButton, setDisableButton] = useState(false);

  const handleCreateBenefitGroupError = ({ error }) => {
    const { data } = error;
    if (data.errors && data.error) {
      if (data.errors.name) {
        toast.error(`${data.error.message}: ${data.errors.name.keys[0]}`);
      }
      if (data.errors.description) {
        toast.error(
          `${data.error.message}: ${data.errors.description.keys[0]}`
        );
      }
      if (data.errors.summary) {
        toast.error(`${data.error.message}: ${data.errors.summary.keys[0]}`);
      }
      if (data.errors.categoryIds) {
        toast.error(
          `${data.error.message}: ${data.errors.categoryIds.keys[0]}`
        );
      }
      if (data.errors.taxes) {
        toast.error(`${data.error.message}: ${data.errors.taxes.keys[0]}`);
      }
      if (data.errors.photo) {
        toast.error(`${data.errors.photo.keys[0]}`);
      }
      if (data.errors.cityIds) {
        toast.error(`${data.errors.cityIds.keys[0]}`);
      }
    } else {
      toast.error(data.error.message);
    }
  };

  const createBenefitGroup = async values => {
    setDisableButton(true);

    const response = await providersService.addBenefitGroup(providerId, values);

    if (response.hasError) {
      setDisableButton(false);
      return handleCreateBenefitGroupError(response);
    }

    addBenefitGroup(providerId, response);
    toast.success(t("success_add_benefit_group"));
    setChosenBenefitGroupId(response.id);
    setTimeout(() => {
      setDisableButton(false);
    }, 550);
    closeModal();
  };
  const formik = useFormik({
    initialValues: new BenefitGroup(),
    validate: validateBenefitGroupForm,
    onSubmit: createBenefitGroup
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    handleChange,
    handleBlur
  } = formik;

  return (
    <AddBenefitGroupStyles>
      <h3 className="title">{t("add_benefit_group")}</h3>
      <form className="form" autoComplete="off" onSubmit={handleSubmit}>
        <BenefitGroupForm
          values={values}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          handleChange={handleChange}
          buttonText="Create"
          handleBlur={handleBlur}
          closeModal={closeModal}
          disableButton={disableButton}
        />
      </form>
    </AddBenefitGroupStyles>
  );
};

AddBenefitGroup.propTypes = {
  providerId: PropTypes.number,
  setChosenBenefitGroupId: PropTypes.func,
  closeModal: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    addBenefitGroup: (providerId, benefitGroup) =>
      dispatch(actionCreators.addBenefitGroup(providerId, benefitGroup))
  };
};

export default connect(undefined, mapDispatchToProps)(AddBenefitGroup);
