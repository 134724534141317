export const SYSTEM_NOTIFICAITON_TYPES = {
  BENEFIT_CHANGED_PRICE: "benefit_changed_price",
  DECREASED_TIER_BUDGET: "decreased_tier_budget",
  CHANGED_TIER_BUDGER: "changed_tier_budget",
  EMPLOYEE_REASSIGNED_TIER: "employee_reassigned_tier",
  BENEFIT_GROUP_CHANGED_LOCATION: "benefit_group_changed_location",
  EMPLOYEE_CHANGED_LOCATION: "employee_changed_location",
  EMPLOYEE_LOCATION_UPDATED_INFO: "employee_locations_updated_info",
  BENEFIT_GROUP_LOCATION_UPDATED_INFO: "benefit_group_locations_updated_info",
  BENEFIT_CHANGED_EXPIRATION_TYPE: "benefit_changed_expiration_type",
  EMPLOYEE_SUBSCRIBED_TO_MCP_BENEFIT: "employee_subscribed_to_mcp_benefit"
};
