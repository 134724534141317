class EmployeeChangedTierSystemNotificationPayload {
  constructor(systemNotificationPayloadData = {}) {
    this.id = systemNotificationPayloadData.id || "";
    this.tierId =
      systemNotificationPayloadData.tierId ||
      systemNotificationPayloadData.tier_id ||
      "";
  }
}

export default EmployeeChangedTierSystemNotificationPayload;
