/**
 * Determine if role exists in roles array.
 * @param {array} roles 
 * @param {UserRoleKeysEnum} roleForComparison 
 * @returns {boolean}
 */
export const doesRoleExist = (
  roles,
  roleForComparison
) => {
  return roles?.some(role => role.key === roleForComparison);
}

/**
 * Determine if role is authenticated.
 * @param {string} authenticatedRole 
 * @param {UserRoleKeysEnum} roleKeyForComparison 
 * @returns {boolean}
 */
export const isAuthenticatedRole = (
  authenticatedRole,
  roleKeyForComparison
) => {
  return authenticatedRole.key === roleKeyForComparison;
}