import styled from 'styled-components'

export const SetPasswordStyles = styled.div`
	padding: 30px;
	background: #d8d8d8;

	.setPasswordContent {
		min-height: calc(100vh - 120px);
		display: flex;
		flex-direction:column;
		align-items: center;
		justify-content: center;

		.circleLogoContainer {
			width: 126px;
			height: 126px;
			margin-bottom: 30px;
		}

		.setPasswordTitle {
			font-size: 24px;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 0.81;
			letter-spacing: normal;
			color: #4b4b4b;
			margin-bottom: 30px;
		}
		.setPasswordText {
			font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.57;
		letter-spacing: normal;
		text-align: center;
		color: #4b4b4b;
		}

			.form {
		    margin-top: 30px;
        max-width: 400px;
        width: 85%;
        
        .errorMsg {
          font-size: 12px;
          color: red
        }

        .label {
          width: 100%;
          font-size: 14px;

          .inputs {
            margin: 0 0 30px 0;
            font-size: 16px;
            padding: 5px 10px 2px 10px;
            line-height: 24px;
            width: 100%;
            border-top: none;
            border-left: none;
            border-right: none;
            border-bottom: 1px solid #1568bf;
          }

          .inputs:focus {
            outline: none;
            border-bottom: 1px solid #1568bf;
          }

          input[type='number']::-webkit-inner-spin-button,
          input[type='number']::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }

        .label:focus-within {
          color: #1568bf;
        }

        .buttonContainer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;

          .termsContainer {
            display: flex;

            .termsCheckbox {
              margin-top: 4px;
            }

            .terms {
              width: 203px;
              margin-left:  12px;
              margin-bottom: 7px;
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 20px;
              color: #4B4B4B;
            }
          }

          .termsErrors {
            position: absolute;
            left: 0;
            top:  -15px;
            height: 15px;
          }

          .forgotPassword {
            color: black;
            font-size: 14px;

            &:hover {
              color: #1568bf;
            }
          }
          /**TODO remove next snippet -
          start - dead code*/
          .submitButton {
            padding: 5px 20px;
            color: #fff;
            cursor: pointer;
            background: darkblue;
            font-weight: bold;
            font-size: 16px;
            border-radius: 4px;
            border: 1px solid darkblue;
          }

          .submitButton:focus {
            outline: none;
          }
          /**end - dead code */
        }
      }

     
	}

`
