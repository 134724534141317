import * as actionTypes from "../../constants/actionTypes/admin/companyTags.constants";

export const setCompanyTags = companyTags => {
  return { type: actionTypes.SET_COMPANY_TAGS, companyTags };
};

export const addCompanyTag = companyTag => {
  return { type: actionTypes.ADD_COMPANY_TAG, companyTag };
}

export const editCompanyTag = companyTag => {
  return { type: actionTypes.EDIT_COMPANY_TAG, companyTag };
}

export const deleteCompanyTag = id => {
  return { type: actionTypes.DELETE_COMPANY_TAG, id };
}