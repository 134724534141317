import React from 'react'
import PropTypes from 'prop-types'

import InputStyles from './InputStyles'

const Input = ({
	type = 'text',
	onBlur,
	styles,
	shouldHaveHight = true,
	placeholder,
	customCssClass,
	pattern,
	rows,
	width,
	margin,
	value,
	name,
	onChange,
	max = Infinity,
	inputWidth = "100%",
	onKeyDown = () => { { } },
	customHeight = "27px",
	min = 0,
	disabled = false
}) => (
	<InputStyles
		width={width}
		margin={margin}
		shouldHaveHight={shouldHaveHight}
		inputWidth={inputWidth}
		customHeight={customHeight}
	>
		<input
			type={type}
			placeholder={placeholder}
			pattern={pattern}
			name={name}
			value={value}
			min={min}
			rows={rows}
			onChange={onChange}
			className={`input ${customCssClass}`}
			onBlur={onBlur && onBlur}
			style={{ ...styles }}
			max={max}
			onKeyDown={onKeyDown}
			disabled={disabled}
		/>
	</InputStyles>
)

Input.propTypes = {
	styles: PropTypes.shape(),
	customCssClass: PropTypes.string,
	type: PropTypes.string,
	shouldHaveHight: PropTypes.bool,
	pattern: PropTypes.string,
	placeholder: PropTypes.string,
	width: PropTypes.string,
	margin: PropTypes.string,
	name: PropTypes.string,
	rows: PropTypes.number,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	max: PropTypes.number
}

export default Input
