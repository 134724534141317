import React, { useEffect, useRef, useState } from "react";
import DashboardStatisticsNavbarStyles from "./DashboardStatisticsNavbarStyles";
import { useDispatch, useSelector } from "react-redux";
import { calculateCompanySubscriptions } from "../helpers/calculateCompanySubscriptions";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import { setAdminDashboardFilters } from "../../../../../actions/admin/dashboard.actions";
import useClickOutside from "../../../../../hooks/useClickOutside";
import { SelectCompaniesComponent } from "./SelectCompaniesComponent";
import { useTranslation } from "react-i18next";

export const DashboardStatisticsNavbar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const showDatePickerRef = useRef(null);

  const { listOfUserBenefits, filters } = useSelector(
    state => state.adminDashboard
  );

  const dropdownRef = useRef(null);

  const [showDropdown, setShowDropdown] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);

  useClickOutside([dropdownRef], () => setShowDropdown(false));
  useClickOutside([showDatePickerRef], () => setShowDatePicker(false));

  const companySubscriptions = calculateCompanySubscriptions(
    listOfUserBenefits
  );

  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const [selectionComplete, toggleSelectionComplete] = useState(false);

  const handleDateChange = date => {
    if (!selectionComplete && !startDate) {
      setStartDate(date);
      return;
    }

    if (!selectionComplete && startDate && !endDate) {
      setEndDate(date);
      toggleSelectionComplete(true);
      return;
    }

    if (selectionComplete && startDate && endDate) {
      setStartDate(date);
      setEndDate(undefined);
      toggleSelectionComplete(false);
      return;
    }
  };

  const handleSelect = date => {
    if (
      !selectionComplete &&
      startDate &&
      !endDate &&
      sameDay(date, startDate)
    ) {
      handleDateChange(date);
    }
  };

  const sameDay = (d1, d2) => moment(d1).isSame(d2, "day");

  const handleSelectCompany = company => {
    dispatch(
      setAdminDashboardFilters({
        ...filters,
        companyId: company === "All Companies" ? null : company
      })
    );
  };

  useEffect(() => {
    if (!selectionComplete) return;

    dispatch(
      setAdminDashboardFilters({
        ...filters,
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD")
      })
    );
  }, [startDate, endDate, selectionComplete]);

  return (
    <DashboardStatisticsNavbarStyles>
      <div className="dashboardNavbarFirstLayer">
        <div className="uniqueUsersCountWrapper">
          <div
            className="uniqueUsersCount"
            onClick={() => setShowDropdown(state => !state)}
            ref={dropdownRef}
          >
            <span>{t("unique_user_sub")}</span>
            <p>{listOfUserBenefits.length}</p>
          </div>
          {showDropdown && (
            <div className="uniqueUsersCountDropdown">
              {Object.entries(companySubscriptions).map(([key, value]) => {
                return (
                  <div key={key} className="uniqueUsersCountDropdownItem">
                    <span>{key}</span>: <span>{value}</span>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <div className="dashboardNavbarSecondLayer">
        <div className="datePickerWrapper">
          <div className="dateWrapper">
            <button
              ref={showDatePickerRef}
              className="openDatePicker"
              onClick={() => setShowDatePicker(state => !state)}
            >
              {t("Select Date")}
            </button>
            {showDatePicker && (
              <ReactDatePicker
                selected={startDate}
                onChange={handleDateChange}
                onSelect={handleSelect}
                selectsEnd={Boolean(startDate)}
                startDate={startDate}
                endDate={endDate}
                inline
              >
                <div className="date-range">
                  {startDate
                    ? moment(startDate).format("DD/MM/YYYY")
                    : "??/??/????"}{" "}
                  -{" "}
                  {endDate
                    ? moment(endDate).format("DD/MM/YYYY")
                    : "??/??/????"}
                </div>
              </ReactDatePicker>
            )}
          </div>
          <SelectCompaniesComponent onSelect={handleSelectCompany} />
        </div>
      </div>
    </DashboardStatisticsNavbarStyles>
  );
};
