/*eslint-disable*/
import React from 'react'
import {useLocation} from 'react-router-dom'
import LoginStyles from './LoginStyles'
import Form from '../../components/forms/resetPassword/resetPassword.form'



const ResetPassword = () => {
	const location = useLocation()

	function getUrlParameter(name) {
		name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
		var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
		var results = regex.exec(location.search);
		return results === null ? '' : decodeURIComponent(results[1]).replace("%2B", "+").replace("%40", "@");
	};
	
	return (
		<LoginStyles>
			<Form
				email={getUrlParameter('email')}
				hash={getUrlParameter('hash')}
			/>
		</LoginStyles>
	)
}


export default ResetPassword
