const openModalAnim = () => {
	const modal = document.getElementById('modal')
	const closeButton = document.getElementById('closeButton')
	modal.classList.add('showModal')
	setTimeout(() => {
		closeButton.classList.add('showCloseButton')
	}, 200)
}


const closeModalAnim = () => {
	const modal = document.getElementById('modal')
	const closeButton = document.getElementById('closeButton')
	if (closeButton && closeButton.classList.contains('showCloseButton')) {
		closeButton.classList.remove('showCloseButton')
	}
	if (modal && modal.classList.contains('showModal')) {
		setTimeout(() => {
			modal.classList.remove('showModal')
		}, 300)
	}
}

export { openModalAnim, closeModalAnim }
