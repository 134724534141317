import { imageSize, createUrl, imageFormat } from './imageHandler'

class PngPhoto {
	constructor(photo = {}) {
		this.id = photo.id || null
		this.url = createUrl(imageSize.original, imageFormat.png, photo)
		this.url2x = createUrl(imageSize.image2X, imageFormat.png, photo)
		this.url3x = createUrl(imageSize.image3X, imageFormat.png, photo)
		this.url4x = createUrl(imageSize.image4X, imageFormat.png, photo)
		this.url5x = createUrl(imageSize.image5X, imageFormat.png, photo)
		this.url6x = createUrl(imageSize.image6X, imageFormat.png, photo)
		this.url7x = createUrl(imageSize.image7X, imageFormat.png, photo)
		this.url8x = createUrl(imageSize.image8X, imageFormat.png, photo)
		this.url9x = createUrl(imageSize.image9X, imageFormat.png, photo)
	}
}

class JpgPhoto {
	constructor(photo = {}) {
		this.id = photo.id || null
		this.url = createUrl(imageSize.original, imageFormat.jpg, photo)
		this.url2x = createUrl(imageSize.image2X, imageFormat.jpg, photo)
		this.url3x = createUrl(imageSize.image3X, imageFormat.jpg, photo)
		this.url4x = createUrl(imageSize.image4X, imageFormat.jpg, photo)
		this.url5x = createUrl(imageSize.image5X, imageFormat.jpg, photo)
		this.url6x = createUrl(imageSize.image6X, imageFormat.jpg, photo)
		this.url7x = createUrl(imageSize.image7X, imageFormat.jpg, photo)
		this.url8x = createUrl(imageSize.image8X, imageFormat.jpg, photo)
		this.url9x = createUrl(imageSize.image9X, imageFormat.jpg, photo)
	}
}

export { PngPhoto, JpgPhoto }
