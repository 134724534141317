import React from "react";

const FilterIconMobileSvg = () => (
  <svg 
    width="20" 
    height="20" 
    viewBox="0 0 20 20" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      fillRule="evenodd" 
      clipRule="evenodd" 
      d="M1.79149 0.938004C4.50337 0.479036 7.24904 0.248892 9.99949 0.250004C12.7965 0.250004 15.5395 0.486004 18.2085 0.938004C18.6409 1.01266 19.0328 1.238 19.3149 1.57408C19.597 1.91015 19.7509 2.33525 19.7495 2.774V3.818C19.7493 4.61359 19.4331 5.37653 18.8705 5.939L12.6885 12.121C12.5492 12.2604 12.4388 12.4258 12.3634 12.6078C12.2881 12.7899 12.2494 12.985 12.2495 13.182V16.109C12.2497 16.6662 12.0946 17.2125 11.8018 17.6865C11.5089 18.1606 11.0898 18.5437 10.5915 18.793L8.83449 19.671C8.72014 19.7281 8.59311 19.755 8.46544 19.7492C8.33777 19.7434 8.2137 19.7051 8.10501 19.6379C7.99631 19.5706 7.9066 19.4768 7.84438 19.3651C7.78215 19.2535 7.74949 19.1278 7.74949 19V13.182C7.74914 12.7843 7.59087 12.403 7.30949 12.122L1.12949 5.938C0.56684 5.37553 0.250658 4.61259 0.250488 3.817V2.774C0.250488 1.877 0.890488 1.091 1.79249 0.938004H1.79149Z" 
      fill="#668CCC"
    />
  </svg>
  
);

export default FilterIconMobileSvg;