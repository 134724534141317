import React from 'react'
import styled from 'styled-components'

const LogoIconSvgStyles = styled.div``

const LogoIconSvg = () => (
	<LogoIconSvgStyles>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			width="45"
			height="66"
			viewBox="0 0 45 66"
		>
			<defs>
				<path id="a" d="M0 0h45v66H0z" />
			</defs>
			<g fill="none" fillRule="evenodd">
				<mask id="b" fill="#fff">
					<use xlinkHref="#a" />
				</mask>
				<path
					fill="#1568BF"
					d="M22.88 54.72c.003-3.555.005-2.36.003-3.555-.018-8.961.037-17.922-.042-26.882-.021-2.413.249-4.086 2.963-5.088 3.014-1.113 4.49-4.734 3.817-7.79-.717-3.25-2.608-5.702-5.964-5.856-5.923-.273-12.352-.083-18.304-.083.044 1.885.043 1.352.044 1.885.027 12.542-.226 25.096.24 37.623.315 8.456 6.954 14.58 15.567 15.575 7.387.854 14.744-3.913 17.36-11.246 2.81-7.882-.008-16.553-6.85-20.91-.948-.604-2.049-.961-3.578-1.661 0 8.288.023 15.89-.002 23.492-.016 4.496-.015 4.496-5.255 4.496m8.953-32.775c1.662 1.167 3.26 2.153 4.708 3.33 8.05 6.544 10.749 18.199 6.383 27.34-4.694 9.825-14.813 15.073-24.871 12.898C7.607 63.254.176 54.277.089 43.496-.019 30.04.098 16.584 0 3.128-.016.788.721-.038 3.02.001c6.4.107 12.804-.056 19.204.066 10.601.2 16.161 10.535 10.725 19.79-.383.65-.714 1.33-1.117 2.088"
					mask="url(#b)"
				/>
			</g>
		</svg>
	</LogoIconSvgStyles>
)

export default LogoIconSvg
