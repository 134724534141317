import React from 'react'
import styled from 'styled-components'

const BenefitsIconSvgStyles = styled.div`
  height: 100%;
	width: 16px;
  display: flex;
	align-items: center;
`

const BenefitsIconSvg = () => (
	<BenefitsIconSvgStyles>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="12"
			height="13"
			viewBox="0 0 12 13"
		>
			<path
				fill="#FFF"
				fillRule="evenodd"
				d="M7.857 10.97c-.197 0-.304-.107-.304-.304V7.874c0-.206.107-.313.304-.313h2.817c.198 0 .297.107.297.313v2.792c0 .197-.1.304-.297.304H7.857zm-6.53 0c-.19 0-.297-.107-.297-.304V7.874c0-.206.107-.313.296-.313h2.817c.197 0 .305.107.305.313v2.792c0 .197-.108.304-.305.304H1.326zm6.514 1.038h2.841c.881 0 1.318-.445 1.318-1.35V7.874c0-.906-.437-1.343-1.318-1.343H7.841c-.881 0-1.318.437-1.318 1.343v2.784c0 .905.437 1.35 1.318 1.35zm-6.523 0h2.841c.881 0 1.318-.445 1.318-1.35V7.874c0-.906-.437-1.343-1.318-1.343H1.318C.437 6.531 0 6.968 0 7.874v2.784c0 .905.437 1.35 1.318 1.35zm6.54-7.56c-.198 0-.305-.108-.305-.313V1.342c0-.197.107-.304.304-.304h2.817c.198 0 .297.107.297.304v2.793c0 .205-.1.312-.297.312H7.857zm-6.532 0c-.19 0-.296-.108-.296-.313V1.342c0-.197.107-.304.296-.304h2.817c.197 0 .305.107.305.304v2.793c0 .205-.108.312-.305.312H1.326zm6.515 1.037h2.841c.881 0 1.318-.445 1.318-1.35V1.35C12 .445 11.563 0 10.682 0H7.841C6.96 0 6.523.445 6.523 1.35v2.785c0 .905.437 1.35 1.318 1.35zm-6.523 0h2.841c.881 0 1.318-.445 1.318-1.35V1.35C5.477.445 5.04 0 4.159 0H1.318C.437 0 0 .445 0 1.35v2.785c0 .905.437 1.35 1.318 1.35z"
			/>
		</svg>
	</BenefitsIconSvgStyles>
)

export default BenefitsIconSvg
