import styled from "styled-components";

const SortSelectInputStyles = styled.div`
  width: 100%;
  min-width: 200px;
  height: ${({ shouldHaveHight }) => shouldHaveHight && "40px"};
  padding-bottom: 22px;
  border-bottom: 1px solid #e6eaee;

  .react-dropdown-select-clear {
    font-size: 13px !important;
    text-transform: capitalize !important;
    font-weight: 700 !important;
    color: hsl(0, 0%, 70%) !important;
    line-height: unset !important;
    font-size: 0 !important; /* Hide the original "×" */
    position: relative;

    &::before {
      content: "X" !important; /* Customize with your desired character or symbol */
      font-size: 13px; /* Set the font size to make the "X" visible */
      color: inherit !important; /* Match the color to the current text color */
      position: absolute !important;
      top: -7px !important;
      left: -7px !important;
    }
  }

  .react-dropdown-select {
    min-height: 40px !important;
    height: 100% !important;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #979797;
    box-shadow: none !important;
    border-color: #979797 !important;
    cursor: pointer;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #5d5d5d;
    display: flex;
    align-items: center;
    /* overflow-x: hidden; */

    .react-dropdown-select-content {
      height: 100%;
      flex-wrap: nowrap;
      text-overflow: ellipsis;
      overflow-x: hidden;
      width: 70px;
      white-space: nowrap;

      input {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #5d5d5d;
      }
    }

    .react-dropdown-select-dropdown {
      max-height: 150px;
    }

    .itemContainer {
      &:hover {
        background: #1568bf;

        .dropDownItem {
          color: #fff;
        }
      }

      .dropDownItem {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #5d5d5d;
      }
    }
  }
`;

export default SortSelectInputStyles;
