import React from "react";
import { isEmpty } from "../../../../services/general.utils";

const BenefitGroupLocationUpdatedInfo = ({ notification }) => {
  return (
    <>
      {!isEmpty(notification.payload.benefit_group_name) && (<h4 className="notification-title">
        There have been some changes regarding benefit group's "{notification.payload.benefit_group_name}" location.
      </h4>)}
      <p className="subtitle-description">
        Please check your benefits on My Benefits page and make sure you are ok with these changes.
      </p>
    </>
  );
};

export default BenefitGroupLocationUpdatedInfo;
