// Models
import { Tier, City } from ".";
import UserBenefit from "./UserBenefit.entity";
// Utils
import { isEmpty } from "../../services/general.utils";


class HrEmployeeDetails {
  constructor(employeeData = {}) {
    this.firstName = employeeData.User
      ? employeeData.User.firstName || employeeData.User.first_name
      : "";
    this.lastName = employeeData.User
      ? employeeData.User.lastName || employeeData.User.last_name
      : "";
    this.isDisabled = employeeData.User
      ? employeeData.User.isDisabled || employeeData.User.is_disabled
      : false
    this.born_date = employeeData.bornDate ? new Date(employeeData.bornDate) : "" || "";
    this.firstDayAtWork = employeeData.firstDayAtWork ? new Date(employeeData.firstDayAtWork) : "" || "";
    this.email = employeeData.User ? employeeData.User.email : "";
    this.relationship = employeeData.relationship || "";
    this.team = employeeData.team || "";
    this.contact = employeeData.contact || "";
    this.description = employeeData.description || "";
    this.gender = employeeData.gender || "";
    this.uniqueId = employeeData.uniqueId || "";
    this.branchCode = employeeData.companyBranchId || "";
    this.children = employeeData.children || false;
    this.shouldEmployeeHaveHRRole = !isEmpty(employeeData.shouldEmployeeHaveHRRole) ? employeeData.shouldEmployeeHaveHRRole : false;
    this.tier = employeeData.Tier ? new Tier(employeeData.Tier) : new Tier();
    this.userBenefits = employeeData.UserBenefits
      ? employeeData.UserBenefits.map(benefit => new UserBenefit(benefit))
      : [];
    this.userId = employeeData.User?.id || null;
    this.cities = employeeData.cities
      ? employeeData.cities.map(city => new City(city))
      : [];
  }
}

export default HrEmployeeDetails;
