import React from "react";
import NotificationPayloadRender from "./NotificationPayloadRender";

const NotificationsContent = ({ notification }) => {
  return (
    <div className="notifications-content">
      <NotificationPayloadRender notification={notification} />
    </div>
  );
};

export default NotificationsContent;
