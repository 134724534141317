import styled from 'styled-components'

const ListItemExpandedStyles = styled.div`
  max-height: 800px;
  cursor: pointer;
  background: ${({backgroundOpen}) => backgroundOpen || '#FFF'};  

  @keyframes expandListItem {
    from {
      transform: translateY(120px);
      height: 120px;
      opacity: 0;
    }
    to {
      transform: translateY(0);
      max-height: 800px;
      opacity: 1;
    }
  }

  .listItemExpanded {
    display: flex;
    width: 100%;
    background: rgb(255, 255, 255);
    height: 0;
    z-index: 2;
    max-height: 0;
    transition: max-height 0.15s ease-out;
    overflow: hidden;
    border-bottom: 1px solid #F0F0F0;
  }


  .listItemExpanded.open {
    display: flex;
    width: 100%;
    background: ${({backgroundOpen}) => backgroundOpen || '#FFF'};
    z-index: 2;      
    animation: expandListItem 0.4s ease-in;
    height: 114px;
    max-height: 2000px;
    transition: max-height 0.15s ease-out;  
    transition-delay: 1s;
    transform-origin: center;
    padding: 0px 20px 16px 20px;
  }

  .genderAndLocation {
    display: flex;
    align-items: flex-start;
    width: 30%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #5F5F5F;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 30px;
    padding-left: 52px;
  }

  .expandedListItemLabel {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    color: #1D1D1D;
  }

  .expandedListItemDataContainer {
    display: flex;
    flex-direction: column;
    gap: 7px;
    margin-bottom: 10px;
  }

  .genderValue:first-letter {
    text-transform: uppercase;
  }

  .contactWrapper {
    width: 20%;
    display: flex;
    align-items: flex-start;
    margin-left: 1.2%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #5F5F5F;
    flex-direction: column;
    justify-content: flex-start;
  }

  .teamAndPositionWrapper {
    width: 19%;
    display: flex;
    align-items: flex-start;
    margin-left: 1%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #5F5F5F;
    flex-direction: column;
    justify-content: flex-start;
  }

  .birthDayAndEnrolldateWrapper {
    width: 19%;
    display: flex;
    align-items: flex-start;
    margin-left: 1%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #5F5F5F;
    flex-direction: column;
    justify-content: flex-start;
  }
`

export default ListItemExpandedStyles;
