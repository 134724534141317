import { renameSupabaseImageURL } from "./renameSupabaseImageURL";
import supabaseClient from "./supabaseClient";

async function processDelegation(
  zip,
  item,
  index,
  delay,
  totalImages,
  onProgress,
  downloadFolder,
  setIsRefundDownloading
) {
  const folder = zip.folder(downloadFolder);

  await new Promise(resolve => setTimeout(resolve, delay));

  const prefix = `https://gazfduoumclmczxravnt.supabase.co/storage/v1/object/public/file_upload/`;

  const renamedImage = item.url.replace(prefix, "");

  const { data, error } = await supabaseClient.storage
    .from("refund-upload")
    .download(renamedImage);

  if (error) {
    console.error(error, "download error");
    setIsRefundDownloading(false);
  } else {
    folder.file(item.name, data, { binary: true });
  }

  // Calculate and print the progress percentage across all items
  const progress = ((index + 1) / totalImages) * 100;
  onProgress(progress);
}

export default processDelegation;
